import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareTextField from '../DeclareTextField';

export default function CompleteInFirstXUnitsRule(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [units, setUnits] = useState(null);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = isRequirement) => {
    const data = { isRequirement: isReq, units: Number(units) };
    addRuleDataHandler({ data, selectedElementPath });
  };

  useEffect(() => {
    setUnits(props.data.units || '');
    setIsRequirement(props.data.isRequirement || false);
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DeclareTextField
          onChange={(e) => {
            setUnits(e.target.value);
          }}
          onBlur={() => {
            processChange(isRequirement);
          }}
          inputlabel={`${t('Before Unit')}*`}
          variant="outlined"
          placeholder={t('Before Unit')}
          value={units}
          name="before_unit"
          type="number"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirement}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirement(e.target.checked);
                processChange(e.target.checked);
              }}
            />
          }
          label={t('Requirement')}
        />
      </Grid>
    </Grid>
  );
}
