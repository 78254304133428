import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TransferOptionRaw from './TransferOptionRaw';
import { transferEquivalencyRawStyles } from '../../../components/styles/transferEquivalencyRowStyles';

const useStyle = makeStyles((theme) => ({
  hr: {
    display: 'block',
    visibility: 'visible',
    borderTop: `${theme.spacing(0.025)} solid #ccc`,
    marginTop: theme.spacing(2.5),
  },
  spacer: { display: 'flex', flex: 1 },
}));

export default function TransferOptions(props) {
  const {
    matchingInProgress,
    OnClickTransferOptionCards,
    matchedOptions,
    disabledCourses,
    value,
    selectedOrg,
    index,
    unUsedCourses,
    allCourseReq,
  } = props;
  const classes = useStyle(props);
  const classesTEQ = transferEquivalencyRawStyles({ option: true });

  return (
    <Paper flex={1} className={classesTEQ.transferEquivalencyContainer}>
      <Box flex={6}>
        <Stack flex={1}>
          {index !== 0 ? <hr className={classes.hr} /> : null}
          <Stack sx={{ marginTop: 1.5 }} direction="row">
            <Box className={classes.spacer}></Box>
            <Typography sx={{ display: 'flex', flex: 11 }} fontSize={12} fontWeight={800}>
              {value.code} {value.name}
            </Typography>
          </Stack>

          <TransferOptionRaw
            matchingInProgress={matchingInProgress}
            OnClickTransferOptionCards={OnClickTransferOptionCards}
            matchedOptions={matchedOptions}
            disabledCourses={disabledCourses}
            value={value}
            key={`tEqRow-${index}`}
            selectedOrg={selectedOrg}
            unUsedCourses={unUsedCourses}
            allCourseReq={allCourseReq}
          />
        </Stack>
      </Box>
    </Paper>
  );
}
