import { Box, Button, Grid, ListItemText, MenuItem, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import DeclaredSelect from '../../../../components/DeclareSelect';
import { useDeclareForm, DeclareForm } from '../../../../components/DeclareForm';
import { appRoutes } from '../../../../helpers/routes';
import { CLASS_SCHEDULE_STATUS, deliveryModes } from '../../../../helpers/appConstants';
import {
  getTerms,
  getAllClassSchedules,
  createClassSchedule,
  modifyClassSchedule,
} from '../../../../redux/actions/learningActivityActions';
import { MenuPropStyles } from '../../../../helpers/common';
import * as courseFormHelper from '../../../../helpers/courseFormHelper';
import { getAcademicYearsWithTermInstances } from '../../../../redux/actions/termActions';
import { setFormError } from '../../../../redux/actions/notificationActions';

export default function CourseForm(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentClassSchedule, isEditView } = props;
  const dispatch = useDispatch();
  const { termList: termsList, academicYears } = useSelector((state) => state.terms);
  const [termInstances, setTermInstances] = useState([]);
  const { values, setValues, handleInputChange } = useDeclareForm({});
  const { formError } = useSelector((state) => state.notifications);
  const { orgId, lActivityId, classScheduleId } = useParams();

  useEffect(() => {
    if (_.isEmpty(termsList)) {
      dispatch(getTerms(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(termsList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }

    if (!_.isEmpty(termsList) && !_.isEmpty(academicYears)) {
      const filteredAllTerms = academicYears.flatMap(({ termInstances }) => termInstances);
      setTermInstances(filteredAllTerms);
    }
  }, [termsList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty(termInstances) && !_.isEmpty(termInstances)) {
      setValues(currentClassSchedule);
    }
  }, [currentClassSchedule, termInstances, termsList]);

  const updateFormError = (field) => dispatch(setFormError({ ...formError, [field]: {} }));

  const data = {
    deliveryMode: values.deliveryMode,
    status: values.status,
    termInstanceId: values.termInstanceId,
    duration: values.duration,
  };

  const saveClass = async () => {
    const navigateToListView = () => {
      navigate(generatePath(appRoutes.catalog.CLASS_SCHEDULE_LIST, { orgId, lActivityId }));
      dispatch(getAllClassSchedules({ orgId, lActivityId }));
    };

    dispatch(createClassSchedule(orgId, lActivityId, data, navigateToListView));
  };

  const updateClass = async () => {
    const navigateToListView = () => {
      navigate(generatePath(appRoutes.catalog.CLASS_SCHEDULE_LIST, { orgId, lActivityId }));
      dispatch(getAllClassSchedules({ orgId, lActivityId }));
    };

    dispatch(modifyClassSchedule(orgId, lActivityId, classScheduleId, data, navigateToListView));
  };

  const cancelAction = async () => {
    navigate(generatePath(appRoutes.catalog.CLASS_SCHEDULE_LIST, { orgId, lActivityId }));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclaredSelect
              inputlabel={<b>{t('Term') + '*'}</b>}
              margin="dense"
              value={values.termInstanceId || null}
              name="termInstanceId"
              onChange={(e) => {
                if (!_.isEmpty(e.target.value)) {
                  updateFormError(e.target.name);
                }

                handleInputChange(e);
              }}
              error={formError['terms']}
              helperText={formError['terms']?.message}
              MenuProps={MenuPropStyles}>
              {termInstances.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  <ListItemText primary={item.displayName} />
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={<b>{t('Delivery Mode') + '*'}</b>}
              margin="dense"
              value={values.deliveryMode || null}
              name="deliveryMode"
              onChange={(e) => {
                if (!_.isEmpty(e.target.value)) {
                  updateFormError(e.target.name);
                }

                handleInputChange(e);
              }}
              error={formError['deliveryMode']}
              helperText={formError['deliveryMode']?.message}
              MenuProps={MenuPropStyles}>
              {deliveryModes.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
          <Grid item xs={6} className="right">
            <DeclaredSelect
              inputlabel={<b>{t('Duration') + '*'}</b>}
              margin="dense"
              value={values.duration ? values.duration : null}
              name="duration"
              onChange={handleInputChange}
              error={formError['duration']}
              helperText={formError['duration']?.message}>
              {courseFormHelper.getTermsList(termsList.length).map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={<b>{t('status') + '*'}</b>}
              margin="dense"
              value={values.status || null}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {Object.values(CLASS_SCHEDULE_STATUS).map((item, index) => (
                <MenuItem
                  value={item.value}
                  key={index}
                  disabled={item.value === CLASS_SCHEDULE_STATUS.CANCEL.value}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateClass() : saveClass();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            cancelAction();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
