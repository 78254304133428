import React from 'react';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';

/**
 * Declare radio input
 * @param props Properties
 */

const useStyle = makeStyles((theme) => ({
  radioCell: {
    '& .MuiRadio-root ': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function DeclareRadio(props) {
  props = Object.assign(props);
  const classes = useStyle();
  const radioStyle = `${classes.radioCell} ${props.centerElement ? 'cellTextCenter' : ''}`;
  return (
    <div className={radioStyle}>
      <Radio {...props} />
    </div>
  );
}
