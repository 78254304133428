import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import StatusCell from '../../../components/StatusCell';
import { useDispatch, useSelector } from 'react-redux';
import SearchTextBox from '../../../components/SearchTextBox';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import { getAllDepartments } from '../../../redux/actions/departmentActions';
import { appRoutes } from '../../../helpers/routes';

export default function DepartmentList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const { departmentList } = useSelector((state) => state.departments);
  const { selectedOrg } = useSelector((state) => state.allOrgs);
  const [searchKey, setSearchKey] = useState('');
  const [filteredDepartmentList, setFilteredDepartmentList] = useState(departmentList);

  useEffect(() => {
    var filteredList = departmentList;
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.type.toLowerCase().startsWith(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = departmentList.filter((o) => filters.some((fn) => fn(o)));
    }
    setFilteredDepartmentList(filteredList);
  }, [departmentList, searchKey]);

  useEffect(() => {
    if (departmentList.length < 1) {
      dispatch(getAllDepartments({ orgId }));
      setSearchKey('');
    }
  }, []);

  const navigateToCreateCollegeDepartments = () => {
    navigate(generatePath(appRoutes.catalog.COLLEGE_DEPARTMEMT_CREATE, { orgId }));
  };

  const navigateToEditCollegeDepartment = (data) => {
    navigate(
      generatePath(appRoutes.catalog.COLLEGE_DEPARTMEMT_EDIT, {
        orgId: selectedOrg.id,
        departmentId: data.id,
      }),
    );
  };

  const columns = [
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'type',
      label: t('type'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => t(capitalize(value)),
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = departmentList.findIndex((x) => x.id === value);
                navigateToEditCollegeDepartment(departmentList[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('collegeAndDepartments')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            onClick={() => {
              navigateToCreateCollegeDepartments();
            }}>
            {t('New College / Department')}
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredDepartmentList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
