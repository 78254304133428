import OrgError from '../../../error/OrgError';
import ReportService from '../../../services/ReportService';

const reportService = new ReportService();

export function* requestCreateStatusHistoryReport(params) {
  if (!params) {
    throw OrgError.orgRequired();
  }

  const { orgId, advisors, studentFirstTerms, startDate, endDate } = params;
  return yield reportService.postPathwayStatusHistoryReport({
    orgId,
    advisors,
    studentFirstTerms,
    startDate,
    endDate,
  });
}

export function* requestCreateCourseProjectionReport(params) {
  const { orgId, requestBody } = params;
  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield reportService.postCourseProjectionReport(orgId, requestBody);
}

export function* requestGenerateStatusHistoryReport(params) {
  const { orgId, requestBody } = params;

  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield reportService.postGenerateStatusHistoryReport(orgId, requestBody);
}
