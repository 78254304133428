import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { termCardStyles } from '../../styles/termCardStyles';
import { AddBoxRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  needMoreTermContainer: {
    flex: 1,
    marginTop: theme.spacing(9),
  },
}));
function TermCardAddNewTerm(props) {
  const { t } = useTranslation();
  const termCardClasses = termCardStyles();
  const cardClasses = useStyles();
  const { addTermCallBack, orderedPathway } = props;
  const termCardContentStyle = `${termCardClasses.termCardContent} ${cardClasses.needMoreTermContainer}`;
  const addNewTermCard = () => {
    addTermCallBack(orderedPathway.slice(-1)[0], orderedPathway.length - 1, true);
  };

  return (
    <Box className={termCardClasses.termCard}>
      <Box className={termCardContentStyle}>
        <Box className={termCardClasses.termCardButtonContainer}>
          <Box className={termCardClasses.termCardAddNewIcon} sx={{ display: 'flex' }}>
            <AddBoxRounded onClick={addNewTermCard} color="secondary" sx={{ cursor: 'pointer' }} />
            <Typography className={termCardClasses.termCardAddNewText}>
              {t('Need another term') + '?'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TermCardAddNewTerm;
