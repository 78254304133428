import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import PrinterIcon from '@mui/icons-material/Print';
import _ from 'lodash';
import { getProgramMappingData } from '../redux/actions/programMapActions';
import { useParams } from 'react-router-dom';
import { setLoadingStatus } from '../redux/actions/loadingActions';
import { PdfTemplateWrapper } from './pdfTemplate';
import { useTranslation } from 'react-i18next';
import { getStatuses } from '../redux/actions/transferEquivalencyActions';
import { getTransferEvaluationForPrint } from '../redux/actions/transferEvaluationActions';
import { getTodaysDate } from '../processes/generateExcel';

export const TEvPrintPdfComponent = ({ evaluation, programMap }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { selectedOrg, orgs } = useSelector((state) => state.allOrgs);
  const { teqStatusesEnum, teqStatuses } = useSelector((state) => state.transferEquivalency);
  const { currentSelectedStudent } = useSelector((state) => state.students);
  const { latestCatalog } = useSelector((state) => state.allCatalogs);
  const [orgPdfLogo, setOrgPdfLogo] = React.useState(null);
  const componentRef = React.useRef(null);
  const [mappedData, setMappedData] = React.useState(null);
  const [equivalencyPrintData, setEquivalencyPrintData] = React.useState([]);
  const [equivalencyPrintDataLoaded, setEquivalencyPrintDataLoaded] = React.useState(false);
  const [transferredCourses, setTransferredCourses] = React.useState([]);
  const [contentAvailable, setContentAvailable] = React.useState(false);
  const DEFAULT_LOGO = window.origin + '/logo.png';
  const HTTP_STATUS_SUCCESS = 200;

  const setProgramMapData = (data) => {
    setMappedData(data);
  };

  const processPrintData = (data) => {
    setEquivalencyPrintData(Object.values(data));
    setEquivalencyPrintDataLoaded(true);
  };

  React.useEffect(() => {
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    }
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(equivalencyPrintData)) {
      processTransferredCourses();
    }
  }, [equivalencyPrintData]);

  React.useEffect(() => {
    if (mappedData != null && equivalencyPrintDataLoaded) {
      setContentAvailable(true);
    }
  }, [mappedData, equivalencyPrintDataLoaded]);

  React.useEffect(() => {
    if (componentRef.current != null) {
      handlePrint();
    }
  }, [mappedData, componentRef, contentAvailable]);

  React.useEffect(async () => {
    if (!_.isNil(selectedOrg.logo)) {
      await fetch(selectedOrg.logo, { method: 'HEAD', cache: 'no-store' })
        .then((data) => {
          if (data.status === HTTP_STATUS_SUCCESS) {
            setOrgPdfLogo(selectedOrg.logo);
          } else {
            setOrgPdfLogo(DEFAULT_LOGO);
          }
        })
        .catch(() => {
          setOrgPdfLogo(DEFAULT_LOGO);
        });
    } else {
      setOrgPdfLogo(DEFAULT_LOGO);
    }
  }, [selectedOrg]);

  const handleAfterPrint = React.useCallback(() => {
    dispatch(setLoadingStatus(false));
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    dispatch(setLoadingStatus(true));
  }, []);

  const getData = () => {
    return new Promise((resolve) => {
      dispatch(getProgramMappingData(orgId, programMap?.id, '', setProgramMapData));
      dispatch(
        getTransferEvaluationForPrint(
          orgId,
          currentSelectedStudent.id,
          evaluation.id,
          processPrintData,
        ),
      );
      resolve();
    });
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const getDocumentTitle = () => {
    const studentFullname = currentSelectedStudent.name;
    const poName = programMap.optionName;
    const todaysDate = getTodaysDate();

    return `${studentFullname}-${poName}-${todaysDate}`;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: getDocumentTitle(),
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const processTransferredCourses = () => {
    const data = equivalencyPrintData.map((data) => {
      const university = orgs.find(({ id }) => id === data.additionalInfo.sourceLa.orgId);
      return {
        ...data,
        additionalInfo: {
          ...data.additionalInfo,
          sourceLa: {
            ...data.additionalInfo.sourceLa,
            university,
          },
        },
      };
    });

    setTransferredCourses(data);
  };

  return (
    <>
      <PrinterIcon
        fontSize="medium"
        color="secondary"
        sx={{
          marginLeft: '10px',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.stopPropagation();
          getData();
        }}
      />
      {contentAvailable && (
        <div style={{ display: 'none' }}>
          <PdfTemplateWrapper
            ref={componentRef}
            selectedOrg={selectedOrg}
            orgPdfLogo={orgPdfLogo}
            student={currentSelectedStudent}
            catalog={latestCatalog}
            evaluation={evaluation}
            mappedData={mappedData}
            transferredCourses={transferredCourses}
            translate={t}
            teqStatusesEnum={teqStatusesEnum}
          />
        </div>
      )}
    </>
  );
};
