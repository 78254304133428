import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareTextField from '../DeclareTextField';
import DeclaredSelect from '../DeclareSelect';
import { courseLevels } from '../../helpers/appConstants';

export default function LearningActivitiesWithLevelGreaterThanRule(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [number, setNumber] = useState(null);
  const [level, setLevel] = useState(null);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = isRequirement, selectedLevel = level) => {
    const data = {
      isRequirement: isReq,
      number: Number(number),
      level: selectedLevel,
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  useEffect(() => {
    setNumber(props.data.number || '');
    setIsRequirement(props.data.isRequirement || false);
    setLevel(props.data.level || '');
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DeclareTextField
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          onBlur={() => {
            processChange();
          }}
          inputlabel={`${t('Number of Learning Activities')}*`}
          variant="outlined"
          placeholder={t('learningActivities')}
          value={number}
          name="number"
          type="number"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={6}>
        <DeclaredSelect
          inputlabel={`${t('level')}*`}
          disabled={!selectedItemEditable}
          margin="dense"
          value={level}
          name="level"
          displayEmpty={true}
          renderValue={(value) => (value !== '' ? value : t('Please Select'))}
          onChange={(e) => {
            setLevel(e.target.value);
            processChange(isRequirement, e.target.value);
          }}>
          {courseLevels.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {t(item.label)}
            </MenuItem>
          ))}
        </DeclaredSelect>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirement}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirement(e.target.checked);
                processChange(e.target.checked);
              }}
            />
          }
          label={t('Requirement')}
        />
      </Grid>
    </Grid>
  );
}
