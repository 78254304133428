import StudentService from '../../../services/StudentService';
import OrgError from '../../../error/OrgError';

const studentService = new StudentService();

export function* requestGetStudents(payload) {
  const { orgId, query } = payload;
  return yield studentService.getAll(orgId, query);
}

export function* requestPostTransferEvaluation(payload) {
  const { org, studentId, data } = payload;

  if (!org) {
    throw OrgError.orgRequired();
  }

  return yield studentService.createTransferEvaluation(org, studentId, data);
}

export function* requestPutTransferEvaluation(payload) {
  const { org, studentId, evaluationId, data } = payload;
  return yield studentService.putTransferEvaluation(org, studentId, evaluationId, data);
}

export function* requestGetStudentsStatusList(param) {
  return yield studentService.getAllStudentStatus(param);
}

export function* requestGetStudentTransferEvaluations(param) {
  const { orgId, studentId } = param;
  return yield studentService.getStudentTransferEvaluations(orgId, studentId);
}

export function* requestGetTransferEvaluationStatusList(param) {
  const { orgId, studentId } = param;
  return yield studentService.getGetTransferEvaluationStatusList(orgId, studentId);
}

export function* requestGetStudentTeqAssignments(param) {
  const { orgId, student, fetchAllCatalogs } = param;
  return yield studentService.getStudentTeqAssignments(orgId, student, fetchAllCatalogs);
}
export function* requestDeleteStudentTeqAssignment(payload) {
  const { org, studentId, transferEquivalencyId } = payload;
  if (!org) {
    throw OrgError.orgRequired();
  }

  return yield studentService.deleteTeqAssignments(org, studentId, transferEquivalencyId);
}

export function* requestGenerateStudentPathway(param) {
  const { orgId, studentId, body } = param;
  return yield studentService.generateStudentPathway(orgId, studentId, body);
}

export function* requestPatchStudent(param) {
  const { orgId, studentId, body } = param;
  return yield studentService.updateStudent(orgId, studentId, body);
}

export function* requestSearchStudents(param) {
  const { orgId, searchKey } = param;
  return yield studentService.searchStudents(orgId, searchKey);
}

export function* requestPutStudentPathway(payload) {
  const { orgId, studentId, programOption, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield studentService.updateStudentPathway(orgId, studentId, programOption, body);
}

export function* requestUpdateStudentByStudentTeqStatus(payload) {
  const { orgId, studentId, transferEquivalencyId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield studentService.updateStudentByStudentTeqStatus(
    orgId,
    studentId,
    transferEquivalencyId,
    body,
  );
}

export function* requestDeleteStudentByStudentTeqStatus(payload) {
  const { orgId, studentId, transferEquivalencyId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield studentService.deleteStudentByStudentTeqStatus(
    orgId,
    studentId,
    transferEquivalencyId,
    body,
  );
}
