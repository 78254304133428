import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { Box, Button, Container, Typography, Grid, TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { appRoutes } from '../../../helpers/routes';
import DeclareDataTable from '../../../components/DeclareDataTable';
import DeclaredSelect from '../../../components/DeclareSelect';
import {
  getAcademicYearsWithTermInstances,
  updateTermInstances,
} from '../../../redux/actions/termActions';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import _ from 'lodash';
import { termsScheduleDateTypes } from '../../../helpers/appConstants';
import { setError } from '../../../redux/actions/notificationActions';
import DeclareDatePicker from '../../../components/DeclareDatePicker';

export default function TermsScheduleList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const orgs = useSelector((store) => store.allOrgs);
  const { termList, academicYears } = useSelector((state) => state.terms);
  const [breadCrumbName] = useState(t('Schedule'));
  const [academicYear, setAcademicYear] = useState(null);
  const [termsScheduleListFromDB, setTermsScheduleListFromDB] = useState([]);
  const [termsScheduleToDisplay, setTermsScheduleToDisplay] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedDateAndType, setSelectedDateAndType] = useState(null);

  useEffect(() => {
    if (_.isEmpty(termList)) {
      dispatch(getTerms(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }
  }, [termList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty(academicYears)) {
      const currentTermId = termList.find(({ currentTerm }) => currentTerm)?.id;
      const currentYear = new Date().getFullYear();
      const year =
        academicYears.find(({ termInstances }) =>
          termInstances.some(
            ({ termId, calendarYear }) => termId === currentTermId && calendarYear === currentYear,
          ),
        )?.startingYear || currentYear;
      showTermInstances(year);
    }
  }, [academicYears]);

  const showTermInstances = (year) => {
    const termInstances =
      academicYears.find(({ startingYear }) => startingYear == year)?.termInstances || [];
    setTermsScheduleListFromDB(getDatesFromTermsSchedule(termInstances));
    setTermsScheduleToDisplay(termInstances.map((data) => ({ ...data })));
    setAcademicYear(year);
  };

  const backToTerms = () => {
    navigate(generatePath(appRoutes.catalog.TERMS_LIST, { orgId: orgs.selectedOrg.id }));
  };

  const getDatesFromTermsSchedule = (termsSchedule) =>
    termsSchedule.map((aSchedule) => {
      return { startDate: aSchedule.startDate, endDate: aSchedule.endDate };
    });

  const handleChangeAcademicYear = (e) => {
    if (_.isEqual(termsScheduleListFromDB, getDatesFromTermsSchedule(termsScheduleToDisplay))) {
      showTermInstances(e.target.value);
    } else {
      dispatch(
        setError({
          message: t('You must save the changes before switching years.'),
        }),
      );
    }
  };

  const columns = [
    {
      name: 'displayName',
      label: t('name'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
        setCellProps: () => ({
          style: { width: '60%' },
        }),
      },
    },
    {
      name: 'startDate',
      label: t('Start Date'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <DeclareDatePicker
              value={!_.isNil(value) ? value : null}
              onChange={(date) => handleDateChange(date, termsScheduleDateTypes.START_DATE)}
              renderInput={(params) => <TextField {...params} />}
            />
          );
        },
      },
    },
    {
      name: 'endDate',
      label: t('End Date'),
      options: {
        filter: true,
        sort: false,
        align: 'right',
        customBodyRender: (value) => {
          return (
            <DeclareDatePicker
              value={!_.isNil(value) ? value : null}
              onChange={(date) => handleDateChange(date, termsScheduleDateTypes.END_DATE)}
              renderInput={(params) => <TextField {...params} />}
            />
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onCellClick: (colData, cellMeta) => {
      const selectedTerm = termsScheduleToDisplay[cellMeta.rowIndex];
      setSelectedTerm(selectedTerm);
    },
  };

  const handleDateChange = (e, selectedDateType) => {
    setSelectedDateAndType({ date: e, type: selectedDateType });
  };

  useEffect(() => {
    if (!_.isNil(selectedDateAndType)) {
      const aTerm = termsScheduleToDisplay.find((aTerm) => aTerm.id === selectedTerm.id);
      if (selectedDateAndType.type === termsScheduleDateTypes.START_DATE) {
        aTerm.startDate = selectedDateAndType.date;
      } else if (selectedDateAndType.type === termsScheduleDateTypes.END_DATE) {
        aTerm.endDate = selectedDateAndType.date;
      }
      setTermsScheduleToDisplay(termsScheduleToDisplay);
      setSelectedDateAndType(null);
    }
  }, [selectedDateAndType]);

  const handleUpdateTermsSchedule = () => {
    const scheduledTermsWithCalenderYear = termsScheduleToDisplay.map((aTerm) => {
      return {
        calendarYear: aTerm.calendarYear,
        startDate: aTerm.startDate,
        endDate: aTerm.endDate,
        id: aTerm.id,
      };
    });

    dispatch(
      updateTermInstances(
        orgs.selectedOrg.id,
        academicYear,
        { scheduledTerms: scheduledTermsWithCalenderYear },
        backToTerms,
      ),
    );
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[{ name: t('Terms ') }, breadCrumbName]} />
          <Grid>
            <DeclaredSelect
              margin="dense"
              value={academicYear}
              name="year"
              color="white"
              onChange={handleChangeAcademicYear}>
              {academicYears.map((item, index) => (
                <MenuItem value={item.startingYear} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={termsScheduleToDisplay} columns={columns} options={options} />
        </Box>
        <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={handleUpdateTermsSchedule}
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              marginRight: '20px',
            }}>
            {t('update')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              color: '#fff',
            }}
            onClick={() => {
              backToTerms();
            }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
