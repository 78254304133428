import { actionTypes } from '../constants/actionTypes';

export const setLearningActivities = (learningActivities) => {
  return {
    type: actionTypes.SET_LEARNING_ACTIVITIES,
    payload: learningActivities,
  };
};

export const setLearningActivitiesTransferEquivalencies = (learningActivities) => {
  return {
    type: actionTypes.SET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES,
    payload: learningActivities,
  };
};

export const getLearningActivities = (params) => {
  return {
    type: actionTypes.GET_LEARNING_ACTIVITIES,
    payload: params,
  };
};

export const getLearningActivitiesTransferEquivalencies = (params) => {
  return {
    type: actionTypes.GET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES,
    payload: params,
  };
};

export const setLearningActivitiesForCatalog = (learningActivities) => {
  return {
    type: actionTypes.SET_LEARNING_ACTIVITIES_FOR_CATALOG,
    payload: learningActivities,
  };
};

export const getLearningActivitiesForCatalog = (org, catalog) => {
  return {
    type: actionTypes.GET_LEARNING_ACTIVITIES_FOR_CATALOG,
    payload: { org, catalog },
  };
};

export const getTerms = (params) => {
  return {
    type: actionTypes.GET_TERMS,
    payload: params,
  };
};

export const setTerms = (terms) => {
  return {
    type: actionTypes.SET_TERMS,
    payload: terms,
  };
};

export const setLearningActivity = (data) => {
  return {
    type: actionTypes.SET_LEARNING_ACTIVITY,
    payload: data,
  };
};

export const getLearningActivity = (org, data, navigate) => {
  return {
    type: actionTypes.GET_LEARNING_ACTIVITY,
    payload: { org, data },
    navigate: navigate,
  };
};

export const postLearningActivity = (org, data, navigate, shouldLoadList = true) => {
  return {
    type: actionTypes.POST_LEARNING_ACTIVITY,
    payload: { org, data },
    navigate,
    shouldLoadList,
  };
};

export const updateLearningActivity = (org, id, data, callback) => {
  return {
    type: actionTypes.PATCH_LEARNING_ACTIVITY,
    payload: { org, id, data },
    callback,
  };
};

export const putLearningActivity = (org, id, data, navigate) => {
  return {
    type: actionTypes.PUT_LEARNING_ACTIVITY,
    payload: { org, id, data },
    navigate: navigate,
  };
};

export const getAllClassSchedules = (payload) => {
  return {
    type: actionTypes.GET_CLASS_SCHEDULES,
    payload,
  };
};

export const setClassSchedules = (payload) => {
  return {
    type: actionTypes.SET_CLASS_SCHEDULES,
    payload,
  };
};

export const createClassSchedule = (org, laId, data, navigate) => {
  return {
    type: actionTypes.CREATE_CLASS_SCHEDULE,
    payload: { org, laId, data },
    navigate: navigate,
  };
};

export const modifyClassSchedule = (org, laId, csId, data, navigate) => {
  return {
    type: actionTypes.UPDATE_CLASS_SCHEDULE,
    payload: { org, laId, csId, data },
    navigate: navigate,
  };
};

export const resetClassScheduleList = (params) => {
  return {
    type: actionTypes.RESET_CLASS_SCHEDULE_LIST,
    payload: params,
  };
};
