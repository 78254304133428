import { Box, Typography } from '@mui/material';
import React from 'react';
import { transferEquivalencyRawStyles } from '../../../components/styles/transferEquivalencyRowStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeclareLearningActivityCard from '../../../components/DeclareLearningActivityCard';
import DeclareToolTip from '../../../components/DeclareToolTip';
import { Description, Edit, NewReleases, CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import theme from '../../../theme';
import TransferEvaluationToolTip from './component/TransferEvaluationToolTip';
import {
  getStudentTeqAssignments,
  updateStudentByStudentTeqStatus,
  deleteStudentByStudentTeqStatus,
} from '../../../redux/actions/studentActions';
import { REQUIREMENT_TYPE } from '../../../helpers/appConstants';

export default function TransferOptionLA(props) {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const {
    matchingInProgress,
    OnClickTransferOptionCards,
    matchedOptions,
    disabledCourses,
    equivalency,
    groupId,
    code,
    name,
    units,
    transferEquivalency = null,
    handleClickEquivalency = null,
    unUsedCourses,
    allCourseReq,
  } = props;
  const classes = transferEquivalencyRawStyles(!transferEquivalency && { option: true });
  const { teqStatusesEnum, teqStatuses } = useSelector((state) => state.transferEquivalency);
  const { t } = useTranslation();
  const { status, destinationEqList, id, note, studentStatus } = equivalency || {
    status: teqStatusesEnum?.EQUIVALENCY_REVIEW_INPROGRESS,
    destinationEqList: [],
    note: null,
  };
  const { currentSelectedStudent } = useSelector((state) => state.students);

  const matchedStyle = {
    rowItems: {
      color: theme.palette.white.main,
    },
    rowItemsUnits: {
      color: theme.palette.white.main,
    },
    laWrapper: {
      background: theme.palette.primary.main,
    },
  };

  const refusedStyle = {
    rowItems: {
      color: theme.palette.white.main,
    },
    rowItemsUnits: {
      color: theme.palette.white.main,
    },
    laWrapper: {
      background: theme.palette.error.main,
    },
  };

  const disabledStyle = {
    rowItems: {
      color: theme.palette.grey.main,
    },
    rowItemsUnits: {
      color: theme.palette.grey.main,
    },
    laWrapper: {
      background: theme.palette.disabledCourse.main,
    },
  };

  const updateStudentTransferEquivalencyStatus = (transferEquivalencyId, statusCode) => {
    let body = {
      statusCode,
    };

    dispatch(
      updateStudentByStudentTeqStatus(
        orgId,
        currentSelectedStudent.id,
        transferEquivalencyId,
        body,
        () => dispatch(getStudentTeqAssignments(orgId, currentSelectedStudent)),
      ),
    );
  };

  const deleteStudentTransferEquivalencyStatus = (transferEquivalencyId, statusCode) => {
    let body = {
      statusCode,
    };

    dispatch(
      deleteStudentByStudentTeqStatus(
        orgId,
        currentSelectedStudent.id,
        transferEquivalencyId,
        body,
        () => dispatch(getStudentTeqAssignments(orgId, currentSelectedStudent)),
      ),
    );
  };

  const getTransferEquivalencyReviewProperties = () => {
    const { code: approvedStatusCode } = teqStatuses.find(
      ({ type }) => type === teqStatusesEnum.APPROVED,
    );
    const { code: refusedStatusCode } = teqStatuses.find(
      ({ type }) => type === teqStatusesEnum.REFUSED,
    );

    const properties = {
      [`${teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW}`]: {
        color: 'yellow',
        titleText: t('Approval required for student'),
        buttonProps: [
          {
            text: t('Approved'),
            onClickFn: () => {
              updateStudentTransferEquivalencyStatus(id, approvedStatusCode);
            },
          },
          {
            text: t('Refused'),
            onClickFn: () => updateStudentTransferEquivalencyStatus(id, refusedStatusCode),
          },
        ],
      },
      [`${teqStatusesEnum.APPROVED}`]: {
        color: 'primary',
        titleText: t('Students transfer option is approved'),
        buttonProps: [
          {
            text: t('Pending'),
            onClickFn: () => {
              deleteStudentTransferEquivalencyStatus(id, approvedStatusCode);
            },
          },
          {
            text: t('Refused'),
            onClickFn: () => updateStudentTransferEquivalencyStatus(id, refusedStatusCode),
          },
        ],
      },
      [`${teqStatusesEnum.REFUSED}`]: {
        color: 'error',
        titleText: t('Students transfer option is refused'),
        buttonProps: [
          {
            text: t('Approved'),
            onClickFn: () => {
              updateStudentTransferEquivalencyStatus(id, approvedStatusCode);
            },
          },
          {
            text: t('Pending'),
            onClickFn: () => deleteStudentTransferEquivalencyStatus(id, refusedStatusCode),
          },
        ],
      },
      [`${teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS}`]: {
        color: 'warning',
        titleText: t(
          'Equivalency review in progress, to update the status edit the transfer equivalency.',
        ),
        buttonProps: [],
      },
    };

    let studentReviewStatus = status?.type;
    if (!_.isNil(studentStatus)) {
      studentReviewStatus = studentStatus?.status?.type;
    }

    return properties[studentReviewStatus];
  };

  const showTransferEquivalencyIcon = () => {
    const validTeqStatus = [
      teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW,
      teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS,
    ];

    if (!validTeqStatus.includes(status?.type)) return null;

    const { color, titleText, buttonProps } = getTransferEquivalencyReviewProperties(id, status);

    return (
      <TransferEvaluationToolTip
        Icon={NewReleases}
        iconProps={{ color }}
        titleText={titleText}
        btnArray={buttonProps}
        btnContainerStyle={{ justifyContent: 'space-around' }}
      />
    );
  };

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Box flex={1} className={classes.arrowContainer}>
        {!_.isEmpty(matchingInProgress?.transferOptions) &&
          matchingInProgress.transferOptions.find(({ eqid }) => eqid === id)?.clicked && (
            <CheckCircleOutline className={classes.actionItems} color="primary" fontSize="small" />
          )}
      </Box>
      <Box
        sx={{
          border: !_.isEmpty(matchingInProgress?.transferOptions?.filter((tf) => tf.eqid === id))
            ? `2px solid ${theme.palette.primary.main} !important`
            : `1px solid ${theme.palette.primary.main} !important`,
        }}
        className={classes.courseContainer}
        onClick={(event) => {
          event.stopPropagation();
          OnClickTransferOptionCards(destinationEqList);
        }}>
        {destinationEqList.map(({ id, units, transferEquivalencyId, type }, index) => {
          let customStyle = {};
          const isCourseMatched = matchedOptions.find(({ transferOptionId }) =>
            transferOptionId.find((tOId) => tOId === `${transferEquivalencyId}_${id}`),
          );
          const isCourseDisabled = disabledCourses.find(
            ({ transferEquivalencyId: teqId, id: courseId }) =>
              transferEquivalencyId === teqId && id === courseId,
          );
          const isCourseRefused = unUsedCourses.includes(id);
          if (isCourseDisabled) {
            customStyle = disabledStyle;
          }
          if (isCourseRefused) {
            customStyle = refusedStyle;
          }
          if (isCourseMatched) {
            customStyle = matchedStyle;
          }

          const requirementCourseOrGroup = allCourseReq.find((res) => res.id === id);
          return (
            <DeclareLearningActivityCard
              key={index}
              units={units}
              name={requirementCourseOrGroup?.name}
              code={
                requirementCourseOrGroup?.type !== REQUIREMENT_TYPE.Course
                  ? t('Req.')
                  : requirementCourseOrGroup?.code
              }
              id={id}
              type={type}
              customStyle={customStyle}
            />
          );
        })}
      </Box>
      <Box
        className={classes.actionItemContainer}
        sx={{
          flex: 1,
        }}>
        {status?.type == teqStatusesEnum?.APPROVED_WITH_NOTE && (
          <DeclareToolTip
            title={
              <Box>
                <Typography color="common.black" fontWeight={800} marginLeft={3}>
                  {note?.message}
                </Typography>
              </Box>
            }>
            <Description
              className={classes.actionItems}
              color="primary"
              fontSize={!_.isNil(transferEquivalency) ? 'medium' : 'small'}
            />
          </DeclareToolTip>
        )}
        {(status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW ||
          status?.type === teqStatusesEnum?.EQUIVALENCY_REVIEW_INPROGRESS) &&
          showTransferEquivalencyIcon()}
        {!_.isNull(transferEquivalency) && (
          <Edit
            className={classes.editIcon}
            color="secondary"
            fontSize="small"
            onClick={() => handleClickEquivalency(code, groupId, name, units, equivalency)}
          />
        )}
      </Box>
    </Box>
  );
}
