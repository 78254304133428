import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

export function useDeclareForm(initValues) {
  const [values, setValues] = useState(initValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    handleInputChange,
  };
}

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
      color: 'inherit',
    },
    '& .MuiFormControl-root': {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .MuiFormHelperText-root': {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.2),
    },
    '& .MuiAutocomplete-input': {
      padding: theme.spacing(0.5),
    },
    '& .MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: theme.spacing(0.5),
      },
    },
    '& .MuiGrid-root': {
      '& .right': {
        paddingLeft: '20px',
      },
      '& .left': {
        paddingRight: '20px',
      },
    },
  },
}));

export function DeclareForm(props) {
  const classes = useStyle();

  return <form className={classes.root}>{props.children}</form>;
}
