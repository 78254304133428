import React from 'react';
import { FormControl, FormHelperText, Select, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import theme from '../theme';

/**
 * Declare select component
 * @param props Properties
 */

const useStyle = makeStyles((theme) => ({
  sm: {
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1.3),
      paddingBottom: theme.spacing(1.3),
    },
  },
}));

const DeclaredSelect = (props) => {
  props = Object.assign(props);
  const display = !_.isUndefined(props.display) ? props.display : true;
  const disable = !_.isUndefined(props.disable) ? props.disable : false;
  const classes = useStyle();

  const styleClasses = `${props.rootEnable ? 'root' : ''} ${props.sm ? classes.sm : ''}`;
  return display ? (
    <>
      <InputLabel sx={props.inputLabelStyles}>{props.inputlabel}</InputLabel>
      <FormControl
        {...(props.error && { error: true })}
        sx={{
          ...props.sx,
          background: props.color,
          borderRadius: '10px',
          border: props.outlineValue || 0,
          borderColor: theme.palette.primary.main,
        }}
        className={styleClasses}>
        <Select disabled={disable} {...props}>
          {props.children}
        </Select>

        <InputLabel margin="dense" variant="filled" sx={{ fontSize: '0.9rem', lineHeight: 0.8 }}>
          {!props.selected ? props.placeholder : ''}
        </InputLabel>
        {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
      </FormControl>
    </>
  ) : null;
};

export default DeclaredSelect;
