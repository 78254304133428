import { actionTypes } from '../constants/actionTypes';

const initialState = {
  userInfo: {},
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_USER_INFO:
      return { ...state, userInfo: payload };
    default:
      return state;
  }
}
