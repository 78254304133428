import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { Box, Button, Container, IconButton, Typography, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import StatusCell from '../../../components/StatusCell';
import { useDispatch, useSelector } from 'react-redux';
import SearchTextBox from '../../../components/SearchTextBox';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import { appRoutes } from '../../../helpers/routes';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import DeclareRadio from '../../../components/DeclareRadio';
import { updateCalendarYearStart, updateCurrentTerm } from '../../../redux/actions/termActions';
import { dataTableStyles } from '../../../components/styles/dataTableStyles';

export default function DepartmentList() {
  const dataTableClasses = dataTableStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const termList = useSelector((state) => state.terms.termList);
  const selectedOrg = useSelector((state) => state.allOrgs.selectedOrg);
  const [searchKey, setSearchKey] = useState('');
  const [filteredTermList, setFilteredTermList] = useState(termList);
  const [currentTerm] = useState(true);

  useEffect(() => {
    var filteredList = termList;
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = termList.filter((o) => filters.some((fn) => fn(o)));
    }
    setFilteredTermList(filteredList);
  }, [termList, searchKey]);

  useEffect(() => {
    if (termList.length < 1) {
      dispatch(getTerms(orgId));
      setSearchKey('');
    }
  }, []);

  const navigateToAddNewTerm = () => {
    navigate(generatePath(appRoutes.catalog.TERM_CREATE, { orgId }));
  };

  const navigateToEditTerm = (data) => {
    navigate(generatePath(appRoutes.catalog.TERM_EDIT, { orgId: selectedOrg.id, termId: data.id }));
  };

  const navigateToReOrderTerms = () => {
    navigate(generatePath(appRoutes.catalog.TERM_REORDER, { orgId }));
  };

  const navigateToScheduleTerms = () => {
    navigate(generatePath(appRoutes.catalog.TERMS_SCHEDULE, { orgId }));
  };

  const handleChange = (event) => {
    const data = {
      id: event.target.value,
    };
    dispatch(updateCurrentTerm(orgId, data));
  };

  const changeCalendarYearStart = (event) => {
    const data = {
      id: event.target.value,
    };
    dispatch(updateCalendarYearStart(orgId, data));
  };

  const columns = [
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'order',
      label: t('Academic Year Order'),
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          className: dataTableClasses.centeredHeader,
        }),
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }} className="cellTextCenter">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'id',
      label: t('Calendar Year Start'),
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          className: dataTableClasses.centeredHeader,
        }),
        customBodyRender: (value) => {
          let index = termList.findIndex((x) => x.id === value);
          return (
            <DeclareRadio
              centerElement={true}
              checked={termList[index].calendarYearStart}
              onChange={changeCalendarYearStart}
              value={value}
              name="calendarYearStart"
            />
          );
        },
      },
    },
    {
      name: 'id',
      label: t('Current'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          let index = termList.findIndex((x) => x.id === value);
          return (
            <DeclareRadio
              checked={currentTerm === termList[index].currentTerm}
              onChange={handleChange}
              value={value}
              name="currentTerm"
            />
          );
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = termList.findIndex((x) => x.id === value);
                navigateToEditTerm(termList[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Terms ')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <Grid>
            {termList.length > 0 ? (
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                  fontWeight: 300,
                  marginRight: '20px',
                }}
                onClick={() => {
                  navigateToScheduleTerms();
                }}>
                {t('Schedule')}
              </Button>
            ) : null}
            {termList.length > 0 ? (
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                  fontWeight: 300,
                  marginRight: '20px',
                }}
                onClick={() => {
                  navigateToReOrderTerms();
                }}>
                {t('Reorder')}
              </Button>
            ) : null}
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              sx={{
                height: '50px',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontWeight: 300,
              }}
              onClick={() => {
                navigateToAddNewTerm();
              }}>
              {t('New Term')}
            </Button>
          </Grid>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredTermList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
