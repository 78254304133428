import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import {
  AppBar,
  Stack,
  MenuItem,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { generatePath, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../redux/actions/auth';
import SearchTextBox from '../SearchTextBox';
import DeclaredSelect from '../DeclareSelect';
import { studentListQuery, urlParamsToFilterStudentList } from '../../helpers/appConstants';
import theme from '../../theme';
import {
  getStudents,
  getStudentTeqAssignments,
  resetCompletedCourses,
  setCurrentSelectedStudent,
  setQueryStudentListOption,
} from '../../redux/actions/studentActions';
import {
  getStudentListViewOption,
  saveTableOptions,
  getIdentityEmail,
  getQueryParams,
  getCurrentCatalog,
} from '../../helpers/studentsHelper';
import { convertToQueryString, createUrlWithQueryParams } from '../../helpers/common';
import { setCompletedCourseReq } from '../../redux/actions/transferEvaluationActions';
//'Profile', 'Account', 'Dashboard' removed for production and UAT env
const settings = ['Logout'];

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoLg: {
    cursor: 'pointer',
    display: 'none',
    textTransform: 'uppercase',
    ' img': {
      float: 'left',
      marginRight: '10px',
      width: '20px',
    },
  },
  logoText: {
    display: 'inline-block',
    fontWeight: 900,
    lineHeight: '2.2 !important',
  },
  logoImg: {
    float: 'left',
    marginRight: '4px',
    width: '40px',
  },
  logoSm: {
    display: 'block',
  },
  input: {
    color: 'white',
  },
  cancel: {},
  icons: {
    alignItems: 'center',
    display: (props) => (props.open ? 'none' : 'flex'),
  },
}));

{
  /**
  
  Header Props:
  menuList - object array of string and icon
  showSearch - boolean
  */
}

const Header = ({ menuList, hideSearchBox = true, dashboardPath }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const org = useParams();
  const { pathname } = useLocation();
  const orgId = Object.values(org)[0].split('/')[0];
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [initials, setInitials] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const [resultsHidden, setResultsHidden] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const programMaps = useSelector((state) => state.programMaps.programMapList);
  const { studentQueryOption } = useSelector((state) => state.students);
  const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
  const { selectedOrg } = useSelector((state) => state.allOrgs);
  const [outlineValue, setOutlineValue] = React.useState(0);
  const studentListOption = Object.values(studentListQuery).map((value) =>
    _.startCase(value.split('_').join(' ')),
  );
  const studentListLocalStorageKey = 'tableOption';

  useEffect(() => {
    if (_.isEmpty(userInfo)) {
      dispatch(getUserInfo());
    }

    if (_.isEmpty(studentQueryOption)) {
      const options = getStudentListViewOption(studentListLocalStorageKey, studentQueryOption);
      const queryStudentOption = _.isEmpty(options.queryStudent)
        ? studentListOption[1]
        : options.queryStudent;

      dispatch(setQueryStudentListOption(queryStudentOption));
    }
  }, []);

  const debounceHandler = useRef(
    _.debounce((orgId, key) => dispatch(getStudents(orgId, key, handleStudentSearchResult)), 400),
  ).current;

  useEffect(() => {
    return () => {
      debounceHandler.cancel();
    };
  }, [debounceHandler]);

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      const firstNameInitial = userInfo.family_name.charAt(0).toUpperCase();
      const givenNameInitial = userInfo.given_name.charAt(0).toUpperCase();
      setInitials(`${givenNameInitial}${firstNameInitial}`);
    }
  }, [userInfo]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutUser = () => {
    window.localStorage.removeItem('token');
    window.location = appRoutes.auth.LOGIN;
  };

  const handleStudentSearchResult = (res) => {
    setSearchResult(res.data);
  };

  const handleSearchKeyChanges = (value) => {
    setResultsHidden(false);
    setSearchKey(value);
    const advisor =
      studentQueryOption === studentListOption[1] && !_.isEmpty(userInfo)
        ? getIdentityEmail(userInfo.username)
        : null;
    const query = getQueryParams({ advisor, query: value });
    const queryString = convertToQueryString(query);
    value !== '' ? debounceHandler(orgId, queryString) : setSearchResult([]);
  };

  const handleStudentListQuery = (data) => {
    const queryData = getStudentListViewOption(studentListLocalStorageKey, studentQueryOption);
    saveTableOptions(studentListLocalStorageKey, {
      ...queryData,
      queryStudent: data,
      page: 0,
    });
    let urlParam = { students: urlParamsToFilterStudentList.ALL_STUDENTS };
    if (data === studentListOption[1]) {
      urlParam = { students: urlParamsToFilterStudentList.MY_STUDENTS };
    }
    navigate(
      generatePath(createUrlWithQueryParams(appRoutes.advisor.STUDENT_LIST, urlParam), {
        orgId: orgId,
      }),
    );
    dispatch(setQueryStudentListOption(data));
  };

  const navigateToStudentPlanning = () => {
    navigate(generatePath(appRoutes.advisor.STUDENT_PLANNING, { orgId: orgId }));
  };

  const navigateToDashboard = () => {
    const generatedPath = generatePath(dashboardPath, { orgId: orgId });
    if (pathname !== generatedPath && !_.isEmpty(selectedOrg)) {
      navigate(generatedPath);
    }
  };
  const handleResultRowClick = (data) => {
    const studentData = searchResult.find(({ id }) => id === data);
    const programMap = programMaps.find(({ optionId }) => studentData.programOption === optionId);
    const currentStudent = {
      ...studentData,
      programOption: {
        id: studentData.programOption,
        name: programMap?.optionName,
        units: programMap?.units,
      },
      units: `${studentData.completedUnits}/${programMap?.units}`,
      refreshData: true,
      currentCatalog: getCurrentCatalog(
        programMaps,
        latestCatalog,
        data.programMapId,
        catalogs,
        studentData.catalogId,
      ),
    };

    setSearchKey('');
    setSearchResult([]);
    dispatch(setCurrentSelectedStudent(currentStudent));
    dispatch(getStudentTeqAssignments(orgId, currentStudent));
    dispatch(setCompletedCourseReq([]));
    dispatch(resetCompletedCourses());
    navigateToStudentPlanning();
  };

  return (
    <AppBar position="fixed" color="white">
      <Container maxWidth="none" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            className={classes.logoLg}
            sx={{ mr: 10, display: { xs: 'none', md: 'flex' } }}
            onClick={navigateToDashboard}>
            <img src="/logo.png" className={classes.logoImg} />
            <Typography
              color="primary"
              variant="h5"
              className={classes.logoText}
              sx={{ fontWeight: 900, lineHeight: 2.2 }}>
              Declare
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, fontWeight: 300 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              <MenuItem key={1} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{t('org')}</Typography>
              </MenuItem>
              <MenuItem key={2} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{t('org-groups')}</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            className={classes.logoLg}
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            onClick={navigateToDashboard}>
            <img src="/logo.png" className={classes.logoImg} />
            <Typography variant="h5" className={classes.logoText} sx={{ fontWeight: 900 }}>
              Declare
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {!_.isEmpty(menuList)
              ? menuList.map(({ name, Icon }) => (
                  <>
                    <Link to="/cat/organizations">
                      <Button
                        startIcon={<Icon />}
                        className={classes.navItems}
                        sx={{
                          color: '#4dbc88',
                          fontWeight: 300,
                          textTransform: 'capitalize',
                          fontSize: '20px',
                          marginRight: '20px',
                        }}>
                        {name}
                      </Button>
                    </Link>
                  </>
                ))
              : null}
          </Box>
          <Box sx={{ display: hideSearchBox ? 'none' : 'flex', flex: 1.5, mr: 20 }}>
            <DeclaredSelect
              margin="dense"
              value={studentQueryOption}
              sm={true}
              color="white"
              onBlur={() => setOutlineValue(0)}
              onFocus={() => setOutlineValue(1)}
              outlineValue={outlineValue}>
              {studentListOption.map((name, index) => (
                <MenuItem value={name} key={index} onClick={() => handleStudentListQuery(name)}>
                  {t(name)}
                </MenuItem>
              ))}
            </DeclaredSelect>

            <Stack>
              <SearchTextBox
                sx={{ width: '350px' }}
                hidden={hideSearchBox}
                label={t('Search Student ID or Name')}
                ml={1}
                setSearchKey={handleSearchKeyChanges}
                onBlur={() => setResultsHidden(true)}
              />
              {!_.isEmpty(searchKey) && !_.isEmpty(searchResult) && (
                <List
                  sx={{
                    width: 350,
                    backgroundColor: theme.palette.white.main,
                    position: 'absolute',
                    mt: 6,
                    ml: 1,
                    borderRadius: 2,
                    boxShadow: `1px 2px 9px ${theme.palette.grey.main}`,
                    display: resultsHidden ? 'none' : 'block',
                  }}
                  component="nav"
                  aria-label="mailbox folders">
                  {searchResult.map((data, index) => (
                    <ListItem
                      sx={{ cursor: 'pointer' }}
                      divider
                      key={index}
                      onMouseDown={() => handleResultRowClick(data.id)}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <ListItemText
                          sx={{ mr: 6, display: 'flex', flex: 2 }}
                          primary={<Typography fontWeight={700}>{`${data.studentId}`}</Typography>}
                        />
                        <ListItemText
                          sx={{ display: 'flex', flex: 10 }}
                          primary={<Typography fontWeight={700}>{`${data.name}`}</Typography>}
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              )}
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{ color: '#4dbc88', textTransform: 'uppercase', marginRight: 2, fontWeight: 300 }}
              variant="body1">
              {userInfo.name || ''}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userInfo.name || ''}
                  src="/static/images/avatar/2.jpg"
                  sx={{ backgroundColor: '#4dbc88', fontWeight: 900 }}>
                  {initials}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <MenuItem key={settings[0]} onClick={logoutUser}>
                <Typography textAlign="center">{settings[0]}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
