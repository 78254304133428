import React from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, Box, Typography, Button } from '@mui/material';
/**
 * Declare dialog box
 * @param props Properties
 */

const useStyle = makeStyles((theme) => ({
  declareDialog: {
    '& .MuiPaper-root ': {
      background: 'common.white',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
  },
  dialogBoxBtn: {
    '&.MuiButton-root': {
      height: theme.spacing(5),
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
      width: theme.spacing(15),
      marginLeft: theme.spacing(1),
    },
  },
  dialogBoxBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogBox: {
    display: 'flex',
    backgroundColor: theme.palette.white.main,
    marginTop: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dialogBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& $dialogBoxItems': {
      marginBottom: theme.spacing(1),
      fontWeight: 700,
    },
  },
  dialogBoxItems: {},
}));

export default function DeclareDialog(props) {
  props = Object.assign(props);
  const classes = useStyle();
  const { header, description, yesLabel, noLabel, cancelBtnColor, handleYesClick, handleNoClick } =
    props;

  const getDialogBoxComponent = () => {
    return (
      <Box className={classes.dialogBox}>
        <Box className={classes.dialogBoxContainer}>
          <Typography className={classes.dialogBoxItems} textAlign="center">
            {header}
          </Typography>
          {description && <Typography>{description}</Typography>}
          <Box className={classes.dialogBoxBtnContainer}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.dialogBoxBtn}
              onClick={handleYesClick}>
              {yesLabel}
            </Button>
            <Button
              variant="contained"
              color={cancelBtnColor ? cancelBtnColor : 'secondary'}
              className={classes.dialogBoxBtn}
              onClick={handleNoClick}>
              {noLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Dialog className={classes.declareDialog} {...props}>
      {getDialogBoxComponent()}
    </Dialog>
  );
}
