import { requestHandler, requestTypes } from '../helper';
import {
  requestCreateCourseProjectionReport,
  requestCreateStatusHistoryReport,
  requestGenerateStatusHistoryReport,
} from '../requests/reports';

export function* handleCreateStatusHistoryReport(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestCreateStatusHistoryReport.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handleCreateCourseProjectionReport(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestCreateCourseProjectionReport.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handleGenerateStatusHistoryReport(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGenerateStatusHistoryReport.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}
