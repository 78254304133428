import { actionTypes } from '../constants/actionTypes';

const initState = {
  list: [],
};

export const classScheduleReducer = (state = initState, { type }) => {
  switch (type) {
    case actionTypes.SELECT_ORG:
      return { ...state, list: [] };
    default:
      return state;
  }
};
