import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeclaredSelect from './DeclareSelect';
import { RuleTypes } from '../helpers/ruleHelper';
import { Box } from '@mui/system';
import { Grid, IconButton, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RelationshipRule from './rules/RelationshipRule';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CompleteNumberOfUnitsRule from './rules/CompleteNumberOfUnitsRule';
import CompleteNumberOfUnitsByTypeRule from './rules/CompleteNumberOfUnitsByTypeRule';
import NoMoreThanXUnitsRule from './rules/NoMoreThanXUnitsRule';
import CompleteNumberOfUnitsByTagRule from './rules/CompleteNumberOfUnitsByTagRule';
import CompleteInFirstXUnitsRule from './rules/CompleteInFirstXUnitsRule';
import LearningActivitiesOrGroupsRule from './rules/LearningActivitiesOrGroupsRule';
import LearningActivitiesWithLevelGreaterThanRule from './rules/LearningActivitiesWithLevelGreaterThanRule';
import NoMoreThanXCoursesRule from './rules/NoMoreThanXCoursesRule';
import QuestionRule from './rules/QuestionRule';
import WarningRule from './rules/WarningRule';
import PlaceholderRule from './rules/PlaceholderRule';
import CompleteBetweenXAndYUnits from './rules/CompleteBetweenXAndYUnitsRule';
import AllowMaximumOfX from './rules/AllowRepeatOfMaximumOfX';
import StudentPreferredProgramX from './rules/StudentsPreferredProgramX';
import OrderLearningActivityInDifferentTermsByTag from './rules/OrderLearningActivityInDifferentTermsByTag';
import LearningActivityCannotIncludeForCreditX from './rules/LearningActivityCannotIncludeForCreditX';
import AllowManualPlanningOfCourseGroupRule from './rules/AllowManualPlanningOfCourseGroupRule';

const useStyle = makeStyles((theme) => ({
  root: {
    marginLeft: '30px',
    '& .MuiFormControl-root': {
      position: 'relative',
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      '&.root::before': {
        content: "''",
        height: 'calc(50% + 50px)',
        borderColor: theme.palette.primary.main,
        borderWidth: '0 0 2px 2px',
        borderStyle: 'solid',
        width: '24px',
        left: '-25px',
        top: '-50px',
        position: 'absolute',
      },
      '&.root::after': {
        borderWidth: '0 0 0 2px',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        content: "''",
        width: '20px',
        height: 'calc(50% + 22px)',
        left: '-25px',
        top: '25px',
        position: 'absolute',
      },
    },
    '& .MuiFormHelperText-root': {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
    },
    '& .MuiAutocomplete-input': {
      padding: theme.spacing(0),
    },
    '& .MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: theme.spacing(0.5),
      },
    },
    '& .MuiGrid-root': {
      '& .right': {
        paddingLeft: '20px',
      },
      '& .left': {
        paddingRight: '20px',
      },
    },
  },
  declareGrid: {
    '&:hover': {
      '& $removeItem': {
        display: 'inline-flex',
      },
    },
  },
  removeItem: {
    '&.MuiIconButton-root': {
      display: 'none',
      color: '#375065',
    },
  },
}));

export default function Rule(props) {
  const { type, selectedElementPath, selectedItemEditable = true } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const [ruleType, setRuleType] = useState(type);

  const handleInputChange = (e) => {
    let val = e.target.value;
    props.changeRuleTypeHandler({ props, val });
  };

  useEffect(() => {
    setRuleType(type);
  }, [type]);

  const getRuleComponent = () => {
    let component = '';
    switch (ruleType) {
      case RuleTypes[0].value:
      case RuleTypes[1].value:
      case RuleTypes[2].value:
      case RuleTypes[3].value:
        component = (
          <RelationshipRule
            addRuleDataHandler={props.addRuleDataHandler}
            selectedElementPath={props.selectedElementPath}
            selectedItemEditable={selectedItemEditable}
            data={props.data}
          />
        );
        break;
      case RuleTypes[4].value:
      case RuleTypes[5].value:
        let { name, placeholder } = RuleTypes.find((rt) => rt.value === ruleType);
        component = (
          <LearningActivitiesOrGroupsRule
            {...props}
            selectedItemEditable={selectedItemEditable}
            label={name}
            placeholder={placeholder}
          />
        );
        break;
      case RuleTypes[6].value:
        component = (
          <CompleteNumberOfUnitsRule {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[7].value:
        component = (
          <CompleteNumberOfUnitsByTagRule {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[8].value:
        component = (
          <CompleteNumberOfUnitsByTypeRule {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[9].value:
        component = (
          <LearningActivitiesWithLevelGreaterThanRule
            {...props}
            selectedItemEditable={selectedItemEditable}
          />
        );
        break;
      case RuleTypes[10].value:
        component = (
          <CompleteInFirstXUnitsRule {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[11].value:
        component = (
          <CompleteBetweenXAndYUnits {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[12].value:
        component = <AllowMaximumOfX {...props} selectedItemEditable={selectedItemEditable} />;
        break;
      case RuleTypes[13].value:
        component = (
          <StudentPreferredProgramX {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[14].value:
        component = (
          <NoMoreThanXCoursesRule {...props} selectedItemEditable={selectedItemEditable} />
        );
        break;
      case RuleTypes[15].value:
        component = <NoMoreThanXUnitsRule {...props} selectedItemEditable={selectedItemEditable} />;
        break;
      case RuleTypes[16].value:
        component = <WarningRule {...props} selectedItemEditable={selectedItemEditable} />;
        break;
      case RuleTypes[17].value:
        component = <QuestionRule {...props} selectedItemEditable={selectedItemEditable} />;
        break;
      case RuleTypes[18].value:
        component = <PlaceholderRule {...props} selectedItemEditable={selectedItemEditable} />;
        break;
      case RuleTypes[19].value:
        component = (
          <OrderLearningActivityInDifferentTermsByTag
            {...props}
            selectedItemEditable={selectedItemEditable}
          />
        );
        break;
      case RuleTypes[20].value:
        component = (
          <LearningActivityCannotIncludeForCreditX
            {...props}
            selectedItemEditable={selectedItemEditable}
          />
        );
        break;
      case RuleTypes[21].value:
        component = (
          <AllowManualPlanningOfCourseGroupRule
            {...props}
            selectedItemEditable={selectedItemEditable}
          />
        );
        break;
      default:
        return '';
    }
    return component;
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} className={classes.declareGrid}>
        <Grid item xs={3}>
          <DeclaredSelect
            rootEnable={true}
            inputlabel={t('Rule Type') + ' *'}
            margin="dense"
            name="ruleType"
            onChange={handleInputChange}
            disabled={!selectedItemEditable}
            value={ruleType}>
            {RuleTypes.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {t(item.name)}
              </MenuItem>
            ))}
          </DeclaredSelect>
        </Grid>
        <Grid item xs={8.4}>
          {getRuleComponent()}
        </Grid>
        <Grid item xs={0.4}>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="105px">
            <IconButton
              aria-label="remove"
              disabled={!selectedItemEditable}
              onClick={() => {
                props.removeRuleOrGroupHandler(selectedElementPath);
              }}
              className={classes.removeItem}>
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
