import { Autocomplete, InputLabel } from '@mui/material';
import React from 'react';

/**
 * Declare auto complete component
 * @param props Properties
 */

export default function DeclareAutocomplete(props) {
  props = Object.assign(props);

  return (
    <>
      <InputLabel>{props.inputlabel}</InputLabel>
      <Autocomplete {...props} />
    </>
  );
}
