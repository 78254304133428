import { setSettings } from '../../actions/settingActions';
import { requestHandler, requestTypes } from '../helper';
import { requestGetSettings, requestPostSettings } from '../requests/settings';

export function* handleGetSettings(action) {
  const { payload } = action;
  yield requestHandler(requestGetSettings.bind(this, payload), setSettings, requestTypes.GET);
}

export function* handlePostSettings(action) {
  const { payload } = action;
  yield requestHandler(requestPostSettings.bind(this, payload), null, requestTypes.POST);
}
