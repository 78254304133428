import { actionTypes } from '../constants/actionTypes';

export const setProgramMaps = (programs) => {
  return {
    type: actionTypes.SET_PROGRAM_MAPS,
    payload: programs,
  };
};

export const getProgramMaps = (params) => {
  return {
    type: actionTypes.GET_PROGRAM_MAPS,
    payload: params,
  };
};

export const getProgramMapRequirements = (org, programMapId) => {
  return {
    type: actionTypes.GET_PROGRAM_MAP_REQUIREMENTS,
    payload: { org, programMapId },
  };
};

export const setProgramMapRequirements = (requirementList) => {
  return {
    type: actionTypes.SET_PROGRAM_MAP_REQUIREMENTS,
    payload: requirementList,
  };
};

export const createProgramMap = (org, data, navigate) => {
  return {
    type: actionTypes.CREATE_PROGRAM_MAP,
    payload: { org, data },
    navigate: navigate,
  };
};

export const editProgramMap = (org, programMapId, data, navigate) => {
  return {
    type: actionTypes.EDIT_PROGRAM_MAP,
    payload: { org, programMapId, data },
    navigate: navigate,
  };
};

export const renameProgramMapGroup = (org, programMapId, groupId, name, callback) => {
  return {
    type: actionTypes.PROGRAM.RENAME_PROGRAM_MAP_GROUP,
    payload: { org, programMapId, id: groupId, name },
    callback,
  };
};

export const addNewProgramMapGroup = (org, programMapId, data, callback) => {
  return {
    type: actionTypes.PROGRAM.ADD_NEW_PROGRAM_MAP_GROUP,
    payload: { org, programMapId, data },
    callback,
  };
};

export const removeProgramMapNode = (org, programMapId, parentId, removedNodeId, callback) => {
  return {
    type: actionTypes.PROGRAM.REMOVE_PROGRAM_MAP_NODE,
    payload: { org, programMapId, data: { parentId, id: removedNodeId } },
    callback,
  };
};

export const moveProgramMapNode = (
  org,
  programMapId,
  id,
  prevParentId,
  newParentId,
  newIndex,
  callback,
) => {
  return {
    type: actionTypes.PROGRAM.MOVE_PROGRAM_MAP_NODE,
    payload: { org, programMapId, data: { id, prevParentId, newParentId, newIndex } },
    callback,
  };
};

export const updateProgramMapNode = (org, programMapId, id, parentId, isRequirement, message) => {
  return {
    type: actionTypes.PROGRAM.UPDATE_PROGRAM_MAP_NODE,
    payload: { org, programMapId, data: { id, isRequirement, message, parentId } },
  };
};

export const linkProgramMapIntoProgramMap = (
  org,
  programMapId,
  parentId,
  groupId,
  linkedProgramMapId,
  callback,
) => {
  return {
    type: actionTypes.PROGRAM.LINK_PROGRAM_MAP_INTO_PROGRAM_MAP,
    payload: { org, programMapId, data: { parentId, groupId, programMapId: linkedProgramMapId } },
    callback,
  };
};

export const getProgramMappingData = (org, programMapId, query = '', callback) => {
  return {
    type: actionTypes.PROGRAM.GET_PROGRAM_MAPPING_DATA,
    payload: { org, programMapId, query },
    callback,
  };
};

export const getLearningActivitiesForGroup = (org, programMapId, data, callback) => {
  return {
    type: actionTypes.PROGRAM.GET_LEARNING_ACTIVITIES_FOR_GROUP,
    payload: { org, programMapId, data },
    callback,
  };
};

export const constructPathwayForProgramMap = (programMapId, body, callback) => {
  return {
    type: actionTypes.CONSTRUCT_PROGRAM_MAP_PATHWAY,
    payload: { programMapId, body },
    callback,
  };
};

export const patchProgramMappingData = (org, programMapId, body, callback) => {
  return {
    type: actionTypes.PROGRAM.PATCH_PROGRAM_MAPPING_DATA,
    payload: { org, programMapId, body },
    callback,
  };
};
