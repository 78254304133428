import { actionTypes } from '../constants/actionTypes';

export const setPrograms = (programs) => {
  return {
    type: actionTypes.SET_PROGRAMS,
    payload: programs,
  };
};

export const getPrograms = (params) => {
  return {
    type: actionTypes.GET_PROGRAMS,
    payload: params,
  };
};

export const getProgram = (orgId, programId, callBack) => {
  return {
    type: actionTypes.GET_PROGRAM,
    payload: { orgId, programId },
    callBack,
  };
};

export const createProgram = (org, data, navigate) => {
  return {
    type: actionTypes.CREATE_PROGRAM,
    payload: { org, data },
    navigate: navigate,
  };
};

export const editProgram = (org, programId, data, navigate) => {
  return {
    type: actionTypes.EDIT_PROGRAM,
    payload: { org, programId, data },
    navigate: navigate,
  };
};

export const getProgramOptions = (orgId, programId) => {
  return {
    type: actionTypes.GET_PROGRAM_OPTIONS,
    payload: { orgId, programId },
  };
};
export const getOption = (orgId, programId, optionId, callBack) => {
  return {
    type: actionTypes.GET_PROGRAM_OPTION,
    payload: { orgId, programId, optionId },
    callBack,
  };
};

export const setOption = (option) => {
  return {
    type: actionTypes.SET_PROGRAM_OPTION,
    payload: { option },
  };
};
export const setProgramOptions = (programOptions) => {
  return {
    type: actionTypes.SET_PROGRAM_OPTIONS,
    payload: programOptions,
  };
};

export const createProgramOption = (orgId, programId, data, navigate) => {
  return {
    type: actionTypes.CREATE_PROGRAM_OPTION,
    payload: { orgId, programId, data },
    navigate: navigate,
  };
};

export const updateProgramOption = (orgId, programId, optionId, data, navigate) => {
  return {
    type: actionTypes.UPDATE_PROGRAM_OPTION,
    payload: { orgId, programId, optionId, data },
    navigate: navigate,
  };
};
