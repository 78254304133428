import { actionTypes } from '../constants/actionTypes';

export const getAllDepartments = (payload) => {
  return {
    type: actionTypes.COLLEGES.GET,
    payload,
  };
};

export const setDepartments = (payload) => {
  return {
    type: actionTypes.COLLEGES.SET,
    payload,
  };
};

export const postDepartment = (org, data, navigate) => {
  return {
    type: actionTypes.COLLEGES.CREATE,
    payload: { org, data },
    navigate: navigate,
  };
};

export const putDepartment = (org, college, data, navigate) => {
  return {
    type: actionTypes.COLLEGES.EDIT,
    payload: { org, college, data },
    navigate: navigate,
  };
};
