import { actionTypes } from '../constants/actionTypes';

const initialState = {
  organizationSettings: [],
  processed: {},
};

export default function settingsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SETTINGS.SET:
      const processedSettings = payload.reduce(
        (previous, current) => ({
          ...previous,
          [current.category]: { settings: current.settings },
        }),
        {},
      );
      return { ...state, organizationSettings: payload, processed: processedSettings };
    default:
      return state;
  }
}
