import {
  Box,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import _, { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, generatePath } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import StyleIcon from '@mui/icons-material/Style';
import { makeStyles } from '@mui/styles';
import DeclareResizePanel from '../../../components/DeclareResizePanel';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import StatusCell from '../../../components/StatusCell';
import style from './sliding-panel.css';
import {
  getProgramMaps,
  getProgramMappingData,
  patchProgramMappingData,
  renameProgramMapGroup,
  addNewProgramMapGroup,
  linkProgramMapIntoProgramMap,
  removeProgramMapNode,
  moveProgramMapNode,
  updateProgramMapNode,
} from '../../../redux/actions/programMapActions';
import ProgramMapTreeContainer from '../../../components/ProgramMapTreeContainer';
import {
  buildNewGroupDetails,
  changeGroupName,
  changeIsRequirement,
  changeMessage,
  getSelectedGroupName,
  getSelectedIsRequirement,
  getSelectedMessage,
  getSelectedItemId,
  getSelectedItem,
  PROGRAM_MAP_TYPE,
  isSelectedItemNameEditable,
  removeItem,
  getSelectedGroupType,
  getSelectedProgramMapId,
  getSelectedGroupProgramMapId,
  getSelectedNode,
  getProgramMapListToSelectDropDown,
  insertNewNodeIntoMapData,
  replaceNodeInMapData,
  findItemIndex,
} from '../../../helpers/programMapHelper';
import {
  addItemsToParent,
  moveTreeItem,
  removeItemsFromParent,
  updateObjectsProgramMap,
} from '../../../helpers/treeHelper';
import RuleContainer from '../../../components/RuleContainer';
import { getAssociatedRule } from '../../../redux/actions/ruleActions';
import { getLearningActivities } from '../../../redux/actions/learningActivityActions';
import { getTags } from '../../../redux/actions/tagActions';
import { appRoutes } from '../../../helpers/routes';
import { searchTree } from '../../../helpers/treeHelper';
import DeclaredSelect from '../../../components/DeclareSelect';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { setError } from '../../../redux/actions/notificationActions';

const useStyle = makeStyles(() => ({
  root: {
    '& .EllipsisText': {
      display: 'inline-block',
      width: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      float: 'left',
    },
  },
}));

export default function ProgramMapView() {
  const { orgId, programId } = useParams();
  const { t } = useTranslation();
  let cx = classNames.bind(style);
  const classes = useStyle();

  const dispatch = useDispatch();

  const [ruleData, setRuleData] = useState({});
  const programMapList = useSelector((state) => state.programMaps.programMapList);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const tagsList = useSelector((state) => state.tags.list);
  const [breadCrumbName, setBreadCrumbName] = useState('');
  const [selectedProgramMapOption, setSelectedProgramMapOption] = useState(null);
  const [isRequirement, setIsRequirement] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectedProgramMap, setSelectedProgramMap] = useState(null);
  const [selectedGroupType, setSelectedGroupType] = useState(null);
  const [selectedItemEditable, setSelectedItemEditable] = useState(true);
  const [newGroupPath, setNewGroupPath] = useState(null);
  const [mapData, setMapData] = useState({ items: [] });
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [visibleMapData, setVisibleMapData] = useState({ items: [] });
  const [programMapId, setSelectedProgramMapId] = useState(null);
  const [filteredProgramMapList, setFilteredProgramMapList] = useState([]);
  const resizePanelRef = useRef();

  useEffect(() => {
    if (!learningActivities.length > 0) {
      dispatch(getLearningActivities(orgId));
    }
    if (tagsList.length === 0) {
      dispatch(getTags(orgId));
    }
  }, []);

  useEffect(() => {
    if (programMapList.length === 0 && !searchKeyword) {
      dispatch(getProgramMaps(orgId));
    } else {
      const selectedProgramMap = programMapList.find((programMap) => programMap.id === programId);
      updateView(selectedProgramMap);
    }
  }, [programMapList]);

  const updateView = (data) => {
    setBreadCrumbName(data.optionName);
    setSelectedProgramMapOption(data);
  };

  useEffect(() => {
    if (_.isEmpty(mapData.items)) {
      const setInitialMapData = (mapDataFromDB) => {
        if (_.isEmpty(mapDataFromDB.items)) {
          addNewGroup([], false, true);
        } else {
          setMapData(updateObjectsProgramMap(mapDataFromDB));
        }
      };

      dispatch(getProgramMappingData(orgId, programId, '', setInitialMapData));
    }
  }, [mapData]);

  const updateParentMapData = (mapDataFromDB, currentMapData = mapData) => {
    const { items, parent } = mapDataFromDB;
    const data = addItemsToParent(currentMapData, items, parent);
    setMapData({ items: data });
  };

  const getItemsFromParents = (parentId, pagination, callback) => {
    const query = `?parent=${parentId}&${pagination}`;
    dispatch(
      getProgramMappingData(orgId, programId, query, (res) => {
        callback(res);
        updateParentMapData(res);
      }),
    );
  };

  /**
   * patchLAToItems function
   * @param data - list of all selected and deselected learning activities
   * @param parent - group id of the learning activities
   * @description add or remove learning activities to the a group
   * only selected or deselected learning activities are added or removed
   * this change is to support adding large number of learning activities to a group
   * @returns list of selected and deselected learning activities
   */
  const updateLaForProgramMapGroups = (data, parent) => {
    const body = {
      items: data,
      parent,
    };

    const deselectedLAs = data.filter(({ deselected }) => deselected);
    const deselectedItems = removeItemsFromParent(mapData, deselectedLAs, parent);

    dispatch(
      patchProgramMappingData(orgId, programId, body, (res) => {
        updateParentMapData(res, { items: deselectedItems });
      }),
    );
  };

  const addNewGroup = (
    selectedElementPath = [],
    linkProgramMap = false,
    creatingInitialData = false,
  ) => {
    const result = buildNewGroupDetails(
      visibleMapData,
      mapData,
      programId,
      selectedElementPath,
      linkProgramMap,
    );
    const callback = (newNode) => {
      const { parentGroupId } = result.newNodeData;
      setNewGroupPath(creatingInitialData ? [0] : result.newGroupPath);
      setMapData(insertNewNodeIntoMapData(mapData, parentGroupId, newNode, programId));
      setIsRequirement(newNode.isRequirement);
      if (linkProgramMap) {
        const list = getProgramMapListToSelectDropDown(
          programMapList,
          selectedElementPath,
          mapData,
          selectedProgramMapOption,
        );
        setFilteredProgramMapList(list);
        setSelectedProgramMap(null);
      }
    };
    dispatch(addNewProgramMapGroup(orgId, programId, result.newNodeData, callback));
  };

  const onGroupSelect = (selectedElementPath, isGroupSelected) => {
    setSelectedItem(selectedElementPath);
    const selectedGroupType = getSelectedGroupType(visibleMapData, selectedElementPath);
    const selectedProgramMapId = getSelectedGroupProgramMapId(visibleMapData, selectedElementPath);
    const getNode = getSelectedNode(visibleMapData, selectedElementPath);
    setSelectedProgramMapId(selectedProgramMapId);
    setSelectedGroupType(selectedGroupType);
    if ([PROGRAM_MAP_TYPE.GROUP, PROGRAM_MAP_TYPE.LEARNING_ACTIVITY].includes(selectedGroupType)) {
      setSelectedGroupName(getSelectedGroupName(visibleMapData, selectedElementPath));
    } else {
      const programMapId = getSelectedProgramMapId(visibleMapData, selectedElementPath);
      const programMap = programMapList.find((res) => res.id === programMapId);
      setSelectedProgramMap(programMap);
      const elementPath = cloneDeep(selectedElementPath);
      if (isGroupSelected) {
        setFilteredProgramMapList(null);
        const list = getProgramMapListToSelectDropDown(
          programMapList,
          elementPath,
          mapData,
          selectedProgramMapOption,
          programMap,
        );
        setFilteredProgramMapList(list);
      }
    }
    setIsRequirement(getSelectedIsRequirement(visibleMapData, selectedElementPath));
    setSelectedMessage(getSelectedMessage(visibleMapData, selectedElementPath));
    setRuleData({});
    setSelectedItemEditable(
      isSelectedItemNameEditable(
        visibleMapData,
        selectedElementPath,
        selectedProgramMapId,
        getNode.isParentNodeProgramMap,
      ),
    );
    const rulableId = getSelectedItemId(visibleMapData, selectedElementPath);
    setSelectedItemId(rulableId);
    dispatch(getAssociatedRule(orgId, rulableId, setRuleData));
  };

  const changeSelectedGroupName = (name) => {
    setSelectedGroupName(name);
  };

  const updateGroupNameInMapData = (name) => {
    const updateMap = () => setMapData(changeGroupName(mapData, selectedItem, name));
    if (name != '' && getSelectedGroupName(mapData, selectedItem) != name) {
      dispatch(renameProgramMapGroup(orgId, programId, selectedItemId, name, updateMap));
    }
  };

  const updateProgramMapGroupNameInMapData = (value) => {
    const callback = (newNode) => {
      setMapData(updateObjectsProgramMap(replaceNodeInMapData(mapData, selectedItemId, newNode)));
    };
    // TODO: as program maps can be linked in the parent level, parent id is always the program map
    const parentId = programId;
    dispatch(
      linkProgramMapIntoProgramMap(orgId, programId, parentId, selectedItemId, value.id, callback),
    );
  };

  const updateNodeInDb = (req, msg) => {
    const parentId =
      selectedItem.length === 1
        ? programId
        : getSelectedItemId(visibleMapData, selectedItem.slice(0, -1));
    dispatch(updateProgramMapNode(orgId, programId, selectedItemId, parentId, req, msg));
  };

  const updateIsRequirementInMapData = (requirement) => {
    setMapData(changeIsRequirement(mapData, selectedItem, requirement));
    updateNodeInDb(requirement, getSelectedMessage(visibleMapData, selectedItem));
  };

  const updateMessageInMapData = (msg) => {
    setMapData(changeMessage(mapData, selectedItem, msg));
    updateNodeInDb(getSelectedIsRequirement(visibleMapData, selectedItem), msg);
  };

  const saveNodeMove = (
    { prevParentId = programId, newParentId = programId, mapData: updatedMap },
    id,
  ) => {
    if (prevParentId != null) {
      const newIndex = findItemIndex(updatedMap, id, newParentId, programId);
      setMapData(updatedMap);
      dispatch(moveProgramMapNode(orgId, programId, id, prevParentId, newParentId, newIndex));
    }
  };

  const moveTreeNode = (dragPath, DropPath, droppedPosition) => {
    const dragItem = getSelectedItem(visibleMapData, dragPath);
    const selectedProgramMapId = getSelectedGroupProgramMapId(visibleMapData, DropPath);
    let validProgramMapListToDroppedGroup = [];
    const elementPathDrop = cloneDeep(DropPath);
    const elementPathDrag = cloneDeep(dragPath);
    elementPathDrop.pop();
    elementPathDrag.pop();
    if (_.isNil(selectedProgramMapId)) {
      if (dragItem.type === PROGRAM_MAP_TYPE.PROGRAM_MAP) {
        // Ignore the same level of program map
        if (_.isEqual(elementPathDrag, elementPathDrop)) {
          saveNodeMove(moveTreeItem(mapData, dragPath, DropPath, droppedPosition), dragItem.id);
        } else {
          validProgramMapListToDroppedGroup = getProgramMapListToSelectDropDown(
            programMapList,
            elementPathDrop,
            mapData,
            selectedProgramMapOption,
          );
          const isIncluded = validProgramMapListToDroppedGroup.find(
            (aProgramMap) => aProgramMap.id === dragItem.programMapId,
          );
          if (!_.isNil(isIncluded)) {
            saveNodeMove(moveTreeItem(mapData, dragPath, DropPath, droppedPosition), dragItem.id);
          } else {
            dispatch(
              setError({
                message: 'Program map already exists in the group.',
              }),
            );
          }
        }
      } else {
        saveNodeMove(moveTreeItem(mapData, dragPath, DropPath, droppedPosition), dragItem.id);
      }
    } else {
      dispatch(
        setError({
          message: 'Use the original program map to make the required changes.',
        }),
      );
    }
  };

  const removeTreeNode = (selectedElementPath) => {
    const parentId =
      selectedElementPath.length === 1
        ? programId
        : getSelectedItemId(visibleMapData, selectedElementPath.slice(0, -1));
    const removedNodeId = getSelectedItemId(visibleMapData, selectedElementPath);

    const callback = () => {
      const updatedMapData = removeItem(mapData, removedNodeId, parentId, programId);
      // if all the groups are removed, set the initial data
      setMapData(updatedMapData);
      setSelectedItem(null);
      setSelectedItemId(null);
    };

    dispatch(removeProgramMapNode(orgId, programId, parentId, removedNodeId, callback));
  };
  const programMapListRoute = generatePath(appRoutes.catalog.PROGRAM_MAP_LIST, { orgId });

  useEffect(() => {
    let result = [];
    if (searchKeyword != null) {
      result = searchTree(mapData.items, searchKeyword);
      let filteredList = { items: result };
      setVisibleMapData(filteredList);
    } else {
      setVisibleMapData(mapData);
    }
  }, [searchKeyword, mapData]);

  useEffect(() => {
    if (newGroupPath) {
      onGroupSelect(newGroupPath);
      setNewGroupPath(null);
    }
  }, [visibleMapData]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          className={classes.root}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          <DeclareBreadcrumb
            items={[{ name: t('Program Map'), customRoute: programMapListRoute }, breadCrumbName]}
          />
          <Card sx={{ padding: 1.5 }}>
            {breadCrumbName && (
              <Stack direction="row" spacing={1}>
                <Typography
                  title={selectedProgramMapOption?.programName}
                  className="EllipsisText"
                  display="inline"
                  variant="h6"
                  sx={{ fontWeight: '800' }}>
                  {selectedProgramMapOption?.programName},
                </Typography>
                <Typography
                  title={breadCrumbName}
                  className="EllipsisText"
                  display="inline"
                  variant="h6"
                  sx={{ fontWeight: '800' }}>
                  {breadCrumbName}
                </Typography>
                <Typography
                  display="inline"
                  variant="body1"
                  sx={{ lineHeight: 2.2, fontWeight: '200' }}>
                  {selectedProgramMapOption?.catalogs[0].name}
                </Typography>
                <StatusCell value={selectedProgramMapOption?.status} />
              </Stack>
            )}
          </Card>
        </Box>
        <Box>
          <Card>
            <div className={cx('container')}>
              <div className={cx('body')}>
                <Box
                  className={cx('content', 'panel')}
                  sx={{
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    minWidth: '200px',
                    maxHeight: 'calc(100vh - 180px)',
                  }}>
                  <ProgramMapTreeContainer
                    mapData={visibleMapData}
                    getItemsFromParents={getItemsFromParents}
                    updateLaForProgramMapGroups={updateLaForProgramMapGroups}
                    setSearchKeyword={setSearchKeyword}
                    searchKeyword={searchKeyword}
                    programName={selectedProgramMapOption?.programName}
                    optionName={breadCrumbName}
                    units={selectedProgramMapOption?.units}
                    catalog={selectedProgramMapOption?.catalogs[0].id}
                    onGroupSelect={onGroupSelect}
                    addNewGroup={addNewGroup}
                    moveTreeNode={moveTreeNode}
                    removeTreeNode={removeTreeNode}
                  />
                </Box>
                <DeclareResizePanel
                  ref={resizePanelRef}
                  direction="w"
                  style={{ width: '580px', zIndex: 1, background: 'white' }}
                  borderClass={style.customResizeBorder}>
                  <div className={cx('sidebar', 'panel')}>
                    <Box sx={{ margin: 2, width: '100%' }}>
                      {selectedItem && (
                        <Box>
                          <Box sx={{ float: 'left', width: '100%', minWidth: '550px' }}>
                            {[PROGRAM_MAP_TYPE.GROUP, PROGRAM_MAP_TYPE.LEARNING_ACTIVITY].includes(
                              selectedGroupType,
                            ) ? (
                              <>
                                <StyleIcon
                                  color="secondary"
                                  sx={{ margin: '10px 10px 0px 10px', float: 'left' }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Box>
                                    <TextField
                                      placeholder={t('Group Name')}
                                      sx={{ width: '300px' }}
                                      value={selectedGroupName}
                                      onChange={(e) => {
                                        changeSelectedGroupName(e.target.value);
                                      }}
                                      onBlur={(e) => {
                                        updateGroupNameInMapData(e.target.value);
                                      }}
                                      disabled={!selectedItemEditable}
                                      error={selectedGroupName === ''}
                                      helperText={
                                        selectedGroupName === ''
                                          ? t('Group Name is not allowed to be empty')
                                          : ''
                                      }
                                    />
                                    <br />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isRequirement}
                                          disabled={!selectedItemEditable}
                                          onChange={(e) => {
                                            setIsRequirement(e.target.checked);
                                          }}
                                          onBlur={(e) => {
                                            updateIsRequirementInMapData(e.target.checked);
                                          }}
                                        />
                                      }
                                      label={t('Requirement')}
                                    />
                                    <br />
                                    {isRequirement && (
                                      <TextField
                                        sx={{ width: '100%', marginBottom: 2 }}
                                        value={selectedMessage}
                                        disabled={!selectedItemEditable}
                                        onChange={(e) => {
                                          setSelectedMessage(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                          updateMessageInMapData(e.target.value);
                                        }}
                                      />
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      overflowY: 'auto',
                                      overflowX: 'hidden',
                                      maxHeight: 'calc(100vh - 300px)',
                                    }}>
                                    <RuleContainer
                                      sx={{ padding: '0px' }}
                                      ruledEntity={selectedItemId}
                                      ruleData={ruleData}
                                      cancelAction={null}
                                      padding="0px"
                                      hideTextBoxControls={true}
                                      autoSave={true}
                                      ruleName={selectedGroupName}
                                      selectedItemEditable={selectedItemEditable}
                                      programMapId={programMapId}
                                    />
                                  </Box>
                                </Box>
                              </>
                            ) : (
                              <>
                                <InsertLinkIcon
                                  color="secondary"
                                  fontSize="large"
                                  sx={{
                                    margin: '10px 10px 0px 10px',
                                    float: 'left',
                                    transform: 'rotateZ(45deg)',
                                  }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Box>
                                    <DeclaredSelect
                                      margin="dense"
                                      sx={{ width: '100%' }}
                                      disabled={!selectedItemEditable}
                                      onChange={(e) => setSelectedProgramMap(e.target.value)}
                                      onBlur={(e) =>
                                        updateProgramMapGroupNameInMapData(e.target.value)
                                      }
                                      value={selectedProgramMap ? selectedProgramMap : 0}>
                                      {_.isNil(selectedProgramMap) && (
                                        <MenuItem disabled hidden value={0}>
                                          {t('Select Program Map')}
                                        </MenuItem>
                                      )}
                                      {filteredProgramMapList.map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                          {t(item.optionName)}
                                        </MenuItem>
                                      ))}
                                    </DeclaredSelect>
                                    <br />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isRequirement}
                                          disabled={!selectedItemEditable}
                                          onChange={(e) => {
                                            setIsRequirement(e.target.checked);
                                          }}
                                          onBlur={(e) => {
                                            updateIsRequirementInMapData(e.target.checked);
                                          }}
                                        />
                                      }
                                      label={t('Is a Requirement')}
                                    />
                                  </Box>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </div>
                </DeclareResizePanel>
              </div>
            </div>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
