import { actionTypes } from '../constants/actionTypes';

const initState = {
  termPlanningStatus: [],
  pathwayStatus: [],
};

export const pathwayReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.PATHWAY.RESET_TERM_PLANNING_STATUSES:
      return { ...state, termPlanningStatus: [] };
    case actionTypes.PATHWAY.SET_TERM_PLANNING_STATUSES:
      return { ...state, termPlanningStatus: payload };
    case actionTypes.PATHWAY.SET_PATHWAY_STATUSES:
      return { ...state, pathwayStatus: payload };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};
