import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import DeclareTextField from '../../../components/DeclareTextField';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import DeclaredSelect from '../../../components/DeclareSelect';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProgramOption,
  getProgramOptions,
  updateProgramOption,
} from '../../../redux/actions/programActions';
import { getStatus, deliveryModes, declarableValues } from '../../../helpers/appConstants';
import { getCatalogs } from '../../../redux/actions/catalogActions';

export default function ProgramOptionForm(props) {
  const { currentOption, isEditView } = props;
  const { orgId, programId, optionId } = useParams();
  const { values, setValues, handleInputChange } = useDeclareForm(currentOption);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orgs = useSelector((store) => store.allOrgs);
  const formError = useSelector((store) => store.notifications.formError);
  const catStore = useSelector((store) => store.allCatalogs);

  const backToProgramListView = () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_OPTION_LIST, { orgId, programId }));
  };
  const handleSaveAction = () => {
    dispatch(getProgramOptions(orgId, programId));
    navigate(generatePath(appRoutes.catalog.PROGRAM_OPTION_LIST, { orgId, programId }));
  };

  useEffect(() => {
    if (!catStore.length > 0) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  useEffect(() => {
    setValues(currentOption);
  }, [currentOption]);

  const data = {
    name: values.name,
    code: values.code,
    url: values.url,
    deliveryMode: values.deliveryMode,
    status: values.status,
    declarable: values.declarable,
    shortDescription: values.shortDescription,
  };

  const saveProgram = async () => {
    dispatch(createProgramOption(orgs.selectedOrg.id, programId, data, handleSaveAction));
  };

  const updateProgram = async () => {
    dispatch(updateProgramOption(orgs.selectedOrg.id, programId, optionId, data, handleSaveAction));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclareTextField
              inputlabel={t('name') + ' *'}
              variant="outlined"
              placeholder={t('Name of the program option')}
              value={values.name}
              name="name"
              onChange={handleInputChange}
              error={formError['name']}
              helperText={formError['name']?.message}
            />
            <DeclaredSelect
              inputlabel={t('deliveryMode') + ' *'}
              margin="dense"
              value={values.deliveryMode}
              name="deliveryMode"
              onChange={handleInputChange}
              error={formError['deliveryMode']}
              helperText={formError['deliveryMode']?.message}>
              {deliveryModes.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={t('Declarable') + ' *'}
              margin="dense"
              value={values.declarable}
              name="declarable"
              onChange={handleInputChange}
              error={formError['declarable']}
              helperText={formError['declarable']?.message}>
              {declarableValues.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
          <Grid item xs={6} className="right">
            <DeclareTextField
              inputlabel={t('Code') + ' *'}
              variant="outlined"
              value={values.code}
              name="code"
              onChange={handleInputChange}
              error={formError['code']}
              helperText={formError['code']?.message}
            />
            <DeclareTextField
              inputlabel={t('descriptionUrl') + ' *'}
              variant="outlined"
              value={values.url}
              name="url"
              onChange={handleInputChange}
              error={formError['url']}
              helperText={formError['url']?.message}
            />
            <DeclaredSelect
              inputlabel={t('status') + ' *'}
              margin="dense"
              value={values.status}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {getStatus().map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Grid>
        <DeclareTextField
          multiline={true}
          inputlabel={t('shortDescription') + ' *'}
          variant="outlined"
          placeholder={t('shortDescription')}
          value={values.shortDescription}
          name="shortDescription"
          onChange={handleInputChange}
          error={formError['shortDescription']}
          helperText={formError['shortDescription']?.message}
        />
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateProgram() : saveProgram();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            backToProgramListView();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
