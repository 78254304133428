import MUIDataTable from 'mui-datatables';
import React from 'react';
import { dataTableStyles } from './styles/dataTableStyles';

/**
 * Declare text input
 * @param props Properties
 */

export default function DeclareDataTable(props) {
  props = Object.assign(props);
  const classes = dataTableStyles();
  return (
    <div className={classes.root}>
      <MUIDataTable {...props} />
    </div>
  );
}
