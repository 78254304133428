import { Box, Typography, Button, Stack } from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TermCard from '../../../components/advisor/StudentView/TermCard';
import TermCardTransferred from '../../../components/advisor/StudentView/TermCardTransferred';
import { setError, setSuccess } from '../../../redux/actions/notificationActions';
import TermCardAddNewTerm from '../../../components/advisor/StudentView/TermCardAddNewTerm';
import { getAllTerms, pathwayAddNewTerm } from '../../../redux/actions/termActions';
import {
  getCompletedCourseReq,
  getStudentTransferEvaluations,
  patchCompletedCourseReq,
  postCompletedCourseReq,
} from '../../../redux/actions/transferEvaluationActions';
import { useTranslation } from 'react-i18next';
import {
  pathwayQuestionTypes,
  termPlanningStatusTypes,
  studentPathwayStatusTypes,
  StudentViewTabMenu,
  INVALID_PATHWAY_DUE_TO_MISSING_COURSES,
  INVALID_PATHWAY_DUE_TO_UNITS_MISMATCH,
  INVALID_PATHWAY_DUE_TO_PRE_REQUISITE,
} from '../../../helpers/appConstants';
import QuestionDialog from './Components/QuestionDialog';
import {
  getPathway,
  getPathwayCourseUsage,
  getPathwayStatus,
  getTermPlanningStatus,
  markCourseAsIncomplete,
  savePathway,
  savePathwayCourseUsage,
  updatePathway,
  validatePathway,
} from '../../../redux/actions/pathwayActions';
import DeclareFlyout from '../../../components/DeclareFlyout';
import {
  buildPathwayData,
  generateCourseUsageData,
  generatePathwayData,
  transformPathwayData,
  doesOrderedPathwayOverlapsWithCompletedTerms,
  includeTermInstancesInPathway,
  getPathwayTerms,
  markCourseAsIncompletedOrCompletedInPathway,
  markRetakenCoursesInPathway,
  preprocessPathwayData,
  updatePathwayData,
  removeCourseFromPathway,
} from '../../../helpers/pathwayHelper';
import {
  getStudentPathway,
  updateStudentPathway,
  updateSelectedStudent,
  setCompletedCourses,
} from '../../../redux/actions/studentActions';
import { ByTermPdfTemplateWrapper } from '../../../templates/byTermPdfTemplate';
import { useReactToPrint } from 'react-to-print';
import { setLoadingStatus } from '../../../redux/actions/loadingActions';
import TermCardBuildPathway from '../../../components/advisor/StudentView/TermCardBuildPathway';
import { getLearningActivities } from '../../../redux/actions/learningActivityActions';
import { getSettings } from '../../../redux/actions/settingActions';
import { getFinancialAidSetting } from '../../../helpers/settingsHelper';
import {
  isFreeElective,
  updateCurrentStudent,
  getDefaultProgramMapId,
  getStudentProgramMapIdByCatalog,
} from '../../../helpers/studentsHelper';
import DeclareBannerAlert from '../../../components/DeclareBannerAlert';
import { Warning } from '@mui/icons-material/';
import { studentPlanningStyles } from './StudentPlanningStyles';
import { joinStringsGrammatically } from '../../../helpers/common';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import DeclareFreeElectivesFlyout from '../../../components/DeclareFreeElectivesFlyout';
import { getLearningActivitiesForGroup } from '../../../redux/actions/programMapActions';
import { getStatuses } from '../../../redux/actions/transferEquivalencyActions';
import { getTCECourseReqIds } from '../../../helpers/pathwayHelper';
import { getCurrentCatalog } from '../../../helpers/studentsHelper';
import PrintOutModal from './Components/PrintOutModal';

const ByTermView = forwardRef(
  ({ setActiveTab, setSelectedLearningActivity, studentStatusBarRef }, ref) => {
    const { orgId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = studentPlanningStyles();
    const { learningActivities } = useSelector((state) => state.allLearningActivities);
    const { studentEvaluations, completedCoursesRequirements: courseReq } = useSelector(
      (state) => state.transferEvaluation,
    );
    const { termPlanningStatus, pathwayStatus } = useSelector((state) => state.pathway);
    const [orderedPathway, setOrderedPathway] = useState([]);
    const [cachedPathway, setCachedPathway] = useState([]);
    const [studentPathway, setStudentPathway] = useState({});
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [flyoutTitle, setFlyoutTitle] = useState('');
    const [flyoutType, setFlyoutType] = useState(null);
    const { termList, termInstances } = useSelector((state) => state.terms);
    const {
      currentSelectedStudent,
      completedCourses: studentCompletedCourses,
      studentOrderedPathway,
      studentCompletedTerms: orderedPathwayFromState,
      list: studentList,
    } = useSelector((state) => state.students);
    const { programMapList } = useSelector((state) => state.programMaps);
    const { selectedOrg } = useSelector((state) => state.allOrgs);
    const { processed } = useSelector((state) => state.settings);
    const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
    const { teqStatusesEnum, teqStatuses } = useSelector((state) => state.transferEquivalency);
    const financialAidValue = getFinancialAidSetting(processed);
    const [pathFormValues, setPathFormValues] = useState({});
    const [questions, setQuestions] = useState([]);
    const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
    const [answeredQuestions, setAnsweredQuestions] = useState({});
    const [activeQuestionAnswers, setActiveQuestionAnswers] = useState({});
    const [groupedCompletedExemptedCourses, setGroupCompletedExemptedCourses] = useState({});
    const [completedCoursesLoaded, setCompletedCoursesLoaded] = useState(false);
    const [selectedProgramMap, setSelectedProgramMap] = useState(null);
    const [selectedCourseItems, setSelectedCourseItems] = useState([]);
    const [courseUsage, setCourseUsage] = useState(null);
    const [studentCompletedTerms, setStudentCompletedTerms] = useState([]);
    const [completedCardHeight, setCompletedCardHeight] = useState('400px');
    //pdf related states
    const HTTP_STATUS_SUCCESS = 200;
    const HTTP_STATUS_BAD_REQUEST = 400;
    const DEFAULT_LOGO = window.origin + '/logo.png';
    const componentRef = useRef(null);
    const [orgPdfLogo, setOrgPdfLogo] = useState(null);
    const [isPathwayGenerating, setIsPathwayGenerating] = useState(false);
    const [resetPathwayBannerVisible, setResetPathwayBannerVisible] = useState(false);
    const [rebuildPathwayBannerVisible, setRebuildPathwayBannerVisible] = useState(false);
    const [rebuildExplorePathwayBannerVisible, setRebuildExplorePathwayBannerVisible] =
      useState(false);
    const [invalidCoursesDueToCSs, setInvalidCoursesDueToCSs] = useState([]);
    const [doNotValidate, setDoNotValidate] = useState(false);
    const [freeElectiveData, setFreeElectiveData] = useState({
      flyOutOpen: false,
      groupId: null,
      selectedCourse: null,
      termInstanceId: null,
      swapFrom: null,
    });
    const [rebuildExplorePathwayBannerMessage, setRebuildExplorePathwayBannerMessage] =
      useState(null);
    const [openPrintModal, setOpenPrintModal] = useState(false);
    const [pdfContentAvailable, setPdfContentAvailable] = useState(false);
    const [totalUnits, setTotalUnits] = useState(0);
    const [pathwayDataLoaded, setPathwayDataLoaded] = useState(false);

    useEffect(() => {
      if (_.isEmpty(teqStatuses)) {
        dispatch(getStatuses(orgId));
      }

      if (_.isEmpty(studentEvaluations)) {
        dispatch(getStudentTransferEvaluations(orgId, currentSelectedStudent.id));
      }

      dispatch(getSettings(orgId));

      if (!_.isEmpty(currentSelectedStudent)) {
        const { id: studentId } = currentSelectedStudent;
        if (_.isUndefined(currentSelectedStudent.refreshData)) {
          setPathwayDataLoaded(false);
          dispatchGetStudentPathway(currentSelectedStudent);
          setCompletedCoursesLoaded(false);
          dispatch(getCompletedCourseReq(orgId, studentId, handleCompletedCourseResponse));
        }
      }

      if (_.isEmpty(termPlanningStatus)) {
        dispatch(getTermPlanningStatus(orgId));
      }

      if (_.isEmpty(termList)) {
        dispatch(getAllTerms(orgId));
      }

      if (_.isEmpty(learningActivities)) {
        dispatch(getLearningActivities(orgId));
      }

      if (_.isEmpty(pathwayStatus)) {
        dispatch(getPathwayStatus(orgId));
      }
      if (_.isEmpty(catalogs)) {
        dispatch(getCatalogs(orgId));
      }
    }, []);

    useEffect(() => {
      if (!_.isEmpty(orderedPathway) && !doNotValidate) {
        const orderedPathwayTermsHasCompletedTerms = doesOrderedPathwayOverlapsWithCompletedTerms(
          orderedPathway,
          studentCompletedTerms,
        );
        if (orderedPathwayTermsHasCompletedTerms) {
          setResetPathwayBannerVisible(orderedPathwayTermsHasCompletedTerms);
        } else {
          setResetPathwayBannerVisible(false);
        }
      }
      if (_.isEmpty(orderedPathway)) {
        setResetPathwayBannerVisible(false);
      }
    }, [studentCompletedTerms, orderedPathway]);

    useEffect(() => {
      const transferredAndCompletedUnits =
        calculateTotalUnitsOfTransferredCompletedCourses(courseReq);
      const completedUnits = transferredAndCompletedUnits.Completed;
      const transferredUnits = transferredAndCompletedUnits.Transferred;
      const totalCompletedTermUnits = calculateTotalUnits(studentCompletedTerms);
      const totalOrderedPathwayUnits = calculateTotalUnits(orderedPathway);
      const totalUnits =
        completedUnits + transferredUnits + totalCompletedTermUnits + totalOrderedPathwayUnits;
      setTotalUnits(totalUnits);
    }, [studentOrderedPathway, studentCompletedTerms, courseReq, orderedPathway]);

    const calculateTotalUnitsOfTransferredCompletedCourses = (data) => {
      const targetItem = getLatestOrFirstItem(data);

      const totalUnits = ['Completed', 'Transferred'].reduce((acc, key) => {
        acc[key] =
          targetItem?.assignments && targetItem?.assignments[key]
            ? targetItem?.assignments[key].reduce((sum, course) => sum + course.units, 0)
            : 0;
        return acc;
      }, {});

      return totalUnits;
    };

    const getLatestOrFirstItem = (data) => {
      if (data.length === 0) {
        return null;
      }

      if (data.length === 1) {
        return data[0];
      }

      return data.reduce((latest, current) =>
        new Date(latest.createdDateTime) > new Date(current.createdDateTime) ? latest : current,
      );
    };

    const calculateTotalUnits = (data) =>
      data.reduce(
        (total, current) => total + current.items.reduce((sum, item) => sum + item.units, 0),
        0,
      );

    const resetRebuildPathwayStatuses = () => {
      setInvalidCoursesDueToCSs([]);
      setRebuildPathwayBannerVisible(false);
      setRebuildExplorePathwayBannerVisible(false);
    };

    const dispatchToUpdateStudentPathway = (previousStatus, newPathwayStatus) => {
      if (previousStatus.code !== newPathwayStatus.code) {
        const { id: studentId, programOption } = currentSelectedStudent;
        const dataToUpdatePathway = {
          statusCode: newPathwayStatus.code,
          previousStatus: previousStatus.code,
        };
        dispatch(updateStudentPathway(orgId, studentId, programOption.id, dataToUpdatePathway));
      }
    };

    const checkValidatePathwayCallbackFailed = (data) => {
      if (data.data.errorCode === INVALID_PATHWAY_DUE_TO_MISSING_COURSES) {
        setRebuildExplorePathwayBannerVisible(true);
        setRebuildExplorePathwayBannerMessage(
          data.data.message ||
            "This plan needs to be updated as a course(s) is marked as incomplete and can't be added again in the available terms.",
        );
      } else if (data.data.errorCode === INVALID_PATHWAY_DUE_TO_UNITS_MISMATCH) {
        setRebuildExplorePathwayBannerVisible(true);
        setRebuildExplorePathwayBannerMessage(
          'This pathway exceeds the max number of units allowed for this program.',
        );
      } else if (data.data.errorCode === INVALID_PATHWAY_DUE_TO_PRE_REQUISITE) {
        setRebuildExplorePathwayBannerVisible(true);
        setRebuildExplorePathwayBannerMessage(data.data.message);
      } else if (!resetPathwayBannerVisible) {
        setRebuildPathwayBannerVisible(true);
        setInvalidCoursesDueToCSs(data.data.errorData);
      }
    };

    const checkRetakeCourseCallbackFailed = (data) => {
      if (!rebuildExplorePathwayBannerVisible) {
        setRebuildExplorePathwayBannerVisible(true);
        setRebuildExplorePathwayBannerMessage(
          "This plan needs to be updated as a course(s) is marked as incomplete and can't be added again in the available terms.",
        );
        saveGeneratedPathway(
          data.data.errorData.additionalInfo?.usedRequirementTransfers,
          data.data.errorData.terms,
          data.data.errorData.questions,
          () => setDoNotValidate(false),
          studentPathwayStatusTypes.PATHWAY_INVALID,
        );
      }
    };

    const successCallback = () => {
      const inPlanningPathwayStatus = pathwayStatus.find(
        (data) => data.type === studentPathwayStatusTypes.IN_PLANNING,
      );
      const previousStatus = currentSelectedStudent.plannedStatus;
      const orderedPathwayTermsHasCompletedTerms = doesOrderedPathwayOverlapsWithCompletedTerms(
        orderedPathway,
        studentCompletedTerms,
      );
      if (
        previousStatus.type === studentPathwayStatusTypes.PATHWAY_INVALID &&
        !orderedPathwayTermsHasCompletedTerms &&
        _.isEmpty(cachedPathway)
      ) {
        dispatchToUpdateStudentPathway(previousStatus, inPlanningPathwayStatus);
        setRebuildPathwayBannerVisible(false);
        setInvalidCoursesDueToCSs([]);
        setRebuildExplorePathwayBannerMessage(null);
        setRebuildExplorePathwayBannerVisible(false);
        currentSelectedStudent.plannedStatus = inPlanningPathwayStatus;
        dispatch(updateSelectedStudent(currentSelectedStudent));
      }
    };

    useEffect(() => {
      if (!_.isNil(studentOrderedPathway)) {
        const optionId = studentOrderedPathway.programOptionId;
        setSelectedProgramMap(() => programMapList.find((pm) => pm.optionId == optionId));
      }
    }, [programMapList, studentOrderedPathway]);

    useEffect(() => {
      if (
        componentRef.current != null &&
        !_.isEmpty(orderedPathway) &&
        pdfContentAvailable &&
        !_.isNil(orgPdfLogo)
      ) {
        handlePrint();
      }
    }, [componentRef, pdfContentAvailable, orderedPathway, orgPdfLogo]);

    useEffect(() => {
      if (!_.isEmpty(studentPathway) && !isPathwayGenerating) {
        dispatch(
          getPathwayCourseUsage(
            orgId,
            currentSelectedStudent.id,
            studentPathway.id,
            (courseUsage) => {
              setCourseUsage(Object.values(courseUsage));
            },
          ),
        );
      }
    }, [studentPathway]);

    useEffect(() => {
      if (
        !_.isEmpty(orderedPathway) &&
        !doNotValidate &&
        completedCoursesLoaded &&
        pathwayDataLoaded &&
        !_.isEmpty(studentOrderedPathway)
      ) {
        resetRebuildPathwayStatuses();
        const { programMapId } = buildPathwayData(
          currentSelectedStudent,
          programMapList,
          null,
          null,
          null,
          catalogs,
        );
        dispatch(
          validatePathway(
            orgId,
            includeTermInstancesInPathway(orderedPathway, termInstances),
            getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
            studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
            programMapId,
            (res) => {
              if (res.code === HTTP_STATUS_BAD_REQUEST) {
                checkValidatePathwayCallbackFailed({ data: res });
              }
            },
            checkValidatePathwayCallbackFailed,
            successCallback,
          ),
        );
      }
    }, [completedCoursesLoaded, doNotValidate, pathwayDataLoaded]);

    useEffect(() => {
      if (
        !_.isEmpty(termList) &&
        !_.isEmpty(learningActivities) &&
        !_.isEmpty(studentOrderedPathway) &&
        !isPathwayGenerating
      ) {
        const data = transformPathwayData(studentOrderedPathway, termList, learningActivities);
        setOrderedPathwayData(data);
      }
    }, [studentPathway, termList, learningActivities, studentOrderedPathway]);

    useEffect(() => {
      if (
        !_.isUndefined(currentSelectedStudent?.refreshData) &&
        currentSelectedStudent?.refreshData
      ) {
        setCompletedCoursesLoaded(false);
        const { id: studentId, programOption } = currentSelectedStudent;
        dispatch(getPathway(orgId, studentId, programOption.id, processPreferredProgramChange));
        dispatch(getCompletedCourseReq(orgId, studentId, handleCompletedCourseResponse));
        const clonedCurrentStudent = _.cloneDeep(currentSelectedStudent);
        delete clonedCurrentStudent.refreshData;
        dispatch(updateSelectedStudent(clonedCurrentStudent));
        setResetPathwayBannerVisible(false);
      }
    }, [currentSelectedStudent]);

    useEffect(() => {
      const { plannedStatus } = currentSelectedStudent;
      if (!_.isNil(plannedStatus) && plannedStatus?.type === studentPathwayStatusTypes.NEW) {
        setRebuildPathwayBannerVisible(false);
        setRebuildExplorePathwayBannerVisible(false);
        setInvalidCoursesDueToCSs([]);
      }
    }, [currentSelectedStudent?.plannedStatus]);

    useImperativeHandle(ref, () => ({
      fetchStudentPathwayAfterResetting() {
        setOrderedPathway([]);
        dispatchGetStudentPathway(currentSelectedStudent);
        const status = pathwayStatus.find((data) => data.type === studentPathwayStatusTypes.NEW);
        const currentStudent = {
          ...currentSelectedStudent,
          currentCatalog: getCurrentCatalog(
            programMapList,
            latestCatalog,
            null,
            catalogs,
            currentSelectedStudent.catalogId,
          ),
          programMapId: null,
        };
        updateCurrentStudent(currentStudent, studentList, status, dispatch);
      },
      openPrintModal() {
        setOpenPrintModal(true);
      },
    }));

    const printPdf = () => {
      setPdfContentAvailable(true);
      setOpenPrintModal(false);
    };

    const dispatchGetStudentPathway = (currentSelectedStudent) => {
      const { id: studentId, programOption } = currentSelectedStudent;
      dispatch(getPathway(orgId, studentId, programOption.id, studentPathwayCallback));
      setIsPathwayGenerating(false);
    };

    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
    }, [componentRef.current]);

    const handleAfterPrint = React.useCallback(() => {
      dispatch(setLoadingStatus(false));
      setPdfContentAvailable(false);
    }, []);

    const handleBeforePrint = React.useCallback(() => {
      dispatch(setLoadingStatus(true));
    }, []);

    const processPreferredProgramChange = (pathwayData, completedTerms = []) => {
      setDoNotValidate(true);
      transformCompletedTerms(completedTerms);
      if (_.isEmpty(pathwayData)) {
        setOrderedPathwayData(pathwayData);
        return setDoNotValidate(false);
      }
      const data = transformPathwayData(pathwayData, termList, learningActivities);
      setOrderedPathwayData(data);
      setStudentPathway(pathwayData);
      setDoNotValidate(false);
      setPathwayDataLoaded(true);
    };

    const addItemsToSelectedCourseList = (multiSelect, item) => {
      const itemList = [...selectedCourseItems];
      const itemIndex = selectedCourseItems.findIndex((course) => course.laId == item.laId);
      if (multiSelect) {
        if (itemIndex > -1) {
          itemList.splice(itemIndex, 1);
          setSelectedCourseItems(itemList);
        } else {
          setSelectedCourseItems([...itemList, item]);
        }
      } else {
        setSelectedCourseItems([item]);
      }
    };

    const handleCompletedCourseResponse = (response) => {
      setCompletedCoursesLoaded(false);
      const data = Object.values(response);
      if (!_.isEmpty(data)) {
        const latestEvaluationAssignments = data[0].assignments;
        setGroupCompletedExemptedCourses(latestEvaluationAssignments);
        dispatch(setCompletedCourses(latestEvaluationAssignments));
      } else {
        setGroupCompletedExemptedCourses({});
      }
      setCompletedCoursesLoaded(true);
    };

    const triggerMarkCourseAsIncomplete = (la, { termId, term: { year: termYear } }) => {
      const { programMapId } = buildPathwayData(
        currentSelectedStudent,
        programMapList,
        null,
        null,
        null,
        catalogs,
      );

      setDoNotValidate(true);
      dispatch(
        markCourseAsIncomplete(
          orgId,
          markCourseAsIncompletedOrCompletedInPathway(
            includeTermInstancesInPathway(
              studentCompletedTerms.concat(orderedPathway),
              termInstances,
            ),
            la.id,
            termId,
            termYear,
          ),
          getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          programMapId,
          la.id,
          (response) => {
            showRetakeCourseSuccessfulBanner(response.terms, la);
            saveGeneratedPathway(
              response.additionalInfo?.usedRequirementTransfers,
              response.terms,
              response.questions,
              (pathway) => {
                const { pathway: pathwayData, completedTerms } = pathway;
                studentPathwayCallback(pathwayData, completedTerms);
                setDoNotValidate(false);
              },
            );
          },
          checkRetakeCourseCallbackFailed,
        ),
      );
    };

    const showRetakeCourseSuccessfulBanner = (pathwayTerms, { id: courseId, code }) => {
      const addedPathwayTerm = pathwayTerms.find((term) =>
        term.items.some(({ id, additionalInfo }) => id === courseId && additionalInfo?.retaken),
      );
      const termInstance = termInstances[addedPathwayTerm.term.termInstanceId];
      dispatch(
        setSuccess({
          message: `${code} ${t('has been added to')} ${termInstance.displayName}.`,
        }),
      );
    };

    const completeAnIncompletedCourse = (la, { termId, term: { year: termYear } }) => {
      const mergedOrderedPathway = studentCompletedTerms.concat(orderedPathway);
      setDoNotValidate(true);
      saveGeneratedPathway(
        studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
        markCourseAsIncompletedOrCompletedInPathway(
          mergedOrderedPathway,
          la.id,
          termId,
          termYear,
          false,
        ),
        null,
        (pathway) => {
          const { pathway: pathwayData, completedTerms } = pathway;
          studentPathwayCallback(pathwayData, completedTerms);
          setDoNotValidate(false);
        },
      );
    };

    const removeFreeElectiveFromPathway = (la, termData) => {
      const { termId, term: { year: termYear } = {} } = termData;
      const mergedOrderedPathway = studentCompletedTerms.concat(orderedPathway);
      setDoNotValidate(true);
      saveGeneratedPathway(
        studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
        removeCourseFromPathway(mergedOrderedPathway, la.id, termId, termYear),
        null,
        (pathway) => {
          const { pathway: pathwayData, completedTerms } = pathway;
          studentPathwayCallback(pathwayData, completedTerms);
          setDoNotValidate(false);
        },
      );
    };

    const resetSelectedCourseItems = () => {
      setSelectedCourseItems([]);
    };

    useEffect(async () => {
      if (!_.isNil(selectedOrg.logo)) {
        await fetch(selectedOrg.logo, { method: 'HEAD', cache: 'no-store' })
          .then((data) => {
            if (data.status === HTTP_STATUS_SUCCESS) {
              setOrgPdfLogo(selectedOrg.logo);
            } else {
              setOrgPdfLogo(DEFAULT_LOGO);
            }
          })
          .catch(() => {
            setOrgPdfLogo(DEFAULT_LOGO);
          });
      } else {
        setOrgPdfLogo(DEFAULT_LOGO);
      }
    }, [selectedOrg]);

    const handlePrint = useReactToPrint({
      content: reactToPrintContent,
      // eslint-disable-next-line prettier/prettier
      documentTitle: `${selectedOrg.initials}-${currentSelectedStudent?.name
        ?.split(' ')
        .join('-')}-${currentSelectedStudent.studentId}-${t('Study-Plan')}`,
      onBeforePrint: handleBeforePrint,
      onAfterPrint: handleAfterPrint,
      removeAfterPrint: true,
    });

    const studentPathwayCallback = (data, completedTerms = []) => {
      transformCompletedTerms(completedTerms);
      setStudentPathway(data);
      setPathwayDataLoaded(true);
    };

    const transformCompletedTerms = (completedTerm) => {
      const data = transformPathwayData({ terms: completedTerm }, termList, learningActivities);
      return setStudentCompletedTerms(data);
    };

    // eslint-disable-next-line prettier/prettier
    const saveGeneratedPathway = (
      usedRequirementTransfers = null,
      orderedPathway,
      questions = null,
      callback = () => {},
      pathwayStatus = studentPathwayStatusTypes.IN_PLANNING,
    ) => {
      const preprocessedPathwayData = preprocessPathwayData(orderedPathway);
      dispatch(
        savePathway(
          orgId,
          currentSelectedStudent.id,
          generatePathwayData(
            usedRequirementTransfers,
            preprocessedPathwayData,
            termPlanningStatus,
            courseReq,
            currentSelectedStudent,
            currentSelectedStudent.programMapId
              ? currentSelectedStudent.programMapId
              : !_.isNil(currentSelectedStudent.catalogId)
              ? getStudentProgramMapIdByCatalog(
                  programMapList,
                  currentSelectedStudent.programOption?.id,
                  currentSelectedStudent.catalogId,
                )
              : getDefaultProgramMapId(
                  programMapList,
                  catalogs,
                  currentSelectedStudent.programOption?.id,
                ),
            answeredQuestions,
            questions,
            pathwayStatus,
          ),
          callback,
        ),
      );
    };

    const pathwayCallback = (pathway) => {
      const studentId = currentSelectedStudent.id;
      setIsPathwayGenerating(true);
      const { terms: orderedPathway, questionWarnings, usedRequirementTransfers } = pathway;
      const pathwayQuestions = questionWarnings.filter(
        (pathwayQues) => pathwayQues.type === pathwayQuestionTypes.QUESTION,
      );
      const terms = markRetakenCoursesInPathway(
        orderedPathway,
        studentOrderedPathway,
        orderedPathwayFromState,
      );
      setCachedPathway(terms);
      if (!_.isEmpty(questionWarnings)) {
        setQuestions(pathwayQuestions);
        setQuestionDialogOpen(true);
      } else {
        setIsPathwayGenerating(false);
        const callback = (pathway) => {
          const { pathway: pathwayData, completedTerms } = pathway;
          const inPlanningPathwayStatus = pathwayStatus.find(
            (data) => data.type === studentPathwayStatusTypes.IN_PLANNING,
          );
          dispatch(
            updateSelectedStudent({
              ...currentSelectedStudent,
              plannedStatus: inPlanningPathwayStatus,
              programMapId: pathwayData.programMapId,
            }),
          );
          studentPathwayCallback(pathwayData, completedTerms);
          setCompletedCourseUsages(pathwayData, studentId);
        };
        saveGeneratedPathway(
          usedRequirementTransfers,
          terms,
          questions,
          callback,
          studentPathwayStatusTypes.IN_PLANNING,
        );
      }
    };

    const setCompletedCourseUsages = (pathway, studentId) => {
      const completedCourseUsage = generateCourseUsageData(
        courseReq,
        pathway.id,
        getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
      );
      const callbackSetCourseUsage = (data) => {
        setCourseUsage(data);
        setIsPathwayGenerating(false);
      };
      dispatch(
        savePathwayCourseUsage(
          orgId,
          studentId,
          pathway.id,
          { completedCourses: completedCourseUsage },
          callbackSetCourseUsage,
        ),
      );
    };

    const updateTermAssignment = (fromTermIndex, toTermIndex, courseReq, pathway) => {
      const { termId: sourceTermId } = pathway[fromTermIndex].term;
      const { termId: destinationTermId } = pathway[toTermIndex].term;
      const { id: courseReqId } = courseReq;

      const data = {
        assignment: [
          {
            sourceTermId,
            destinationTermId,
            courseReqId,
          },
        ],
      };
      dispatch(updatePathway(orgId, currentSelectedStudent.id, data));
    };

    const retrieveProgramMappingData = (data, callback = () => {}) => {
      const { programMapId } = currentSelectedStudent;
      dispatch(getLearningActivitiesForGroup(orgId, programMapId, data, callback));
    };

    const replaceFreeElectiveToPathway = (electiveData) => {
      const { programMapId } = currentSelectedStudent;
      let clonePathway = _.cloneDeep(orderedPathway);
      const updatedPathway = updatePathwayData(clonePathway, electiveData, termInstances);

      const displayFreeElectiveErrors = (data) => {
        if (data.code === 400) {
          dispatch(
            setError({
              message: `${t('Course is not available in this term')} - ${data.message}`,
            }),
          );
        }
      };

      const callback = (data) => {
        if (data.status) {
          saveGeneratedPathway(
            studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
            updatedPathway,
            null,
            transformNewPathwayCallback,
          );
        }
      };

      dispatch(
        validatePathway(
          orgId,
          includeTermInstancesInPathway(updatedPathway, termInstances),
          getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          programMapId,
          displayFreeElectiveErrors,
          () => {}, // needs this to avoid free elective error being automatically getting hidden
          (data) => callback(data),
        ),
      );
    };

    const transformNewPathwayCallback = (data) => {
      const { pathway: pathwayData } = data;
      setOrderedPathwayData(transformPathwayData(pathwayData, termList, learningActivities));
      setCompletedCourseUsages(pathwayData, currentSelectedStudent.id);
      // student completed terms have not been set using setStudentCompletedTerms as this callback is used for scenarios where completed terms are not changed
    };

    const addNewTerm = (data, index, regularTermOnly = false) => {
      const requestData = {
        lastTermYear: data.term.year,
        termId: data.term.id,
        regularTermOnly: regularTermOnly,
      };

      const callback = (newTerm) => {
        const newArray = [
          ...orderedPathway.slice(0, index + 1),
          newTerm,
          ...orderedPathway.slice(index + 1),
        ];
        saveGeneratedPathway(
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          newArray,
          null,
          transformNewPathwayCallback,
        );
      };

      dispatch(pathwayAddNewTerm(orgId, requestData, callback));
    };

    const removeTerm = (index) => {
      const pathwayElement = orderedPathway[index];
      if (_.isEmpty(pathwayElement.items)) {
        orderedPathway.splice(index, 1);
        const pathway = [...orderedPathway];
        saveGeneratedPathway(
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          pathway,
          null,
          transformNewPathwayCallback,
        );
      } else {
        dispatch(
          setError({
            persistErrors: true,
            message: 'You need to move courses before you can remove the term.',
          }),
        );
      }
    };

    const movePathwayItems = (toPath) => {
      let clonePathway = _.cloneDeep(orderedPathway);
      let movedItems = [];
      selectedCourseItems.forEach((element) => {
        const index = clonePathway[element.path[0]].items.findIndex(
          (item) => item.id == element.laId,
        );
        movedItems.push(clonePathway[element.path[0]].items.splice(index, 1));
        clonePathway[element.path[0]].units = clonePathway[element.path[0]].items.reduce(
          (total, num) => total + num.units,
          0,
        );
      });

      clonePathway[toPath].units = clonePathway[toPath].items.reduce(
        (total, num) => total + num.units,
        0,
      );

      clonePathway[toPath].items.push(...movedItems.flat(1));

      const callback = () => {
        setOrderedPathwayData(clonePathway);
        updateTermAssignments(toPath, movedItems.flat(), clonePathway);
      };
      const { programMapId } = buildPathwayData(
        currentSelectedStudent,
        programMapList,
        null,
        null,
        null,
        catalogs,
      );
      dispatch(
        validatePathway(
          orgId,
          includeTermInstancesInPathway(clonePathway, termInstances),
          getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          programMapId,
          callback,
        ),
      );
    };

    const updateTermAssignments = (toTermIndex, courseReq, pathway) => {
      let assignment = [];
      selectedCourseItems.forEach((element, index) => {
        const { termId: sourceTermId } = pathway[element.path[0]].term;
        const { termId: destinationTermId } = pathway[toTermIndex].term;
        const { id: courseReqId } = courseReq[index];

        assignment.push({
          sourceTermId,
          destinationTermId,
          courseReqId,
        });
      });

      const data = {
        assignment,
      };
      dispatch(updatePathway(orgId, currentSelectedStudent.id, data));
      resetSelectedCourseItems();
    };

    const movePathwayItem = (fromPath, toPath) => {
      let clonePathway = _.cloneDeep(orderedPathway);
      let movedItem = clonePathway[fromPath[0]].items.splice(fromPath[1], 1);
      clonePathway[toPath].items.push(movedItem[0]);
      clonePathway[fromPath[0]].units = clonePathway[fromPath[0]].items.reduce(
        (total, num) => total + num.units,
        0,
      );

      clonePathway[toPath].units = clonePathway[toPath].items.reduce(
        (total, num) => total + num.units,
        0,
      );

      const callback = () => {
        setOrderedPathwayData(clonePathway);
        updateTermAssignment(fromPath[0], toPath, movedItem[0], clonePathway);
      };
      const { programMapId } = buildPathwayData(
        currentSelectedStudent,
        programMapList,
        null,
        null,
        null,
        catalogs,
      );
      dispatch(
        validatePathway(
          orgId,
          includeTermInstancesInPathway(clonePathway, termInstances),
          getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          programMapId,
          callback,
        ),
      );
    };

    const sitOutTerm = (parentIndex) => {
      let clonePathway = _.cloneDeep(orderedPathway);
      if (_.isEmpty(clonePathway[parentIndex].items)) {
        clonePathway[parentIndex].termPlanningStatus = termPlanningStatusTypes.SIT_OUT;
        clonePathway[parentIndex].units = 0;
        clonePathway[parentIndex].items = [];
        setOrderedPathwayData(clonePathway);
        const termId = clonePathway[parentIndex].term.termId;
        const { code: statusCode } = termPlanningStatus.find(
          ({ type }) => type === termPlanningStatusTypes.SIT_OUT,
        );
        const data = {
          termId,
          statusCode,
        };
        dispatch(updatePathway(orgId, currentSelectedStudent.id, data));
      } else {
        dispatch(
          setError({
            persistErrors: true,
            message:
              'You need to move the planned courses before you can mark the term as Sit Out.',
          }),
        );
      }
    };

    const undoSitOutTerm = (parentIndex) => {
      let clonePathway = _.cloneDeep(orderedPathway);
      clonePathway[parentIndex].termPlanningStatus = termPlanningStatusTypes.AUTO_PLANNED;
      setOrderedPathwayData(clonePathway);

      const termId = clonePathway[parentIndex].term.termId;
      const { code: statusCode } = termPlanningStatus.find(
        ({ type }) => type === termPlanningStatusTypes.AUTO_PLANNED,
      );
      const data = {
        termId,
        statusCode,
      };

      dispatch(updatePathway(orgId, currentSelectedStudent.id, data));
    };

    const saveCourses = (selectedCourses) => {
      if (_.isEmpty(groupedCompletedExemptedCourses)) {
        dispatch(
          postCompletedCourseReq(
            orgId,
            currentSelectedStudent.id,
            { laId: selectedCourses },
            () => {
              dispatch(
                getCompletedCourseReq(
                  orgId,
                  currentSelectedStudent.id,
                  handleCompletedCourseResponse,
                ),
              );
            },
          ),
        );
      } else {
        dispatch(
          patchCompletedCourseReq(
            orgId,
            currentSelectedStudent.id,
            courseReq[0].id,
            { laId: selectedCourses, type: flyoutType },
            () => {
              dispatch(
                getCompletedCourseReq(
                  orgId,
                  currentSelectedStudent.id,
                  handleCompletedCourseResponse,
                ),
              );
            },
          ),
        );
      }
    };

    const handleQuestionDialogClose = () => {
      setQuestionDialogOpen(false);
    };

    const handleQuestionDialogCancel = () => {
      setQuestionDialogOpen(false);
      setAnsweredQuestions({});
      setActiveQuestionAnswers({});
    };

    const handleQuestionSubmit = () => {
      setQuestionDialogOpen(false);

      const isEveryAnswerPositive = Object.entries(activeQuestionAnswers).every((item) => {
        return item[1] == true;
      });

      if (!isEveryAnswerPositive) {
        const pathwayData = _.cloneDeep(pathFormValues);
        pathwayData['questionResponses'] = answeredQuestions;
        dispatchStudentOrderedPathway(pathwayData);
      } else {
        const callback = (data) => {
          setOrderedPathwayData(transformPathwayData(data, termList, learningActivities));
        };
        saveGeneratedPathway(
          studentOrderedPathway.additionalInfo?.usedRequirementTransfers,
          cachedPathway,
          questions,
          callback,
        );
      }
      setActiveQuestionAnswers({});
    };

    const toggleCompletedStatus = (term, items = [], termCompleted = false) => {
      if (termCompleted) {
        const incompletedCourseIndex = items.findIndex(
          ({ additionalInfo }) => additionalInfo?.incompleted,
        );
        if (incompletedCourseIndex > -1) {
          dispatch(
            setError({
              message: t(
                'You need to undo incomplete courses within this term before you mark this term as incomplete.',
              ),
            }),
          );
          return;
        }
      } else {
        const unassignedFreeElective = items.findIndex(({ additionalInfo }) =>
          isFreeElective(additionalInfo),
        );
        if (unassignedFreeElective > -1) {
          dispatch(
            setError({
              message: t(
                'You need to assign all manually planned courses within this term before you mark this term as incomplete.',
              ),
            }),
          );
          return;
        }
      }
      const mergedOrderedPathway = studentCompletedTerms.concat(orderedPathway);
      const currentIndex = mergedOrderedPathway.findIndex(
        ({ term: termData }) => termData.termId === term.termId,
      );
      const newStatus =
        mergedOrderedPathway[currentIndex].termPlanningStatus !== termPlanningStatusTypes.COMPLETED
          ? termPlanningStatusTypes.COMPLETED
          : termPlanningStatusTypes.AUTO_PLANNED;

      if (
        newStatus === termPlanningStatusTypes.AUTO_PLANNED &&
        mergedOrderedPathway
          .slice(currentIndex)
          .filter(
            ({ termPlanningStatus }) => termPlanningStatus === termPlanningStatusTypes.COMPLETED,
          ).length > 1
      ) {
        dispatch(
          setError({
            persistErrors: true,
            message: t(
              'You need to mark all terms after selected term as incomplete before you can mark selected term as incomplete term.',
            ),
          }),
        );
        return;
      }
      const updatePathwayCallBack = () => {
        const { id: studentId, programOption } = currentSelectedStudent;
        setDoNotValidate(false);
        dispatch(getPathway(orgId, studentId, programOption.id, studentPathwayCallback));
      };
      const { code: statusCode } = termPlanningStatus.find(({ type }) => type === newStatus);
      const { id: pathwayId, programOptionId } = studentPathway;
      const data = { termId: term.termId, statusCode, pathwayId, programOptionId };
      dispatch(updatePathway(orgId, currentSelectedStudent.id, data, updatePathwayCallBack));
    };

    const setOrderedPathwayData = (orderedPathway) => {
      if (_.isEmpty(orderedPathway)) {
        resetRebuildPathwayStatuses();
        setResetPathwayBannerVisible(false);
        setOrderedPathway([]);
      } else {
        setOrderedPathway(orderedPathway);
      }
    };

    useEffect(() => {
      if (!_.isEmpty(studentOrderedPathway)) {
        if (resetPathwayBannerVisible) {
          const pathwayStatusInvalid = pathwayStatus.find(
            (data) => data.type === studentPathwayStatusTypes.PATHWAY_INVALID,
          );
          updateCurrentStudent(
            currentSelectedStudent,
            studentList,
            pathwayStatus.find(({ type }) => type === studentPathwayStatusTypes.PATHWAY_INVALID),
            dispatch,
          );
          const previousStatus = currentSelectedStudent.plannedStatus;
          dispatchToUpdateStudentPathway(previousStatus, pathwayStatusInvalid);
        }
        if (
          (rebuildPathwayBannerVisible && !resetPathwayBannerVisible) ||
          rebuildExplorePathwayBannerVisible
        ) {
          const invalidPathwayStatus = pathwayStatus.find(
            (data) => data.type === studentPathwayStatusTypes.PATHWAY_INVALID,
          );
          const previousStatus = currentSelectedStudent.plannedStatus;
          dispatchToUpdateStudentPathway(previousStatus, invalidPathwayStatus);
          dispatch(
            updateSelectedStudent({
              ...currentSelectedStudent,
              plannedStatus: invalidPathwayStatus,
            }),
          );
        }
      }
    }, [
      resetPathwayBannerVisible,
      rebuildPathwayBannerVisible,
      rebuildExplorePathwayBannerVisible,
      studentOrderedPathway,
    ]);

    const dispatchStudentOrderedPathway = (pathwayData) => {
      dispatch(
        getStudentPathway(
          orgId,
          currentSelectedStudent.id,
          buildPathwayData(
            currentSelectedStudent,
            programMapList,
            pathwayData,
            getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
            financialAidValue,
            catalogs,
          ),
          pathwayCallback,
        ),
      );
    };

    const rebuildPathway = () => {
      const values = {
        selectedTerms: getPathwayTerms(orderedPathway, studentCompletedTerms, termInstances),
        rebuildPathway: true,
      };
      dispatchStudentOrderedPathway(values);
    };

    return (
      <>
        <QuestionDialog
          open={questionDialogOpen}
          questions={questions}
          answeredQuestions={answeredQuestions}
          setAnsweredQuestions={setAnsweredQuestions}
          activeQuestionAnswers={activeQuestionAnswers}
          setActiveQuestionAnswers={setActiveQuestionAnswers}
          handleClose={handleQuestionDialogClose}
          handleCancel={handleQuestionDialogCancel}
          handleSubmit={handleQuestionSubmit}
          pathFormValues={pathFormValues}
        />
        <PrintOutModal
          printPdf={printPdf}
          openPrintModal={openPrintModal}
          setOpenPrintModal={setOpenPrintModal}
          studentStatusBarRef={studentStatusBarRef}
        />
        <DeclareFlyout
          flyoutOpen={flyoutOpen}
          flyoutType={flyoutType}
          completedCourses={groupedCompletedExemptedCourses}
          setFlyoutOpen={setFlyoutOpen}
          title={flyoutTitle}
          confirmBtnLabel={t('Include')}
          handleConfirmAction={saveCourses}
        />
        <DeclareFreeElectivesFlyout
          freeElectiveData={freeElectiveData}
          setFreeElectiveData={setFreeElectiveData}
          confirmBtnLabel={t('Include')}
          replaceFreeElectiveToPathway={replaceFreeElectiveToPathway}
          learningActivities={learningActivities}
          retrieveProgramMappingData={retrieveProgramMappingData}
        />
        {resetPathwayBannerVisible && (
          <DeclareBannerAlert>
            <Box className={classes.warningBannerBox}>
              <Box sx={{ display: 'flex', justifyContent: 'start', flex: 8, gap: 2 }}>
                <Box className={classes.warningIconAndMessage}>
                  <Warning color="error" fontSize="medium" />
                </Box>
                <Box className={classes.warningIconAndMessage}>
                  <Typography
                    color="error"
                    fontWeight={800}
                    fontSize="large"
                    sx={{ lineHeight: '1.2', textOverflow: 'ellipsis' }}>
                    {t(
                      "This pathway needs to be updated as the student's completed terms haven't been considered",
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.warningActionButton}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.warningPathwayBtn}
                  onClick={() => setActiveTab(StudentViewTabMenu[3].value)}>
                  <Typography sx={{ color: 'common.white' }}>{t('Explore')}</Typography>
                </Button>
              </Box>
            </Box>
          </DeclareBannerAlert>
        )}
        {rebuildPathwayBannerVisible && !resetPathwayBannerVisible ? (
          <DeclareBannerAlert>
            <Box className={classes.warningBannerBox}>
              <Box sx={{ display: 'flex', justifyContent: 'start', flex: 8, gap: 2 }}>
                <Box className={classes.warningIconAndMessage}>
                  <Warning color="error" fontSize="medium" />
                </Box>
                <Box className={classes.warningIconAndMessage}>
                  <Typography
                    fontWeight={400}
                    fontSize="large"
                    className={classes.rebuildBannerFontStyles}
                    sx={{ lineHeight: '1.2', textOverflow: 'ellipsis' }}>
                    {t('This plan needs to be updated due to schedule changes of') +
                      ' ' +
                      joinStringsGrammatically(
                        invalidCoursesDueToCSs.map((course) => {
                          return course.code;
                        }),
                        t('and'),
                      ) +
                      ' ' +
                      t('course(s).')}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.warningActionButton}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.warningPathwayBtn}
                  onClick={() => rebuildPathway()}>
                  <Typography sx={{ color: 'common.white' }}>{t('Rebuild Pathway')}</Typography>
                </Button>
              </Box>
            </Box>
          </DeclareBannerAlert>
        ) : null}

        {rebuildExplorePathwayBannerVisible && (
          <DeclareBannerAlert>
            <Box className={classes.warningBannerBox}>
              <Box sx={{ display: 'flex', justifyContent: 'start', flex: 8, gap: 2 }}>
                <Box className={classes.warningIconAndMessage}>
                  <Warning color="error" fontSize="medium" />
                </Box>
                <Box className={classes.warningIconAndMessage}>
                  <Typography
                    color="error"
                    fontWeight={800}
                    fontSize="large"
                    sx={{ lineHeight: '1.2', textOverflow: 'ellipsis' }}>
                    {t(rebuildExplorePathwayBannerMessage)}
                  </Typography>
                </Box>
              </Box>
              <Stack direction="row" gap={1}>
                <Box className={classes.warningActionButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.warningPathwayBtn}
                    onClick={() => setActiveTab(StudentViewTabMenu[3].value)}>
                    <Typography sx={{ color: 'common.white' }}>{t('Explore')}</Typography>
                  </Button>
                </Box>
                <Box className={classes.warningActionButton}>
                  <Button
                    variant="contained"
                    color="lightGrey"
                    className={classes.warningPathwayBtn}
                    onClick={() => rebuildPathway()}>
                    <Typography>{t('Rebuild Pathway')}</Typography>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </DeclareBannerAlert>
        )}

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingTop: 2,
            position: 'relative',
          }}
          onClick={() => {
            resetSelectedCourseItems();
          }}>
          <>
            <Box
              className={classes.pathwayOverlay}
              sx={{
                display:
                  resetPathwayBannerVisible ||
                  rebuildPathwayBannerVisible ||
                  rebuildExplorePathwayBannerVisible
                    ? 'block'
                    : 'none',
              }}></Box>
            <TermCardTransferred
              completedCardHeight={completedCardHeight}
              setFlyoutOpen={setFlyoutOpen}
              orderedPathway={orderedPathway}
              setFlyoutTitle={setFlyoutTitle}
              setFlyoutType={setFlyoutType}
              studentPathway={studentPathway}
              pathwayCallback={pathwayCallback}
              studentCompletedTerms={studentCompletedTerms}
              completedCourses={getTCECourseReqIds(
                courseReq,
                studentCompletedTerms.concat(orderedPathway),
              )}
              courseUsage={courseUsage}
              setDoNotValidate={setDoNotValidate}
              data={{
                term: {
                  name: t('Transferred, Completed & Exempt'),
                },
                items: groupedCompletedExemptedCourses,
              }}
              index={-1}
              ref={componentRef}
              handleCompletedCourseResponse={handleCompletedCourseResponse}
              pathwayAdditionalInfo={studentOrderedPathway.additionalInfo}
            />

            {studentCompletedTerms.map((data, index) => (
              <TermCard
                setCompletedCardHeight={setCompletedCardHeight}
                key={index}
                index={index}
                data={data}
                termData={{ id: data.termId, year: data.year }}
                orderedPathway={orderedPathway}
                setActiveTab={setActiveTab}
                setSelectedLearningActivity={setSelectedLearningActivity}
                selectedCourseItems={selectedCourseItems}
                toggleCompletedStatus={toggleCompletedStatus}
                invalidCoursesDueToCSs={invalidCoursesDueToCSs}
                completedCourses={getTCECourseReqIds(
                  courseReq,
                  studentCompletedTerms.concat(orderedPathway),
                )}
                markCourseAsIncomplete={triggerMarkCourseAsIncomplete}
                completeAnIncompletedCourse={completeAnIncompletedCourse}
              />
            ))}

            {orderedPathway.map((data, index) => (
              <TermCard
                setCompletedCardHeight={setCompletedCardHeight}
                key={index}
                index={index}
                data={data}
                termData={data.term}
                orderedPathway={orderedPathway}
                handlePathwayMove={movePathwayItem}
                handleMultipleMove={movePathwayItems}
                sitOutTerm={sitOutTerm}
                addNewTerm={addNewTerm}
                undoSitOutTerm={undoSitOutTerm}
                removeTerm={removeTerm}
                setActiveTab={setActiveTab}
                setSelectedLearningActivity={setSelectedLearningActivity}
                selectedCourseItems={selectedCourseItems}
                addItemsToSelectedCourseList={addItemsToSelectedCourseList}
                resetSelectedCourseItems={resetSelectedCourseItems}
                toggleCompletedStatus={toggleCompletedStatus}
                invalidCoursesDueToCSs={invalidCoursesDueToCSs}
                completedCourses={getTCECourseReqIds(
                  courseReq,
                  studentCompletedTerms.concat(orderedPathway),
                )}
                markCourseAsIncomplete={triggerMarkCourseAsIncomplete}
                completeAnIncompletedCourse={completeAnIncompletedCourse}
                freeElectiveData={freeElectiveData}
                setFreeElectiveData={setFreeElectiveData}
                totalUnits={totalUnits}
                totalProgramUnits={currentSelectedStudent.programOption?.units}
                removeCourseFromPathway={removeFreeElectiveFromPathway}
              />
            ))}
            {_.isEmpty(orderedPathway) && (
              <TermCardBuildPathway
                pathwayCallback={pathwayCallback}
                setPathFormValues={setPathFormValues}
                completedCourses={getTCECourseReqIds(
                  courseReq,
                  studentCompletedTerms.concat(orderedPathway),
                )}
                completedTerms={studentCompletedTerms}
              />
            )}
            {!_.isEmpty(orderedPathway) && (
              <TermCardAddNewTerm addTermCallBack={addNewTerm} orderedPathway={orderedPathway} />
            )}
          </>

          {!_.isEmpty(orderedPathway) && !_.isNil(selectedProgramMap) && !_.isNil(orgPdfLogo) && (
            <div style={{ display: 'none' }}>
              <ByTermPdfTemplateWrapper
                t={t}
                ref={componentRef}
                student={currentSelectedStudent}
                studentCompletedTerms={studentCompletedTerms}
                orderedPathway={orderedPathway}
                selectedOrg={selectedOrg}
                selectedProgramMap={selectedProgramMap}
                orgPdfLogo={orgPdfLogo}
                groupedCompletedExemptedCourses={studentCompletedCourses}
                teqStatusesEnum={teqStatusesEnum}
                pathwayAdditionalInfo={studentOrderedPathway.additionalInfo}
              />
            </div>
          )}
        </Box>
      </>
    );
  },
);

ByTermView.displayName = 'ByTermView';
export default ByTermView;
