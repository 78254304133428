import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Typography, Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import {
  getTransferEvaluationStatusList,
  postTransferEvaluation,
} from '../../../redux/actions/transferEvaluationActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { appRoutes } from '../../../helpers/routes';
import theme from '../../../theme';
import DeclareAutocomplete from '../../../components/DeclareAutocomplete';
import _ from 'lodash';
import { EvaluationStatusTypes, getStatus } from '../../../helpers/appConstants';
import { getStudentTeqAssignments } from '../../../redux/actions/studentActions';

const useStyle = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  button: {
    height: '45px',
    width: '150px',
  },
});

export default function Create() {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const ref = useRef();

  const programMaps = useSelector((state) => state.programMaps.programMapList);
  const { programList } = useSelector((state) => state.programs);
  const { currentSelectedStudent: selectedStudent } = useSelector((state) => state.students);
  const { catalogs } = useSelector((state) => state.allCatalogs);
  const { statusList } = useSelector((state) => state.transferEvaluation);
  const [catalogOpen, setCatalogOpen] = useState(false);
  const [programOptionOpen, setProgramOptionOpen] = useState(false);
  const [selectedProgramOption, setSelectedProgramOption] = useState({});
  const [processedCatalogs, setProcessedCatalogs] = useState([]);
  const [processedProgramList, setProcessedProgramList] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState({});

  useEffect(() => {
    if (_.isEmpty(programMaps)) {
      dispatch(getProgramMaps(orgId));
    }
    if (_.isEmpty(statusList)) {
      dispatch(getTransferEvaluationStatusList(orgId, selectedStudent.id));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(catalogs)) {
      processCatalogList();
    }
  }, [catalogs]);

  useEffect(() => {
    const declarableProgramOption = programList
      .flatMap(({ programOptions }) => programOptions)
      .filter(({ declarable }) => declarable);

    const programOptionsList = programMaps
      .filter(
        ({ catalogs, optionId }) =>
          catalogs[0].id === selectedCatalog.id &&
          declarableProgramOption.some((data) => data.id === optionId),
      )
      .map(({ id, programName, optionName, optionId }) => ({
        name: `${programName}, ${optionName}`,
        programMapId: id,
        optionId,
      }))
      .sort(({ name: nameA }, { name: nameB }) =>
        nameA.toLowerCase().localeCompare(nameB.toLowerCase()),
      );

    setProcessedProgramList(programOptionsList);
    setSelectedProgramOption(
      programOptionsList.find(({ optionId }) => optionId === selectedStudent.programOption.id),
    );
  }, [selectedCatalog]);

  const processCatalogList = () => {
    const studentCatalog = selectedStudent.currentCatalog;
    const catalogStatus = getStatus().reduce(
      (prev, curr) => Object.assign(prev, { [curr.value]: curr.value }),
      {},
    );
    const allActiveCatalogs = catalogs.filter(({ status }) => status === catalogStatus.ACTIVE);

    const result = allActiveCatalogs.map((data) => {
      const name = data.id === studentCatalog.id ? `${data.name} (${t("Student's")})` : data.name;
      return {
        ...data,
        name,
      };
    });

    setProcessedCatalogs(result);
    setSelectedCatalog(result.find(({ id }) => id === studentCatalog.id));
  };
  const saveTransferEvaluation = () => {
    const { id: studentId } = selectedStudent;
    const status = statusList.find(({ type }) => type === EvaluationStatusTypes.IN_PROGRESS);
    const data = {
      programMapId: selectedProgramOption.programMapId,
      unitsCovered: 0,
      statusCode: status.code,
      catalogId: selectedCatalog.id,
    };
    dispatch(postTransferEvaluation(orgId, studentId, data, navigateToViewTransferEvaluation));
  };

  const navigateToViewTransferEvaluation = ({ id, statusCode }) => {
    const callback = (tEqAssignment) => {
      if (_.isEmpty(tEqAssignment)) {
        navigate(generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, { orgId: orgId }));
      } else {
        navigate(
          generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_VIEW, {
            orgId: orgId,
            programMapId: selectedProgramOption.programMapId,
          }),
          {
            state: {
              ...selectedProgramOption,
              catalogId: selectedCatalog.id,
              evaluation: {
                id,
                status: { code: statusCode },
              },
            },
          },
        );
      }
    };
    const student = { ...selectedStudent, currentCatalog: selectedCatalog };
    dispatch(getStudentTeqAssignments(orgId, student, false, callback));
  };

  const navigateToListTransferEvaluation = () => {
    navigate(generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, { orgId: orgId }));
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <StudentStatusBar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),
          }}>
          <Typography color="primary" variant="h4" fontWeight={700}>
            {t('New Evaluation')}
          </Typography>
        </Box>
        <Box className={classes.body}>
          <DeclareAutocomplete
            sx={{
              width: '350px',
              marginBottom: '16px',
            }}
            onOpen={() => {
              setCatalogOpen(true);
            }}
            onBlur={() => {
              setCatalogOpen(false);
            }}
            open={catalogOpen}
            disableClearable
            getOptionLabel={(option) => option.name || ''}
            value={selectedCatalog}
            onChange={(_event, value) => {
              setSelectedCatalog(value);
              setCatalogOpen(false);
            }}
            inputlabel={<Typography fontWeight={600}>{`${t('Catalog')} *`}</Typography>}
            options={processedCatalogs}
            renderInput={(params) => (
              <TextField {...params} value={params.name} ref={(el) => (ref.current = el)} />
            )}
          />
          <DeclareAutocomplete
            sx={{
              width: '350px',
            }}
            onOpen={() => {
              setProgramOptionOpen(true);
            }}
            onBlur={() => {
              setProgramOptionOpen(false);
            }}
            open={programOptionOpen}
            disableClearable
            getOptionLabel={(option) => option.name || ''}
            value={selectedProgramOption}
            onChange={(_event, value) => {
              setSelectedProgramOption(value);
              setProgramOptionOpen(false);
            }}
            inputlabel={<Typography fontWeight={600}>{`${t('Program Option')} *`}</Typography>}
            options={processedProgramList}
            renderInput={(params) => (
              <TextField {...params} value={params.name} ref={(el) => (ref.current = el)} />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{ marginRight: '20px', borderRadius: 22, textTransform: 'capitalize' }}
            className={classes.button}
            onClick={() => saveTransferEvaluation()}>
            {t('Add Evaluation')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            sx={{ color: theme.palette.white.main, borderRadius: 22, textTransform: 'capitalize' }}
            className={classes.button}
            onClick={() => navigateToListTransferEvaluation()}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
