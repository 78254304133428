import { requestHandler, requestTypes } from '../helper';
import {
  requestGetOrgClassSchedules,
  requestGetOrgPlannedStudent,
  requestPatchClassSchedule,
} from '../requests/classSchedules';

export function* handleGetOrgClassSchedules(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetOrgClassSchedules.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
  );
}

export function* handlePatchClassSchedule(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPatchClassSchedule.bind(this, payload),
    null,
    requestTypes.PATCH,
    callback,
  );
}

export function* handleGetOrgPlannedStudents(action) {
  const { payload, callback } = action;
  const cb = (data) => {
    return callback(Object.values(data));
  };
  yield requestHandler(requestGetOrgPlannedStudent.bind(this, payload), null, requestTypes.GET, cb);
}
