import OrgError from '../../../error/OrgError';
import {
  INVALID_PATHWAY_DUE_TO_CLASS_SCHEDULES,
  INVALID_PATHWAY_DUE_TO_MISSING_COURSES,
  INVALID_PATHWAY_DUE_TO_UNITS_MISMATCH,
} from '../../../helpers/appConstants';
import PathwayService from '../../../services/PathwayService';

const pathwayService = new PathwayService();

export function* requestGetTermPlanningStatus(payload) {
  const { orgId } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.getTermPlanningStatus(orgId);
}

export function* requestGetPathwayStatus(payload) {
  const { orgId } = payload;
  if (!orgId) {
    throw new OrgError.orgRequired();
  }

  return yield pathwayService.getPathwayStatuses(orgId);
}

export function* requestPostPathways(payload) {
  const { orgId, studentId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.savePathways(orgId, studentId, body);
}

export function* requestPostPathwaysCourseUsage(payload) {
  const { orgId, studentId, pathwayId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.savePathwaysCourseUsage(orgId, studentId, pathwayId, body);
}

export function* requestGetPathwaysCourseUsage(payload) {
  const { orgId, studentId, pathwayId } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.getPathwaysCourseUsage(orgId, studentId, pathwayId);
}

export function* requestGetPathways(payload) {
  const { orgId, studentId, programOptionId } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.getPathways(orgId, studentId, programOptionId);
}

export function* requestPutPathway(payload) {
  const { orgId, studentId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.updatePathway(orgId, studentId, body);
}

export function* requestGetPathwaysToExplore(payload) {
  const { orgId, data } = payload;
  return yield pathwayService.explorePathways(orgId, data);
}

export function* requestValidatePathway(payload) {
  const {
    orgId,
    pathway,
    completedCourses,
    transferredRequirements,
    programMapId,
    errorCallback,
    successCallback,
  } = payload;
  return yield pathwayService
    .validatePathway(orgId, {
      pathway,
      completedCourses,
      transferredRequirements,
      programMapId,
    })
    .then((data) => {
      if (
        errorCallback &&
        !data.status &&
        data.data.code === 400 &&
        (data.data.errorCode === INVALID_PATHWAY_DUE_TO_CLASS_SCHEDULES ||
          data.data.errorCode === INVALID_PATHWAY_DUE_TO_MISSING_COURSES ||
          data.data.errorCode === INVALID_PATHWAY_DUE_TO_UNITS_MISMATCH)
      ) {
        errorCallback(data);
        return { ...data, status: true };
      } else if (
        successCallback &&
        (data.status ||
          (!data.status && data.data.errorCode !== INVALID_PATHWAY_DUE_TO_CLASS_SCHEDULES))
      ) {
        successCallback(data);
        return { ...data, status: true };
      } else {
        return data;
      }
    });
}

export function* requestPostResetPathway(payload) {
  const { orgId, studentId, pathwayId } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield pathwayService.resetPathway(orgId, studentId, pathwayId);
}

export function* requestMarkCourseAsIncomplete(payload) {
  const { orgId, courseId, pathway, completedCourses, programMapId, errorCallback } = payload;
  return yield pathwayService
    .markCourseAsIncomplete(orgId, {
      courseId,
      pathway,
      completedCourses,
      programMapId,
    })
    .then((data) => {
      if (!data.status && data.data.code == 400 && errorCallback) {
        errorCallback(data, pathway);
      }
      return data;
    });
}
