import TermsService from '../../../services/termsServices';
import OrgError from '../../../error/OrgError';

const termsService = new TermsService();

export function* requestGetTerms(param) {
  if (!param) {
    throw OrgError.orgRequired();
  }
  return yield termsService.getAll(param);
}

export function requesetPostTerm(payload) {
  const { org, data } = payload;
  return termsService.save(org, data);
}

export function requesetPutTerm(payload) {
  const { org, termId, data } = payload;
  return termsService.update(org, termId, data);
}

export function requestPutTermsOrder(payload) {
  const { org, data } = payload;
  return termsService.updateTermsOrder(org, data);
}

export function requestPutTermInstances(payload) {
  const { org, data } = payload;
  return termsService.updateTermInstances(org, data);
}

export function requestGetTermInstances(payload) {
  const { org } = payload;
  return termsService.getTermInstances(org);
}

export function requestPutCurrentTerm(payload) {
  const { org, data } = payload;
  return termsService.updateCurrentTerm(org, data);
}

export function requestPutCalendarYearStartTerm(payload) {
  const { org, data } = payload;
  return termsService.updateCalendarYearStartTerm(org, data);
}

export function requestPostPathwayNewTerm(payload) {
  const { orgId, data } = payload;
  return termsService.savePathwayNewTerm(orgId, data);
}

export function requestPostGetTermsOrder(payload) {
  const { orgId, data } = payload;
  return termsService.getTermsOrder(orgId, data);
}
