export const getStatus = () => [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Archived', value: 'ARCHIVED' },
  { label: 'Build', value: 'BUILD' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'QA', value: 'QA' },
];

export const CLASS_SCHEDULE_STATUS = {
  ACTIVE: { label: 'Active', value: 'ACTIVE' },
  APPROVED: { label: 'Approved', value: 'APPROVED' },
  ARCHIVED: { label: 'Archived', value: 'ARCHIVED' },
  BUILD: { label: 'Build', value: 'BUILD' },
  INACTIVE: { label: 'Inactive', value: 'INACTIVE' },
  QA: { label: 'QA', value: 'QA' },
  CANCEL: { label: 'Canceled', value: 'CANCEL' },
};

export const courseTypes = [
  { label: 'Lecture', value: 'LECTURE' },
  { label: 'Lab', value: 'LAB' },
  { label: 'Research', value: 'RESEARCH' },
  { label: 'Placement', value: 'PLACEMENT' },
  { label: 'Lecture and Lab', value: 'LECTURE_AND_LAB' },
  { label: 'Lecture and Practicum', value: 'LECTURE_AND_PRACTICUM' },
  { label: 'Practicum', value: 'PRACTICUM' },
  { label: 'Internship', value: 'INTERNSHIP' },
];

export const courseLevels = [
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '300', value: 300 },
  { label: '400', value: 400 },
  { label: '500', value: 500 },
  { label: '600', value: 600 },
  { label: '700', value: 700 },
  { label: '800', value: 800 },
  { label: '900', value: 900 },
];

export const departments = [
  { label: 'Department', value: 'DEPARTMENT' },
  { label: 'College', value: 'COLLEGE' },
  { label: 'School', value: 'SCHOOL' },
];

export const programTypes = [
  { label: 'Major', value: 'MAJOR' },
  { label: 'Minor', value: 'MINOR' },
  { label: 'Certificate', value: 'CERT' },
  { label: 'GE', value: 'GE' },
  { label: 'Other', value: 'OTHER' },
];

export const declarableValues = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const deliveryModes = [
  { label: 'Online', value: 'ONLINE' },
  { label: 'Offline', value: 'OFFLINE' },
  { label: 'Hybrid', value: 'HYBRID' },
  { label: 'Placement', value: 'PLACEMENT' },
];

// TODO: this is a temp list should fetch from the BE
export const termPlanningStatusTypes = {
  AUTO_PLANNED: 'AUTO_PLANNED',
  SIT_OUT: 'SIT_OUT',
  IN_PROGRESS: 'IN_PROGRESS',
  MANUALLY_PLANNED: 'MANUALLY_PLANNED',
  CONFIRMED_AND_SENT: 'CONFIRMED_AND_SENT',
  COMPLETED: 'COMPLETED',
};

// { label: 'Future Only', value: 'FUTURE ONLY' }   { label: 'Past Only', value: 'PAST ONLY' } hidden for production and UAT
export const TermOption = [{ label: 'All', value: 'ALL' }];

export const explorePathwayOptions = [{ label: 'Suggested Pathways', value: 'SUGGESTED_PATHWAYS' }];

export const TransferEquivalencyType = [
  { label: 'Course', value: 'COURSE' },
  { label: 'Requirement', value: 'REQUIREMENT' },
];

export const learningActivityType = {
  COURSE: 'COURSE',
  TEST: 'TEST',
};
export const EvaluationStatusTypes = {
  IN_PROGRESS: 'IN_PROGRESS',
  EVALUATION_SENT: 'EVALUATION_SENT',
  USED_IN_PLANNING: 'USED_IN_PLANNING',
  ARCHIVED: 'ARCHIVED',
};
export const StudentViewTabMenu = [
  {
    name: 'Pathway',
    value: 'PATHWAY',
    disable: true,
  },
  {
    name: 'By Term',
    value: 'BY_TERM',
    disable: false,
  },
  {
    name: 'By Requirement',
    value: 'BY_REQUIREMENT',
    disable: true,
  },
  {
    name: 'Explore',
    value: 'EXPLORE',
    disable: false,
  },
];

export const TermData = {
  studentId: '7565689',
  name: 'John Doe',
  terms: [
    {
      id: 1,
      statusCode: 400,
      name: 'Spring 2022',
      learningActivities: [
        {
          code: 'ACC 102',
          name: 'Intro to Accounting',
          units: 3,
        },
        {
          code: 'ACC 102',
          name: 'Intro to Business',
          units: 3,
        },
      ],
    },
    {
      id: 2,
      statusCode: 400,
      name: 'Summer 2022',
      learningActivities: [
        {
          code: 'ACC 102',
          name: 'Intro to Accounting',
          units: 3,
        },
        {
          code: 'ACC 102',
          name: 'Intro to Business',
          units: 3,
        },
        {
          code: 'ACC 102',
          name: 'Intro to Business',
          units: 3,
        },
      ],
    },
    {
      id: 3,
      statusCode: 400,
      name: 'Fall 2022',
      learningActivities: [
        {
          code: 'ACC 102',
          name: 'Intro to Accounting',
          units: 3,
        },
        {
          code: 'ACC 102',
          name: 'Intro to Business',
          units: 3,
        },
      ],
    },
    {
      id: 4,
      statusCode: 300,
      name: 'Spring 2023',
      learningActivities: [],
    },
  ],
  programOption: {
    id: '100A',
    name: 'Nursing',
    units: 120,
  },
  completedUnits: 100,
};

export const pathwayQuestionTypes = {
  QUESTION: 'QUESTION',
  WARNING: 'WARNING',
};
export const pathwayCourseCategory = {
  COMPLETED: 'Completed',
  TRANSFERRED: 'Transferred',
  EXEMPT: 'Exempt',
};

export const studentPathwayStatusTypes = {
  NEW: 'NEW',
  IN_PLANNING: 'IN_PLANNING',
  PATHWAY_DROPPED: 'PATHWAY_DROPPED',
  PATHWAY_COMPLETED: 'PATHWAY_COMPLETED',
  PATHWAY_SENT: 'PATHWAY_SENT',
  PATHWAY_INVALID: 'PATHWAY_INVALID',
  ARCHIVED: 'ARCHIVED',
};

export const statusesOfValidPathways = Object.values(studentPathwayStatusTypes).filter(
  (data) => data !== studentPathwayStatusTypes.NEW && data !== studentPathwayStatusTypes.ARCHIVED,
);

export const selectedTransferItemTypes = {
  PROGRAM_MAP: 'PROGRAM_MAP',
  TRANSFER_OPTION: 'TRANSFER_OPTIONS',
};

export const selectedItemTypes = {
  COURSE: 'COURSE',
  REQUIREMENT: 'REQUIREMENT',
};

export const studentListQuery = {
  ALL_STUDENTS: 'all_students',
  MY_STUDENTS: 'my_students',
};

export const allowedUserDomains = {
  GET_DECLARED_DOMAIN: 'getdeclared.com',
};

export const MAXIMUM_GENERATED_TERMS_IN_YEARS = 9;
export const LearningActivityCannotIncludeForCreditXIfStatements = [
  { label: 'Specific courses are in pathway', value: 'SPECIFIC_COURSES_ARE_IN_PATHWAY' },
];

export const DECLARE_LOGGED_IN_USER = 'Declare-Logged-In-User';
export const advisorStatusForStudents = {
  UNASSIGNED: 'Unassigned',
};

export const INVALID_PATHWAY_DUE_TO_PRE_REQUISITE = '10003';
export const INVALID_PATHWAY_DUE_TO_CLASS_SCHEDULES = '10004';
export const INVALID_PATHWAY_DUE_TO_MISSING_COURSES = '10005';
export const INVALID_PATHWAY_DUE_TO_UNITS_MISMATCH = '10006';

export const termsScheduleDateTypes = {
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
};

export const urlParamsToFilterStudentList = {
  ALL_STUDENTS: 'all',
  MY_STUDENTS: 'my',
  ALL: 'all',
};

export const localStorageKeys = {
  STUDENT_LIST_FILTER_PARAMS: 'studentListFilterParams',
  EQUIVALENCY_FILTER_PARAMS: 'equivalencyFilterParams',
};

export const noOfAdditionalValuesForFilterDropDowns = {
  ADVISOR: 1,
};

export const ALL_CATALOGS = 'All Catalogs';
export const ELECTIVES = 'Electives';
export const ASSIGN = 'Assign';

export const WAIT_UNTIL_SEARCH_MILLISECONDS = 400;

export const newTeqStatus = { name: 'New', type: 'NEW' };

export const transferringOptionTypes = {
  COURSE_ONLY: 'courseOnly',
  WITH_REQUIREMENT: 'withRequirement',
};

export const dropDownStyle = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  marginThreshold: 0,
};

export const REQUIREMENT_TYPE = {
  Course: 'Course',
};
