import { makeStyles } from '@mui/styles';

export const termCardStyles = makeStyles((theme) => ({
  termCard: {
    display: 'flex',
    height: 'auto',
    minHeight: theme.spacing(50),
    backgroundColor: theme.palette.white.main,
    width: '49%',
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    justifyContent: ({ center }) => (center ? 'center' : 'flex-start'),
  },
  termCardContent: {
    display: 'flex',
    height: theme.spacing(50),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  completedCourseCardContent: {
    display: 'flex',
    height: theme.spacing(50),
    flexDirection: 'column',
    justifyContent: 'center',
  },
  termCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  contextMenuBtn: {
    cursor: 'pointer',
    marginTop: theme.spacing(0.7),
  },
  sitOutBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  termCardButton: {
    width: '22em',
    '&.MuiButton-root': {
      height: theme.spacing(6),
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  termCardTransferredLaList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    overflowY: 'auto',
    flex: 1,
    justifyContent: ({ center }) => (center ? 'center' : 'flex-start'),
  },
  termCardButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& $termCardButtonItem': {
      marginBottom: theme.spacing(1),
    },
  },
  termCardAddNewIcon: {
    '&.MuiSvgIcon-root': {
      paddingTop: theme.spacing(2),
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(10),
      cursor: 'pointer',
    },
  },
  termCardAddNewText: {
    '&.MuiTypography-root': {
      marginLeft: theme.spacing(1),
      fontWeight: 700,
    },
  },
  termCardButtonItem: {},
}));
