import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import DeclaredSelect from '../../../components/DeclareSelect';
import { useTranslation } from 'react-i18next';

import { appRoutes } from '../../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus } from '../../../helpers/appConstants';
import { createProgramMap, editProgramMap } from '../../../redux/actions/programMapActions';
import DeclareTextField from '../../../components/DeclareTextField';

export default function ProgramMapForm(props) {
  const { currentProgramMap, isEditView } = props;
  const { orgId, programId } = useParams();
  const { values, setValues, handleInputChange } = useDeclareForm(currentProgramMap);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orgs = useSelector((store) => store.allOrgs);
  const formError = useSelector((store) => store.notifications.formError);
  const programList = useSelector((store) => store.programs.programList);
  const catalogList = useSelector((store) => store.allCatalogs.catalogs);

  const navigateToListView = () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_MAP_LIST, { orgId }));
  };

  useEffect(() => {
    setValues(currentProgramMap);
  }, [currentProgramMap]);

  const data = {
    programOption: values.programOption,
    units: Number(values.units),
    maxUnits: values.maxUnits === '' || values.maxUnits === null ? null : Number(values.maxUnits),
    catalogs: values.catalogs[0]?.id != undefined ? [values.catalogs[0]?.id] : [],
    status: values.status,
  };

  const saveProgramMap = async () => {
    dispatch(createProgramMap(orgs.selectedOrg.id, data, navigateToListView));
  };

  const updateProgramMap = async () => {
    dispatch(editProgramMap(orgs.selectedOrg.id, programId, data, navigateToListView));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclaredSelect
              inputlabel={t('Program Option') + ' *'}
              margin="dense"
              value={values.programOption}
              name="programOption"
              onChange={handleInputChange}
              error={formError['programOption']}
              helperText={formError['programOption']?.message}>
              {(programList || [])
                .reduce((options, { programOptions }) => {
                  options.push(...programOptions);
                  return options;
                }, [])
                .map(({ name, id }, index) => (
                  <MenuItem value={id} key={index}>
                    {name}
                  </MenuItem>
                ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={t('status') + ' *'}
              margin="dense"
              value={values.status}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {getStatus().map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
          <Grid item xs={6} className="right">
            <Grid container>
              <Grid item xs={6} className="left">
                <DeclareTextField
                  inputlabel={t('Min # of Units') + ' *'}
                  variant="outlined"
                  value={values.units}
                  name="units"
                  onChange={handleInputChange}
                  error={formError['units']}
                  helperText={formError['units']?.message}
                  type="number"
                />
              </Grid>
              <Grid item xs={6} className="right">
                <DeclareTextField
                  inputlabel={t('Max # of Units')}
                  variant="outlined"
                  value={values.maxUnits}
                  name="maxUnits"
                  onChange={handleInputChange}
                  error={formError['maxUnits']}
                  helperText={formError['maxUnits']?.message}
                  type="number"
                />
              </Grid>
            </Grid>
            <DeclaredSelect
              inputlabel={t('Catalog') + ' *'}
              margin="dense"
              value={values.catalogs}
              name="catalogs"
              onChange={(event) => {
                handleInputChange({ target: { name: 'catalogs', value: [event.target.value] } });
              }}
              error={formError['catalogs']}
              helperText={formError['catalogs']?.message}
              renderValue={(selected) => selected.map((catalog) => catalog.name).join(', ')}>
              {catalogList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateProgramMap() : saveProgramMap();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            navigateToListView();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
