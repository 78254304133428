import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLearningActivities } from '../../../redux/actions/learningActivityActions';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import TransferOptionLA from './TransferOptionLA';

export default function TransferOptionRaw(props) {
  const {
    matchingInProgress,
    OnClickTransferOptionCards,
    matchedOptions,
    disabledCourses,
    value: { code, name, units, equivalencies, id },
    selectedOrg,
    unUsedCourses,
    allCourseReq,
  } = props;

  const learningActivityStore = useSelector(
    (state) => state.allLearningActivities.learningActivities,
  );
  const dispatch = useDispatch();
  const { orgId } = useParams();

  useEffect(() => {
    if (!learningActivityStore.length > 0) {
      dispatch(getLearningActivities(orgId));
    }
  }, []);

  return _.map(equivalencies, (equivalency) => {
    return (
      <TransferOptionLA
        matchingInProgress={matchingInProgress}
        OnClickTransferOptionCards={OnClickTransferOptionCards}
        matchedOptions={matchedOptions}
        disabledCourses={disabledCourses}
        selectedOrg={selectedOrg}
        equivalency={equivalency}
        groupId={id}
        code={code}
        name={name}
        units={units}
        equivalencies={equivalencies}
        unUsedCourses={unUsedCourses}
        allCourseReq={allCourseReq}
      />
    );
  });
}
