import { actionTypes } from '../constants/actionTypes';

const initState = {
  inprogressLoadingOperations: {},
};

export const loadingReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING_STATUS:
      const { visibility, key = 'default' } = payload;
      const inprogressLoadingOperations = state.inprogressLoadingOperations;
      if (visibility) {
        inprogressLoadingOperations[key] = true;
      } else {
        delete inprogressLoadingOperations[key];
      }
      return { ...state, inprogressLoadingOperations };
    default:
      return state;
  }
};
