import { actionTypes } from '../constants/actionTypes';

const initState = {
  list: [],
};

const tagReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.TAGS.SET:
      return { ...state, list: payload };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};

export default tagReducer;
