import { Button, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

import { Operators } from '../helpers/ruleHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      margin: 5,
      height: '50px',
      borderRadius: '20px',
      textTransform: 'capitalize',
      fontWeight: 300,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  right: {
    float: 'right',
  },
  declareGrid: {
    '&:hover': {
      '& $removeItem': {
        display: 'inline-flex',
      },
    },
  },
  removeItem: {
    '&.MuiIconButton-root': {
      display: 'none',
      color: '#375065',
    },
  },
}));

export default function RuleButtonComponent(props) {
  const { selectedElementPath, uuid, data, selectedItemEditable = true } = props;

  //anchor element value will hold the selected element so that we can display the dropdown under the selected element on the dom
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setSelectedIndex(Operators.indexOf(props.operator));
  });

  const { t } = useTranslation();
  const isOperatorsMenuVisible = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    props.changeOperatorHandler(selectedElementPath, index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} className={classes.declareGrid}>
        <Grid item xs={3}>
          <Button
            id="basic-button"
            aria-controls={isOperatorsMenuVisible ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOperatorsMenuVisible ? 'true' : undefined}
            onClick={handleClick}
            disabled={!selectedItemEditable}
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            endIcon={<KeyboardArrowDownIcon />}>
            {Operators[selectedIndex]}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOperatorsMenuVisible}
            disabled={!selectedItemEditable}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            {Operators.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={8.43}>
          <Box className={classes.right}>
            {selectedElementPath.length === 0 && data.length == 0 ? (
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                disabled={!selectedItemEditable}
                onClick={() => {
                  return;
                }}>
                {t('Link Rule')}
              </Button>
            ) : (
              ''
            )}
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              disabled={!selectedItemEditable}
              onClick={() => {
                props.addRule(selectedElementPath, uuid);
              }}>
              {t('Add Rule')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              disabled={!selectedItemEditable}
              onClick={() => {
                props.addGroup(selectedElementPath, uuid);
              }}>
              {t('Add Rule Group')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={0.4}>
          {selectedElementPath.length != 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60px">
              <IconButton
                aria-label="remove"
                disabled={!selectedItemEditable}
                onClick={() => {
                  props.removeRuleOrGroupHandler(selectedElementPath);
                }}
                className={classes.removeItem}>
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
