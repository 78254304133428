import React, { useEffect, useState } from 'react';
import { useParams, matchPath, useLocation, useNavigate, generatePath } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import StatusCell from '../../../../components/StatusCell';
import { useDispatch, useSelector } from 'react-redux';
import DeclareDataTable from '../../../../components/DeclareDataTable';
import { appRoutes } from '../../../../helpers/routes';
import { getAllClassSchedules, getTerms } from '../../../../redux/actions/learningActivityActions';
import CreateEditClassSchedule from './CreateEditClassSchedule';
import { CLASS_SCHEDULE_STATUS, deliveryModes } from '../../../../helpers/appConstants';
import _ from 'lodash';
import { getAcademicYearsWithTermInstances } from '../../../../redux/actions/termActions';

export default function ClassScheduleList(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { currentLearningActivity } = props;
  const { orgId, lActivityId } = useParams();
  const navigate = useNavigate();
  const { classScheduleList } = useSelector((state) => state.allLearningActivities);
  const { termList: termsList, academicYears } = useSelector((state) => state.terms);
  const [transformedClassScheduleList, setTransformedClassScheduleList] = useState([]);
  const [termInstances, setTermInstances] = useState([]);
  const isClassScheduleAddView = matchPath(
    { path: appRoutes.catalog.CLASS_SCHEDULE_CREATE },
    pathname,
  );
  const isClassScheduleEditView = matchPath(
    { path: appRoutes.catalog.CLASS_SCHEDULE_EDIT },
    pathname,
  );

  useEffect(() => {
    if (_.isEmpty(classScheduleList)) {
      dispatch(getAllClassSchedules({ orgId, lActivityId }));
    }

    if (_.isEmpty(termsList)) {
      dispatch(getTerms(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(termsList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }

    if (!_.isEmpty(termsList) && !_.isEmpty(academicYears)) {
      const filteredAllTerms = academicYears.flatMap(({ termInstances }) => termInstances);
      setTermInstances(filteredAllTerms);
    }
  }, [termsList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty(classScheduleList) && !_.isEmpty(termInstances)) {
      const result = transformClassScheduleList(classScheduleList);
      setTransformedClassScheduleList(result);
    }
  }, [classScheduleList, termInstances]);

  const transformClassScheduleList = (data) => {
    const output = [];
    data.forEach(({ status, deliveryMode, duration, id, termInstanceId }) => {
      const termInstance = termInstances.find((res) => res.id === termInstanceId);
      output.push({
        id,
        status,
        deliveryMode,
        duration,
        termInstance,
      });
    });

    return output;
  };

  const navigateToEditClassSchedule = (clScheduleId) => {
    const classScheduleId = clScheduleId.id;
    navigate(
      generatePath(appRoutes.catalog.CLASS_SCHEDULE_EDIT, { orgId, lActivityId, classScheduleId }),
    );
  };

  const columns = [
    {
      name: 'termInstance',
      label: t('Term'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="p" className="ElipsisText">
              {value.displayName}
            </Typography>
          );
        },
      },
    },
    {
      name: 'deliveryMode',
      label: t('Delivery Mode'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (selectedDMs) => {
          return (
            <Typography variant="p" className="ElipsisText">
              {deliveryModes
                .filter(({ value }) => selectedDMs.includes(value))
                .map(({ label }) => label)
                .join(', ')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} name={CLASS_SCHEDULE_STATUS[value].label} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = classScheduleList.findIndex((x) => x.id === value);
                navigateToEditClassSchedule(classScheduleList[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <>
      {isClassScheduleAddView || isClassScheduleEditView ? (
        <CreateEditClassSchedule currentLearningActivity={currentLearningActivity} />
      ) : (
        <DeclareDataTable data={transformedClassScheduleList} columns={columns} options={options} />
      )}
    </>
  );
}
