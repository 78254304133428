import { Box, Container, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DeclareBreadcrumb from '../../../../components/DeclareBreadcrumb';
import CourseForm from './CourseForm';
import RuleContainer from '../../../../components/RuleContainer';
import ClassScheduleList from './ClassScheduleList';
import { useLocation, useParams, matchPath, useNavigate, generatePath } from 'react-router-dom';
import TabView from '../../../../components/TabView';
import { getStatus } from '../../../../helpers/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLearningActivities,
  getLearningActivity,
} from '../../../../redux/actions/learningActivityActions';
import { getAssociatedRule } from '../../../../redux/actions/ruleActions';
import { getTags } from '../../../../redux/actions/tagActions';
import { appRoutes } from '../../../../helpers/routes';

export default function CreateCourse() {
  const { t } = useTranslation();
  const { orgId, lActivityId } = useParams();
  const isEditView = lActivityId !== undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCourseEditView = matchPath({ path: appRoutes.catalog.COURSE_EDIT }, pathname);
  const isClassScheduleAddView = matchPath(
    { path: appRoutes.catalog.CLASS_SCHEDULE_CREATE },
    pathname,
  );
  const isClassScheduleEditView = matchPath(
    { path: appRoutes.catalog.CLASS_SCHEDULE_EDIT },
    pathname,
  );
  const isClassScheduleListView = matchPath(
    { path: appRoutes.catalog.CLASS_SCHEDULE_LIST },
    pathname,
  );

  const navigateTabIndex = () => {
    if (isCourseEditView) {
      return 0;
    } else if (isClassScheduleListView || isClassScheduleEditView) {
      return 1;
    } else {
      return 2;
    }
  };

  const [activeTabIndex, setActiveTabIndex] = React.useState(navigateTabIndex);
  const [ruleData, setRuleData] = React.useState({});
  const [courseCode, setCourseCode] = React.useState(t('Create Course'));
  const learningActivityList = useSelector(
    (state) => state.allLearningActivities.learningActivities,
  );
  const tagList = useSelector((state) => state.tags.list);

  const [currentLearningActivity, setCurrentLearningActivity] = useState({
    name: '',
    code: '',
    units: '',
    url: '',
    type: '',
    level: '',
    shortDescription: '',
    status: getStatus()[3].value,
    tags: [],
    externalId: '',
    catalogVersions: [],
    learningActivityType: 'COURSE',
    minUnits: null,
    unitStep: null,
    maxUnits: null,
  });

  const navigateToEditView = () => {
    setActiveTabIndex(0);
    navigate(generatePath(appRoutes.catalog.COURSE_EDIT, { orgId, lActivityId }));
  };

  const navigateToClassScheduleView = () => {
    setActiveTabIndex(1);
    navigate(generatePath(appRoutes.catalog.CLASS_SCHEDULE_LIST, { orgId, lActivityId }));
  };

  const navigateToRuleView = () => {
    setActiveTabIndex(2);
    navigate(generatePath(appRoutes.catalog.COURSE_RULE_EDIT, { orgId, lActivityId }));
  };

  const cancelAction = () => {
    navigate(generatePath(appRoutes.catalog.LEARNING_ACTIVITY_LIST, { orgId }));
  };

  const navigateToCreateClassSchedule = () => {
    setActiveTabIndex(1);
    navigate(generatePath(appRoutes.catalog.CLASS_SCHEDULE_CREATE, { orgId, lActivityId }));
  };

  const tabs = [
    {
      name: t('Overview'),
      component: (
        <CourseForm
          currentLearningActivity={currentLearningActivity}
          isEditView={isEditView}
          cancelAction={cancelAction}
        />
      ),
      callBack: navigateToEditView,
    },
    {
      name: t('classSchedule'),
      component: (
        <ClassScheduleList
          currentLearningActivity={currentLearningActivity}
          cancelAction={cancelAction}
        />
      ),
      callBack: navigateToClassScheduleView,
    },
    {
      name: t('Rules'),
      component: (
        <RuleContainer ruledEntity={lActivityId} ruleData={ruleData} cancelAction={cancelAction} />
      ),
      callBack: navigateToRuleView,
    },
  ];

  useEffect(() => {
    if (activeTabIndex === 2) {
      const callback = (data) => {
        setRuleData(data);
      };
      dispatch(getAssociatedRule(orgId, lActivityId, callback));
      if (tagList.length === 0) {
        dispatch(getTags(orgId));
      }
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (isEditView) {
      if (learningActivityList.length === 0) {
        dispatch(getLearningActivities(orgId));
      } else {
        const selectedLearningActivity = learningActivityList.find(
          (activity) => activity.id === lActivityId,
        );
        dispatch(
          getLearningActivity(orgId, selectedLearningActivity, (data) => {
            setCurrentLearningActivity(data);
            setCourseCode(data.code);
          }),
        );
      }
    }
  }, [learningActivityList]);
  const laListRoute = generatePath(appRoutes.catalog.LEARNING_ACTIVITY_LIST, { orgId });

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          {isClassScheduleAddView ? (
            <DeclareBreadcrumb
              items={[
                { name: t('learningActivities'), customRoute: laListRoute },
                courseCode,
                t('New Schedule'),
              ]}
            />
          ) : (
            <DeclareBreadcrumb
              items={[{ name: t('learningActivities'), customRoute: laListRoute }, courseCode]}
            />
          )}
          {isClassScheduleListView || isClassScheduleAddView ? (
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              sx={{
                height: '50px',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontWeight: 300,
                alignItems: 'right',
              }}
              onClick={() => {
                navigateToCreateClassSchedule();
              }}>
              {t('New Schedule')}
            </Button>
          ) : null}
        </Box>
        {isEditView ? (
          <TabView tabs={tabs} selectedTab={activeTabIndex} />
        ) : (
          <CourseForm
            currentLearningActivity={currentLearningActivity}
            isEditView={isEditView}
            cancelAction={cancelAction}
          />
        )}
      </Box>
    </Container>
  );
}
