import { Box, Container, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import StatusCell from '../../../components/StatusCell';
import {
  CLASS_SCHEDULE_STATUS,
  WAIT_UNTIL_SEARCH_MILLISECONDS,
  deliveryModes,
} from '../../../helpers/appConstants';
import { defaultTableOptions, getQueryParams } from '../../../helpers/classScheduleHelper';
import { convertToQueryString } from '../../../helpers/common';
import { appRoutes } from '../../../helpers/routes';
import { getOrgClassSchedules } from '../../../redux/actions/classScheduleActions';
import { getAllTerms, getAcademicYearsWithTermInstances } from '../../../redux/actions/termActions';

export default function ClassScheduleList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();

  const { list: classSchedulesList } = useSelector((state) => state.classSchedules);
  const [filteredClassSchedulesList, setFilteredClassSchedulesList] = useState(classSchedulesList);
  const [tableOptions, setTableOptions] = useState(defaultTableOptions);
  const { termList, academicYears, termInstances } = useSelector((state) => state.terms);

  const debouncedSearch = React.useRef(
    _.debounce((query) => {
      dispatch(getOrgClassSchedules(orgId, query, handleGetClassScheduleResponse));
    }, WAIT_UNTIL_SEARCH_MILLISECONDS),
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }
  }, [termList, academicYears]);

  const handleSearchKeyChanges = (value) => {
    const data = { ...tableOptions, page: 0, query: value };
    const query = getQueryParams(data);
    const queryString = convertToQueryString(query);
    debouncedSearch(queryString);
    setTableOptions(data);
  };

  const handleGetClassScheduleResponse = (res) => {
    const { data, totalItems } = res;
    setTableOptions((tableOptions) => ({ ...tableOptions, totalItems }));
    setFilteredClassSchedulesList(
      data.map((cs) => ({
        ...cs,
        termInstanceIdForYear: cs.termInstanceId,
        termInstanceIdForTerms: cs.termInstanceId,
      })),
    );
  };

  useEffect(() => {
    const query = getQueryParams(tableOptions);
    const queryString = convertToQueryString(query);
    if (_.isEmpty(termList)) {
      dispatch(getAllTerms(orgId));
    }
    dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
  }, []);

  const columns = [
    {
      name: 'lActivity.code',
      label: t('Course Code'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (code) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {code}
            </Typography>
          );
        },
      },
    },
    {
      name: 'lActivity.name',
      label: t('Course Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (name) => {
          return <Typography variant="body2">{name}</Typography>;
        },
      },
    },
    {
      name: 'termInstanceIdForYear',
      label: t('Year'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography variant="body2">{termInstances[value]?.name}</Typography>;
        },
      },
    },
    {
      name: 'termInstanceIdForTerms',
      label: t('Term'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography variant="body2">{termInstances[value]?.displayName}</Typography>;
        },
      },
    },
    {
      name: 'deliveryMode',
      label: t('Delivery Mode'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dm) => {
          return (
            <Typography variant="body2">
              {
                deliveryModes.find(({ value }) => {
                  return value === dm;
                }).label
              }
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: t('Status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} name={CLASS_SCHEDULE_STATUS[value].label} />;
        },
      },
    },
  ];

  const navigateToSingleClassSchedule = ({
    orgId,
    lActivityId,
    termInstanceId,
    deliveryMode,
    classScheduleData,
  }) => {
    navigate(
      generatePath(appRoutes.advisor.SINGLE_CLASS_SCHEDULE_VIEW, {
        orgId,
        lActivityId,
        termInstanceId,
        deliveryMode,
      }),
      { state: classScheduleData },
    );
  };

  const handleOnRowClick = (_data, { dataIndex }) => {
    const classScheduleData = filteredClassSchedulesList[dataIndex];
    const {
      lActivity: { id: lActivityId },
      termInstanceId,
      deliveryMode,
    } = classScheduleData;
    const dataForNavigate = { orgId, lActivityId, termInstanceId, deliveryMode, classScheduleData };
    navigateToSingleClassSchedule(dataForNavigate);
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    serverSide: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    count: tableOptions.totalItems,
    page: tableOptions.page,
    rowsPerPage: tableOptions.rowsPerPage,
    viewColumns: false,
    enableNestedDataAccess: '.',
    onRowClick: handleOnRowClick,
    sortOrder: tableOptions.sort,
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        const data = {
          ...tableOptions,
          page: tableState.page,
          rowsPerPage: tableState.rowsPerPage,
        };

        const query = getQueryParams(data);
        const queryString = convertToQueryString(query);
        dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
        setTableOptions(data);
      }

      if (action === 'sort') {
        const data = {
          ...tableOptions,
          sort: tableState.sortOrder,
        };

        const query = getQueryParams(data);
        const queryString = convertToQueryString(query);
        dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
        setTableOptions(data);
      }
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Class Schedules')]} />
          <Box sx={{ display: 'flex', flex: 0.3 }}>
            <SearchTextBox mr={0} ml={2} setSearchKey={handleSearchKeyChanges} />
          </Box>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable columns={columns} options={options} data={filteredClassSchedulesList} />
        </Box>
      </Box>
    </Container>
  );
}
