import {
  setProgramMaps,
  getProgramMaps,
  setProgramMapRequirements,
} from '../../actions/programMapActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestGetProgramMaps,
  requestCreateProgramMap,
  requestEditProgramMap,
  requestGetProgramMappingData,
  requestGetProgramMapRequirements,
  requestConstructProgramMapPathway,
  requestPatchProgramMappingData,
  requestGetLearningActivitiesForGroup,
  requestRenameProgramMapGroup,
  requestAddNewProgramMapGroup,
  requestLinkProgramMapIntoProgramMap,
  requestRemoveProgramMapNode,
  requestMoveProgramMapNode,
  requestUpdateProgramMapNode,
} from '../requests/programMap';

export function* handleGetProgramMaps(action) {
  const { payload } = action;
  yield requestHandler(requestGetProgramMaps.bind(this, payload), setProgramMaps, requestTypes.GET);
}

export function* handleCreateProgramMap(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestCreateProgramMap.bind(this, payload),
    getProgramMaps.bind(this, payload.org),
    requestTypes.POST,
    navigate,
  );
}

export function* handleEditProgramMap(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestEditProgramMap.bind(this, payload),
    getProgramMaps.bind(this, payload.org),
    requestTypes.PUT,
    navigate,
  );
}

export function* handleRenameProgramMapGroup(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestRenameProgramMapGroup.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    false,
  );
}

export function* handleAddNewProgramMapGroup(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestAddNewProgramMapGroup.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    true,
  );
}

export function* handleLinkProgramMapIntoProgramMap(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestLinkProgramMapIntoProgramMap.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    true,
  );
}

export function* handleRemoveProgramMapNode(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestRemoveProgramMapNode.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    true,
  );
}

export function* handleMoveProgramMapNode(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestMoveProgramMapNode.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    true,
  );
}

export function* handleUpdateProgramMapNode(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestUpdateProgramMapNode.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
    null,
    true,
    true,
  );
}

export function* handleGetProgramMappingData(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetProgramMappingData.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
  );
}

export function* handleGetLearningActivitiesForGroup(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetLearningActivitiesForGroup.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
  );
}

export function* handleGetProgramMapRequirements(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetProgramMapRequirements.bind(this, payload),
    setProgramMapRequirements,
    requestTypes.GET,
    null,
  );
}

export function* handleConstructProgramMapPathway(action) {
  const { payload } = action;
  yield requestHandler(
    requestConstructProgramMapPathway.bind(this, payload),
    null,
    requestTypes.POST,
    null,
  );
}

export function* handleUpdateProgramMappingData(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPatchProgramMappingData.bind(this, payload),
    null,
    requestTypes.PATCH,
    callback,
  );
}
