import { Box, Button, Container, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { appRoutes } from '../../../helpers/routes';
import {
  getLearningActivities,
  resetClassScheduleList,
} from '../../../redux/actions/learningActivityActions';
import StatusCell from '../../../components/StatusCell';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { DATA_TABLE_ACTIONS } from '../../../helpers/common';

export default function LearningActivityList() {
  const { t } = useTranslation();
  const learningActivityStore = useSelector(
    (state) => state.allLearningActivities.learningActivities,
  );
  const selectedOrg = useSelector((state) => state.allOrgs.selectedOrg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchKey, setSearchKey] = useState('');
  const [filteredLAList, setFilteredLAList] = useState(learningActivityStore);
  const [laTableListOptions, setLaTableListOptions] = useLocalStorage('laTableOptions', {
    page: 0,
    rowsPerPage: 10,
    sort: {
      name: 'code',
      direction: 'asc',
    },
    totalItems: 0,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    var filteredList = [];
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.code.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = learningActivityStore.filter((o) => filters.some((fn) => fn(o)));
      learningActivityStore.forEach((element) => {
        const filterCatalog = [(o) => o.name.toLowerCase().includes(searchKey.toLowerCase())];
        let catalogListFiltered = element.catalog.filter((o) => filterCatalog.some((fn) => fn(o)));
        let isExist = filteredList.filter((res) => res.id === element.id);
        if (catalogListFiltered.length > 0 && isExist.length === 0) {
          filteredList.push(element);
        }
      });
      setFilteredLAList(filteredList);
    } else {
      setFilteredLAList(learningActivityStore);
    }
  }, [learningActivityStore, searchKey]);

  useEffect(() => {
    if (!learningActivityStore.length > 0) {
      dispatch(getLearningActivities(orgId));
      setSearchKey('');
    }
  }, []);

  const navigateToCreateCourse = () => {
    navigate(generatePath(appRoutes.catalog.COURSE_CREATE, { orgId: selectedOrg.id }));
  };

  const navigateToEditLearningActivity = async (data) => {
    if (data.type === 'Course') {
      navigate(
        generatePath(appRoutes.catalog.COURSE_EDIT, {
          orgId: selectedOrg.id,
          lActivityId: data.id,
        }),
        dispatch(resetClassScheduleList()),
      );
    } else {
      // TODO implement the edit test view
    }
  };

  const columns = [
    {
      name: 'code',
      label: t('Code'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'name',
      label: t('Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: 'catalog',
      label: 'Catalog',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="p" className="ElipsisText">
              {value.map((val) => val.name).join(', ')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = learningActivityStore.findIndex((x) => x.id === value);
                navigateToEditLearningActivity(learningActivityStore[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    count: laTableListOptions.totalItems,
    page: laTableListOptions.page,
    rowsPerPage: laTableListOptions.rowsPerPage,
    sortOrder: laTableListOptions.sort,
    onTableChange: (action, tableState) => {
      if (
        action === DATA_TABLE_ACTIONS.CHANGE_PAGE ||
        action === DATA_TABLE_ACTIONS.CHANGE_ROWS_PER_PAGE ||
        action === DATA_TABLE_ACTIONS.SORT
      ) {
        setLaTableListOptions({
          ...laTableListOptions,
          page: tableState.page,
          rowsPerPage: tableState.rowsPerPage,
          sort: tableState.sortOrder,
        });
      }
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('learningActivities')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            endIcon={<KeyboardArrowDownIcon />}>
            New Learning Activity
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              onClick={() => {
                navigateToCreateCourse();
              }}>
              {t('Create new course')}
            </MenuItem>
            <MenuItem onClick={handleClose}>{t('Create new test')}</MenuItem>
          </Menu>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredLAList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
