import AdvisorService from '../../../services/AdvisorService';
import OrgError from '../../../error/OrgError';

const advisorService = new AdvisorService();

export function* requestGetAdvisors(param) {
  if (!param) {
    throw OrgError.orgRequired();
  }
  return yield advisorService.getAll(param);
}

export function* requestPutAdvisorForStudent(param) {
  const { orgId, data } = param;
  return yield advisorService.updateAdvisorForStudent(orgId, data);
}
