import _ from 'lodash';
import i18n from 'i18next';
import {
  MAXIMUM_GENERATED_TERMS_IN_YEARS,
  studentPathwayStatusTypes,
  termPlanningStatusTypes,
} from './appConstants';
import { getDefaultProgramMapId, getStudentProgramMapIdByCatalog } from './studentsHelper';

export const transformPathwayData = (studentPathway, termList, learningActivities) => {
  const { terms } = studentPathway;
  let courseId = '';
  let courseUnits = 0;
  let courseName = '';
  let courseCode = '';

  return terms.map(({ termId, statusId, items, id: tid, year }) => {
    let termUnits = 0;
    const term = termList.find(({ id }) => id === termId);
    const itemsData = _.isEmpty(items)
      ? items
      : items
          .filter(({ courseReqId }) => !_.isEmpty(courseReqId))
          .map(({ additionalInfo, courseReqId }) => {
            if (!_.isNil(additionalInfo) && additionalInfo?.freeElective) {
              courseId = courseReqId;
              courseUnits = additionalInfo.units;
              courseName = additionalInfo.groupName;
              courseCode = i18n.t('Assign');
            } else {
              const courseReq = learningActivities.find(({ id }) => id === courseReqId);
              courseId = courseReq?.id;
              courseUnits = courseReq?.units;
              courseName = courseReq?.name;
              courseCode = courseReq?.code;
            }

            if (additionalInfo?.incompleted) {
              termUnits += 0;
            } else {
              termUnits += courseUnits;
            }

            return {
              id: courseId,
              units: courseUnits,
              name: courseName,
              code: courseCode,
              additionalInfo,
            };
          });

    return {
      term: {
        ...term,
        year,
        calendarYear: year,
        termId: tid,
      },
      termPlanningStatus: statusId.type,
      units: termUnits,
      items: itemsData,
      termId: termId,
    };
  });
};

export const buildPathwayData = (
  currentSelectedStudent,
  programMapList,
  formValues,
  completedCourses,
  financialAidSettingValue,
  catalogs,
) => {
  const programOptionId = currentSelectedStudent.programOption.id;
  const requiresFinancialAid = currentSelectedStudent.requiresFinancialAid;

  let programMapId = currentSelectedStudent.programMapId;
  if (!programMapId) {
    programMapId = !_.isNil(currentSelectedStudent.catalogId)
      ? getStudentProgramMapIdByCatalog(
          programMapList,
          programOptionId,
          currentSelectedStudent.catalogId,
        )
      : getDefaultProgramMapId(programMapList, catalogs, programOptionId);
  }

  const data = { ...formValues, programMapId, completedCourses, requiresFinancialAid };

  if (requiresFinancialAid && !_.isNil(financialAidSettingValue)) {
    data.minNumOfUnitsPerTerm = financialAidSettingValue;
  }

  return data;
};

export const generatePathwayData = (
  usedRequirementTransfers,
  orderedPathway,
  termPlanningStatus,
  courseReq,
  currentSelectedStudent,
  programMapId,
  answeredQuestions,
  questions,
  pathwayStatus = studentPathwayStatusTypes.IN_PLANNING,
) => {
  let filterQuestions = null;
  const programOptionId = currentSelectedStudent.programOption.id;
  const orderedPathwayData = orderedPathway.map(
    ({ items, term, termPlanningStatus: tpStatus }) => ({
      term: {
        id: term.id,
        year: term.year,
      },
      termPlanningStatusCode: termPlanningStatus.find(({ type }) => type === tpStatus).code,
      items: items.map(({ id, additionalInfo }) => ({ id, additionalInfo })),
    }),
  );

  if (!_.isEmpty(answeredQuestions) && !_.isEmpty(questions)) {
    const questionIds = Object.keys(answeredQuestions);
    filterQuestions = questions.filter(({ id }) => questionIds.find((ids) => ids === id));
    filterQuestions = _.isEmpty(filterQuestions)
      ? null
      : filterQuestions.map(({ id }) => {
          const answer = answeredQuestions[id];
          return {
            questionId: id,
            answer,
            answerType: typeof answer,
          };
        });
  }

  const pathwayData = {
    evaluationId: courseReq[0]?.evaluationId?.id,
    pathwayStatus,
    programOptionId,
    programMapId,
    orderedPathway: orderedPathwayData,
    questions: filterQuestions,
    additionalInfo: courseReq[0]?.assignments?.Transferred?.some(
      (trf) => trf.additionalInfo && trf.additionalInfo?.requirementTransfer?.unitsTransferred,
    )
      ? { usedRequirementTransfers: usedRequirementTransfers }
      : null,
  };

  return pathwayData;
};

export const getReOrderedTermsByCalenderYear = (termList, year = new Date().getFullYear()) => {
  const calendarYearStarIndex = termList.findIndex(({ calendarYearStart }) => calendarYearStart);
  const termsInsideTheCurrentYear = termList.slice(calendarYearStarIndex);
  const termsForNextYear = termList.slice(0, calendarYearStarIndex);
  return termsInsideTheCurrentYear.concat(termsForNextYear).map((term) => ({ ...term, year }));
};

export const checkTermIsCompletable = (
  orderedPathway,
  index,
  termList,
  year = new Date().getFullYear(),
) => {
  const organizationCurrentTerm = termList.find(({ currentTerm }) => currentTerm);
  const selectedTerm = orderedPathway[index];

  const orderedTermsByCalenderYear = getReOrderedTermsByCalenderYear(termList, year);

  const selecTedTermIndex = orderedTermsByCalenderYear.findIndex(
    (theTerm) => theTerm.id === selectedTerm.term.id,
  );
  const organizationCurrentTermIndex = orderedTermsByCalenderYear.findIndex(
    (aTerm) => aTerm.id === organizationCurrentTerm.id,
  );

  let completable = false;

  if (selectedTerm.term.year < orderedTermsByCalenderYear[organizationCurrentTermIndex].year) {
    completable = true;
  } else if (
    selectedTerm.term.year === orderedTermsByCalenderYear[organizationCurrentTermIndex].year &&
    selecTedTermIndex <= organizationCurrentTermIndex
  ) {
    completable = true;
  }

  const currentTermNotSitOut = selectedTerm.status !== termPlanningStatusTypes.SIT_OUT;

  const hasPreviousNonCompletedTerms =
    orderedPathway
      .slice(0, index)
      .filter(
        ({ termPlanningStatus }) =>
          termPlanningStatus !== termPlanningStatusTypes.COMPLETED &&
          termPlanningStatus !== termPlanningStatusTypes.SIT_OUT,
      ).length == 0;

  return completable && currentTermNotSitOut && hasPreviousNonCompletedTerms;
};

export const generateCourseUsageData = (courseReq, pathwayId, completedCourses) => {
  let completedCourseUsage = [];
  if (!_.isEmpty(courseReq)) {
    completedCourseUsage = Object.values(courseReq[0].assignments).flatMap((courseArray) => {
      return courseArray.map(({ type, courseReqId }) => ({
        pathwayId,
        type,
        courseReqId,
      }));
    });
  }

  if (
    !_.isNil(completedCourses?.transferringRequirements) &&
    !_.isEmpty(completedCourses?.transferringRequirements)
  ) {
    completedCourses.transferringRequirements = completedCourses.transferringRequirements.map(
      ({ id }) => id,
    );
  }

  const allCompletedCourses = Object.values(completedCourses).flat();
  return completedCourseUsage.filter(({ courseReqId }) =>
    allCompletedCourses.some((id) => id === courseReqId),
  );
};

export const getTermsToSelect = (termList) => {
  if (_.isEmpty(termList)) return [];

  const year = new Date().getFullYear() - 1;
  const currentTermIndex = termList.findIndex((res) => res.currentTerm);
  const yearStartTerm = termList.find((data) => data.calendarYearStart);
  const afterCurrentTerm = termList.slice(currentTermIndex);
  const newTermList = afterCurrentTerm.concat(termList.slice(0, currentTermIndex));
  const maxYear = year + MAXIMUM_GENERATED_TERMS_IN_YEARS;
  let currentYear = newTermList[0]?.order > yearStartTerm?.order ? year : year - 1;
  const result = [];

  while (currentYear <= maxYear) {
    newTermList.map((data) => {
      if (data.calendarYearStart) {
        currentYear++;
      }

      result.push({
        ...data,
        year: currentYear,
      });
    });
  }

  return result;
};

export const validateNextTerm = (
  nextTerm,
  nextYear,
  orderedTerms,
  organizationCurrentTermIndex,
) => {
  const nextTermIndex = orderedTerms.findIndex((theTerm) => theTerm.id === nextTerm);

  return (
    nextYear > orderedTerms[organizationCurrentTermIndex].year ||
    (nextYear === orderedTerms[organizationCurrentTermIndex].year &&
      nextTermIndex >= organizationCurrentTermIndex)
  );
};

export const getNextRegularTerm = (termsList, lastTerm) => {
  const lastTermIndex = termsList.findIndex(
    (aTerm) => aTerm.id === lastTerm.id && aTerm.year === lastTerm.year,
  );
  const nextRegularTerm = termsList.find((term) => {
    const index = termsList.findIndex((aTerm) => aTerm.id === term.id && aTerm.year === term.year);
    if (index > lastTermIndex && term.regularTerm) {
      return term;
    }
  });
  return nextRegularTerm;
};

export const createRenderValueMessage = (termList, selectedTermFromDropDown, t) => {
  const currentTerm = termList.find((aTerm) => aTerm.currentTerm);
  const currentTermInTheList = selectedTermFromDropDown.find(
    (aTerm) => aTerm.id === currentTerm.id && aTerm.year === new Date().getFullYear(),
  );
  let message = '';
  if (selectedTermFromDropDown.length >= 1 && _.isNil(currentTermInTheList)) {
    message = JSON.stringify(selectedTermFromDropDown.length) + t('Terms selected');
  } else if (selectedTermFromDropDown.length > 1 && !_.isNil(currentTermInTheList)) {
    message = ' + ' + JSON.stringify(selectedTermFromDropDown.length - 1) + t('Terms selected');
  }
  return message;
};

export const checkTheTermHasCompletedStatus = (selectedTerm, completedTerms) => {
  const completedTerm = completedTerms.find(
    (completedTerm) =>
      completedTerm.term.id === selectedTerm.id && completedTerm.term.year === selectedTerm.year,
  );
  return !_.isNil(completedTerm);
};

export const doesOrderedPathwayOverlapsWithCompletedTerms = (orderedPathway, completedTerms) => {
  const duplicateTerms = orderedPathway.filter((aTerm) => {
    const theTerm = completedTerms.find(
      (completedTerm) =>
        completedTerm.term.id === aTerm.term.id && completedTerm.term.year === aTerm.term.year,
    );
    return !_.isUndefined(theTerm);
  });
  return !_.isEmpty(duplicateTerms);
};

export const getFormattedOrderedPathwayData = (
  studentOrderedPathway,
  termList,
  learningActivities,
) => transformPathwayData(studentOrderedPathway, termList, learningActivities);

export const getFormattedStudentCompletedTermsFromOrderedPathway = (
  studentCompletedTerms,
  termList,
  learningActivities,
) => transformPathwayData({ terms: studentCompletedTerms }, termList, learningActivities);

export const reorderPathwayStatusList = (pathwayStatus, type) => {
  const status = pathwayStatus.filter(({ [type]: t }) => t !== studentPathwayStatusTypes.ARCHIVED);

  const ordered = [
    studentPathwayStatusTypes.NEW,
    studentPathwayStatusTypes.IN_PLANNING,
    studentPathwayStatusTypes.PATHWAY_SENT,
    studentPathwayStatusTypes.PATHWAY_INVALID,
    studentPathwayStatusTypes.PATHWAY_DROPPED,
    studentPathwayStatusTypes.PATHWAY_COMPLETED,
  ];

  return status.sort((a, b) => {
    return ordered.indexOf(a[type]) - ordered.indexOf(b[type]);
  });
};

export const findTermInstance = (termInstances, { id, year }) =>
  _.find(termInstances, ({ termId, calendarYear }) => termId === id && calendarYear === year);

export const includeTermInstancesInPathway = (orderedPathway, termInstances) =>
  orderedPathway.map((pathwayTerm) => {
    const { term, items, termPlanningStatus } = pathwayTerm;
    const termInstanceId = findTermInstance(termInstances, term)?.id;
    return { items, termPlanningStatus, term: { ...term, termInstanceId } };
  });

export const markCourseAsIncompletedOrCompletedInPathway = (
  orderedPathway,
  courseId,
  termId,
  termYear,
  markIncomplete = true,
) => {
  const copyOfPathway = _.cloneDeep(orderedPathway);
  const incompletedCourse = copyOfPathway
    .find(({ term: { id, year } }) => id === termId && termYear === year)
    .items.find(({ id }) => id === courseId);

  if (markIncomplete) {
    incompletedCourse.additionalInfo = { ...incompletedCourse.additionalInfo, incompleted: true };
  } else {
    incompletedCourse.additionalInfo = { ...incompletedCourse.additionalInfo, incompleted: false };
    const termIndex = copyOfPathway.findIndex((a) =>
      a.items.some(({ id, additionalInfo }) => id === courseId && additionalInfo?.retaken),
    );

    if (termIndex !== -1) {
      const itemIndex = copyOfPathway[termIndex].items.findIndex(
        ({ id, additionalInfo }) => id === courseId && additionalInfo?.retaken,
      );
      if (itemIndex !== -1) {
        copyOfPathway[termIndex].items.splice(itemIndex, 1);
      }
    }
  }
  return copyOfPathway;
};

export const getPathwayTerms = (orderedPathway, studentCompletedTerms, termInstances) =>
  orderedPathway
    .concat(studentCompletedTerms)
    .map(({ term, termPlanningStatus }) => {
      const termInstanceId = findTermInstance(termInstances, term)?.id;
      return { ...term, termPlanningStatus, termInstanceId };
    })
    .filter(({ termPlanningStatus }) => termPlanningStatus !== termPlanningStatusTypes.COMPLETED);

export const isIncompletedCourse = (additionalInfo) => _.get(additionalInfo, 'incompleted', false);

export const isRetakenCourse = (additionalInfo) => _.get(additionalInfo, 'retaken', false);

export const markRetakenCoursesInPathway = (newPathway, oldPathway, completedTerms) => {
  const allCoursesInCompletedTerms = (oldPathway.terms || [])
    .concat(completedTerms)
    .filter(({ statusId }) => statusId.type === termPlanningStatusTypes.COMPLETED)
    .map(({ items }) => items)
    .flat();

  const incompleteCourses = allCoursesInCompletedTerms
    .filter(({ additionalInfo }) => isIncompletedCourse(additionalInfo))
    .map(({ courseReqId }) => courseReqId);

  const reTakenCourses = allCoursesInCompletedTerms
    .filter(({ additionalInfo }) => isRetakenCourse(additionalInfo))
    .map(({ courseReqId }) => courseReqId);

  const missingRetakenCourses = _.difference(_.uniq(incompleteCourses), _.uniq(reTakenCourses));

  return newPathway.map((term) => ({
    ...term,
    items: term.items.map((course) => {
      const index = missingRetakenCourses.findIndex((courseId) => courseId === course.id);
      let additionalInfo = course.additionalInfo;
      if (index !== -1) {
        missingRetakenCourses.splice(index, 1);
        additionalInfo = { ...additionalInfo, retaken: true };
      }
      return { ...course, additionalInfo };
    }),
  }));
};

export const preprocessPathwayData = (orderedPathway) =>
  orderedPathway.map((data) => {
    const items = data.items.map((itemData) => {
      const additionalInfo = itemData.additionalInfo;
      if (!_.isNil(additionalInfo) && additionalInfo?.freeElective) {
        return {
          ...itemData,
          additionalInfo: {
            ...additionalInfo,
            groupName: itemData.name,
          },
        };
      } else {
        return itemData;
      }
    });

    return {
      ...data,
      items,
    };
  });

export const updatePathwayData = (clonePathway, electiveData, termInstances) => {
  const term = termInstances[electiveData.termInstanceId];
  return clonePathway.map((data) => {
    if (data.term.id === term.termId && data.term.calendarYear === term.calendarYear) {
      const items = data.items.map((itemData) =>
        itemData.id === electiveData.swapFrom
          ? {
              ...itemData,
              ...electiveData.selectedCourse,
              additionalInfo: {
                ...itemData.additionalInfo,
                swapFrom: electiveData.swapFrom,
                freeElective: false,
              },
            }
          : itemData,
      );

      return {
        ...data,
        items,
      };
    }

    return data;
  });
};

export const getTCECourseReqIds = (courseReq, terms, transformed = true) => {
  const transferringRequirements = [];
  const transferred = [];
  const completed = [];
  const exempt = [];

  const latestEvaluationAssignments = courseReq[0]?.assignments;

  latestEvaluationAssignments?.Transferred?.forEach((item) => {
    const { id, additionalInfo } = item;
    const { requirementTransfer } = additionalInfo || {};

    if (requirementTransfer && requirementTransfer.unitsTransferred) {
      transferringRequirements.push({
        id: id,
        units: requirementTransfer.unitsTransferred,
      });
    } else {
      transferred.push(id);
    }
  });

  latestEvaluationAssignments?.Completed?.forEach((item) => {
    completed.push(item.id);
  });

  latestEvaluationAssignments?.Exempt?.forEach((item) => {
    exempt.push(item.id);
  });

  if (!_.isEmpty(terms)) {
    let transformedTerms = terms;
    if (!transformed) {
      transformedTerms = transformTermsForTCE(terms);
    }

    const completedCourseIds = transformedTerms
      .flatMap(({ termPlanningStatus, items }) => {
        if (termPlanningStatus === 'COMPLETED') {
          return items.filter(
            ({ additionalInfo }) => _.isNull(additionalInfo) || !additionalInfo?.incompleted,
          );
        }
        return [];
      })
      .map(({ id }) => id);
    completed.push(...completedCourseIds);
  }

  const result = {
    transferred: transferred,
    completed: [...new Set(completed)],
    exempt: exempt,
    transferringRequirements: transferringRequirements,
  };

  return result;
};

const transformTermsForTCE = (terms) => {
  let courseId = '';
  return terms.map(({ statusId, items }) => {
    let termUnits = 0;
    const itemsData = _.isEmpty(items)
      ? items
      : items
          .filter(({ courseReqId }) => !_.isEmpty(courseReqId))
          .map(({ additionalInfo, courseReqId }) => {
            if (!_.isNil(additionalInfo) && additionalInfo?.freeElective) {
              courseId = courseReqId;
            } else {
              courseId = courseReqId;
            }

            return {
              id: courseId,
              additionalInfo,
            };
          });

    return {
      termPlanningStatus: statusId.type,
      units: termUnits,
      items: itemsData,
    };
  });
};

export const removeCourseFromPathway = (orderedPathway, courseId, termId, termYear) => {
  const copyOfPathway = _.cloneDeep(orderedPathway);
  const term = copyOfPathway.find(({ term: { id, year } }) => id === termId && termYear === year);

  const courseIndex = term.items.findIndex(({ id }) => id === courseId);

  if (courseIndex !== -1) {
    term.items.splice(courseIndex, 1);
  }

  return copyOfPathway;
};
