import { HttpService } from './HttpService';

export default class TransferEvaluationService extends HttpService {
  async saveCompletedCourseReq(org, studentId, body) {
    return this.post(`/organizations/${org}/students/${studentId}/completedCourses`, body);
  }
  async updateCompletedCourseReq(org, studentId, reqId, body) {
    return this.patch(
      `/organizations/${org}/students/${studentId}/completedCourses/${reqId}`,
      body,
    );
  }
  async getCompletedCourseReq(org, studentId) {
    return this.get(`/organizations/${org}/students/${studentId}/completedCourses`);
  }

  async getTransferEvaluationForPrint(orgId, studentId, evaluationId) {
    return this.get(
      `/organizations/${orgId}/students/${studentId}/transferEvaluations/${evaluationId}/print`,
    );
  }
}
