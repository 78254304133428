import _, { drop } from 'lodash';

export const RuleTypes = [
  {
    name: 'Prerequisite',
    value: 'PREREQUISITE',
  },
  {
    name: 'Corequisite',
    value: 'COREQUISITE',
  },
  {
    name: 'Recommended Corequisite',
    value: 'RECOMMENDED_COREQUISITE',
  },
  {
    name: 'Preparatory Course',
    value: 'PREPARATORY_COURSE',
  },
  {
    name: 'Complete Number of Learning Activities',
    value: 'COMPLETE_NUMBER_OF_LEARNING_ACTIVITIES',
    placeholder: 'Number of Learning Activities',
  },
  {
    name: 'Complete Number of Learning Activities or Groups',
    value: 'COMPLETE_NUMBER_OF_LEARNING_ACTIVITIES_OR_GROUPS',
    placeholder: 'Number of Learning Activities or Groups',
  },
  {
    name: 'Complete Number of Units',
    value: 'COMPLETE_NUMBER_OF_UNITS',
  },
  {
    name: 'Complete Number of Units by Tag',
    value: 'COMPLETE_NUMBER_OF_UNITS_BY_TAG',
  },
  {
    name: 'Complete Number Units by Type',
    value: 'COMPLETE_NUMBER_OF_UNITS_BY_TYPE',
  },
  {
    name: 'Complete Number of Learning Activities with Level Greater Than',
    value: 'COMPLETE_NUMBER_OF_LEARNING_ACTIVITIES_WITH_LEVEL_GREATER_THAN',
  },
  {
    name: 'Complete in First X Units',
    value: 'COMPLETE_IN_FIRST_X_UNITS',
  },
  {
    name: 'Complete between X and Y Units',
    value: 'COMPLETE_BETWEEN_X_AND_Y_UNITS',
  },
  {
    name: 'Allow repeat for a maximum of X Units',
    value: 'ALLOW_REPEAT_FOR_MAXIMUM_X_UNITS',
  },
  {
    name: "Student's preferred program is X",
    value: 'STUDENT_PREFERRED_PROGRAM_IS_X',
  },
  {
    name: 'No More Than X Courses',
    value: 'NO_MORE_THAN_X_COURSES',
  },
  {
    name: 'No More Than X Units',
    value: 'NO_MORE_THAN_X_UNITS',
  },
  {
    name: 'Warning',
    value: 'WARNING',
  },
  {
    name: 'Question',
    value: 'QUESTION',
  },
  {
    name: 'Placeholder',
    value: 'PLACEHOLDER',
  },
  {
    name: 'Order Learning Activity in Different Terms By Tag',
    value: 'ORDER_LA_IN_DIFFERENT_TERMS',
  },
  {
    name: "Learning Activity can't be included for credit if X",
    value: 'LEARNING_ACTIVITY_CANNOT_INCLUDE_FOR_CREDIT_IF_X',
  },
  {
    name: 'Allow Manual Planning of Course Group',
    value: 'ALLOW_MANUAL_PLANNING_OF_COURSE_GROUP',
  },
];

export const Operators = ['AND', 'OR'];

export const RuleCat = {
  RULE: 'RULE',
  GROUP: 'GROUP',
};

export const appendRuleOrGroup = (selectedElementPath, type, ruleData) => {
  const rule = {
    type: RuleTypes[0].value,
    uuid: _.uniqueId(),
    data: { isRequirement: false },
  };

  const ruleGroup = {
    operator: Operators[0],
    uuid: _.uniqueId(),
    data: [],
  };

  let rules = _.cloneDeep(ruleData);
  if (selectedElementPath.length > 0) {
    let selectedLocation = rules;
    selectedElementPath.forEach((element) => {
      selectedLocation = selectedLocation.data[element];
    });
    selectedLocation.data.push(type == RuleCat.RULE ? rule : ruleGroup);
  } else {
    rules.data.push(type == RuleCat.RULE ? rule : ruleGroup);
  }

  return rules;
};

export const changeOperator = (selectedElementPath, newOperatorIndex, ruleData) => {
  let rules = _.cloneDeep(ruleData);
  if (selectedElementPath.length > 0) {
    let selectedLocation = rules;
    selectedElementPath.forEach((element) => {
      selectedLocation = selectedLocation.data[element];
    });

    selectedLocation.operator = Operators[newOperatorIndex];
  } else {
    rules.operator = Operators[newOperatorIndex];
  }

  return rules;
};

export const addRuleData = (params, ruleData) => {
  const { selectedElementPath } = params;
  let rules = _.cloneDeep(ruleData);
  let selectedElement = rules.data;

  selectedElementPath.forEach((element) => {
    if (selectedElement.operator) {
      selectedElement = selectedElement.data[element];
    } else {
      selectedElement = selectedElement[element];
    }
  });
  selectedElement.data = params.data;
  return rules;
};

export const changeRuleType = (params, ruleData) => {
  let rules = _.cloneDeep(ruleData);
  let selectedElement = rules;
  params.props.selectedElementPath.forEach((element) => {
    selectedElement = selectedElement.data[element];
  });
  selectedElement.type = params.val;
  return rules;
};

export const removeRuleOrGroup = (elementPath, ruleData) => {
  let rules = _.cloneDeep(ruleData);
  let selectedElement = rules;
  elementPath.forEach((element, index) => {
    if (index == elementPath.length - 1) {
      selectedElement.data.splice(element, 1);
    } else {
      selectedElement = selectedElement.data[element];
    }
  });
  return rules;
};
