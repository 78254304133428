import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { FormControlLabel, InputLabel } from '@mui/material';

import DeclaredSelect from '../../../../components/DeclareSelect';
import DeclareAutocomplete from '../../../../components/DeclareAutocomplete';
import {
  getTerms,
  postLearningActivity,
  putLearningActivity,
  getLearningActivities,
} from '../../../../redux/actions/learningActivityActions';
import { useDeclareForm, DeclareForm } from '../../../../components/DeclareForm';
import DeclareTextField from '../../../../components/DeclareTextField';
import { getCatalogs } from '../../../../redux/actions/catalogActions';
import { getTags } from '../../../../redux/actions/tagActions';
import { MenuPropStyles } from '../../../../helpers/common';
import { appRoutes } from '../../../../helpers/routes';
import { courseTypes, courseLevels, getStatus } from '../../../../helpers/appConstants';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  formUnitBoxes: {
    display: 'flex',
    height: theme.spacing(9),
  },
  formUnit: {
    height: theme.spacing(9),
  },
  boxTextFieldMin: {
    width: theme.spacing(15),
    flexDirection: 'column',
  },

  boxTextField: {
    width: theme.spacing(15),
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },
  leftBottomContent: {
    marginTop: theme.spacing(2.7),
  },
  rightBottomContent: {
    marginTop: theme.spacing(0),
  },
  responsiveMultiSelect: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: ({ innerContainerWidth }) => innerContainerWidth / 2,
    },
    [theme.breakpoints.down('xxl')]: {
      maxWidth: ({ innerContainerWidth }) => innerContainerWidth / 2 + 160,
    },
  },
}));

export default function CourseForm(props) {
  const { currentLearningActivity, isEditView, cancelAction } = props;
  const dispatch = useDispatch();
  const orgStore = useSelector((state) => state.allOrgs);
  const catStore = useSelector((state) => state.allCatalogs);
  const termsList = useSelector((state) => state.terms.termList);
  const formError = useSelector((state) => state.notifications.formError);
  const existingTags = useSelector((state) => state.tags.list).map(({ name }) => name);
  const [allowUnitRange, setAllowUnitRange] = useState(false);
  const { innerContainerWidth } = useSelector((state) => state.ui);
  const { orgId } = useParams();

  const classes = useStyle({ innerContainerWidth });

  useEffect(() => {
    if (termsList.length === 0) {
      dispatch(getTerms(orgId));
    }
    if (catStore.catalogs.length === 0) {
      dispatch(getCatalogs(orgId));
    }
    if (existingTags.length === 0) {
      dispatch(getTags(orgId));
    }
  }, []);

  const { values, setValues, handleInputChange } = useDeclareForm(currentLearningActivity);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const { catalogVersions: catalogs } = currentLearningActivity;
    const catalogVersions = catStore.catalogs.filter(
      ({ id }) => catalogs.findIndex((catId) => catId === id) > -1,
    );
    setValues({ ...currentLearningActivity, catalogVersions });
  }, [currentLearningActivity, catStore, termsList]);

  const data = {
    name: values.name,
    code: values.code,
    units: Number(values.units),
    url: values.url,
    type: values.type,
    level: values.level,
    shortDescription: values.shortDescription,
    status: values.status,
    tags: values.tags,
    externalId: values.externalId,
    catalogVersions: values.catalogVersions.map((cat) => cat.id),
    learningActivityType: 'COURSE',
    minUnits: values.minUnits,
    unitStep: values.unitStep,
    maxUnits: values.maxUnits,
  };

  const saveCourse = async () => {
    const navigateToEditView = ({ id }) => {
      navigate(generatePath(appRoutes.catalog.COURSE_EDIT, { orgId, lActivityId: id }));
      dispatch(getLearningActivities(orgId));
      dispatch(getTags(orgId));
    };
    dispatch(postLearningActivity(orgStore.selectedOrg.id, data, navigateToEditView));
  };

  const updateCourse = async () => {
    dispatch(
      putLearningActivity(
        orgStore.selectedOrg.id,
        currentLearningActivity.id,
        {
          ...data,
          existingCatalogVersions: currentLearningActivity.catalogVersions,
        },
        () => {
          dispatch(getLearningActivities(orgId));
          dispatch(getTags(orgId));
        },
      ),
    );
  };

  const handleTagsInputChange = (_event, value) => {
    const lCaseExistingTags = existingTags.map(_.lowerCase);
    const newTags = value.map((tag) => {
      const index = lCaseExistingTags.findIndex((item) => item === _.lowerCase(tag));
      return index < 0 ? tag : existingTags[index];
    });
    handleInputChange({ target: { name: 'tags', value: newTags } });
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclareTextField
              inputlabel={t('courseName') + ' *'}
              variant="outlined"
              placeholder={t('nameOfTheCourse')}
              value={values.name}
              name="name"
              onChange={handleInputChange}
              error={formError['name']}
              helperText={formError['name']?.message}
            />
            <Box>
              {!allowUnitRange ? (
                <Box className={classes.formUnit}>
                  <DeclareTextField
                    inputlabel={t('units') + ' *'}
                    variant="outlined"
                    placeholder={t('units')}
                    value={values.units}
                    name="units"
                    onChange={handleInputChange}
                    error={formError['units']}
                    helperText={formError['units']?.message}
                  />
                </Box>
              ) : (
                <Box className={classes.formUnitBoxes}>
                  <Box className={classes.boxTextFieldMin}>
                    <DeclareTextField
                      inputlabel={t('Min Units') + ' *'}
                      variant="outlined"
                      value={values.minUnits}
                      name="minUnits"
                      onChange={handleInputChange}
                      error={formError['minUnits']}
                      helperText={formError['minUnits']?.message}
                    />
                  </Box>
                  <Box className={classes.boxTextField}>
                    <DeclareTextField
                      inputlabel={t('Unit Step') + ' *'}
                      variant="outlined"
                      value={values.unitStep}
                      name="unitStep"
                      onChange={handleInputChange}
                      error={formError['unitStep']}
                      helperText={formError['unitStep']?.message}
                    />
                  </Box>
                  <Box className={classes.boxTextField}>
                    <DeclareTextField
                      inputlabel={t('Max Units') + ' *'}
                      variant="outlined"
                      value={values.maxUnits}
                      name="maxUnits"
                      onChange={handleInputChange}
                      error={formError['maxUnits']}
                      helperText={formError['maxUnits']?.message}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {/* This section need to enable after backend works done */}
            {/* <FormControlLabel
              control={
                <Checkbox
                  sx={{ marginLeft: '3px' }}
                  checked={allowUnitRange}
                  onChange={(e) => {
                    setAllowUnitRange(e.target.checked);
                  }}
                />
              }
              label={t('Allow range')}
            /> */}
            <Box className={classes.leftBottomContent}>
              <DeclaredSelect
                inputlabel={t('type') + ' *'}
                margin="dense"
                value={values.type}
                name="type"
                onChange={handleInputChange}
                error={formError['type']}
                helperText={formError['type']?.message}>
                {courseTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                inputlabel={t('level') + ' *'}
                margin="dense"
                value={values.level}
                name="level"
                onChange={handleInputChange}
                error={formError['level']}
                helperText={formError['level']?.message}>
                {courseLevels.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclareTextField
                inputlabel={t('externalId')}
                variant="outlined"
                placeholder={t('externalId')}
                value={values.externalId}
                name="externalId"
                onChange={handleInputChange}
                error={formError['externalId']}
                helperText={formError['externalId']?.message}
              />
            </Box>
          </Grid>
          <Grid item xs={6} className="right">
            <DeclareTextField
              inputlabel={t('courseCode') + ' *'}
              variant="outlined"
              placeholder={t('codeOfTheCourse')}
              value={values.code}
              name="code"
              onChange={handleInputChange}
              error={formError['code']}
              helperText={formError['code']?.message}
            />
            <DeclareTextField
              inputlabel={t('descriptionUrl') + ' *'}
              variant="outlined"
              placeholder={t('descriptionUrl')}
              value={values.url}
              name="url"
              onChange={handleInputChange}
              error={formError['url']}
              helperText={formError['url']?.message}
            />
            <Box className={classes.rightBottomContent}>
              <DeclareAutocomplete
                freeSolo
                autoSelect
                onChange={handleTagsInputChange}
                inputlabel={t('tags')}
                multiple
                limitTags={3}
                value={values.tags}
                options={existingTags}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                    placeholder={t('tags')}
                    name="tags"
                    error={formError['tags']}
                    helperText={formError['tags']?.message}
                  />
                )}
              />
              <DeclaredSelect
                className={classes.responsiveMultiSelect}
                multiple
                inputlabel={t('Catalog') + ' *'}
                margin="dense"
                value={values.catalogVersions}
                name="catalogVersions"
                onChange={handleInputChange}
                error={formError['catalogVersions']}
                helperText={formError['catalogVersions']?.message}
                MenuProps={MenuPropStyles}
                renderValue={(selected) => selected.map((catalog) => catalog.name).join(', ')}>
                {catStore.catalogs.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <Checkbox
                      checked={values.catalogVersions.findIndex((cat) => cat.id === item.id) > -1}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                inputlabel={t('status') + ' *'}
                margin="dense"
                value={values.status}
                name="status"
                onChange={handleInputChange}
                error={formError['status']}
                helperText={formError['status']?.message}>
                {getStatus().map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Box>
          </Grid>
        </Grid>
        <DeclareTextField
          multiline={true}
          inputlabel={t('shortDescription') + ' *'}
          variant="outlined"
          placeholder={t('shortDescription')}
          value={values.shortDescription}
          name="shortDescription"
          onChange={handleInputChange}
          error={formError['shortDescription']}
          helperText={formError['shortDescription']?.message}
        />
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateCourse() : saveCourse();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            cancelAction();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
