import _ from 'lodash';
import { createDataRows, createExcelFile, getTodaysDate, initExcel } from './generateExcel';

export async function generateExcelForCourseProjection(t, organization, tableColumns, data) {
  const columns = tableColumns.map(({ name, label }) => ({ header: label, key: name }));

  const columnData = data
    .map((da) =>
      columns.map((cl) => {
        if (cl.key != 'course') {
          return da[cl.key];
        } else {
          return `${da.course.code} - ${da.course.name}`;
        }
      }),
    )
    .sort();

  const title = 'Course Projection';
  const today = getTodaysDate();
  const filename = `${organization.initials}-${_.camelCase(title)}-${today}.xlsx`;

  const [workbook, sheet] = initExcel(title, columns);
  createDataRows(sheet, columnData);
  createExcelFile(workbook, filename);
}
