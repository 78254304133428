import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import CustomError from '../../error/CustomError';
import { setError, setFormError, setSuccess } from '../actions/notificationActions';
import { setLoadingStatus } from '../actions/loadingActions';
import { redirectToLogin } from '../actions/auth';
import { appRoutes } from '../../helpers/routes';

export const requestTypes = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export function* requestHandler(
  apiCall,
  callback,
  type,
  navigate,
  navData,
  showSuccess = false,
  showLoading = true,
  persistErrors = false,
) {
  const loadingKey = apiCall;
  if (showLoading) yield put(setLoadingStatus(true, loadingKey));
  try {
    const response = yield call(apiCall);
    let { status, data } = response;
    if (data?.code === 401) {
      const pathname = window.location.pathname;
      const isLoginPage = pathname.split('/')[1] === appRoutes.auth.LOGIN.split('/')[1];
      if (!isLoginPage) {
        window.localStorage.setItem('returnUrl', window.location.href);
      }

      window.localStorage.removeItem('token');
      window.localStorage.removeItem('access');
      yield put(redirectToLogin());
    }
    if (type === requestTypes.PUT || type === requestTypes.POST || type === requestTypes.PATCH) {
      if (status) {
        if (callback) {
          yield put(callback(data));
        }
        yield put(setError({}));
        yield put(setFormError({}));
        if (showSuccess) {
          yield put(setSuccess({ message: 'Successfully saved' }));
        }
        navigate && navigate(data);
      } else {
        const errorObj = _.keyBy(data.errors, 'field');
        if (!_.isEmpty(errorObj)) {
          if (errorObj.json) {
            yield put(setSuccess({}));
            const { json } = errorObj;
            json.persistErrors = persistErrors;
            yield put(setError(json));
          }
          yield put(setFormError(errorObj));
        } else {
          yield put(setError({ message: data.message }));
        }
      }
    } else {
      if (status) {
        if (callback) {
          yield put(callback(data));
        }
        navigate && navigate({ ...navData, ...data });
      }
    }
    yield put(setLoadingStatus(false, loadingKey));
  } catch (error) {
    console.log('error', error);
    if (error instanceof CustomError) {
      yield put(setError(error));
    } else {
      yield put(setFormError(_.keyBy(error.response.data.errors, 'field')));
    }
    if (showLoading) yield put(setLoadingStatus(false, loadingKey));
  }
}
