import TeqService from '../../../services/TransferEquivalencyService';
import OrgError from '../../../error/OrgError';
import { RequirementService } from '../../../services/RequirementService';

const teqService = new TeqService();
const requirementService = new RequirementService();

export function* requestPostTeq(payload) {
  const { org, data } = payload;
  if (org) {
    return yield teqService.save(org, data);
  }
  throw OrgError.orgRequired();
}

export function* requestPostTeqWithRequirements(payload) {
  const { org, data } = payload;
  if (org) {
    return yield teqService.saveWithRequirements(org, data);
  }
  throw OrgError.orgRequired();
}

export function* requestUpdateTeq(payload) {
  const { org, data, transferEquivalencyId } = payload;
  if (!org) {
    throw OrgError.orgRequired();
  }
  return yield teqService.update(org, transferEquivalencyId, data);
}

export function* requestGetRequirements(orgId, catalogIds) {
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield requirementService.getAll(orgId, catalogIds);
}

export function requestGetTeqs(org, transferringOrg, learningActivities, catalogId) {
  if (org) {
    return teqService.getTeqFromLAs(org, transferringOrg, learningActivities, catalogId);
  }
  throw OrgError.orgRequired();
}

export function requestPostTeqAssignments(payload) {
  const { orgId, studentId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return teqService.saveTeqAssignments(orgId, studentId, body);
}

export function requestPatchTeqAssignments(payload) {
  const { orgId, studentId, body } = payload;
  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return teqService.patchTeqAssignments(orgId, studentId, body);
}

export function* requestGetTeqStatuses(orgId) {
  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield teqService.getAllStatuses(orgId);
}
