import { OrgService } from '../../../services/OrgService';

const orgService = new OrgService();

export function requestGetOrgs() {
  return orgService.getAll();
}

export function requesetPostOrg(data) {
  return orgService.save(data);
}

export function requesetPutOrg(data) {
  return orgService.update(data);
}
