import React from 'react';
import { Menu, MenuList, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
/**
 * Declare Menu
 * @param props Properties
 */

const useStyles = makeStyles((theme) => ({
  menuList: {},
  paper: {
    background: 'red',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  declareMenu: {
    '& .MuiList-root ': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

export default function DeclareMenu(props) {
  props = Object.assign(props);

  const { children } = props;
  const classes = useStyles();

  return (
    <Menu className={classes.declareMenu} {...props}>
      <Paper className={classes.paper}>
        <MenuList className={classes.menuList} dense>
          {children}
        </MenuList>
      </Paper>
    </Menu>
  );
}
