import { Box, Container } from '@mui/material';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../components/DeclareBreadcrumb';

export default function Dashboard() {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('dashboard')]} />
        </Box>
      </Box>
    </Container>
  );
}
