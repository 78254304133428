import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { appRoutes } from '../../../helpers/routes';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updateTermsOrder } from '../../../redux/actions/termActions';
import { listStyleDND, itemStyleDND } from '../../../theme';

export default function TermOrderList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const termList = useSelector((state) => state.terms.termList);
  const orgs = useSelector((store) => store.allOrgs);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (termList.length < 1) {
      dispatch(getTerms(orgId));
    } else {
      setItems(termList);
    }
  }, [termList]);

  const backToTerms = () => {
    navigate(generatePath(appRoutes.catalog.TERMS_LIST, { orgId: orgs.selectedOrg.id }));
  };

  const updateTermOrder = () => {
    const requestItem = { orderItems: items.map((res, idx) => ({ id: res.id, order: idx + 1 })) };
    dispatch(updateTermsOrder(orgs.selectedOrg.id, requestItem, backToTerms));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsOrder = reorder(items, result.source.index, result.destination.index);

    setItems(itemsOrder);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Reorder Terms')]} />
        </Box>
        <Box sx={{ marginTop: '10px', fontFamily: 'Quicksand' }}>
          {t('Drag and drop terms to reorder.')}
        </Box>
        <TableContainer component={Paper} sx={{ marginTop: '20px', 'overflow-x': 'hidden' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={listStyleDND(snapshot.isDraggingOver)}>
                      {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <TableRow
                              key={item.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}>
                              <TableCell
                                component="th"
                                scope="row"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={itemStyleDND(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}>
                                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                                  {item.name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              marginRight: '20px',
            }}
            onClick={() => {
              updateTermOrder();
            }}>
            {t('update')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              color: '#fff',
            }}
            onClick={() => {
              backToTerms();
            }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
