import { HttpService } from './HttpService';

export default class ProgramService extends HttpService {
  async getAll(id) {
    return this.get(`organizations/${id}/programs`);
  }

  async save(org, body) {
    return this.post(`organizations/${org}/programs`, body);
  }
  async update(org, programId, body) {
    return this.put(`organizations/${org}/programs/${programId}`, body);
  }

  async getProgramOptions(orgId, programId) {
    return this.get(`organizations/${orgId}/programs/${programId}/programOptions`);
  }

  async getOption(orgId, programId, optionId) {
    return this.get(`organizations/${orgId}/programs/${programId}/programOptions/${optionId}`);
  }

  async saveOption(orgId, programId, body) {
    return this.post(`organizations/${orgId}/programs/${programId}/programOptions`, body);
  }

  async updateOption(orgId, programId, optionId, body) {
    return this.put(
      `organizations/${orgId}/programs/${programId}/programOptions/${optionId}`,
      body,
    );
  }
}
