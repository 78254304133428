import { Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { transferEquivalencyRawStyles } from '../../../components/styles/transferEquivalencyRowStyles';
import { useSelector } from 'react-redux';
import DeclareLearningActivityCard from '../../../components/DeclareLearningActivityCard';
import DeclareToolTip from '../../../components/DeclareToolTip';
import { AddBoxRounded, Description, Edit, NewReleases, Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import TransferEvaluationToolTip from '../../../pages/advisor/TransferEvaluation/component/TransferEvaluationToolTip';
import { TransferEquivalencyType } from '../../../helpers/appConstants';

export default function TeqDestinationLA(props) {
  const {
    equivalency,
    groupId,
    code,
    name,
    units,
    empty = null,
    transferEquivalency = null,
    handleClickEquivalency = null,
  } = props;
  const classes = transferEquivalencyRawStyles(!transferEquivalency && { option: true });
  const { t } = useTranslation();
  const { teqStatusesEnum } = useSelector((state) => state.transferEquivalency);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { allRequirements } = useSelector((state) => state.transferEquivalency);
  const { currentSelectedStudent } = useSelector((state) => state.students);
  const { status, destinationEqList, note } = equivalency || {
    status: teqStatusesEnum?.EQUIVALENCY_REVIEW_INPROGRESS,
    destinationEqList: [],
    note: null,
  };
  const allCourseReq = learningActivities.concat(allRequirements);

  const customStyle = {
    rowItems: {
      fontWeight: 800,
      fontSize: 'medium',
    },
    rowItemsUnits: {
      fontSize: 'medium',
    },
  };

  return (
    <>
      {!_.isNull(empty) ? (
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.courseContainer}
            sx={{ display: 'flex', flex: 10, padding: '12px' }}>
            <Typography fontWeight={800}>{t('Not Found')}</Typography>
          </Stack>
          <AddBoxRounded
            onClick={() => handleClickEquivalency(code, groupId, name, units)}
            sx={{ cursor: 'pointer', flex: 1 }}
            color="error"
            className={classes.icon}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box className={classes.courseContainer}>
            {destinationEqList.map(({ id, units, type }, index) => {
              const la = allCourseReq.find((res) => res?.id === id);
              return (
                <DeclareLearningActivityCard
                  key={index}
                  units={units}
                  name={
                    la?.name
                      ? la?.name
                      : type === TransferEquivalencyType[0].value
                      ? t('Not found')
                      : t('Requirment not found')
                  }
                  code={
                    la?.code
                      ? la?.code
                      : type === TransferEquivalencyType[1].value
                      ? t('Req.')
                      : null
                  }
                  id={id}
                  type={type}
                  customStyle={customStyle}
                />
              );
            })}
          </Box>
          <Box
            className={classes.actionItemContainer}
            sx={{
              flex: 1,
            }}>
            {status?.type == teqStatusesEnum?.APPROVED_WITH_NOTE && (
              <DeclareToolTip
                title={
                  <Box>
                    <Typography color="common.black" fontWeight={800} marginLeft={3}>
                      {note?.message}
                    </Typography>
                  </Box>
                }>
                <Description
                  className={classes.actionItems}
                  color="primary"
                  fontSize={!_.isNil(transferEquivalency) ? 'medium' : 'small'}
                />
              </DeclareToolTip>
            )}
            {status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW && (
              <DeclareToolTip
                title={
                  <Paper>
                    <Typography marginLeft={2} fontWeight={700} color="common.black">
                      {t('Student by Student Review is Required.')}
                    </Typography>
                  </Paper>
                }>
                <NewReleases
                  className={classes.actionItems}
                  color="yellow"
                  fontSize={!_.isNil(transferEquivalency) ? 'medium' : 'small'}
                />
              </DeclareToolTip>
            )}
            {!status && (
              <TransferEvaluationToolTip
                Icon={Warning}
                iconProps={{ color: 'error' }}
                iconStyle={{ marginLeft: 1 }}
                titleText={t(
                  `Transfer equivalency is not available for the student's preferred program catalog.`,
                )}
                customContent={
                  <Box>
                    <Typography color="common.black" fontWeight={800} marginLeft={3}>
                      {currentSelectedStudent?.currentCatalog?.name}
                    </Typography>
                  </Box>
                }
              />
            )}
            {!_.isNull(transferEquivalency) && (
              <Edit
                className={classes.editIcon}
                color="secondary"
                fontSize="small"
                onClick={() => handleClickEquivalency(code, groupId, name, units, equivalency)}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
