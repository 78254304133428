import ResizePanel from 'react-resize-panel';

class DeclareResizePanel extends ResizePanel {
  constructor(props) {
    super(props);
  }
  validateSize() {
    return;
  }
}
export default DeclareResizePanel;
