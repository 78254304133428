import { setDepartments, getAllDepartments } from '../../actions/departmentActions';
import {
  requestGetColleges,
  requestPostCollegeDepartment,
  requesetPutCollegeDepartment,
} from '../requests/department';
import { requestHandler, requestTypes } from '../helper';

export function* handleGetColleges(action) {
  const {
    payload: { orgId },
  } = action;
  yield requestHandler(requestGetColleges.bind(this, orgId), setDepartments, requestTypes.GET);
}

export function* handlePostCollegesDepartments(action) {
  const { payload, navigate } = action;
  const orgId = payload.org;
  yield requestHandler(
    requestPostCollegeDepartment.bind(this, payload),
    getAllDepartments.bind(this, { orgId }),
    requestTypes.POST,
    navigate,
  );
}

export function* handlePutCollegeDepartment(action) {
  const { payload, navigate } = action;
  const orgId = payload.org;
  yield requestHandler(
    requesetPutCollegeDepartment.bind(this, payload),
    getAllDepartments.bind(this, { orgId }),
    requestTypes.PUT,
    navigate,
  );
}
