import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#4dbc88',
      light: '#89d9aa',
    },
    secondary: {
      main: '#327bb4',
    },
    white: {
      main: '#ffffff',
    },
    black: {
      main: '#000000',
    },
    yellow: {
      main: '#FFDE2B',
    },
    grey: {
      main: '#B0B0B0',
      contrastText: '#fff',
    },
    lightGrey: {
      main: '#EBEBEB',
      contrastText: '#000',
    },
    alert: {
      main: '#F2DEDD',
    },
    warning: {
      main: '#fc8908',
    },
    error: {
      main: '#E8544D',
      secondary: '#C62828',
    },
    container: {
      main: '#ece7e7',
    },
    new: {
      main: '#000000',
    },
    inPlanning: {
      main: '#474ae7',
    },
    sent: {
      main: '#4cbc88',
    },
    invalidPathway: {
      main: '#fec63c',
    },
    pathwayDropped: {
      main: '#ff5e55',
    },
    complete: {
      main: '#4cbd88',
    },
    disabledCourse: {
      main: '#F9F0F0',
    },
  },
  shadows: Array(25).fill('none'),
  typography: {
    fontFamily: 'Quicksand',
  },
  rebuildBanner: {
    fontFamily: 'Helvetica',
  },
  declareStyles: {
    page: {
      marginTop: '30px',
      marginLeft: '30px',
    },
    lightText: {
      fontWeight: 300,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'none',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: 'none',
          },
          MuiButton: {
            root: {
              ':hover': {
                backgroundColor: 'none',
              },
            },
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        toolButton: {
          padding: '0px !important',
          minWidth: '0px !important',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        paper: {
          borderRadius: '10px !important',
        },
        responsiveBase: {
          borderRadius: '10px',
        },
        responsiveStacked: {
          maxHeight: 'none',
          overflowX: 'auto',
        },
      },
    },
  },
});

theme.components.MuiContainer = {
  styleOverrides: {
    root: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
};

theme.components.MuiOutlinedInput = {
  styleOverrides: {
    notchedOutline: {
      borderColor: `${theme.palette.primary.main} !important`,
      borderRadius: '10px',
      borderWidth: '1px !important',
    },
  },
};

export default theme;

const GRID = 8;

export const listStyleDND = (isDraggingOver) => ({
  background: isDraggingOver ? 'white' : 'white',
  padding: GRID,
  width: '100%',
  border: 1,
});

export const itemStyleDND = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: GRID * 2,
  margin: `0 0 ${GRID}px 0`,
  width: '20000px',
  background: isDragging ? '#4dbc88' : 'white',
  ...draggableStyle,
});
