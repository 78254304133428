import React, { useRef, useState, useEffect } from 'react';
import DeclareAutocomplete from './DeclareAutocomplete';
import { TextField, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { selectOrg } from './../redux/actions/orgActions';
import { NavLink, useLocation, useNavigate, generatePath } from 'react-router-dom';
import { appRoutes, APP_TYPES_ROUTE } from './../helpers/routes';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocalStorage } from './../hooks/useLocalStorage';
import { sidebarStyles } from './styles/sidebarStyles';
import CatSidebar from './catalog/Sidebar';
import AdvSidebar from './advisor/Sidebar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getSettings } from '../redux/actions/settingActions';
import { settings, settingsCategories } from '../helpers/settingsHelper';
import { setInnerContainerWidth } from '../redux/actions/uiActions';
import { SCREEN_SIZES } from '../helpers/common';

const Sidebar = (props) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { selectedOrg, orgs } = useSelector((state) => state.allOrgs);
  const [menuCollapsed, setMenuCollapsed] = useLocalStorage('menuCollapsed', false);
  const [orgSelectOpen, setOrgSelectOpen] = useState(false);
  const ref = useRef();
  const { organizationSettings } = useSelector((state) => state.settings);
  const [transferEQAndTransferEVHidden, setTransferEQAndTransferEVHidden] = useState(true);

  useEffect(() => {
    if (isSmallScreen) {
      setMenuCollapsed(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    getSettingsData();
  }, [selectedOrg]);

  useEffect(() => {
    if (!_.isEmpty(organizationSettings)) {
      const moduleAndFeaturesSettings = organizationSettings.find(
        ({ category }) => category === settingsCategories.MODULES_AND_FEATURES,
      );
      if (!_.isNil(moduleAndFeaturesSettings)) {
        const transferEvaluationModuleHiddenStatus =
          moduleAndFeaturesSettings.settings[
            settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE
          ];
        if (!_.isNil(transferEvaluationModuleHiddenStatus)) {
          setTransferEQAndTransferEVHidden(!transferEvaluationModuleHiddenStatus);
        }
      }
    }
  }, [organizationSettings]);

  const timeout = 200;

  const styleProps = {
    arrowLeft: menuCollapsed ? '55px' : '335px',
    sm: menuCollapsed,
    timeout: timeout,
  };

  const classes = sidebarStyles(styleProps);

  const getSettingsData = () => {
    if (!_.isNil(selectedOrg.id)) {
      dispatch(getSettings(selectedOrg.id));
    }
  };

  const sideBarNavigation = (route) => {
    if (pathname !== route) {
      getSettingsData();
      navigate(route);
    }
  };

  const displayActiveClass = (isActive) => {
    return isActive ? `${classes.item} ${classes.active}` : `${classes.item}`;
  };

  const onToggleClicked = () => {
    setMenuCollapsed(!menuCollapsed);
  };
  const containerStyles = `${classes.container} ${menuCollapsed ? classes.menuCollapse : ''}`;

  useEffect(() => {
    dispatch(
      setInnerContainerWidth(menuCollapsed ? SCREEN_SIZES.LARGE_SCREEN : SCREEN_SIZES.SMALL_SCREEN),
    );
  }, [menuCollapsed]);

  return (
    <Box className={classes.root}>
      <Box onClick={onToggleClicked} className={classes.collapseBtn}>
        {menuCollapsed ? (
          <KeyboardArrowRightIcon color="grey" />
        ) : (
          <KeyboardArrowLeftIcon color="grey" />
        )}
      </Box>
      <Box className={containerStyles}>
        {!menuCollapsed ? (
          <DeclareAutocomplete
            onOpen={() => {
              setOrgSelectOpen(true);
            }}
            onBlur={() => {
              setOrgSelectOpen(false);
            }}
            open={orgSelectOpen}
            disableClearable
            getOptionLabel={(option) => option.name || ''}
            value={selectedOrg}
            onChange={(_event, value) => {
              let path =
                `/${props.platform}` == APP_TYPES_ROUTE.CAT
                  ? generatePath(appRoutes.catalog.DASHBOARD, { orgId: value.id })
                  : generatePath(appRoutes.advisor.DASHBOARD, { orgId: value.id });
              dispatch(selectOrg(value));
              sideBarNavigation(path);
              setOrgSelectOpen(false);
            }}
            options={orgs}
            renderInput={(params) => (
              <TextField {...params} value={params.id} ref={(el) => (ref.current = el)} />
            )}
          />
        ) : (
          <Box
            className={classes.shortName}
            onClick={() => {
              onToggleClicked();
              setOrgSelectOpen(true);
              setTimeout(() => {
                if (ref?.current) {
                  ref.current.click();
                }
              }, timeout);
            }}>
            <Typography color="black" fontWeight={800}>
              {selectedOrg?.initials}
            </Typography>
          </Box>
        )}
        {!_.isEmpty(selectedOrg) && (
          <>
            {`/${props.platform}` == APP_TYPES_ROUTE.CAT ? (
              <CatSidebar
                generatePath={generatePath}
                NavLink={NavLink}
                appRoutes={appRoutes}
                orgId={selectedOrg?.id}
                sideBarNavigation={sideBarNavigation}
                displayActiveClass={displayActiveClass}
                classes={classes}
                Typography={Typography}
                hidden={false}
                t={t}
              />
            ) : (
              <AdvSidebar
                generatePath={generatePath}
                NavLink={NavLink}
                appRoutes={appRoutes}
                orgId={selectedOrg?.id}
                sideBarNavigation={sideBarNavigation}
                displayActiveClass={displayActiveClass}
                classes={classes}
                Typography={Typography}
                hidden={transferEQAndTransferEVHidden}
                t={t}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
