import { actionTypes } from '../constants/actionTypes';

export const createPathwayStatusHistoryReport = (
  { orgId, advisors, studentFirstTerms, startDate, endDate },
  callback,
) => {
  return {
    type: actionTypes.REPORTS.FETCH_PATHWAY_STATUS_HISTORY,
    payload: { orgId, advisors, studentFirstTerms, startDate, endDate },
    callback,
  };
};

export const createCourseProjectionReport = (orgId, requestBody, callback) => {
  return {
    type: actionTypes.REPORTS.FETCH_COURSE_PROJECTION,
    payload: { orgId, requestBody },
    callback,
  };
};

export const generateStatusHistoryReport = (orgId, requestBody, callback) => ({
  type: actionTypes.REPORTS.GENERATE_STATUS_HISTORY,
  payload: { orgId, requestBody },
  callback,
});
