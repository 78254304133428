import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, MenuItem, ListItemText, Stack } from '@mui/material';
import { termCardStyles } from '../../styles/termCardStyles';
import { MoreVert, Warning } from '@mui/icons-material';
import DeclareMenu from '../../DeclareMenu';
import _, { set } from 'lodash';
import TermComponent from './TermComponent';
import SitOutComponent from './SitOutComponent';
import { makeStyles } from '@mui/styles';
import {
  EvaluationStatusTypes,
  pathwayCourseCategory,
  termPlanningStatusTypes as tpStatus,
} from '../../../helpers/appConstants';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { buildPathwayData, getPathwayTerms } from '../../../helpers/pathwayHelper';
import {
  getStudentPathway,
  updateStudentByStudentTeqStatus,
} from '../../../redux/actions/studentActions';
import { generateHashFromString } from '../../../helpers/common';
import {
  getFinancialAidSetting,
  getTransferModuleSettings,
  settings,
  settingsCategories,
} from '../../../helpers/settingsHelper';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import { appRoutes } from '../../../helpers/routes';
import TransferEvaluationToolTip from '../../../pages/advisor/TransferEvaluation/component/TransferEvaluationToolTip';
import { getCompletedCourseReq } from '../../../redux/actions/transferEvaluationActions';
import { getStatuses } from '../../../redux/actions/transferEquivalencyActions';

const useStyles = makeStyles((theme) => ({
  laList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    overflowY: 'auto',
    flex: 1,
    justifyContent: ({ center }) => (center ? 'center' : 'flex-start'),
  },
  pathwayBtn: {
    width: '14em',
    '&.MuiButton-root': {
      height: '45px',
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  rebuildPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 5,
    bottom: 0,
    height: '120px',
    backgroundColor: 'white',
    '-webkit-box-shadow': '0px -6px 9px -3px rgba(0,0,0,0.2)',
    '-moz-box-shadow': '0px -6px 9px -3px rgba(0,0,0,0.2)',
    'box-shadow': '0px -6px 9px -3px rgba(0,0,0,0.2)',
    'border-bottom-left-radius': '10px',
    'border-bottom-right-radius': '10px',
  },
  transferredTitleBox: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function TermCardTransferred(props) {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const termCardClasses = termCardStyles();
  const {
    completedCourses,
    courseUsage,
    studentPathway,
    orderedPathway,
    data: { term, items, termPlanningStatus },
    index,
    setFlyoutOpen,
    setFlyoutTitle,
    setFlyoutType,
    pathwayCallback,
    completedCardHeight,
    studentCompletedTerms,
    setDoNotValidate,
    pathwayAdditionalInfo,
    handleCompletedCourseResponse,
  } = props;

  const [ignoredCompletedCourses, setIgnoredCompletedCourses] = useLocalStorage(
    'ignoredCompletedCourses',
    {},
  );
  const [anchorElement, setAnchorElement] = useState(null);
  const [pathwayNeedsToRebuild, setPathwayNeedsToRebuild] = useState(false);
  const [termCardHeight, setTermCardHeight] = useState(400);
  const [showTransferEvaluationWarningIcon, setShowTransferEvaluationWarningIcon] = useState(false);
  const [transferEvaluationMessage, setTransferEvaluationMessage] = useState('');
  const [transferEvaluationButtonText, setTransferEvaluationButtonText] = useState('');
  const [transferEvaluationNavigation, setTransferEvaluationNavigation] = useState('');
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalUsedUnits, setTotalUsedUnits] = useState(0);
  const [manageTransferText, setManageTransferText] = useState(t('Manage Transfer Evaluations'));
  const [transferModuleSetting, setTransferModuleSetting] = useState(true);
  const { currentSelectedStudent } = useSelector((state) => state.students);
  const { studentEvaluations } = useSelector((state) => state.transferEvaluation);
  const { programMapList } = useSelector((state) => state.programMaps);
  const { processed } = useSelector((state) => state.settings);
  const { organizationSettings } = useSelector((state) => state.settings);
  const { termInstances } = useSelector((state) => state.terms);
  const catalogs = useSelector((state) => state.allCatalogs.catalogs);
  const financialAidSetting = getFinancialAidSetting(processed);
  const displayRebuildMenu = !_.isEmpty(orderedPathway) && pathwayNeedsToRebuild;
  const [canRebuildPathway, setCanRebuildPathway] = useState(false);
  const transferModuleActive = getTransferModuleSettings(processed);
  const { teqStatuses } = useSelector((state) => state.transferEquivalency);

  const openMenu = Boolean(anchorElement);

  useEffect(() => {
    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  useEffect(() => {
    const shouldPathwayBeRebuilt =
      !_.isEmpty(orderedPathway) &&
      pathwayNeedsToRebuild &&
      generateHashFromString(JSON.stringify(items) + studentPathway?.programOptionId) !=
        ignoredCompletedCourses[studentPathway?.studentId?.userId];
    setCanRebuildPathway(shouldPathwayBeRebuilt);
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    }
  }, [items, studentPathway, orderedPathway, pathwayNeedsToRebuild, ignoredCompletedCourses]);

  useEffect(() => {
    let height = completedCardHeight < 400 ? 400 : completedCardHeight;
    setTermCardHeight(
      !_.isEmpty(orderedPathway) ? (canRebuildPathway ? height - 100 : height) : 400,
    );
  }, [canRebuildPathway, orderedPathway, completedCardHeight]);

  useEffect(() => {
    if (!_.isEmpty(organizationSettings)) {
      const moduleAndFeaturesSettings = organizationSettings.find(
        ({ category }) => category === settingsCategories.MODULES_AND_FEATURES,
      );
      if (!_.isNil(moduleAndFeaturesSettings)) {
        const transferEvaluationModuleHiddenStatus =
          moduleAndFeaturesSettings.settings[
            settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE
          ];
        if (!transferEvaluationModuleHiddenStatus) {
          setManageTransferText(t('Manage Transfer Courses'));
        }

        setTransferModuleSetting(transferEvaluationModuleHiddenStatus);
      }
    }
  }, [organizationSettings]);

  useEffect(() => {
    const completedCourses = Object.entries(items).flatMap((data) => data[1]);

    if (_.isNil(courseUsage)) {
      setPathwayNeedsToRebuild(false);
    } else if (courseUsage.length != completedCourses.length) {
      setPathwayNeedsToRebuild(true);
    } else {
      for (const course of completedCourses) {
        const data = courseUsage.find(
          (cCourse) => cCourse.courseReqId === course.id && cCourse.type === course.type,
        );
        if (_.isUndefined(data)) {
          setPathwayNeedsToRebuild(true);
          return;
        }
      }
      setPathwayNeedsToRebuild(false);
    }
  }, [studentPathway, items, courseUsage, completedCourses]);

  useEffect(() => {
    checkStudentEvaluation();
  }, [currentSelectedStudent.programOption, studentEvaluations]);

  useEffect(() => {
    const { totalUnits, totalUsedUnits } = calculateUnits(
      items,
      pathwayAdditionalInfo?.usedRequirementTransfers,
    );
    setTotalUnits(totalUnits);
    setTotalUsedUnits(totalUsedUnits);
  }, [items, pathwayAdditionalInfo]);

  const findUsedUnitsForTransferredCourse = (courseId, usedRequirementTransfers) => {
    const matchingTransfer = usedRequirementTransfers?.find((transfer) => transfer.id === courseId);
    return matchingTransfer ? matchingTransfer?.units : 0;
  };

  const calculateUnits = (items, usedRequirementTransfers) => {
    let totalUnits = 0;
    let totalUsedUnits = 0;

    ['Completed'].forEach((category) => {
      (items[category] || []).forEach((item) => {
        totalUnits += item.units;
        totalUsedUnits += item.units;
      });
    });

    (items.Transferred || []).forEach((item) => {
      if (item.additionalInfo && item.additionalInfo.requirementTransfer) {
        totalUnits += item.additionalInfo.requirementTransfer.unitsTransferred;
        totalUsedUnits += findUsedUnitsForTransferredCourse(item.id, usedRequirementTransfers);
      } else {
        totalUnits += item.units;
        totalUsedUnits += item.units;
      }
    });

    return { totalUnits, totalUsedUnits };
  };

  const handleMenuOpen = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const includeCompleteCourses = () => {
    setFlyoutTitle(t('Manage Completed Courses'));
    setFlyoutType(pathwayCourseCategory.COMPLETED);
    setFlyoutOpen(true);
  };

  const includeExemptCourses = () => {
    setFlyoutTitle(t('Manage Exempt Courses'));
    setFlyoutType(pathwayCourseCategory.EXEMPT);
    setFlyoutOpen(true);
  };

  const handleTransferCoursesFlyOut = () => {
    setFlyoutTitle(manageTransferText);
    setFlyoutType(pathwayCourseCategory.TRANSFERRED);
    setFlyoutOpen(true);
  };

  const appendIgnoredCompletedCourses = () => {
    const newIgnoredCompletedCourses = {
      ...ignoredCompletedCourses,
      [studentPathway.studentId.userId]: generateHashFromString(
        JSON.stringify(items) + studentPathway.programOptionId,
      ),
    };
    setIgnoredCompletedCourses(newIgnoredCompletedCourses);
  };

  const rebuildPathway = () => {
    const values = {
      selectedTerms: getPathwayTerms(orderedPathway, studentCompletedTerms, termInstances),
      rebuildPathway: true,
    };

    dispatch(
      getStudentPathway(
        orgId,
        currentSelectedStudent.id,
        buildPathwayData(
          currentSelectedStudent,
          programMapList,
          values,
          completedCourses,
          financialAidSetting,
          catalogs,
        ),
        pathwayCallback,
      ),
    );
  };

  const setStudentEvaluationStates = (iconValue, buttonText, message, navigation) => {
    setShowTransferEvaluationWarningIcon(iconValue);
    setTransferEvaluationButtonText(t(buttonText));
    setTransferEvaluationMessage(t(message));
    setTransferEvaluationNavigation(navigation);
  };

  const checkStudentEvaluation = () => {
    if (_.isEmpty(studentEvaluations) || !transferModuleActive)
      return setShowTransferEvaluationWarningIcon(false);

    const evaluations = studentEvaluations
      .map(({ programMapId, statusCode }) => {
        const pm = programMapList.find(({ id }) => id === programMapId);
        return {
          ...pm,
          statusCode,
        };
      })
      .filter((data) => data.optionId === currentSelectedStudent.programOption.id);

    if (_.isEmpty(evaluations)) {
      return setStudentEvaluationStates(
        true,
        'Create Evaluation',
        'Transfers have not been evaluated for this program',
        appRoutes.advisor.TRANSFER_EVALUATIONS_CREATE,
      );
    }

    const studentHasEvaluationUsedInPlanning = evaluations.filter(
      ({ statusCode }) => statusCode.type === EvaluationStatusTypes.USED_IN_PLANNING,
    );

    if (_.isEmpty(studentHasEvaluationUsedInPlanning)) {
      return setStudentEvaluationStates(
        true,
        'View Evaluations',
        'A transfer evaluation for this program exists but not used',
        appRoutes.advisor.TRANSFER_EVALUATIONS_LIST,
      );
    }

    setShowTransferEvaluationWarningIcon(false);
  };

  const updateStudentTransferEquivalencyStatus = (transferEquivalencyId, body) => {
    setDoNotValidate(false);
    dispatch(
      updateStudentByStudentTeqStatus(
        orgId,
        currentSelectedStudent.id,
        transferEquivalencyId,
        body,
        () =>
          dispatch(
            getCompletedCourseReq(orgId, currentSelectedStudent.id, handleCompletedCourseResponse),
          ),
      ),
    );
  };

  return (
    <Box className={termCardClasses.termCard}>
      <Box className={termCardClasses.termCardHeader}>
        <Box>
          <Typography variant="body1" fontSize={21} fontWeight={700}>
            {term.name}
          </Typography>
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {`${totalUnits} ${t('Units')}`} {`(${totalUsedUnits} ${t('Units Used in Plan')})`}
          </Typography>
        </Box>
        <Box className={termCardClasses.sitOutBtn}>
          <MoreVert
            onClick={handleMenuOpen}
            fontSize="medium"
            color="common.black"
            className={termCardClasses.contextMenuBtn}
          />
          <DeclareMenu anchorEl={anchorElement} open={openMenu} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                transferModuleSetting
                  ? navigate(
                      generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, {
                        orgId: orgId,
                      }),
                    )
                  : handleTransferCoursesFlyOut();
                handleMenuClose();
              }}>
              <ListItemText>{manageTransferText}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                includeCompleteCourses();
                handleMenuClose();
              }}>
              <ListItemText>{t('Manage Completed Courses')}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                includeExemptCourses();
                handleMenuClose();
              }}>
              <ListItemText>{t('Manage Exempt Courses')}</ListItemText>
            </MenuItem>

            {displayRebuildMenu && (
              <MenuItem
                onClick={() => {
                  setDoNotValidate(false);
                  rebuildPathway();
                  handleMenuClose();
                }}>
                <ListItemText>{t('Rebuild Pathway')}</ListItemText>
              </MenuItem>
            )}
          </DeclareMenu>
        </Box>
      </Box>
      <Box
        className={termCardClasses.completedCourseCardContent}
        sx={{
          height: termCardHeight,
        }}>
        <Box className={termCardClasses.termCardButtonContainer}>
          {/* TODO need to check if the user has not ordered pathway, but transferred, complete or exempt learning activities */}
          {_.isEmpty(orderedPathway) && _.isEmpty(items) ? (
            <>
              <Box className={termCardClasses.termCardButtonItem}>
                <Button
                  className={termCardClasses.termCardButton}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    transferModuleSetting
                      ? navigate(
                          generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, {
                            orgId: orgId,
                          }),
                        )
                      : handleTransferCoursesFlyOut()
                  }>
                  <Typography color="common.white">{manageTransferText}</Typography>
                </Button>
              </Box>
              <Box className={termCardClasses.termCardButtonItem}>
                <Button
                  className={termCardClasses.termCardButton}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => includeCompleteCourses()}>
                  <Typography color="common.white">{t('Manage Completed Courses')}</Typography>
                </Button>
              </Box>
              <Box className={termCardClasses.termCardButtonItem}>
                <Button
                  className={termCardClasses.termCardButton}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => includeExemptCourses()}>
                  <Typography color="common.white">{t('Manage Exempt Courses')}</Typography>
                </Button>
              </Box>
            </>
          ) : (
            _.isEmpty(items) && (
              <Box className={termCardClasses.termCardTransferredLaList}>
                <Box className={termCardClasses.termCardButtonItem}>
                  <Typography color="common.black" fontWeight={700}>
                    {t('Nothing added') + '.'}
                  </Typography>
                </Box>
              </Box>
            )
          )}
        </Box>
        {!_.isEmpty(items) && (
          <Box className={classes.laList}>
            {/* TODO: get these status from the backend */}
            {termPlanningStatus === tpStatus.SIT_OUT ? (
              <SitOutComponent />
            ) : (
              <>
                {!_.isUndefined(items?.Transferred) && !_.isEmpty(items?.Transferred) ? (
                  <>
                    <Box className={classes.transferredTitleBox}>
                      <Typography>{t('Transferred')} </Typography>
                      {showTransferEvaluationWarningIcon && (
                        <TransferEvaluationToolTip
                          Icon={Warning}
                          iconProps={{ color: 'error' }}
                          iconStyle={{ marginLeft: 1 }}
                          titleText={transferEvaluationMessage}
                          btnArray={[
                            {
                              text: transferEvaluationButtonText,
                              onClickFn: () =>
                                navigate(
                                  generatePath(transferEvaluationNavigation, {
                                    orgId: orgId,
                                  }),
                                ),
                            },
                          ]}
                        />
                      )}
                    </Box>
                    {items.Transferred.map((la, laIndex) => (
                      <TermComponent
                        key={laIndex}
                        path={[index, laIndex]}
                        laCode={la.code}
                        laName={la.name}
                        laUnits={la.units}
                        la={la}
                        status={termPlanningStatus}
                        laAdditionalInfo={la.additionalInfo}
                        pathwayAdditionalInfo={pathwayAdditionalInfo}
                        termTransferred={true}
                        updateStudentTransferEquivalencyStatus={
                          updateStudentTransferEquivalencyStatus
                        }
                        orderedPathway={orderedPathway}
                      />
                    ))}
                  </>
                ) : null}
                {!_.isUndefined(items?.Completed) && !_.isEmpty(items?.Completed) ? (
                  <>
                    <Typography>{t('Completed')}</Typography>
                    {items?.Completed?.map((la, laIndex) => (
                      <TermComponent
                        key={laIndex}
                        path={[index, laIndex]}
                        laCode={la.code}
                        laName={la.name}
                        laUnits={la.units}
                        status={termPlanningStatus}
                        laAdditionalInfo={la.additionalInfo}
                        termTransferred={true}
                      />
                    ))}
                  </>
                ) : null}

                {!_.isUndefined(items?.Exempt) && !_.isEmpty(items?.Exempt) ? (
                  <>
                    <Typography>{t('Exempt')}</Typography>
                    {items?.Exempt.map((la, laIndex) => (
                      <TermComponent
                        key={laIndex}
                        path={[index, laIndex]}
                        laCode={la.code}
                        laName={la.name}
                        laUnits={la.units}
                        status={termPlanningStatus}
                        laAdditionalInfo={la.additionalInfo}
                        termTransferred={true}
                      />
                    ))}
                  </>
                ) : null}
              </>
            )}
          </Box>
        )}
      </Box>
      {canRebuildPathway && (
        <Box className={classes.rebuildPanel}>
          <Typography textAlign="center" fontSize={20}>
            {t('Some added courses are not part of the pathway.')}
          </Typography>
          <Stack direction="row" justifyContent="center" gap={1}>
            <Button
              className={classes.pathwayBtn}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                setDoNotValidate(false);
                rebuildPathway();
              }}>
              <Typography sx={{ color: 'common.white' }}>{t('Rebuild Pathway')}</Typography>
            </Button>
            <Button
              className={classes.pathwayBtn}
              disableElevation
              variant="contained"
              color="grey"
              onClick={() => {
                appendIgnoredCompletedCourses();
              }}>
              <Typography sx={{ color: 'common.white' }}>{t('Ignore')}</Typography>
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default TermCardTransferred;
