import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import DeclaredSelect from '../../../components/DeclareSelect';
import { useTranslation } from 'react-i18next';

import { appRoutes } from '../../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus, departments } from '../../../helpers/appConstants';
import DeclareTextField from '../../../components/DeclareTextField';
import { postDepartment, putDepartment } from '../../../redux/actions/departmentActions';

export default function ProgramMapForm(props) {
  const { currentDepartment, isEditView } = props;
  const { departmentId } = useParams();
  const { values, setValues, handleInputChange } = useDeclareForm(currentDepartment);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orgs = useSelector((store) => store.allOrgs);
  const { formError } = useSelector((store) => store.notifications);

  useEffect(() => {
    setValues(currentDepartment);
  }, [currentDepartment]);

  const data = {
    name: values.name,
    status: values.status,
    type: values.type,
  };

  const backToCollegesAndDepartments = () => {
    navigate(generatePath(appRoutes.catalog.COLLEGE_LIST, { orgId: orgs.selectedOrg.id }));
  };

  const saveCollegesAndDepartments = async () => {
    dispatch(postDepartment(orgs.selectedOrg.id, data, backToCollegesAndDepartments));
  };

  const updateCollegesAndDepartments = async () => {
    dispatch(putDepartment(orgs.selectedOrg.id, departmentId, data, backToCollegesAndDepartments));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclareTextField
              inputlabel={t('name') + ' *'}
              variant="outlined"
              value={values.name}
              name="name"
              onChange={handleInputChange}
              error={formError['name']}
              helperText={formError['name']?.message}
            />
            <DeclaredSelect
              inputlabel={t('status') + ' *'}
              margin="dense"
              value={values.status}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {getStatus().map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
          <Grid item xs={6} className="right">
            <DeclaredSelect
              inputlabel={t('type') + ' *'}
              margin="dense"
              value={values.type}
              name="type"
              onChange={handleInputChange}
              error={formError['type']}
              helperText={formError['type']?.message}>
              {departments.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateCollegesAndDepartments() : saveCollegesAndDepartments();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            backToCollegesAndDepartments();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
