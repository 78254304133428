import React, { useState, useEffect } from 'react';
import { getStatus } from '../../../../helpers/appConstants';
import ClassScheduleForm from './ClassScheduleForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClassSchedules } from '../../../../redux/actions/learningActivityActions';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

export default function EditCreateClassSchedule(props) {
  const { currentLearningActivity } = props;
  const dispatch = useDispatch();
  const { orgId, lActivityId, classScheduleId } = useParams();
  const isEditView = classScheduleId !== undefined;
  const { classScheduleList } = useSelector((state) => state.allLearningActivities);
  const [currentClassSchedule, setCurrentClassSchedule] = useState({
    deliveryMode: '',
    status: getStatus()[3].value,
    termInstance: '',
    duration: 1,
  });

  useEffect(() => {
    if (isEditView) {
      if (_.isEmpty(classScheduleList)) {
        dispatch(getAllClassSchedules({ orgId, lActivityId }));
      } else {
        const selectedClassSchedule = classScheduleList.find(({ id }) => id === classScheduleId);

        if (_.isUndefined(selectedClassSchedule)) return;
        const { deliveryMode, duration, termInstanceId, status } = selectedClassSchedule;

        setCurrentClassSchedule({
          deliveryMode,
          duration,
          termInstanceId,
          status,
        });
      }
    }
  }, [classScheduleList]);

  return (
    <ClassScheduleForm
      currentClassSchedule={currentClassSchedule}
      currentLearningActivity={currentLearningActivity}
      isEditView={isEditView}
    />
  );
}
