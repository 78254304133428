import { makeStyles } from '@mui/styles';

export const sidebarStyles = makeStyles((theme) => ({
  root: {
    '&:hover $collapseBtn': {
      display: 'block',
    },
  },
  container: {
    transition: `all ${({ timeout }) => timeout / 1000}s`,
    width: '350px',
    overflow: 'auto',
    height: 'calc(100vh - 114px)',
    color: 'white',
    paddingTop: theme.spacing(14),
    backgroundColor: theme.palette.primary.main,
    position: 'sticky',
    top: 0,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      border: `1px solid ${theme.palette.container.main}`,
    },
    '& .MuiTextField-root': {
      backgroundColor: 'white',
      borderRadius: '10px',
      marginBottom: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      height: '45px',
    },
    '& .MuiAutocomplete-root': {
      paddingLeft: '20px',
      paddingRight: '20px',
      '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
        paddingTop: '4px',
      },
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.up('sm')]: {
      cursor: 'pointer',
    },
    '&:hover': {
      color: '#375065',
    },
  },
  active: {
    color: '#375065',
    backgroundColor: '#8bdcc4',
  },
  icon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  text: {
    fontWeight: 500,
  },
  menuCollapse: {
    width: '70px',
    '& $text': {
      display: 'none',
    },
  },
  collapseBtn: {
    display: ({ sm }) => {
      return sm ? 'block' : 'none';
    },
    transition: 'all 0.2s',
    background: 'white',
    width: '25px',
    height: '25px',
    position: 'fixed',
    left: ({ arrowLeft }) => arrowLeft,
    top: '90px',
    zIndex: 10,
    border: '1px solid gray',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  shortName: {
    cursor: 'pointer',
    background: 'white',
    marginRight: '10px',
    marginLeft: '10px',
    marginBottom: '24px',
    textAlign: 'center',
    borderRadius: '10px',
    '& .MuiTypography-root': {
      lineHeight: 2.8,
    },
  },
}));
