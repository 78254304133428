import { HttpService } from './HttpService';

export class CatService extends HttpService {
  async getAll(id) {
    return await this.get(`organizations/${id}/catalogs`);
  }

  async save(org, body) {
    return await this.post(`organizations/${org}/catalogs`, body);
  }

  async duplicate(org, sourceCatalog, body) {
    return await this.post(`organizations/${org}/catalogs/${sourceCatalog}/duplicate`, body);
  }

  async update(org, catalog, body) {
    return await this.put(`organizations/${org}/catalogs/${catalog}`, body);
  }

  async order(org, body) {
    return await this.put(`organizations/${org}/catalogs/order`, body);
  }
}
