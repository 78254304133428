import { actionTypes } from '../constants/actionTypes';

export const setError = (error) => {
  return {
    type: actionTypes.SET_ERROR,
    payload: error,
  };
};

export const setSuccess = (successData) => {
  return {
    type: actionTypes.SET_SUCCESS,
    payload: successData,
  };
};

export const setFormError = (error) => {
  return {
    type: actionTypes.SET_FORM_ERROR,
    payload: error,
  };
};
