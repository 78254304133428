import { Download } from '@mui/icons-material';
import {
  Checkbox,
  Stack,
  ListItemText,
  Box,
  Button,
  Container,
  MenuItem,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { React, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import { DeclareForm, useDeclareForm } from '../../../components/DeclareForm';
import DeclaredSelect from '../../../components/DeclareSelect';
import SearchTextBox from '../../../components/SearchTextBox';
import { MenuPropStyles, SCREEN_SIZES } from '../../../helpers/common';
import { reorderPathwayStatusList } from '../../../helpers/pathwayHelper';
import { generateExcelForCourseProjection } from '../../../processes/exportCourseProjectionReport';
import { getLearningActivities, getTerms } from '../../../redux/actions/learningActivityActions';
import { setError, setFormError } from '../../../redux/actions/notificationActions';
import { getPathwayStatus } from '../../../redux/actions/pathwayActions';
import { createCourseProjectionReport } from '../../../redux/actions/reportActions';
import { getAcademicYearsWithTermInstances } from '../../../redux/actions/termActions';
import { studentPathwayStatusTypes } from '../../../helpers/appConstants';

const useStyle = makeStyles((theme) => ({
  generateBtn: {
    '&.MuiButtonBase-root.MuiButton-root': {
      marginTop: theme.spacing(4),
      height: theme.spacing(5.5),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 400,
      width: theme.spacing(25),
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  filterControlColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: theme.spacing(1),
  },
  filterContainer: {
    display: 'flex',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
}));

export default function CourseProjectionReport() {
  const DEFAULT_RIGHT_MARGIN = 15;
  const { t } = useTranslation();
  const { termList, academicYears } = useSelector((state) => state.terms);
  const { pathwayStatus } = useSelector((state) => state.pathway);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { innerContainerWidth } = useSelector((state) => state.ui);
  const { selectedOrg } = useSelector((state) => state.allOrgs);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [allTerms, setAllTerms] = useState([]);
  const [orderedPathwayStatus, setOrderedPathwayStatus] = useState([]);
  const { formError } = useSelector((state) => state.notifications);
  const containerRef = useRef(0);
  const [tableMaxWidth, setTableMaxWidth] = useState(null);
  const [tableColumns, setTableColumns] = useState([
    {
      name: 'course',
      label: t('Course'),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { maxWidth: '100px', width: '100px' },
        }),
        customBodyRender: ({ code, name }) => {
          return (
            <Tooltip title={name} arrow>
              <Typography variant="body2" fontWeight={700} sx={{ width: '100px' }}>
                {code}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
  ]);
  const { values, handleInputChange } = useDeclareForm({
    startingTerm: [],
    endingTerm: [],
    pathwayStatus: [],
  });
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const classes = useStyle();

  useEffect(() => {
    if (_.isEmpty(termList)) {
      dispatch(getTerms(orgId));
    }
    if (_.isEmpty(pathwayStatus)) {
      dispatch(getPathwayStatus(orgId));
    }
    if (_.isEmpty(learningActivities)) {
      dispatch(getLearningActivities(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }

    if (!_.isEmpty(academicYears) && !_.isEmpty(termList)) {
      const filteredAllTerms = academicYears.flatMap((academicYear) => academicYear.termInstances);
      setAllTerms(filteredAllTerms);
    }
  }, [termList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty([pathwayStatus])) {
      setOrderedPathwayStatus(
        reorderPathwayStatusList(pathwayStatus, 'type').map((status) => status),
      );
    }
  }, [pathwayStatus]);

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    enableNestedDataAccess: '.',
    customSort: (data, colIndex, order) => {
      return data.sort((firstElement, secondElement) => {
        if (colIndex === 0) {
          return (
            firstElement.data[colIndex].code.localeCompare(
              secondElement.data[colIndex].code,
              'en',
              {
                sensitivity: 'base',
              },
            ) * (order === 'desc' ? -1 : 1)
          );
        } else {
          return (
            (firstElement.data[colIndex] < secondElement.data[colIndex] ? -1 : 1) *
            (order === 'desc' ? 1 : -1)
          );
        }
      });
    },
  };

  const onGenerateClick = () => {
    const requestBody = {
      startTerm: { id: values.startingTerm.termId, year: values.startingTerm.calendarYear },
      endTerm: { id: values.endingTerm.termId, year: values.endingTerm.calendarYear },
      pathwayStatusIds: [...values.pathwayStatus.map(({ id }) => id)],
      courseIds: [...learningActivities.map(({ id }) => id)],
    };

    const errorObj = {};

    if (_.isEmpty(values.startingTerm)) {
      errorObj['startingTerm'] = {
        message: '"startingTerm" is required',
      };
    }
    if (_.isEmpty(values.endingTerm)) {
      errorObj['endingTerm'] = {
        message: '"endingTerm" is required',
      };
    }
    if (_.isEmpty(values.pathwayStatus)) {
      errorObj['pathwayStatus'] = {
        message: '"pathwayStatus" is required',
      };
    }

    dispatch(setFormError(errorObj));

    if (_.isEmpty(errorObj)) {
      dispatch(
        createCourseProjectionReport(orgId, requestBody, (response) => {
          const { data, terms } = response;
          const columns = terms.map((term) => {
            const filteredTerm = allTerms.find(
              (termDetail) =>
                termDetail.termId === term.id && termDetail.calendarYear === term.year,
            );
            return {
              name: `term${filteredTerm.id}`,
              label: filteredTerm.displayName,
              options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                  style: {
                    maxWidth: '100px',
                    width: '100px',
                    textAlign: 'center',
                  },
                }),
                customBodyRender: (value) => {
                  return (
                    <Typography variant="body2" fontWeight={700} sx={{ width: '100px' }}>
                      {value}
                    </Typography>
                  );
                },
              },
            };
          });

          setTableColumns((tableColumnList) => [tableColumnList[0], ...columns]);
          const courseData = data.map((course) => {
            const courseDetails = learningActivities.find((la) => la.id === course.id);
            const columnList = columns.map((column, index) => {
              return {
                [column.name]: course.projection[index],
              };
            });
            const columnObject = Object.assign({}, ...columnList);
            return {
              course: courseDetails,
              ...columnObject,
            };
          });

          const sortedCourseData = courseData.sort((firstElement, secondElement) => {
            return firstElement.course.code.localeCompare(secondElement.course.code, 'en', {
              sensitivity: 'base',
            });
          });

          setTableData(sortedCourseData);
        }),
      );
    }
  };

  const handleTermChange = (e) => {
    const { name, value } = e.target;
    let startingTermIndex;
    let endTermIndex;
    if (name === 'endingTerm') {
      if (!_.isEmpty(values.startingTerm)) {
        startingTermIndex = allTerms.findIndex(({ id }) => id === values.startingTerm.id);
        endTermIndex = allTerms.findIndex(({ id }) => id === value.id);
      }
    } else {
      if (!_.isEmpty(values.endingTerm)) {
        startingTermIndex = allTerms.findIndex(({ id }) => id === value.id);
        endTermIndex = allTerms.findIndex(({ id }) => id === values.endingTerm.id);
      }
    }

    if (endTermIndex <= startingTermIndex) {
      dispatch(
        setError({
          message: t('Ending term should be after starting term.'),
        }),
      );
      return;
    }

    handleInputChange({ target: { name, value } });
  };

  const downloadCourseProjection = async () => {
    generateExcelForCourseProjection(t, selectedOrg, tableColumns, filteredTableData);
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value;
    var filteredList = [];
    if (!_.isEmpty(tableData)) {
      const filters = [
        (o) => o.course.name.toLowerCase().includes(searchText.toLowerCase()),
        (o) => o.course.code.toLowerCase().includes(searchText.toLowerCase()),
      ];

      filteredList = tableData.filter((o) => filters.some((fn) => fn(o)));
      setFilteredTableData(filteredList);
    } else {
      setFilteredTableData(tableData);
    }
  };
  const debouncedResults = useMemo(() => {
    return _.debounce(handleSearchChange, 300);
  }, [tableData]);

  useEffect(() => {
    handleSearchChange({ target: { value: '' } });
  }, [tableData]);

  useEffect(() => {
    if (!_.isNil(innerContainerWidth)) {
      if (innerContainerWidth < containerRef.current.offsetWidth) {
        if (containerRef.current.offsetWidth > SCREEN_SIZES.LARGE_SCREEN) {
          setTableMaxWidth(containerRef.current.offsetWidth);
        } else {
          setTableMaxWidth(innerContainerWidth);
        }
      } else {
        setTableMaxWidth(innerContainerWidth);
      }
    }
  }, [innerContainerWidth, containerRef]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }} ref={containerRef}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Course Projections')]} />
        </Box>
        <DeclareForm>
          <Paper className={classes.filterContainer}>
            <Box className={classes.filterControlColumn}>
              <DeclaredSelect
                inputlabel={
                  <Typography fontWeight={700} color="common.black">
                    {t('Starting Term') + ' *'}
                  </Typography>
                }
                sm={true}
                margin="dense"
                name="startingTerm"
                placeholder="Select"
                selected={!_.isEmpty(values.startingTerm)}
                onChange={handleTermChange}
                error={formError['startingTerm']}
                helperText={formError['startingTerm']?.message}
                MenuProps={MenuPropStyles}
                value={values.startingTerm}>
                {allTerms.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                inputlabel={
                  <Typography fontWeight={700} color="common.black">
                    {t('Pathway Status') + ' *'}
                  </Typography>
                }
                multiple
                sm={true}
                margin="dense"
                name="pathwayStatus"
                selected={!_.isEmpty(values.pathwayStatus)}
                MenuProps={MenuPropStyles}
                placeholder="Select"
                onChange={handleInputChange}
                error={formError['pathwayStatus']}
                helperText={formError['pathwayStatus']?.message}
                value={values.pathwayStatus}
                renderValue={(selected) =>
                  selected.map((pathStatus) => pathStatus.name).join(', ')
                }>
                {orderedPathwayStatus
                  .filter(
                    (pathwayStatus) =>
                      pathwayStatus.type !== studentPathwayStatusTypes.PATHWAY_INVALID,
                  )
                  .map((item, index) => (
                    <MenuItem value={item} key={index}>
                      <Checkbox
                        checked={
                          values.pathwayStatus.findIndex(
                            (pathStatus) => pathStatus.type === item.type,
                          ) > -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
              </DeclaredSelect>
            </Box>
            <Box className={classes.filterControlColumn}>
              <DeclaredSelect
                inputlabel={
                  <Typography fontWeight={700} color="common.black">
                    {t('Ending Term') + ' *'}
                  </Typography>
                }
                sm={true}
                margin="dense"
                name="endingTerm"
                selected={!_.isEmpty(values.endingTerm)}
                placeholder="Select"
                onChange={handleTermChange}
                error={formError['endingTerm']}
                helperText={formError['endingTerm']?.message}
                MenuProps={MenuPropStyles}
                value={values.endingTerm}>
                {allTerms.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <Button
                onClick={onGenerateClick}
                disableElevation
                variant="contained"
                color="secondary"
                className={classes.generateBtn}>
                {t('Generate Report')}
              </Button>
            </Box>
          </Paper>
        </DeclareForm>
        <Box className={classes.searchContainer}>
          <Stack flexDirection="row" alignItems="center">
            <SearchTextBox
              sx={{ width: 350 }}
              label={t('Search course name or code')}
              mr={0}
              onChange={debouncedResults}
            />
            <IconButton
              aria-label="download"
              disabled={_.isEmpty(filteredTableData)}
              disableRipple={true}
              color="secondary"
              size="large"
              onClick={downloadCourseProjection}>
              <Download fontSize="inherit" />
            </IconButton>
          </Stack>
        </Box>
        <Box
          mt={3}
          mb={3}
          sx={{
            maxWidth: _.isEmpty(tableColumns)
              ? tableMaxWidth + DEFAULT_RIGHT_MARGIN
              : tableMaxWidth,
            overflowX: 'auto',
            width: '100%',
          }}>
          <DeclareDataTable
            data={filteredTableData}
            columns={tableColumns.length > 1 ? tableColumns : []}
            options={options}
          />
        </Box>
      </Box>
    </Container>
  );
}
