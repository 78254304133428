import { HttpService } from './HttpService';

export class ClassScheduleService extends HttpService {
  async getAll(orgId, query) {
    return this.get(`organizations/${orgId}/classSchedules?${query}`);
  }

  cancelSingleClassSchedule(orgId, classScheduleId, body) {
    return this.patch(`organizations/${orgId}/classSchedules/${classScheduleId}`, body);
  }

  async plannedStudents(orgId, termId, year, courseId) {
    return await this.get(
      `organizations/${orgId}/students/byClassSchedule?termId=${termId}&year=${year}&courseId=${courseId}`,
    );
  }

  async sendCancelledClassScheduleStats(orgId, body) {
    return this.post(`organizations/${orgId}/reports/classSchedules`, body);
  }
}
