import { AuthService } from '../../../services/AuthService';

const authService = new AuthService();

export function requestRedirectToLogin(payload) {
  return authService.goToLoginScreen(payload);
}

export function requestUserInfo() {
  return authService.getUserInfo();
}
