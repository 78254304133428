import { Box, Container, Typography, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import theme from '../../../theme';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import StatusCell from '../../../components/StatusCell';
import { appRoutes } from '../../../helpers/routes';
import {
  getProgramMapRequirements,
  getProgramMaps,
} from '../../../redux/actions/programMapActions';
import { getStudentTransferEvaluations } from '../../../redux/actions/transferEvaluationActions';
import _ from 'lodash';
import { TEvPrintPdfComponent } from '../../../templates/TEvPrintPdfComponent';
import { EvaluationStatusTypes } from '../../../helpers/appConstants';
import { getAllRequirements } from '../../../redux/actions/transferEquivalencyActions';

const TEV_STATUS_WIDTH = 125;

export default function List() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const selectedStudent = useSelector((state) => state.students.currentSelectedStudent);
  const studentTEvs = useSelector((state) => state.transferEvaluation.studentEvaluations);
  const programMaps = useSelector((state) => state.programMaps.programMapList);
  const { allRequirements } = useSelector((state) => state.transferEquivalency);
  const { catalogs } = useSelector((state) => state.allCatalogs);
  const studentEvaluations = studentTEvs.map((evaluation) => {
    const programMap = programMaps.find(({ id }) => evaluation.programMapId === id);
    return {
      id: evaluation.id,
      programOptionCode: programMap?.optionCode,
      programOptionName: `${programMap?.programName}, ${programMap?.optionName}`,
      status: evaluation.statusCode,
      programMapId: evaluation.programMapId,
      unitCompleted: `${evaluation.unitsCovered}/${programMap?.units}`,
      unitsCovered: evaluation.unitsCovered,
      totalUnits: programMap?.units,
      mapping: evaluation.mapping,
      catalog: programMap.catalogs[0],
    };
  });
  const [searchKey, setSearchKey] = useState('');
  const [filteredEvaluations, setFilteredEvaluations] = useState(studentEvaluations);

  useEffect(() => {
    dispatch(getStudentTransferEvaluations(orgId, selectedStudent.id));
  }, [selectedStudent]);

  useEffect(() => {
    if (_.isEmpty(programMaps)) {
      dispatch(getProgramMaps(orgId));
    }
  }, []);

  useEffect(() => {
    let filteredList = studentEvaluations;

    if (searchKey != '') {
      const filters = [
        (o) => o.programOptionCode.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.programOptionName.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.name.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = filteredList.filter((o) => filters.some((fn) => fn(o)));
    }
    setFilteredEvaluations(filteredList);
  }, [studentTEvs, searchKey]);

  const navigateToTransferEvaluationView = (programMapDetails) => {
    navigate(
      generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_VIEW, {
        orgId: orgId,
        programMapId: programMapDetails.programMapId,
      }),
      { state: programMapDetails },
    );
  };

  const navigateToTransferEvaluationCreate = () => {
    navigate(generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_CREATE, { orgId: orgId }));
  };

  const getStatusCellType = (type) => {
    switch (type) {
      case EvaluationStatusTypes.IN_PROGRESS:
        return 'PENDING';
      case EvaluationStatusTypes.ARCHIVED:
        return 'INACTIVE';
      default:
        return 'ACTIVE';
    }
  };

  const columns = [
    {
      name: 'programOptionCode',
      label: t('Program Option'),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: theme.spacing(17) } }),
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'programOptionName',
      label: t('Program Option Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'unitCompleted',
      label: t('Units Covered'),
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { minWidth: theme.spacing(14) } }),
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'catalog',
      label: t('Catalog'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: ({ name }) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {name}
            </Typography>
          );
        },
        sortCompare: (order) => {
          return ({ data: { name: catalogName1 } }, { data: { name: catalogName2 } }) => {
            if (order === 'asc') {
              return catalogName1.localeCompare(catalogName2);
            } else {
              return catalogName2.localeCompare(catalogName1);
            }
          };
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: ({ name, type }) => {
          return (
            <StatusCell value={getStatusCellType(type)} name={name} width={TEV_STATUS_WIDTH} />
          );
        },
        sortCompare: (order) => {
          return ({ data: { name: firstStatusName } }, { data: { name: secondStatusName } }) => {
            if (order === 'asc') {
              return firstStatusName.localeCompare(secondStatusName);
            } else {
              return secondStatusName.localeCompare(firstStatusName);
            }
          };
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        sort: false,
        customBodyRender: (id) => {
          const evaluation = filteredEvaluations.find((stEval) => stEval.id == id);
          const { status, programMapId } = evaluation;
          if (
            status.type === EvaluationStatusTypes.USED_IN_PLANNING ||
            status.type === EvaluationStatusTypes.EVALUATION_SENT
          ) {
            const programMap = programMaps.find(({ id }) => programMapId === id);
            return (
              <Box>
                <TEvPrintPdfComponent evaluation={evaluation} programMap={programMap} />
              </Box>
            );
          }
          return null;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onRowClick: (_data, { dataIndex }) => {
      const evaluation = filteredEvaluations[dataIndex];
      const { programMapId, programOptionName, catalog } = evaluation;
      dispatch(getProgramMapRequirements(orgId, programMapId));
      dispatch(getAllRequirements(orgId, [catalog.id]));
      navigateToTransferEvaluationView({
        name: programOptionName,
        programMapId,
        evaluation,
        catalogId: catalog.id,
      });
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <StudentStatusBar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
          }}>
          <Typography sx={{ fontWeight: 700, fontSize: '30px', color: theme.palette.primary.main }}>
            {t('Transfer Evaluations')}
          </Typography>
          <Box
            sx={{
              flex: '0.8 0 0',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <SearchTextBox ml={2} mr={2} searchKey={searchKey} setSearchKey={setSearchKey} />
            <Chip
              label={'New Evaluation'}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.white.main,
                fontSize: '16px',
                height: '40px',
                borderRadius: '20px',
              }}
              onClick={() => navigateToTransferEvaluationCreate()}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredEvaluations} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
