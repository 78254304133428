import _ from 'lodash';

export const getQueryParams = (options) => {
  const data = {
    page: options.page + 1,
    size: options.rowsPerPage,
    sortColumn: options.sort?.name,
    sortDirection: options.sort?.direction,
    query: _.isUndefined(options?.query) ? null : options?.query,
  };

  return _.pickBy(data, _.identity);
};

export const defaultTableOptions = {
  totalItems: 0,
  page: 0,
  rowsPerPage: 10,
  sort: {
    name: 'lActivity.code',
    direction: 'asc',
  },
  query: '',
};
