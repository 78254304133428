import { actionTypes } from '../constants/actionTypes';

const initState = {
  programList: [],
  selectedProgramId: null,
  programOptionsOfSelectedProgram: [],
};

export const programReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROGRAMS:
      return { ...state, programList: payload };
    case actionTypes.SELECT_ORG:
      return { ...state, programList: [] };
    case actionTypes.GET_PROGRAM_OPTIONS:
      return { ...state, selectedProgramId: payload.programId };
    case actionTypes.SET_PROGRAM_OPTIONS:
      return { ...state, programOptionsOfSelectedProgram: payload };
    default:
      return state;
  }
};
