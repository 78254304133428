import _ from 'lodash';
import i18n from 'i18next';

export const PROGRAM_MAP_TYPE = {
  GROUP: 'GROUP',
  PROGRAM_MAP: 'PROGRAM_MAP',
  LEARNING_ACTIVITY: 'LEARNING_ACTIVITY',
};

export const findMaxGroupNumber = (items = [], groupNamePrefix = '') => {
  return items.reduce((prevMax, item) => {
    var re = new RegExp(`^${groupNamePrefix} (\\d+)$`);
    const match = item.name.match(re);
    const val = item.type === PROGRAM_MAP_TYPE.GROUP && match ? match[1] : 0;
    return Math.max(prevMax, val, findMaxGroupNumber(item.items, groupNamePrefix));
  }, 0);
};

export const insertNewNodeIntoMapData = (mapData, parentId, newNode, programId) => {
  const mapDataCopy = _.cloneDeep(mapData);
  updateMapDataWithNewNode(mapDataCopy, parentId, newNode, programId);
  return mapDataCopy;
};

const updateMapDataWithNewNode = (mapData, parentId, newNode, programId = '') => {
  if (parentId === programId || parentId === mapData.id) {
    const { items } = mapData;
    items.push(newNode);
    return true;
  }
  return mapData.items?.some((item) => updateMapDataWithNewNode(item, parentId, newNode));
};

export const replaceNodeInMapData = (mapData, groupId, newNode) => {
  return {
    ...mapData,
    items: mapData.items.map((item) =>
      item.id === groupId ? newNode : replaceNodeInMapData(item, groupId, newNode),
    ),
  };
};

export const buildNewGroupDetails = (
  visibleMapData,
  completeMapData,
  programMapId,
  selectedElementPath = [],
  linkProgramMap = false,
) => {
  let selectedItem = visibleMapData.items;
  let parentGroupId = programMapId;
  if (selectedElementPath.length > 0) {
    selectedElementPath.forEach((element) => {
      parentGroupId = selectedItem[element].id;
      selectedItem = selectedItem[element].items;
    });
  }

  const groupNo = findMaxGroupNumber(completeMapData.items, i18n.t('New Group')) + 1;
  const name = linkProgramMap ? i18n.t('Select Program Map') : `${i18n.t('New Group')} ${groupNo}`;
  return {
    newGroupPath: [...selectedElementPath, ...[selectedItem.length]],
    newNodeData: {
      name,
      type: linkProgramMap ? PROGRAM_MAP_TYPE.PROGRAM_MAP : PROGRAM_MAP_TYPE.GROUP,
      isRequirement: false,
      items: [],
      parentGroupId,
      order: selectedItem.length,
    },
  };
};

export const getSelectedGroupName = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).name;
};

export const getSelectedProgramMapId = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).programMapId;
};

export const getSelectedGroupType = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).type;
};

export const getSelectedNode = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items);
};

export const getSelectedGroupProgramMapId = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).programMapId;
};

export const getSelectedIsRequirement = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).isRequirement;
};

export const getSelectedMessage = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).message;
};

export const getSelectedItemId = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items).id;
};

export const getSelectedItem = (treeData, selectedElementPath) => {
  return getTheSelectedItem(selectedElementPath, treeData.items);
};

export const isSelectedItemNameEditable = (
  treeData,
  selectedElementPath,
  programMapId = null,
  isParentNodeProgramMap = false,
) => {
  if (_.isNil(programMapId)) {
    return (
      getTheSelectedItem(selectedElementPath, treeData.items).type !==
      PROGRAM_MAP_TYPE.LEARNING_ACTIVITY
    );
  } else if (!_.isNil(programMapId) && isParentNodeProgramMap) {
    return true;
  } else {
    return false;
  }
};

export const changeGroupName = (treeData, selectedElementPath, name, programMapId = null) => {
  let mapDataCopy = _.cloneDeep(treeData);
  getTheSelectedItem(selectedElementPath, mapDataCopy.items).name = name;
  return mapDataCopy;
};

export const changeIsRequirement = (treeData, selectedElementPath, isRequirement) => {
  let mapDataCopy = _.cloneDeep(treeData);
  getTheSelectedItem(selectedElementPath, mapDataCopy.items).isRequirement = isRequirement;
  if (!isRequirement) {
    getTheSelectedItem(selectedElementPath, mapDataCopy.items).message = '';
  }
  return mapDataCopy;
};

export const changeMessage = (treeData, selectedElementPath, message) => {
  let mapDataCopy = _.cloneDeep(treeData);
  getTheSelectedItem(selectedElementPath, mapDataCopy.items).message = message;
  return mapDataCopy;
};

export const removeItem = (mapData, nodeId, parentId, mapId) => {
  const parentMatched = mapId === parentId;
  const data = { ...mapData };
  if (!_.isUndefined(mapData.items)) {
    data.items = mapData.items
      .filter((item) => !(item.id === nodeId && parentMatched))
      .map((item) => removeItem(item, nodeId, parentId, item.id));
  }
  return data;
};

export const findItemIndex = (mapData, nodeId, parentId, mapId) => {
  const parentMatched = mapId === parentId;
  let itemIndex = null;
  mapData.items?.some((item, index) => {
    if (item.id === nodeId && parentMatched) {
      itemIndex = index;
      return true;
    } else {
      const indexInNested = findItemIndex(item, nodeId, parentId, item.id);
      if (indexInNested != null) {
        itemIndex = indexInNested;
        return true;
      }
    }
  });
  return itemIndex;
};

export const addLearningActivitiesToSelectedElem = (
  treeData,
  selectedElementPath,
  learningActivities,
) => {
  const mapDataCopy = _.cloneDeep(treeData);
  const selectedItem = getTheSelectedItem(selectedElementPath, mapDataCopy.items);
  const selectedElement = selectedElementPath.length == 0 ? selectedItem : selectedItem.items;
  const itemsToRemove = selectedElement
    .filter(({ type }) => type === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY)
    .filter(({ id }) => learningActivities.findIndex((item) => item.id === id) === -1);
  itemsToRemove.forEach(({ id }) => {
    const index = selectedElement.findIndex((item) => item.id === id);
    selectedElement.splice(index, 1);
  });
  const itemsToInsert = learningActivities.filter(
    ({ id }) => selectedElement.findIndex((item) => item.id === id) === -1,
  );
  itemsToInsert.forEach((theItem) => {
    theItem.newlyAdded = true;
  });
  selectedElement.push(...itemsToInsert);
  return mapDataCopy;
};

const getTheSelectedItem = (selectedElementPath, selectedElement) => {
  let group = selectedElement;
  selectedElementPath.forEach((element, index) => {
    if (selectedElementPath.length === index + 1) {
      group = group[element];
    } else {
      group = group[element].items;
    }
  });

  return group;
};

const getFilteredProgramMapList = (
  mapItems = [],
  elementPath,
  programMapList,
  selectedProgramMapOption,
  selectedProgramMap,
) => {
  let parent = { items: mapItems };
  let programMapIds = [];
  elementPath.forEach((pathIndex) => {
    parent = parent.items[pathIndex];
  });
  const children = parent.items;

  programMapIds = children
    .filter((child) =>
      _.isNull(selectedProgramMap)
        ? child.type === PROGRAM_MAP_TYPE.PROGRAM_MAP
        : child.type === PROGRAM_MAP_TYPE.PROGRAM_MAP && child.id !== selectedProgramMap.id,
    )
    .map(({ programMapId }) => programMapId);

  const filteredProgramMapList = programMapList.filter(
    (res) =>
      !programMapIds.includes(res.id) &&
      res.id !== selectedProgramMapOption.id &&
      res.catalogs[0].id === selectedProgramMapOption.catalogs[0].id,
  );

  return filteredProgramMapList;
};

export const getProgramMapListToSelectDropDown = (
  programMapList = [],
  elementPath,
  currentMap,
  selectedProgramMapOption,
  selectedProgramMap = null,
) => {
  if (!_.isNil(selectedProgramMap)) {
    elementPath.pop();
  }
  const map = getFilteredProgramMapList(
    currentMap.items,
    elementPath,
    programMapList,
    selectedProgramMapOption,
    selectedProgramMap,
  );
  return map;
};
