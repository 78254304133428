import React from 'react';
import { styled, TextField } from '@mui/material';

const CssTextField = styled(TextField)({
  '& label': {
    color: '#8792a1',
    lineHeight: 0.8,
  },
  '& label.Mui-focused': {
    color: '#4dbc88',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#4dbc88',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#4dbc88',
    },
    '&:hover fieldset': {
      borderColor: '#4dbc88',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4dbc88',
    },
    backgroundColor: 'white',
    height: '45px',
    borderRadius: '10px',
  },
});

export default function SearchTextBox(props) {
  const {
    onChange,
    searchKey,
    setSearchKey,
    label = 'Search',
    mr = 12,
    ml = 12,
    hidden = false,
    sx,
    onBlur = () => {},
  } = props;

  const handleChange = (event) => {
    setSearchKey(event.target.value);
  };

  return (
    <CssTextField
      autoComplete="off"
      label={label}
      type="search"
      sx={{
        ...sx,
        flex: 1,
        height: '45px',
        borderRadius: '30px',
        marginRight: mr,
        marginLeft: ml,
        visibility: hidden && 'hidden',
      }}
      onChange={onChange ? onChange : handleChange}
      value={searchKey}
      onBlur={onBlur}
    />
  );
}
