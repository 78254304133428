import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import React from 'react';
import DeclareBreadcrumb from '../../components/DeclareBreadcrumb';

export const OrganizationGroups = (props) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Organization Groups')]} />
        </Box>
      </Box>
    </Container>
  );
};
