import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import ProgramOptionForm from './ProgramOptionForm';
import { getStatus } from '../../../helpers/appConstants';
import { getProgram, getOption } from '../../../redux/actions/programActions';

export default function EditCreateProgramOption() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId, programId, optionId } = useParams();
  const isEditView = optionId !== undefined;
  const optionInitData = {
    status: getStatus()[3].value,
    deliveryMode: '',
    declarable: '',
  };
  const [currentOption, setCurrentOption] = React.useState(optionInitData);

  const [breadCrumbProgramName, setBreadCrumbProgramName] = React.useState('');
  const [breadCrumbOptionName, setBreadCrumbOptionName] = React.useState(
    isEditView ? '' : t('New Option'),
  );
  const programList = useSelector((store) => store.programs.programList);

  const updateBreadCrumbProgramName = ({ name }) => {
    setBreadCrumbProgramName(name);
  };

  useEffect(() => {
    if (programList.length === 0) {
      dispatch(getProgram(orgId, programId, updateBreadCrumbProgramName));
    } else {
      const selectedProgram = programList.find((program) => program.id === programId);
      updateBreadCrumbProgramName(selectedProgram);
    }
  }, [programList]);

  const updateOptionViewValues = (data) => {
    setBreadCrumbOptionName(data.name);
    setCurrentOption(data);
  };

  useEffect(() => {
    if (isEditView) {
      dispatch(getOption(orgId, programId, optionId, updateOptionViewValues));
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '20px' }}>
          <DeclareBreadcrumb items={[t('programs'), breadCrumbProgramName, breadCrumbOptionName]} />
        </Box>
        <ProgramOptionForm currentOption={currentOption} isEditView={isEditView} />
      </Box>
    </Container>
  );
}
