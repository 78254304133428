import { actionTypes } from '../constants/actionTypes';

const initState = {
  advisorsList: [],
};

const advisorReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADVISORS.SET:
      return { ...state, advisorsList: payload };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};

export default advisorReducer;
