import * as React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';

/**
 * Declare banner input
 * @param props Properties
 */

const useStyle = makeStyles((theme) => ({
  banner: {
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.alert.main,
    },
  },
}));

export default function DeclareBannerAlert(props) {
  props = Object.assign(props);
  const { children } = props;
  const classes = useStyle();
  return (
    <Stack sx={{ flex: 1 }}>
      <Card className={classes.banner}>{children}</Card>
    </Stack>
  );
}
