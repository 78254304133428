import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import { appRoutes } from './routes';
import { studentPathwayStatusTypes } from './appConstants';

export const SCREEN_SIZES = {
  SMALL_SCREEN: 1086,
  LARGE_SCREEN: 1365,
};

export const getSidebarVisibility = (pathname) => {
  const sidebarHiddenPathPatterns = [
    appRoutes.catalog.ORGANIZATION_LIST,
    appRoutes.catalog.ORGANIZATION_GROUP,
    appRoutes.catalog.ORGANIZATION_CREATE,
    appRoutes.catalog.ORGANIZATION_EDIT,
  ];

  return !sidebarHiddenPathPatterns.reduce(
    (preVal, pattern) => preVal || matchPath(pattern, pathname) !== null,
    false,
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuPropStyles = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const timeSince = (dateStr = '', currentDate = new Date()) => {
  const date = new Date(dateStr);
  if (Number.isNaN(new Date(date).getDate())) {
    return '';
  }
  const seconds = Math.floor((currentDate - date) / 1000);
  let interval = seconds / 31536000;

  const getTerm = (term, value) => {
    const valueRounded = Math.floor(value);
    return `${valueRounded} ${term}${valueRounded > 1 ? 's' : ''}`;
  };

  if (interval > 1) {
    return getTerm('year', interval);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return getTerm('month', interval);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return getTerm('day', interval);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return getTerm('hour', interval);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return getTerm('minute', interval);
  }
  return getTerm('second', seconds);
};

export const subtractArrays = (arr1, arr2) => {
  const result = _.cloneDeep(arr1);
  arr2.forEach((element) => {
    const index = result.indexOf(element);
    if (index > -1) {
      result.splice(index, 1);
    }
  });
  return result;
};

const PRIME_SEED_NUMBER = 31;
export const generateHashFromString = (str) =>
  str
    .split('')
    .reduce((prev, curr) => (Math.imul(PRIME_SEED_NUMBER, prev) + curr.charCodeAt(0)) | 0, 0);

export const DATA_TABLE_ACTIONS = {
  CHANGE_PAGE: 'changePage',
  CHANGE_ROWS_PER_PAGE: 'changeRowsPerPage',
  SORT: 'sort',
};

export const convertToQueryString = (options) =>
  Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export const joinStringsGrammatically = (array, staticString) => {
  if (_.size(array) === 1) {
    return array.slice(-1).toString();
  } else {
    return array.slice(0, -1).join(', ') + ' ' + staticString + ' ' + array.slice(-1);
  }
};

export const convertDateIntoMonthDate = (date) => {
  const dateObj = new Date(date);
  const options = { month: 'short', day: '2-digit' };
  return dateObj.toLocaleString('en-US', options);
};

export const convertTimestampIntoDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toISOString().slice(0, 10);
  return formattedDate;
};

export function getColorForPathwayStatus(theme, value) {
  let color = theme.palette.new.main;
  switch (value) {
    case studentPathwayStatusTypes.NEW:
      color = theme.palette.new.main;
      break;
    case studentPathwayStatusTypes.IN_PLANNING:
      color = theme.palette.inPlanning.main;
      break;
    case studentPathwayStatusTypes.PATHWAY_DROPPED:
      color = theme.palette.pathwayDropped.main;
      break;
    case studentPathwayStatusTypes.PATHWAY_COMPLETED:
      color = theme.palette.complete.main;
      break;
    case studentPathwayStatusTypes.PATHWAY_SENT:
      color = theme.palette.sent.main;
      break;
    case studentPathwayStatusTypes.PATHWAY_INVALID:
      color = theme.palette.invalidPathway.main;
      break;
    default:
      color = theme.palette.new.main;
      break;
  }

  return color;
}

export const convertObjectsListToArray = (objectsList) => {
  const convertedArray = [];
  for (const key in objectsList) {
    convertedArray.push(objectsList[key]);
  }
  return convertedArray;
};

export const createUrlWithQueryParams = (url, parameter) => {
  const convertedQueryString = convertToQueryString(parameter);
  const returnURL = url + `?${convertedQueryString}`;
  return returnURL;
};

export const saveFilterParamsToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFilterParamsFromLocalStorage = (key) => {
  const data = window.localStorage.getItem(key);
  if (!_.isNull(data)) {
    return JSON.parse(data);
  }
};
