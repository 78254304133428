import {
  setTerms,
  pathwaySetNewTerm,
  pathwaySetTermsOrder,
  setAcademicYearsWithTermInstances,
  updateAcademicYearsWithTermInstances,
} from '../../actions/termActions';
import { getTerms } from '../../actions/learningActivityActions';
import {
  requestGetTerms,
  requesetPostTerm,
  requesetPutTerm,
  requestPutTermsOrder,
  requestPutTermInstances,
  requestPutCurrentTerm,
  requestPostPathwayNewTerm,
  requestPostGetTermsOrder,
  requestPutCalendarYearStartTerm,
  requestGetTermInstances,
} from '../requests/terms';
import { requestHandler, requestTypes } from '../helper';

export function* handleGetColleges(action) {
  const {
    payload: { orgId },
  } = action;
  yield requestHandler(requestGetTerms.bind(this, orgId), setTerms, requestTypes.GET);
}

export function* handlePostTerms(action) {
  const { payload, navigate } = action;
  const orgId = payload.org;
  yield requestHandler(
    requesetPostTerm.bind(this, payload),
    getTerms.bind(this, orgId),
    requestTypes.POST,
    navigate,
  );
}

export function* handlePutTerms(action) {
  const { payload, navigate } = action;
  const orgId = payload.org;
  yield requestHandler(
    requesetPutTerm.bind(this, payload),
    getTerms.bind(this, orgId),
    requestTypes.PUT,
    navigate,
  );
}

export function* handlePutTermsOrder(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutTermsOrder.bind(this, payload),
    setTerms,
    requestTypes.PUT,
    navigate,
  );
}

export function* handlePutTermInstances(action) {
  const { payload, navigate } = action;
  const { year } = payload;
  yield requestHandler(
    requestPutTermInstances.bind(this, payload),
    (data) => updateAcademicYearsWithTermInstances(data, year),
    requestTypes.PUT,
    navigate,
  );
}

export function* handleGetAcademicYearsWithTermInstances(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetTermInstances.bind(this, payload),
    setAcademicYearsWithTermInstances,
    requestTypes.GET,
  );
}

export function* handlePutCurrentTerm(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutCurrentTerm.bind(this, payload),
    setTerms,
    requestTypes.PUT,
    navigate,
  );
}

export function* handlePutCalendarYearStartTerm(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutCalendarYearStartTerm.bind(this, payload),
    setTerms,
    requestTypes.PUT,
    navigate,
  );
}

export function* handlePostPathwayNewTerm(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostPathwayNewTerm.bind(this, payload),
    pathwaySetNewTerm,
    requestTypes.POST,
    callback,
  );
}

export function* handleGetTermsOrder(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostGetTermsOrder.bind(this, payload),
    pathwaySetTermsOrder,
    requestTypes.POST,
    callback,
  );
}
