import { setAdvisors } from '../../actions/advisorActions';
import { requestGetAdvisors, requestPutAdvisorForStudent } from '../requests/advisors';
import { requestHandler, requestTypes } from '../helper';

export function* handleGetAdvisors(action) {
  const { payload } = action;
  yield requestHandler(requestGetAdvisors.bind(this, payload), setAdvisors, requestTypes.GET);
}

export function* handleUpdateAdvisorForStudent(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPutAdvisorForStudent.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
  );
}
