import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import TabView from '../../../components/TabView';
import { settingsCategories } from '../../../helpers/settingsHelper';
import { getSettings } from '../../../redux/actions/settingActions';
import PathwaySettings from './PathwaySettings';
import ModulesAndFeaturesSettings from './ModulesAndFeaturesSettings';

export default function List() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { organizationSettings } = useSelector((state) => state.settings);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    dispatch(getSettings(orgId));
  }, []);

  const navigateToSelectedTab = (index) => {
    setActiveTabIndex(index);
  };

  const tabs = [
    {
      name: t(settingsCategories.PATHWAYS),
      component: (
        <PathwaySettings
          pathwaySettings={organizationSettings.find(
            ({ category }) => category === settingsCategories.PATHWAYS,
          )}
        />
      ),
      callBack: () => navigateToSelectedTab(0),
    },
    {
      name: t(settingsCategories.MODULES_AND_FEATURES),
      component: (
        <ModulesAndFeaturesSettings
          moduleAndFeaturesSettings={organizationSettings.find(
            ({ category }) => category === settingsCategories.MODULES_AND_FEATURES,
          )}
        />
      ),
      callBack: () => navigateToSelectedTab(1),
    },
  ];
  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          <DeclareBreadcrumb items={[t('Settings')]} />
        </Box>
        <TabView tabs={tabs} selectedTab={activeTabIndex} />
      </Box>
    </Container>
  );
}
