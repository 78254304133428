import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { TransferEquivalencyType } from '../helpers/appConstants';
import _ from 'lodash';

export default function DeclareLearningActivityCard(props) {
  const {
    index,
    code,
    name,
    units,
    codeComponent,
    nameComponent,
    unitComponent,
    customStyle,
    type = TransferEquivalencyType.COURSE,
  } = props;

  const styleJson = {
    rowItems: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'auto',
      '&.MuiTypography-root': {
        fontWeight: customStyle?.rowItems?.fontWeight ?? null,
        fontSize: customStyle?.rowItems?.fontSize ?? 'small',
        color: customStyle?.rowItems?.color ?? theme.palette.black.main,
      },
    },
    rowItemsUnits: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'right',
      width: 'auto',
      paddingRight: theme.spacing(1),
      '&.MuiTypography-root': {
        fontSize: customStyle?.rowItemsUnits?.fontSize ?? 'small ',
        color: customStyle?.rowItemsUnits?.color ?? theme.palette.black.main,
      },
    },
    laWrapper: {
      padding: theme.spacing(1.5),
      background: customStyle?.laWrapper?.background ?? 'none',

      '&:nth-of-type(1)': {
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        borderEndStartRadius: '0px',
        borderEndEndRadius: '0px',
      },
      '&:last-of-type': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        borderEndStartRadius: theme.spacing(1),
        borderEndEndRadius: theme.spacing(1),
      },
      '&:only-child': {
        borderRadius: theme.spacing(1),
      },
    },
  };

  const laStyles = makeStyles(() => styleJson);
  const classes = laStyles();
  const { t } = useTranslation();
  return (
    <Stack
      key={`laItem-${index}`}
      direction="row"
      justifyContent="space-between"
      justifyItems="stretch"
      alignItems="flex-start"
      className={classes.laWrapper}>
      <>
        {!_.isNull(code) ? (
          <Box key={`laItem-stack-${index}`} sx={{ display: 'flex', flex: 1 }}>
            {codeComponent ? (
              codeComponent()
            ) : (
              <Typography key={index} className={classes.rowItems}>
                {code}
              </Typography>
            )}
          </Box>
        ) : null}

        <Box key={`laItem-box-${index}`} sx={{ display: 'flex', flex: 2 }}>
          {nameComponent ? (
            nameComponent()
          ) : (
            <Typography
              key={`laItem-tp1-${index}`}
              sx={{
                marginLeft: name === t('Requirment not found') || name === t('Not found') ? 0 : 1,
              }}
              className={classes.rowItems}>
              {name}
            </Typography>
          )}
        </Box>
        <Box key={`laItem-tp3-${index}`} sx={{ flex: 1 }}>
          {unitComponent ? (
            unitComponent()
          ) : (
            <Typography className={classes.rowItemsUnits}>{`${units} ${t('units')}`}</Typography>
          )}
        </Box>
      </>
    </Stack>
  );
}
