import { makeStyles } from '@mui/styles';

export const studentPlanningStyles = makeStyles((theme) => ({
  warningBannerBox: {
    display: 'flex',
    padding: '14px 16px',
    justifyContent: 'space-between',
  },
  warningIconAndMessageBox: {
    display: 'flex',
    justifyContent: 'start',
    flex: 8,
    gap: 2,
  },
  warningIconAndMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  warningActionButton: {
    display: 'flex',
    alignItems: 'center',
    flex: 4,
    justifyContent: 'end',
  },
  warningPathwayBtn: {
    width: '12em',
    '&.MuiButton-root': {
      height: '45px',
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  pathwayOverlay: {
    width: '100%',
    height: `calc(100% - ${theme.spacing(5)})`,
    backgroundColor: 'black',
    opacity: '0.15',
    position: 'absolute',
    top: 0,
    left: 0,
    margin: `${theme.spacing(2)} 0 ${theme.spacing(5)} 0`,
    cursor: 'not-allowed',
    zIndex: 1,
  },

  rebuildBannerFontStyles: {
    '&.MuiTypography-root': {
      color: theme.palette.error.secondary,
      fontFamily: theme.rebuildBanner.fontFamily,
    },
  },
}));
