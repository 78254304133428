import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Home,
  CompareArrows,
  Crop,
  MenuBook,
  People,
  AccessTime,
  BarChart,
} from '@mui/icons-material';
import { urlParamsToFilterStudentList } from '../../helpers/appConstants';
import { createUrlWithQueryParams } from '../../helpers/common';
import { getAdvisorValueForDropDown } from '../../helpers/studentsHelper';

export default function Sidebar(props) {
  const {
    generatePath,
    NavLink,
    appRoutes,
    orgId,
    sideBarNavigation,
    displayActiveClass,
    classes,
    Typography,
    t,
    hidden = false,
  } = props;
  const { currentSelectedStudent } = useSelector((state) => state.students);

  const localStorageDataForStudent = getAdvisorValueForDropDown();

  return (
    <>
      <NavLink
        to={generatePath(appRoutes.advisor.DASHBOARD, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.advisor.DASHBOARD, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <Home className={classes.icon} />
        <Typography className={classes.text}>{t('dashboard')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(
          createUrlWithQueryParams(appRoutes.advisor.STUDENT_LIST, {
            students: urlParamsToFilterStudentList.ALL_STUDENTS,
          }),
          {
            orgId: orgId,
          },
        )}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(
            generatePath(
              createUrlWithQueryParams(appRoutes.advisor.STUDENT_LIST, {
                students: localStorageDataForStudent,
              }),
              {
                orgId: orgId,
              },
            ),
          );
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <People className={classes.icon} />
        <Typography className={classes.text}>{t('Students')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.advisor.GLOBAL_CLASS_SCHEDULE_LIST, {
          orgId: orgId,
        })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(
            generatePath(appRoutes.advisor.GLOBAL_CLASS_SCHEDULE_LIST, {
              orgId: orgId,
            }),
          );
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <AccessTime className={classes.icon} />
        <Typography className={classes.text}>{t('Class Schedules')}</Typography>
      </NavLink>
      {!hidden && !_.isEmpty(currentSelectedStudent) && (
        <>
          <NavLink
            to={generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, {
              orgId: orgId,
            })}
            onClick={(e) => {
              e.preventDefault();
              sideBarNavigation(
                generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, {
                  orgId: orgId,
                }),
              );
            }}
            className={({ isActive }) => displayActiveClass(isActive)}>
            <CompareArrows className={classes.icon} />
            <Typography className={classes.text}>{t('Transfer Equivalencies')}</Typography>
          </NavLink>

          <NavLink
            to={generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, {
              orgId: orgId,
            })}
            onClick={(e) => {
              e.preventDefault();
              sideBarNavigation(
                generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, {
                  orgId: orgId,
                }),
              );
            }}
            className={({ isActive }) => displayActiveClass(isActive)}>
            <Crop className={classes.icon} />
            <Typography className={classes.text}>{t('Transfer Evaluations')}</Typography>
          </NavLink>
        </>
      )}
      <NavLink
        to={generatePath(appRoutes.advisor.REPORTS_COURSE_PROJECTION, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(
            generatePath(appRoutes.advisor.REPORTS_COURSE_PROJECTION, { orgId: orgId }),
          );
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <BarChart className={classes.icon} />
        <Typography className={classes.text}>{t('Reports')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.DASHBOARD, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.DASHBOARD, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <MenuBook className={classes.icon} />
        <Typography className={classes.text}>{t('Catalog Manager')}</Typography>
      </NavLink>
    </>
  );
}
