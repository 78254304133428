import { actionTypes } from '../constants/actionTypes';

export const getAllTerms = (payload) => {
  return {
    type: actionTypes.GET_TERMS,
    payload,
  };
};

export const setTerms = (payload) => {
  return {
    type: actionTypes.SET_TERMS,
    payload,
  };
};

export const postTerms = (org, data, navigate) => {
  return {
    type: actionTypes.CREATE_TERMS,
    payload: { org, data },
    navigate: navigate,
  };
};

export const putTerms = (org, termId, data, navigate) => {
  return {
    type: actionTypes.UPDATE_TERMS,
    payload: { org, termId, data },
    navigate: navigate,
  };
};

export const updateTermsOrder = (org, data, navigate) => {
  return {
    type: actionTypes.UPDATE_TERMS_ORDER,
    payload: { org, data },
    navigate: navigate,
  };
};

export const updateTermInstances = (org, year, data, navigate) => {
  return {
    type: actionTypes.UPDATE_TERMS_INSTANCES,
    payload: { org, year, data },
    navigate: navigate,
  };
};

export const getAcademicYearsWithTermInstances = (org) => {
  return {
    type: actionTypes.GET_ACADEMIC_YEARS_WITH_TERM_INSTANCES,
    payload: { org },
  };
};

export const setAcademicYearsWithTermInstances = (payload) => {
  return {
    type: actionTypes.SET_ACADEMIC_YEARS_WITH_TERM_INSTANCES,
    payload,
  };
};

export const updateAcademicYearsWithTermInstances = (data, year) => {
  return {
    type: actionTypes.UPDATE_ACADEMIC_YEARS_WITH_TERM_INSTANCES,
    payload: { data, year },
  };
};

export const updateCurrentTerm = (org, data, navigate) => {
  return {
    type: actionTypes.UPDATE_CURRENT_TERM,
    payload: { org, data },
    navigate: navigate,
  };
};

export const updateCalendarYearStart = (org, data, navigate) => {
  return {
    type: actionTypes.UPDATE_CALENDAR_YEAR_START_TERM,
    payload: { org, data },
    navigate: navigate,
  };
};

export const pathwayAddNewTerm = (orgId, data, callback) => {
  return {
    type: actionTypes.PATHWAY.ADD_TERM,
    payload: { orgId, data },
    callback,
  };
};

export const getTermsOrder = (orgId, data, callback) => {
  return {
    type: actionTypes.PATHWAY.GET_TERMS_ORDER,
    payload: { orgId, data },
    callback,
  };
};

export const pathwaySetNewTerm = (data) => {
  return {
    type: actionTypes.PATHWAY.SET_TERM,
    payload: data,
  };
};

export const pathwaySetTermsOrder = (data) => {
  return {
    type: actionTypes.PATHWAY.SET_TERMS_ORDER,
    payload: data,
  };
};
