import React, { useState, useRef, useCallback } from 'react';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import TabComponent from '../../../components/advisor/StudentView/TabComponent';
import { StudentViewTabMenu } from '../../../helpers/appConstants';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import ByTermView from './ByTermView';
import ExploreView from './ExploreView';
import { resetPathway } from '../../../redux/actions/pathwayActions';

export default function View() {
  const [activeTab, setActiveTab] = useState(StudentViewTabMenu[1].value);
  const [selectedExcludeCourses, setSelectedExcludeCourse] = useState(null);

  const dispatch = useDispatch();
  const studentStatusBarRef = useRef();
  const byTermViewRef = useRef();

  const setSelectedLearningActivity = (la) => {
    setSelectedExcludeCourse(la);
  };

  const handlePdfPrint = () => {
    byTermViewRef.current.openPrintModal();
  };

  const handleResetPathway = (orgId, studentId, pathwayId) => {
    dispatch(
      resetPathway(
        orgId,
        studentId,
        pathwayId,
        byTermViewRef.current.fetchStudentPathwayAfterResetting,
      ),
    );
  };
  const TabSwitch = () => {
    let component;
    switch (activeTab) {
      case StudentViewTabMenu[0].value:
        break;
      case StudentViewTabMenu[1].value:
        component = (
          <ByTermView
            setActiveTab={setActiveTab}
            setSelectedLearningActivity={setSelectedLearningActivity}
            studentStatusBarRef={studentStatusBarRef}
            ref={byTermViewRef}
          />
        );
        break;
      case StudentViewTabMenu[2].value:
        break;
      case StudentViewTabMenu[3].value:
        component = <ExploreView selectedExcludeCourses={selectedExcludeCourses} />;
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: '100px',
      }}>
      <StudentStatusBar ref={studentStatusBarRef} />
      <TabComponent
        handleResetPathway={handleResetPathway}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        handlePdfPrint={handlePdfPrint}
      />
      <TabSwitch />
    </Container>
  );
}
