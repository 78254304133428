import { actionTypes } from '../constants/actionTypes';

const initState = {
  transferEvaluation: {},
  studentEvaluations: [],
  statusList: [],
  completedCoursesRequirements: [],
};

const transferEvaluationReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.TRANSFER_EVALUATION.SET:
      return { ...state, transferEvaluation: payload };
    case actionTypes.TRANSFER_EVALUATION.GET_STUDENT_EVALUATIONS:
      return { ...state, studentEvaluations: [] };
    case actionTypes.TRANSFER_EVALUATION.SET_STUDENT_EVALUATIONS:
      return { ...state, studentEvaluations: payload };
    case actionTypes.SET_PROGRAM_MAP_REQUIREMENTS:
      const currentEvaluation = state.studentEvaluations.map((eq) => {
        if (eq.programMapId === payload.parent) {
          eq['requirementList'] = payload;
        }
        return eq;
      });

      return { ...state, studentEvaluations: currentEvaluation };
    case actionTypes.TRANSFER_EVALUATION.SET_STATUS_LIST:
      return { ...state, statusList: payload };
    case actionTypes.TRANSFER_EVALUATION.SET_COMPLETED_COURSE_REQ:
      return { ...state, completedCoursesRequirements: payload };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};

export default transferEvaluationReducer;
