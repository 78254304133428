import OrgError from '../../../error/OrgError';
import {
  LearningActivityService,
  ClassScheduleService,
} from '../../../services/LearningActivityService';

const learningActivityService = new LearningActivityService();

export function* requestGetLearningActivities(param) {
  if (param) {
    return yield learningActivityService.getAll(param);
  }
  throw OrgError.orgRequired();
}

export function* requestGetLearningActivitiesForCatalog(param) {
  if (param) {
    return yield learningActivityService.getAllForCatalog(param);
  }
  throw OrgError.orgRequired();
}

export function requestGetLearningActivity(payload) {
  const { org, data } = payload;
  return learningActivityService.getActivity(org, data);
}

export function requestGetLearningActivityTerms(payload) {
  if (payload) {
    return learningActivityService.getTerms(payload);
  }
  throw OrgError.orgRequired();
}

export function requestPostLearningActivity(payload) {
  const { org, data } = payload;
  return learningActivityService.save(org, data);
}

export function requestPatchLearningActivity(payload) {
  const { org, id, data } = payload;
  return learningActivityService.partiallyUpdate(org, id, data);
}

export function requestPutLearningActivity(payload) {
  const { org, id, data } = payload;
  return learningActivityService.update(org, id, data);
}

export function* requestGetClassSchedules(param, lActivityId) {
  if (!param) {
    throw OrgError.orgRequired();
  }
  return yield learningActivityService.getAllClassSchedules(param, lActivityId);
}

export function* requestPostClassSchedule(payload) {
  const { org, data, laId } = payload;
  if (org) {
    return yield learningActivityService.saveClassSchedule(org, laId, data);
  }
  throw OrgError.orgRequired();
}

export function requestPutClassSchedule(payload) {
  const { org, data, laId, csId } = payload;
  return learningActivityService.updateClassSchedule(org, laId, csId, data);
}
