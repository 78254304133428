import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.white.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    boxShadow: 24,
  },
  actionBtn: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
      height: '45px',
      borderRadius: '20px',
      textTransform: 'capitalize',
      fontWeight: 300,
      width: '200px',
      cursor: 'pointer',
      color: theme.palette.common.white,
    },
  },
}));
function PrintOutModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { openPrintModal, setOpenPrintModal, printPdf, studentStatusBarRef } = props;

  return (
    <Modal open={openPrintModal} onClose={() => setOpenPrintModal(false)}>
      <Box className={classes.modalBox}>
        <Stack spacing={3} sx={{ alignItems: 'center', mt: 6, mb: 6 }}>
          <Typography id="modal-modal-title" variant="h6" fontWeight={700} component="h2">
            {t('Do you want to mark this pathway as confirmed & Sent?')}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              className={classes.actionBtn}
              onClick={() => {
                studentStatusBarRef.current.setPathwayStatusToSent();
                printPdf();
              }}>
              {t('Print & Mark As Sent')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="grey"
              className={classes.actionBtn}
              onClick={() => {
                printPdf();
              }}>
              {t('Print Only')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default PrintOutModal;
