import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setSuccess } from '../redux/actions/notificationActions';

export default function DeclareSnackBar() {
  const { t } = useTranslation();
  const { error, success } = useSelector((state) => state.notifications);
  const [msg, setMsg] = useState(null);
  const [type, setType] = useState('error');
  const [snackVisiblle, setSnackVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setSuccess({}));
    if (!error?.persistErrors) {
      dispatch(setError({}));
    }
    setSnackVisible(false);
  };

  useEffect(() => {
    setMsg(error?.message || success?.message);
    setType(error?.message ? 'error' : 'success');
    if (
      (error?.persistErrors && error?.message != null) ||
      (!error?.persistErrors && success?.message != null) ||
      (!error?.persistErrors && error?.message != null)
    ) {
      setSnackVisible(true);
    }
  }, [error, success]);

  return (
    <Snackbar
      open={snackVisiblle}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}>
      <Alert
        onClose={(_event, reason) => {
          if (error?.persistErrors && reason == undefined) {
            setSnackVisible(false);
          } else {
            handleClose();
          }
        }}
        severity={type}>
        {t(msg)}
      </Alert>
    </Snackbar>
  );
}
