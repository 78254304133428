import { Box, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import OrganizationForm from './OrganizationForm';

export default function CreateOrganization(props) {
  const { t } = useTranslation();
  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '20px' }}>
          <DeclareBreadcrumb items={[t('organizations'), t('Create Organization')]} />
        </Box>
        <OrganizationForm />
      </Box>
    </Container>
  );
}
