import { createDataRows, createExcelFile, getTodaysDate, initExcel } from './generateExcel';

export async function generateExcelForOrgClassSchedules(organization, data) {
  const columns = [
    {
      header: 'WES_ID',
      key: 'wes_id',
    },
    {
      header: 'Course Code',
      key: 'courseId',
    },
    {
      header: 'Catalog',
      key: 'catalog',
    },
    {
      header: 'Term',
      key: 'term',
    },
    {
      header: 'Delivery Mode',
      key: 'deliveryMode',
    },
    {
      header: 'Duration',
      key: 'duration',
    },
  ];

  const columnData = data
    .map(({ catalog, lActivity, deliveryMode, term }) => [
      null,
      lActivity.code,
      catalog.name,
      term.displayName,
      deliveryMode,
      `1 Consecutive Term`,
    ])
    .sort();

  const title = 'Class Schedules';
  const today = getTodaysDate();
  const filename = `${organization.initials}-${title.replace(' ', '')}-${today}.xlsx`;

  const [workbook, sheet] = initExcel(title, columns);
  createDataRows(sheet, columnData);
  createExcelFile(workbook, filename);
}
