import OrgError from '../../../error/OrgError';
import { CatService } from '../../../services/CatService';

const catService = new CatService();

export function* requestGetCatalogs(param) {
  if (param) {
    return yield catService.getAll(param);
  }
  throw OrgError.orgRequired();
}

export function requesetPostCatalog(payload) {
  const { org, data } = payload;
  return catService.save(org, data);
}

export function requesetPutCatalog(payload) {
  const { org, catalog, data } = payload;
  return catService.update(org, catalog, data);
}

export function requestPostDuplicateCatalog(payload) {
  const { org, data, sourceCatalog } = payload;
  return catService.duplicate(org, sourceCatalog, data);
}

export function requestPutCatalogsOrder(payload) {
  const { org, data } = payload;
  return catService.order(org, data);
}
