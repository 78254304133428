import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, MenuItem, Checkbox, ListItemText } from '@mui/material';
import DeclaredSelect from '../../DeclareSelect';
import { KeyboardBackspace } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { termCardStyles } from '../../styles/termCardStyles';
import { getTermsToSelect, createRenderValueMessage } from '../../../helpers/pathwayHelper';

const useStyle = makeStyles((theme) => ({
  pathwayBtn: {
    width: '24em',
    '&.MuiButton-root': {
      height: '45px',
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  cardDataContainer: {
    marginTop: theme.spacing(2),
  },
  navigateBack: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    '& $cardItems': {
      marginBottom: '10px',
    },
  },
  cardItems: {
    marginTop: theme.spacing(2),
    '& .MuiFormControl-root': {
      width: theme.spacing(20),
    },
  },
  studentAttendingTerms: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      width: theme.spacing(45),
    },
  },
}));

export default function TermCardCustomizedTerms({
  setBuildPathwayParams,
  handleShowCustomizedTerms,
  clickBuildPathway,
  buildPathwayParams,
  completedTerms,
}) {
  const { t } = useTranslation();
  const allTerms = useSelector((state) => state.terms.termList);
  const formError = useSelector((state) => state.notifications.formError);
  const [termListDropDown, setTermListDropDown] = useState([]);
  const classes = useStyle();
  const termCardClasses = termCardStyles();
  const [renderValueMessage, setRenderValueMessage] = useState(null);

  useEffect(() => {
    const termsList = getTermsToSelect(allTerms);
    const currentTerm = termsList.findIndex((data) => data.currentTerm);

    const selectedTerms = buildPathwayParams.selectedTerms;

    const termsToDisbaled = completedTerms.map((aTerm) => ({ ...aTerm.term }));

    const termListWithSelections = termsList.slice(currentTerm).map((term) => {
      const isSelected = selectedTerms.some(
        (data) => data.id === term.id && data.year === term.year,
      );
      const isSelectedAndDisabled = termsToDisbaled.some(
        (data) => data.id === term.id && data.year === term.year,
      );

      return {
        ...term,
        isSelected,
        isSelectedAndDisabled,
      };
    });

    updateRenderMessage(termListWithSelections);
    setTermListDropDown(termListWithSelections);
  }, []);

  const updateRenderMessage = (termList) => {
    const selectedTerms = termList.filter((data) => data.isSelected);
    const selectedTermsToShowRenderValue = termList.filter(
      (data) => data.isSelected || data.isSelectedAndDisabled,
    );
    setBuildPathwayParams({ ...buildPathwayParams, selectedTerms });
    const messageRenderValue = createRenderValueMessage(
      allTerms,
      selectedTermsToShowRenderValue,
      t,
    );
    setRenderValueMessage(messageRenderValue);
  };

  const handleChangeCheckBox = (item) => {
    const termIndex = termListDropDown.findIndex(
      (aTerm) => aTerm.id === item.id && aTerm.year === item.year,
    );

    const selectedTerm = termListDropDown[termIndex];
    termListDropDown[termIndex] = {
      ...selectedTerm,
      isSelected: !selectedTerm.isSelected,
    };

    updateRenderMessage(termListDropDown);
    setTermListDropDown(termListDropDown);
  };

  return (
    <Box className={termCardClasses.termCard}>
      <Box className={classes.cardDataContainer}>
        <Box className={classes.navigateBack}>
          <KeyboardBackspace
            onClick={() => handleShowCustomizedTerms(false, buildPathwayParams)}
            color="primary"
            sx={{ cursor: 'pointer' }}
            fontSize="medium"
          />
          <Typography>{t('Back')}</Typography>
        </Box>
        <Box className={classes.cardContainer}>
          <Typography className={classes.cardItems} variant="body1" fontSize={21} fontWeight={700}>
            {t('What terms will the student attend?')}
          </Typography>
          <Box className={classes.studentAttendingTerms}>
            <DeclaredSelect
              multiple
              margin="dense"
              value={termListDropDown.filter(
                (data) => data.isSelected || data.isSelectedAndDisabled,
              )}
              name="selectedTerms"
              error={formError['selectedTerms']}
              helperText={formError['selectedTerms']?.message}
              renderValue={(selected) =>
                selected
                  .filter((trm) => trm.currentTerm && trm.year === new Date().getFullYear())
                  .map((term) => term.name + ' ' + term.year)
                  .join(', ') +
                ' ' +
                renderValueMessage
              }>
              {termListDropDown.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Checkbox
                    onClick={() => handleChangeCheckBox(item)}
                    checked={item.isSelected || item.isSelectedAndDisabled}
                    disabled={item.isSelectedAndDisabled}
                  />
                  <ListItemText primary={item.name + ' ' + item.year} />
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Box>
          <Box className={classes.cardItems}>
            <Button
              className={classes.pathwayBtn}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                clickBuildPathway(buildPathwayParams, true);
              }}>
              <Typography sx={{ color: 'common.white' }}>{t('Build Pathway')}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
