import { setCompletedCourseReq } from '../../actions/transferEvaluationActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestGetCompletedCourseReq,
  requestPatchCompletedCourseReq,
  requestPostCompletedCourseReq,
  requestGetTransferEvaluationForPrint,
} from '../requests/transferEvaluation';

export function* handlePostCompletedCourseReq(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostCompletedCourseReq.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handlePatchCompletedCourseReq(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPatchCompletedCourseReq.bind(this, payload),
    null,
    requestTypes.PATCH,
    callback,
  );
}

export function* handleGetCompletedCourseReq(action) {
  const { payload, callback } = action;

  yield requestHandler(
    requestGetCompletedCourseReq.bind(this, payload),
    setCompletedCourseReq,
    requestTypes.GET,
    callback,
  );
}

export function* handleGetTransferEvaluationForPrint(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetTransferEvaluationForPrint.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
  );
}
