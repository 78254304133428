import { actionTypes } from '../constants/actionTypes';

export const setCatalogs = (catalogs) => {
  return {
    type: actionTypes.SET_CATALOGS,
    payload: catalogs,
  };
};

export const setLatestCatalog = (catalog) => {
  return {
    type: actionTypes.SET_LATEST_CATALOGS,
    payload: catalog,
  };
};

export const setLatestSourceCatalog = (catalog) => {
  return {
    type: actionTypes.SET_LATEST_SOURCE_CATALOG,
    payload: catalog,
  };
};

export const getCatalogs = (params, self = true) => {
  return {
    type: actionTypes.GET_CATALOGS,
    payload: params,
    self,
  };
};

export const postCatalog = (org, data, navigate) => {
  return {
    type: actionTypes.POST_CATALOG,
    payload: { org, data },
    navigate: navigate,
  };
};

export const putCatalog = (org, catalog, data, navigate) => {
  return {
    type: actionTypes.PUT_CATALOG,
    payload: { org, catalog, data },
    navigate: navigate,
  };
};

export const duplicateCatalog = (org, sourceCatalog, data, navigate) => ({
  type: actionTypes.DUPLICATE_CATALOG,
  payload: { org, data, sourceCatalog },
  navigate,
});

export const updateCatalogsOrder = (org, data, navigate) => {
  return {
    type: actionTypes.UPDATE_CATALOGS_ORDER,
    payload: { org, data },
    navigate: navigate,
  };
};
