import { actionTypes } from '../constants/actionTypes';

export const setOrgs = (orgs) => {
  return {
    type: actionTypes.SET_ORGS,
    payload: orgs,
  };
};

export const setOrgsWithSelectedOrg = (orgs, selectedOrg) => {
  return {
    type: actionTypes.SET_ORGS_WITH_SELECTED_ORG,
    payload: { orgs, selectedOrg },
  };
};

export const selectOrg = (org) => {
  return {
    type: actionTypes.SELECT_ORG,
    payload: org,
  };
};

export const getOrgs = () => {
  return {
    type: actionTypes.GET_ORGS,
  };
};

export const getOrgsWithSelectedOrg = (selectedOrgId, navigate) => {
  return {
    type: actionTypes.GET_ORGS_WITH_SELECTED_ORG,
    payload: selectedOrgId,
    navigate,
  };
};

export const postOrg = (org, navigate, createDefaultResources = false) => {
  return {
    type: actionTypes.POST_ORG,
    payload: org,
    navigate: navigate,
    createDefaultResources,
  };
};

export const putOrg = (org, navigate) => {
  return {
    type: actionTypes.PUT_ORG,
    payload: org,
    navigate: navigate,
  };
};
