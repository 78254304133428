import { actionTypes } from '../constants/actionTypes';

const initState = {
  orgs: [],
  selectedOrg: {},
};

export const orgReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ORGS:
      return { ...state, orgs: payload };
    case actionTypes.SET_ORGS_WITH_SELECTED_ORG:
      const { orgs, selectedOrg } = payload;
      return { ...state, orgs, selectedOrg };
    case actionTypes.SELECT_ORG:
      return { ...state, selectedOrg: payload };
    default:
      return state;
  }
};
