import { HttpService } from './HttpService';

export default class StudentService extends HttpService {
  async getAll(orgId, query) {
    return this.get(`organizations/${orgId}/students?${query}`);
  }

  async searchStudents(orgId, searchKey) {
    return this.get(`organizations/${orgId}/students?q=${searchKey}`);
  }

  async createTransferEvaluation(org, studentId, body) {
    return this.post(`organizations/${org}/students/${studentId}/transferEvaluations`, body);
  }

  async putTransferEvaluation(org, studentId, evaluationId, body) {
    return this.put(
      `organizations/${org}/students/${studentId}/transferEvaluations/${evaluationId}`,
      body,
    );
  }

  async getAllStudentStatus(id) {
    return this.get(`organizations/${id}/students/statuses`);
  }

  async getStudentTransferEvaluations(orgId, studentId) {
    return this.get(`organizations/${orgId}/students/${studentId}/transferEvaluations`);
  }

  async getGetTransferEvaluationStatusList(orgId, studentId) {
    return this.get(`organizations/${orgId}/students/${studentId}/transferEvaluations/statuses`);
  }

  async getStudentTeqAssignments(orgId, student, fetchAllCatalogs = false) {
    let catalogParam = '';
    if (!fetchAllCatalogs) {
      catalogParam = `?catalogId=${student.currentCatalog?.id}`;
    }
    return this.get(
      `organizations/${orgId}/students/${student.id}/transferEqAssignments${catalogParam}`,
    );
  }

  async deleteTeqAssignments(org, studentId, transferEquivalencyId) {
    return this.delete(
      `/organizations/${org}/students/${studentId}/transferEqAssignments/${transferEquivalencyId}`,
    );
  }

  async generateStudentPathway(orgId, studentId, body) {
    return this.post(`organizations/${orgId}/students/${studentId}/pathways/generate`, body);
  }

  async updateStudent(orgId, studentId, body) {
    return this.patch(`organizations/${orgId}/students/${studentId}`, body);
  }

  async updateStudentPathway(orgId, studentId, programOptionId, body) {
    return this.put(
      `organizations/${orgId}/students/${studentId}/pathways/${programOptionId}`,
      body,
    );
  }

  async updateStudentByStudentTeqStatus(orgId, studentId, transferEquivalencyId, body) {
    return this.put(
      `organizations/${orgId}/students/${studentId}/transferEqAssignments/${transferEquivalencyId}/studentStatus`,
      body,
    );
  }

  async deleteStudentByStudentTeqStatus(orgId, studentId, transferEquivalencyId, body) {
    return this.delete(
      `organizations/${orgId}/students/${studentId}/transferEqAssignments/${transferEquivalencyId}/studentStatus`,
      body,
    );
  }
}
