import { actionTypes } from '../constants/actionTypes';
import _ from 'lodash';

const initState = {
  allRequirements: [],
  current: [],
  assignedTeq: {},
  teqStatuses: [],
  teqStatusesEnum: {},
  selectedLACodes: [],
  selectedSourceOrg: null,
  transferEqAssignments: [],
};

export const transferEquivalencyReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.TRANSFER_EQUIVALENCY.SET_REQUIREMENTS:
      const { items } = payload;
      return { ...state, allRequirements: items };
    case actionTypes.TRANSFER_EQUIVALENCY.SET_LIST:
      const filteredData = state.current.filter((data) => {
        if (data.sourceLearningActivityId) {
          return !payload.find(
            (la) => la.sourceLearningActivityId === data.sourceLearningActivityId,
          );
        } else if (data.code) {
          return !payload.find((la) => la.code === data.code);
        }
        return true;
      });
      return {
        ...state,
        current: [...filteredData, ...payload].sort(
          (a, b) => a.transferringCourseId - b.transferringCourseId,
        ),
      };
    case actionTypes.TRANSFER_EQUIVALENCY.DELETE_LIST:
      const data = state.current.filter(
        ({ sourceLearningActivityId }) => sourceLearningActivityId !== payload.laId,
      );
      return { ...state, current: data };
    case actionTypes.TRANSFER_EQUIVALENCY.SET_STATUSES:
      return {
        ...state,
        teqStatuses: payload,
        teqStatusesEnum: payload.reduce((acc, curr) => {
          acc[curr.type] = curr.type;
          return acc;
        }, {}),
      };
    case actionTypes.SELECT_ORG:
    case actionTypes.STUDENTS.SET_SELECTED_STUDENT:
      return initState;
    case actionTypes.TRANSFER_EQUIVALENCY.RESET:
      return { ...state, current: [] };
    case actionTypes.TRANSFER_EQUIVALENCY.SET_ASSIGNMENT:
      return { ...state, assignedTeq: payload };
    case actionTypes.TRANSFER_EQUIVALENCY.SET_LA_CODES:
      return { ...state, selectedLACodes: payload };
    case actionTypes.TRANSFER_EQUIVALENCY.SET_SELECTED_SOURCE_ORG:
      return { ...state, selectedSourceOrg: payload };
    case actionTypes.TRANSFER_EQUIVALENCY.RESET_LA_CODES:
      return { ...state, selectedLACodes: [] };
    case actionTypes.TRANSFER_EQUIVALENCY.RESET_SELECTED_SOURCE_ORG:
      return { ...state, selectedSourceOrg: null };
    default:
      return state;
  }
};
