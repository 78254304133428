import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { getPrograms } from '../../../redux/actions/programActions';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import { getStatus } from '../../../helpers/appConstants';
import ProgramMapForm from './ProgramMapForm';
import { getProgramMaps } from '../../../redux/actions/programMapActions';

export default function EditCreateProgramMap() {
  const { t } = useTranslation();
  const { orgId, programId } = useParams();
  const dispatch = useDispatch();
  const isEditView = programId !== undefined;
  const [currentProgramMap, setCurrentProgramMap] = useState({
    programOption: '',
    units: '',
    catalogs: [],
    status: getStatus()[3].value,
  });

  const [breadCrumbName, setBreadCrumbName] = useState(isEditView ? '' : t('New Program Map'));

  const programList = useSelector((store) => store.programs.programList);
  const catalogList = useSelector((store) => store.allCatalogs.catalogs);
  const programMapList = useSelector((store) => store.programMaps.programMapList);

  useEffect(() => {
    if (programList.length === 0) {
      dispatch(getPrograms(orgId));
    }

    if (catalogList.length === 0) {
      dispatch(getCatalogs(orgId));
    }

    if (isEditView) {
      if (programMapList.length === 0) {
        dispatch(getProgramMaps(orgId));
      } else {
        const selectedProgramMap = programMapList.find((programMap) => programMap.id === programId);
        setBreadCrumbName(selectedProgramMap.optionName);
        setCurrentProgramMap({
          programOption: selectedProgramMap.optionId,
          units: selectedProgramMap.units,
          maxUnits: selectedProgramMap.maxUnits,
          catalogs: selectedProgramMap.catalogs,
          status: selectedProgramMap.status,
        });
      }
    }
  }, [programMapList]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '20px' }}>
          <DeclareBreadcrumb items={[{ name: t('Program Map') }, breadCrumbName]} />
        </Box>
        {<ProgramMapForm currentProgramMap={currentProgramMap} isEditView={isEditView} />}
      </Box>
    </Container>
  );
}
