import SettingService from '../../../services/SettingService';

const settingsService = new SettingService();

export function* requestGetSettings(param) {
  return yield settingsService.getSettings(param);
}

export function* requestPostSettings(payload) {
  const { org, data } = payload;
  return yield settingsService.createSettings(org, data);
}
