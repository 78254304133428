import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import DeclareTextField from '../DeclareTextField';
import { useTranslation } from 'react-i18next';

export default function CompleteInFirstXQuestionRule(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [question, setQuestion] = useState(null);
  const [positiveAnswer, setPositiveAnswer] = useState(null);
  const [negativeAnswer, setNegativeAnswer] = useState(null);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = isRequirement) => {
    const data = {
      isRequirement: isReq,
      question: question,
      positiveAnswer: positiveAnswer,
      negativeAnswer: negativeAnswer,
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  useEffect(() => {
    setPositiveAnswer(props.data.positiveAnswer || '');
    setNegativeAnswer(props.data.negativeAnswer || '');
    setQuestion(props.data.question || '');
    setIsRequirement(props.data.isRequirement || false);
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DeclareTextField
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          onBlur={() => {
            processChange();
          }}
          inputlabel={`${t('Question')}*`}
          variant="outlined"
          placeholder={t('Ask a question of advisors or students?')}
          value={question}
          name="question"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={3}>
        <DeclareTextField
          onChange={(e) => setPositiveAnswer(e.target.value)}
          onBlur={() => processChange()}
          inputlabel={`${t('Positive Answer')}*`}
          variant="outlined"
          placeholder={t('Yes')}
          value={positiveAnswer}
          name="positive_answer"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={3}>
        <DeclareTextField
          onChange={(e) => setNegativeAnswer(e.target.value)}
          onBlur={() => processChange()}
          inputlabel={`${t('Negative Answer')}*`}
          variant="outlined"
          placeholder={t('No')}
          value={negativeAnswer}
          name="negative_answer"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirement}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirement(e.target.checked);
                processChange(e.target.checked);
              }}
            />
          }
          label={t('Requirement')}
        />
      </Grid>
    </Grid>
  );
}
