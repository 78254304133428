import { Box, Button, IconButton, Modal, Slide, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchTextBox from './SearchTextBox';
import { FilterAlt, CancelOutlined } from '@mui/icons-material';
import DeclareDataTable from './DeclareDataTable';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { WAIT_UNTIL_SEARCH_MILLISECONDS } from '../helpers/appConstants';
import _ from 'lodash';

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    '& .EllipsisText': {
      display: 'inline-block',
      width: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      float: 'left',
    },
  },
  paper: {
    overflow: 'auto',
    position: 'absolute',
    background: 'white',
    left: 20,
    right: 20,
    top: 20,
    bottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `20px 20px 0px 20px`,
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionBtnContainer: { float: 'right', marginBottom: theme.spacing(1) },
  actionBtn: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
      height: '45px',
      borderRadius: '20px',
      textTransform: 'capitalize',
      fontWeight: 300,
      width: '150px',
      color: theme.palette.common.white,
    },
  },
  removeBtn: { padding: theme.spacing(1) },
  modal: { alignItems: 'center', justifyContent: 'center' },
}));

export default function DeclareFreeElectivesFlyout({
  freeElectiveData,
  setFreeElectiveData,
  confirmBtnLabel,
  replaceFreeElectiveToPathway,
  learningActivities,
  retrieveProgramMappingData,
}) {
  const { flyOutOpen, groupId, termInstanceId } = freeElectiveData;
  const classes = useStyle();
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const [itemCount, setItemCount] = useState(0);
  const [tableProperties, setTableProperties] = useState({
    sort: { direction: 'asc', name: 'name' },
    rowsPerPage: 10,
    page: 0,
    search: searchKey,
  });
  const [laRowsSelected, setLaRowsSelected] = useState([]);
  const [filteredLAList, setFilteredLAList] = useState([]);
  const [title, setTitle] = useState('');
  const [selectedLAsFilterEnabled, setSelectedLAsFilterEnabled] = useState(false);
  const [itemLearningActivities, setItemLearningActivities] = useState([]);
  const [selectedLearningActivities, setSelectedLearningActivities] = useState([]);

  useEffect(() => {
    if (!_.isNil(groupId)) {
      const { sort, rowsPerPage, page, search } = tableProperties;
      retrieveProgramMappingData(
        {
          groupId,
          termInstanceId,
          page: page,
          size: rowsPerPage,
          order: sort.direction,
          sortBy: sort.name,
          search,
        },
        handleProgramMapData,
      );
    }
  }, [groupId, tableProperties, termInstanceId]);

  const debouncedSearch = React.useRef(
    _.debounce((search) => {
      setTableProperties({ ...tableProperties, search });
    }, WAIT_UNTIL_SEARCH_MILLISECONDS),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    debouncedSearch(searchKey);
  }, [searchKey]);

  useEffect(() => {
    let laList = itemLearningActivities;
    if (selectedLAsFilterEnabled) {
      laList = laList.filter(
        ({ id }) => selectedLearningActivities.findIndex((la) => la.id === id) != -1,
      );
    }
    setFilteredLAList(laList);
  }, [selectedLAsFilterEnabled, selectedLearningActivities, itemLearningActivities]);

  useEffect(() => {
    const indexes = selectedLearningActivities.map(({ id }) =>
      filteredLAList.findIndex((la) => la.id === id),
    );
    setLaRowsSelected(indexes);
  }, [filteredLAList, selectedLearningActivities]);

  const handleProgramMapData = (data) => {
    const { parentName, items, totalItems } = data;
    setTitle(parentName);
    setItemLearningActivities(items);
    setItemCount(totalItems);
  };

  const handleModalClose = () => {
    setSelectedLearningActivities([]);
    setFreeElectiveData({ ...freeElectiveData, flyOutOpen: false });
  };

  const handleSelectedLAsLink = () => {
    setSelectedLAsFilterEnabled(true);
  };

  const handleConfirmAction = (selectedLA) => {
    const laToInclude = selectedLA[0];
    const selectedCourse = learningActivities.find(({ id }) => id === laToInclude.id);
    const { id, name, units, code } = selectedCourse;
    const data = {
      ...freeElectiveData,
      selectedCourse: { id, name, units, code },
    };

    setFreeElectiveData(data);
    replaceFreeElectiveToPathway(data);
  };

  const columns = [
    {
      name: 'name',
      label: t('Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'code',
      label: t('Code'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'units',
      label: t('Units'),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    tableBodyMaxHeight: '580px',
    download: false,
    serverSide: true,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'none',
    rowsSelected: laRowsSelected,
    sortOrder: tableProperties.sort,
    count: itemCount,
    page: tableProperties.page,
    rowsPerPage: tableProperties.rowsPerPage,
    onRowSelectionChange: (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
      const selectedLA = rowsSelected.map((index) => {
        return {
          id: filteredLAList[index].id,
        };
      });
      setLaRowsSelected(rowsSelected);
      setSelectedLearningActivities(selectedLA);
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        setTableProperties({
          ...tableProperties,
          rowsPerPage: tableState.rowsPerPage,
          page: tableState.page,
        });
      }

      if (action === 'sort') {
        setTableProperties({
          ...tableProperties,
          sort: tableState.sortOrder,
        });
      }
    },
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="declare-flyout"
      aria-describedby="declare-flyout-description"
      open={flyOutOpen}
      onClose={handleModalClose}>
      <Slide direction="left" in={flyOutOpen} mountOnEnter unmountOnExit>
        <div className={classes.paper}>
          <Box display="flex">
            <Typography variant="h4" color="primary">
              {title}
            </Typography>
            <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
            <FilterAlt fontSize="large" color="secondary" />
          </Box>
          <Box display="flex">
            <Typography
              variant="h6"
              color="grey"
              onClick={selectedLearningActivities.length != 0 && handleSelectedLAsLink}
              className={selectedLearningActivities.length != 0 ? classes.clickable : ''}>
              {`${selectedLearningActivities.length} ${t('Selected')}`}
            </Typography>
            {selectedLAsFilterEnabled && (
              <IconButton
                className={classes.removeBtn}
                aria-label="remove"
                onClick={() => {
                  setSelectedLAsFilterEnabled(false);
                }}>
                <CancelOutlined />
              </IconButton>
            )}
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <DeclareDataTable data={filteredLAList} columns={columns} options={options} />
          </Box>
          <Box className={classes.actionBtnContainer}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              className={classes.actionBtn}
              onClick={() => {
                handleConfirmAction(selectedLearningActivities);
                handleModalClose();
              }}>
              {confirmBtnLabel}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="grey"
              className={classes.actionBtn}
              onClick={() => {
                handleModalClose();
              }}>
              {t('cancel')}
            </Button>
          </Box>
        </div>
      </Slide>
    </Modal>
  );
}
