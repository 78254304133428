import { actionTypes } from '../constants/actionTypes';

export const getTermPlanningStatus = (orgId) => {
  return {
    type: actionTypes.PATHWAY.GET_TERM_PLANNING_STATUSES,
    payload: { orgId },
  };
};

export const getPathwayStatus = (orgId) => ({
  type: actionTypes.PATHWAY.GET_PATHWAY_STATUSES,
  payload: { orgId },
});

export const setPathwayStatus = (payload) => ({
  type: actionTypes.PATHWAY.SET_PATHWAY_STATUSES,
  payload,
});

export const setTermPlanningStatus = (payload) => {
  return {
    type: actionTypes.PATHWAY.SET_TERM_PLANNING_STATUSES,
    payload,
  };
};

export const resetTermPlanningStatus = () => {
  return {
    type: actionTypes.PATHWAY.RESET_TERM_PLANNING_STATUSES,
  };
};

export const savePathway = (orgId, studentId, body, callback) => {
  return {
    type: actionTypes.PATHWAY.CREATE,
    payload: { orgId, studentId, body },
    callback,
  };
};

export const savePathwayCourseUsage = (orgId, studentId, pathwayId, body, callback) => {
  return {
    type: actionTypes.PATHWAY.CREATE_COURSE_USAGE,
    payload: { orgId, studentId, pathwayId, body },
    callback,
  };
};

export const getPathway = (orgId, studentId, programOptionId, callback = null) => {
  return {
    type: actionTypes.PATHWAY.GET,
    payload: { orgId, studentId, programOptionId },
    callback,
  };
};

export const getPathwayCourseUsage = (orgId, studentId, pathwayId, callback) => {
  return {
    type: actionTypes.PATHWAY.GET_COURSE_USAGE,
    payload: { orgId, studentId, pathwayId },
    callback,
  };
};

export const getPathwaysToExplore = (orgId, data, callback) => {
  return {
    type: actionTypes.PATHWAY.GET_PATHWAYS_TO_EXPLORE,
    payload: { orgId, data },
    callback,
  };
};

export const updatePathway = (orgId, studentId, body, callback = null) => {
  return {
    type: actionTypes.PATHWAY.UPDATE_PATHWAY_ASSIGNMENT,
    payload: { orgId, studentId, body },
    callback,
  };
};

export const validatePathway = (
  orgId,
  pathway,
  completedCourses,
  transferredRequirements,
  programMapId,
  callback,
  errorCallback,
  successCallback,
) => {
  return {
    type: actionTypes.PATHWAY.VALIDATE_PATHWAY,
    payload: {
      orgId,
      pathway,
      completedCourses,
      transferredRequirements,
      programMapId,
      errorCallback,
      successCallback,
    },
    callback,
  };
};

export const resetPathway = (orgId, studentId, pathwayId, callback) => {
  return {
    type: actionTypes.PATHWAY.RESET,
    payload: { orgId, studentId, pathwayId },
    callback,
  };
};

export const markCourseAsIncomplete = (
  orgId,
  pathway,
  completedCourses,
  transferredRequirements,
  programMapId,
  courseId,
  callback,
  errorCallback,
) => {
  return {
    type: actionTypes.PATHWAY.MARK_COURSE_AS_INCOMPLETE,
    payload: {
      orgId,
      pathway,
      completedCourses,
      transferredRequirements,
      programMapId,
      courseId,
      errorCallback,
    },
    callback,
  };
};
