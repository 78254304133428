import DepartmentService from '../../../services/DepartmentService';
import OrgError from '../../../error/OrgError';

const departmentService = new DepartmentService();

export function* requestGetColleges(param) {
  if (!param) {
    throw OrgError.orgRequired();
  }
  return yield departmentService.getAll(param);
}

export function requestPostCollegeDepartment(payload) {
  const { org, data } = payload;
  if (org) {
    return departmentService.save(org, data);
  }
  throw OrgError.orgRequired();
}

export function requesetPutCollegeDepartment(payload) {
  const { org, college, data } = payload;
  return departmentService.update(org, college, data);
}
