import { actionTypes } from '../constants/actionTypes';

export const postTransferEvaluation = (org, studentId, data, navigate) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.CREATE,
    payload: { org, studentId, data },
    navigate: navigate,
  };
};

export const editTransferEvaluation = (org, studentId, evaluationId, data, navigate) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.EDIT,
    payload: { org, studentId, evaluationId, data },
    navigate: navigate,
  };
};

export const setTransferEvaluation = (data) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.SET,
    payload: data,
  };
};

export const getStudentTransferEvaluations = (orgId, studentId, callback) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.GET_STUDENT_EVALUATIONS,
    payload: { orgId, studentId },
    callback,
  };
};

export const setStudentTransferEvaluations = (data) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.SET_STUDENT_EVALUATIONS,
    payload: data,
  };
};

export const getTransferEvaluationStatusList = (orgId, studentId) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.GET_STATUS_LIST,
    payload: { orgId, studentId },
  };
};

export const setTransferEvaluationStatusList = (statusList) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.SET_STATUS_LIST,
    payload: statusList,
  };
};

export const postCompletedCourseReq = (orgId, studentId, body, callback) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.CREATE_COMPLETED_COURSE_REQ,
    payload: { orgId, studentId, body },
    callback,
  };
};

export const patchCompletedCourseReq = (orgId, studentId, reqId, body, callback) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.UPDATE_COMPLETED_COURSE_REQ,
    payload: { orgId, studentId, reqId, body },
    callback,
  };
};

export const getCompletedCourseReq = (orgId, studentId, callback = null) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.GET_COMPLETED_COURSE_REQ,
    payload: { orgId, studentId },
    callback,
  };
};

export const setCompletedCourseReq = (payload) => {
  return {
    type: actionTypes.TRANSFER_EVALUATION.SET_COMPLETED_COURSE_REQ,
    payload,
  };
};

export const getTransferEvaluationForPrint = (
  orgId,
  studentId,
  evaluationId,
  callback = () => {},
) => ({
  type: actionTypes.TRANSFER_EVALUATION.GET_EVALUATION_FOR_PRINT,
  payload: { orgId, studentId, evaluationId },
  callback,
});
