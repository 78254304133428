import { requestHandler, requestTypes } from '../helper';
import { requestPostRule, requestUpdateRule, requestGetAssociatedRule } from '../requests/rules';

export function* handlePostRule(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostRule.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
    null,
    true,
    !payload.silent,
    payload.persistErrors,
  );
}

export function* handleUpdateRule(action) {
  const { payload } = action;
  yield requestHandler(
    requestUpdateRule.bind(this, payload),
    null,
    requestTypes.POST,
    null,
    null,
    true,
    !payload.silent,
    payload.persistErrors,
  );
}

export function* handleGetAssociatedRule(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetAssociatedRule.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
  );
}
