import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button, Typography } from '@mui/material';

function TransferEvaluationActionBtn({ btnText, onClick }) {
  return (
    <Button
      disableElevation
      variant="contained"
      color="secondary"
      sx={{
        borderRadius: '20px',
        textTransform: 'capitalize',
      }}
      onClick={onClick}>
      {btnText}
    </Button>
  );
}
/**
 * 
 * btnArray props
  [
    {
      text: string,
      onClickFn: Function
    }
  ]
 */
export default function TransferEvaluationToolTip({
  titleText,
  Icon,
  iconProps,
  iconStyle,
  showButton = true,
  btnContainerStyle = {},
  btnArray = [],
  customContent = null,
}) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'common.white',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
            padding: '10px',
          },
        },
      }}
      title={
        <Box>
          <Typography color="common.black" fontWeight={800}>
            {titleText}
          </Typography>
          <Box
            sx={{
              display: showButton ? 'flex' : 'none',
              marginTop: '6px',
              ...btnContainerStyle,
            }}>
            {btnArray.map(({ text, onClickFn }, index) => (
              <TransferEvaluationActionBtn key={index} btnText={text} onClick={onClickFn} />
            ))}
          </Box>
          {customContent ? customContent : null}
        </Box>
      }
      placement="bottom">
      <Icon sx={{ ...iconStyle }} fontSize="medium" {...iconProps} />
    </Tooltip>
  );
}
