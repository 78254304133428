import { HttpService } from './HttpService';

export default class TermsService extends HttpService {
  async getAll(id) {
    return this.get(`organizations/${id}/semesters`);
  }

  async save(org, body) {
    return await this.post(`organizations/${org}/semesters`, body);
  }

  async savePathwayNewTerm(org, body) {
    return await this.post(`organizations/${org}/semesters/pathway/add`, body);
  }

  async getTermsOrder(org, body) {
    return await this.post(`organizations/${org}/semesters/pathway/`, body);
  }

  async update(org, termId, body) {
    return await this.put(`organizations/${org}/semesters/${termId}`, body);
  }

  async updateTermsOrder(org, body) {
    return await this.put(`organizations/${org}/semesters/order`, body);
  }

  updateTermInstances(org, body) {
    return this.put(`organizations/${org}/academicYears/termInstances`, body);
  }

  getTermInstances(org) {
    return this.get(`organizations/${org}/academicYears/termInstances`);
  }

  async updateCurrentTerm(org, body) {
    return await this.put(`organizations/${org}/semesters/current`, body);
  }

  async updateCalendarYearStartTerm(org, body) {
    return this.put(`organizations/${org}/semesters/calendarYearStart`, body);
  }
}
