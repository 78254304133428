export const settingsCategories = {
  PATHWAYS: 'PATHWAYS',
  FEATURES: 'FEATURES',
  MODULES_AND_FEATURES: 'MODULES_AND_FEATURES',
};

export const settings = {
  [settingsCategories.PATHWAYS]: {
    MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID: 'MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID',
  },
  [settingsCategories.MODULES_AND_FEATURES]: {
    TRANSFER_EVALUATION_MODULE: 'TRANSFER_EVALUATION_MODULE',
  },
};

export const getFinancialAidSetting = (processedSettings) =>
  processedSettings[settingsCategories.PATHWAYS]?.settings
    .MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID;

export const getTransferModuleSettings = (processedSettings) =>
  processedSettings[settingsCategories.MODULES_AND_FEATURES]?.settings.TRANSFER_EVALUATION_MODULE;
