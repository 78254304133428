import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import DeclareAutocomplete from '../DeclareAutocomplete';
import DeclareTextField from '../DeclareTextField';
import { getLearningActivitiesForCatalog } from '../../redux/actions/learningActivityActions';

export default function RelationshipRule(props) {
  const { selectedItemEditable = true } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId, programId, lActivityId } = useParams();
  const { learningActivities, byCatalog } = useSelector((state) => state.allLearningActivities);
  const { programMapList } = useSelector((state) => state.programMaps);
  const [learningActivity, setLearningActivity] = useState('');
  const [lActivities, setLActivities] = useState([]);
  const [message, setMessage] = useState('');
  const [isRequirement, setIsRequirement] = useState(false);
  const [learningValue, setLearningValue] = useState('');
  const [isRequirementValue, setIsRequirementValue] = useState(false);
  const { list: learningActivitiesList } = byCatalog;

  useEffect(() => {
    const catalog = getCatalog();
    if (catalog && _.isEmpty(learningActivitiesList)) {
      dispatch(getLearningActivitiesForCatalog(orgId, catalog));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(learningActivitiesList)) {
      setLActivities(learningActivitiesList);
    }
  }, [learningActivitiesList]);

  useEffect(() => {
    setMessage(props.data.message || '');
    setIsRequirement(props.data.isRequirement);
    let index = lActivities.findIndex((leaningActivity) => {
      return leaningActivity.id == props.data.learningActivity;
    });
    setLearningActivity(lActivities[index]);
    setLearningValue(props.data.learningActivity);
  }, [props]);

  const getCatalog = () => {
    let catId = null;

    if (_.isUndefined(programId) && !_.isUndefined(lActivityId)) {
      const la = learningActivities.find(({ id }) => id === lActivityId);
      catId = la.catalog[0].id;
    } else {
      const programMap = programMapList.find(({ id }) => id === programId);
      catId = programMap.catalogs[0].id;
    }

    return catId;
  };

  getCatalog();

  const setLearningValueFn = (lActivity) => {
    setLearningValue(lActivity.id);
    let data = {};
    data.learningActivity = lActivity.id;
    data.message = message;
    data.isRequirement = isRequirementValue;
    let selectedElementPath = props.selectedElementPath;

    props.addRuleDataHandler({ data, selectedElementPath });
    let index = lActivities.findIndex((leaningActivity) => {
      return leaningActivity.id == lActivity.id;
    });
    setLearningActivity(lActivities[index]);
  };

  const setMsgValueFn = (msg) => {
    let data = {};
    data.learningActivity = learningValue;
    data.message = msg;
    data.isRequirement = isRequirementValue;
    let selectedElementPath = props.selectedElementPath;
    props.addRuleDataHandler({ data, selectedElementPath });
    setMessage(msg);
  };

  const setIsRequirementValueFn = (isReq) => {
    setIsRequirementValue(isReq);
    let data = {};
    data.learningActivity = learningValue;
    data.message = message;
    data.isRequirement = isReq;
    let selectedElementPath = props.selectedElementPath;
    props.addRuleDataHandler({ data, selectedElementPath });
    setIsRequirement(isReq);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DeclareAutocomplete
          inputlabel={`${t('Learning Activity')} *`}
          disableClearable
          getOptionLabel={(option) => option.code + ' ' + option.name || ''}
          value={learningActivity}
          onChange={(_event, value) => {
            setLearningValueFn(value);
          }}
          options={lActivities}
          disabled={!selectedItemEditable}
          renderInput={(params) => (
            <TextField {...params} value={params.id} placeholder={t('Learning Activity')} />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <DeclareTextField
          onChange={(e) => {
            setMsgValueFn(e.target.value);
          }}
          inputlabel={t('Message')}
          variant="outlined"
          placeholder={t('A warning for advisors & students')}
          value={message}
          disabled={!selectedItemEditable}
          name="message"
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirement}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirementValueFn(e.target.checked);
              }}
            />
          }
          label={t('Requirement')}
        />
      </Grid>
    </Grid>
  );
}
