import { HttpService } from './HttpService';

export default class AdvisorService extends HttpService {
  async getAll(id) {
    return this.get(`organizations/${id}/advisors`);
  }

  async updateAdvisorForStudent(id, body) {
    return this.put(`organizations/${id}/advisors`, body);
  }
}
