import { actionTypes } from '../constants/actionTypes';

export const getOrgClassSchedules = (orgId, query, callback) => {
  return {
    type: actionTypes.ORG_CLASS_SCHEDULES.GET,
    payload: { orgId, query },
    callback,
  };
};

export const cancelSingleClassSchedule = (params, callback) => {
  return {
    type: actionTypes.ORG_CLASS_SCHEDULES.CANCEL_SINGLE,
    payload: params,
    callback,
  };
};

export const getOrgPlannedStudents = (orgId, termId, year, courseId, callback) => {
  return {
    type: actionTypes.ORG_CLASS_SCHEDULES.GET_PLANNED_STUDENTS,
    payload: { orgId, termId, year, courseId },
    callback,
  };
};
