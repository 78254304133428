import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StatusCell from '../../StatusCell';
import ProgressBar from '../ProgressBar';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../../helpers/routes';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, Save } from '@mui/icons-material';
import DeclaredSelect from '../../DeclareSelect';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { getPrograms } from '../../../redux/actions/programActions';
import { updateStudent, updateStudentPathway } from '../../../redux/actions/studentActions';
import { getPathwayStatus } from '../../../redux/actions/pathwayActions';
import { studentPathwayStatusTypes } from '../../../helpers/appConstants';
import { updateCurrentStudent } from '../../../helpers/studentsHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '40%',
  },

  statusRoot: {
    '&:hover $edit': {
      display: 'block',
    },
  },

  edit: {
    '&.MuiSvgIcon-root': {
      display: 'none',
    },
  },

  pathwaySelect: {
    '&.MuiInputBase-root': {
      height: '30px',
      fontWeight: 'bold',
      border: 1,
    },
    '&:hover $.MuiSelect-select': {
      border: 0,
      borderColor: theme.palette.primary.main,
    },
  },

  studentName: {
    '&.MuiTypography-root': {
      marginTop: theme.spacing(-1),
    },
  },
}));

const StudentStatusBar = forwardRef((_props, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const { currentSelectedStudent, list: studentList } = useSelector((state) => state.students);
  const { pathwayStatus } = useSelector((state) => state.pathway);
  const { programList } = useSelector((state) => state.programs);
  const { latestCatalog } = useSelector((state) => state.allCatalogs);
  const { programMapList } = useSelector((state) => state.programMaps);

  const [declarableProgramOptions, setDeclarableProgramOptions] = useState([]);
  const [selectedProgramOption, setSelectedProgramOption] = useState({ name: '', id: '' });
  const [editPathwayStatus, setEditPathwayStatus] = useState(false);
  const [outlineValue, setOutlineValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [reorderedPathwayStatus, setReorderedPathwayStatus] = useState([]);

  useEffect(() => {
    if (_.isEmpty(currentSelectedStudent)) {
      navigate(generatePath(appRoutes.advisor.DASHBOARD, { orgId: orgId }));
    }

    if (_.isEmpty(programList)) {
      dispatch(getPrograms(orgId));
      dispatch(getProgramMaps(orgId));
    }

    if (_.isEmpty(pathwayStatus)) {
      dispatch(getPathwayStatus(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentSelectedStudent)) {
      const { name, id } = currentSelectedStudent.programOption;
      setSelectedProgramOption({ ...selectedProgramOption, name, id });
    }
  }, [currentSelectedStudent]);

  useEffect(() => {
    if (!_.isEmpty(pathwayStatus)) {
      const status = reorderPathwayStatusList();
      setReorderedPathwayStatus(status);
    }
  }, [pathwayStatus]);

  useImperativeHandle(ref, () => ({
    setPathwayStatusToSent() {
      const type = studentPathwayStatusTypes.PATHWAY_SENT;
      handlePathwayStatusSelection(type);
    },
  }));

  const reorderPathwayStatusList = () => {
    const status = pathwayStatus.filter(
      ({ type }) =>
        type !== studentPathwayStatusTypes.NEW &&
        type !== studentPathwayStatusTypes.PATHWAY_INVALID &&
        type !== studentPathwayStatusTypes.ARCHIVED,
    );
    const pathwaySentIndex = status.findIndex(
      ({ type }) => type === studentPathwayStatusTypes.PATHWAY_SENT,
    );
    const pathwayDroppedIndex = status.findIndex(
      ({ type }) => type === studentPathwayStatusTypes.PATHWAY_DROPPED,
    );

    [status[pathwaySentIndex], status[pathwayDroppedIndex]] = [
      status[pathwayDroppedIndex],
      status[pathwaySentIndex],
    ];

    return status;
  };

  const handleProgramOptionSelection = (name, id) => {
    setSelectedProgramOption({
      ...selectedProgramOption,
      name,
      id,
    });
  };

  const saveNewProgramOption = () => {
    const newStatus = pathwayStatus.find(
      ({ type }) => type === studentPathwayStatusTypes.ARCHIVED,
    ).code;

    const studentPathwayStatusCode = currentSelectedStudent.plannedStatus.code;

    const previousStatus = _.isUndefined(studentPathwayStatusCode)
      ? pathwayStatus.find(({ type }) => type === studentPathwayStatusTypes.NEW).code
      : studentPathwayStatusCode;

    const previousProgramOption = currentSelectedStudent.programOption.id;

    const data = {
      programOption: selectedProgramOption.id,
      additionInfo: {
        previousProgramOption,
        previousStatus,
        newStatus,
      },
    };

    setEditMode(false);
    dispatch(updateStudent(orgId, currentSelectedStudent.id, data));
  };

  const handleEditMode = () => {
    if (!_.isEmpty(programList) && !_.isEmpty(programMapList)) {
      const filteredProgramMaps = programMapList.filter(
        ({ catalogs }) => catalogs[0].id === latestCatalog.id,
      );

      const data = programList
        .flatMap(({ programOptions }) => programOptions)
        .filter(
          ({ declarable, id }) =>
            declarable &&
            id !== currentSelectedStudent.programOption.id &&
            filteredProgramMaps.find(({ optionId }) => id === optionId),
        );

      setDeclarableProgramOptions(data);
      setEditMode(true);
    }
  };

  const handlePathwayStatusSelection = (type) => {
    const studentId = currentSelectedStudent.id;
    const { code: previousStatus } = pathwayStatus.find(
      (data) => data.type === currentSelectedStudent.plannedStatus.type,
    );
    const status = reorderedPathwayStatus.find((data) => data.type === type);
    const programOption = currentSelectedStudent.programOption.id;
    setEditPathwayStatus(false);
    // TODO: update studentList improvement
    updateCurrentStudent(currentSelectedStudent, studentList, status, dispatch);

    dispatch(
      updateStudentPathway(orgId, studentId, programOption, {
        statusCode: status.code,
        previousStatus,
      }),
    );
  };

  const ProgramOptionListComponent = () => {
    return (
      <Stack direction="row" spacing={1} sx={{ marginBottom: 1 }}>
        <DeclaredSelect
          margin="dense"
          value={selectedProgramOption.name}
          sx={{ height: '30px', fontWeight: 'bold', width: '100%' }}
          color="white">
          <MenuItem value={currentSelectedStudent.programOption.name}>
            {currentSelectedStudent.programOption.name}
          </MenuItem>
          {declarableProgramOptions.map(({ name, id }, index) => (
            <MenuItem
              value={name}
              key={index}
              onClick={() => handleProgramOptionSelection(name, id)}>
              {name}
            </MenuItem>
          ))}
        </DeclaredSelect>
        <Save
          color="secondary"
          sx={{ cursor: 'pointer' }}
          fontSize="medium"
          onClick={() => saveNewProgramOption()}
        />
      </Stack>
    );
  };

  const ProgramOptionNameComponent = () => {
    return (
      <Stack direction="row" spacing={1} sx={{ marginBottom: 1 }}>
        <Typography display="inline" variant="body1" fontWeight={600}>
          {selectedProgramOption.name}
        </Typography>
        <Edit
          color="secondary"
          sx={{ cursor: 'pointer' }}
          fontSize="medium"
          onClick={() => handleEditMode()}
        />
      </Stack>
    );
  };

  const isPathwayStatusEditable =
    !_.isEmpty(currentSelectedStudent) &&
    currentSelectedStudent.plannedStatus.type !== studentPathwayStatusTypes.NEW &&
    currentSelectedStudent.plannedStatus.type !== studentPathwayStatusTypes.PATHWAY_INVALID;

  const PathwayStatusNameComponent = () => (
    <Stack
      className={classes.statusRoot}
      direction="row"
      spacing={1}
      alignItems="center"
      onClick={() => (isPathwayStatusEditable ? setEditPathwayStatus(true) : null)}>
      <StatusCell
        sx={{ width: '150px' }}
        name={currentSelectedStudent?.plannedStatus?.name}
        value={currentSelectedStudent?.plannedStatus?.type}
      />
      {isPathwayStatusEditable ? <Edit className={classes.edit} color="secondary" /> : null}
    </Stack>
  );

  const PathwayStatusListComponent = () => (
    <Stack>
      <DeclaredSelect
        margin="dense"
        value={currentSelectedStudent.plannedStatus.name}
        className={classes.pathwaySelect}
        onBlur={() => {
          setEditPathwayStatus(false);
          setOutlineValue(0);
        }}
        onFocus={() => {
          setOutlineValue(1);
        }}
        defaultOpen={true}
        outlineValue={outlineValue}
        color="white">
        {reorderedPathwayStatus.map(({ name, type }, index) => (
          <MenuItem value={name} key={index} onClick={() => handlePathwayStatusSelection(type)}>
            {name}
          </MenuItem>
        ))}
      </DeclaredSelect>
    </Stack>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.progressContainer}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography
            color="primary"
            className={classes.studentName}
            fontSize={32}
            fontWeight={800}>
            {currentSelectedStudent?.name}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography display="inline" variant="h6" fontWeight={600}>
            {currentSelectedStudent?.studentId}
          </Typography>
          {editPathwayStatus ? <PathwayStatusListComponent /> : <PathwayStatusNameComponent />}
        </Stack>
      </Box>
      <Box className={classes.progressContainer}>
        {editMode ? <ProgramOptionListComponent /> : <ProgramOptionNameComponent />}
        <ProgressBar
          allUnits={currentSelectedStudent?.programOption?.units}
          completedUnits={currentSelectedStudent?.completedUnits}
        />
      </Box>
    </Box>
  );
});
StudentStatusBar.displayName = 'StudentStatusBar';
export default StudentStatusBar;
