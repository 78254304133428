import { HttpService } from './HttpService';

export default class TeqService extends HttpService {
  async save(org, body) {
    return this.post(`/organizations/${org}/transfer-equivalencies`, body);
  }

  async saveWithRequirements(org, body) {
    return this.post(`/organizations/${org}/transfer-equivalencies/withRequirements`, body);
  }

  async update(org, teqId, body) {
    return this.put(`/organizations/${org}/transfer-equivalencies/${teqId}`, body);
  }

  async getTeqFromLAs(org, transferringOrg, learningActivities, catalogId) {
    let queryParams = `learningActivities=${learningActivities}&transferringOrg=${transferringOrg}`;
    if (catalogId) {
      queryParams += `&catalogId=${catalogId}`;
    }
    return this.get(`organizations/${org}/transfer-equivalencies?${queryParams}`);
  }

  async saveTeqAssignments(org, studentId, body) {
    return this.post(`/organizations/${org}/students/${studentId}/transferEqAssignments`, body);
  }

  async patchTeqAssignments(org, studentId, body) {
    return this.patch(`/organizations/${org}/students/${studentId}/transferEqAssignments`, body);
  }

  async getAllStatuses(org) {
    return this.get(`/organizations/${org}/transfer-equivalencies/statuses`);
  }
}
