import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Box, TextField, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareAutocomplete from '../DeclareAutocomplete';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getPrograms } from '../../redux/actions/programActions';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyle = makeStyles((theme) => ({
  boxSelectFieldProgramOption: {
    width: theme.spacing(40),
  },
  ruleProgramOptionbox: {
    display: 'flex',
  },
}));

export default function StudentPreferredProgramX(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const programList = useSelector((state) => state.programs.programList);
  const [optionsList, setOptionsList] = useState([]);
  const [selectedProgramOptions, setSelectedProgramOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [requirementCheck, setRequirementCheck] = useState(false);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const processChange = (isReq = requirementCheck, options) => {
    const data = {
      isRequirement: isReq,
      programOptions: options,
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  const classes = useStyle();

  useEffect(() => {
    if (_.isEmpty(programList) > 0) {
      dispatch(getPrograms(orgId));
    }
  }, []);

  useEffect(() => {
    const programOptionsList = [];
    if (!_.isEmpty(programList) > 0) {
      programList.map((proLst) => {
        proLst.programOptions.map((program) => {
          programOptionsList.push(program);
        });
      });
    }
    setOptionsList(programOptionsList);
  }, [programList]);

  useEffect(() => {
    if (!_.isEmpty(props.data.programOptions) && !_.isEmpty(optionsList)) {
      setSelectedOptions(props.data.programOptions);
      const existingOptions = [];
      props.data.programOptions.map((option) => {
        const item = _.find(optionsList, (op) => op.id === option);
        if (!_.isUndefined(item)) {
          existingOptions.push(item);
        }
      });
      setSelectedProgramOptions(existingOptions);
    }
    setIsRequirement(props.data.isRequirement || false);
    setRequirementCheck(props.data.isRequirement || false);
  }, [optionsList]);

  const setProgramOptions = (programOptions) => {
    const newOptionsIds = [];
    const newOptions = [];
    programOptions.map((option) => {
      newOptionsIds.push(option.id);
      newOptions.push(option);
    });
    setSelectedOptions(newOptionsIds);
    setSelectedProgramOptions(newOptions);
    processChange(isRequirement, newOptionsIds);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.ruleProgramOptionbox}>
          <Box className={classes.boxSelectFieldProgramOption}>
            <DeclareAutocomplete
              multiple
              inputlabel={`${t('Program Option')} *`}
              onChange={(_event, value) => {
                setProgramOptions(value);
              }}
              limitTags={1}
              value={selectedProgramOptions}
              options={optionsList}
              getOptionLabel={(option) => option.name + ' ' + option?.code}
              disabled={!selectedItemEditable}
              renderOption={(props, value, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {value.name}
                </li>
              )}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    key={index}
                    label={option.name + ' ' + option.code}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => <TextField {...params} value={params.id} />}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={requirementCheck}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setRequirementCheck(e.target.checked);
              }}
              onBlur={() => {
                processChange(requirementCheck, selectedOptions);
              }}
            />
          }
          label={t('Is a requirement')}
        />
      </Grid>
    </Grid>
  );
}
