import _ from 'lodash';

export const flatMapProgrammeMap = (programMap, requirements, courses) => {
  if (!_.isNil(programMap)) {
    _.forEach(programMap, (res) => {
      if (res.isRequirement) {
        requirements.push({ id: res.id, type: res.type, name: res.name });
      } else {
        courses.push({ id: res.id, type: res.type, name: res.name });
      }
      flatMapProgrammeMap(res.items, requirements, courses);
    });
  }
  return { requirements: requirements, courses: courses, map: programMap };
};

export const flatMapTransferOptions = (currentEquivalencies, destinationsCourses) => {
  if (!_.isNil(currentEquivalencies)) {
    _.forEach(currentEquivalencies, (updatedCurrentEquivalency) => {
      _.forEach(updatedCurrentEquivalency.equivalencies, ({ destinationEqList }) => {
        _.forEach(destinationEqList, (res) => {
          destinationsCourses.push(res);
        });
      });
    });
  }
  return {
    destinations: destinationsCourses,
    currentEquivalencies: currentEquivalencies,
  };
};

export const getUnusedCourses = (flatProgramMap, destinations, unusedCourses) => {
  _.forEach(destinations, (destination) => {
    const item = flatProgramMap.find((res) => res.id === destination.id);
    if (_.isUndefined(item)) {
      unusedCourses.push(destination.id);
    }
  });
  return unusedCourses;
};
