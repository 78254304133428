import { Box, Container, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, generatePath, matchPath } from 'react-router-dom';

import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import ProgramForm from './ProgramForm';
import TabView from '../../../components/TabView';
import ProgramOptionList from './ProgramOptionList';
import SearchTextBox from '../../../components/SearchTextBox';
import { appRoutes } from '../../../helpers/routes';
import { getProgram } from '../../../redux/actions/programActions';
import { getStatus } from '../../../helpers/appConstants';
import { getAllDepartments } from '../../../redux/actions/departmentActions';

export default function EditCreateProgram() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isProgramOptionListView = matchPath(
    { path: appRoutes.catalog.PROGRAM_OPTION_LIST },
    pathname,
  );
  const [activeTabIndex, setActiveTabIndex] = React.useState(isProgramOptionListView ? 1 : 0);
  const navigate = useNavigate();
  const { orgId, programId } = useParams();
  const isEditView = programId !== undefined;
  const dispatch = useDispatch();
  const [currentProgram, setCurrentProgram] = React.useState({
    name: '',
    type: '',
    department: '',
    status: getStatus()[3].value,
  });
  const [breadCrumbName, setBreadCrumbName] = React.useState(isEditView ? '' : t('New Program'));
  const [searchKey, setSearchKey] = useState('');

  const programList = useSelector((store) => store.programs.programList);
  const departmentList = useSelector((store) => store.departments.departmentList);

  const updateView = ({ name, type, department, status }) => {
    setBreadCrumbName(name);
    setCurrentProgram({ name, type, department: department.id, status });
  };
  useEffect(() => {
    if (departmentList.length === 0) {
      dispatch(getAllDepartments({ orgId }));
    }
    if (isEditView) {
      if (programList.length === 0) {
        dispatch(getProgram(orgId, programId, updateView));
      } else {
        const selectedProgram = programList.find((program) => program.id === programId);
        updateView(selectedProgram);
      }
      setSearchKey('');
    }
  }, [programList]);

  const navigateToCreateProgramOption = () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_OPTION_CREATE, { orgId, programId }), {
      state: currentProgram,
    });
  };

  const navigateToProgramOptions = () => {
    setActiveTabIndex(1);
    setSearchKey('');
    navigate(generatePath(appRoutes.catalog.PROGRAM_OPTION_LIST, { orgId, programId }));
  };

  const navigateToProgramEdit = () => {
    setActiveTabIndex(0);
    navigate(generatePath(appRoutes.catalog.PROGRAM_EDIT, { orgId, programId }));
  };

  const tabs = [
    {
      name: t('Program'),
      component: <ProgramForm currentProgram={currentProgram} isEditView={isEditView} />,
      callBack: navigateToProgramEdit,
    },
    {
      name: t('Options'),
      component: <ProgramOptionList searchKey={searchKey} />,
      callBack: navigateToProgramOptions,
    },
  ];
  const programRoute = generatePath(appRoutes.catalog.PROGRAM_LIST, { orgId });
  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '20px' }}>
          <DeclareBreadcrumb
            items={[{ name: t('programs'), customRoute: programRoute }, breadCrumbName]}
          />
          {activeTabIndex === 1 ? (
            <>
              <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                sx={{
                  height: '45px',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                  fontWeight: 300,
                }}
                onClick={() => {
                  navigateToCreateProgramOption();
                }}>
                {t('New Option')}
              </Button>
            </>
          ) : (
            ''
          )}
        </Box>
        {isEditView ? (
          <TabView tabs={tabs} selectedTab={activeTabIndex} />
        ) : (
          <ProgramForm currentProgram={currentProgram} isEditView={isEditView} />
        )}
      </Box>
    </Container>
  );
}
