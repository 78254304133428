import React, { useState, useEffect } from 'react';
import { ThemeProvider, Box, Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Apartment, AccountTree } from '@mui/icons-material';

import Sidebar from './components/Sidebar';
import CatalogHeader from './components/catalog/Header';
import theme from './theme';
import { appRoutes, APP_TYPES_ROUTE } from './helpers/routes';
import { setError, setFormError } from './redux/actions/notificationActions';
import { getOrgsWithSelectedOrg } from './redux/actions/orgActions';
import { getSidebarVisibility } from './helpers/common';
import DeclareSnackBar from './components/DeclareSnackBar';
import catalogRoutes from './components/catalog/routes';
import ErrorPage from './pages/catalog/ErrorPage';
import advisorRoutes from './components/advisor/routes';
import loginRoutes from './components/auth/routes';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  declarePage: {
    backgroundColor: '#eaeaea',
  },
}));

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <AppWrapper></AppWrapper>
        </DndProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;

const AppWrapper = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();
  const [sidebarEnabled, setSidebarEnabled] = useState(false);
  const [platform, setPlatform] = useState(null);
  const loadingState = useSelector((state) => state.loadingState);
  const dispatch = useDispatch();
  const orgState = useSelector((reduxState) => reduxState.allOrgs);
  const navigate = useNavigate();

  const menuList = [
    {
      name: t('organizations'),
      Icon: () => <Apartment />,
    },
    {
      name: t('organizationGroups'),
      Icon: () => <AccountTree />,
    },
  ];

  useEffect(() => {
    setSidebarEnabled(getSidebarVisibility(pathname));
    dispatch(setFormError({}));
    dispatch(setError({}));
    const splitVals = pathname.split('/');

    if (splitVals.length > 3) {
      if (orgState.orgs.length === 0) {
        const navigateToOrgList = () => {
          navigate(appRoutes.catalog.HOME);
        };
        dispatch(getOrgsWithSelectedOrg(splitVals[2], navigateToOrgList));
      }
    }
    setPlatform(splitVals[1]);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route
          path={`${APP_TYPES_ROUTE.ADV}/*`}
          element={
            <CatalogHeader hideSearchBox={false} dashboardPath={appRoutes.advisor.DASHBOARD} />
          }
        />
        <Route
          path={`${APP_TYPES_ROUTE.CAT}/*`}
          element={
            <CatalogHeader
              menuList={menuList}
              hideSearchBox={true}
              dashboardPath={appRoutes.catalog.DASHBOARD}
            />
          }
        />
        {loginRoutes}
      </Routes>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1400 }}
        open={!_.isEmpty(loadingState.inprogressLoadingOperations)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DeclareSnackBar />
      {pathname.split('/')[1] === appRoutes.auth.LOGIN.split('/')[1] ? null : (
        <Box
          className={classes.declarePage}
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {sidebarEnabled ? (
            <Box sx={{ display: 'flex', flex: '0 1 0' }}>
              <Routes>
                <Route path="/*" element={<Sidebar platform={platform} />} />
              </Routes>
            </Box>
          ) : (
            ''
          )}
          <Box sx={{ display: 'flex', flex: '1 0 0' }}>
            <Routes>
              {catalogRoutes}
              {advisorRoutes}
              <Route
                path="/"
                element={<Navigate to={appRoutes.catalog.ORGANIZATION_LIST} replace />}
              />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Box>
        </Box>
      )}
    </>
  );
};
