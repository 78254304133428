import OrgError from '../../../error/OrgError';
import ProgramService from '../../../services/ProgramService';

const programService = new ProgramService();

export function* requestGetPrograms(param) {
  if (param) {
    return yield programService.getAll(param);
  }
  throw OrgError.orgRequired();
}

export function* requestCreateProgram(payload) {
  const { org, data } = payload;
  if (org) {
    return yield programService.save(org, data);
  }
  throw OrgError.orgRequired();
}

export function requestEditProgram(payload) {
  const { org, programId, data } = payload;
  return programService.update(org, programId, data);
}

export function* requestGetProgramOptions({ orgId, programId }) {
  if (orgId) {
    return yield programService.getProgramOptions(orgId, programId);
  }
  throw OrgError.orgRequired();
}

export function* requestGetOption({ orgId, programId, optionId }) {
  return yield programService.getOption(orgId, programId, optionId);
}

export function* requestCreateProgramOption(payload) {
  const { orgId, programId, data } = payload;
  if (orgId) {
    return yield programService.saveOption(orgId, programId, data);
  }
  throw OrgError.orgRequired();
}

export function* requestUpdateProgramOption(payload) {
  const { orgId, programId, optionId, data } = payload;
  return yield programService.updateOption(orgId, programId, optionId, data);
}
