export const APP_TYPES_ROUTE = {
  CAT: '/cat',
  ADV: '/adv',
};

const authRoutes = {
  LOGIN: '/login/',
};

const advisorRoutes = {
  HOME: APP_TYPES_ROUTE.ADV,
  DASHBOARD: APP_TYPES_ROUTE.ADV + '/:orgId/dashboard',
  TRANSFER_EQUIVALENCIES: APP_TYPES_ROUTE.ADV + '/:orgId/equivalencies',
  TRANSFER_EVALUATIONS_LIST: APP_TYPES_ROUTE.ADV + '/:orgId/evaluations',
  TRANSFER_GRADES: APP_TYPES_ROUTE.ADV + '/:orgId/evaluations/transferGrades',
  TRANSFER_EVALUATIONS_VIEW: APP_TYPES_ROUTE.ADV + '/:orgId/evaluations/:programMapId/view',
  TRANSFER_EVALUATIONS_CREATE: APP_TYPES_ROUTE.ADV + '/:orgId/evaluations/new',
  STUDENT_PLANNING: APP_TYPES_ROUTE.ADV + '/:orgId/studentPlanning/',
  CREATE_TRANSFER_EQUIVALENCIES: APP_TYPES_ROUTE.ADV + '/:orgId/equivalencies/new/',
  EDIT_TRANSFER_EQUIVALENCIES: APP_TYPES_ROUTE.ADV + '/:orgId/equivalencies/:transferEquivalencyId',
  STUDENT_LIST: APP_TYPES_ROUTE.ADV + '/:orgId/studentList',
  GLOBAL_CLASS_SCHEDULE_LIST: APP_TYPES_ROUTE.ADV + '/:orgId/classSchedules',
  SINGLE_CLASS_SCHEDULE_VIEW:
    APP_TYPES_ROUTE.ADV + '/:orgId/classSchedules/:lActivityId/:termInstanceId/:deliveryMode',
  REPORTS_COURSE_PROJECTION: APP_TYPES_ROUTE.ADV + '/:orgId/reports/courseProjection',
};

const catalogRoutes = {
  HOME: APP_TYPES_ROUTE.CAT,
  DASHBOARD: APP_TYPES_ROUTE.CAT + '/:orgId/dashboard',
  CATALOG_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/catalog-version',
  CATALOG_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/catalog-version/create-catalog',
  CATALOG_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/catalog-version/edit-catalog',
  CATALOG_DUPLICATE: APP_TYPES_ROUTE.CAT + '/:orgId/catalog-version/duplicate-catalog',
  CATALOG_REORDER: APP_TYPES_ROUTE.CAT + '/:orgId/catalog-version/reorder',
  ORGANIZATION_LIST: APP_TYPES_ROUTE.CAT + '/organizations',
  ORGANIZATION_GROUP: APP_TYPES_ROUTE.CAT + '/organization-groups',
  ORGANIZATION_CREATE: APP_TYPES_ROUTE.CAT + '/organizations/create-organization',
  ORGANIZATION_EDIT: APP_TYPES_ROUTE.CAT + '/organizations/edit-organization',
  LEARNING_ACTIVITY_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities',
  COURSE_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities/new-course',
  COURSE_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities/:lActivityId',
  COURSE_RULE_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities/:lActivityId/rule',
  PROGRAM_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/programs',
  PROGRAM_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/programs/new',
  PROGRAM_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/programs/:programId',
  PROGRAM_OPTION_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/programs/:programId/programOptions',
  PROGRAM_OPTION_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/programs/:programId/programOptions/new',
  PROGRAM_OPTION_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/programs/:programId/programOptions/:optionId',
  PROGRAM_MAP_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/programMaps',
  PROGRAM_MAP_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/programMaps/new',
  PROGRAM_MAP_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/programMaps/:programId',
  PROGRAM_MAP_VIEW: APP_TYPES_ROUTE.CAT + '/:orgId/programMaps/:programId/view',
  COLLEGE_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/departments',
  COLLEGE_DEPARTMEMT_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/departments/new',
  COLLEGE_DEPARTMEMT_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/departments/:departmentId',
  TERMS_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/terms',
  TERM_CREATE: APP_TYPES_ROUTE.CAT + '/:orgId/terms/new',
  TERM_EDIT: APP_TYPES_ROUTE.CAT + '/:orgId/terms/:termId',
  TERM_REORDER: APP_TYPES_ROUTE.CAT + '/:orgId/terms/reorder',
  TERMS_SCHEDULE: APP_TYPES_ROUTE.CAT + '/:orgId/terms/schedule',
  CLASS_SCHEDULE_LIST:
    APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities/:lActivityId/classSchedules',
  CLASS_SCHEDULE_CREATE:
    APP_TYPES_ROUTE.CAT + '/:orgId/learning-activities/:lActivityId/classSchedules/new',
  CLASS_SCHEDULE_EDIT:
    APP_TYPES_ROUTE.CAT +
    '/:orgId/learning-activities/:lActivityId/classSchedules/:classScheduleId',
  SETTINGS_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/settings',
  GLOBAL_CLASS_SCHEDULE_LIST: APP_TYPES_ROUTE.CAT + '/:orgId/classSchedules',
};

export const appRoutes = {
  advisor: advisorRoutes,
  catalog: catalogRoutes,
  auth: authRoutes,
};

export const getBreadCrumbRoute = (pathname, noOfLevels, level) => {
  const paths = pathname.split('/');
  return paths.splice(0, paths.length - noOfLevels + level + 1).join('/');
};
