import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import RuleButtonComponent from './RuleButtonComponent';
import Rule from './Rule';
import { RuleCat } from '../helpers/ruleHelper';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  declareRuleGroup: {
    position: 'relative',
    padding: 8,
    border: '1px solid',
    borderRadius: 20,
    borderColor: theme.palette.primary.main,
    '&:nth-of-type(1)': {
      borderColor: ({ error }) => {
        return error ? theme.palette.error.main : theme.palette.primary.main;
      },
    },
    '& > $declareRuleGroup': {
      marginTop: '10px',
      marginBottom: '10px',
      marginLeft: '20px',
      '&::before': {
        borderWidth: '0 0 2px 2px',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        content: "''",
        width: '16px',
        top: '-32px',
        left: '-16px',
        height: 'calc(50% + 32px)',
        boxSizing: 'border-box',
        position: 'absolute',
      },
      '&::after': {
        borderWidth: '0 0 0 2px',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        content: "''",
        width: '16px',
        height: '100%',
        position: 'absolute',
        top: '30px',
        left: '-16px',
        boxSizing: 'border-box',
      },
    },
    '&:last-child': {
      '&::after': {
        display: 'none',
      },
    },
  },
  rule: {
    '&:last-child': {
      '& .MuiFormControl-root': {
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  right: {
    float: 'right',
  },
}));

export default function RuleGroup(props) {
  const ref = useRef(null);
  const { type, operator, data, uuid } = props.ruleData;
  const { selectedElementPath, selectedItemEditable } = props;
  const { error } = useSelector((state) => state.notifications);
  const classes = useStyles({ error: error?.persistErrors ? true : false });
  // -1 = Before Item, 0 = On Item, 1 = After Item
  const [draggedEleHoverPosition, setDraggedEleHoverPosition] = useState(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: RuleCat.RULE,
    item: {
      type: operator ? 'GROUP' : 'RULE',
      id: uuid,
      location: selectedElementPath,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver, elem }, drop] = useDrop(
    () => ({
      accept: RuleCat.RULE,
      hover: (item, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          const myWidthPotion = ref.current?.getBoundingClientRect().height / 3;
          const diff = monitor.getClientOffset().y - ref.current?.getBoundingClientRect().top;
          const section = Math.floor(diff / myWidthPotion) - 1;
          setDraggedEleHoverPosition(section);
        }
      },
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        props.moveRule(item.location, selectedElementPath, draggedEleHoverPosition);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }) && selectedElementPath.length != 0,
        dropItem: monitor.getItem(),
        elem: selectedElementPath,
        targetId: monitor.targetId,
      }),
    }),
    [props, draggedEleHoverPosition],
  );

  return (
    <>
      {isOver && draggedEleHoverPosition === -1 ? (
        <Box
          className={classes.declareRuleGroup}
          style={{
            borderStyle: 'dashed',
            height: '3px',
            padding: '0px',
            marginTop: '0',
            marginBottom: '0',
          }}></Box>
      ) : (
        ''
      )}
      <Box
        ref={selectedItemEditable ? drag(drop(ref)) : null}
        className={operator ? classes.declareRuleGroup : classes.rule}
        style={{
          display: isDragging ? 'none' : '',
          fontSize: 25,
          fontWeight: 'bold',
          cursor: uuid == 1 ? 'default' : 'move',
        }}>
        {operator ? (
          <RuleButtonComponent
            addRule={props.addRule}
            addGroup={props.addGroup}
            changeOperatorHandler={props.changeOperatorHandler}
            operator={operator}
            data={data}
            uuid={uuid}
            selectedElementPath={selectedElementPath}
            selectedItemEditable={selectedItemEditable}
            removeRuleOrGroupHandler={props.removeRuleOrGroupHandler}></RuleButtonComponent>
        ) : (
          ''
        )}
        {isOver && draggedEleHoverPosition === 0 && operator && data.length === 0 ? (
          <Box
            className={classes.declareRuleGroup}
            style={{
              borderStyle: 'dashed',
              padding: '0',
              marginTop: '0',
              marginBottom: '0',
            }}></Box>
        ) : (
          ''
        )}
        {operator ? (
          data.map((value, key) => (
            <RuleGroup
              ruleData={value}
              key={key}
              uuid={uuid}
              index={key}
              selectedElementPath={[...selectedElementPath, ...[key]]}
              addRule={props.addRule}
              addGroup={props.addGroup}
              moveRule={props.moveRule}
              changeOperatorHandler={props.changeOperatorHandler}
              changeRuleTypeHandler={props.changeRuleTypeHandler}
              addRuleDataHandler={props.addRuleDataHandler}
              selectedItemEditable={selectedItemEditable}
              removeRuleOrGroupHandler={props.removeRuleOrGroupHandler}></RuleGroup>
          ))
        ) : (
          <Rule
            uuid={uuid}
            type={type}
            data={data}
            selectedElementPath={selectedElementPath}
            changeRuleTypeHandler={props.changeRuleTypeHandler}
            addRuleDataHandler={props.addRuleDataHandler}
            selectedItemEditable={selectedItemEditable}
            removeRuleOrGroupHandler={props.removeRuleOrGroupHandler}></Rule>
        )}
      </Box>
      {isOver && draggedEleHoverPosition === 1 ? (
        <Box
          className={classes.declareRuleGroup}
          style={{
            borderStyle: 'dashed',
            height: '3px',
            padding: '0px',
            marginTop: '0',
            marginBottom: '0',
          }}></Box>
      ) : (
        ''
      )}
    </>
  );
}
