import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareTextField from '../DeclareTextField';

export default function NoMoreThanXCoursesRule(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [maxCourses, setMaxCourses] = useState(null);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = isRequirement) => {
    const data = {
      isRequirement: isReq,
      number: Number(maxCourses),
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  useEffect(() => {
    setMaxCourses(props.data.number || '');
    setIsRequirement(props.data.isRequirement || false);
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DeclareTextField
          onChange={(e) => {
            setMaxCourses(e.target.value);
          }}
          onBlur={() => {
            processChange();
          }}
          inputlabel={`${t('Max Courses')}*`}
          variant="outlined"
          placeholder={t('Maximum Courses')}
          value={maxCourses}
          name="max_courses"
          type="number"
          disabled={!selectedItemEditable}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirement}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirement(e.target.checked);
                processChange(e.target.checked);
              }}
            />
          }
          label={t('Requirement')}
        />
      </Grid>
    </Grid>
  );
}
