/* eslint-disable react/jsx-key */
import React from 'react';
import { Route } from 'react-router-dom';
import { appRoutes } from '../../helpers/routes';
import StudentList from '../../pages/advisor/StudentList';
import CreateEditView from '../../pages/advisor/TransferEquivalency/CreateEditView';
import ListView from '../../pages/advisor/TransferEquivalency/ListView';
import StudentView from '../../pages/advisor/StudentPlaning/View';
import TransferEvaluationList from '../../pages/advisor/TransferEvaluation/List';
import TransferEvaluationView from '../../pages/advisor/TransferEvaluation/View';
import CreateTransferEvaluation from '../../pages/advisor/TransferEvaluation/Create';
import TransferGrades from '../../pages/advisor/TransferEvaluation/TransferGrades';
import ClassScheduleList from '../../pages/advisor/ClassSchedule/ClassScheduleList';
import ClassScheduleView from '../../pages/advisor/ClassSchedule/ClassScheduleView';
import Dashboard from '../../pages/advisor/Dashboard';
import CourseProjectionReport from '../../pages/advisor/Report/CourseProjectionReport';

const routes = [
  <Route path={appRoutes.advisor.DASHBOARD} element={<Dashboard />} />,
  <Route path={appRoutes.advisor.STUDENT_PLANNING} element={<StudentView />} />,
  <Route path={appRoutes.advisor.STUDENT_LIST} element={<StudentList />} />,
  <Route path={appRoutes.advisor.CREATE_TRANSFER_EQUIVALENCIES} element={<CreateEditView />} />,
  <Route path={appRoutes.advisor.EDIT_TRANSFER_EQUIVALENCIES} element={<CreateEditView />} />,
  <Route path={appRoutes.advisor.TRANSFER_EQUIVALENCIES} element={<ListView />} />,
  <Route path={appRoutes.advisor.TRANSFER_EVALUATIONS_LIST} element={<TransferEvaluationList />} />,
  <Route path={appRoutes.advisor.TRANSFER_EVALUATIONS_VIEW} element={<TransferEvaluationView />} />,
  <Route path={appRoutes.advisor.TRANSFER_GRADES} element={<TransferGrades />} />,
  <Route path={appRoutes.advisor.GLOBAL_CLASS_SCHEDULE_LIST} element={<ClassScheduleList />} />,
  <Route path={appRoutes.advisor.SINGLE_CLASS_SCHEDULE_VIEW} element={<ClassScheduleView />} />,
  <Route
    path={appRoutes.advisor.TRANSFER_EVALUATIONS_CREATE}
    element={<CreateTransferEvaluation />}
  />,
  <Route path={appRoutes.advisor.REPORTS_COURSE_PROJECTION} element={<CourseProjectionReport />} />,
];

export default routes;
