/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import theme from '../../theme';
import { appRoutes } from '../../helpers/routes';
import { getOrgs } from '../../redux/actions/orgActions';

const useStyles = makeStyles(() => ({
  logoImg: {
    width: '40px',
    paddingBottom: 30,
  },
  msLogo: {
    width: '20px',
    height: '20px',
    paddingTop: 4,
    paddingRight: 16,
    paddingLeft: 6,
  },
  msText: {
    fontFamily: 'Segoe UI',
    color: '#5E5E5E',
    fontSize: '16px',
    fontWeight: 600,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: theme.palette.primary.main,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    height: '350px',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.white.main,
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
  },
}));

export default function Login() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgs } = useSelector((state) => state.allOrgs);
  const token = window.localStorage.getItem('token');
  const returnUrl = window.localStorage.getItem('returnUrl');

  useEffect(() => {
    if (!_.isNull(token)) {
      if (!_.isNull(returnUrl)) {
        window.location = returnUrl;
      } else {
        dispatch(getOrgs());
      }
    }
  }, [token]);

  useEffect(() => {
    if (!_.isEmpty(orgs)) {
      navigateToStudentList(orgs);
    }
  }, [orgs]);

  useEffect(() => {
    if (!_.isEmpty(location.hash)) {
      const { id_token, access_token } = transformTokenData();
      window.localStorage.setItem('token', id_token);
      window.localStorage.setItem('access', access_token);
    }
  }, [location.hash]);

  const transformTokenData = () => {
    return location.hash
      .substring(1)
      .split('&')
      .map((data) => data.split('='))
      .reduce((arr, [key, value]) => Object.assign(arr, { [key]: value }), {});
  };

  const signIn = () => {
    const payload = {
      identityProvider: process.env.REACT_APP_IDENTITY_PROVIDER,
      redirectURI: process.env.REACT_APP_REDIRECT_URI,
      clientId: process.env.REACT_APP_AWS_CLIENT_ID,
      responseType: process.env.REACT_APP_RESPONSE_TYPE,
      endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
    };

    const { identityProvider, redirectURI, clientId, responseType, endpoint } = payload;
    window.location = `${endpoint}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectURI}&identity_provider=${identityProvider}`;
  };

  const navigateToStudentList = (orgs) => {
    const { id: orgId } = orgs[0];
    window.location = appRoutes.advisor.STUDENT_LIST.replace(':orgId', orgId);
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.mainContainer}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <img src="/logo.png" className={classes.logoImg} />
          <Typography
            sx={{ pl: 1, pt: 1 }}
            color={theme.palette.primary.main}
            fontWeight={600}
            fontSize={'22px'}>
            {'DECLARE'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            border: 2,
            borderColor: '#8C8C8C',
            textAlign: 'center',
            padding: 1,
            cursor: 'pointer',
            ml: 2,
          }}
          onClick={() => signIn()}>
          <img src="/microsoft-logo.png" className={classes.msLogo} />
          <Typography
            fontWeight={600}
            fontSize={18}
            color={'#5E5E5E'}
            fontFamily={'Segoe UI'}
            sx={{ paddingRight: 1 }}>
            {'Sign in with Microsoft'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
