import OrgError from '../../../error/OrgError';
import { ClassScheduleService } from '../../../services/ClassScheduleService';

const classScheduleService = new ClassScheduleService();

export function* requestPostClassScheduleStats(params) {
  const { orgId, data } = params;
  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield classScheduleService.sendCancelledClassScheduleStats(orgId, data);
}
