import { HttpService } from './HttpService';

export class RuleService extends HttpService {
  async save(org, body) {
    return this.post(`organizations/${org}/rules`, body);
  }

  async update(orgId, ruleId, body) {
    return this.put(`organizations/${orgId}/rules/${ruleId}`, body);
  }

  async getAssociatedRule(orgId, rulableId) {
    return this.get(`organizations/${orgId}/rules?ruledEntity=${rulableId}`);
  }
}
