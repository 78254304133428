import { actionTypes } from '../constants/actionTypes';
import { getStatus } from '../../helpers/appConstants';

const initState = {
  catalogs: [],
  latestCatalog: {},
  sourceLatestCatalog: {},
};

export const catalogReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CATALOGS:
      return {
        ...state,
        catalogs: payload,
        latestCatalog: payload
          .filter(({ status }) => status === getStatus()[0].value)
          .sort(({ order: orderA }, { order: orderB }) => orderB - orderA)[0],
      };
    case actionTypes.SELECT_ORG:
      return { ...state, catalogs: [] };
    case actionTypes.SET_LATEST_CATALOGS:
      return { ...state, latestCatalog: payload };
    case actionTypes.SET_LATEST_SOURCE_CATALOG:
      return { ...state, sourceLatestCatalog: payload };
    default:
      return state;
  }
};
