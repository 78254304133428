import React from 'react';
import { InputLabel, TextField } from '@mui/material';

/**
 * Declare text input
 * @param props Properties
 */

const DeclareTextField = (props) => {
  props = Object.assign(props);
  return (
    <>
      <InputLabel>{props.inputlabel}</InputLabel>
      <TextField {...props} />
    </>
  );
};

export default DeclareTextField;
