import React from 'react';
import { FormControlLabel } from '@mui/material';

/**
 * Declare form control
 * @param props Properties
 */

export default function DeclareFormControl(props) {
  props = Object.assign(props);
  return (
    <div>
      <FormControlLabel {...props} />
    </div>
  );
}
