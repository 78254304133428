import { actionTypes } from '../constants/actionTypes';

const initState = {
  error: {},
  formError: {},
  success: {
    message: null,
  },
};

export const notificationReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_ERROR:
      return { ...state, error: payload };
    case actionTypes.SET_FORM_ERROR:
      return { ...state, formError: payload };
    case actionTypes.SET_SUCCESS:
      return { ...state, success: payload };
    default:
      return state;
  }
};
