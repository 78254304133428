import { select } from 'redux-saga/effects';
import {
  setStudentsStatus,
  setStudentTeqAssignments,
  setCurrentSelectedStudent,
} from '../../actions/studentActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestGetStudents,
  requestGetStudentsStatusList,
  requestGetStudentTeqAssignments,
  requestGetStudentTransferEvaluations,
  requestPostTransferEvaluation,
  requestGetTransferEvaluationStatusList,
  requestPutTransferEvaluation,
  requestGenerateStudentPathway,
  requestPatchStudent,
  requestSearchStudents,
  requestPutStudentPathway,
  requestDeleteStudentTeqAssignment,
  requestUpdateStudentByStudentTeqStatus,
  requestDeleteStudentByStudentTeqStatus,
} from '../requests/students';
import {
  setTransferEvaluation,
  setStudentTransferEvaluations,
  setTransferEvaluationStatusList,
} from '../../actions/transferEvaluationActions';

import { getCurrentCatalog } from '../../../helpers/studentsHelper';

export function* handleGetStudents(action) {
  const { payload, callback } = action;
  const cb = (data) => {
    return callback(data);
  };
  yield requestHandler(requestGetStudents.bind(this, payload), null, requestTypes.GET, cb);
}

export function* handlePostTransferEvaluation(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPostTransferEvaluation.bind(this, payload),
    setTransferEvaluation,
    requestTypes.POST,
    navigate,
  );
}

export function* handleEditTransferEvaluation(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutTransferEvaluation.bind(this, payload),
    null,
    requestTypes.POST,
    navigate,
  );
}

export function* handleGetStudentsStatusList(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetStudentsStatusList.bind(this, payload),
    setStudentsStatus,
    requestTypes.GET,
  );
}

export function* handleGetStudentTransferEvaluations(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetStudentTransferEvaluations.bind(this, payload),
    setStudentTransferEvaluations,
    requestTypes.GET,
    callback,
  );
}

export function* handleGetTransferEvaluationStatusList(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetTransferEvaluationStatusList.bind(this, payload),
    setTransferEvaluationStatusList,
    requestTypes.GET,
  );
}

export function* handleGetStudentTeqAssignments(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetStudentTeqAssignments.bind(this, payload),
    setStudentTeqAssignments,
    requestTypes.GET,
    callback,
  );
}

export function* handleDeleteStudentTeqAssignment(action) {
  const { payload } = action;
  yield requestHandler(
    requestDeleteStudentTeqAssignment.bind(this, payload),
    null,
    requestTypes.DELETE,
  );
}

export function* handleGenerateStudentPathway(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGenerateStudentPathway.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handlePatchStudent(action) {
  const { payload } = action;
  const {
    allCatalogs: { latestCatalog, catalogs },
    programMaps: { programMapList },
  } = yield select();

  const callback = (data) => {
    const filteredProgramMaps = programMapList.filter(
      ({ catalogs }) => catalogs[0].id === latestCatalog.id,
    );

    const programMap = filteredProgramMaps.find(({ optionId }) => data.programOption === optionId);
    const student = {
      ...data,
      programOption: {
        id: data.programOption,
        name: programMap?.optionName,
        units: programMap?.units,
      },
      units: `${data.completedUnits}/${programMap?.units}`,
      refreshData: true,
      currentCatalog: getCurrentCatalog(
        programMapList,
        latestCatalog,
        data.programMapId,
        catalogs,
        data.catalogId,
      ),
    };

    return setCurrentSelectedStudent(student);
  };

  yield requestHandler(requestPatchStudent.bind(this, payload), callback, requestTypes.PATCH);
}

export function* handleSearchStudents(action) {
  const { payload, callback } = action;
  const cb = (data) => {
    return callback(Object.values(data));
  };
  yield requestHandler(requestSearchStudents.bind(this, payload), null, requestTypes.GET, cb);
}

export function* handleUpdateStudentPathway(action) {
  const { payload } = action;
  yield requestHandler(requestPutStudentPathway.bind(this, payload), null, requestTypes.PUT);
}

export function* handleUpdateStudentByStudentTeqStatus(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestUpdateStudentByStudentTeqStatus.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
  );
}

export function* handleDeleteStudentByStudentTeqStatus(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestDeleteStudentByStudentTeqStatus.bind(this, payload),
    null,
    requestTypes.PUT,
    callback,
  );
}
