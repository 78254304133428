import { actionTypes } from '../constants/actionTypes';

const initState = {
  innerContainerWidth: null,
};

const uiReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.UI.INNER_CONTAINER_WIDTH:
      return { ...state, innerContainerWidth: payload };
    default:
      return state;
  }
};

export default uiReducer;
