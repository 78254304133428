import _ from 'lodash';
import { setPathwayStatus, setTermPlanningStatus } from '../../actions/pathwayActions';
import { setStudentPathway } from '../../actions/studentActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestGetPathways,
  requestGetPathwaysCourseUsage,
  requestGetPathwaysToExplore,
  requestGetTermPlanningStatus,
  requestPostResetPathway,
  requestPostPathways,
  requestPostPathwaysCourseUsage,
  requestPutPathway,
  requestValidatePathway,
  requestGetPathwayStatus,
  requestMarkCourseAsIncomplete,
} from '../requests/pathways';

export function* handleGetTermPlanningStatus(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetTermPlanningStatus.bind(this, payload),
    setTermPlanningStatus,
    requestTypes.GET,
  );
}

export function* handleGetPathwayStatus(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetPathwayStatus.bind(this, payload),
    setPathwayStatus,
    requestTypes.GET,
  );
}

export function* handlePostPathways(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostPathways.bind(this, payload),
    setStudentPathway,
    requestTypes.POST,
    callback,
  );
}
export function* handlePostPathwaysCourseUsage(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostPathwaysCourseUsage.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handleGetPathwaysCourseUsage(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetPathwaysCourseUsage.bind(this, payload),
    null,
    requestTypes.GET,
    callback,
    null,
    false,
    false,
    false,
  );
}

export function* handleGetPathways(action) {
  const { payload, callback = null } = action;

  const cb = (data) => {
    const { completedTerms, pathway } = data;
    if (!_.isNil(callback)) {
      return callback(pathway, completedTerms);
    }
    return data;
  };

  yield requestHandler(
    requestGetPathways.bind(this, payload),
    setStudentPathway,
    requestTypes.GET,
    cb,
  );
}

export function* handlePutPathway(action) {
  const { payload, callback } = action;
  yield requestHandler(requestPutPathway.bind(this, payload), null, requestTypes.PUT, callback);
}

export function* handleGetPathwaysToExplore(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestGetPathwaysToExplore.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handleValidatePathway(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestValidatePathway.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handlePostResetPathway(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostResetPathway.bind(this, payload),
    null,
    requestTypes.PATCH,
    callback,
  );
}

export function* handleMarkCourseAsIncomplete(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestMarkCourseAsIncomplete.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}
