import { Breadcrumbs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBreadCrumbRoute } from '../helpers/routes';

const useStyle = makeStyles((theme) => ({
  declareBreadCumb: {
    margin: '20px 0px',
    letterSpacing: '-0.5px',
    fontWeight: 450,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  declareBreadCumbCurrent: {
    margin: '20px 0px',
    letterSpacing: '-0.5px',
    fontWeight: 450,
    '&:hover': {
      cursor: 'default',
    },
  },
  root: {
    '& .MuiBreadcrumbs-separator': {
      fontSize: '30px',
      fontWeight: 450,
      color: theme.palette.primary.main,
    },
  },
}));

const DeclareBreadcrumb = (props) => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const classes = useStyle();
  const { items = [] } = props;

  return (
    <Breadcrumbs separator="&#62;" aria-label="breadcrumb" className={classes.root}>
      {items.map((item, index) => {
        const name = item.name || item;
        const navigationUrl = item.customRoute || getBreadCrumbRoute(pathname, items.length, index);
        const isLastItem = index === items.length - 1;
        const lastItemColor = items.length === 1 ? 'primary' : 'black';
        const lastItemText = state?.routeName ? state.routeName : name;
        return isLastItem ? (
          <Typography
            variant="h4"
            key={index}
            color={lastItemColor}
            className={classes.declareBreadCumbCurrent}>
            {lastItemText}
          </Typography>
        ) : (
          <Typography
            variant="h4"
            key={index}
            color="primary"
            className={classes.declareBreadCumb}
            onClick={() => {
              navigate(navigationUrl);
            }}>
            {name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default DeclareBreadcrumb;
