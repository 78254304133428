import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, MenuItem } from '@mui/material';
import DeclaredSelect from '../../DeclareSelect';
import * as catalogHelper from '../../../helpers/catalogHelper';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { termCardStyles } from '../../styles/termCardStyles';
import _ from 'lodash';
import { setFormError } from '../../../redux/actions/notificationActions';

const useStyle = makeStyles((theme) => ({
  pathwayBtn: {
    width: '24em',
    '&.MuiButton-root': {
      height: '45px',
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& $cardItems': {
      marginBottom: '10px',
    },
  },
  cardItems: {
    '& .MuiFormControl-root': {
      width: theme.spacing(20),
    },
  },
  studentAttendingTerms: {
    '& .MuiFormControl-root': {
      width: theme.spacing(45),
    },
  },
}));

export default function TermCardYear({
  handleShowCustomizedTerms,
  clickBuildPathway,
  formValues: values,
  handleFormInputChange: handleInputChange,
}) {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const termList = useSelector((state) => state.terms.termList).filter((term) => term.regularTerm);
  const { programMapList } = useSelector((state) => state.programMaps);
  const formError = useSelector((state) => state.notifications.formError);
  const classes = useStyle();
  const termCardClasses = termCardStyles({ center: true });
  let disableBuildPathway = true;

  useEffect(() => {
    if (termList.length < 1) {
      dispatch(getTerms(orgId));
    }

    if (_.isEmpty(programMapList)) {
      dispatch(getProgramMaps(orgId));
    }
  }, []);

  if (values.graduatingTerm && values.graduatingYear && values.nextTerm && values.nextYear) {
    disableBuildPathway = false;
  }

  return (
    <Box className={termCardClasses.termCard}>
      <Box className={classes.cardContainer}>
        <Typography className={classes.cardItems} variant="body1" fontSize={21} fontWeight={700}>
          {t('What is the next term for the student?') + '*'}
        </Typography>
        <Box className={classes.cardItems} sx={{ display: 'flex' }}>
          <DeclaredSelect
            margin="dense"
            value={values.nextTerm ? values.nextTerm : 0}
            onChange={handleInputChange}
            error={formError['nextTerm']}
            helperText={formError['nextTerm']?.message}
            name="nextTerm">
            <MenuItem disabled hidden value={0}>
              {t('Please select')}
            </MenuItem>
            {termList.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </DeclaredSelect>
          <DeclaredSelect
            sx={{ marginLeft: '10px' }}
            margin="dense"
            value={values.nextYear ? values.nextYear : 0}
            onChange={handleInputChange}
            error={formError['nextYear']}
            helperText={formError['nextYear']?.message}
            name="nextYear">
            <MenuItem disabled hidden value={0}>
              {t('Please select')}
            </MenuItem>
            {catalogHelper
              .getYears(true)
              .reverse()
              .map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
          </DeclaredSelect>
        </Box>

        <Typography className={classes.cardItems} variant="body1" fontSize={21} fontWeight={700}>
          {t('When does the student hope to graduate?') + '*'}
        </Typography>
        <Box className={classes.cardItems} sx={{ display: 'flex' }}>
          <DeclaredSelect
            margin="dense"
            value={values.graduatingTerm ? values.graduatingTerm : 0}
            onChange={(e) => {
              dispatch(setFormError({}));
              handleInputChange(e);
            }}
            error={formError['graduatingTerm']}
            helperText={formError['graduatingTerm']?.message}
            name="graduatingTerm">
            <MenuItem disabled hidden value={0}>
              {t('Please select')}
            </MenuItem>
            {termList.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </DeclaredSelect>
          <DeclaredSelect
            sx={{ marginLeft: '10px' }}
            margin="dense"
            value={values.graduatingYear ? values.graduatingYear : 0}
            onChange={handleInputChange}
            error={formError['graduatingYear']}
            helperText={formError['graduatingYear']?.message}
            name="graduatingYear">
            <MenuItem disabled hidden value={0}>
              {t('Please select')}
            </MenuItem>
            {catalogHelper
              .getYears(true)
              .reverse()
              .map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
          </DeclaredSelect>
        </Box>

        <Box className={classes.cardItems}>
          {disableBuildPathway ? (
            <Typography color="gray">{t('Customize Terms')}</Typography>
          ) : (
            <Typography
              onClick={() => {
                dispatch(setFormError({}));
                handleShowCustomizedTerms(true, values);
              }}
              color="primary"
              sx={{ cursor: 'pointer' }}>
              {t('Customize Terms')}
            </Typography>
          )}
        </Box>
        <Box className={classes.cardItems}>
          <Button
            className={classes.pathwayBtn}
            disableElevation
            disabled={disableBuildPathway}
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(setFormError({}));
              clickBuildPathway(values);
            }}>
            <Typography sx={{ color: 'common.white' }}>{t('Build Pathway')}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
