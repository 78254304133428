import { HttpService } from './HttpService';

export default class ProgramMapService extends HttpService {
  async getAll(id) {
    return this.get(`organizations/${id}/programMaps`);
  }

  async create(org, body) {
    return this.post(`organizations/${org}/programMaps`, body);
  }

  async update(org, programMapId, body) {
    return this.put(`organizations/${org}/programMaps/${programMapId}`, body);
  }

  renameProgramMapGroup(org, programMapId, body) {
    return this.post(`organizations/${org}/programMaps/${programMapId}/mapping/rename`, body);
  }

  addNewProgramMapGroup(org, programMapId, body) {
    return this.post(`organizations/${org}/programMaps/${programMapId}/mapping/addGroup`, body);
  }

  linkProgramMapIntoProgramMap(org, programMapId, body) {
    return this.post(
      `organizations/${org}/programMaps/${programMapId}/mapping/setProgramMap`,
      body,
    );
  }

  removeProgramMapNode(org, programMapId, body) {
    return this.post(`organizations/${org}/programMaps/${programMapId}/mapping/removeNode`, body);
  }

  moveProgramMapNode(org, programMapId, body) {
    return this.post(`organizations/${org}/programMaps/${programMapId}/mapping/moveNode`, body);
  }

  updateProgramMapNode(org, programMapId, body) {
    return this.post(`organizations/${org}/programMaps/${programMapId}/mapping/updateNode`, body);
  }

  async patchProgramMapping(org, programMapId, body) {
    return this.patch(`organizations/${org}/programMaps/${programMapId}/mapping`, body);
  }

  async getProgramMapping(org, programMapId, query) {
    return this.get(`organizations/${org}/programMaps/${programMapId}/mapping${query}`);
  }

  getLearningActivitiesForGroup(
    org,
    programMapId,
    { groupId, termInstanceId, page, size, order, sortBy, search },
  ) {
    return this.get(
      `organizations/${org}/programMaps/${programMapId}/learningActivities?parent=${groupId}&termInstance=${termInstanceId}&page=${page}&size=${size}&search=${search}&sortBy=${sortBy}&order=${order}`,
    );
  }

  async getProgramRequirements(org, programMapId) {
    return this.get(`organizations/${org}/programMaps/${programMapId}/requirements`);
  }

  async constructProgramMapPathway(programMapId, body) {
    return this.post(`engine/path/construct/${programMapId}`, body);
  }
}
