import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import StatusCell from '../../../components/StatusCell';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { appRoutes } from '../../../helpers/routes';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import _ from 'lodash';

export default function CatalogList() {
  const catalogStore = useSelector((state) => state.allCatalogs.catalogs);
  const selectedOrg = useSelector((state) => state.allOrgs.selectedOrg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { orgId } = useParams();
  const [searchKey, setSearchKey] = useState('');
  const [filteredCatalogList, setFilteredCatalogList] = useState(catalogStore);

  useEffect(() => {
    dispatch(getCatalogs(orgId));
    if (_.isEmpty(catalogStore)) {
      setSearchKey('');
    }
  }, []);

  useEffect(() => {
    var filteredList = catalogStore;
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = catalogStore.filter((o) => filters.some((fn) => fn(o)));
    }
    setFilteredCatalogList(filteredList);
  }, [catalogStore, searchKey]);

  const navigateToReorderCatalog = () => {
    navigate(generatePath(appRoutes.catalog.CATALOG_REORDER, { orgId }));
  };

  const navigateToCreateCatalog = () => {
    navigate(generatePath(appRoutes.catalog.CATALOG_CREATE, { orgId }));
  };

  const navigateToDuplicateCatalog = () => {
    navigate(generatePath(appRoutes.catalog.CATALOG_DUPLICATE, { orgId }));
  };

  const navigateToEditCatalog = (data) => {
    data.editView = true;
    data.routeName = data.name;

    navigate(generatePath(appRoutes.catalog.CATALOG_EDIT, { orgId: selectedOrg.id }), {
      state: data,
    });
  };

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        setCellProps: () => ({ style: { width: '80%' } }),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = catalogStore.findIndex((x) => x.id === value);
                navigateToEditCatalog(catalogStore[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onRowClick: (rowData, rowMeta) => {
      return;
    },
    onCellClick: (colData, cellMeta) => {
      return;
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Catalogs')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              sx={{
                height: '50px',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontWeight: 300,
                marginRight: '10px',
              }}
              onClick={() => {
                navigateToReorderCatalog();
              }}>
              {t('Reorder')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              sx={{
                height: '50px',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontWeight: 300,
                marginRight: '10px',
              }}
              onClick={() => {
                navigateToCreateCatalog();
              }}>
              {t('New Catalog')}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              sx={{
                height: '50px',
                borderRadius: '20px',
                textTransform: 'capitalize',
                fontWeight: 300,
              }}
              onClick={() => {
                navigateToDuplicateCatalog();
              }}>
              {t('Duplicate Catalog')}
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredCatalogList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
