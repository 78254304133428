import { HttpService } from './HttpService';

export default class PathwayService extends HttpService {
  async getTermPlanningStatus(org) {
    return this.get(`/organizations/${org}/pathways/termPlanningStatuses`);
  }

  async getPathwayStatuses(org) {
    return this.get(`/organizations/${org}/pathways/statuses`);
  }

  async savePathways(org, studentId, body) {
    return this.post(`/organizations/${org}/students/${studentId}/pathways`, body);
  }

  async savePathwaysCourseUsage(org, studentId, pathwayId, body) {
    return this.post(
      `/organizations/${org}/students/${studentId}/pathways/${pathwayId}/courseUsages`,
      body,
    );
  }

  async getPathwaysCourseUsage(org, studentId, pathwayId) {
    return this.get(
      `/organizations/${org}/students/${studentId}/pathways/${pathwayId}/courseUsages`,
    );
  }

  async getPathways(org, studentId, programOptionId) {
    return this.get(`/organizations/${org}/students/${studentId}/pathways/${programOptionId}`);
  }

  async updatePathway(org, studentId, body) {
    return this.put(`/organizations/${org}/students/${studentId}/pathways`, body);
  }

  async explorePathways(org, body) {
    return this.post(`/organizations/${org}/pathways/explore`, body);
  }

  async validatePathway(org, body) {
    return this.post(`/organizations/${org}/pathways/validate`, body);
  }

  resetPathway(org, studentId, pathwayId) {
    return this.post(`/organizations/${org}/students/${studentId}/pathways/${pathwayId}/reset`);
  }

  markCourseAsIncomplete(org, body) {
    return this.post(`/organizations/${org}/pathways/retakeCourse`, body);
  }
}
