import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import DeclareTextField from '../../../components/DeclareTextField';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DeclaredSelect from '../../../components/DeclareSelect';
import { appRoutes } from '../../../helpers/routes';
import { getStatus, programTypes } from '../../../helpers/appConstants';
import { createProgram, editProgram } from '../../../redux/actions/programActions';

export default function ProgramForm(props) {
  const { currentProgram, isEditView } = props;
  const { orgId, programId } = useParams();
  const { values, setValues, handleInputChange } = useDeclareForm(currentProgram);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orgs = useSelector((store) => store.allOrgs);
  const formError = useSelector((store) => store.notifications.formError);
  const departmentList = useSelector((store) => store.departments.departmentList);

  const backToProgramListView = () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_LIST, { orgId: orgs.selectedOrg.id }));
  };

  const handleSaveAction = (response) => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_EDIT, { orgId, programId: response.id }));
  };

  useEffect(() => {
    setValues(currentProgram);
  }, [currentProgram]);

  const data = {
    name: values.name,
    type: values.type,
    department: values.department,
    status: values.status,
  };

  const saveProgram = async () => {
    dispatch(createProgram(orgs.selectedOrg.id, data, handleSaveAction));
  };

  const updateProgram = async () => {
    dispatch(editProgram(orgs.selectedOrg.id, programId, data, handleSaveAction));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclareTextField
              inputlabel={t('name') + ' *'}
              variant="outlined"
              placeholder={t('Name of the program')}
              value={values.name}
              name="name"
              onChange={handleInputChange}
              error={formError['name']}
              helperText={formError['name']?.message}
            />
            <DeclaredSelect
              inputlabel={t('Department or College') + ' *'}
              margin="dense"
              value={values.department}
              name="department"
              onChange={handleInputChange}
              error={formError['department']}
              helperText={formError['department']?.message}>
              {(departmentList || []).map(({ name, id }, index) => (
                <MenuItem value={id} key={index}>
                  {name}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
          <Grid item xs={6} className="right">
            <DeclaredSelect
              inputlabel={t('type') + ' *'}
              margin="dense"
              value={values.type}
              name="type"
              onChange={handleInputChange}
              error={formError['type']}
              helperText={formError['type']?.message}>
              {programTypes.map(({ value, label }, index) => (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={t('status') + ' *'}
              margin="dense"
              value={values.status}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {getStatus().map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            isEditView ? updateProgram() : saveProgram();
          }}>
          {isEditView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            backToProgramListView();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
