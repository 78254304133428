import { combineReducers } from 'redux';
import { orgReducer } from './orgReducer';
import { catalogReducer } from './catalogReducer';
import { learningActivityReducer } from './learningActivityReducer';
import { loadingReducer } from './loadingReducer';
import { programReducer } from './programReducer';
import { notificationReducer } from './notificationReducer';
import programMapReducer from './programMapReducer';
import departmentsReducer from './departmentReducer';
import termReducer from './termReducer';
import tagReducer from './tagReducer';
import advisorReducer from './advisorReducer';
import studentReducer from './studentReducer';
import { transferEquivalencyReducer } from './transferEquivalencyReducer';
import transferEvaluationReducer from './transferEvaluationReducer';
import { pathwayReducer } from './pathwayReducer';
import authReducer from './authReducer';
import settingsReducer from './settingsReducer';
import { classScheduleReducer } from './classScheduleReducer';
import uiReducer from './uiReducer';

export const reducers = combineReducers({
  auth: authReducer,
  allOrgs: orgReducer,
  allCatalogs: catalogReducer,
  allLearningActivities: learningActivityReducer,
  loadingState: loadingReducer,
  programs: programReducer,
  programMaps: programMapReducer,
  notifications: notificationReducer,
  departments: departmentsReducer,
  terms: termReducer,
  tags: tagReducer,
  advisors: advisorReducer,
  students: studentReducer,
  settings: settingsReducer,
  transferEquivalency: transferEquivalencyReducer,
  transferEvaluation: transferEvaluationReducer,
  pathway: pathwayReducer,
  classSchedules: classScheduleReducer,
  ui: uiReducer,
});
