import { ListItemText, MenuItem, MenuList } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function LAContextMenu(props) {
  const {
    selectedElementPath,
    handleMenuClose,
    setChildVisibility,
    handleModalOpen,
    childVisible,
    hasChild,
    removeTreeNode,
    programMapId,
  } = props;

  const { t } = useTranslation();
  return (
    <MenuList dense>
      {/* Should uncomment these when we implement the funtionality of the menu items  */}
      {/* <MenuItem>
        <ListItemText
          onClick={() => {
            handleModalOpen(selectedElementPath);
            handleMenuClose();
            !(childVisible && hasChild) && setChildVisibility(true);
          }}>
          {t('Link Learning Activity')}
        </ListItemText>
      </MenuItem> */}
      {/* <MenuItem>
        <ListItemText>{t('Link Program Mapping')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Duplicate')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Validate')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Copy')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Cut')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Paste')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Undo')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Redo')}</ListItemText>
      </MenuItem>
      <Divider /> */}
      {_.isNil(programMapId) && (
        <MenuItem>
          <ListItemText
            onClick={() => {
              removeTreeNode(selectedElementPath);
              handleMenuClose();
            }}>
            {t('Remove')}
          </ListItemText>
        </MenuItem>
      )}
    </MenuList>
  );
}
