export const actionTypes = {
  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
  SET_SAVING_STATUS: 'SET_SAVING_STATUS',
  SET_ORGS: 'SET_ORGS',
  SET_ORGS_WITH_SELECTED_ORG: 'SET_ORGS_WITH_SELECTED_ORG',
  SELECT_ORG: 'SELECT_ORG',
  GET_ORGS: 'GET_ORGS',
  GET_ORGS_WITH_SELECTED_ORG: 'GET_ORGS_WITH_SELECTED_ORG',
  POST_ORG: 'POST_ORG',
  PUT_ORG: 'PUT_ORG',
  SET_CATALOGS: 'SET_CATALOGS',
  SET_LATEST_CATALOGS: 'SET_LATEST_CATALOGS',
  SET_LATEST_SOURCE_CATALOG: 'SET_LATEST_SOURCE_CATALOG',
  GET_CATALOGS: 'GET_CATALOGS',
  POST_CATALOG: 'POST_CATALOG',
  PUT_CATALOG: 'PUT_CATALOG',
  DUPLICATE_CATALOG: 'DUPLICATE_CATALOG',
  UPDATE_CATALOGS_ORDER: 'UPDATE_CATALOGS_ORDER',
  SET_LEARNING_ACTIVITIES: 'SET_LEARNING_ACTIVITIES',
  GET_LEARNING_ACTIVITIES: 'GET_LEARNING_ACTIVITIES',
  GET_LEARNING_ACTIVITY: 'GET_LEARNING_ACTIVITY',
  SET_LEARNING_ACTIVITY: 'SET_LEARNING_ACTIVITY',
  SET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES: 'SET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES',
  GET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES: 'GET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES',
  GET_LEARNING_ACTIVITIES_FOR_CATALOG: 'GET_LEARNING_ACTIVITIES_FOR_CATALOG',
  SET_LEARNING_ACTIVITIES_FOR_CATALOG: 'SET_LEARNING_ACTIVITIES_FOR_CATALOG',
  GET_TERMS: 'GET_TERMS',
  SET_TERMS: 'SET_TERMS',
  POST_LEARNING_ACTIVITY: 'POST_LEARNING_ACTIVITY',
  PATCH_LEARNING_ACTIVITY: 'PATCH_LEARNING_ACTIVITY',
  PUT_LEARNING_ACTIVITY: 'PUT_LEARNING_ACTIVITY',
  GET_ASSOCIATED_RULE: 'GET_ASSOCIATED_RULE',
  SET_ERROR: 'SET_ERROR',
  SET_FORM_ERROR: 'SET_FORM_ERROR',
  SET_SUCCESS: 'SET_SUCCESS',
  POST_RULE: 'POST_RULE',
  UPDATE_RULE: 'UPDATE_RULE',
  GET_PROGRAMS: 'GET_PROGRAMS',
  SET_PROGRAMS: 'SET_PROGRAMS',
  GET_PROGRAM: 'GET_PROGRAM',
  GET_PROGRAM_OPTIONS: 'GET_PROGRAM_OPTIONS',
  SET_PROGRAM_OPTIONS: 'SET_PROGRAM_OPTIONS',
  GET_PROGRAM_OPTION: 'GET_PROGRAM_OPTION',
  SET_PROGRAM_OPTION: 'SET_PROGRAM_OPTION',
  CREATE_PROGRAM: 'CREATE_PROGRAM',
  EDIT_PROGRAM: 'EDIT_PROGRAM',
  CREATE_PROGRAM_OPTION: 'CREATE_PROGRAM_OPTION',
  UPDATE_PROGRAM_OPTION: 'UPDATE_PROGRAM_OPTION',
  SET_DEPARTMENTS: 'SET_DEPARTMENTS',
  GET_DEPARTMENTS: 'GET_DEPARTMENTS',
  GET_PROGRAM_MAPS: 'GET_PROGRAM_MAPS',
  SET_PROGRAM_MAPS: 'SET_PROGRAM_MAPS',
  CREATE_PROGRAM_MAP: 'CREATE_PROGRAM_MAP',
  EDIT_PROGRAM_MAP: 'EDIT_PROGRAM_MAP',
  GET_PROGRAM_MAP_REQUIREMENTS: 'GET_PROGRAM_MAP_REQUIREMENTS',
  SET_PROGRAM_MAP_REQUIREMENTS: 'SET_PROGRAM_MAP_REQUIREMENTS',
  CONSTRUCT_PROGRAM_MAP_PATHWAY: 'CONSTRUCT_PROGRAM_MAP_PATHWAY',
  PROGRAM: {
    GET_PROGRAM_MAPPING_DATA: 'GET_PROGRAM_MAPPING_DATA',
    GET_LEARNING_ACTIVITIES_FOR_GROUP: 'GET_LEARNING_ACTIVITIES_FOR_GROUP',
    PATCH_PROGRAM_MAPPING_DATA: 'PATCH_PROGRAM_MAPPING_DATA',
    RENAME_PROGRAM_MAP_GROUP: 'RENAME_PROGRAM_MAP_GROUP',
    ADD_NEW_PROGRAM_MAP_GROUP: 'ADD_NEW_PROGRAM_MAP_GROUP',
    LINK_PROGRAM_MAP_INTO_PROGRAM_MAP: 'LINK_PROGRAM_MAP_INTO_PROGRAM_MAP',
    REMOVE_PROGRAM_MAP_NODE: 'REMOVE_PROGRAM_MAP_NODE',
    MOVE_PROGRAM_MAP_NODE: 'MOVE_PROGRAM_MAP_NODE',
    UPDATE_PROGRAM_MAP_NODE: 'UPDATE_PROGRAM_MAP_NODE',
  },
  COLLEGES: {
    CREATE: 'CREATE_COLLEGES',
    SET: 'SET_COLLEGES',
    EDIT: 'EDIT_COLLEGES',
    GET: 'GET_COLLEGES',
  },
  CREATE_TERMS: 'CREATE_TERMS',
  UPDATE_TERMS: 'UPDATE_TERMS',
  UPDATE_TERMS_ORDER: 'UPDATE_TERMS_ORDER',
  UPDATE_TERMS_INSTANCES: 'UPDATE_TERMS_INSTANCES',
  GET_ACADEMIC_YEARS_WITH_TERM_INSTANCES: 'GET_ACADEMIC_YEARS_WITH_TERM_INSTANCES',
  SET_ACADEMIC_YEARS_WITH_TERM_INSTANCES: 'SET_ACADEMIC_YEARS_WITH_TERM_INSTANCES',
  UPDATE_ACADEMIC_YEARS_WITH_TERM_INSTANCES: 'UPDATE_ACADEMIC_YEARS_WITH_TERM_INSTANCES',
  UPDATE_CURRENT_TERM: 'UPDATE_CURRENT_TERM',
  UPDATE_CALENDAR_YEAR_START_TERM: 'UPDATE_CALENDAR_YEAR_START_TERM',
  GET_CLASS_SCHEDULES: 'GET_CLASS_SCHEDULES',
  SET_CLASS_SCHEDULES: 'SET_CLASS_SCHEDULES',
  CREATE_CLASS_SCHEDULE: 'CREATE_CLASS_SCHEDULE',
  UPDATE_CLASS_SCHEDULE: 'UPDATE_CLASS_SCHEDULE',
  RESET_CLASS_SCHEDULE_LIST: 'RESET_CLASS_SCHEDULE_LIST',
  TAGS: {
    GET: 'GET_TAGS',
    SET: 'SET_TAGS',
  },
  PATHWAY: {
    ADD_TERM: 'ADD_TERM',
    SET_TERM: 'SET_TERM',
    GET_TERMS_ORDER: 'GET_TERMS_ORDER',
    SET_TERMS_ORDER: 'SET_TERMS_ORDER',
    GET_TERM_PLANNING_STATUSES: 'GET_TERM_PLANNING_STATUSES',
    SET_TERM_PLANNING_STATUSES: 'SET_TERM_PLANNING_STATUSES',
    RESET_TERM_PLANNING_STATUSES: 'RESET_TERM_PLANNING_STATUSES',
    CREATE: 'CREATE_PATHWAY',
    GET: 'GET_PATHWAY',
    CREATE_COURSE_USAGE: 'CREATE_PATHWAY_COURSE_USAGE',
    GET_COURSE_USAGE: 'GET_PATHWAY_COURSE_USAGE',
    UPDATE_PATHWAY_ASSIGNMENT: 'UPDATE_PATHWAY_ASSIGNMENT',
    GET_PATHWAYS_TO_EXPLORE: 'GET_PATHWAYS_TO_EXPLORE',
    VALIDATE_PATHWAY: 'VALIDATE_PATHWAY',
    RESET: 'RESET_PATHWAY',
    SET_PATHWAY_STATUSES: 'SET_PATHWAY_STATUSES',
    GET_PATHWAY_STATUSES: 'GET_PATHWAY_STATUSES',
    MARK_COURSE_AS_INCOMPLETE: 'MARK_COURSE_AS_INCOMPLETE',
  },
  STUDENTS: {
    GET: 'GET_STUDENTS',
    SET: 'SET_STUDENTS',
    UPDATE_STUDENT: 'UPDATE_STUDENT',
    SET_SELECTED_STUDENT: 'SET_SELECTED_STUDENT',
    GET_STUDENT_STATUS: 'GET_STUDENT_STATUS',
    SET_STUDENT_STATUS: 'SET_STUDENT_STATUS',
    GET_TEQ_ASSIGNMENTS: 'GET_TEQ_ASSIGNMENTS',
    SET_TEQ_ASSIGNMENTS: 'SET_TEQ_ASSIGNMENTS',
    GET_ORDERED_PATHWAY: 'GET_ORDERED_PATHWAY',
    SET_ORDERED_PATHWAY: 'SET_ORDERED_PATHWAY',
    RESET_ORDERED_PATHWAY: 'RESET_ORDERED_PATHWAY',
    SET_COMPLETED_COURSES: 'SET_COMPLETED_COURSES',
    RESET_COMPLETED_COURSES: 'RESET_COMPLETED_COURSES',
    SEARCH_STUDENTS: 'SEARCH_STUDENTS',
    SET_STUDENT_LIST_QUERY_OPTION: 'SET_STUDENT_LIST_QUERY_OPTION',
    UPDATE_STUDENT_PATHWAY: 'UPDATE_STUDENT_PATHWAY',
    UPDATE_SELECTED_STUDENT: 'UPDATE_SELECTED_STUDENT',
    UPDATE_STUDENT_BY_STUDENT_TEQ_STATUS: 'UPDATE_STUDENT_BY_STUDENT_TEQ_STATUS',
    DELETE_STUDENT_BY_STUDENT_TEQ_STATUS: 'DELETE_STUDENT_BY_STUDENT_TEQ_STATUS',
  },
  TRANSFER_EVALUATION: {
    CREATE: 'CREATE_TRANSFER_EVALUATION',
    EDIT: 'EDIT_TRANSFER_EVALUATION',
    SET: 'SET_TRANSFER_EVALUATION',
    GET_STUDENT_EVALUATIONS: 'GET_STUDENT_EVALUATIONS',
    SET_STUDENT_EVALUATIONS: 'SET_STUDENT_EVALUATIONS',
    GET_STATUS_LIST: 'GET_STATUS_LIST',
    SET_STATUS_LIST: 'SET_STATUS_LIST',
    CREATE_COMPLETED_COURSE_REQ: 'CREATE_COMPLETED_COURSE_REQ',
    UPDATE_COMPLETED_COURSE_REQ: 'UPDATE_COMPLETED_COURSE_REQ',
    GET_COMPLETED_COURSE_REQ: 'GET_COMPLETED_COURSE_REQ',
    SET_COMPLETED_COURSE_REQ: 'SET_COMPLETED_COURSE_REQ',
    GET_EVALUATION_FOR_PRINT: 'GET_EVALUATION_FOR_PRINT',
  },
  TRANSFER_EQUIVALENCY: {
    GET_REQUIREMENTS: 'GET_REQUIREMENTS',
    SET_REQUIREMENTS: 'SET_REQUIREMENTS',
    CREATE: 'CREATE',
    CREATE_WITH_REQUIREMENTS: 'CREATE_WITH_REQUIREMENTS',
    EDIT: 'EDIT',
    GET_LIST: 'GET_LIST',
    SET_LIST: 'SET_LIST',
    DELETE_LIST: 'DELETE_LIST',
    RESET: 'REST',
    CREATE_ASSIGNMENT: 'CREATE_ASSIGNMENT',
    PATCH_ASSIGNMENT: 'PATCH_ASSIGNMENT',
    SET_ASSIGNMENT: 'SET_ASSIGNMENT',
    GET_STATUSES: 'GET_STATUSES',
    SET_STATUSES: 'SET_STATUSES',
    SET_LA_CODES: 'SET_LA_CODES',
    SET_SELECTED_SOURCE_ORG: 'SET_SELECTED_SOURCE_ORG',
    RESET_SELECTED_SOURCE_ORG: 'RESET_SELECTED_SOURCE_ORG',
    RESET_LA_CODES: 'RESET_LA_CODES',
    GET: 'GET',
  },
  REDIRECT_TO_LOGIN: 'REDIRECT_TO_LOGIN',
  GET_USER_INFO: 'GET_USER_INFO',
  SET_USER_INFO: 'SET_USER_INFO',
  SETTINGS: {
    GET: 'GET_SETTINGS',
    SET: 'SET_SETTINGS',
    SAVE: 'SAVE_SETTINGS',
  },
  ORG_CLASS_SCHEDULES: {
    GET: 'GET_ORG_CLASS_SCHEDULES',
    SET: 'SET_ORG_CLASS_SCHEDULES',
    GET_PLANNED_STUDENTS: 'GET_PLANNED_STUDENTS',
    SET_PLANNED_STUDENTS: 'SET_PLANNED_STUDENTS',
    CANCEL_SINGLE: 'CANCEL_SINGLE_CLASS_SCHEDULE',
  },
  ADVISORS: {
    GET: 'GET_ADVISORS',
    SET: 'SET_ADVISORS',
    SET_ADVISOR_FOR_STUDENT: 'SET_ADVISOR_FOR_STUDENT',
  },
  CLASS_SCHEDULES_STATS: {
    CANCEL_SINGLE: 'CANCEL_SINGLE_CLASS_SCHEDULE_STATS',
  },
  REPORTS: {
    FETCH_PATHWAY_STATUS_HISTORY: 'FETCH_PATHWAY_STATUS_HISTORY_REPORT',
    FETCH_COURSE_PROJECTION: 'FETCH_COURSE_PROJECTION_REPORT',
    GENERATE_STATUS_HISTORY: 'GENERATE_STATUS_HISTORY',
  },
  UI: {
    INNER_CONTAINER_WIDTH: 'UI_INNER_CONTAINER_WIDTH',
  },
};
