import React from 'react';
import { Box } from '@mui/system';
import TreeNode from './Tree/TreeNode';

export default function ProgramMapTree({
  data = [],
  searchKeyword,
  addNewGroup,
  selectedElementPath = [],
  onGroupSelect,
  handleModalOpen,
  moveTreeNode,
  removeTreeNode,
  getItemsFromParents,
}) {
  return (
    <Box>
      <div className="d-tree">
        <ul className="d-tree-container">
          {data.map((tree, key) => (
            <TreeNode
              key={key}
              getItemsFromParents={getItemsFromParents}
              searchKeyword={searchKeyword}
              node={tree}
              selectedElementPath={[...selectedElementPath, ...[key]]}
              addNewGroup={addNewGroup}
              onGroupSelect={onGroupSelect}
              handleModalOpen={handleModalOpen}
              moveTreeNode={moveTreeNode}
              removeTreeNode={removeTreeNode}
            />
          ))}
        </ul>
      </div>
    </Box>
  );
}
