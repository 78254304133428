import OrgError from '../../../error/OrgError';
import TransferEvaluationService from '../../../services/TransferEvaluationService';

const transferEvaluationService = new TransferEvaluationService();

export function* requestPostCompletedCourseReq(payload) {
  const { orgId, studentId, body } = payload;

  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield transferEvaluationService.saveCompletedCourseReq(orgId, studentId, body);
}

export function* requestPatchCompletedCourseReq(payload) {
  const { orgId, studentId, reqId, body } = payload;

  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield transferEvaluationService.updateCompletedCourseReq(orgId, studentId, reqId, body);
}

export function* requestGetCompletedCourseReq(payload) {
  const { orgId, studentId } = payload;

  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield transferEvaluationService.getCompletedCourseReq(orgId, studentId);
}

export function* requestGetTransferEvaluationForPrint(payload) {
  const { orgId, studentId, evaluationId } = payload;

  return yield transferEvaluationService.getTransferEvaluationForPrint(
    orgId,
    studentId,
    evaluationId,
  );
}
