import { HttpService } from './HttpService';

export default class SettingService extends HttpService {
  getSettings(org) {
    return this.get(`/organizations/${org}/settings`);
  }

  createSettings(org, body) {
    return this.post(`/organizations/${org}/settings`, body);
  }
}
