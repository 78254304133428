import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import DeclaredSelect from '../DeclareSelect';
import { MenuPropStyles } from '../../helpers/common';
import { getLearningActivitiesForCatalog } from '../../redux/actions/learningActivityActions';
import { LearningActivityCannotIncludeForCreditXIfStatements } from '../../helpers/appConstants';
import DeclareAutocomplete from '../DeclareAutocomplete';

export default function LearningActivityCannotIncludeForCreditX(props) {
  const { selectedItemEditable = true } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orgId, programId } = useParams();
  const formError = useSelector((state) => state.notifications.formError);
  const { byCatalog } = useSelector((state) => state.allLearningActivities);
  const { programMapList } = useSelector((state) => state.programMaps);
  const [learningActivityList, setLearningActivityList] = useState([]);
  const [isRequirementValue, setIsRequirementValue] = useState(false);
  const [courses, setCourses] = useState([]);
  const [ifStatement, setIfStatement] = useState(
    LearningActivityCannotIncludeForCreditXIfStatements[0],
  );
  const { list: learningActivitiesList } = byCatalog;

  useEffect(() => {
    const catalog = getCatalog();
    if (catalog && _.isEmpty(learningActivitiesList)) {
      dispatch(getLearningActivitiesForCatalog(orgId, catalog));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(learningActivitiesList)) {
      setLearningActivityList(learningActivitiesList);
    }
  }, [learningActivitiesList]);

  useEffect(() => {
    setIsRequirementValue(props.data.isRequirement);
    if (
      !_.isNil(props.data.courses) &&
      !_.isNil(props.data.ifStatement) &&
      !_.isEmpty(learningActivityList)
    ) {
      setIfStatement(
        LearningActivityCannotIncludeForCreditXIfStatements.find(
          (conditionValue) => conditionValue.value === props.data.ifStatement,
        ),
      );
      const coursesFromDB = props.data.courses.map((learningActivity) => {
        const laActivity = learningActivityList.find(
          (aLearningActivity) => aLearningActivity.id === learningActivity,
        );
        return laActivity;
      });
      setCourses(coursesFromDB);
    }
  }, [props, learningActivityList]);

  const getCatalog = () => {
    const programMap = programMapList.find(({ id }) => id === programId);
    return programMap.catalogs[0].id;
  };

  const saveRuleData = () => {
    let data = {};
    data.courses = courses.map((learningActivity) => learningActivity.id);
    data.ifStatement = ifStatement.value;
    data.isRequirement = isRequirementValue;
    let selectedElementPath = props.selectedElementPath;
    props.addRuleDataHandler({ data, selectedElementPath });
  };

  const setIsRequirementValueFn = (isReq) => {
    setIsRequirementValue(isReq);
  };

  const handleInputChange = (e) => {
    setCourses(e.target.value);
  };

  const handleInputChangeSelect = (e) => {
    setIfStatement(e);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DeclareAutocomplete
          inputlabel={`${t('If')} *`}
          disableClearable
          getOptionLabel={(option) => option.label + ''}
          value={ifStatement}
          onChange={(_event, value) => {
            handleInputChangeSelect(value);
          }}
          onBlur={saveRuleData}
          options={LearningActivityCannotIncludeForCreditXIfStatements}
          disabled={!selectedItemEditable}
          renderInput={(params) => (
            <TextField {...params} value={params.value} placeholder={t('If')} />
          )}
        />
      </Grid>
      <Grid item xs={6} className="right">
        <DeclaredSelect
          sx={{ width: 200 }}
          multiple
          inputlabel={t('Courses') + ' *'}
          margin="dense"
          value={courses}
          name="courses"
          onChange={handleInputChange}
          onBlur={saveRuleData}
          error={formError['courses']}
          helperText={formError['courses']?.message}
          MenuProps={MenuPropStyles}
          renderValue={(selected) => selected.map((sem) => sem.code + ' ' + sem.name).join(', ')}>
          {learningActivityList.map((item, index) => (
            <MenuItem value={item} key={index}>
              <Checkbox checked={courses.findIndex((sem) => sem.id === item.id) > -1} />
              <Typography whiteSpace={'normal'}>{item.code + ' ' + item.name}</Typography>
            </MenuItem>
          ))}
        </DeclaredSelect>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequirementValue}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setIsRequirementValueFn(e.target.checked);
              }}
              onBlur={saveRuleData}
            />
          }
          label={t('Is a requirement')}
        />
      </Grid>
    </Grid>
  );
}
