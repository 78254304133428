import { Box, Container, IconButton, Typography, Button, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import {
  constructPathwayForProgramMap,
  getProgramMaps,
} from '../../../redux/actions/programMapActions';
import StatusCell from '../../../components/StatusCell';
import EditIcon from '@mui/icons-material/Edit';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import { appRoutes } from '../../../helpers/routes';
import { Construction, AutorenewRounded } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { timeSince } from '../../../helpers/common';
import { allowedUserDomains, ALL_CATALOGS } from '../../../helpers/appConstants';
import _ from 'lodash';
import DeclaredSelect from '../../../components/DeclareSelect';
import { getCatalogs } from '../../../redux/actions/catalogActions';

const useStyles = makeStyles(() =>
  createStyles({
    rotate: {
      animation: '$spin 2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }),
);

export default function ProgramMapList() {
  const classes = useStyles();
  const { orgId } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { programMapList } = useSelector((state) => state.programMaps);
  const { userInfo } = useSelector((state) => state.auth);
  const { catalogs } = useSelector((state) => state.allCatalogs);
  const [searchKey, setSearchKey] = useState('');
  const [processedProgram, setProcessedProgram] = useState({}); // set state of programMap currently building on click
  const [filteredProgramMapList, setFilteredProgramMapList] = useState(programMapList);
  const [userNameDomain, setUserNameDomain] = useState('');
  const [programMapQuery, setProgramMapQuery] = useState(ALL_CATALOGS);

  useEffect(() => {
    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
    dispatch(getProgramMaps(orgId));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      setUserNameDomain(userInfo.username.split('@')[1]);
    }
  }, [userInfo]);

  useEffect(() => {
    var filteredMapList = [];
    if (searchKey != '') {
      const filters = [
        (o) => o.programName.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
        (o) => o.optionName.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredMapList = filteredProgramMapList.filter((o) => filters.some((fn) => fn(o)));
      filteredProgramMapList.forEach((element) => {
        const filterProgramOptions = [
          (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        ];
        let programOptionFiltered = element.catalogs.filter((o) =>
          filterProgramOptions.some((fn) => fn(o)),
        );
        let isExist = filteredMapList.filter((res) => res.id === element.id);
        if (programOptionFiltered.length > 0 && isExist.length === 0) {
          filteredMapList.push(element);
        }
      });
      setFilteredProgramMapList(filteredMapList);
    } else {
      if (programMapQuery !== ALL_CATALOGS) {
        processProgramMapQuery(programMapQuery);
      } else {
        setFilteredProgramMapList(programMapList);
      }
    }
  }, [programMapList, searchKey]);

  useEffect(() => {
    if (!_.isEmpty(catalogs)) {
      processProgramMapQuery(programMapQuery, filteredProgramMapList);
    }
  }, [programMapQuery]);

  const navigateToEditProgramMap = async (data) => {
    navigate(
      generatePath(appRoutes.catalog.PROGRAM_MAP_EDIT, { orgId: orgId, programId: data.id }),
    );
  };

  const navigateToViewProgramMap = async (data) => {
    navigate(
      generatePath(appRoutes.catalog.PROGRAM_MAP_VIEW, { orgId: orgId, programId: data.id }),
    );
  };

  const navigateToNewProgramMap = async () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_MAP_CREATE, { orgId }));
  };

  const generatePathway = (program) => {
    const { id: programMapId } = program;
    const body = { exportToExcel: false };
    setProcessedProgram({ ...processedProgram, [programMapId]: { processing: true } });
    dispatch(constructPathwayForProgramMap(programMapId, body));
  };

  const processProgramMapQuery = (query) => {
    if (query === ALL_CATALOGS) {
      return setFilteredProgramMapList(programMapList);
    }

    const result = programMapList.filter(({ catalogs }) =>
      catalogs.some((data) => data.id === query),
    );

    setFilteredProgramMapList(result);
  };

  const columns = [
    {
      name: 'optionName',
      label: t('Option'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'programName',
      label: t('Program'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'catalogs',
      label: t('Catalog'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return ({ data: prevData }, { data: nextData }) => {
            const previous = prevData.map((val) => val.name).join(', ');
            const next = nextData.map((val) => val.name).join(', ');
            if (order === 'asc') {
              return previous.localeCompare(next);
            } else {
              return next.localeCompare(previous);
            }
          };
        },
        customBodyRender: (value) => {
          const catalogName = value.map((val) => val.name).join(', ');
          return (
            <Typography variant="p" className="ElipsisText" style={{ width: '150px' }}>
              {catalogName}
            </Typography>
          );
        },
      },
    },
    {
      name: 'noOfPathways',
      label: `# ${t('of Pathways')}`,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="p" className="ElipsisText" style={{ width: '150px' }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'lastConstructed',
      label: `${t('Last Constructed')}`,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="p" className="ElipsisText" style={{ width: '150px' }}>
              {value ? `${timeSince(value)} ${t('ago')}` : ''}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        customBodyRender: (value) => {
          const programMap = programMapList.find((p) => p.id === value);
          return (
            <Box style={{ display: 'flex' }}>
              <IconButton
                aria-label="delete"
                size="medium"
                id={value}
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToEditProgramMap(programMap);
                }}>
                <EditIcon fontSize="inherit" color="secondary" />
              </IconButton>
              {programMap.processing || processedProgram[programMap.id]?.processing ? (
                <IconButton
                  aria-label="constructing pathway"
                  size="medium"
                  id={value}
                  onClick={(e) => e.stopPropagation()}>
                  <AutorenewRounded
                    className={classes.rotate}
                    fontSize="inherit"
                    color="secondary"
                  />
                </IconButton>
              ) : userNameDomain === allowedUserDomains.GET_DECLARED_DOMAIN ? (
                <>
                  <IconButton
                    aria-label="construct pathway"
                    size="medium"
                    id={value}
                    onClick={(e) => {
                      e.stopPropagation();
                      generatePathway(programMap);
                    }}>
                    <Construction fontSize="inherit" color="secondary" />
                  </IconButton>
                </>
              ) : null}
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      let id = rowData[6].props.children[0].props.id;
      let index = programMapList.findIndex((x) => x.id === id);
      const program = programMapList[index];
      navigateToViewProgramMap(program);
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Program Maps')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <DeclaredSelect
            margin="dense"
            value={programMapQuery}
            sm={true}
            sx={{ width: '200px' }}
            color="white">
            <MenuItem value={ALL_CATALOGS} onClick={() => setProgramMapQuery(ALL_CATALOGS)}>
              {t(ALL_CATALOGS)}
            </MenuItem>
            {catalogs.map(({ id, name }, index) => (
              <MenuItem value={id} key={index} onClick={() => setProgramMapQuery(id)}>
                {t(name)}
              </MenuItem>
            ))}
          </DeclaredSelect>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              marginLeft: '20px',
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            onClick={() => {
              navigateToNewProgramMap();
            }}>
            {t('New Program Map')}
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredProgramMapList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
