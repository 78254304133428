import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { makeStyles } from '@mui/styles';
import ProgramMapTree from './ProgramMapTree';
import { MenuPropStyles } from '../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import StatusCell from './StatusCell';
import DeclareDataTable from './DeclareDataTable';
import { getLearningActivitiesForCatalog } from '../redux/actions/learningActivityActions';
import { useParams } from 'react-router-dom';
import SearchTextBox from './SearchTextBox';
import { PROGRAM_MAP_TYPE } from '../helpers/programMapHelper';

const useStyle = makeStyles(() => ({
  root: {
    position: 'absolute',
    '& .EllipsisText': {
      display: 'inline-block',
      width: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      float: 'left',
    },
  },
  paper: {
    overflow: 'auto',
    position: 'absolute',
    background: 'white',
    left: 20,
    right: 20,
    top: 20,
    bottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 20px 0px 20px',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  programMapUnits: {
    '& .EllipsisText': {
      fontWeight: '200',
      display: 'inline-block',
      width: 'fit-content',
    },
  },
}));

//TODO: Combine menu items into one component and refactor

export default function ProgramMapTreeContainer({
  programName = '',
  optionName = '',
  units = '',
  ...props
}) {
  const {
    mapData,
    setSearchKeyword,
    searchKeyword,
    addNewGroup,
    onGroupSelect,
    catalog,
    moveTreeNode,
    removeTreeNode,
    getItemsFromParents,
    updateLaForProgramMapGroups,
  } = props;
  const learningActivitiesByCatalog = useSelector((state) => state.allLearningActivities.byCatalog);
  const learningActivityList = learningActivitiesByCatalog.list;
  const [selectedLearningActivities, setSelectedLearningActivities] = useState([]);
  const [originalSelectedLearningActivities, setOriginalLSelectedLearningActivities] = useState([]);
  const [allSelectedLa, setAllSelectedLa] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedElementPath, setSelectedElementPath] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [laRowsSelected, setLaRowsSelected] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyle();
  const { orgId } = useParams();
  const [searchKey, setSearchKey] = useState('');
  const [filteredLAList, setFilteredLAList] = useState([]);
  const [selectedLAsFilterEnabled, setSelectedLAsFilterEnabled] = useState(false);
  const [currentParent, setCurrentParent] = useState(null);

  useEffect(() => {
    const shouldLoadLasBelongToCatalog =
      learningActivitiesByCatalog.catalog != catalog || learningActivityList.length === 0;
    if (catalog && shouldLoadLasBelongToCatalog) {
      dispatch(getLearningActivitiesForCatalog(orgId, catalog));
    }
  }, [catalog]);

  useEffect(() => {
    let laList = learningActivityList;
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.code.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      laList = laList.filter((o) => filters.some((fn) => fn(o)));
    }
    if (selectedLAsFilterEnabled) {
      laList = laList.filter(
        ({ id }) => selectedLearningActivities.findIndex((la) => la.id === id) != -1,
      );
    }
    setFilteredLAList(laList);
  }, [learningActivityList, searchKey, selectedLAsFilterEnabled, selectedLearningActivities]);

  useEffect(() => {
    const indexes = selectedLearningActivities.map(({ id }) =>
      filteredLAList.findIndex((la) => la.id === id),
    );
    setLaRowsSelected(indexes);
  }, [filteredLAList, selectedLearningActivities]);

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = (correspondingNode) => (path) => {
    const { items, id } = correspondingNode;
    const allLas = items.filter(({ type }) => type === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY);
    setCurrentParent(correspondingNode);
    setSelectedLearningActivities(allLas);
    setOriginalLSelectedLearningActivities(allLas);
    setSelectedElementPath(path);
    setSelectedLAsFilterEnabled(false);
    setFilteredLAList(learningActivityList);
    setSearchKey('');
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setAllSelectedLa([]);
    setOriginalLSelectedLearningActivities([]);
    setSelectedElementPath([]);
    setModalOpen(false);
  };

  const handleSelectedLAsLink = () => {
    setSelectedLAsFilterEnabled(true);
  };

  const columns = [
    {
      name: 'name',
      label: t('Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'code',
      label: t('Code'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'status',
      label: t('Status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    tableBodyMaxHeight: '580px',
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'none',
    rowsSelected: laRowsSelected,
    onRowSelectionChange: (currentRowsSelected, _allRowsSelected, rowsSelected) => {
      const hiddenSelectedLAs = selectedLearningActivities.filter(
        ({ id }) => filteredLAList.findIndex((la) => la.id === id) === -1,
      );
      const allLa = rowsSelected.map((index) => generateLaData(index)).concat(hiddenSelectedLAs);
      setLaRowsSelected(rowsSelected);
      setSelectedLearningActivities(allLa);
    },
  };

  const generateLaData = (index) => ({
    type: PROGRAM_MAP_TYPE.LEARNING_ACTIVITY,
    id: filteredLAList[index].id,
    name: filteredLAList[index].name,
    code: filteredLAList[index].code,
    isRequirement: false,
    message: '',
    deselected: false,
  });

  const handleChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const resetSearchStatus = () => {
    setSearchVisible(false);
    setSearchKeyword('');
  };

  const updateLAsForProgramMap = () => {
    const addedList = selectedLearningActivities
      .filter(
        ({ id: selectedId }) =>
          !originalSelectedLearningActivities.some(({ id }) => selectedId === id),
      )
      .map((data) => ({ ...data, deselected: false, order: currentParent.order }));

    const removedList = originalSelectedLearningActivities
      .filter(
        ({ id: selectedId }) => !selectedLearningActivities.some(({ id }) => selectedId === id),
      )
      .map((data) => ({ ...data, deselected: true }));
    updateLaForProgramMapGroups([...addedList, ...removedList], currentParent.id);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ margin: 2 }} className={classes.root}>
        {programName && (
          <Box
            sx={{
              width: '600px',
              marginBottom: 1,
              display: searchVisible ? 'none' : 'flex',
            }}>
            <Typography title={programName} className="EllipsisText" variant="h5" fontWeight="800">
              {programName},
            </Typography>
            <Typography title={optionName} className="EllipsisText" variant="h5" fontWeight="800">
              {optionName}
            </Typography>
            <Typography className={classes.programMapUnits} variant="h5">
              {units} {t('Units')}
            </Typography>
            <Box sx={{ display: 'inline-block', float: 'left' }}>
              <IconButton
                sx={{ padding: '5px', display: 'inline-block', float: 'left' }}
                aria-label="more"
                id="long-button"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ padding: '5px' }}
                aria-label="remove"
                onClick={() => {
                  setSearchVisible(true);
                }}>
                <SearchIcon fontSize="medium" />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              PaperProps={MenuPropStyles}>
              <Paper sx={{ border: '1px solid', borderColor: 'green' }}>
                <MenuList dense>
                  <MenuItem
                    onClick={() => {
                      addNewGroup();
                      handleMenuClose();
                    }}>
                    <ListItemText>{t('New Group')}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      addNewGroup([], true);
                      handleMenuClose();
                    }}>
                    <ListItemText>{t('Link Program Map')}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Menu>
          </Box>
        )}

        <Box sx={{ width: '355px', marginBottom: 1, display: searchVisible ? 'flex' : 'none' }}>
          <TextField
            onChange={handleChange}
            placeholder="Search"
            value={searchKeyword}
            sx={{ width: '300px' }}></TextField>
          <IconButton
            sx={{ padding: '5px', margin: '10px' }}
            aria-label="remove"
            onClick={resetSearchStatus}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={modalOpen}
          onClose={handleModalClose}
          style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Slide direction="left" in={modalOpen} mountOnEnter unmountOnExit>
            <div className={classes.paper}>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="h4" color="primary">
                  {t('Link Learning Activities')}
                </Typography>
                <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
                <FilterAltIcon fontSize="large" color="secondary" />
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="h6"
                  color="grey"
                  onClick={selectedLearningActivities.length != 0 && handleSelectedLAsLink}
                  className={selectedLearningActivities.length != 0 ? classes.clickable : ''}>
                  {`${selectedLearningActivities.length} ${t('Learning Activities Selected')}`}
                </Typography>
                {selectedLAsFilterEnabled && (
                  <IconButton
                    sx={{ padding: '5px' }}
                    aria-label="remove"
                    onClick={() => {
                      setSelectedLAsFilterEnabled(false);
                    }}>
                    <CancelOutlinedIcon />
                  </IconButton>
                )}
              </Box>
              <Box sx={{ mt: 4, mb: 4 }}>
                <DeclareDataTable data={filteredLAList} columns={columns} options={options} />
              </Box>
              <Box sx={{ float: 'right', marginBottom: '10px' }}>
                <Button
                  disableElevation
                  variant="contained"
                  color="secondary"
                  sx={{
                    height: '45px',
                    borderRadius: '20px',
                    textTransform: 'capitalize',
                    fontWeight: 300,
                    width: '150px',
                    marginRight: '20px',
                  }}
                  onClick={() => {
                    updateLAsForProgramMap();
                    handleModalClose();
                  }}>
                  {t('Add Links')}
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="grey"
                  sx={{
                    height: '45px',
                    borderRadius: '20px',
                    textTransform: 'capitalize',
                    fontWeight: 300,
                    width: '150px',
                    color: '#fff',
                  }}
                  onClick={() => {
                    handleModalClose();
                  }}>
                  {t('cancel')}
                </Button>
              </Box>
            </div>
          </Slide>
        </Modal>
        {programName && (
          <ProgramMapTree
            data={mapData.items}
            getItemsFromParents={getItemsFromParents}
            searchKeyword={searchKeyword}
            addNewGroup={addNewGroup}
            selectedElementPath={selectedElementPath}
            onGroupSelect={onGroupSelect}
            handleModalOpen={handleModalOpen}
            moveTreeNode={moveTreeNode}
            removeTreeNode={removeTreeNode}
          />
        )}
      </Box>
    </Box>
  );
}
