import React from 'react';
import { Box, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import DeclareToolTip from './DeclareToolTip';

function TabPanel(props) {
  const { children, activeTabIndex, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={activeTabIndex !== index}
      id={`declare-tabpanel-${index}`}
      aria-labelledby={`declare-tab-${index}`}
      {...other}>
      {activeTabIndex === index && <Box>{children}</Box>}
    </div>
  );
}

function commonProps(index) {
  return {
    id: `declare-tab-${index}`,
    'aria-controls': `declare-tabpanel-${index}`,
  };
}

export default function TabView(props) {
  const { tabs, selectedTab = 0, enableEllipsisText = false, warningMsg } = props;
  const [activeTabIndex, setActiveTabIndex] = React.useState(selectedTab);

  const handleChange = (_event, newIndex) => {
    setActiveTabIndex(newIndex);
    const tab = tabs[newIndex];
    tabs[newIndex].callBack?.(tab.tabId);
  };

  const getTabComponent = (tab, index) => {
    return tab ? (
      enableEllipsisText ? (
        <Tooltip title={tab.name} arrow key={index}>
          <Tab
            icon={
              tab.showWarning ? (
                <DeclareToolTip
                  title={
                    <Typography
                      color="common.black"
                      fontSize="small"
                      fontWeight={400}
                      marginLeft={3}>
                      {warningMsg}
                    </Typography>
                  }
                  key={index}>
                  <Warning color="error" />
                </DeclareToolTip>
              ) : null
            }
            iconPosition="end"
            label={
              <Box
                sx={{
                  display: 'inline-block',
                  width: '200px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  float: 'left',
                }}>
                {tab.name}
              </Box>
            }
            {...commonProps(index)}
          />
        </Tooltip>
      ) : (
        <Tab
          icon={
            tab.showWarning ? (
              <DeclareToolTip
                title={
                  <Typography color="common.black" fontSize="small" fontWeight={400} marginLeft={3}>
                    {warningMsg}
                  </Typography>
                }
                key={index}>
                <Warning color="error" />
              </DeclareToolTip>
            ) : null
          }
          iconPosition="start"
          label={tab.name}
          {...commonProps(index)}
        />
      )
    ) : null;
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', background: 'white' }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="Declare tab view">
          {tabs.map((tab, index) => getTabComponent(tab, index))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} activeTabIndex={activeTabIndex} index={index}>
          {tab?.component}
        </TabPanel>
      ))}
    </>
  );
}
