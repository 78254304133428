import _ from 'lodash';
import { PROGRAM_MAP_TYPE } from './programMapHelper';

const treeTypes = {
  data: 'data', // for Rules
  items: 'items', // for ProgramMaps
};

/*
moveTreeItem is responsible for handling the drag and drop functionality of the application
treeData -> actual JSON representation of the data that we need to manipulate
dragPath -> array of indexes to represent the dragged element path of the JSON -> [0] or [0,0] (sample array)
dropPath -> array of indexes to represent the dropped element path of the JSON -> [0] or [0,0] (sample array) can be empty for Rules []
droppedPosition -> its helps us to determine where the items dropped relative to the element location
droppedPosition can have three values
droppedPosition = 1 -> means dropped Item drop above respective to the element
droppedPosition = 0 -> means dropped Item drop on top of the respective element
droppedPosition = -1 -> means dropped Item drop below respective to the element
*/
export const moveTreeItem = (treeData, dragPath, dropPath, droppedPosition) => {
  let mapData = _.cloneDeep(treeData);

  let prevParentId = null;
  let newParentId = null;

  // determining the type of data that receiving
  let dataAttributeKey = !_.isUndefined(mapData.data) ? treeTypes.data : treeTypes.items;
  let isNotAGroup = true;
  let isLearningActivityCanDropOnRoot = false;

  // Checking if the dropPath is a group or not | for Rules
  if (dataAttributeKey == treeTypes.data) {
    isNotAGroup = !dropPath.reduce((parent, index) => parent.data[index], treeData)?.operator;
  } else {
    // Checking if the dropPath is a group or not | for programMap
    isNotAGroup =
      dropPath.reduce((parent, index) => parent.items[index], treeData)?.type ==
      PROGRAM_MAP_TYPE.LEARNING_ACTIVITY;
    const dragItemNotAGroup =
      dragPath.reduce((parent, index) => parent.items[index], treeData)?.type ==
      PROGRAM_MAP_TYPE.LEARNING_ACTIVITY;

    isLearningActivityCanDropOnRoot =
      droppedPosition != 0 && dropPath.length == 1 && dragItemNotAGroup;
  }

  // dropPath can be equal to an empty array of Rules since the ruleWrapperContainer also a valid drop target
  // abort doing anything and return the original JSON back to the UI
  if (
    (droppedPosition == 0 && isNotAGroup) ||
    _.isEmpty(dropPath) ||
    isLearningActivityCanDropOnRoot
  ) {
    return { prevParentId, newParentId, mapData };
  }

  let toPath = dropPath;
  if (droppedPosition == 1) {
    // dropped after
    ++toPath[toPath.length - 1];
  }
  let parentOfDraggedItem = mapData;
  let draggedItem = null;

  // remove dragged item from the tree
  for (let pathIndex = 0; pathIndex < dragPath.length; pathIndex++) {
    if (pathIndex == dragPath.length - 1) {
      draggedItem = parentOfDraggedItem[dataAttributeKey][dragPath[pathIndex]];
      parentOfDraggedItem[dataAttributeKey].splice(dragPath[pathIndex], 1);
      prevParentId = parentOfDraggedItem.id || parentOfDraggedItem.uuid;
      if (toPath.length - 1 >= pathIndex && toPath[pathIndex] > dragPath[pathIndex]) {
        --toPath[pathIndex];
      }
    } else {
      parentOfDraggedItem = parentOfDraggedItem[dataAttributeKey][dragPath[pathIndex]];
    }
  }

  // insert the dragged item into the dropped location of the tree
  let parentOfDroppedItem = mapData;
  for (let pathIndex = 0; pathIndex < toPath.length; pathIndex++) {
    if (pathIndex == toPath.length - 1) {
      if (droppedPosition != 0) {
        parentOfDroppedItem[dataAttributeKey].splice(toPath[pathIndex], 0, draggedItem);
        newParentId = parentOfDroppedItem.id || parentOfDroppedItem.uuid;
      } else {
        parentOfDroppedItem[dataAttributeKey][toPath[pathIndex]][dataAttributeKey].push(
          draggedItem,
        );
        newParentId =
          parentOfDroppedItem[dataAttributeKey][toPath[pathIndex]].id ||
          parentOfDroppedItem[dataAttributeKey][toPath[pathIndex]].uuid;
      }
    } else {
      parentOfDroppedItem = parentOfDroppedItem[dataAttributeKey][toPath[pathIndex]];
    }
  }

  return { prevParentId, newParentId, mapData };
};

export const searchTree = (items = [], searchKeyword) => {
  return items
    .map((res) => {
      const returnedItems = searchTree(res.items, searchKeyword);
      return { ...res, items: returnedItems };
    })
    .filter(
      (item) =>
        item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        (item.code ? item.code.toLowerCase().includes(searchKeyword.toLowerCase()) : null) ||
        (item.items ? item.items.length > 0 : []),
    );
};

export const addProgramMapToLinkProgramMaps = (items = [], programMap = null) => {
  return items.map((res) => {
    if (_.isNil(res.programMapId)) {
      if (!_.isNil(programMap)) {
        res.isParentNodeProgramMap = false;
        res.programMapId = programMap;
        const returnItems = addProgramMapToLinkProgramMaps(res.items, programMap);
        return { ...res, items: returnItems };
      } else {
        const returnItems = addProgramMapToLinkProgramMaps(res.items, res.programMapId);
        return { ...res, items: returnItems };
      }
    } else if (!_.isNil(res.programMapId)) {
      res.isParentNodeProgramMap = true;
      if (!_.isNil(programMap)) {
        res.isParentNodeProgramMap = false;
      }
      const returnItems = addProgramMapToLinkProgramMaps(res.items, res.programMapId);
      return { ...res, items: returnItems };
    }
  });
};

export const updateObjectsProgramMap = (mapData) => {
  const updateResult = addProgramMapToLinkProgramMaps(mapData.items);
  return { items: updateResult };
};

export const addItemsToParent = (mapData, dataFromDB, parent) => {
  return mapData.items.map((data) => {
    if (data.id === parent) {
      const mapItem = data.items.concat(dataFromDB);
      return { ...data, items: mapItem };
    }

    if (!_.isEmpty(data.items)) {
      const mapData = addItemsToParent(data, dataFromDB, parent);
      return { ...data, items: mapData };
    } else {
      return data;
    }
  });
};

export const removeItemsFromParent = (mapData, deselectedItems, parent) => {
  return mapData.items.map((data) => {
    if (data.id === parent) {
      const mapItem = data.items.filter(
        (child) => !deselectedItems.some(({ id }) => child.id === id),
      );
      return { ...data, items: mapItem };
    }

    if (!_.isEmpty(data.items)) {
      const mapData = removeItemsFromParent(data, deselectedItems, parent);
      return { ...data, items: mapData };
    } else {
      return data;
    }
  });
};
