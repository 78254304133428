import { Box, Button, Container, Typography } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import StatusCell from '../../../components/StatusCell';
import { CLASS_SCHEDULE_STATUS, deliveryModes } from '../../../helpers/appConstants';
import { appRoutes } from '../../../helpers/routes';
import DeclareDialog from '../../../components/DeclareDialog';
import {
  cancelSingleClassSchedule,
  getOrgPlannedStudents,
} from '../../../redux/actions/classScheduleActions';
import { setCompletedCourseReq } from '../../../redux/actions/transferEvaluationActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { formatName, studentsListAggregator } from '../../../helpers/studentsHelper';
import {
  getStudentStatus,
  getStudentTeqAssignments,
  setCompletedCourses,
  setCurrentSelectedStudent,
} from '../../../redux/actions/studentActions';
import { updateCancelClassScheduleStats } from '../../../redux/actions/classScheduleStatsActions';
import { getIdentityEmail, getCurrentCatalog } from '../../../helpers/studentsHelper';
import { setError } from '../../../redux/actions/notificationActions';
import { getAcademicYearsWithTermInstances, getAllTerms } from '../../../redux/actions/termActions';
import { getCatalogs, setLatestCatalog } from '../../../redux/actions/catalogActions';

const useStyles = makeStyles((theme) => ({
  contentWrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  csCard: {
    borderBottom: 1,
    borderColor: 'divider',
    background: 'white',
    borderRadius: theme.spacing(1),
  },
  csWrapper: {
    display: 'flex',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
  },
  studentListWrapper: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
}));
export default function ClassScheduleView() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, lActivityId: courseId, termInstanceId, deliveryMode } = useParams();
  const { studentStatusList } = useSelector((state) => state.students);
  const { termList, academicYears, termInstances } = useSelector((state) => state.terms);
  const programMaps = useSelector((state) => state.programMaps.programMapList);
  const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
  const [rawStudentList, setRawStudentList] = useState([]);
  const [plannedStudents, setPlannedStudents] = useState([]);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const [filteredStudentList, setFilteredStudentList] = useState([]);

  const {
    state: {
      lActivity: { code, name: laName },
      status,
      duration,
      id: classScheduleId,
    },
  } = useLocation();

  const [classScheduleStatus, setClassScheduleStatus] = useState(status);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }
  }, [termList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty(termInstances) && !_.isNil(termInstanceId)) {
      dispatch(
        getOrgPlannedStudents(
          orgId,
          termInstances[termInstanceId]?.termId,
          termInstances[termInstanceId]?.calendarYear,
          courseId,
          handleResponse,
        ),
      );
    }
  }, [termInstances, termInstanceId]);

  useEffect(() => {
    if (_.isEmpty(programMaps)) {
      dispatch(getProgramMaps(orgId));
    }

    if (_.isEmpty(studentStatusList)) {
      dispatch(getStudentStatus(orgId));
    }

    if (_.isEmpty(termList)) {
      dispatch(getAllTerms(orgId));
    }

    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  const handleResponse = (data) => {
    setRawStudentList(data);
  };

  useEffect(() => {
    if (!_.isEmpty(programMaps) && !_.isEmpty(studentStatusList) && !_.isEmpty(catalogs)) {
      const { id: catalogId } = latestCatalog;
      const filteredPM = programMaps.filter(({ catalogs }) =>
        catalogs.find((data) => data.id === catalogId),
      );
      const studentData = studentsListAggregator(rawStudentList, filteredPM, programMaps);
      setPlannedStudents(studentData);
      setFilteredStudentList(studentData);
    }
  }, [programMaps, rawStudentList, studentStatusList, catalogs]);

  useEffect(() => {
    searchList();
  }, [searchKey]);

  const callBackClassSchedules = (response) => {
    if (!_.isEmpty(response)) {
      setClassScheduleStatus(response.status);
      const studentList = filteredStudentList.map((student) => {
        return { studentId: student.studentId };
      });

      const data = {
        userEmail: getIdentityEmail(userInfo.username),
        students: studentList,
        classScheduleId: classScheduleId,
        status: response.status,
      };
      dispatch(updateCancelClassScheduleStats({ orgId, data }));
      navigate('.', {
        state: {
          lActivity: { code, name: laName },
          status: response.status,
          duration,
          id: classScheduleId,
        },
      });
    }
  };

  const cancelClassSchedule = () => {
    setCancelDialogOpen(false);
    const data = {
      lActivityId: courseId,
      termInstanceId,
      deliveryMode,
      duration,
      status: CLASS_SCHEDULE_STATUS.CANCEL.value,
    };
    dispatch(cancelSingleClassSchedule({ orgId, classScheduleId, data }, callBackClassSchedules));
  };

  const activateClassSchedule = () => {
    const data = {
      lActivityId: courseId,
      termInstanceId,
      deliveryMode,
      duration,
      status: CLASS_SCHEDULE_STATUS.ACTIVE.value,
    };
    dispatch(cancelSingleClassSchedule({ orgId, classScheduleId, data }, callBackClassSchedules));
  };

  const navigateToStudentPlanning = () => {
    navigate(generatePath(appRoutes.advisor.STUDENT_PLANNING, { orgId: orgId }));
  };

  const handleRowClick = (data) => {
    const studentId = data[0].props.children;
    const student = plannedStudents.find((data) => data.studentId === studentId);
    const currentStudent = {
      ...student,
      currentCatalog: getCurrentCatalog(
        programMaps,
        latestCatalog,
        student.programMapId,
        catalogs,
        data.catalogId,
      ),
    };
    dispatch(setCompletedCourseReq([]));
    dispatch(setCompletedCourses({ Completed: [], Transferred: [], Exempt: [] }));
    dispatch(setCurrentSelectedStudent(currentStudent));
    dispatch(getStudentTeqAssignments(orgId, currentStudent));
    navigateToStudentPlanning();
  };

  const searchList = () => {
    if (searchKey !== '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.studentId.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => {
          const name = formatName(o.advisor.identity);
          return name.toLowerCase().includes(searchKey.toLowerCase());
        },
        (o) => o.programOption.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.programOption.code.toLowerCase().includes(searchKey.toLowerCase()),
      ];

      const data = plannedStudents.filter((o) => filters.some((fn) => fn(o)));
      return setFilteredStudentList(data);
    } else {
      setFilteredStudentList(plannedStudents);
    }
  };

  const columns = [
    {
      name: 'studentId',
      label: t('ID'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'name',
      label: t('Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (name) => {
          return <Typography variant="body2">{name}</Typography>;
        },
      },
    },
    {
      name: 'advisor',
      label: t('Advisor'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return ({ data: prevData }, { data: nextData }) => {
            const prevName = formatName(prevData.identity);
            const nextName = formatName(nextData.identity);
            if (order === 'asc') {
              return prevName.localeCompare(nextName);
            } else {
              return nextName.localeCompare(prevName);
            }
          };
        },
        customBodyRender: (value) => {
          const name = formatName(value.identity);
          return <Typography variant="body2">{name}</Typography>;
        },
      },
    },
    {
      name: 'programOption.code',
      label: t('Program Option'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (currentOption, nextOption) => {
            if (order === 'asc') {
              if (_.isUndefined(currentOption.data)) {
                return -1;
              } else if (_.isUndefined(nextOption.data)) {
                return 0;
              }
              return currentOption.data.localeCompare(nextOption.data);
            } else {
              if (_.isUndefined(nextOption.data)) {
                return -1;
              } else if (_.isUndefined(currentOption.data)) {
                return 0;
              }
              return nextOption.data.localeCompare(currentOption.data);
            }
          };
        },
        customBodyRender: (code) => {
          return <Typography variant="body2">{code}</Typography>;
        },
      },
    },
    {
      name: 'programOption.name',
      label: t('Program Option Name'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (currentOption, nextOption) => {
            if (order === 'asc') {
              if (_.isUndefined(currentOption.data)) {
                return -1;
              } else if (_.isUndefined(nextOption.data)) {
                return 0;
              }
              return currentOption.data.localeCompare(nextOption.data);
            } else {
              if (_.isUndefined(nextOption.data)) {
                return -1;
              } else if (_.isUndefined(currentOption.data)) {
                return 0;
              }
              return nextOption.data.localeCompare(currentOption.data);
            }
          };
        },
        customBodyRender: (name) => {
          return <Typography variant="body2">{name}</Typography>;
        },
      },
    },
    {
      name: 'plannedStatus',
      label: t('Planned'),
      options: {
        filter: true,
        sort: true,
        sortCompare: (order) => {
          return (firstPlannedStatus, nextPlannedStatus) => {
            if (order === 'asc') {
              return firstPlannedStatus.data.type.localeCompare(nextPlannedStatus.data.type);
            } else {
              return nextPlannedStatus.data.type.localeCompare(firstPlannedStatus.data.type);
            }
          };
        },
        customBodyRender: ({ type, name }) => {
          return <StatusCell value={type} name={name} />;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    enableNestedDataAccess: '.',
    onRowClick: handleRowClick,
  };

  const checkFilteredStudentsForClassSchedule = () => {
    if (_.isEmpty(filteredStudentList)) {
      setCancelDialogOpen(true);
    } else {
      dispatch(
        setError({
          message: t(
            'You must update student’s pathways to not include this class schedule before it can be canceled.',
          ),
        }),
      );
    }
  };

  const csListRoute = generatePath(appRoutes.advisor.GLOBAL_CLASS_SCHEDULE_LIST, { orgId });
  return (
    <Container maxWidth="xl">
      <DeclareDialog
        header={t('Are you sure you want to cancel this class schedule?')}
        description={`${rawStudentList.length} ${t('student will need to be replanned.')}`}
        yesLabel={t('Yes')}
        noLabel={t('No')}
        open={cancelDialogOpen}
        onClose={() => {}}
        handleYesClick={() => {
          cancelClassSchedule();
        }}
        handleNoClick={() => {
          setCancelDialogOpen(false);
        }}
        cancelBtnColor="grey"
      />
      <Box sx={{ marginTop: '100px' }}>
        <Box className={classes.contentWrapper}>
          <DeclareBreadcrumb
            items={[{ name: t('Class Schedules'), customRoute: csListRoute }, code]}
          />

          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            onClick={() => {
              classScheduleStatus === CLASS_SCHEDULE_STATUS.CANCEL.value
                ? activateClassSchedule()
                : checkFilteredStudentsForClassSchedule();
            }}>
            {classScheduleStatus === CLASS_SCHEDULE_STATUS.CANCEL.value
              ? t('Activate Class Schedule')
              : t('Cancel Class Schedule')}
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Box className={classes.csCard}>
            <Box className={classes.csWrapper}>
              <Stack flex={6}>
                <Typography fontWeight={800} sx={{ mb: 2 }}>
                  {t('Course Code')}
                </Typography>
                <Typography sx={{ mb: 2 }}>{code}</Typography>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Year')}
                </Typography>
                <Typography sx={{ mb: 2 }}>{termInstances[termInstanceId]?.name}</Typography>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Delivery Mode')}
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  {
                    deliveryModes.find(({ value }) => {
                      return value === deliveryMode;
                    }).label
                  }
                </Typography>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Status')}
                </Typography>
                <StatusCell
                  sx={{ mb: 2 }}
                  value={classScheduleStatus}
                  name={CLASS_SCHEDULE_STATUS[classScheduleStatus].label}
                />
              </Stack>
              <Stack flex={6}>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Course Name')}
                </Typography>
                <Typography sx={{ mb: 2 }}>{laName}</Typography>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Term')}
                </Typography>
                <Typography sx={{ mb: 2 }}>{termInstances[termInstanceId]?.displayName}</Typography>
                <Typography sx={{ mb: 2 }} fontWeight={800}>
                  {t('Duration')}
                </Typography>
                <Typography sx={{ mb: 2 }}>
                  {duration} {duration > 1 ? t('Consecutive Terms') : t('Consecutive Term')}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={classes.studentListWrapper}>
            <DeclareBreadcrumb items={[t('Planned Students')]} />
            <Box sx={{ display: 'flex', flex: 0.3 }}>
              <SearchTextBox mr={0} ml={2} searchKey={searchKey} setSearchKey={setSearchKey} />
            </Box>
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <DeclareDataTable data={filteredStudentList} columns={columns} options={options} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
