import { actionTypes } from '../constants/actionTypes';

export const setTags = (programs) => {
  return {
    type: actionTypes.TAGS.SET,
    payload: programs,
  };
};

export const getTags = (params) => {
  return {
    type: actionTypes.TAGS.GET,
    payload: params,
  };
};
