import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { getStatus, departments, declarableValues } from '../../../helpers/appConstants';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import TermForm from './TermForm';

export default function EditCreateDepartment() {
  const { t } = useTranslation();
  const { orgId, termId } = useParams();
  const dispatch = useDispatch();
  const isEditView = termId !== undefined;

  const [currentDepartment, setCurrentDepartment] = useState({
    name: '',
    status: getStatus()[3].value,
    type: departments[0].value,
    regularTerm: declarableValues[0].value,
  });

  const [breadCrumbName, setBreadCrumbName] = useState(isEditView ? '' : t('New term'));

  const departmentList = useSelector((state) => state.terms.termList);

  useEffect(() => {
    if (isEditView) {
      if (departmentList.length === 0) {
        dispatch(getTerms(orgId));
      } else {
        const selectedDepartment = departmentList.find(
          (departmentMap) => departmentMap.id === termId,
        );
        setBreadCrumbName(selectedDepartment.name);
        setCurrentDepartment({
          name: selectedDepartment.name,
          status: selectedDepartment.status,
          type: selectedDepartment.type,
          regularTerm: selectedDepartment.regularTerm,
        });
      }
    }
  }, [departmentList]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px', marginBottom: '' }}>
        <Box
          sx={{ display: 'flex', justifyContent: '', alignItems: 'center', marginBottom: '20px' }}>
          <DeclareBreadcrumb items={[{ name: t('Terms ') }, breadCrumbName]} />
        </Box>
        {<TermForm currentDepartment={currentDepartment} isEditView={isEditView} />}
      </Box>
    </Container>
  );
}
