import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttachEmail, Print, MoreVert, Replay } from '@mui/icons-material';
import CustomChip from './CustomChip';
import DeclaredSelect from '../../DeclareSelect';
import {
  explorePathwayOptions,
  StudentViewTabMenu,
  TermOption,
  studentPathwayStatusTypes,
} from '../../../helpers/appConstants';
import { makeStyles } from '@mui/styles';
import DeclareDialog from '../../DeclareDialog';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  getFormattedOrderedPathwayData,
  getFormattedStudentCompletedTermsFromOrderedPathway,
  doesOrderedPathwayOverlapsWithCompletedTerms,
} from '../../../helpers/pathwayHelper';

const useStyles = makeStyles((theme) => ({
  tabComponent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  actionBtn: {
    marginRight: `${theme.spacing(1)} !important`,
  },
}));

function TabComponent({ setActiveTab, activeTab, handlePdfPrint, handleResetPathway }) {
  const [termOptions, setTermOption] = useState('');
  const [exploreOption, setExploreOption] = useState(explorePathwayOptions[0].value);
  const [active, setActive] = useState(StudentViewTabMenu[1].value);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [resetBtnDisable, setResetBtnDisable] = useState(true);
  const [printBtnDisable, setPrintBtnDisable] = useState(true);
  const { pathwayStatus } = useSelector((state) => state.pathway);
  const { termList } = useSelector((state) => state.terms);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { studentOrderedPathway, currentSelectedStudent, studentCompletedTerms } = useSelector(
    (state) => state.students,
  );
  const { orgId } = useParams();
  const { t } = useTranslation();

  const handleOnClick = (value) => {
    setActive(value);
    setActiveTab(value);
  };

  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  useEffect(() => {
    switch (currentSelectedStudent?.plannedStatus?.type) {
      case studentPathwayStatusTypes.NEW:
        setPrintBtnDisable(true);
        setResetBtnDisable(true);
        break;
      case studentPathwayStatusTypes.PATHWAY_INVALID:
        setPrintBtnDisable(true);
        setResetBtnDisable(false);
        break;
      default:
        setPrintBtnDisable(false);
        setResetBtnDisable(false);
    }
  }, [currentSelectedStudent?.plannedStatus]);

  const clickResetPathway = () => {
    if (
      !_.isEmpty(studentOrderedPathway) &&
      !_.isEmpty(currentSelectedStudent) &&
      !_.isEmpty(orgId)
    ) {
      setResetDialogOpen(false);
      handleResetPathway(orgId, currentSelectedStudent.id, studentOrderedPathway.id);
    }
  };

  const classes = useStyles();
  return (
    <Box className={classes.tabComponent}>
      <DeclareDialog
        header={t('Are you sure you want to reset this plan?')}
        description={t('You will not be able to recover previous changes if you proceed.')}
        yesLabel={t('Reset Plan')}
        noLabel={t('Cancel')}
        open={resetDialogOpen}
        onClose={() => {}}
        handleYesClick={() => {
          clickResetPathway();
        }}
        handleNoClick={() => {
          setResetDialogOpen(false);
        }}
        cancelBtnColor="grey"
      />
      <Box sx={{ display: 'flex', flex: 2, justifyContent: 'flex-start' }}>
        {StudentViewTabMenu.map((data, index) => (
          <CustomChip
            clickable={true}
            label={t(data.name)}
            key={index}
            onClick={() => handleOnClick(data.value)}
            isActive={data.value === active}
            disabled={data.disable}
          />
        ))}
        <IconButton aria-label="delete" size="medium" sx={{ display: 'none' }}>
          <MoreVert />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
        {active == StudentViewTabMenu[3].value ? (
          <>
            <DeclaredSelect
              color="white"
              margin="dense"
              value={exploreOption}
              name="type"
              onChange={(e) => {
                setExploreOption(e.target.value);
              }}>
              {explorePathwayOptions.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                setResetDialogOpen(true);
              }}
              disabled={resetBtnDisable}
              variant="text"
              startIcon={<Replay color={resetBtnDisable ? 'grey.main' : 'secondary'} />}
              className={classes.actionBtn}>
              <Typography color={resetBtnDisable ? 'grey.main' : 'black.main'} fontWeight={800}>
                {t('Reset')}
              </Typography>
            </Button>
            <Button
              variant="text"
              disabled
              startIcon={<AttachEmail color="grey" />}
              className={classes.actionBtn}>
              <Typography color="grey" fontWeight={800}>
                {t('Email')}
              </Typography>
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handlePdfPrint();
              }}
              disabled={printBtnDisable}
              variant="text"
              startIcon={
                <Print color={resetBtnDisable || printBtnDisable ? 'grey.main' : 'secondary'} />
              }
              className={classes.actionBtn}>
              <Typography
                color={resetBtnDisable || printBtnDisable ? 'grey.main' : 'black.main'}
                fontWeight={800}>
                {t('Print')}
              </Typography>
            </Button>
            <DeclaredSelect
              color="white"
              margin="dense"
              sx={{ display: 'none' }}
              value={termOptions}
              name="type"
              displayEmpty={true}
              onChange={(e) => {
                setTermOption(e.target.value);
              }}
              renderValue={(value) => (value !== '' ? value : t('Please Select'))}>
              {TermOption.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {t(item.label)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </>
        )}
      </Box>
    </Box>
  );
}

export default TabComponent;
