import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareTextField from '../DeclareTextField';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

const useStyle = makeStyles((theme) => ({
  boxTextFieldAfter: {
    width: theme.spacing(15),
  },
  boxTextFieldBefore: {
    width: theme.spacing(30),
    marginLeft: theme.spacing(2),
  },
  ruleUnitBoxes: {
    display: 'flex',
  },
}));

export default function CompleteBetweenXAndYUnits(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [afterUnits, setAfterUnits] = useState(null);
  const [beforeUnits, setBeforeUnits] = useState(null);
  const [beforeUnitsEnabled, setBeforeUnitsEnabled] = useState(false);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = isRequirement) => {
    const data = {
      isRequirement: isReq,
      beforeUnits: Number(beforeUnits),
      afterUnits: Number(afterUnits),
      beforeUnitsUnlimited: beforeUnitsEnabled,
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  const classes = useStyle();

  useEffect(() => {
    setBeforeUnits(props.data.beforeUnits || null);
    if (_.gte(props.data.afterUnits, 0)) {
      setAfterUnits(props.data.afterUnits);
    } else {
      setAfterUnits(null);
    }
    setIsRequirement(props.data.isRequirement || false);
    if (
      _.isUndefined(props.data.beforeUnits) &&
      !_.isUndefined(props.data.afterUnits) &&
      !_.isUndefined(props.data.isRequirement)
    ) {
      setBeforeUnitsEnabled(true);
    }
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.ruleUnitBoxes}>
          <Box className={classes.boxTextFieldAfter}>
            <DeclareTextField
              onChange={(e) => {
                setAfterUnits(e.target.value);
              }}
              onBlur={() => {
                processChange(isRequirement);
              }}
              inputlabel={`${t('After Unit')}*`}
              variant="outlined"
              placeholder={t('After Unit')}
              value={afterUnits}
              name="afterUnits"
              type="number"
              disabled={!selectedItemEditable}
            />
          </Box>
          {!beforeUnitsEnabled && (
            <Box className={classes.boxTextFieldBefore}>
              <DeclareTextField
                onChange={(e) => {
                  setBeforeUnits(e.target.value);
                }}
                onBlur={() => {
                  processChange(isRequirement);
                }}
                inputlabel={`${t('Before and Including Unit')}*`}
                variant="outlined"
                placeholder={t('Before and Including Unit')}
                value={beforeUnits}
                name="beforeUnits"
                type="number"
                disabled={!selectedItemEditable}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={beforeUnitsEnabled}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setBeforeUnitsEnabled(e.target.checked);
              }}
              onBlur={() => {
                processChange(isRequirement);
              }}
            />
          }
          label={t('Before and Including Unit is unlimited')}
        />
      </Grid>
    </Grid>
  );
}
