import { Box, Button, IconButton, Modal, Slide, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchTextBox from './SearchTextBox';
import { FilterAlt, CancelOutlined } from '@mui/icons-material';
import DeclareDataTable from './DeclareDataTable';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    '& .EllipsisText': {
      display: 'inline-block',
      width: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      float: 'left',
    },
  },
  paper: {
    overflow: 'auto',
    position: 'absolute',
    background: 'white',
    left: 20,
    right: 20,
    top: 20,
    bottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: `20px 20px 0px 20px`,
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionBtnContainer: { float: 'right', marginBottom: theme.spacing(1) },
  actionBtn: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
      height: '45px',
      borderRadius: '20px',
      textTransform: 'capitalize',
      fontWeight: 300,
      width: '150px',
      color: theme.palette.common.white,
    },
  },
  removeBtn: { padding: theme.spacing(1) },
}));

export default function DeclareFlyout({
  flyoutOpen,
  flyoutType,
  completedCourses,
  setFlyoutOpen,
  title,
  confirmBtnLabel,
  handleConfirmAction,
}) {
  const classes = useStyle();
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const [laRowsSelected, setLaRowsSelected] = useState([]);
  const [filteredLAList, setFilteredLAList] = useState([]);
  const [selectedLAsFilterEnabled, setSelectedLAsFilterEnabled] = useState(false);

  // get LAs related to catalog
  const learningActivityList = useSelector(
    (state) => state.allLearningActivities.learningActivities,
  );
  const [selectedLearningActivities, setSelectedLearningActivities] = useState([]);

  const handleModalClose = () => {
    setSelectedLearningActivities([]);
    setFlyoutOpen(false);
  };

  const handleSelectedLAsLink = () => {
    setSelectedLAsFilterEnabled(true);
  };

  useEffect(() => {
    if (flyoutOpen) {
      const loadedCourses = completedCourses[flyoutType];
      if (!isNil(loadedCourses)) {
        setSelectedLearningActivities(loadedCourses);
      }
    }
  }, [completedCourses, flyoutType, flyoutOpen]);

  useEffect(() => {
    let laList = learningActivityList;

    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.code.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      laList = laList.filter((o) => filters.some((fn) => fn(o)));
    }
    if (selectedLAsFilterEnabled) {
      laList = laList.filter(
        ({ id }) => selectedLearningActivities.findIndex((la) => la.id === id) != -1,
      );
    }
    setFilteredLAList(laList);
  }, [learningActivityList, searchKey, selectedLAsFilterEnabled, selectedLearningActivities]);

  useEffect(() => {
    const indexes = selectedLearningActivities.map(({ id }) =>
      filteredLAList.findIndex((la) => la.id === id),
    );
    setLaRowsSelected(indexes);
  }, [filteredLAList, selectedLearningActivities]);

  const columns = [
    {
      name: 'name',
      label: t('Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'code',
      label: t('Code'),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    tableBodyMaxHeight: '580px',
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsOnClick: true,
    selectableRowsHeader: true,
    selectToolbarPlacement: 'none',
    rowsSelected: laRowsSelected,
    onRowSelectionChange: (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
      const selectedLA = rowsSelected.map((index) => {
        return {
          id: filteredLAList[index].id,
          type: flyoutType,
        };
      });

      const hiddenSelectedLAs = selectedLearningActivities.filter(
        ({ id }) => filteredLAList.findIndex((la) => la.id === id) === -1,
      );
      setLaRowsSelected(rowsSelected);
      setSelectedLearningActivities(selectedLA.concat(hiddenSelectedLAs));
    },
  };

  return (
    <Modal
      aria-labelledby="declare-flyout"
      aria-describedby="declare-flyout-description"
      open={flyoutOpen}
      onClose={handleModalClose}
      style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Slide direction="left" in={flyoutOpen} mountOnEnter unmountOnExit>
        <div className={classes.paper}>
          <Box display="flex">
            <Typography variant="h4" color="primary">
              {title}
            </Typography>
            <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
            <FilterAlt fontSize="large" color="secondary" />
          </Box>
          <Box display="flex">
            <Typography
              variant="h6"
              color="grey"
              onClick={selectedLearningActivities.length != 0 && handleSelectedLAsLink}
              className={selectedLearningActivities.length != 0 ? classes.clickable : ''}>
              {`${selectedLearningActivities.length} ${t('Selected')}`}
            </Typography>
            {selectedLAsFilterEnabled && (
              <IconButton
                className={classes.removeBtn}
                aria-label="remove"
                onClick={() => {
                  setSelectedLAsFilterEnabled(false);
                }}>
                <CancelOutlined />
              </IconButton>
            )}
          </Box>
          <Box sx={{ mt: 4, mb: 4 }}>
            <DeclareDataTable data={filteredLAList} columns={columns} options={options} />
          </Box>
          <Box className={classes.actionBtnContainer}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              className={classes.actionBtn}
              onClick={() => {
                handleConfirmAction(selectedLearningActivities);
                handleModalClose();
              }}>
              {confirmBtnLabel}
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="grey"
              className={classes.actionBtn}
              onClick={() => {
                handleModalClose();
              }}>
              {t('cancel')}
            </Button>
          </Box>
        </div>
      </Slide>
    </Modal>
  );
}
