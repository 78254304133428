import { applyMiddleware, compose, createStore } from 'redux';
import { reducers } from './reducers';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

sagaMiddleware.run(watcherSaga);
export default store;
