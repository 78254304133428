import { actionTypes } from '../constants/actionTypes';

const initState = {
  learningActivities: [],
  lActivitiesForTransferEquivalencies: [],
  byCatalog: { catalog: null, list: [] },
  classScheduleList: [],
};

export const learningActivityReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LEARNING_ACTIVITIES:
      return { ...state, learningActivities: payload, byCatalog: initState.byCatalog };
    case actionTypes.SET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES:
      return {
        ...state,
        lActivitiesForTransferEquivalencies: payload,
        byCatalog: initState.byCatalog,
      };
    case actionTypes.GET_LEARNING_ACTIVITIES_FOR_CATALOG:
      return { ...state, byCatalog: { catalog: payload.catalog, list: [] } };
    case actionTypes.SET_LEARNING_ACTIVITIES_FOR_CATALOG:
      return { ...state, byCatalog: { catalog: state.byCatalog.catalog, list: payload } };
    case actionTypes.SET_CLASS_SCHEDULES:
      return { ...state, classScheduleList: payload };
    case actionTypes.RESET_CLASS_SCHEDULE_LIST:
      return { ...state, classScheduleList: [] };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};
