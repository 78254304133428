import { RemoveCircle } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeclareLearningActivityCard from '../../../components/DeclareLearningActivityCard';
import { TransferEquivalencyType } from '../../../helpers/appConstants';

const sourceLaStyles = makeStyles((theme) => ({
  courseContainer: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
    '&.MuiBox-root:nth-of-type(2)': {
      marginTop: 'auto',
    },
  },
  removeItem: {
    position: 'absolute',
    right: theme.spacing(-3),
    top: theme.spacing(-2),
    '&.MuiSvgIcon-root': {
      visibility: 'hidden',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rowItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    '&.MuiTypography-root': {
      fontWeight: ({ option }) => (option ? null : 800),
      fontSize: ({ option }) => (option ? 'small' : 'medium'),
    },
  },
  rowItemsUnits: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    width: 'auto',
    paddingRight: ({ option }) => (option ? theme.spacing(1) : null),
    '&.MuiTypography-root': {
      fontSize: ({ option }) => (option ? 'small' : 'medium'),
    },
  },
}));
export default function TeqSourceLA(props) {
  const { code, name, units, removeAction } = props;
  const classes = sourceLaStyles();
  const { t } = useTranslation();

  const codeComponent = () => {
    return (
      <Typography className={classes.rowItems} fontWeight={800}>
        {code}
      </Typography>
    );
  };

  const nameComponent = () => {
    return !_.isEmpty(name) ? (
      <Typography className={classes.rowItems} fontWeight={800}>
        {name}
      </Typography>
    ) : (
      <Typography className={classes.rowItems} sx={{ fontWeight: 800 }}>
        {t('Not Found')}
      </Typography>
    );
  };

  const unitComponent = () => {
    return (
      <Typography className={classes.rowItemsUnits}>
        {!_.isUndefined(units) ? (
          <Typography className={classes.rowItemsUnits}>{`${units} ${t('units')}`}</Typography>
        ) : (
          <Typography></Typography>
        )}
      </Typography>
    );
  };
  return (
    <Stack flex={1} className={classes.courseContainer}>
      <Box sx={{ position: 'relative' }}>
        <RemoveCircle
          color="secondary"
          className={classes.removeItem}
          onClick={() => {
            removeAction();
          }}
        />
      </Box>
      <DeclareLearningActivityCard
        code={code}
        name={name}
        units={units}
        type={TransferEquivalencyType[0].value}
        codeComponent={codeComponent}
        nameComponent={nameComponent}
        unitComponent={unitComponent}
      />
    </Stack>
  );
}
