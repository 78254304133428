import _ from 'lodash';
import i18n from 'i18next';
import { createDataRows, createExcelFile, getTodaysDate, initExcel } from './generateExcel';

export function generateStudentStatusReportExcel(org, pathwayStatuses, filteredAllTerms, data) {
  const statusHeaders = pathwayStatuses.map(({ name, id }) => ({ header: name, key: id }));
  const columns = [{ header: '', key: '' }, ...statusHeaders];

  const statusCounts = _.mapValues(data, (values) =>
    pathwayStatuses.map(({ id }) => values[id] || 0),
  );
  const columnData = filteredAllTerms.map(({ displayName, endDate }) => [
    displayName,
    ...(endDate ? _.values(statusCounts[endDate]) : [i18n.t('Term dates not set')]),
  ]);

  const title = i18n.t('Student pathways by terms');
  const today = getTodaysDate();
  const filename = `${org.initials}-${title}-${today}.xlsx`;

  const [workbook, sheet] = initExcel(title, columns, { bold: false, size: 11 });
  createDataRows(sheet, columnData);
  createExcelFile(workbook, filename);
}
