import React, { useState } from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';

export default function ProgramMapLoadMore({ node, getItemsFromParents }) {
  const MAX_ITEMS = 50;
  const [responseLength, setResponseLength] = useState(MAX_ITEMS);
  const length = node.items.length;
  const showTypography =
    length >= MAX_ITEMS && !_.isEmpty(node.items) && responseLength >= MAX_ITEMS;

  const generatePagination = () => {
    const page = Math.ceil(length / MAX_ITEMS) + 1;
    return `page=${page}&size=${MAX_ITEMS}`;
  };

  const handleResponse = (res) => {
    const { items } = res;
    setResponseLength(items.length);
  };

  return showTypography ? (
    <Typography
      variant="body2"
      sx={{ ml: 5.5, cursor: 'pointer' }}
      onClick={() => {
        const query = generatePagination();
        getItemsFromParents(node.id, query, handleResponse);
      }}>
      Load More
    </Typography>
  ) : null;
}
