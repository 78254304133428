import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  chip: {
    '&.MuiButtonBase-root.MuiChip-root': {
      backgroundColor: ({ isActive }) => (isActive ? theme.palette.secondary.main : null),
      padding: theme.spacing(3),
      borderRadius: theme.spacing(3),
      fontSize: theme.spacing(2),
      fontWeight: 700,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.white.main,
      },
      color: ({ isActive }) => (isActive ? theme.palette.white.main : null),
      marginRight: theme.spacing(3),
    },
  },
}));

export default function CustomChip({ label, isActive, ...props }) {
  const classes = useStyles({ isActive });
  return <Chip {...props} label={label} className={classes.chip} />;
}
