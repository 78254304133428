import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../../helpers/routes';
import { getProgramOptions } from '../../../redux/actions/programActions';
import StatusCell from '../../../components/StatusCell';
import EditIcon from '@mui/icons-material/Edit';
import DeclareDataTable from '../../../components/DeclareDataTable';

export default function ProgramOptionList(props) {
  const { t } = useTranslation();
  const { programOptionsOfSelectedProgram, selectedProgramId } = useSelector(
    (state) => state.programs,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, programId } = useParams();
  const [filteredProgramOptionsList, setFilteredProgramOptionsList] = useState(
    programOptionsOfSelectedProgram,
  );
  const { searchKey } = props;

  useEffect(() => {
    var filteredList = programOptionsOfSelectedProgram;
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
      ];
      filteredList = programOptionsOfSelectedProgram.filter((o) => filters.some((fn) => fn(o)));
    }
    setFilteredProgramOptionsList(filteredList);
  }, [programOptionsOfSelectedProgram, searchKey]);

  useEffect(() => {
    if (selectedProgramId != programId || programOptionsOfSelectedProgram.length == 0) {
      dispatch(getProgramOptions(orgId, programId));
    }
  }, []);

  const navigateToEditProgram = async (optionId) => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_OPTION_EDIT, { orgId, programId, optionId }));
  };

  const columns = [
    {
      name: 'name',
      label: t('Option'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                navigateToEditProgram(value);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <Box sx={{ mb: 4 }}>
      <DeclareDataTable data={filteredProgramOptionsList} columns={columns} options={options} />
    </Box>
  );
}
