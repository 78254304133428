import axios from 'axios';
import { DECLARE_LOGGED_IN_USER } from '../helpers/appConstants';

export class HttpService {
  headers = {};

  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 300000,
      headers: this.setHeaders(),
    });

    this.client.interceptors.request.use((config) => {
      // improve this by passing the redux store
      config.headers.common[DECLARE_LOGGED_IN_USER] = window[DECLARE_LOGGED_IN_USER]
      return config;
    })
  }

  formatResponse(status, data) {
    return { status, data };
  }

  async get(url) {
    return await this.client
      .get(url)
      .then((res) => {
        return this.formatResponse(true, res.data);
      })
      .catch((error) => {
        return this.formatResponse(false, error.response.data);
      });
  }

  async post(url, body) {
    return await this.client
      .post(url, body)
      .then((res) => {
        return this.formatResponse(true, res.data);
      })
      .catch((error) => {
        return this.formatResponse(false, error.response.data);
      });
  }

  async put(url, body) {
    return await this.client
      .put(url, body)
      .then((res) => {
        return this.formatResponse(true, res.data);
      })
      .catch((error) => {
        return this.formatResponse(false, error.response.data);
      });
  }

  async patch(url, body) {
    return await this.client
      .patch(url, body)
      .then((res) => {
        return this.formatResponse(true, res.data);
      })
      .catch((error) => {
        return this.formatResponse(false, error.response.data);
      });
  }

  async delete(url) {
    return await this.client.delete(url);
  }

  setHeaders() {
    const token = window.localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = token;
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }
}
