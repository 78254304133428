import { Menu, Paper } from '@mui/material';
import React from 'react';
import { PROGRAM_MAP_TYPE } from '../../helpers/programMapHelper';
import GroupContextMenu from './GroupContextMenu';
import LAContextMenu from './LAContextMenu';
import _ from 'lodash';

export default function TreeContextMenu(props) {
  const {
    type,
    searchKeyMap,
    anchorEl,
    openMenu,
    handleMenuClose,
    MenuPropStyles,
    selectedElementPath,
    addNewGroup,
    setChildVisibility,
    handleModalOpen,
    childVisible,
    hasChild,
    removeTreeNode,
    showRemoveOnly,
  } = props;

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={openMenu}
      onClose={handleMenuClose}
      PaperProps={MenuPropStyles}>
      <Paper sx={{ border: '1px solid', borderColor: 'green' }}>
        {[PROGRAM_MAP_TYPE.GROUP, PROGRAM_MAP_TYPE.PROGRAM_MAP].includes(type) && (
          <GroupContextMenu
            addNewGroup={addNewGroup}
            selectedElementPath={selectedElementPath}
            handleMenuClose={handleMenuClose}
            setChildVisibility={setChildVisibility}
            handleModalOpen={handleModalOpen}
            childVisible={childVisible}
            hasChild={hasChild}
            removeTreeNode={removeTreeNode}
            showRemoveOnly={showRemoveOnly}
          />
        )}
        {type == PROGRAM_MAP_TYPE.LEARNING_ACTIVITY && (
          <LAContextMenu
            searchKeyMap={searchKeyMap}
            selectedElementPath={selectedElementPath}
            handleMenuClose={handleMenuClose}
            setChildVisibility={setChildVisibility}
            handleModalOpen={handleModalOpen}
            childVisible={childVisible}
            hasChild={hasChild}
            removeTreeNode={removeTreeNode}
          />
        )}
      </Paper>
    </Menu>
  );
}
