import _ from 'lodash';
import { createDataRows, createExcelFile, getTodaysDate, initExcel } from './generateExcel';

export async function generateExcelForStudentList(t, organization, termList, { data }) {
  const columns = [
    {
      header: t('Student ID'),
      key: 'studentId',
    },
    {
      header: t('First Name'),
      key: 'firstName',
    },
    {
      header: t('Last Name'),
      key: 'lastName',
    },
    {
      header: t('University Email'),
      key: 'eduEmail',
    },
    {
      header: t(`Student's First Term`),
      key: 'initialTerm',
    },
    {
      header: t(`Student's First Year`),
      key: 'initialYear',
    },
    {
      header: t('Status'),
      key: 'plannedStatusName',
    },
  ];

  const columnData = data
    .map(
      ({
        studentId,
        firstName,
        lastName,
        eduEmail,
        pathwayTerm: { id: pathwayTermId, year: pathwayYear },
        firstTerm: { id: termId, year: initialYear },
        plannedStatus: { name: plannedStatusName },
      }) => [
        studentId,
        firstName,
        lastName,
        eduEmail,
        termList.find(({ id }) => id === (pathwayTermId || termId))?.name ?? null,
        pathwayYear || initialYear,
        plannedStatusName,
      ],
    )
    .sort();

  const title = 'Student List';
  const today = getTodaysDate();
  const filename = `${organization.initials}-${_.camelCase(title)}-${today}.xlsx`;

  const [workbook, sheet] = initExcel(title, columns);
  createDataRows(sheet, columnData);
  createExcelFile(workbook, filename);
}
