import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Container,
  Button,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { ArrowRightAlt, ControlPoint, DoNotDisturbOnRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, generatePath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeclareTextField from '../../../components/DeclareTextField';
import DeclaredSelect from '../../../components/DeclareSelect';
import DeclareAutocomplete from '../../../components/DeclareAutocomplete';
import { useDeclareForm } from '../../../components/DeclareForm';
import DeclareFormControl from '../../../components/DeclareFormControl';
import { appRoutes } from '../../../helpers/routes';
import {
  courseLevels,
  courseTypes,
  getStatus,
  learningActivityType,
  TransferEquivalencyType,
  newTeqStatus,
  transferringOptionTypes,
  dropDownStyle,
} from '../../../helpers/appConstants';
import {
  postLearningActivity,
  updateLearningActivity,
} from '../../../redux/actions/learningActivityActions';
import { makeStyles } from '@mui/styles';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import theme from '../../../theme';
import {
  getTeq,
  getStatuses,
  postTeq,
  postTeqWithRequirements,
  setSelectedLACodes,
  updateTeq,
} from '../../../redux/actions/transferEquivalencyActions';
import _, { set } from 'lodash';
import { setError, setFormError } from '../../../redux/actions/notificationActions';
import { getStudentTeqAssignments } from '../../../redux/actions/studentActions';
import { getCurrentCatalog } from '../../../helpers/studentsHelper';
import TabView from '../../../components/TabView';
import { v4 as uuidv4 } from 'uuid';
import { useRef } from 'react';
import { SCREEN_SIZES } from '../../../helpers/common';

const useStyle = makeStyles((theme) => ({
  select: {
    '&.MuiSelect-select': {
      paddingRight: '200px',
    },
  },
  button: {
    '&.MuiButton-root': {
      height: '45px',
      borderRadius: '20px',
      textTransform: 'capitalize',
      fontWeight: 300,
      width: '150px',
    },
  },
  arrowIcon: {
    '&.MuiSvgIcon-root': {
      paddingTop: '15px',
    },
  },
  addNewButton: {
    '&.MuiButtonBase-root.MuiButton-root': {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(6),
      paddingLeft: 0,
      '&:hover': { backgroundColor: 'transparent' },
    },
    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: theme.spacing(3),
    },
  },
  typography: {
    '&.MuiTypography-root': {
      fontSize: '30px',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  },
  inputLabelText: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
    },
  },
  addNewText: {
    '&.MuiTypography-root': {
      fontWeight: 700,
      color: theme.palette.common.black,
      textTransform: 'capitalize',
    },
  },
  tEqDestinations: {
    '&:hover $removeItem': {
      visibility: 'visible',
      cursor: 'pointer',
    },
  },
  removeItem: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      color: theme.palette.secondary.main,
      visibility: 'hidden',
      marginTop: `-${theme.spacing(2)}`,
    },
  },
  unitContainer: {
    marginLeft: theme.spacing(8.5),
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: theme.spacing(9),
    },
  },
  mainContainer: {
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
  statusContainer: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      width: theme.spacing(30),
    },
    marginTop: theme.spacing(2),
  },
  transferTypeSelection: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2.5),
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      '-webkit-flex-direction': 'row',
    },
  },
  catalogSelect: {
    width: theme.spacing(45),
  },
  tabContainer: {
    '& .MuiTabs-scrollButtons.Mui-disabled': {
      opacity: '0.3',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      minHeight: '0px',
    },
  },
}));

export default function CreateTransferEquivalency(props) {
  const requestObj = { units: null, type: TransferEquivalencyType[0].value, data: null };
  const requirementRequestObj = { units: null, type: TransferEquivalencyType[1].value, data: null };
  const {
    currentTransferEquivalency,
    isEditView,
    learningActivities,
    allRequirements,
    isLaNameFieldDisabled,
    teqFromState,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId, transferEquivalencyId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const formError = useSelector((state) => state.notifications.formError);
  const [isEdit, setIsEdit] = useState(true);
  const { values, setValues, handleInputChange } = useDeclareForm({
    currentTransferEquivalency,
    catalogVersions: [],
  });
  const [requestData, setRequestData] = useState([requestObj]);
  const [requirementRequestData, setRequirementRequestData] = useState([requirementRequestObj]);
  const [TeqErrorData, setTeqErrorData] = useState([]);
  const { sourceLatestCatalog } = useSelector((state) => state.allCatalogs);
  const { teqStatuses, selectedSourceOrg, teqStatusesEnum } = useSelector(
    (state) => state.transferEquivalency,
  );
  const classes = useStyle();
  const [transferType, setTransferType] = useState(
    !isEditView
      ? transferringOptionTypes.COURSE_ONLY
      : _.isNull(state.catalogId)
      ? transferringOptionTypes.COURSE_ONLY
      : transferringOptionTypes.WITH_REQUIREMENT,
  );
  const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
  const { programMapList } = useSelector((state) => state.programMaps);
  const { currentSelectedStudent } = useSelector((state) => state.students);
  const [currentCatalog, setCurrentCatalog] = useState({});
  const [data, setData] = useState([]);
  const [currentSelectedEquivalency, setCurrentSelectedEquivalency] = useState({});
  const [activeCatalogs, setActiveCatalogs] = useState([]);
  const { innerContainerWidth } = useSelector((state) => state.ui);
  const [tabViewMaxWidth, setTabViewMaxWidth] = useState(null);
  const containerRef = useRef(0);
  useEffect(() => {
    if (!_.isNil(innerContainerWidth)) {
      if (innerContainerWidth < containerRef.current.offsetWidth) {
        if (containerRef.current.offsetWidth > SCREEN_SIZES.LARGE_SCREEN) {
          setTabViewMaxWidth(containerRef.current.offsetWidth);
        } else {
          setTabViewMaxWidth(innerContainerWidth);
        }
      } else {
        setTabViewMaxWidth(innerContainerWidth);
      }
    }
  }, [innerContainerWidth, containerRef]);

  useEffect(() => {
    setValues(currentTransferEquivalency);
  }, [currentTransferEquivalency]);

  useEffect(() => {
    dispatch(getStudentTeqAssignments(orgId, currentSelectedStudent, true));
    initializeUpdate();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(catalogs)) {
      const catalogVersions = [];
      const currentCatalog = getCurrentCatalog(
        programMapList,
        latestCatalog,
        currentSelectedStudent.programMapId,
        catalogs,
        currentSelectedStudent.catalogId,
      );
      setCurrentCatalog(currentCatalog);
      catalogVersions.push(currentCatalog);
      const catalogStatus = getStatus().reduce(
        (prev, curr) => Object.assign(prev, { [curr.value]: curr.value }),
        {},
      );
      const allActiveCatalogs = catalogs.filter(({ status }) => status === catalogStatus.ACTIVE);
      setActiveCatalogs(allActiveCatalogs);
    }
  }, [catalogs, programMapList, currentSelectedStudent]);

  useEffect(() => {
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    }
  }, [teqStatuses]);

  useEffect(() => {
    if (!_.isEmpty(activeCatalogs) && !_.isEmpty(data)) {
      const missingCatalogs = activeCatalogs.filter((catalog) => !(catalog.id in data));
      if (!_.isEmpty(missingCatalogs)) {
        const updatedData = { ...data };

        missingCatalogs.forEach((missingCatalog) => {
          const teqDataToUpdate = JSON.parse(JSON.stringify(data[state.catalogId]));
          const updatedTeqData = updateValues(teqDataToUpdate, missingCatalog.id);
          updatedData[missingCatalog.id] = updatedTeqData;
        });
        setData(updatedData);
      }
    }
  }, [data, activeCatalogs]);

  const updateValues = (obj, newCatalogId) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => updateValues(item, newCatalogId));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((newObj, key) => {
        if (key === 'catalogId') {
          newObj[key] = newCatalogId;
        } else if (key === 'transferEquivalencyId' || key === 'status') {
          newObj[key] = null;
        } else if (key === 'destinationEqList') {
          newObj[key] = obj[key].map((destItem) => ({
            ...destItem,
            id: null,
            units: null,
            data: null,
            transferEquivalencyId: null,
          }));
        } else {
          newObj[key] = updateValues(obj[key], newCatalogId);
        }
        return newObj;
      }, {});
    } else {
      return obj;
    }
  };

  const initializeUpdate = () => {
    if (isEdit && !_.isUndefined(state.destinationEqList)) {
      const allCourseReq = mergeCourseReq();
      const newRequestObject = generateEditRequestData(allCourseReq);
      setRequestData(newRequestObject);
      setIsEdit(false);
      setTransferType(
        state.catalogId
          ? transferringOptionTypes.WITH_REQUIREMENT
          : transferringOptionTypes.COURSE_ONLY,
      );

      const callback = (teqData) => {
        const transformedData = transformData(Object.values(teqData)[0], state.groupId);
        setData(transformedData);
        setCurrentSelectedEquivalency(transformedData[state.catalogId][0]);
      };

      if (state.catalogId) {
        dispatch(getTeq(orgId, state.sourceOrgId, state.sourceLaCode, callback));
      }
    }
  };

  const transformData = (data, groupId) => {
    const courseRequirments = learningActivities.concat(allRequirements);
    const courseRequirmentsMap = new Map(
      courseRequirments.map((activity) => [activity.id, activity]),
    );

    return data.equivalencies
      .filter((equivalency) => equivalency.groupId === groupId)
      .reduce((result, equivalency) => {
        const catalogId = equivalency.catalogId;

        if (!result[catalogId]) {
          result[catalogId] = [];
        }

        const updatedEquivalency = {
          ...equivalency,
          destinationEqList: equivalency.destinationEqList.map((destination) => ({
            ...destination,
            data: destination.id
              ? { ...courseRequirmentsMap.get(destination.id), catalogId }
              : null,
          })),
        };

        result[catalogId].push(updatedEquivalency);
        return result;
      }, {});
  };

  const mergeCourseReq = () => {
    return learningActivities.concat(allRequirements);
  };

  const generateEditRequestData = (allCourseReq) => {
    return state.destinationEqList.map((data) => {
      const index = allCourseReq.findIndex((value) => data.id === value.id);
      return {
        type: data.type,
        units: data.units,
        data: allCourseReq[index],
      };
    });
  };

  const navigateBack = (isEdited = true) => {
    navigate(generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, { orgId }), {
      state: {
        isEdited,
      },
    });
  };

  const isTeqDataEmpty = (data) => {
    const errorObj = {};
    const errorData = data.map(({ units, type, data }) => ({
      units: units === null || units === '',
      type: type === null,
      data: data === null,
    }));
    setTeqErrorData(errorData);
    if (!_.values(teqStatusesEnum).includes(values.teqStatus)) {
      errorObj['statusCode'] = {
        message: t('Status is required'),
      };
      dispatch(setFormError(errorObj));
    }
    if (values.teqStatus === teqStatusesEnum.APPROVED_WITH_NOTE) {
      if (_.isEmpty(values.transferNote)) {
        errorObj['transferNote'] = {
          message: t('Note is required'),
        };
      }
      dispatch(setFormError(errorObj));
    }

    return (
      data.some(
        (obj) => obj.units === null || obj.units === '' || obj.type === null || obj.data === null,
      ) || !_.isEmpty(errorObj)
    );
  };

  const saveTeq = (learningActivity) => {
    if (transferType === transferringOptionTypes.COURSE_ONLY) {
      saveCoursesOnlyTeq(learningActivity);
    } else {
      saveCoursesAndRequirementsTeq(learningActivity);
    }
  };

  const saveCoursesAndRequirementsTeq = (learningActivity) => {
    if (isTeqDataEmpty(requirementRequestData)) {
      return;
    }
    const sourceLAId = _.isUndefined(learningActivity) ? state.sourceLaId : learningActivity.id;
    const data = constructData(requirementRequestData, sourceLAId);
    data['catalogId'] = getApplicableCatalog().id;
    data['duplicateForOtherCatalogs'] = true;
    const catalogId = data.catalogId;
    if (isTeqDuplicate(data, transferEquivalencyId)) {
      dispatchErrorForDuplicateTeq();
    } else {
      dispatch(setSelectedLACodes([values.transferringCode]));
      dispatch(
        postTeqWithRequirements(orgId, data, (res) =>
          processSavedCoursesAndRequirementsTeqData(res, sourceLAId, catalogId),
        ),
      );
    }
  };

  const processSavedCoursesAndRequirementsTeqData = (data, sourceLaId, catalogId) => {
    const transferEquivalencyId = data[0].destinationEqList[0].transferEquivalencyId;
    const teqData = data.filter(({ catalogId: id }) => id === catalogId)[0];

    const stateData = {
      sourceOrgId: selectedSourceOrg.id,
      sourceLaId,
      sourceLaCode: values.transferringCode,
      sourceLaName: values.transferringName,
      sourceLaUnits: values.transferringUnits,
      catalogId,
      transferringCourseId: teqData.transferringCourseId,
      transferEquivalencyId,
      destinationEqList: teqData.destinationEqList,
      groupId: teqData.groupId,
    };

    const callback = (response) => {
      const transformedData = transformData(Object.values(response)[0], teqData.groupId);
      setData(transformedData);
      setCurrentSelectedEquivalency(transformedData[catalogId][0]);
    };

    dispatch(getTeq(orgId, stateData.sourceOrgId, stateData.sourceLaCode, callback));

    navigate(
      generatePath(appRoutes.advisor.EDIT_TRANSFER_EQUIVALENCIES, {
        orgId,
        transferEquivalencyId,
      }),
      { state: { ...stateData } },
    );
  };

  const constructData = (requestData, sourceLearningActivityId) => {
    const destinationEqList = requestData.map((res) => ({
      units: Number(res.units),
      type: res.type,
      id: res.data?.id,
    }));

    const statusTeq = teqStatuses.find((sts) => sts.type === values.teqStatus);
    const data = {
      adminId: 2,
      sourceLearningActivityId,
      statusCode: statusTeq?.code,
      sourceOrgId: state.sourceOrgId,
      destinationEqList,
      destinationOrgId: orgId,
      transferringCourseId: values.transferringCourseId,
      groupId: transferType === transferringOptionTypes.WITH_REQUIREMENT ? uuidv4() : null,
    };

    if (values.teqStatus === teqStatusesEnum.APPROVED_WITH_NOTE) {
      data['note'] = values.transferNote;
    }

    return data;
  };

  const constructDataWithRequirments = (requestData, sourceLearningActivityId) => {
    const destinationEqList = requestData.destinationEqList.map((res) => ({
      units: Number(res.units),
      type: res.type,
      id: res.data?.id,
    }));

    const statusTeq = requestData.status;

    const data = {
      adminId: 2,
      sourceLearningActivityId,
      statusCode: statusTeq?.code,
      sourceOrgId: state.sourceOrgId,
      destinationEqList,
      destinationOrgId: orgId,
      transferringCourseId: state.transferringCourseId,
      groupId: requestData.groupId,
      catalogId: requestData.catalogId,
    };

    if (statusTeq?.type === teqStatusesEnum.APPROVED_WITH_NOTE) {
      data['note'] = requestData.note.message;
    }

    return data;
  };

  const dispatchErrorForDuplicateTeq = () => {
    dispatch(
      setError({
        message: t('Transfer Equivalency already exists.'),
      }),
    );
  };

  const isTeqDuplicate = (data, transferEquivalencyId) => {
    const dataSourceLearningActivityId = data.sourceLearningActivityId;
    const dataDestinationEqList = data.destinationEqList;
    return teqFromState.some(
      (item) =>
        item.sourceLearningActivityId === dataSourceLearningActivityId &&
        item.equivalencies.some(
          (eq) =>
            eq.destinationEqList.length === dataDestinationEqList.length &&
            eq.transferEquivalencyId !== +transferEquivalencyId &&
            eq.destinationEqList.every((destEq1) =>
              dataDestinationEqList.some((destEq2) => destEq1.id === destEq2.id),
            ),
        ),
    );
  };

  const saveCoursesOnlyTeq = (learningActivity) => {
    if (isTeqDataEmpty(requestData)) {
      return;
    }
    const sourceLAId = _.isUndefined(learningActivity) ? state.sourceLaId : learningActivity.id;

    const data = constructData(requestData, sourceLAId);

    if (isTeqDuplicate(data)) {
      dispatchErrorForDuplicateTeq();
    } else {
      dispatch(setSelectedLACodes([values.transferringCode]));
      dispatch(postTeq(orgId, data, navigateBack));
    }
  };

  const editTeq = () => {
    if (transferType === transferringOptionTypes.WITH_REQUIREMENT) {
      const updatedItems = Object.values(data).flatMap((array) =>
        array.filter((item) => item.updated === true),
      );

      if (updatedItems.length === 0) {
        navigateBack(false);
        return;
      }

      const withTransferEquivalencyId = updatedItems.filter(
        (item) => item.transferEquivalencyId !== null,
      );
      const withoutTransferEquivalencyId = updatedItems.filter(
        (item) => item.transferEquivalencyId === null,
      );

      withTransferEquivalencyId.forEach((item, index) => {
        const dataToUpdate = constructDataWithRequirments(
          data[item.catalogId][0],
          state.sourceLaId,
        );

        const equivalencyId = item.destinationEqList[0].transferEquivalencyId;

        if (isTeqDuplicate(dataToUpdate, equivalencyId)) {
          dispatchErrorForDuplicateTeq();
        } else {
          if (index === 0) {
            dispatch(setSelectedLACodes([currentTransferEquivalency.transferringCode]));
          }

          dispatch(
            updateTeq(
              orgId,
              dataToUpdate,
              equivalencyId,
              index === withTransferEquivalencyId.length - 1 ? navigateBack : null,
            ),
          );
        }
      });

      withoutTransferEquivalencyId.forEach((item, index) => {
        const dataToUpdate = constructDataWithRequirments(
          data[item.catalogId][0],
          state.sourceLaId,
        );
        dataToUpdate['duplicateForOtherCatalogs'] = false;
        dispatch(
          postTeqWithRequirements(
            orgId,
            dataToUpdate,
            index === withoutTransferEquivalencyId.length - 1 ? navigateBack : null,
          ),
        );
      });
    } else {
      if (isTeqDataEmpty(requestData)) {
        return;
      }

      const dataToUpdate = constructData(requestData, state.sourceLaId);
      if (isTeqDuplicate(dataToUpdate, transferEquivalencyId)) {
        dispatchErrorForDuplicateTeq();
      } else {
        dispatch(setSelectedLACodes([values.transferringCode]));
        dispatch(updateTeq(orgId, dataToUpdate, transferEquivalencyId, navigateBack));
      }
    }
  };

  const updateRequestData = (selectedType, value, index) => {
    if (transferType === transferringOptionTypes.COURSE_ONLY) {
      const result = requestData.map((data, position) => {
        if (index === position) {
          data[selectedType] = value;
        }

        return data;
      });

      setRequestData(result);
    } else {
      if (!isEditView) {
        const result = requirementRequestData.map((data, position) => {
          if (index === position) {
            data[selectedType] = value;
          }

          return data;
        });

        setRequirementRequestData(result);
      } else {
        setData((prevData) => ({
          ...prevData,
          [currentSelectedEquivalency.catalogId]: prevData[
            currentSelectedEquivalency.catalogId
          ].map((item) => {
            if (item.catalogId === currentSelectedEquivalency.catalogId) {
              return {
                ...item,
                updated: true,
                destinationEqList: item.destinationEqList.map((data, position) => {
                  if (index === position) {
                    data[selectedType] = value;
                  }

                  return data;
                }),
              };
            }
            return item;
          }),
        }));
      }
    }
  };

  const handleSelectChange = (value, index) => {
    updateRequestData('type', value, index);
    updateRequestData('data', null, index);
  };

  const addNewRow = () => {
    if (transferType === transferringOptionTypes.COURSE_ONLY) {
      setRequestData([...requestData, requestObj]);
    } else {
      if (!isEditView) {
        setRequirementRequestData([...requirementRequestData, requirementRequestObj]);
      } else {
        setData((prevData) => ({
          ...prevData,
          [currentSelectedEquivalency.catalogId]: prevData[
            currentSelectedEquivalency.catalogId
          ].map((item) => {
            if (item.catalogId === currentSelectedEquivalency.catalogId) {
              return {
                ...item,
                updated: true,
                destinationEqList: [...item.destinationEqList, requirementRequestObj],
              };
            }
            return item;
          }),
        }));
      }
    }
  };

  const handleStatusChangeForRequirments = (event) => {
    const statusTeq = teqStatuses.find((sts) => sts.type === event.target.value);
    setData((prevData) => ({
      ...prevData,
      [currentSelectedEquivalency.catalogId]: prevData[currentSelectedEquivalency.catalogId].map(
        (item) => {
          if (item.catalogId === currentSelectedEquivalency.catalogId) {
            return {
              ...item,
              updated: true,
              status: statusTeq,
            };
          }
          return item;
        },
      ),
    }));
  };

  const handleNoteChangeForRequirments = (event) => {
    setData((prevData) => ({
      ...prevData,
      [currentSelectedEquivalency.catalogId]: prevData[currentSelectedEquivalency.catalogId].map(
        (item) => {
          if (item.catalogId === currentSelectedEquivalency.catalogId) {
            return {
              ...item,
              updated: true,
              note: { message: event.target.value },
            };
          }
          return item;
        },
      ),
    }));
  };

  const removeAddedRow = (indexToRemove) => {
    if (transferType === transferringOptionTypes.COURSE_ONLY) {
      const filteredArray = requestData.filter((_data, position) => position !== indexToRemove);
      setRequestData(filteredArray);
    } else {
      if (!isEditView) {
        const filteredArray = requirementRequestData.filter(
          (_data, position) => position !== indexToRemove,
        );
        setRequirementRequestData(filteredArray);
      } else {
        setData((prevData) => ({
          ...prevData,
          [currentSelectedEquivalency.catalogId]: prevData[
            currentSelectedEquivalency.catalogId
          ].map((item) => {
            if (item.catalogId === currentSelectedEquivalency.catalogId) {
              return {
                ...item,
                updated: true,
                destinationEqList: item.destinationEqList.filter(
                  (_, index) => index !== indexToRemove,
                ),
              };
            }
            return item;
          }),
        }));
      }
    }
  };

  const saveForm = () => {
    if (state?.sourceOrgId) {
      const laData = {
        name: values.transferringName,
        code: values.transferringCode,
        units: Number(values.transferringUnits),
        url: 'https://getdeclared.com/',
        type: courseTypes[0].value,
        level: courseLevels[0].value,
        shortDescription: values.transferringName,
        status: getStatus()[3].value,
        tags: [],
        externalId: values.transferringName,
        catalogVersions: [sourceLatestCatalog.id],
        learningActivityType: learningActivityType.COURSE,
        duration: 1,
      };

      if (!_.isEmpty(state.sourceLaId)) {
        saveTeq();
      } else {
        dispatch(postLearningActivity(state?.sourceOrgId, laData, saveTeq, false));
      }
    }
  };

  const updateUnitsAndTeq = (isEditView) => {
    if (
      _.isUndefined(currentTransferEquivalency.transferringUnits) ||
      currentTransferEquivalency.transferringUnits === Number(values.transferringUnits)
    ) {
      isEditView ? editTeq() : saveForm();
    } else {
      dispatch(
        updateLearningActivity(
          state?.sourceOrgId,
          state.sourceLaId,
          { units: Number(values.transferringUnits) },
          isEditView ? editTeq() : saveForm(),
        ),
      );
    }
  };

  const getFilteredOptionsForDropDown = (
    courseReqList,
    existingDestCourseReqList,
    currentIndex,
  ) => {
    const { type } = existingDestCourseReqList[currentIndex];
    const existingCourseReqIds = existingDestCourseReqList
      .filter((val, index) => index != currentIndex && val.data && val.type === type)
      .map(({ data: { id } }) => id);
    return courseReqList.filter(({ id }) => !existingCourseReqIds.includes(id));
  };

  const setUnitsOnBlur = (name, unit) => {
    if (_.isEmpty(unit) || unit < 0) {
      setValues({ ...values, [name]: 0 });
    }
  };

  const filterCoursesByCatalog = (list) => {
    const catalogId = getApplicableCatalog()?.id;
    return list.filter(({ catalog }) => catalog.find(({ id }) => id === catalogId) != null);
  };

  const filterRequirementsByCatalog = (list) => {
    const id = getApplicableCatalog()?.id;
    return list.filter(({ catalogId }) => catalogId === id);
  };

  const getApplicableCatalog = () =>
    _.isEmpty(values.catalogVersions) ? currentCatalog : values.catalogVersions;

  const renderCourseOnly = () => {
    return requestData.map((res, index) => {
      return (
        <>
          <Box
            sx={{ display: 'flex', marginTop: theme.spacing(4) }}
            className={classes.tEqDestinations}>
            <Box sx={{ marginLeft: 0 }}>
              <ArrowRightAlt sx={{ verticalAlign: 'center' }} className={classes.arrowIcon} />
            </Box>
            <Box sx={{ marginLeft: theme.spacing(3), display: 'flex' }}>
              <Box sx={{ display: 'flex', direction: 'column' }}>
                <DeclareAutocomplete
                  disableClearable
                  disabled={_.isEmpty(requestData[index].type)}
                  sx={{ width: theme.spacing(69.5) }}
                  getOptionLabel={(option) =>
                    res.type === TransferEquivalencyType[0].value
                      ? `${option.code} ${'\t'} ${option.name}`
                      : `${option?.programOption.code} ${'\t'} ${option.message}`
                  }
                  onChange={(_event, value) => {
                    updateRequestData('data', value, index);
                    updateRequestData('units', value.units, index);
                  }}
                  value={res.data}
                  options={
                    requestData[index].type === TransferEquivalencyType[0].value
                      ? getFilteredOptionsForDropDown(learningActivities, requestData, index)
                      : getFilteredOptionsForDropDown(allRequirements, requestData, index)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.data}
                      helperText={
                        !_.isEmpty(TeqErrorData) &&
                        TeqErrorData[index]?.data &&
                        t('Learning Activity is required')
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            <Box className={classes.unitContainer}>
              <Box sx={{ display: 'flex', direction: 'column' }}>
                <DeclareTextField
                  disabled={res.type === TransferEquivalencyType[0].value}
                  variant="outlined"
                  name="units"
                  inputProps={{ min: 0 }}
                  type="number"
                  value={res.units}
                  error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.units}
                  onChange={(e) => updateRequestData('units', e.target.value, index)}
                  helperText={
                    !_.isEmpty(TeqErrorData) && TeqErrorData[index]?.units && t('Units is required')
                  }
                />
                {requestData.length > 1 ? (
                  <Box sx={{ position: 'relative' }}>
                    <DoNotDisturbOnRounded
                      className={classes.removeItem}
                      onClick={() => removeAddedRow(index)}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </>
      );
    });
  };

  const renderCourseAndRequirement = () => {
    return (
      <Fragment>
        <Box sx={{ marginTop: theme.spacing(1) }}>
          <DeclaredSelect
            MenuProps={dropDownStyle}
            inputlabel={t('Catalog') + ' *'}
            className={classes.catalogSelect}
            margin="dense"
            value={getApplicableCatalog()}
            name="catalogVersions"
            onChange={(e) => {
              handleInputChange(e);
              setRequirementRequestData(
                requirementRequestData.map((data) => ({ ...data, units: '', data: null })),
              );
            }}
            error={formError['type']}
            helperText={formError['type']?.message}>
            {catalogs
              .filter(({ status }) => status === getStatus()[0].value)
              .map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Typography variant="p" className="ElipsisText">
                    {item.id === currentCatalog?.id ? item.name + t(" (Student's)") : item.name}
                  </Typography>
                </MenuItem>
              ))}
          </DeclaredSelect>
        </Box>
        {requirementRequestData.map((res, index) => {
          return (
            <>
              <Box
                sx={{ display: 'flex', marginTop: theme.spacing(5) }}
                className={classes.tEqDestinations}>
                <Box sx={{ marginLeft: 0 }}>
                  <ArrowRightAlt sx={{ verticalAlign: 'center' }} className={classes.arrowIcon} />
                </Box>
                <Box sx={{ marginLeft: theme.spacing(2) }}>
                  <DeclaredSelect
                    margin="dense"
                    name="newEquivalencyTypes"
                    onChange={(e) => handleSelectChange(e.target.value, index)}
                    value={res.type}
                    sx={{ width: theme.spacing(31) }}
                    className={classes.select}
                    error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.type}
                    helperText={t('Type is required')}
                    disable={index === 0 ? true : false}>
                    {TransferEquivalencyType.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </DeclaredSelect>
                </Box>
                <Box sx={{ marginLeft: theme.spacing(4), display: 'flex' }}>
                  <Box sx={{ display: 'flex', direction: 'column' }}>
                    <DeclareAutocomplete
                      disableClearable
                      disabled={_.isEmpty(requirementRequestData[index].type)}
                      sx={{ width: theme.spacing(69.5) }}
                      getOptionLabel={(option) =>
                        res.type === TransferEquivalencyType[0].value
                          ? `${option.code} ${'\t'} ${option.name}`
                          : `${option?.programOption.code} ${'\t'} ${option.name}`
                      }
                      onChange={(_event, value) => {
                        updateRequestData('data', value, index);
                        updateRequestData('units', value.units || '', index);
                      }}
                      value={res.data}
                      options={
                        requirementRequestData[index].type === TransferEquivalencyType[0].value
                          ? filterCoursesByCatalog(
                              getFilteredOptionsForDropDown(
                                learningActivities,
                                requirementRequestData,
                                index,
                              ),
                            )
                          : filterRequirementsByCatalog(
                              getFilteredOptionsForDropDown(
                                allRequirements,
                                requirementRequestData,
                                index,
                              ),
                            )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.data}
                          helperText={
                            !_.isEmpty(TeqErrorData) &&
                            TeqErrorData[index]?.data &&
                            (requirementRequestData[index].type === TransferEquivalencyType[0].value
                              ? t('Learning Activity is required')
                              : t('Requirement is required'))
                          }
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box className={classes.unitContainer}>
                  <Box sx={{ display: 'flex', direction: 'column' }}>
                    <DeclareTextField
                      disabled={res.type === TransferEquivalencyType[0].value}
                      variant="outlined"
                      name="units"
                      inputProps={{ min: 0 }}
                      type="number"
                      value={res.units}
                      error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.units}
                      onChange={(e) => updateRequestData('units', e.target.value, index)}
                      helperText={
                        !_.isEmpty(TeqErrorData) &&
                        TeqErrorData[index]?.units &&
                        t('Units is required')
                      }
                    />
                    {requirementRequestData.length > 1 && index > 0 ? (
                      <Box sx={{ position: 'relative' }}>
                        <DoNotDisturbOnRounded
                          className={classes.removeItem}
                          onClick={() => removeAddedRow(index)}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </>
          );
        })}
      </Fragment>
    );
  };

  const getCatalogTabs = () => {
    return !_.isEmpty(activeCatalogs) && !_.isEmpty(data)
      ? activeCatalogs.map(({ name, id: tabCatalogId }) => {
          const tabCatalogData =
            data[tabCatalogId] && data[tabCatalogId][0] ? data[tabCatalogId][0] : null;
          return tabCatalogData
            ? {
                name: name,
                tabId: tabCatalogId,
                showWarning: tabCatalogData.status ? false : true,
                component: (
                  <Fragment>
                    {tabCatalogData?.destinationEqList.map((res, index) => {
                      return (
                        <>
                          <Box
                            sx={{ display: 'flex', marginTop: theme.spacing(5) }}
                            className={classes.tEqDestinations}>
                            <Box sx={{ marginLeft: 0 }}>
                              <ArrowRightAlt
                                sx={{ verticalAlign: 'center' }}
                                className={classes.arrowIcon}
                              />
                            </Box>
                            <Box sx={{ marginLeft: theme.spacing(2) }}>
                              <DeclaredSelect
                                margin="dense"
                                name="newEquivalencyTypes"
                                onChange={(e) => handleSelectChange(e.target.value, index)}
                                value={res.type}
                                sx={{ width: theme.spacing(31) }}
                                className={classes.select}
                                error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.type}
                                helperText={t('Type is required')}
                                disable={index === 0 ? true : false}>
                                {TransferEquivalencyType.map((item, index) => (
                                  <MenuItem value={item.value} key={index}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </DeclaredSelect>
                            </Box>
                            <Box sx={{ marginLeft: theme.spacing(4), display: 'flex' }}>
                              <Box sx={{ display: 'flex', direction: 'column' }}>
                                <DeclareAutocomplete
                                  disableClearable
                                  disabled={_.isEmpty(
                                    tabCatalogData?.destinationEqList[index].type,
                                  )}
                                  sx={{ width: theme.spacing(69.5) }}
                                  getOptionLabel={(option) =>
                                    res.type === TransferEquivalencyType[0].value
                                      ? `${option.code} ${'\t'} ${option.name}`
                                      : `${option?.programOption.code} ${'\t'} ${option.name}`
                                  }
                                  onChange={(_event, value) => {
                                    updateRequestData('data', value, index);
                                    updateRequestData('units', value.units || '', index);
                                  }}
                                  value={res.data}
                                  options={
                                    tabCatalogData?.destinationEqList[index].type ===
                                    TransferEquivalencyType[0].value
                                      ? filterCoursesByCatalog(
                                          getFilteredOptionsForDropDown(
                                            learningActivities,
                                            tabCatalogData?.destinationEqList,
                                            index,
                                          ),
                                        )
                                      : filterRequirementsByCatalog(
                                          getFilteredOptionsForDropDown(
                                            allRequirements,
                                            tabCatalogData?.destinationEqList,
                                            index,
                                          ),
                                        )
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.data}
                                      helperText={
                                        !_.isEmpty(TeqErrorData) &&
                                        TeqErrorData[index]?.data &&
                                        (tabCatalogData?.destinationEqList[index].type ===
                                        TransferEquivalencyType[0].value
                                          ? t('Learning Activity is required')
                                          : t('Requirement is required'))
                                      }
                                    />
                                  )}
                                />
                              </Box>
                            </Box>
                            <Box className={classes.unitContainer}>
                              <Box sx={{ display: 'flex', direction: 'column' }}>
                                <DeclareTextField
                                  disabled={res.type === TransferEquivalencyType[0].value}
                                  variant="outlined"
                                  name="units"
                                  inputProps={{ min: 0 }}
                                  type="number"
                                  value={res.units}
                                  error={!_.isEmpty(TeqErrorData) && TeqErrorData[index]?.units}
                                  onChange={(e) =>
                                    updateRequestData('units', e.target.value, index)
                                  }
                                  helperText={
                                    !_.isEmpty(TeqErrorData) &&
                                    TeqErrorData[index]?.units &&
                                    t('Units is required')
                                  }
                                />
                                {tabCatalogData?.destinationEqList.length > 1 && index > 0 ? (
                                  <Box sx={{ position: 'relative' }}>
                                    <DoNotDisturbOnRounded
                                      className={classes.removeItem}
                                      onClick={() => removeAddedRow(index)}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    <Box sx={{ display: 'flex' }}>
                      <Button
                        size="large"
                        onClick={addNewRow}
                        color="secondary"
                        className={classes.addNewButton}
                        startIcon={<ControlPoint />}>
                        <Typography className={classes.addNewText}>{t('New')}</Typography>
                      </Button>
                    </Box>
                    <Box className={classes.statusContainer}>
                      <DeclaredSelect
                        inputlabel={
                          <Typography className={classes.inputLabelText}>
                            {t('Equivalency Status') + ' *'}
                          </Typography>
                        }
                        margin="dense"
                        value={tabCatalogData.status ? tabCatalogData.status.type : 0}
                        name="teqStatus"
                        onChange={handleStatusChangeForRequirments}
                        sx={{ alignItems: 'right', width: theme.spacing(20) }}
                        className={classes.select}
                        error={formError['statusCode']}
                        helperText={formError['statusCode']?.message}>
                        <MenuItem disabled value={0}>
                          {t('Select Status')}
                        </MenuItem>
                        {teqStatuses
                          .filter(
                            ({ name, type }) =>
                              !name.includes('student') && type != newTeqStatus.type,
                          )
                          .map((item, index) => (
                            <MenuItem value={item.type} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </DeclaredSelect>
                    </Box>
                    {!_.isEmpty(teqStatuses) &&
                    data[tabCatalogId][0].status?.type === teqStatusesEnum.APPROVED_WITH_NOTE ? (
                      <Box sx={{ marginTop: theme.spacing(4) }}>
                        <DeclareTextField
                          sx={{ width: theme.spacing(132) }}
                          inputlabel={
                            <Typography className={classes.inputLabelText}>
                              {t('Note') + ' *'}
                            </Typography>
                          }
                          variant="outlined"
                          name="transferNote"
                          value={tabCatalogData.note?.message}
                          onChange={handleNoteChangeForRequirments}
                          error={formError['transferNote']}
                          helperText={formError['transferNote']?.message}
                          inputProps={{
                            maxLength: 1024,
                          }}
                        />
                      </Box>
                    ) : null}
                  </Fragment>
                ),
                callBack: (index) => {
                  setCurrentSelectedEquivalency(data[index][0]);
                },
              }
            : null;
        })
      : null;
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }} ref={containerRef}>
        <StudentStatusBar />
        <Paper sx={{ padding: '20px' }} className={classes.mainContainer}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            <Typography className={classes.typography}>
              {isEditView ? t('Edit Transfer Equivalency') : t('New Transfer Equivalency')}
            </Typography>
          </Box>
          <Grid sx={{ display: 'flex', marginTop: theme.spacing(6) }}>
            <Grid item>
              <DeclareTextField
                sx={{ width: theme.spacing(16) }}
                inputlabel={
                  <Typography className={classes.inputLabelText}>
                    {t('Transferring in') + ' *'}
                  </Typography>
                }
                variant="outlined"
                name="transferringCode"
                disabled
                onChange={handleInputChange}
                error={formError['transferringIn']}
                helperText={formError['transferringIn']?.message}
                value={values.transferringCode}
              />
            </Grid>
            <Grid item sx={{ marginLeft: theme.spacing(4) }}>
              <DeclareTextField
                sx={{ width: theme.spacing(95) }}
                inputlabel={
                  <Typography className={classes.inputLabelText}>
                    {t('Learning Activity Name') + ' *'}
                  </Typography>
                }
                variant="outlined"
                name="transferringName"
                onChange={handleInputChange}
                error={formError['name']}
                helperText={formError['name']?.message}
                value={values.transferringName}
                disabled={isLaNameFieldDisabled}
              />
            </Grid>
            <Grid item sx={{ marginLeft: theme.spacing(8.5) }} className={classes.unitContainer}>
              <DeclareTextField
                inputlabel={
                  <Typography className={classes.inputLabelText}>{t('units') + ' *'}</Typography>
                }
                onBlur={(val) => {
                  const { name, value } = val.target;
                  setUnitsOnBlur(name, value);
                }}
                inputProps={{ min: 0 }}
                value={values.transferringUnits}
                variant="outlined"
                type="number"
                name="transferringUnits"
                onChange={handleInputChange}
                error={formError['units']}
                helperText={formError['units']?.message}
              />
            </Grid>
          </Grid>
          {!isEditView ? (
            <Box className={classes.transferTypeSelection}>
              <FormControl>
                <RadioGroup
                  className={classes.radioGroup}
                  name={'transferTypeSelection'}
                  onChange={(event, value) => {
                    setTransferType(value);
                  }}>
                  <FormControlLabel
                    value={transferringOptionTypes.COURSE_ONLY}
                    control={
                      <Radio
                        checked={transferType === transferringOptionTypes.COURSE_ONLY}
                        size="small"
                      />
                    }
                    label={t('Transfer Courses Only')}
                  />
                  <DeclareFormControl
                    value={transferringOptionTypes.WITH_REQUIREMENT}
                    control={
                      <Radio
                        checked={transferType === transferringOptionTypes.WITH_REQUIREMENT}
                        size="small"
                      />
                    }
                    label={t('Transfer Courses and Requirements')}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : null}
          {transferType === transferringOptionTypes.COURSE_ONLY
            ? renderCourseOnly()
            : !isEditView
            ? renderCourseAndRequirement()
            : null}
          {isEditView && transferType === transferringOptionTypes.WITH_REQUIREMENT ? (
            !_.isEmpty(data) ? (
              <Box
                sx={{
                  marginTop: '32px',
                  maxWidth: `${tabViewMaxWidth}px`,
                }}
                className={classes.tabContainer}>
                <TabView
                  warningMsg={t('Transfer equivalency is not available for this catalog.')}
                  tabs={getCatalogTabs()}
                  selectedTab={catalogs.findIndex(({ id }) => id === state.catalogId)}
                  enableEllipsisText={true}
                />
              </Box>
            ) : null
          ) : (
            <Fragment>
              <Box sx={{ display: 'flex' }}>
                <Button
                  size="large"
                  onClick={addNewRow}
                  color="secondary"
                  className={classes.addNewButton}
                  startIcon={<ControlPoint />}>
                  <Typography className={classes.addNewText}>{t('New')}</Typography>
                </Button>
              </Box>
              <Box className={classes.statusContainer}>
                <DeclaredSelect
                  inputlabel={
                    <Typography className={classes.inputLabelText}>
                      {t('Equivalency Status') + ' *'}
                    </Typography>
                  }
                  margin="dense"
                  value={values.teqStatus ? values.teqStatus : 0}
                  name="teqStatus"
                  onChange={handleInputChange}
                  sx={{ alignItems: 'right', width: theme.spacing(20) }}
                  className={classes.select}
                  error={formError['statusCode']}
                  helperText={formError['statusCode']?.message}>
                  <MenuItem disabled value={0}>
                    {t('Select Status')}
                  </MenuItem>
                  {teqStatuses
                    .filter(
                      ({ name, type }) => !name.includes('student') && type != newTeqStatus.type,
                    )
                    .map((item, index) => (
                      <MenuItem value={item.type} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                </DeclaredSelect>
              </Box>
              {!_.isEmpty(teqStatuses) &&
              values.teqStatus === teqStatusesEnum.APPROVED_WITH_NOTE ? (
                <Box sx={{ marginTop: theme.spacing(4) }}>
                  <DeclareTextField
                    sx={{ width: theme.spacing(132) }}
                    inputlabel={
                      <Typography className={classes.inputLabelText}>{t('Note') + ' *'}</Typography>
                    }
                    variant="outlined"
                    name="transferNote"
                    value={values.transferNote}
                    onChange={handleInputChange}
                    error={formError['transferNote']}
                    helperText={formError['transferNote']?.message}
                    inputProps={{
                      maxLength: 1024,
                    }}
                  />
                </Box>
              ) : null}
            </Fragment>
          )}
        </Paper>
        <Box sx={{ float: 'right', marginTop: theme.spacing(2) }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => updateUnitsAndTeq(isEditView)}
            sx={{ marginRight: theme.spacing(2) }}>
            {isEditView ? t('update') : t('save')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            className={classes.button}
            onClick={() => {
              navigateBack(false);
            }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
