import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DeclareForm, useDeclareForm } from '../../../components/DeclareForm';
import DeclareTextField from '../../../components/DeclareTextField';
import { settings, settingsCategories } from '../../../helpers/settingsHelper';
import { createSettings } from '../../../redux/actions/settingActions';
import { getSettings } from '../../../redux/actions/settingActions';

export default function PathwaySettings({ pathwaySettings }) {
  const { t } = useTranslation();
  const { values, setValues, handleInputChange } = useDeclareForm({
    [settings[settingsCategories.PATHWAYS].MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID]: 0,
  });
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const formError = useSelector((state) => state.notifications.formError);

  useEffect(() => {
    dispatch(getSettings(orgId));
  }, []);

  const saveSettings = () => {
    const data = {
      category: settingsCategories.PATHWAYS,
      settings: values,
    };
    dispatch(createSettings(orgId, data));
  };

  useEffect(() => {
    if (!_.isEmpty(pathwaySettings)) {
      Object.keys(pathwaySettings.settings).map((data) => {
        setValues({ [data]: pathwaySettings.settings[data] });
      });
    }
  }, [pathwaySettings]);

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={12} className="left">
            <DeclareTextField
              sx={{ width: '60px !important' }}
              inputlabel={
                <Typography fontWeight={800}>
                  {t('Minimum Number of Units Per Term for Students on Financial Aid') + ' *'}
                </Typography>
              }
              variant="outlined"
              placeholder={t('Units')}
              value={
                values[
                  settings[settingsCategories.PATHWAYS].MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID
                ]
              }
              name={
                settings[settingsCategories.PATHWAYS].MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID
              }
              onChange={handleInputChange}
              error={
                !_.isNil(
                  Object.values(formError).find(({ message }) =>
                    message.includes(
                      settings[settingsCategories.PATHWAYS]
                        .MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID,
                    ),
                  ),
                )
              }
              helperText={Object.values(formError)
                .find(({ message }) =>
                  message.includes(
                    settings[settingsCategories.PATHWAYS]
                      .MIN_NUM_OF_UNITS_PER_TERM_ON_FINANCIAL_AID,
                  ),
                )
                ?.message.replace(/"([^"]+)"/g, '')}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
          }}
          onClick={() => {
            saveSettings();
          }}>
          {t('save')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
