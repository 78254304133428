import { actionTypes } from '../constants/actionTypes';

const initState = {
  departmentList: [],
};

const collegeReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.COLLEGES.SET:
      return { ...state, departmentList: payload };
    case actionTypes.SELECT_ORG:
      return { ...state, departmentList: [] };
    default:
      return state;
  }
};

export default collegeReducer;
