import { HttpService } from './HttpService';

export class OrgService extends HttpService {
  async getAll() {
    return await this.get('organizations');
  }

  async save(body) {
    return await this.post('organizations', body);
  }

  async update(body) {
    return await this.put('organizations', body);
  }
}
