import {
  processAcademicYears,
  updateAcademicYears,
  processedTermInstances,
} from '../../helpers/termsHelper';
import { actionTypes } from '../constants/actionTypes';

const initState = {
  termList: [],
  academicYears: [],
  termInstances: {},
};

const termReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TERMS:
      return { ...state, termList: payload, academicYears: [], termInstances: {} };
    case actionTypes.SET_ACADEMIC_YEARS_WITH_TERM_INSTANCES:
      const processedAcademicYears = processAcademicYears(payload, state.termList);
      const processedTermInstancesData = processedTermInstances(processedAcademicYears);
      return {
        ...state,
        academicYears: processedAcademicYears,
        termInstances: processedTermInstancesData,
      };
    case actionTypes.UPDATE_ACADEMIC_YEARS_WITH_TERM_INSTANCES:
      return {
        ...state,
        academicYears: updateAcademicYears(state.academicYears, payload.data, payload.year),
      };
    case actionTypes.SELECT_ORG:
      return initState;
    default:
      return state;
  }
};

export default termReducer;
