import React from 'react';
import { Box, Paper, Typography, Stack, Divider } from '@mui/material';
import { ArrowRightAlt, AddCircleOutlineRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { appRoutes } from '../../../helpers/routes';
import { transferEquivalencyRawStyles } from '../../../components/styles/transferEquivalencyRowStyles';
import { groupBy } from 'lodash';
import TeqSourceLA from './TeqSourceLA';
import TeqDestinationLA from './TeqDestinationLA';

export default function TransferEquivalencyRow(props) {
  const {
    value: { code, name, units, equivalencies, sourceLearningActivityId, transferringCourseId },
    selectedOrg,
    removeItem,
    teqStatuses,
    selectedTransferOption,
    state,
    showDivider,
  } = props;

  const { t } = useTranslation();
  const classes = transferEquivalencyRawStyles();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const { teqStatusesEnum } = useSelector((state) => state.transferEquivalency);

  const navigateToTransferEquivalency = (laCode, laId, laName, laUnits, equivalency) => {
    const state = {
      sourceOrgId: selectedOrg.id,
      sourceLaId: laId,
      sourceLaCode: laCode,
      sourceLaName: laName,
      sourceLaUnits: laUnits,
      transferringCourseId: transferringCourseId,
    };

    const isNewView = _.isEmpty(equivalency);

    isNewView
      ? navigate(generatePath(appRoutes.advisor.CREATE_TRANSFER_EQUIVALENCIES, { orgId }), {
          state,
        })
      : navigate(
          generatePath(appRoutes.advisor.EDIT_TRANSFER_EQUIVALENCIES, {
            orgId,
            transferEquivalencyId: equivalency.destinationEqList[0].transferEquivalencyId,
          }),
          { state: { ...state, ...equivalency } },
        );
  };

  const updateTransferEquivalency = (code, id, name, units, equivalency) => {
    navigateToTransferEquivalency(code, id, name, units, equivalency);
  };

  const getGroupingKey = (equivalency) => {
    if (
      equivalency.status?.type === teqStatusesEnum?.APPROVED_WITH_NOTE ||
      equivalency.status?.type === teqStatusesEnum?.APPROVED
    ) {
      return t('Approved');
    }
    return t(!equivalency.status ? t('New') : equivalency.status.name);
  };
  const groupedEquivalencies = groupBy(equivalencies, getGroupingKey);
  const isNewEquivalency = () => _.isEmpty(equivalencies);

  const destinations = isNewEquivalency() ? (
    <React.Fragment>
      <Typography fontWeight={800} fontSize={12} marginTop={3} paddingBottom={0.25}>
        {t('New')}
      </Typography>
      <TeqDestinationLA
        selectedOrg={selectedOrg}
        teqStatuses={teqStatuses}
        empty={true}
        transferEquivalency={true}
        selectedTransferOption={selectedTransferOption}
        name={name}
        handleClickEquivalency={navigateToTransferEquivalency}
        state={state}
        code={code}
        groupId={sourceLearningActivityId}
        units={units}
        key={`empty-row-${sourceLearningActivityId}`}
      />
    </React.Fragment>
  ) : (
    Object.keys(groupedEquivalencies).map((typeKey) => (
      <React.Fragment key={`group-${typeKey}`}>
        <Typography fontWeight={800} fontSize={12} marginTop={3} paddingBottom={0.25}>
          {typeKey}
        </Typography>
        {groupedEquivalencies[typeKey].map((equivalency) => (
          <TeqDestinationLA
            selectedOrg={selectedOrg}
            teqStatuses={teqStatuses}
            equivalencies={equivalencies}
            equivalency={equivalency}
            groupId={sourceLearningActivityId}
            code={code}
            name={name}
            units={units}
            transferEquivalency={true}
            handleClickEquivalency={updateTransferEquivalency}
            selectedTransferOption={selectedTransferOption}
            state={state}
            key={equivalency.id || equivalency.code}
          />
        ))}
      </React.Fragment>
    ))
  );

  return (
    <React.Fragment>
      <Paper flex={1} className={classes.rawContainer}>
        <Box flex={5} className={classes.transferInCourseContainer}>
          <TeqSourceLA code={code} name={name} units={units} removeAction={removeItem} />
        </Box>
        <Box flex={1} className={classes.arrowContainer}>
          <ArrowRightAlt className={classes.arrowIcon} />
        </Box>
        <Box flex={6}>
          <Stack flex={1}>
            {destinations}
            {!isNewEquivalency() && (
              <Stack direction="row" gap={1} className={classes.addEquivalency}>
                <AddCircleOutlineRounded
                  className={classes.icon}
                  color="secondary"
                  onClick={() =>
                    navigateToTransferEquivalency(code, sourceLearningActivityId, name, units)
                  }
                />
                <Typography fontWeight={800}>{t('Add Equivalency')}</Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Paper>
      {showDivider && <Divider className={classes.rawDivider} />}
    </React.Fragment>
  );
}
