import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  sitOutCard: {
    display: 'flex',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    alignContent: 'center',
    height: '80px',
  },
}));

function SitOutComponent() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.sitOutCard}>
      <Typography variant="body1" fontSize={16} fontWeight={700}>
        {t('Student defined break.')}
      </Typography>
    </Box>
  );
}

export default SitOutComponent;
