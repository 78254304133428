import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { camelCase } from 'lodash';

import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusCell = (props) => {
  const { value, name, sx, width = 100 } = props;
  const { t } = useTranslation();

  let backgroundColor = 'default';
  let color = '#000';
  if (
    [
      'ACTIVE',
      'COMPLETED',
      'CONFIRMED_SENT',
      'PLANNED',
      'PATHWAY_COMPLETED',
      'PATHWAY_SENT',
    ].includes(value)
  ) {
    backgroundColor = 'primary';
    color = '#fff';
  } else if (
    ['INACTIVE', 'UNPLANNED', 'SIT_OUT', 'CANCEL', 'PATHWAY_DROPPED', 'PATHWAY_INVALID'].includes(
      value,
    )
  ) {
    backgroundColor = 'error';
    color = '#fff';
  } else if (['PENDING', 'BUILD', 'ARCHIVED', 'PARTIALLY', 'IN_PLANNING'].includes(value)) {
    backgroundColor = 'warning';
    color = '#fff';
  } else if (['IN_PROGRESS'].includes(value)) {
    backgroundColor = 'info';
    color = '#fff';
  }

  return (
    <Chip
      label={t(name) || t(camelCase(value))}
      color={backgroundColor}
      sx={[{ color, width: `${width}px` }, sx]}
    />
  );
};

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default StatusCell;
