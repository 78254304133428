import { Box, Container, Typography, MenuItem, Grid, Checkbox, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, generatePath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../components/DeclareBreadcrumb';
import {
  getStudents,
  setCurrentSelectedStudent,
  getStudentStatus,
  getStudentTeqAssignments,
  setCompletedCourses,
  setStudents,
  setQueryStudentListOption,
} from '../../redux/actions/studentActions';
import StatusCell from '../../components/StatusCell';
import { Crop, Edit, Download } from '@mui/icons-material';
import DeclareDataTable from '../../components/DeclareDataTable';
import { appRoutes } from '../../helpers/routes';
import _ from 'lodash';
import {
  getStudentTransferEvaluations,
  setCompletedCourseReq,
} from '../../redux/actions/transferEvaluationActions';
import {
  EvaluationStatusTypes,
  studentListQuery,
  advisorStatusForStudents,
  urlParamsToFilterStudentList,
  localStorageKeys,
  noOfAdditionalValuesForFilterDropDowns,
  statusesOfValidPathways,
} from '../../helpers/appConstants';
import { getProgramMaps } from '../../redux/actions/programMapActions';
import DeclareDialog from '../../components/DeclareDialog';
import { makeStyles, useTheme } from '@mui/styles';
import {
  getStudentListViewOption,
  getQueryParams,
  saveTableOptions,
  studentsListAggregator,
  getIdentityEmail,
  formatName,
  advisorsSortByName,
  getCurrentCatalog,
} from '../../helpers/studentsHelper';
import {
  convertToQueryString,
  createUrlWithQueryParams,
  getFilterParamsFromLocalStorage,
  saveFilterParamsToLocalStorage,
  MenuPropStyles,
} from '../../helpers/common';
import { getLearningActivities } from '../../redux/actions/learningActivityActions';
import DeclaredSelect from '../../components/DeclareSelect';
import { getAllAdvisors, changeAdvisorForStudent } from '../../redux/actions/advisorActions';
import { generateExcelForStudentList } from '../../processes/exportStudentList';
import { useDeclareForm } from '../../components/DeclareForm';
import { getPathwayStatus } from '../../redux/actions/pathwayActions';
import { reorderPathwayStatusList } from '../../helpers/pathwayHelper';
import { getAcademicYearsWithTermInstances, getAllTerms } from '../../redux/actions/termActions';
import { getCatalogs } from '../../redux/actions/catalogActions';
import { settings, settingsCategories } from '../../helpers/settingsHelper';

const useStyles = makeStyles((theme) => ({
  dataTableRoot: {
    '& .MuiTableRow-hover:hover': {
      cursor: 'pointer',
    },
  },

  advisorCell: {
    display: 'flex',
    '&:hover .MuiSvgIcon-root': {
      visibility: 'visible',
    },
    '& .MuiSvgIcon-root': {
      visibility: 'hidden',
    },
  },
  advisorEditDropDown: {
    display: 'flex',
    width: theme.spacing(20),
  },
  advisorEditIcon: {
    paddingLeft: theme.spacing(1),
  },
  dropDownContainer: {
    marginTop: theme.spacing(15),
  },
  dropDownBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropDownGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  studentListDownloadBtn: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  dropDown: {
    width: theme.spacing(30),
  },

  filterDropDownBox: {
    display: 'flex',
    gap: 10,
  },
}));

export default function StudentList() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    list: studentList,
    studentStatusList,
    currentSelectedStudent,
    studentQueryOption,
  } = useSelector((state) => state.students);
  const programMaps = useSelector((state) => state.programMaps.programMapList);
  const { userInfo } = useSelector((state) => state.auth);
  const { termList, academicYears } = useSelector((state) => state.terms);
  const { advisorsList } = useSelector((state) => state.advisors);
  const { selectedOrg } = useSelector((state) => state.allOrgs);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const studentListOption = Object.values(studentListQuery).map((value) =>
    _.startCase(value.split('_').join(' ')),
  );
  const classes = useStyles();
  const [advisor, setAdvisor] = useState('');
  const [rawStudentList, setRawStudentList] = useState([]);
  const [studentAdvisorEmail, setStudentAdvisorEmail] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showAdvisorDropDown, setShowAdvisorDropDown] = useState(false);
  const [sortedAdvisorsList, setSortedAdvisorsList] = useState([]);
  const studentListLocalStorageKey = 'tableOption';
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [totalStudents, setTotalStudents] = useState([]);
  const location = useLocation();
  const [reorderedPathwayStatus, setReorderedPathwayStatus] = useState([]);
  const { values, setValues, handleInputChange } = useDeclareForm({
    selectedPathwayStatuses: [],
    selectedTerms: [],
  });
  const { pathwayStatus } = useSelector((state) => state.pathway);
  const searchParams = new URLSearchParams(location.search);
  const [allTerms, setAllTerms] = useState([]);
  const [queryForStudentList, setQueryForStudentList] = useState({});
  const { organizationSettings } = useSelector((state) => state.settings);
  const [transferEQAndTransferEVHidden, setTransferEQAndTransferEVHidden] = useState(true);

  useEffect(() => {
    const options = getStudentListViewOption(studentListLocalStorageKey, studentQueryOption);
    const queryStudentOption = _.isEmpty(options.queryStudent)
      ? studentListOption[1]
      : options.queryStudent;

    dispatch(setQueryStudentListOption(queryStudentOption));
    saveTableOptions(studentListLocalStorageKey, {
      ...options,
      queryStudent: queryStudentOption,
    });

    if (_.isEmpty(programMaps)) {
      dispatch(getProgramMaps(orgId));
    }

    if (_.isEmpty(studentStatusList)) {
      dispatch(getStudentStatus(orgId));
    }

    if (_.isEmpty(termList)) {
      dispatch(getAllTerms(orgId));
    }

    if (_.isEmpty(learningActivities)) {
      dispatch(getLearningActivities(orgId));
    }

    if (_.isEmpty(advisorsList)) {
      dispatch(getAllAdvisors(orgId));
    }
    if (_.isEmpty(pathwayStatus)) {
      dispatch(getPathwayStatus(orgId));
    }

    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  useEffect(() => {
    !_.isEmpty(userInfo) && setAdvisor(getIdentityEmail(userInfo.username));
  }, [userInfo]);

  useEffect(() => {
    if (!_.isEmpty(advisorsList)) {
      const advisorsListSorted = advisorsSortByName(advisorsList);
      setSortedAdvisorsList(advisorsListSorted);
      const selectedAdvisors = setSelectedAdvisorsInPageLoad(advisorsListSorted);
      setSelectedAdvisors(selectedAdvisors);
    }
  }, [advisorsList]);

  useEffect(() => {
    let selectedPathwayStatuses = [];
    let selectedAllTerms = [];
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }
    if (!_.isEmpty(academicYears) && !_.isEmpty(termList)) {
      const filteredAllTerms = academicYears.flatMap((academicYear) => academicYear.termInstances);
      setAllTerms(filteredAllTerms);
      selectedAllTerms = setSelectedTermsInPageLoad(filteredAllTerms);
    }

    if (!_.isEmpty(pathwayStatus)) {
      const reOrderedPathwayStatuses = reorderPathwayStatusList(pathwayStatus, 'type');
      setReorderedPathwayStatus(reOrderedPathwayStatuses);
      selectedPathwayStatuses = setSelectedPathwayStatusesInPageLoad(reOrderedPathwayStatuses);
    }

    if (!_.isEmpty(selectedAllTerms) && !_.isEmpty(selectedPathwayStatuses)) {
      setValues({
        ...values,
        selectedTerms: selectedAllTerms,
        selectedPathwayStatuses: selectedPathwayStatuses,
      });
    }
  }, [termList, academicYears, pathwayStatus]);

  useEffect(() => {
    if (!_.isEmpty(organizationSettings)) {
      const moduleAndFeaturesSettings = organizationSettings.find(
        ({ category }) => category === settingsCategories.MODULES_AND_FEATURES,
      );
      if (!_.isNil(moduleAndFeaturesSettings)) {
        const transferEvaluationModuleHiddenStatus =
          moduleAndFeaturesSettings.settings[
            settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE
          ];
        if (!_.isNil(transferEvaluationModuleHiddenStatus)) {
          setTransferEQAndTransferEVHidden(!transferEvaluationModuleHiddenStatus);
        }
      }
    }
  }, [organizationSettings]);

  const setSelectedTermsInPageLoad = (selectedTerms) => {
    let filteredParams = getFilterParamsFromLocalStorage(
      localStorageKeys.STUDENT_LIST_FILTER_PARAMS,
    );

    filteredParams = filteredParams && filteredParams[orgId];
    let dropDownValues = selectedTerms;
    if (!_.isUndefined(filteredParams?.selectedTerms) && !_.isEmpty(filteredParams.selectedTerms)) {
      if (!filteredParams.selectedTerms.includes(urlParamsToFilterStudentList.ALL)) {
        dropDownValues = filteredParams.selectedTerms.map((param) => {
          const value = selectedTerms.find((status) => param == status.id);
          return value;
        });
      }
    }
    return dropDownValues;
  };

  const setSelectedPathwayStatusesInPageLoad = (reOrderedPathwayStatuses) => {
    let filteredParams = getFilterParamsFromLocalStorage(
      localStorageKeys.STUDENT_LIST_FILTER_PARAMS,
    );

    filteredParams = filteredParams && filteredParams[orgId];
    let dropDownValues = reOrderedPathwayStatuses;
    if (!_.isUndefined(filteredParams?.pathwayStatus) && !_.isEmpty(filteredParams.pathwayStatus)) {
      if (!filteredParams.pathwayStatus.includes(urlParamsToFilterStudentList.ALL)) {
        dropDownValues = filteredParams.pathwayStatus.map((param) => {
          const value = reOrderedPathwayStatuses.find((status) => param == status.id);
          return value;
        });
      }
    }
    return dropDownValues;
  };

  const setSelectedAdvisorsInPageLoad = (advisorListSorted) => {
    let filteredParams = getFilterParamsFromLocalStorage(
      localStorageKeys.STUDENT_LIST_FILTER_PARAMS,
    );

    filteredParams = filteredParams && filteredParams[orgId];
    let dropDownValues = [...advisorListSorted, advisorStatusForStudents.UNASSIGNED];
    if (!_.isUndefined(filteredParams?.advisors) && !_.isEmpty(filteredParams.advisors)) {
      if (!filteredParams.advisors.includes(urlParamsToFilterStudentList.ALL)) {
        dropDownValues = filteredParams.advisors.map((param) => {
          if (param === advisorStatusForStudents.UNASSIGNED) {
            return advisorStatusForStudents.UNASSIGNED;
          } else {
            const value = advisorListSorted.find((advisor) => param == advisor.user.id);
            return value;
          }
        });
      }
    }
    return dropDownValues;
  };

  useEffect(() => {
    if (!_.isEmpty(programMaps) && !_.isEmpty(studentStatusList) && !_.isEmpty(latestCatalog)) {
      const { id: catalogId } = latestCatalog;
      const filteredPM = programMaps.filter(({ catalogs }) =>
        catalogs.find((data) => data.id === catalogId),
      );

      const studentData = studentsListAggregator(rawStudentList, filteredPM, programMaps);
      dispatch(setStudents(studentData));
    }
  }, [programMaps, rawStudentList, studentStatusList, latestCatalog]);

  const getStudentFirstTermsFromUrlParameters = (urlParameters) => {
    let urlParams = [];
    if (urlParameters.includes(urlParamsToFilterStudentList.ALL)) {
      urlParams = [urlParamsToFilterStudentList.ALL];
    } else {
      urlParams = urlParameters.map((parameter) => {
        const termInstance = allTerms.find((aTerm) => parameter === aTerm.id);
        const value = { id: termInstance.termId, year: termInstance.calendarYear };
        return value;
      });
    }
    return urlParams;
  };

  const getPathwayStatusesValuesFromUrlParameters = (urlParameters) => {
    let urlParams = [];
    if (urlParameters.includes(urlParamsToFilterStudentList.ALL)) {
      urlParams = [urlParamsToFilterStudentList.ALL];
    } else {
      urlParams = urlParameters.map((parameter) => {
        return reorderedPathwayStatus.find((status) => parameter === status.id).type;
      });
    }
    return urlParams.join();
  };

  const getAdvisorsFromUrlParameters = (urlParameters) => {
    let urlParams = [];
    if (urlParameters.includes(urlParamsToFilterStudentList.ALL)) {
      urlParams = [urlParamsToFilterStudentList.ALL];
    } else {
      urlParams = urlParameters.map((parameter) => {
        if (parameter === advisorStatusForStudents.UNASSIGNED) {
          return advisorStatusForStudents.UNASSIGNED;
        } else {
          return sortedAdvisorsList.find((advisor) => parameter === advisor.user.id).email;
        }
      });
    }
    return urlParams.join();
  };

  useEffect(() => {
    if (
      (!_.isEmpty(studentQueryOption) && !_.isEmpty(advisor) && !_.isEmpty(sortedAdvisorsList),
      !_.isEmpty(reorderedPathwayStatus) && !_.isEmpty(allTerms))
    ) {
      dispatchToGetStudentsList();
    }
  }, [studentQueryOption, advisor, sortedAdvisorsList, reorderedPathwayStatus, allTerms]);

  const getAdvisorsUrlParameters = () => {
    let advisors = null;
    if (
      selectedAdvisors.length ===
      sortedAdvisorsList.length + noOfAdditionalValuesForFilterDropDowns.ADVISOR
    ) {
      advisors = [urlParamsToFilterStudentList.ALL];
    } else {
      advisors = selectedAdvisors.map((selectedAdvisor) => {
        if (selectedAdvisor === advisorStatusForStudents.UNASSIGNED) {
          return selectedAdvisor;
        } else {
          return selectedAdvisor.user.id;
        }
      });
    }
    return advisors;
  };

  const getPathwayStatusesUrlParameters = () => {
    let pathwayStatuses = null;
    if (values.selectedPathwayStatuses.length === reorderedPathwayStatus.length) {
      pathwayStatuses = [urlParamsToFilterStudentList.ALL];
    } else {
      pathwayStatuses = values.selectedPathwayStatuses.map((selectedStatus) => {
        return selectedStatus.id;
      });
    }
    return pathwayStatuses;
  };

  const getStudentFirstTermsUrlParameters = () => {
    let selectedTerms = null;
    if (values.selectedTerms.length === allTerms.length) {
      selectedTerms = [urlParamsToFilterStudentList.ALL];
    } else {
      selectedTerms = values.selectedTerms.map((selectedTerm) => {
        return selectedTerm.id;
      });
    }
    return selectedTerms;
  };

  const setUrlParameters = (urlParam) => {
    navigate(
      generatePath(createUrlWithQueryParams(appRoutes.advisor.STUDENT_LIST, urlParam), {
        orgId: orgId,
      }),
    );
  };

  const dispatchToGetStudentsList = (queryData = null) => {
    let options = queryData;
    if (_.isNil(queryData)) {
      options = getStudentListViewOption(studentListLocalStorageKey, studentQueryOption);
    }
    const advisorsUrlParameters = getAdvisorsUrlParameters();
    const advisorParamsToRequestData = getAdvisorsFromUrlParameters(advisorsUrlParameters);
    const pathwayStatusesUrlParameters = getPathwayStatusesUrlParameters();
    const pathwayStatusesToRequestData = getPathwayStatusesValuesFromUrlParameters(
      pathwayStatusesUrlParameters,
    );
    const studentFirstTermsUrlParameters = getStudentFirstTermsUrlParameters();
    const studentFirstTermsRequestData = getStudentFirstTermsFromUrlParameters(
      studentFirstTermsUrlParameters,
    );
    let query = getQueryParams({
      ...options,
      advisors: advisorParamsToRequestData,
      pathwayStatuses: pathwayStatusesToRequestData,
      studentFirstTerms: encodeURIComponent(JSON.stringify(studentFirstTermsRequestData)),
    });

    let studentQueryOptionResetValue = studentListOption[0];
    if (searchParams.get('students') === urlParamsToFilterStudentList.MY_STUDENTS) {
      query = getQueryParams({
        ...options,
        advisor,
        advisors: advisorParamsToRequestData,
        pathwayStatuses: pathwayStatusesToRequestData,
        studentFirstTerms: encodeURIComponent(JSON.stringify(studentFirstTermsRequestData)),
      });
      studentQueryOptionResetValue = studentListOption[1];
    }
    const queryString = convertToQueryString(query);
    dispatch(setQueryStudentListOption(studentQueryOptionResetValue));
    let urlParam = {
      [orgId]: {
        students: searchParams.get('students'),
        advisors: advisorsUrlParameters,
        pathwayStatus: pathwayStatusesUrlParameters,
        selectedTerms: studentFirstTermsUrlParameters,
      },
    };
    saveFilterParamsToLocalStorage(localStorageKeys.STUDENT_LIST_FILTER_PARAMS, urlParam);
    setQueryForStudentList(query);
    setUrlParameters(urlParam[orgId]);
    if (
      !_.isEmpty(pathwayStatusesToRequestData) &&
      !_.isEmpty(advisorParamsToRequestData) &&
      !_.isEmpty(studentFirstTermsRequestData)
    ) {
      dispatch(getStudents(orgId, queryString, handleGetStudentResponse));
    } else if (
      _.isEmpty(pathwayStatusesToRequestData) ||
      _.isEmpty(advisorParamsToRequestData) ||
      _.isEmpty(studentFirstTermsRequestData)
    ) {
      setRawStudentList([]);
      setTotalStudents(null);
      resetStateValues();
    }
  };

  const handleDispatchToGetStudentList = () => {
    const data = {
      ...studentListViewOptions,
      page: 0,
      queryStudent: studentQueryOption,
    };
    saveTableOptions(studentListLocalStorageKey, data);
    dispatchToGetStudentsList(data);
  };

  const resetStateValues = () => {
    setSelectedRowIndex(null);
    setShowAdvisorDropDown(false);
    setStudentAdvisorEmail(null);
  };

  const handleGetStudentResponse = (res) => {
    const { data, totalItems } = res;

    setRawStudentList(data);
    setTotalStudents(totalItems);
    const queryData = getStudentListViewOption(studentListLocalStorageKey, studentQueryOption);

    saveTableOptions(studentListLocalStorageKey, {
      ...queryData,
      totalItems: totalItems,
    });
    resetStateValues();
  };

  const navigateToStudentPlanning = () => {
    navigate(generatePath(appRoutes.advisor.STUDENT_PLANNING, { orgId: orgId }));
  };

  const handleOnRowClick = (data) => {
    const studentId = data.studentId;
    const studentData = studentList.find((info) => info.studentId === studentId);
    dispatch(setCompletedCourseReq([]));
    dispatch(setCompletedCourses({ Completed: [], Transferred: [], Exempt: [] }));

    dispatch(
      setCurrentSelectedStudent({
        ...studentData,
        currentCatalog: getCurrentCatalog(
          programMaps,
          latestCatalog,
          studentData.programMapId,
          catalogs,
          studentData.catalogId,
        ),
      }),
    );
    dispatch(
      getStudentTeqAssignments(orgId, {
        ...studentData,
        currentCatalog: getCurrentCatalog(
          programMaps,
          latestCatalog,
          studentData.programMapId,
          catalogs,
          studentData.catalogId,
        ),
      }),
    );
    const studentHasValidPathway = statusesOfValidPathways.some(
      (status) => status === studentData.plannedStatus.type,
    );

    const callback = (tEvList) => {
      const programOptionId = studentData.programOption?.id;
      const approvedEvaluationProgramOptions = _.values(tEvList)
        .filter(({ statusCode }) => statusCode.type === EvaluationStatusTypes.USED_IN_PLANNING)
        .map(({ programMapId }) => programMaps.find(({ id }) => id === programMapId)?.optionId);
      const approvedMajorPresent = approvedEvaluationProgramOptions.some(
        (id) => id === programOptionId,
      );

      if (transferEQAndTransferEVHidden || approvedMajorPresent) {
        navigateToStudentPlanning();
      } else {
        setDialogOpen(true);
      }
    };

    if (studentHasValidPathway) {
      navigateToStudentPlanning();
    } else {
      dispatch(getStudentTransferEvaluations(orgId, studentData.id, callback));
    }
  };

  const navigateToTransferEvaluations = (data) => {
    dispatch(
      setCurrentSelectedStudent({
        ...data,
        currentCatalog: getCurrentCatalog(
          programMaps,
          latestCatalog,
          data.programMapId,
          catalogs,
          data.catalogId,
        ),
      }),
    );
    navigate(generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, { orgId: orgId }));
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const setShowDropDown = (advisorForSelectedStudent) => {
    setSelectedRowIndex(advisorForSelectedStudent);
    setShowAdvisorDropDown(true);
    setStudentAdvisorEmail(
      advisorForSelectedStudent.advisor.email || advisorStatusForStudents.UNASSIGNED,
    );
  };

  const handleChangeAdvisor = (e) => {
    setStudentAdvisorEmail(e.target.value);
  };

  const handleUpdateStudentAdvisor = () => {
    const callback = () => {
      dispatchToGetStudentsList();
    };

    let assignedAdvisorEmail = studentAdvisorEmail;

    if (studentAdvisorEmail === advisorStatusForStudents.UNASSIGNED) {
      assignedAdvisorEmail = null;
    }

    const requestData = {
      advisorEmail: assignedAdvisorEmail,
      studentId: selectedRowIndex.studentId,
    };

    const previousAdvisorEmail =
      studentList.find((aStudent) => aStudent.studentId === selectedRowIndex.studentId).advisor
        ?.email || null;

    if (assignedAdvisorEmail !== previousAdvisorEmail) {
      dispatch(changeAdvisorForStudent(orgId, requestData, callback));
    } else {
      resetStateValues();
    }
  };

  const columns = [
    {
      name: 'studentId',
      label: t('ID'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'advisor',
      label: t('advisor'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, index) => {
          const theIndex = index.rowIndex;
          const name = formatName(value.identity);
          return showAdvisorDropDown && selectedRowIndex === studentList[theIndex] ? (
            <DeclaredSelect
              freeSolo
              margin="dense"
              name="ruleType"
              className={_.isNull(studentAdvisorEmail) ? classes.advisorEditDropDown : null}
              onChange={handleChangeAdvisor}
              onBlur={handleUpdateStudentAdvisor}
              defaultOpen={true}
              value={studentAdvisorEmail}>
              <MenuItem value={advisorStatusForStudents.UNASSIGNED}>{t('Unassigned')}</MenuItem>
              {sortedAdvisorsList.map((item, index) => (
                <MenuItem value={item.email} key={index}>
                  {formatName(item.identity)}
                </MenuItem>
              ))}
            </DeclaredSelect>
          ) : (
            <Box className={classes.advisorCell}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {name}
              </Typography>
              <Edit className={classes.advisorEditIcon} color="secondary" fontSize="small" />
            </Box>
          );
        },
      },
    },
    {
      name: 'programOption',
      label: t('Program Option'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const programOption = value?.name;
          return (
            <Typography variant="body2" noWrap={false} sx={{ fontWeight: 700 }}>
              {programOption}
            </Typography>
          );
        },
      },
    },
    {
      name: 'units',
      label: t('Units'),
      options: {
        setCellProps: () => ({ style: { minWidth: theme.spacing(8) } }),
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'studentStatus',
      label: t('Student Status'),
      options: {
        setCellProps: () => ({ style: { minWidth: theme.spacing(17) } }),
        filter: true,
        sort: true,
        customBodyRender: (studentStatus) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {_.capitalize(studentStatus)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'plannedStatus',
      label: t('Pathway Status'),
      options: {
        setCellProps: () => ({ style: { minWidth: theme.spacing(14) } }),
        filter: true,
        sort: true,
        customBodyRender: ({ type, name }) => {
          return <StatusCell value={type} name={name} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Box>
              <Crop
                onClick={(e) => {
                  e.stopPropagation();
                  const index = studentList.findIndex((x) => x.id === value);
                  navigateToTransferEvaluations(studentList[index]);
                }}
                sx={{ cursor: 'pointer' }}
                fontSize="medium"
                color="secondary"
              />
            </Box>
          );
        },
      },
    },
  ];

  const studentListViewOptions = getStudentListViewOption(
    studentListLocalStorageKey,
    studentQueryOption,
  );

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    serverSide: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    count: studentListViewOptions.totalItems,
    page: studentListViewOptions.page,
    rowsPerPage: studentListViewOptions.rowsPerPage,
    viewColumns: false,
    sortOrder: studentListViewOptions.sort,
    onCellClick: (colData, cellMeta) => {
      const selectedStudent = studentList[cellMeta.rowIndex];
      if (cellMeta.colIndex !== 2 && !showAdvisorDropDown) {
        handleOnRowClick(selectedStudent);
      } else if (cellMeta.colIndex === 2 && !showAdvisorDropDown) {
        setShowDropDown(selectedStudent);
      }
    },

    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        const data = {
          ...studentListViewOptions,
          page: tableState.page,
          rowsPerPage: tableState.rowsPerPage,
          queryStudent: studentQueryOption,
        };
        dispatchToGetStudentsList(data);
        saveTableOptions(studentListLocalStorageKey, data);
      }

      if (action === 'sort') {
        const data = {
          ...studentListViewOptions,
          sort: tableState.sortOrder,
          queryStudent: studentQueryOption,
        };
        dispatchToGetStudentsList(data);
        saveTableOptions(studentListLocalStorageKey, data);
      }
    },
  };

  const handleSetAdvisors = (e) => {
    const { value } = e.target;
    if (value.includes(urlParamsToFilterStudentList.ALL)) {
      if (
        selectedAdvisors.length ===
        sortedAdvisorsList.length + noOfAdditionalValuesForFilterDropDowns.ADVISOR
      ) {
        setSelectedAdvisors([]);
      } else {
        setSelectedAdvisors([...sortedAdvisorsList, advisorStatusForStudents.UNASSIGNED]);
      }
    } else if (value.includes(advisorStatusForStudents.UNASSIGNED)) {
      setSelectedAdvisors(value);
    } else {
      setSelectedAdvisors(value);
    }
  };

  const handleSetPathways = (e) => {
    const { name, value } = e.target;
    if (value.includes(urlParamsToFilterStudentList.ALL)) {
      if (values.selectedPathwayStatuses.length === reorderedPathwayStatus.length) {
        handleInputChange({ target: { name, value: [] } });
      } else {
        handleInputChange({ target: { name, value: reorderedPathwayStatus } });
      }
    } else {
      handleInputChange(e);
    }
  };

  const handleSetStudentTerms = (e) => {
    const { name, value } = e.target;
    if (value.includes(urlParamsToFilterStudentList.ALL)) {
      if (values.selectedTerms.length === allTerms.length) {
        handleInputChange({ target: { name, value: [] } });
      } else {
        handleInputChange({ target: { name, value: allTerms } });
      }
    } else {
      handleInputChange(e);
    }
  };

  const downloadStudentList = async () => {
    const query = { ...getQueryParams(studentListViewOptions), ...queryForStudentList };
    query.page = 1;
    query.size = totalStudents;
    query.sortColumn = 'studentId';
    query.sortDirection = 'asc';
    const queryString = convertToQueryString(query);
    dispatch(
      getStudents(orgId, queryString, (data) => {
        generateExcelForStudentList(t, selectedOrg, termList, data);
      }),
    );
  };

  return (
    <Container maxWidth="xl">
      <>
        <DeclareDialog
          header={t('Is the student transferring courses?')}
          yesLabel={t('Yes')}
          noLabel={t('No')}
          open={dialogOpen}
          onClose={handleDialogClose}
          handleYesClick={() => navigateToTransferEvaluations(currentSelectedStudent)}
          handleNoClick={navigateToStudentPlanning}
        />
      </>

      <Box className={classes.dropDownContainer}>
        <Box className={classes.dropDownBox}>
          <DeclareBreadcrumb items={[t('Students')]} />
          <Grid className={classes.dropDownGrid}>
            <Box className={classes.filterDropDownBox}>
              <DeclaredSelect
                sm={true}
                className={classes.dropDown}
                multiple
                margin="dense"
                value={selectedAdvisors}
                name="selectedAdvisors"
                color="white"
                onChange={handleSetAdvisors}
                onClose={handleDispatchToGetStudentList}
                MenuProps={MenuPropStyles}
                renderValue={(selected) => {
                  if (
                    selected.length ===
                    sortedAdvisorsList.length + noOfAdditionalValuesForFilterDropDowns.ADVISOR
                  ) {
                    return t('All Advisors');
                  } else {
                    return selected
                      .map((selectedItem) => {
                        if (selectedItem === advisorStatusForStudents.UNASSIGNED) {
                          return advisorStatusForStudents.UNASSIGNED;
                        } else {
                          return formatName(selectedItem.identity);
                        }
                      })
                      .join(', ');
                  }
                }}>
                <MenuItem value={urlParamsToFilterStudentList.ALL}>
                  <Checkbox
                    checked={
                      selectedAdvisors.length ===
                      sortedAdvisorsList.length + noOfAdditionalValuesForFilterDropDowns.ADVISOR
                    }
                  />
                  <ListItemText primary={t('All Advisors')} />
                </MenuItem>
                <MenuItem value={advisorStatusForStudents.UNASSIGNED}>
                  <Checkbox
                    checked={selectedAdvisors.includes(advisorStatusForStudents.UNASSIGNED)}
                  />
                  <ListItemText primary={t('Unassigned')} />
                </MenuItem>
                {sortedAdvisorsList.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <Checkbox
                      checked={selectedAdvisors.findIndex((term) => term.email === item.email) > -1}
                    />
                    <ListItemText primary={formatName(item.identity)} />
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                sx={{ width: 250 }}
                sm={true}
                color="white"
                className={classes.dropDown}
                MenuProps={MenuPropStyles}
                multiple
                margin="dense"
                value={values.selectedTerms}
                name="selectedTerms"
                onChange={handleSetStudentTerms}
                onClose={handleDispatchToGetStudentList}
                renderValue={(selected) => {
                  if (selected.length === allTerms.length) {
                    return t('All First Terms');
                  } else {
                    return selected.map((selectedItem) => selectedItem.displayName).join(', ');
                  }
                }}>
                <MenuItem value={urlParamsToFilterStudentList.ALL}>
                  <Checkbox checked={values.selectedTerms.length === allTerms.length} />
                  <ListItemText primary={t('All First Terms')} />
                </MenuItem>
                {allTerms.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <Checkbox
                      checked={values.selectedTerms.findIndex((sem) => sem.id === item.id) > -1}
                    />
                    <ListItemText primary={item.displayName} />
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                sm={true}
                className={classes.dropDown}
                multiple
                margin="dense"
                value={values.selectedPathwayStatuses}
                name="selectedPathwayStatuses"
                color="white"
                onChange={handleSetPathways}
                onClose={handleDispatchToGetStudentList}
                MenuProps={MenuPropStyles}
                renderValue={(selected) => {
                  if (selected.length === reorderedPathwayStatus.length) {
                    return t('All Pathways');
                  } else {
                    return selected.map((selectedItem) => selectedItem.name).join(', ');
                  }
                }}>
                <MenuItem value={urlParamsToFilterStudentList.ALL}>
                  <Checkbox
                    checked={
                      values.selectedPathwayStatuses.length === reorderedPathwayStatus.length
                    }
                  />
                  <ListItemText primary={t('All Pathways')} />
                </MenuItem>
                {reorderedPathwayStatus.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <Checkbox
                      checked={
                        values.selectedPathwayStatuses.findIndex((status) => status === item) > -1
                      }
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Box>
            <Download
              className={classes.studentListDownloadBtn}
              color="secondary"
              onClick={() => {
                downloadStudentList();
              }}></Download>
          </Grid>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }} className={classes.dataTableRoot}>
          <DeclareDataTable data={studentList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
