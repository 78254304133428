import {
  Box,
  Chip,
  Container,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { MoreVert, Download } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import _ from 'lodash';
import CountUp from 'react-countup';
import ReactECharts from 'echarts-for-react';
import {
  convertDateIntoMonthDate,
  convertTimestampIntoDate,
  getColorForPathwayStatus,
  MenuPropStyles,
  createUrlWithQueryParams,
  saveFilterParamsToLocalStorage,
} from '../../helpers/common';
import {
  advisorsSortByName,
  formatName,
  getIdentityEmail,
  getAdvisorsUrlParameters,
  getStudentFirstTermsUrlParameters,
  getStudentListViewOption,
  saveTableOptions,
} from '../../helpers/studentsHelper';
import { getLearningActivities, getTerms } from '../../redux/actions/learningActivityActions';
import { getAllAdvisors } from '../../redux/actions/advisorActions';
import { useDeclareForm } from '../../components/DeclareForm';
import DeclaredSelect from '../../components/DeclareSelect';
import DeclareBreadcrumb from '../../components/DeclareBreadcrumb';
import { getPathwayStatus } from '../../redux/actions/pathwayActions';
import {
  createPathwayStatusHistoryReport,
  generateStatusHistoryReport,
} from '../../redux/actions/reportActions';
import { useTheme } from '@mui/styles';
import { generateStudentStatusReportExcel } from '../../processes/generateStudentStatusReport';
import { getAcademicYearsWithTermInstances } from '../../redux/actions/termActions';
import { reorderPathwayStatusList } from '../../helpers/pathwayHelper';
import { generatePath, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../helpers/routes';
import { localStorageKeys, urlParamsToFilterStudentList } from '../../helpers/appConstants';
import { getProgramMaps } from '../../redux/actions/programMapActions';

const useStyle = makeStyles((theme) => ({
  chartCategories: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  categoryCount: {
    '&.MuiTypography-root': { fontSize: theme.spacing(6), fontWeight: 'bold' },
  },
  chartContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
  },
  categoryContainer: { display: 'flex', justifyContent: 'space-evenly' },
  reportWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    paddingBottom: '24px',
  },
  reportTitleContainer: { padding: '24px', display: 'flex', justifyContent: 'space-between' },
  filterContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  reportDownloadIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.6),
  },
  customChip: {
    '&.MuiChip-root': {
      border: 'none',
      color: theme.palette.grey.main,
    },
  },
}));

export default function Dashboard() {
  const ALL_ADVISOR = 'ALL_ADVISORS';
  const studentListLocalStorageKey = 'tableOption';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { termList, academicYears } = useSelector((state) => state.terms);
  const { selectedOrg } = useSelector((state) => state.allOrgs);
  const { advisorsList } = useSelector((state) => state.advisors);
  const { pathwayStatus } = useSelector((state) => state.pathway);
  const { orgs } = useSelector((state) => state.allOrgs);
  const { orgId } = useParams();
  const classes = useStyle();
  const initPlaceHolders = { termSelectPlaceHolder: t(`Student's First Term`) };
  const { values, setValues, handleInputChange } = useDeclareForm({ semesters: [], advisors: [] });
  const [allTerms, setAllTerms] = useState([]);
  const [currentSelectedTerm, setCurrentSelectedTerm] = useState({});
  const { loadingState } = useSelector((state) => state);
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.auth);
  const [sortedAdvisorsList, setSortedAdvisorsList] = useState([]);
  const [isChartVisible, setIsChartVisible] = useState(true);
  const [isTermDateError, setIsTermDateError] = useState(false);
  const navigate = useNavigate();
  const preValues = useRef();
  const { studentQueryOption } = useSelector((state) => state.students);
  const [options, setOptions] = useState({
    responsive: false,
    maintainAspectRatio: true,
    legend: {
      show: true,
      type: 'scroll',
      data: [],
    },
    tooltip: {
      trigger: 'axis',
      showContent: true,
      axisPointer: { type: 'cross' },
    },
    xAxis: {
      type: 'category',
      data: [],
      boundaryGap: false,
      nameLocation: 'end',
      axisTick: {
        alignWithLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#ccc',
        },
      },
      axisLabel: {
        color: '#4cbc88',
        interval: 0,
        rich: {
          gap: {
            width: 50,
            height: 20,
            align: 'center',
          },
        },
      },
    },
    yAxis: {
      gridIndex: 0,
      show: true,
      splitNumber: 10,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'transparent',
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#ccc',
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    dispatch(getTerms(orgId));
    dispatch(getAllAdvisors(orgId));
    dispatch(getPathwayStatus(orgId));
    dispatch(getLearningActivities(orgId));
    dispatch(getProgramMaps(orgId));
  }, [selectedOrg]);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }

    if (!_.isEmpty(academicYears) && !_.isEmpty(termList)) {
      const currentYear = new Date().getFullYear();
      const filteredAllTerms = academicYears.flatMap((academicYear) => academicYear.termInstances);
      setAllTerms(filteredAllTerms);
      const filteredCurrentTerm = filteredAllTerms.find(
        ({ calendarYear, termId }) =>
          calendarYear === currentYear && termId === termList.find((term) => term?.currentTerm)?.id,
      );
      setCurrentSelectedTerm(filteredCurrentTerm);
    } else {
      setAllTerms([]);
      setCurrentSelectedTerm({});
      setValues({ ...values, semesters: [] });
    }
  }, [termList, academicYears]);

  useEffect(() => {
    if (!_.isEmpty(advisorsList)) {
      const sortedAdvisorsList = advisorsSortByName(advisorsList);
      setSortedAdvisorsList(sortedAdvisorsList);
    } else {
      setSortedAdvisorsList([]);
      setValues({ ...values, advisors: [] });
    }
  }, [advisorsList]);

  useEffect(() => {
    if (!_.isEmpty(sortedAdvisorsList) && !_.isEmpty(userInfo)) {
      const advisorIndex = sortedAdvisorsList.findIndex(
        (adv) => adv.email === getIdentityEmail(userInfo.username),
      );

      if (advisorIndex > -1) {
        setValues({ ...values, advisors: [sortedAdvisorsList[advisorIndex]] });
      } else {
        setValues({ ...values, advisors: sortedAdvisorsList });
      }
    }
  }, [sortedAdvisorsList, userInfo]);

  useEffect(() => {
    if (
      !_.isEmpty(currentSelectedTerm?.startDate) &&
      !_.isEmpty(currentSelectedTerm?.endDate) &&
      !_.isEmpty(values.semesters) &&
      !_.isEmpty(values.advisors)
    ) {
      setIsChartVisible(true);
    } else {
      setIsChartVisible(false);
    }

    if (!_.isEmpty(currentSelectedTerm)) {
      if (!_.isEmpty(currentSelectedTerm.startDate) && !_.isEmpty(currentSelectedTerm.endDate)) {
        setIsTermDateError(false);
      } else {
        setIsTermDateError(true);
      }
    }
  }, [currentSelectedTerm, values]);

  const getChartDetails = () => {
    if (
      !_.isEmpty(pathwayStatus) &&
      !_.isEmpty(values.advisors) &&
      !_.isEmpty(values.semesters) &&
      !_.isEmpty(currentSelectedTerm.startDate) &&
      !_.isEmpty(currentSelectedTerm.endDate)
    ) {
      setOptions((options) => ({
        ...options,
        legend: {
          ...options.legend,
          data: reorderPathwayStatusList(pathwayStatus, 'type').map((stat) => stat.name),
        },
      }));

      const callback = ({ timePoints, countData, endData }) => {
        if (!_.isEmpty(timePoints)) {
          setOptions((options) => ({
            ...options,
            xAxis: {
              ...options.xAxis,
              data: timePoints.map((time) => convertDateIntoMonthDate(time)),
            },
          }));
        }

        if (!_.isEmpty(countData)) {
          const series = Object.keys(countData).map((cData) => {
            const matchingPathwayStatus = pathwayStatus.find(({ id }) => id == cData);
            if (matchingPathwayStatus) {
              const { name, type, id } = matchingPathwayStatus;
              return {
                name: name,
                data: countData[cData],
                type: 'line',
                smooth: true,
                seriesLayoutBy: 'row',
                emphasis: { focus: 'series' },
                declareType: type,
                declareEndData: endData[id],
                declareStatusId: id,
                lineStyle: {
                  width: 5,
                  color: getColorForPathwayStatus(theme, type),
                },
              };
            }
          });

          setOptions((options) => ({
            ...options,
            series: reorderPathwayStatusList(series, 'declareType'),
          }));
        }
      };
      dispatch(
        createPathwayStatusHistoryReport(
          {
            orgId,
            advisors: values.advisors.map((adv) => adv.email),
            studentFirstTerms: values.semesters.map(({ termId, calendarYear }) => ({
              id: termId,
              year: calendarYear,
            })),
            startDate: convertTimestampIntoDate(currentSelectedTerm.startDate),
            endDate: convertTimestampIntoDate(currentSelectedTerm.endDate),
          },
          callback,
        ),
      );
    }
  };

  useEffect(() => {
    preValues.current = values;
  }, [values]);

  const handleOnClose = () => {
    if (_.isEqual(preValues.current, values)) {
      getChartDetails();
      preValues.current = null;
    }
  };

  const getAdvisorsAndStudentFirstTerms = () => ({
    advisors:
      values.advisors.length === sortedAdvisorsList.length
        ? null
        : values.advisors.map((adv) => adv.email),
    studentFirstTerms: values.semesters.map(({ termId, calendarYear }) => ({
      id: termId,
      year: calendarYear,
    })),
  });

  const handleSelectAdvisors = (e) => {
    const { name, value } = e.target;
    if (value.includes(ALL_ADVISOR)) {
      if (values.advisors.length === sortedAdvisorsList.length) {
        handleInputChange({ target: { name, value: [] } });
      } else {
        handleInputChange({ target: { name, value: sortedAdvisorsList } });
      }
    } else {
      handleInputChange(e);
    }
  };

  const hasPreviousTerm = () => {
    const filteredTermIndex = allTerms.findIndex((term) => term.id === currentSelectedTerm.id);
    return filteredTermIndex != 0 && filteredTermIndex != -1;
  };

  const hasNextTerm = () => {
    const filteredTermIndex = allTerms.findIndex((term) => term.id === currentSelectedTerm.id);
    return filteredTermIndex != allTerms.length - 1 && filteredTermIndex != -1;
  };

  const onPreviousTermClick = () => {
    const filteredTermIndex = allTerms.findIndex((term) => term.id === currentSelectedTerm.id);
    setCurrentSelectedTerm(allTerms[filteredTermIndex - 1]);
  };

  const onNextTermClick = () => {
    const filteredTermIndex = allTerms.findIndex((term) => term.id === currentSelectedTerm.id);
    setCurrentSelectedTerm(allTerms[filteredTermIndex + 1]);
  };

  const studentListViewOptions = getStudentListViewOption(
    studentListLocalStorageKey,
    studentQueryOption,
  );

  const handleNavigateToStudentList = (pathwayStatusId) => {
    const selectedAdvisors = getAdvisorsUrlParameters(values.advisors, sortedAdvisorsList);
    const selectedSemester = getStudentFirstTermsUrlParameters(values.semesters, allTerms);
    let urlParam = {
      [orgId]: {
        students: urlParamsToFilterStudentList.ALL_STUDENTS,
        advisors: selectedAdvisors,
        pathwayStatus: [pathwayStatusId],
        selectedTerms: selectedSemester,
      },
    };
    const data = {
      ...studentListViewOptions,
      page: 0,
      queryStudent: studentQueryOption,
    };
    saveTableOptions(studentListLocalStorageKey, data);
    saveFilterParamsToLocalStorage(localStorageKeys.STUDENT_LIST_FILTER_PARAMS, urlParam);
    navigate(
      generatePath(
        createUrlWithQueryParams(appRoutes.advisor.STUDENT_LIST, {
          students: urlParamsToFilterStudentList.ALL_STUDENTS,
        }),
        {
          orgId: orgId,
        },
      ),
    );
  };
  useEffect(() => {
    getChartDetails();
  }, [currentSelectedTerm]);

  const counterUpComp = (
    { declareEndData, declareStatusId, name, lineStyle: { color } },
    index,
  ) => {
    return (
      <CountUp key={index} start={0} end={declareEndData} delay={0}>
        {({ countUpRef }) => (
          <Box
            onClick={() => handleNavigateToStudentList(declareStatusId)}
            className={classes.chartCategories}>
            <Typography
              sx={{ cursor: 'pointer' }}
              color={color}
              className={classes.categoryCount}
              ref={countUpRef}
            />
            <Typography sx={{ cursor: 'pointer' }}>{t(name)}</Typography>
          </Box>
        )}
      </CountUp>
    );
  };

  const handleReportsDownload = () => {
    const organization = orgs.find((data) => data.id === orgId);
    const filteredAllTerms = academicYears.flatMap(({ termInstances }) =>
      termInstances.map(({ endDate, displayName }) => ({
        endDate,
        displayName,
      })),
    );

    const requestBody = {
      ...getAdvisorsAndStudentFirstTerms(),
      dates: filteredAllTerms.map(({ endDate }) => endDate).filter(Boolean),
    };

    dispatch(
      generateStatusHistoryReport(orgId, requestBody, (data) =>
        processStatusHistoryReport(filteredAllTerms, data, organization),
      ),
    );
  };

  const processStatusHistoryReport = (filteredAllTerms, data, org) => {
    const pathwayStatues = reorderPathwayStatusList(pathwayStatus, 'type');
    generateStudentStatusReportExcel(org, pathwayStatues, filteredAllTerms, data);
  };

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          marginTop: '100px',
        }}>
        <Box className={classes.filterContainer}>
          <DeclareBreadcrumb items={[t('dashboard')]} />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DeclaredSelect
              sx={{ width: 250 }}
              sm={true}
              color="white"
              selected={!_.isEmpty(values.semesters)}
              placeholder={initPlaceHolders.termSelectPlaceHolder}
              multiple
              margin="dense"
              value={values.semesters}
              name="semesters"
              onClose={handleOnClose}
              onChange={handleInputChange}
              MenuProps={MenuPropStyles}
              renderValue={(selected) => selected.map((sem) => sem.displayName).join(', ')}>
              {allTerms.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Checkbox
                    checked={values.semesters.findIndex((sem) => sem.id === item.id) > -1}
                  />
                  <ListItemText primary={item.displayName} />
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              multiple
              sx={{ width: 250 }}
              sm={true}
              color="white"
              margin="dense"
              name="advisors"
              onClose={handleOnClose}
              onChange={handleSelectAdvisors}
              MenuProps={MenuPropStyles}
              value={values.advisors}
              renderValue={(selected) => {
                if (selected.length === sortedAdvisorsList.length) {
                  return t('All Advisors');
                } else {
                  return selected.length == 1
                    ? selected.map((adv) => formatName(adv.identity))
                    : `${formatName(selected[0].identity)} + 
                     ${selected.length - 1} ${selected.length - 1 > 1 ? 'Others' : 'Other'}`;
                }
              }}>
              <MenuItem value={ALL_ADVISOR}>
                <Checkbox checked={values.advisors.length === sortedAdvisorsList.length} />
                <ListItemText primary={t('All Advisors')} />
              </MenuItem>
              {sortedAdvisorsList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Checkbox
                    checked={values.advisors.findIndex((adv) => adv.email === item.email) > -1}
                  />
                  <ListItemText primary={formatName(item.identity)} />
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Box>
        </Box>
        <Paper className={classes.reportWrapper}>
          <Box className={classes.reportTitleContainer}>
            <Box>
              <Stack>
                <Stack direction="row">
                  <Typography variant="h6" fontWeight="bold">
                    {t('Student Pathways by Week')}
                  </Typography>
                  <Button
                    onClick={handleReportsDownload}
                    disabled={!isChartVisible}
                    variant="text"
                    startIcon={<Download color={isChartVisible ? 'secondary' : 'grey'} />}
                    className={classes.actionBtn}
                  />
                </Stack>

                <Typography color="gray">
                  {t('Changes to student pathway status by week.')}
                </Typography>
              </Stack>
            </Box>
            <Box>
              {currentSelectedTerm && (
                <Stack direction="row" gap={1}>
                  {hasPreviousTerm() && (
                    <Chip
                      className={classes.customChip}
                      variant="outlined"
                      label={t('Previous Term')}
                      clickable={true}
                      onClick={() => {
                        onPreviousTermClick();
                      }}
                    />
                  )}
                  <Chip label={currentSelectedTerm.displayName} sx={{ fontWeight: 'bold' }} />
                  {hasNextTerm() && (
                    <Chip
                      className={classes.customChip}
                      variant="outlined"
                      label={t('Next Term')}
                      clickable={true}
                      onClick={() => {
                        onNextTermClick();
                      }}
                    />
                  )}
                  <MoreVert sx={{ marginTop: '5px' }} />
                </Stack>
              )}
            </Box>
          </Box>
          <Box className={classes.chartContainer}>
            {isChartVisible ? (
              <ReactECharts
                showLoading={!_.isEmpty(loadingState.inprogressLoadingOperations)}
                option={options}
                style={{
                  float: 'left',
                  height: 400,
                  paddingTop: '24px',
                  width: '100%',
                  maxWidth: '1080px',
                  position: 'relative',
                }}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 400,
                  paddingTop: '24px',
                }}>
                <Typography textAlign="center" fontWeight="bold">
                  {isTermDateError && t('Set the term start and end dates to view this report.')}
                </Typography>
              </Box>
            )}
          </Box>
          {isChartVisible && (
            <Box className={classes.categoryContainer}>
              {options.series.map((data, index) => counterUpComp(data, index))}
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
}
