import { appRoutes } from '../../helpers/routes';
import { actionTypes } from '../constants/actionTypes';

export const redirectToLogin = () => {
  return {
    type: actionTypes.REDIRECT_TO_LOGIN,
    payload: appRoutes.auth.LOGIN,
  };
};

export const getUserInfo = () => {
  return {
    type: actionTypes.GET_USER_INFO,
  };
};

export const setUserInfo = (payload) => {
  return {
    type: actionTypes.SET_USER_INFO,
    payload,
  };
};
