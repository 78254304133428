import {
  setPrograms,
  getPrograms,
  setProgramOptions,
  setOption,
} from '../../actions/programActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestGetPrograms,
  requestCreateProgram,
  requestEditProgram,
  requestGetProgramOptions,
  requestCreateProgramOption,
  requestGetOption,
  requestUpdateProgramOption,
} from '../requests/program';
import { setError } from '../../actions/notificationActions';

export function* handleGetPrograms(action) {
  const { payload } = action;
  yield requestHandler(requestGetPrograms.bind(this, payload), setPrograms, requestTypes.GET);
}

export function* handleGetProgram(action) {
  const { payload, callBack } = action;
  const onResponse = (programs) => {
    const selectedProgram = programs.find((program) => program.id === payload.programId);
    if (!selectedProgram) {
      return setError({ message: 'Invalid URL' });
    }
    callBack(selectedProgram);
    return setPrograms(programs, selectedProgram);
  };
  yield requestHandler(requestGetPrograms.bind(this, payload.orgId), onResponse, requestTypes.GET);
}

export function* handleCreateProgram(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestCreateProgram.bind(this, payload),
    getPrograms.bind(this, payload.org),
    requestTypes.POST,
    navigate,
  );
}

export function* handleEditProgram(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestEditProgram.bind(this, payload),
    getPrograms.bind(this, payload.org),
    requestTypes.PUT,
    navigate,
  );
}

export function* handleGetProgramOptions(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetProgramOptions.bind(this, payload),
    setProgramOptions,
    requestTypes.GET,
  );
}

export function* handleGetProgramOption(action) {
  const { payload, callBack } = action;
  yield requestHandler(requestGetOption.bind(this, payload), setOption, requestTypes.GET, callBack);
}

export function* handleCreateProgramOption(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestCreateProgramOption.bind(this, payload),
    getPrograms.bind(this, payload.orgId),
    requestTypes.POST,
    navigate,
  );
}

export function* handleUpdateProgramOption(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestUpdateProgramOption.bind(this, payload),
    getPrograms.bind(this, payload.orgId),
    requestTypes.POST,
    navigate,
  );
}
