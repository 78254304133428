import ProgramMapService from '../../../services/ProgramMapService';

const programMapService = new ProgramMapService();

export function* requestGetProgramMaps(param) {
  return yield programMapService.getAll(param);
}

export function* requestCreateProgramMap(payload) {
  const { org, data } = payload;
  return yield programMapService.create(org, data);
}

export function requestEditProgramMap(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.update(org, programMapId, data);
}

export function requestRenameProgramMapGroup(payload) {
  const { org, programMapId, id, name } = payload;
  return programMapService.renameProgramMapGroup(org, programMapId, { id, name });
}

export function requestAddNewProgramMapGroup(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.addNewProgramMapGroup(org, programMapId, data);
}

export function requestLinkProgramMapIntoProgramMap(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.linkProgramMapIntoProgramMap(org, programMapId, data);
}

export function requestRemoveProgramMapNode(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.removeProgramMapNode(org, programMapId, data);
}

export function requestMoveProgramMapNode(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.moveProgramMapNode(org, programMapId, data);
}

export function requestUpdateProgramMapNode(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.updateProgramMapNode(org, programMapId, data);
}

export function requestGetProgramMappingData(payload) {
  const { org, programMapId, query } = payload;
  return programMapService.getProgramMapping(org, programMapId, query);
}

export function requestGetLearningActivitiesForGroup(payload) {
  const { org, programMapId, data } = payload;
  return programMapService.getLearningActivitiesForGroup(org, programMapId, data);
}

export function requestGetProgramMapRequirements(payload) {
  const { org, programMapId } = payload;
  return programMapService.getProgramRequirements(org, programMapId);
}

export function requestConstructProgramMapPathway(payload) {
  const { programMapId, body } = payload;
  return programMapService.constructProgramMapPathway(programMapId, body);
}

export function requestPatchProgramMappingData(payload) {
  const { org, programMapId, body } = payload;
  return programMapService.patchProgramMapping(org, programMapId, body);
}
