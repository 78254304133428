import _ from 'lodash';
import { setStudents, updateSelectedStudent } from '../redux/actions/studentActions';
import { urlParamsToFilterStudentList } from './appConstants';
import {
  noOfAdditionalValuesForFilterDropDowns,
  advisorStatusForStudents,
  localStorageKeys,
  getStatus,
} from './appConstants';
import { getFilterParamsFromLocalStorage } from './common';

export const studentsListAggregator = (studentData, filteredProgramMaps, allProgramMaps) => {
  let programMap = null;
  return studentData.map((student) => {
    if (_.isNil(student.programMapId)) {
      programMap = filteredProgramMaps.find(({ optionId }) => student.programOption === optionId);
    } else {
      programMap = allProgramMaps.find(({ id }) => student.programMapId === id);
    }

    return {
      ...student,
      programOption: {
        id: student.programOption,
        name: programMap?.optionName,
        code: programMap?.optionCode,
        units: programMap?.units,
      },
      units: `${student.completedUnits}/${programMap?.units}`,
    };
  });
};

export const updateCurrentStudent = (
  currentStudent,
  studentList,
  plannedStatus,
  dispatch = () => {},
  programMapId = null,
) => {
  const index = studentList.findIndex(({ id }) => id === currentStudent.id);
  const updatedCurrentStudent = {
    ...currentStudent,
    plannedStatus,
    ...(programMapId != null && { programMapId }),
  };
  studentList[index] = updatedCurrentStudent;

  dispatch(setStudents(studentList));
  dispatch(updateSelectedStudent(updatedCurrentStudent));
};

export const getStudentListViewOption = (key, query) => {
  const data = window.localStorage.getItem(key);

  if (!_.isNull(data)) {
    return JSON.parse(data);
  }

  return {
    page: 0,
    rowsPerPage: 10,
    queryStudent: query,
    sort: {},
    totalItems: 0,
  };
};

export const getQueryParams = (options) => {
  const data = {
    page: options.page + 1,
    size: options.rowsPerPage,
    sortColumn: options.sort?.name,
    sortDirection: options.sort?.direction,
    advisor: !_.isUndefined(options?.advisor) ? options.advisor : null,
    advisors:
      !_.isUndefined(options?.advisors) &&
      !options?.advisors.includes(urlParamsToFilterStudentList.ALL)
        ? options.advisors
        : null,
    pathwayStatuses:
      !_.isUndefined(options?.pathwayStatuses) &&
      !options?.pathwayStatuses.includes(urlParamsToFilterStudentList.ALL)
        ? options.pathwayStatuses
        : null,
    studentFirstTerms:
      !_.isUndefined(options?.studentFirstTerms) &&
      !options?.studentFirstTerms.includes(urlParamsToFilterStudentList.ALL)
        ? options.studentFirstTerms
        : null,
    query: _.isUndefined(options?.query) ? null : options?.query,
  };
  return _.pickBy(data, _.identity);
};

export const saveTableOptions = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const getIdentityEmail = (username) => {
  // eslint-disable-next-line no-undef
  const usernameSplitter = process.env.REACT_APP_IDENTITY_PROVIDER.toLowerCase();
  return username.toLowerCase().replace(`${usernameSplitter}_`, '');
};

export const formatName = (data) => {
  return _.isEmpty(data) ? '-' : `${data.firstname} ${data.lastname}`;
};

export const advisorsSortByName = (advisorsList) => {
  const sortedAdvisorsList = advisorsList.sort((a, b) => {
    let x = formatName(a.identity),
      y = formatName(b.identity);
    return x.localeCompare(y);
  });
  return sortedAdvisorsList;
};

export const getAdvisorsUrlParameters = (selectedAdvisors, sortedAdvisorsList) => {
  let advisors = null;
  if (
    selectedAdvisors.length ===
    sortedAdvisorsList.length + noOfAdditionalValuesForFilterDropDowns.ADVISOR
  ) {
    advisors = [urlParamsToFilterStudentList.ALL];
  } else {
    advisors = selectedAdvisors.map((selectedAdvisor) => {
      if (selectedAdvisor === advisorStatusForStudents.UNASSIGNED) {
        return selectedAdvisor;
      } else {
        return selectedAdvisor.user.id;
      }
    });
  }
  return advisors;
};

export const getStudentFirstTermsUrlParameters = (selectedFirstTerms, allTerms) => {
  let selectedTerms = null;
  if (selectedFirstTerms.length === allTerms.length) {
    selectedTerms = [urlParamsToFilterStudentList.ALL];
  } else {
    selectedTerms = selectedFirstTerms.map((selectedTerm) => {
      return selectedTerm.id;
    });
  }
  return selectedTerms;
};

export const getAdvisorValueForDropDown = () => {
  const filteredParams = getFilterParamsFromLocalStorage(
    localStorageKeys.STUDENT_LIST_FILTER_PARAMS,
  );
  let studentValue = urlParamsToFilterStudentList.ALL_STUDENTS;
  if (!_.isNil(filteredParams?.students)) {
    studentValue = filteredParams?.students;
  }
  return studentValue;
};

export const isFreeElective = (info) => !_.isNil(info) && info?.freeElective;

export const getDefaultProgramMapId = (programMapList, catalogs, programOptionId) => {
  const programMap = programMapList
    .filter(({ optionId }) => optionId === programOptionId)
    .map(({ id, catalogs: cats }) => ({
      id,
      order: catalogs.find((catalog) => catalog.id === cats[0].id)?.order,
      status: catalogs.find((catalog) => catalog.id === cats[0].id)?.status,
    }))
    .filter(({ status }) => status === getStatus()[0].value)
    .sort(({ order: orderA }, { order: orderB }) => orderB - orderA)[0];

  return programMap?.id;
};

export const getStudentProgramMapIdByCatalog = (programMapList, programOptionId, catalogId) => {
  return programMapList.find(
    (record) =>
      record.optionId === programOptionId &&
      record.catalogs.length > 0 &&
      record.catalogs[0].id === catalogId,
  )?.id;
};

export const getCurrentCatalog = (
  programMapList,
  latestCatalog,
  currentProgramMapId,
  catalogs,
  catalogId,
) => {
  if (_.isNil(currentProgramMapId)) {
    return _.isNil(catalogId)
      ? latestCatalog
      : catalogs.find((catalog) => catalog.id === catalogId);
  }

  const programMap = programMapList.find(({ id }) => id === currentProgramMapId);
  return catalogs.find((catalog) => catalog.id === programMap.catalogs[0].id);
};

export const isStudentPreferredProgram = (selectedStudent, programMapId, programMapList) => {
  const programOptionId = programMapList.find(({ id }) => id === programMapId).optionId;
  return selectedStudent.programOption.id === programOptionId;
};

export const getUnitsCovered = (completedCourses, learningActivities) =>
  completedCourses
    .map(({ id, additionalInfo }) => {
      if (!_.isNull(additionalInfo.requirementTransfer)) {
        return additionalInfo.requirementTransfer.unitsTransferred;
      }

      const la = learningActivities.find((data) => data.id === id);
      return la.units;
    })
    .reduce((prev, curr) => prev + curr, 0);
