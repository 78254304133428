import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CreateEditWrapper from './CreateEditWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequirements, getStatuses } from '../../../redux/actions/transferEquivalencyActions';
import { getLearningActivities } from '../../../redux/actions/learningActivityActions';
import _ from 'lodash';
import { getCatalogs } from '../../../redux/actions/catalogActions';

export default function CreateEditTransferEquivalency() {
  const { transferEquivalencyId, orgId } = useParams();
  const { state } = useLocation();
  const isEditView = transferEquivalencyId !== undefined;
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { allRequirements, current } = useSelector((state) => state.transferEquivalency);
  const { catalogs } = useSelector((state) => state.allCatalogs);
  const dispatch = useDispatch();
  const [isLaNameFieldDisabled, setLaNameFieldDisabled] = useState(false);
  const [currentTransferEquivalency, setCurrentTransferEquivalency] = useState({
    transferringCode: '',
    transferringName: '',
    teqStatus: '',
    transferringUnits: '',
    transferNote: '',
  });
  const { teqStatuses } = useSelector((state) => state.transferEquivalency);

  useEffect(() => {
    if (_.isEmpty(learningActivities)) {
      dispatch(getLearningActivities(orgId));
    }
    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(allRequirements) && !_.isEmpty(catalogs)) {
      dispatch(
        getAllRequirements(
          orgId,
          catalogs.map(({ id }) => id),
        ),
      );
    }
  }, [catalogs]);
  useEffect(() => {
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    }
  }, [teqStatuses]);

  useEffect(() => {
    if (!_.isEmpty(state.sourceLaName)) {
      setLaNameFieldDisabled(true);
    }
    setCurrentTransferEquivalency({
      transferringCode: state.sourceLaCode,
      transferringName: state.sourceLaName,
      teqStatus: !_.isNil(state.status) ? state.status?.type : 0,
      transferringUnits: state.sourceLaUnits,
      transferNote: state.note?.message || '',
      transferringCourseId: state.transferringCourseId,
    });
  }, [state, teqStatuses]);

  return (
    <>
      <CreateEditWrapper
        teqFromState={current}
        currentTransferEquivalency={currentTransferEquivalency}
        isEditView={isEditView}
        learningActivities={learningActivities}
        allRequirements={allRequirements}
        isLaNameFieldDisabled={isLaNameFieldDisabled}
      />
    </>
  );
}
