import React from 'react';
import {
  Home,
  CardMembership,
  LocalLibrary,
  InsertLink,
  Reorder,
  CalendarToday,
  Apartment,
  AccountBoxOutlined,
  Settings,
  AccessTime,
} from '@mui/icons-material';

export default function Sidebar(props) {
  const {
    generatePath,
    NavLink,
    appRoutes,
    orgId,
    sideBarNavigation,
    displayActiveClass,
    classes,
    Typography,
    t,
    hidden = false,
  } = props;

  return (
    <>
      {!hidden && (
        <NavLink
          to={generatePath(appRoutes.catalog.DASHBOARD, { orgId: orgId })}
          onClick={(e) => {
            e.preventDefault();
            sideBarNavigation(generatePath(appRoutes.catalog.DASHBOARD, { orgId: orgId }));
          }}
          className={({ isActive }) => displayActiveClass(isActive)}>
          <Home className={classes.icon} />
          <Typography className={classes.text}>{t('dashboard')}</Typography>
        </NavLink>
      )}
      <NavLink
        to={generatePath(appRoutes.catalog.LEARNING_ACTIVITY_LIST, {
          orgId: orgId,
        })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(
            generatePath(appRoutes.catalog.LEARNING_ACTIVITY_LIST, {
              orgId: orgId,
            }),
          );
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <CardMembership className={classes.icon} />
        <Typography className={classes.text}>{t('learningActivities')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.GLOBAL_CLASS_SCHEDULE_LIST, {
          orgId: orgId,
        })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(
            generatePath(appRoutes.catalog.GLOBAL_CLASS_SCHEDULE_LIST, {
              orgId: orgId,
            }),
          );
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <AccessTime className={classes.icon} />
        <Typography className={classes.text}>{t('Class Schedules')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.PROGRAM_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.PROGRAM_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <LocalLibrary className={classes.icon} />
        <Typography className={classes.text}>{t('programs')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.PROGRAM_MAP_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.PROGRAM_MAP_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <InsertLink className={classes.icon} />
        <Typography className={classes.text}>{t('Program Maps')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.CATALOG_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.CATALOG_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <Reorder className={classes.icon} />
        <Typography className={classes.text}>{t('Catalogs')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.TERMS_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.TERMS_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <CalendarToday className={classes.icon} />
        <Typography className={classes.text}>{t('Terms ')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.COLLEGE_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.COLLEGE_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <Apartment className={classes.icon} />
        <Typography className={classes.text}>{t('collegeAndDepartments')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.catalog.SETTINGS_LIST, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.catalog.SETTINGS_LIST, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <Settings className={classes.icon} />
        <Typography className={classes.text}>{t('Settings')}</Typography>
      </NavLink>
      <NavLink
        to={generatePath(appRoutes.advisor.DASHBOARD, { orgId: orgId })}
        onClick={(e) => {
          e.preventDefault();
          sideBarNavigation(generatePath(appRoutes.advisor.DASHBOARD, { orgId: orgId }));
        }}
        className={({ isActive }) => displayActiveClass(isActive)}>
        <AccountBoxOutlined className={classes.icon} />
        <Typography className={classes.text}>{t('Student Planning')}</Typography>
      </NavLink>
    </>
  );
}
