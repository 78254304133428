/* eslint-disable react/jsx-key */
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { OrganizationGroups } from '../../pages/catalog/OrganizationGroups';
import OranizationList from '../../pages/catalog/Organizations/OranizationList';
import CatalogList from '../../pages/catalog/Catalogs/CatalogList';
import { appRoutes } from '../../helpers/routes';
import CreateCatalog from '../../pages/catalog/Catalogs/CreateCatalog';
import LearningActivityList from '../../pages/catalog/LearningActivity/LearningActivityList';
import Dashboard from '../../pages/catalog/Dashboard';
import CreateCourse from '../../pages/catalog/LearningActivity/Courses/CreateCourse';
import CreateOrganization from '../../pages/catalog/Organizations/CreateOrganization';
import ProgramList from '../../pages/catalog/Program/ProgramList';
import EditCreateProgram from '../../pages/catalog/Program/EditCreateProgram';
import EditCreateProgramOption from '../../pages/catalog/Program/EditCreateProgramOption';
import ProgramMapList from '../../pages/catalog/ProgramMap/ProgramMapList';
import EditCreateProgramMap from '../../pages/catalog/ProgramMap/EditCreateProgramMap';
import ProgramMapView from '../../pages/catalog/ProgramMap/ProgramMapView';
import DepartmentList from '../../pages/catalog/Departments/DepartmentList';
import EditCreateDepartment from '../../pages/catalog/Departments/EditCreateDepartment';
import TermList from '../../pages/catalog/Terms/TermsList';
import EditCreateTerm from '../../pages/catalog/Terms/EditCreateTerm';
import TermsOrderList from '../../pages/catalog/Terms/TermsOrderList';
import TermsScheduleList from '../../pages/catalog/Terms/TermsScheduleList';
import List from '../../pages/catalog/Settings/List';
import CatalogClassScheduleList from '../../pages/catalog/ClassSchedule/List';
import DuplicateCatalog from '../../pages/catalog/Catalogs/DuplicateCatalog';
import CatalogsReorder from '../../pages/catalog/Catalogs/CatalogsOrderList';

const routes = [
  <Route exact path={appRoutes.catalog.ORGANIZATION_LIST} element={<OranizationList />} />,
  <Route path={appRoutes.catalog.DASHBOARD} element={<Dashboard />} />,
  <Route path={appRoutes.catalog.CATALOG_LIST} element={<CatalogList />} />,
  <Route path={appRoutes.catalog.CATALOG_CREATE} element={<CreateCatalog />} />,
  <Route path={appRoutes.catalog.CATALOG_EDIT} element={<CreateCatalog />} />,
  <Route path={appRoutes.catalog.CATALOG_DUPLICATE} element={<DuplicateCatalog />} />,
  <Route path={appRoutes.catalog.CATALOG_REORDER} element={<CatalogsReorder />} />,
  <Route path={appRoutes.catalog.ORGANIZATION_GROUP} element={<OrganizationGroups />} />,
  <Route path={appRoutes.catalog.ORGANIZATION_CREATE} element={<CreateOrganization />} />,
  <Route path={appRoutes.catalog.ORGANIZATION_EDIT} element={<CreateOrganization />} />,
  <Route path={appRoutes.catalog.LEARNING_ACTIVITY_LIST} element={<LearningActivityList />} />,
  <Route path={appRoutes.catalog.COURSE_CREATE} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.COURSE_EDIT} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.COURSE_RULE_EDIT} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.CLASS_SCHEDULE_LIST} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.CLASS_SCHEDULE_CREATE} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.CLASS_SCHEDULE_EDIT} element={<CreateCourse />} />,
  <Route path={appRoutes.catalog.PROGRAM_LIST} element={<ProgramList />} />,
  <Route path={appRoutes.catalog.PROGRAM_CREATE} element={<EditCreateProgram />} />,
  <Route path={appRoutes.catalog.PROGRAM_EDIT} element={<EditCreateProgram />} />,
  <Route path={appRoutes.catalog.PROGRAM_OPTION_LIST} element={<EditCreateProgram />} />,
  <Route path={appRoutes.catalog.PROGRAM_OPTION_CREATE} element={<EditCreateProgramOption />} />,
  <Route path={appRoutes.catalog.PROGRAM_OPTION_EDIT} element={<EditCreateProgramOption />} />,
  <Route path={appRoutes.catalog.PROGRAM_MAP_LIST} element={<ProgramMapList />} />,
  <Route path={appRoutes.catalog.PROGRAM_MAP_CREATE} element={<EditCreateProgramMap />} />,
  <Route path={appRoutes.catalog.PROGRAM_MAP_EDIT} element={<EditCreateProgramMap />} />,
  <Route path={appRoutes.catalog.PROGRAM_MAP_VIEW} element={<ProgramMapView />} />,
  <Route path={appRoutes.catalog.COLLEGE_LIST} element={<DepartmentList />} />,
  <Route path={appRoutes.catalog.COLLEGE_DEPARTMEMT_CREATE} element={<EditCreateDepartment />} />,
  <Route path={appRoutes.catalog.COLLEGE_DEPARTMEMT_EDIT} element={<EditCreateDepartment />} />,
  <Route path={appRoutes.catalog.TERMS_LIST} element={<TermList />} />,
  <Route path={appRoutes.catalog.TERM_CREATE} element={<EditCreateTerm />} />,
  <Route path={appRoutes.catalog.TERM_EDIT} element={<EditCreateTerm />} />,
  <Route path={appRoutes.catalog.TERM_REORDER} element={<TermsOrderList />} />,
  <Route path={appRoutes.catalog.TERMS_SCHEDULE} element={<TermsScheduleList />} />,
  <Route path={appRoutes.catalog.SETTINGS_LIST} element={<List />} />,
  <Route
    path={appRoutes.catalog.GLOBAL_CLASS_SCHEDULE_LIST}
    element={<CatalogClassScheduleList />}
  />,
  <Route
    path={appRoutes.catalog.HOME}
    element={<Navigate to={appRoutes.catalog.ORGANIZATION_LIST} replace />}
  />,
];

export default routes;
