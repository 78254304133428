import { actionTypes } from '../constants/actionTypes';

export const setStudents = (payload) => {
  return {
    type: actionTypes.STUDENTS.SET,
    payload,
  };
};

export const setStudentsStatus = (statusList) => {
  return {
    type: actionTypes.STUDENTS.SET_STUDENT_STATUS,
    payload: statusList,
  };
};

export const getStudents = (orgId, query, callback) => {
  return {
    type: actionTypes.STUDENTS.GET,
    payload: { orgId, query },
    callback,
  };
};

export const getStudentStatus = (params) => {
  return {
    type: actionTypes.STUDENTS.GET_STUDENT_STATUS,
    payload: params,
  };
};

export const setCurrentSelectedStudent = (data) => {
  return {
    type: actionTypes.STUDENTS.SET_SELECTED_STUDENT,
    payload: data,
  };
};

export const updateSelectedStudent = (data) => {
  return {
    type: actionTypes.STUDENTS.UPDATE_SELECTED_STUDENT,
    payload: data,
  };
};

export const getStudentTeqAssignments = (orgId, student, fetchAllCatalogs, callback) => {
  return {
    type: actionTypes.STUDENTS.GET_TEQ_ASSIGNMENTS,
    payload: { orgId, student, fetchAllCatalogs },
    callback,
  };
};

export const setStudentTeqAssignments = (data) => {
  return {
    type: actionTypes.STUDENTS.SET_TEQ_ASSIGNMENTS,
    payload: data,
  };
};

export const getStudentPathway = (orgId, studentId, body, callback) => {
  return {
    type: actionTypes.STUDENTS.GET_ORDERED_PATHWAY,
    payload: { orgId, studentId, body },
    callback,
  };
};

export const setStudentPathway = (payload) => {
  return {
    type: actionTypes.STUDENTS.SET_ORDERED_PATHWAY,
    payload,
  };
};

export const setSavedStudentPathway = (data) => {
  return {
    type: actionTypes.STUDENTS.SET_SAVED_ORDERED_PATHWAY,
    payload: data,
  };
};

export const setCompletedCourses = (data) => {
  return {
    type: actionTypes.STUDENTS.SET_COMPLETED_COURSES,
    payload: data,
  };
};

export const resetCompletedCourses = () => {
  return {
    type: actionTypes.STUDENTS.RESET_COMPLETED_COURSES,
  };
};

export const resetStudentPathway = (data) => {
  return {
    type: actionTypes.STUDENTS.RESET_ORDERED_PATHWAY,
    payload: data,
  };
};

export const updateStudent = (orgId, studentId, body) => {
  return {
    type: actionTypes.STUDENTS.UPDATE_STUDENT,
    payload: { orgId, studentId, body },
  };
};

export const updateStudentPathway = (orgId, studentId, programOption, body) => ({
  type: actionTypes.STUDENTS.UPDATE_STUDENT_PATHWAY,
  payload: { orgId, studentId, programOption, body },
});

export const setQueryStudentListOption = (payload) => ({
  type: actionTypes.STUDENTS.SET_STUDENT_LIST_QUERY_OPTION,
  payload,
});

export const updateStudentByStudentTeqStatus = (
  orgId,
  studentId,
  transferEquivalencyId,
  body,
  callback = null,
) => ({
  type: actionTypes.STUDENTS.UPDATE_STUDENT_BY_STUDENT_TEQ_STATUS,
  payload: { orgId, studentId, transferEquivalencyId, body },
  callback,
});

export const deleteStudentByStudentTeqStatus = (
  orgId,
  studentId,
  transferEquivalencyId,
  body,
  callback = null,
) => ({
  type: actionTypes.STUDENTS.DELETE_STUDENT_BY_STUDENT_TEQ_STATUS,
  payload: { orgId, studentId, transferEquivalencyId, body },
  callback,
});
