import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Container,
  TextField,
  Chip,
  Divider,
  createFilterOptions,
  Checkbox,
  ListItemText,
  Typography,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, generatePath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeclareForm, useDeclareForm } from '../../../components/DeclareForm';
import DeclareAutocomplete from '../../../components/DeclareAutocomplete';
import { getLearningActivitiesTransferEquivalencies } from '../../../redux/actions/learningActivityActions';
import { appRoutes } from '../../../helpers/routes';
import { makeStyles } from '@mui/styles';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import DeclaredSelect from '../../../components/DeclareSelect';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import {
  getTeqList,
  deleteTeq,
  resetTeqList,
  getStatuses,
  setSelectedLACodes,
  setSelectedSourceOrg,
  postTeqAssignments,
  getAllRequirements,
} from '../../../redux/actions/transferEquivalencyActions';
import TransferEquivalencyRow from '../../../pages/advisor/TransferEquivalency/TransferEquivalencyRow';
import {
  MenuPropStyles,
  getFilterParamsFromLocalStorage,
  saveFilterParamsToLocalStorage,
} from '../../../helpers/common';
import { localStorageKeys, newTeqStatus, getStatus } from '../../../helpers/appConstants';
import { getTransferEvaluationStatusList } from '../../../redux/actions/transferEvaluationActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { getStudentTeqAssignments } from '../../../redux/actions/studentActions';
import { setError } from '../../../redux/actions/notificationActions';

const useStyle = makeStyles((theme) => ({
  button: {
    '&.MuiButton-root': {
      height: '45px',
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
    },
  },
  text: {
    '&.MuiMenuItem-root': {
      fontWeight: 700,
    },
  },
  searchControlContainer: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  equivalencyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    '& .MuiTextField-root': {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    '& .MuiFormControl-root': {
      backgroundColor: 'white',
    },
  },
  declarePage: { marginTop: theme.spacing(12) },
  laSearchContainer: { padding: theme.spacing(2.5), flex: '1' },
  searchBtnWrapper: {
    display: 'flex',
    flex: '1',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  topLayout: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
  laSelect: {
    '& .MuiChip-label, & .MuiButtonBase-root .MuiSvgIcon-root': {
      color: theme.palette.white.main,
    },
    '& .MuiAutocomplete-clearIndicator': {
      backgroundColor: theme.palette.grey.main,
      marginRight: theme.spacing(0.5),
    },
    '& .MuiAutocomplete-popupIndicator': {
      backgroundColor: theme.palette.grey.main,
    },
  },
  saveBtnBox: {
    float: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  dropDown: {
    width: theme.spacing(45),
  },
  transferEquivalencyContainer: {
    marginTop: ({ option }) => (option ? null : theme.spacing(3)),
    padding: ({ option }) => (option ? null : theme.spacing(3)),
    paddingBottom: ({ option }) => (option ? null : theme.spacing(0)),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:nth-last-of-type(1)': {
      marginBottom: theme.spacing(3),
    },
    '&:hover $removeItem': {
      visibility: 'visible',
    },
    '&:hover $addEquivalency': {
      visibility: 'visible',
    },
    '&:hover $editIcon': {
      display: 'block',
    },
  },

  noData: {
    padding: ({ option }) => (option ? null : theme.spacing(3)),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const filter = createFilterOptions();
export default function TransferEquivalencies() {
  const classes = useStyle();
  const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { orgs } = useSelector((state) => state.allOrgs);
  const navigate = useNavigate();
  const formError = useSelector((state) => state.notifications.formError);
  const learningActivityStore = useSelector(
    (state) => state.allLearningActivities.lActivitiesForTransferEquivalencies,
  );
  const {
    current: currentEquivalencies,
    selectedLACodes,
    selectedSourceOrg,
    teqStatuses,
    allRequirements,
  } = useSelector((state) => state.transferEquivalency);
  const { currentSelectedStudent, transferEqAssignments } = useSelector((state) => state.students);
  const [orgSelectOpen, setOrgSelectOpen] = useState(false);
  const [filteredTeqList, setFilteredTeqList] = useState([]);
  const { values, setValues, handleInputChange } = useDeclareForm({
    selectedEquivalencyStatuses: [],
    equivalencyStatusesSelectionInProgress: false,
  });
  const { programMapList } = useSelector((state) => state.programMaps);
  const { teqStatusesEnum } = useSelector((state) => state.transferEquivalency);
  const { statusList } = useSelector((state) => state.transferEvaluation);
  const { catalogs } = useSelector((state) => state.allCatalogs);

  useEffect(() => {
    dispatch(getStudentTeqAssignments(orgId, currentSelectedStudent));

    if (!_.isEmpty(transferEqAssignments) && _.isEmpty(selectedSourceOrg)) {
      const sourceOrgId = transferEqAssignments[0].sourceOrgId;
      const sourceOrg = orgs.find(({ id }) => id === sourceOrgId);
      dispatch(setSelectedSourceOrg(sourceOrg));
    }

    if (_.isEmpty(programMapList)) {
      dispatch(getProgramMaps(orgId));
    }
    if (_.isEmpty(statusList)) {
      dispatch(getTransferEvaluationStatusList(orgId, currentSelectedStudent.id));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(catalogs)) {
      dispatch(
        getAllRequirements(
          orgId,
          catalogs.filter(({ status }) => status === getStatus()[0].value).map(({ id }) => id),
        ),
      );
    }
  }, [catalogs]);

  useEffect(() => {
    if (selectedSourceOrg != null) {
      dispatch(getLearningActivitiesTransferEquivalencies(selectedSourceOrg.id));
      dispatch(getCatalogs(selectedSourceOrg.id, false));
    }
  }, [selectedSourceOrg]);

  useEffect(() => {
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    } else {
      const dropDownValues = teqStatuses;
      // Manipulate the teqStatuses array to add the new status 'New' to the dropdown as it is not coming from the API since it is not a real status
      if (!dropDownValues.find((status) => status.type === newTeqStatus.type)) {
        dropDownValues.push(newTeqStatus);
      }

      setValues({
        ...values,
        selectedEquivalencyStatuses: setSelectedEquivalencyStatuses(dropDownValues),
      });
    }
  }, [teqStatuses]);

  useEffect(() => {
    if (!_.isEmpty(transferEqAssignments)) {
      if (_.isEmpty(selectedSourceOrg)) {
        const sourceOrgId = transferEqAssignments[0].sourceOrgId;
        const sourceOrg = orgs.find(({ id }) => id === sourceOrgId);
        dispatch(setSelectedSourceOrg(sourceOrg));
      }
      if (_.isEmpty(currentEquivalencies) && !_.isNull(currentSelectedStudent?.currentCatalog)) {
        getCurrentEquivalencies();
      }
    }
  }, [transferEqAssignments]);

  useEffect(() => {
    if (!_.isEmpty(currentEquivalencies) && !values.equivalencyStatusesSelectionInProgress) {
      filterEquivalenciesBySelectedStatuses();
    }
    if (_.isEmpty(currentEquivalencies)) {
      setFilteredTeqList([]);
    }
  }, [currentEquivalencies, values.selectedEquivalencyStatuses]);

  useEffect(() => {
    if (
      !_.isNull(currentSelectedStudent?.currentCatalog) &&
      !_.isUndefined(state?.isEdited) &&
      !_.isEmpty(selectedLACodes)
    ) {
      getTeqs(
        orgId,
        selectedSourceOrg.id,
        selectedLACodes,
        currentSelectedStudent?.currentCatalog.id,
      );
    }
    if (
      !_.isUndefined(currentSelectedStudent?.refreshData) &&
      currentSelectedStudent?.refreshData
    ) {
      if (!_.isEmpty(transferEqAssignments)) {
        if (_.isEmpty(selectedSourceOrg)) {
          const sourceOrgId = transferEqAssignments[0].sourceOrgId;
          const sourceOrg = orgs.find(({ id }) => id === sourceOrgId);
          dispatch(setSelectedSourceOrg(sourceOrg));
        }
        if (_.isEmpty(currentEquivalencies) && !_.isNull(currentSelectedStudent?.currentCatalog)) {
          getCurrentEquivalencies();
        }
      }
    }
  }, [currentSelectedStudent]);

  const getCurrentEquivalencies = () => {
    const { sourceOrgId } = transferEqAssignments[0];
    const laCodes = transferEqAssignments.map(({ code }) => code);
    getTeqs(orgId, sourceOrgId, laCodes, currentSelectedStudent?.currentCatalog.id);
  };

  const handleTagsInputChange = (_event, value) => {
    dispatch(setSelectedLACodes(value));
  };

  const navigateToCreateOrganization = () => {
    navigate(appRoutes.catalog.ORGANIZATION_CREATE, {
      state: { fromAdvisor: true, orgId: orgId },
    });
  };

  const handleEquivalencyInputChange = (value) => {
    dispatch(setSelectedSourceOrg(value));
    setOrgSelectOpen(false);
    dispatch(setSelectedLACodes([]));
    if (currentEquivalencies.length > 0) {
      dispatch(resetTeqList());
    }
  };

  const navigateToTransferEvaluationList = () => {
    navigate(generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, { orgId }));
  };

  const navigateToTransferGrades = () => {
    let navigationState = state;

    navigate(generatePath(appRoutes.advisor.TRANSFER_GRADES, { orgId }), {
      state: {
        sourceCourses: currentEquivalencies,
        ...navigationState,
      },
    });
  };

  const getTeqs = async (desOrgId, sourceOrgId, laCodes, catalogId) => {
    if (!_.isEmpty(laCodes)) {
      dispatch(getTeqList(desOrgId, sourceOrgId, laCodes, catalogId));
      dispatch(setSelectedLACodes([]));
    }
  };

  const removeItem = (id) => {
    dispatch(deleteTeq(id));
  };

  const handleSetEquiValencyStatuses = (e) => {
    const { name, value } = e.target;

    if (value.includes('ALL')) {
      if (values.selectedEquivalencyStatuses.length === teqStatuses.length) {
        handleInputChange({ target: { name, value: [] } });
      } else {
        handleInputChange({ target: { name, value: teqStatuses } });
      }
    } else {
      handleInputChange(e);
    }
  };

  const handleEquiValencyStatuses = () => {
    saveFilterPrams(values.selectedEquivalencyStatuses);
    filterEquivalenciesBySelectedStatuses();
    setValues({ ...values, equivalencyStatusesSelectionInProgress: false });
  };

  const saveFilterPrams = (selectedEquivalencyStatuses) => {
    let urlParam = {
      [orgId]: {
        equivalencyStatuses: selectedEquivalencyStatuses,
      },
    };
    saveFilterParamsToLocalStorage(localStorageKeys.EQUIVALENCY_FILTER_PARAMS, urlParam);
  };

  const setSelectedEquivalencyStatuses = (equivalencyStatuses) => {
    let filteredStatuses = getFilterParamsFromLocalStorage(
      localStorageKeys.EQUIVALENCY_FILTER_PARAMS,
    );

    filteredStatuses = filteredStatuses && filteredStatuses[orgId];
    let dropDownValues = equivalencyStatuses;
    if (
      !_.isUndefined(filteredStatuses?.equivalencyStatuses) &&
      !_.isEmpty(filteredStatuses.equivalencyStatuses)
    ) {
      if (!filteredStatuses.equivalencyStatuses.includes('ALL')) {
        dropDownValues = filteredStatuses.equivalencyStatuses.map((param) => {
          const value = equivalencyStatuses.find((status) => param.type == status.type);
          return value;
        });
      }
    } else {
      saveFilterPrams(equivalencyStatuses);
    }
    return dropDownValues;
  };

  const filterEquivalenciesBySelectedStatuses = () => {
    const filteredTeqs = currentEquivalencies.flatMap((item) => {
      // Check if an item should be considered as 'New' status
      const isNewStatus =
        !item.equivalencies ||
        item.equivalencies.length === 0 ||
        item.equivalencies.some((eq) => !eq.status);

      if (
        isNewStatus &&
        values.selectedEquivalencyStatuses.some(
          (selectedStatus) => selectedStatus.type === newTeqStatus.type,
        )
      ) {
        return [item];
      } else {
        const filteredEquivalencies = item.equivalencies?.filter((eq) =>
          values.selectedEquivalencyStatuses.some((status) => status.type === eq.status?.type),
        );

        if (filteredEquivalencies?.length > 0) {
          return [{ ...item, equivalencies: filteredEquivalencies }];
        }
      }
      return [];
    });

    const sortedList = sortFilteredTeqList(filteredTeqs);
    setFilteredTeqList(sortedList);
  };

  const sortFilteredTeqList = (filteredTeqList) => {
    const sortOrder = [
      teqStatusesEnum.APPROVED,
      teqStatusesEnum.APPROVED_WITH_NOTE,
      teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW,
      teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS,
      teqStatusesEnum.REFUSED,
      teqStatusesEnum.ARCHIVED,
    ];
    return filteredTeqList.map((item) => {
      item?.equivalencies?.sort((a, b) => {
        const indexA = sortOrder.indexOf(a.status?.type);
        const indexB = sortOrder.indexOf(b.status?.type);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });
      return item;
    });
  };

  const saveTEqAssignments = () => {
    const { id: studentId } = currentSelectedStudent;
    const hasNewRecords = currentEquivalencies?.some(
      (eq) =>
        !eq.name ||
        eq.transferringCourseId === null ||
        eq.equivalencies.some((equ) => equ.status === null),
    );
    const requestBody = currentEquivalencies.map((equivalency) => equivalency.transferringCourseId);
    if (hasNewRecords) {
      displaySaveErrors();
    } else {
      dispatch(
        postTeqAssignments(
          orgId,
          studentId,
          { transferringCourseIds: requestBody },
          navigateToTransferGrades,
        ),
      );
    }
  };

  const displaySaveErrors = () => {
    dispatch(
      setError({
        message: `${t(
          'You need to remove or create a transfer equivalencies for all Not Found records before saving',
        )}`,
      }),
    );
  };

  return (
    <Container maxWidth="xl">
      <Box className={classes.declarePage}>
        <StudentStatusBar />
        <DeclareForm>
          <Box className={classes.topLayout}>
            <Paper className={classes.laSearchContainer}>
              <Grid container>
                <Grid item xs={4} className="left">
                  <DeclareAutocomplete
                    freeSolo={false}
                    disableClearable
                    inputlabel={t('Transferring from') + ' *'}
                    getOptionLabel={(option) => option.name || ''}
                    value={selectedSourceOrg}
                    onOpen={() => {
                      setOrgSelectOpen(true);
                    }}
                    onBlur={() => {
                      setOrgSelectOpen(false);
                    }}
                    options={orgs}
                    open={orgSelectOpen}
                    renderInput={(params) => <TextField {...params} />}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      let reg = new RegExp(`^.*${inputValue}.*$`, 'gi');
                      const isExisting = options.some((option) => {
                        return option.name.match(reg);
                      });

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          btn: true,
                        });
                      }
                      return filtered;
                    }}
                    renderOption={(_props, option) => (
                      <>
                        <MenuItem
                          key={_props.key}
                          onClick={() => handleEquivalencyInputChange(option)}
                          className={classes.text}
                          value={option.id}>
                          {option.name}
                        </MenuItem>
                        {_props['data-option-index'] === orgs.length - 1 ? (
                          <>
                            <Divider key={'divider'} />
                            <MenuItem
                              key={_props.key}
                              className={classes.text}
                              onClick={navigateToCreateOrganization}>
                              {t('Create a New Organization')}
                            </MenuItem>
                          </>
                        ) : null}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6} sx={{ marginLeft: '35px' }} className="center">
                  <DeclareAutocomplete
                    className={classes.laSelect}
                    disabled={selectedSourceOrg ? false : true}
                    onChange={handleTagsInputChange}
                    inputlabel={t('Learning Activity Codes') + ' *'}
                    multiple
                    limitTags={3}
                    freeSolo
                    value={selectedLACodes}
                    options={learningActivityStore.map(({ code }) => code)}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          color="primary"
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="courseCodes"
                        error={formError['courseCodes']}
                        helperText={formError['courseCodes']?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} className="right">
                  <Button
                    disableElevation
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    sx={{ width: '125px', marginTop: '30px' }}
                    onClick={() => {
                      getTeqs(
                        orgId,
                        selectedSourceOrg.id,
                        selectedLACodes,
                        currentSelectedStudent?.currentCatalog.id,
                      );
                    }}>
                    {t('Search')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </DeclareForm>
      </Box>
      <Box className={classes.equivalencyWrapper}>
        {!_.isEmpty(currentEquivalencies) && (
          <Box className={classes.searchControlContainer}>
            <Box>
              <DeclaredSelect
                sm={true}
                className={classes.dropDown}
                multiple
                margin="dense"
                value={values.selectedEquivalencyStatuses}
                name="selectedEquivalencyStatuses"
                color="white"
                onChange={handleSetEquiValencyStatuses}
                onOpen={() =>
                  setValues({ ...values, equivalencyStatusesSelectionInProgress: true })
                }
                onClose={handleEquiValencyStatuses}
                MenuProps={MenuPropStyles}
                renderValue={(selected) => {
                  if (selected.length === teqStatuses.length) {
                    return t('All');
                  } else {
                    return selected.map((selectedItem) => selectedItem.name).join(', ');
                  }
                }}>
                <MenuItem value={'ALL'}>
                  <Checkbox
                    checked={values.selectedEquivalencyStatuses.length === teqStatuses.length}
                  />
                  <ListItemText primary={t('All')} />
                </MenuItem>
                {teqStatuses.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    <Checkbox
                      checked={
                        values.selectedEquivalencyStatuses.findIndex(
                          (status) => status?.type === item.type,
                        ) > -1
                      }
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Box>
          </Box>
        )}

        {!_.isEmpty(currentEquivalencies) && (
          <Paper flex={1} className={classes.transferEquivalencyContainer}>
            <Box flex={6}>
              <Stack flex={1}>
                <Typography fontWeight={800}>{t('Transferring In')}</Typography>
                <Typography fontWeight={800}>{`${t('Showing ')} ${filteredTeqList.length} ${t(
                  'of ',
                )} ${currentEquivalencies.length}`}</Typography>
              </Stack>
            </Box>
            <Box flex={6}>
              <Stack flex={1}>
                <Typography fontWeight={800}>{t('Transferring to')}</Typography>
              </Stack>
            </Box>
          </Paper>
        )}

        {!_.isEmpty(currentEquivalencies) && _.isEmpty(filteredTeqList) ? (
          <Paper className={classes.noData}>
            <Box>
              <Typography fontWeight={800}>
                {t('No transfer equivalencies are available for the selected filters.')}
              </Typography>
            </Box>
          </Paper>
        ) : null}

        {filteredTeqList.map((eq, index) => {
          return (
            <TransferEquivalencyRow
              value={eq}
              teqStatuses={teqStatuses}
              key={`tEqRow-${index}`}
              selectedOrg={selectedSourceOrg}
              removeItem={() => removeItem(eq.sourceLearningActivityId)}
              state={state}
              showDivider={index !== filteredTeqList.length - 1}
            />
          );
        })}
      </Box>
      {!_.isEmpty(filteredTeqList) && (
        <Box className={classes.saveBtnBox}>
          <Button
            disabled={_.isEmpty(filteredTeqList)}
            disableElevation
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => saveTEqAssignments()}
            sx={{
              width: '150px',
              marginRight: '20px',
            }}>
            {t('save')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            className={classes.button}
            onClick={() => navigateToTransferEvaluationList()}
            sx={{
              width: '150px',
              marginRight: '20px',
            }}>
            {t('cancel')}
          </Button>
        </Box>
      )}
    </Container>
  );
}
