import { actionTypes } from '../constants/actionTypes';

export const getAllRequirements = (orgId, catalogIds) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.GET_REQUIREMENTS,
    payload: { orgId, catalogIds },
  };
};

export const setRequirements = (data) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_REQUIREMENTS,
    payload: data,
  };
};

export const postTeq = (org, data, navigate) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.CREATE,
    payload: { org, data },
    navigate: navigate,
  };
};

export const postTeqWithRequirements = (org, data, navigate) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.CREATE_WITH_REQUIREMENTS,
    payload: { org, data },
    navigate: navigate,
  };
};

export const updateTeq = (org, data, transferEquivalencyId, navigate) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.EDIT,
    payload: { org, data, transferEquivalencyId },
    navigate,
  };
};

export const getTeqList = (orgId, sourceOrgId, learningActivities, catalogId) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.GET_LIST,
    payload: { orgId, sourceOrgId, learningActivities, catalogId },
  };
};

export const getTeq = (orgId, sourceOrgId, learningActivities, callback) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.GET,
    payload: { orgId, sourceOrgId, learningActivities },
    callback,
  };
};

export const setTeqList = (data) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_LIST,
    payload: data,
  };
};

export const deleteTeq = (laId) => ({
  type: actionTypes.TRANSFER_EQUIVALENCY.DELETE_LIST,
  payload: { laId },
});

export const getStatuses = (orgId) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.GET_STATUSES,
    payload: orgId,
  };
};

export const setStatuses = (data) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_STATUSES,
    payload: data,
  };
};

export const resetTeqList = (data) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.RESET,
    payload: data,
  };
};

export const postTeqAssignments = (orgId, studentId, data, navigate) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.CREATE_ASSIGNMENT,
    payload: { orgId, studentId, body: data },
    navigate,
  };
};

export const patchTeqAssignments = (orgId, studentId, data, navigate) => ({
  type: actionTypes.TRANSFER_EQUIVALENCY.PATCH_ASSIGNMENT,
  payload: { orgId, studentId, body: data },
  navigate,
});

export const setTeqAssignments = (payload) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_ASSIGNMENT,
    payload,
  };
};

export const setSelectedLACodes = (payload) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_LA_CODES,
    payload,
  };
};

export const setSelectedSourceOrg = (payload) => {
  return {
    type: actionTypes.TRANSFER_EQUIVALENCY.SET_SELECTED_SOURCE_ORG,
    payload,
  };
};
