import { useTranslation } from 'react-i18next';

export const getTermsList = (length) => {
  const { t } = useTranslation();
  var termDurations = [];

  for (var i = 0; i <= length - 1; i++) {
    termDurations.push({
      label:
        (i + 1).toString() +
        ' ' +
        (parseInt(i + 1) > 1 ? t('Consecutive Terms') : t('Consecutive Term')).toString(),
      value: i + 1,
    });
  }
  return termDurations;
};
