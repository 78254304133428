import axios from 'axios';
import { DECLARE_LOGGED_IN_USER } from '../helpers/appConstants';
import { appRoutes } from '../helpers/routes';
import { getIdentityEmail } from '../helpers/studentsHelper';
import { HttpService } from './HttpService';

export class AuthService extends HttpService {
  goToLoginScreen(link) {
    window.location = link;
  }

  async getUserInfo() {
    // eslint-disable-next-line no-undef
    const cognitoURI = process.env.REACT_APP_COGNITO_ENDPOINT;
    const endpoint = `oauth2/userInfo`;
    const access = window.localStorage.getItem('access');
    const instance = axios.create({
      baseURL: cognitoURI,
      timeout: 31000,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    const response = await instance.get(endpoint, {
      validateStatus: function (status) {
        if (status === 401) {
          window.localStorage.setItem('returnUrl', window.location.href);
          window.localStorage.removeItem('token');
          window.location = appRoutes.auth.LOGIN;
        }

        return status;
      },
    });

    const username = getIdentityEmail(response.data.username);
    window[DECLARE_LOGGED_IN_USER] = username;

    return response;
  }
}
