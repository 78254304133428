import { Box, Divider, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useEffect, useState } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { learningActivityType, StudentViewTabMenu } from '../../../helpers/appConstants';
import {
  Warning,
  MoreVert,
  Description,
  ContentPasteSearch,
  NewReleases,
} from '@mui/icons-material/';
import _ from 'lodash';
import { Preview } from 'react-dnd-preview';
import useDragScrolling from '../../../hooks/userDragScrolling';
import DeclareMenu from '../../DeclareMenu';
import theme from '../../../theme';
import { Tooltip } from '@mui/material';
import TransferEvaluationToolTip from '../../../pages/advisor/TransferEvaluation/component/TransferEvaluationToolTip';
import { useSelector } from 'react-redux';
import { getTransferModuleSettings } from '../../../helpers/settingsHelper';

const useStyles = makeStyles((theme) => ({
  laContainerItems: {
    justifyContent: 'space-between',
    justifyItems: 'stretch',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    '&:nth-of-type(1)': {
      marginTop: theme.spacing(0),
    },
  },
  laRowItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
  },
  laRowIncompleteItems: {
    display: 'flex',
    color: theme.palette.error.main,
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(45deg)',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    right: '-8rem',
    width: '20rem',
    position: 'absolute',
    padding: '8px 0 8px 0',
    '&.MuiTypography-root': {
      fontSize: '0.76rem',
    },
  },
  incompleteCourseContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  laItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  laContainer: {
    display: 'flex',
    flex: 11,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
  },
  laContainerInvalidCourse: {
    display: 'flex',
    flex: 11,
    border: `1px solid ${theme.palette.error.main}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
  },
  laIconContainer: {
    '&:hover .MuiSvgIcon-root': {
      visibility: 'visible',
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      visibility: 'hidden',
    },
  },
  retakenLabel: {
    position: 'absolute',
    '&.MuiTypography-root': {
      marginTop: theme.spacing(2.6),
      fontSize: theme.spacing(1.2),
      fontWeight: 500,
    },
  },
  laContainerParent: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover .MuiSvgIcon-root': {
      visibility: 'visible',
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      visibility: 'hidden',
    },
  },
  transferEvaluationWarningBox: {
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      visibility: 'visible',
    },
  },
}));

function TermComponent({
  termCompleted,
  laCode,
  laName,
  laUnits,
  path,
  la,
  laAdditionalInfo,
  pathwayAdditionalInfo,
  setActiveTab,
  setSelectedLearningActivity,
  termTransferred = null,
  addItemsToSelectedCourseList,
  selected,
  draggable,
  selectedCount,
  fromTerm,
  resetSelectedCourseItems,
  invalidCoursesDueToCSs = [],
  termData = null,
  markCourseAsIncomplete,
  completeAnIncompletedCourse,
  hasPathway = true,
  freeElectiveData,
  setFreeElectiveData,
  orderedPathway,
  totalUnits,
  totalProgramUnits,
  removeCourseFromPathway,
  updateStudentTransferEquivalencyStatus = () => {},
}) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const { addEventListenerForWindow, removeEventListenerForWindow } = useDragScrolling();
  const [isInvalidCourse, setIsInvalidCourse] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const { teqStatusesEnum, teqStatuses } = useSelector((state) => state.transferEquivalency);
  const openMenu = Boolean(anchorElement);
  const { processed } = useSelector((state) => state.settings);
  const transferModuleActive = getTransferModuleSettings(processed);
  const showFreeElectivesMenu =
    !_.isNil(laAdditionalInfo) && _.has(laAdditionalInfo, 'freeElective');

  const isUnassignedFreeElectives = showFreeElectivesMenu && laAdditionalInfo.freeElective;
  const courseUnits = !_.isNil(laAdditionalInfo?.requirementTransfer)
    ? laAdditionalInfo.requirementTransfer.unitsTransferred
    : laUnits;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: learningActivityType.COURSE,
      item: () => {
        addEventListenerForWindow();
        return { laCode, laName, laUnits, path };
      },
      end: () => {
        removeEventListenerForWindow();
      },
      canDrag: draggable,
      collect: (monitor) => {
        if (monitor.isDragging() && !selected) {
          if (selectedCount > 0) {
            resetSelectedCourseItems();
          }
        }
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [selectedCount, draggable],
  );

  useEffect(() => {
    const invalidCourse = invalidCoursesDueToCSs.find(
      (aCourse) =>
        aCourse.id === la.id &&
        aCourse.termId === termData.termId &&
        termData.term.year === aCourse.year,
    );
    if (!_.isNil(invalidCourse)) {
      setIsInvalidCourse(true);
    } else {
      setIsInvalidCourse(false);
    }
  }, [invalidCoursesDueToCSs]);

  const setSwapCourseAndNavigateToExploreTab = (la) => {
    setActiveTab(StudentViewTabMenu[3].value);
    setSelectedLearningActivity(la);
  };

  const handleMenuOpen = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const SwapCourse = ({ show = true }) =>
    show ? (
      <MenuItem
        onClick={() => {
          setSwapCourseAndNavigateToExploreTab(la);
          handleMenuClose();
        }}>
        <ListItemText>{t('Swap Course')}</ListItemText>
      </MenuItem>
    ) : null;

  const MarkIncomplete = ({ show = true }) =>
    show ? (
      <MenuItem
        onClick={() => {
          markCourseAsIncomplete(la, termData);
          handleMenuClose();
        }}>
        <ListItemText>{t('Mark Incomplete & Retake It')}</ListItemText>
      </MenuItem>
    ) : null;

  const UndoIncomplete = ({ show = false }) =>
    show ? (
      <MenuItem
        onClick={() => {
          completeAnIncompletedCourse(la, termData);
          handleMenuClose();
        }}>
        <ListItemText>{t('Undo Incomplete')}</ListItemText>
      </MenuItem>
    ) : null;

  const AssignCourse = ({ show = true }) =>
    show ? (
      <MenuItem
        onClick={() => {
          handleFreeElectiveMenu(laAdditionalInfo, la);
          handleMenuClose();
        }}>
        <ListItemText>{t('Assign Course')}</ListItemText>
      </MenuItem>
    ) : null;

  const RemoveFreeElective = ({ show = false }) =>
    show ? (
      <MenuItem
        onClick={() => {
          removeCourseFromPathway(la, termData);
          handleMenuClose();
        }}>
        <ListItemText>{t('Remove')}</ListItemText>
      </MenuItem>
    ) : null;

  const showIcon = (message, type) => {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'common.white',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
            },
          },
        }}
        title={
          <Box>
            <Typography sx={{ marginLeft: 3, color: 'common.black', fontWeight: 800 }}>
              {message}
            </Typography>
          </Box>
        }
        placement="bottom-start">
        {type === 'WARNING' ? (
          <Warning sx={{ cursor: 'pointer' }} color="yellow" fontSize="small" />
        ) : (
          <Description sx={{ cursor: 'pointer' }} color="primary" fontSize="medium" />
        )}
      </Tooltip>
    );
  };

  const showFreeElectiveIcon = (info) => (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'common.white',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
          },
        },
      }}
      title={
        <Box>
          <Typography sx={{ marginLeft: 3, color: 'common.black', fontWeight: 800 }}>
            {t('Input is required by an Advisor or Student.')}
          </Typography>
        </Box>
      }
      placement="bottom-start">
      <ContentPasteSearch
        onClick={() => handleFreeElectiveMenu(info)}
        sx={{ cursor: 'pointer' }}
        color="secondary"
        fontSize="medium"
      />
    </Tooltip>
  );

  const handleFreeElectiveMenu = (info) => {
    const parentId = info.groupId;
    setFreeElectiveData({
      ...freeElectiveData,
      flyOutOpen: true,
      groupId: parentId,
      termInstanceId: termData.termInstanceId,
      swapFrom: la.id,
    });
  };

  const showWarningIcon = (laAdditionalInfo) =>
    laAdditionalInfo?.type === 'WARNING'
      ? showIcon(laAdditionalInfo?.message, laAdditionalInfo.type)
      : null;

  const getTransferEquivalencyReviewProperties = (
    transferEquivalencyId,
    status,
    completedCourseReqId,
    courseReqId,
  ) => {
    const { code: approvedStatusCode } = teqStatuses.find(
      ({ type }) => type === teqStatusesEnum.APPROVED,
    );
    const { code: refusedStatusCode } = teqStatuses.find(
      ({ type }) => type === teqStatusesEnum.REFUSED,
    );

    const body = {
      completedCourseReqId,
      courseId: courseReqId,
    };

    const properties = {
      [`${teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW}`]: {
        color: 'yellow',
        titleText: t('Approval required for student'),
        buttonProps: [
          {
            text: t('Approved'),
            onClickFn: () => {
              updateStudentTransferEquivalencyStatus(transferEquivalencyId, {
                ...body,
                statusCode: approvedStatusCode,
              });
            },
          },
          {
            text: t('Refused'),
            onClickFn: () =>
              updateStudentTransferEquivalencyStatus(transferEquivalencyId, {
                ...body,
                statusCode: refusedStatusCode,
              }),
          },
        ],
      },
      [`${teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS}`]: {
        color: 'warning',
        titleText: t(
          'Equivalency review in progress, to update the status edit the transfer equivalency',
        ),
        buttonProps: [],
      },
    };

    return properties[status.type];
  };

  const showTransferEquivalencyIcon = (la, laAdditionalInfo) => {
    const showIcon =
      !_.isNil(laAdditionalInfo) &&
      !_.isNil(laAdditionalInfo?.transferEquivalencyId) &&
      !_.isNil(la?.completedCourseReqId) &&
      !_.isNil(teqStatuses) &&
      transferModuleActive;

    if (!showIcon) return null;

    const { completedCourseReqId, courseReqId } = la;
    const { transferEquivalencyId, status, studentStatus } = laAdditionalInfo;
    const validTeqStatus = [
      teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW,
      teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS,
    ];

    if (
      !validTeqStatus.includes(status.type) ||
      studentStatus?.status?.type === teqStatusesEnum.APPROVED
    )
      return null;

    const { color, titleText, buttonProps } = getTransferEquivalencyReviewProperties(
      transferEquivalencyId,
      status,
      completedCourseReqId,
      courseReqId,
    );

    return (
      <TransferEvaluationToolTip
        Icon={NewReleases}
        iconProps={{ color }}
        titleText={titleText}
        btnArray={buttonProps}
        btnContainerStyle={{ justifyContent: 'space-around' }}
      />
    );
  };

  const showTransferEquivalencyCourseWarning = (la, laAdditionalInfo, pathwayAdditionalInfo) => {
    const warningArray = [];

    if (!transferModuleActive) return null;

    if (!_.isNil(laAdditionalInfo) && !_.isEmpty(laAdditionalInfo?.warning)) {
      warningArray.push(laAdditionalInfo?.warning);
    }

    const requirementsDetails = pathwayAdditionalInfo?.usedRequirementTransfers.find(
      ({ id }) => id === la.courseReqId,
    );

    if (!_.isEmpty(orderedPathway)) {
      const units = laAdditionalInfo?.requirementTransfer?.unitsTransferred;
      const transferUnits = requirementsDetails?.units || 0;
      const message = `${transferUnits} of ${units} units used in the current plan`;
      if (transferUnits < units) {
        warningArray.push(message);
      }
    }

    if (_.isEmpty(warningArray)) return null;

    const warningText = warningArray.join('; ');

    return (
      <TransferEvaluationToolTip
        Icon={Warning}
        iconProps={{ color: 'yellow' }}
        iconStyle={{ marginTop: '2px' }}
        titleText={warningText}
      />
    );
  };

  return (
    <>
      <Box className={classes.laContainerParent}>
        {/* TODO: add this in a separate story */}
        {/* {selected && <Preview>{generatePreview}</Preview>} */}
        {/* <DragPreviewImage connect={preview} src="/transparent.png" /> */}
        <Box
          style={{ borderWidth: selected ? '3px' : '1px', overflow: 'hidden' }}
          className={isInvalidCourse ? classes.laContainerInvalidCourse : classes.laContainer}
          sx={{
            border: laAdditionalInfo?.incompleted
              ? `1px solid ${theme.palette.grey.main}`
              : `1px solid ${theme.palette.primary.main}`,
          }}
          ref={path[0] != -1 ? drag(ref) : null}
          onClick={(event) => {
            if (laAdditionalInfo?.incompleted) return;

            event.stopPropagation();
            if (fromTerm && draggable) {
              const laId = la.id;
              if (event.metaKey || event.ctrlKey) {
                addItemsToSelectedCourseList(true, { laId, laCode, laName, laUnits, path });
              } else {
                addItemsToSelectedCourseList(false, { laId, laCode, laName, laUnits, path });
              }
            }
          }}>
          <Stack
            key={`laContainer-${path[1]}`}
            direction="row"
            className={classes.laContainerItems}
            sx={{
              color: laAdditionalInfo?.incompleted ? theme.palette.grey.main : null,
            }}
            spacing={1}>
            {_.isNil(laAdditionalInfo?.requirementTransfer) ? (
              <Box key={`laItem-stack-${path[1]}`} sx={{ display: 'flex', flex: 1 }}>
                <Typography
                  key={path[1]}
                  className={classes.rowItems}
                  color={isInvalidCourse && 'error'}
                  fontWeight={800}>
                  {laCode}
                </Typography>
              </Box>
            ) : null}
            <Box key={`laItem-box-${path[1]}`} sx={{ display: 'flex', flex: 2 }}>
              <Typography
                key={`laItem-tp1-${path[1]}`}
                sx={{ marginLeft: 1 }}
                className={classes.laRowItems}
                color={isInvalidCourse && 'error'}
                fontWeight={800}>
                {laName}
              </Typography>
            </Box>
            <Box key={`laItem-tp3-${path[1]}`} className={classes.incompleteCourseContainer}>
              {laAdditionalInfo?.incompleted ? (
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: theme.palette.white.main,
                  }}>
                  <Typography className={classes.laRowIncompleteItems}>{`Incomplete`}</Typography>
                </Box>
              ) : (
                <Stack>
                  <Typography className={classes.laRowItems}>
                    {`${courseUnits} ${t('units')}`}
                  </Typography>
                  {laAdditionalInfo?.retaken && (
                    <Typography className={classes.retakenLabel} color="primary">
                      {t('Retaken')}
                    </Typography>
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
        </Box>
        <Box
          className={classes.laItemContainer}
          sx={{
            flex: 1,
          }}>
          {_.isNull(termTransferred) &&
            hasPathway &&
            (!isUnassignedFreeElectives || totalUnits > totalProgramUnits) && (
              <Box className={classes.laIconContainer}>
                <>
                  <MoreVert onClick={handleMenuOpen} color="secondary" fontSize="small" />
                  <DeclareMenu anchorEl={anchorElement} open={openMenu} onClose={handleMenuClose}>
                    <AssignCourse
                      show={!termCompleted && showFreeElectivesMenu && !isUnassignedFreeElectives}
                    />
                    <SwapCourse show={!termCompleted && !isUnassignedFreeElectives} />
                    <MarkIncomplete
                      show={
                        hasPathway &&
                        termCompleted &&
                        !laAdditionalInfo?.incompleted &&
                        !isUnassignedFreeElectives
                      }
                    />
                    <UndoIncomplete
                      show={
                        hasPathway &&
                        termCompleted &&
                        laAdditionalInfo?.incompleted &&
                        !isUnassignedFreeElectives
                      }
                    />
                    <RemoveFreeElective
                      show={showFreeElectivesMenu && totalUnits > totalProgramUnits}
                    />
                  </DeclareMenu>
                </>
              </Box>
            )}
          <>
            {showWarningIcon(laAdditionalInfo)}
            {!termCompleted && showFreeElectivesMenu && showFreeElectiveIcon(laAdditionalInfo, la)}
          </>
          {!_.isUndefined(laAdditionalInfo?.note) ? showIcon(laAdditionalInfo?.note) : null}
          <Box className={classes.transferEvaluationWarningBox}>
            {showTransferEquivalencyIcon(la, laAdditionalInfo)}
            {showTransferEquivalencyCourseWarning(la, laAdditionalInfo, pathwayAdditionalInfo)}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TermComponent;
