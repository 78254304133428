import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/**
 * Declare date picker
 * @param props Properties
 */

export default function DeclareDatePicker(props) {
  props = Object.assign(props);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker {...props} />
      </LocalizationProvider>
    </div>
  );
}
