import React, { useEffect, useRef, useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StyleIcon from '@mui/icons-material/Style';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { makeStyles } from '@mui/styles';
import ProgramMapTree from '../ProgramMapTree';
import './TreeNode.css';
import { MenuPropStyles } from '../../helpers/common';
import { PROGRAM_MAP_TYPE } from '../../helpers/programMapHelper';
import { useDrag, useDrop } from 'react-dnd';
import TreeContextMenu from './TreeContextMenu';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import _ from 'lodash';
import ProgramMapLoadMore from '../ProgramMapLoadMore';

const useStyle = makeStyles((theme) => ({
  chevron: {
    float: 'left',
    '&.MuiSvgIcon-root': {
      height: '1.4em',
      width: '0.8em',
    },
  },
  groupIcon: {
    margin: theme.spacing(0.5),
    float: 'left',
    marginLeft: ({ hasChild }) => (hasChild ? theme.spacing(2) : 'auto'),
  },
}));

export default function TreeNode({
  node,
  searchKeyword,
  addNewGroup,
  selectedElementPath,
  onGroupSelect,
  addLearningActivities,
  handleModalOpen,
  moveTreeNode,
  removeTreeNode,
  getItemsFromParents,
}) {
  let dragDropRef = null;
  const [childVisible, setChildVisibility] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const ref = useRef();
  const [draggedEleHoverPosition, setDraggedEleHoverPosition] = useState(null);

  const hasChild = node.type != PROGRAM_MAP_TYPE.LEARNING_ACTIVITY && node.items.length > 0;
  const isProgramMapNode = node.type === PROGRAM_MAP_TYPE.PROGRAM_MAP;

  const openMenu = Boolean(anchorEl);
  const classes = useStyle(hasChild);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ProgramTreeNode',
    item: {
      location: selectedElementPath,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver, elem }, drop] = useDrop(
    () => ({
      accept: 'ProgramTreeNode',
      hover: (_item, monitor) => {
        if (monitor.isOver({ shallow: true }) && !searchKeyword) {
          const myWidthPotion = ref.current?.getBoundingClientRect().height / 3;
          const diff = monitor.getClientOffset().y - ref.current?.getBoundingClientRect().top;
          const section = Math.floor(diff / myWidthPotion) - 1;
          setDraggedEleHoverPosition(section);
        }
      },
      drop: (item, monitor) => {
        if (!searchKeyword) {
          if (monitor.didDrop()) {
            return;
          }
          moveTreeNode(item.location, elem, draggedEleHoverPosition);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }) && selectedElementPath.length != 0,
        dropItem: monitor.getItem(),
        elem: selectedElementPath,
        targetId: monitor.targetId,
      }),
    }),
    [selectedElementPath, draggedEleHoverPosition, searchKeyword],
  );

  useEffect(() => {
    if (searchKeyword) {
      setChildVisibility(true);
    }
  }, [searchKeyword]);

  const clickArrowIcon = (childVisible) => {
    setChildVisibility(!childVisible);
  };

  let hideMenuOptions = false;
  let showRemoveOnly = false;

  if (_.isNull(node.programMapId)) {
    dragDropRef = drag(drop(ref));
    hideMenuOptions = false;
  } else {
    if (node.isParentNodeProgramMap) {
      showRemoveOnly = true;
      hideMenuOptions = false;
      dragDropRef = drag(drop(ref));
    } else if (node.type === PROGRAM_MAP_TYPE.GROUP && node.newlyAdded) {
      dragDropRef = drag(drop(ref));
      hideMenuOptions = false;
    } else if (node.type === PROGRAM_MAP_TYPE.PROGRAM_MAP && node.newlyAdded) {
      showRemoveOnly = true;
      hideMenuOptions = false;
      dragDropRef = drag(drop(ref));
    } else if (node.type === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY && node.newlyAdded) {
      dragDropRef = drag(drop(ref));
      showRemoveOnly = true;
    } else {
      hideMenuOptions = true;
    }
  }

  const onClickTreeNode = (selectedElementPath, isGroupSelected = false) => {
    onGroupSelect(selectedElementPath, isGroupSelected);
  };

  return (
    <>
      {isOver && draggedEleHoverPosition === -1 && searchKeyword === null ? (
        <li
          className={classes.declareRuleGroup}
          style={{
            borderTop: '0.01em solid',
            width: 'auto',
          }}></li>
      ) : (
        ''
      )}

      <li
        ref={dragDropRef}
        className="d-tree-node"
        style={{
          display: isDragging ? 'none' : '',
          fontSize: 25,
          fontWeight: 'bold',
        }}>
        <div>
          <div className="d-tree-head">
            {node.type == PROGRAM_MAP_TYPE.GROUP && hasChild && (
              <>
                {!(childVisible && hasChild) ? (
                  <ArrowRightIcon
                    className={classes.chevron}
                    onClick={() => setChildVisibility((v) => !v)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className={classes.chevron}
                    onClick={() => setChildVisibility((v) => !v)}
                  />
                )}
              </>
            )}
            {node.type == PROGRAM_MAP_TYPE.LEARNING_ACTIVITY && (
              <CardMembershipIcon
                color="secondary"
                sx={{ margin: '4px 4px 4px 20px', float: 'left' }}
              />
            )}
            {node.type == PROGRAM_MAP_TYPE.GROUP && (
              <>
                {!hasChild && (
                  <ArrowDropDownIcon className={classes.chevron} sx={{ visibility: 'hidden' }} />
                )}
                <StyleIcon className={classes.groupIcon} color="secondary" />
              </>
            )}
            {isProgramMapNode && (
              <>
                {!childVisible ? (
                  <ArrowRightIcon
                    className={classes.chevron}
                    onClick={() => clickArrowIcon(childVisible)}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className={classes.chevron}
                    onClick={() => clickArrowIcon(childVisible)}
                  />
                )}
                <InsertLinkIcon
                  className={classes.groupIcon}
                  color="secondary"
                  sx={{ transform: 'rotateZ(45deg)' }}
                />
              </>
            )}
            <Typography
              display="inline"
              sx={{ float: 'left', lineHeight: 2.1 }}
              onClick={() => {
                onClickTreeNode(selectedElementPath, true);
              }}>
              {`${node.code ? node.code + ' ' : ''}${node.name}`}
            </Typography>

            {!hideMenuOptions ? (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
                className="d-tree-action">
                <MoreVertIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton sx={{ visibility: 'hidden' }}>
                <MoreVertIcon fontSize="small" sx={{ visibility: 'hidden' }} />
              </IconButton>
            )}

            <TreeContextMenu
              type={node.type}
              searchKeyword={searchKeyword}
              anchorEl={anchorEl}
              openMenu={openMenu}
              handleMenuClose={handleMenuClose}
              MenuPropStyles={MenuPropStyles}
              selectedElementPath={selectedElementPath}
              addNewGroup={addNewGroup}
              setChildVisibility={setChildVisibility}
              handleModalOpen={handleModalOpen(node)}
              childVisible={childVisible}
              hasChild={hasChild}
              removeTreeNode={removeTreeNode}
              showRemoveOnly={showRemoveOnly}
            />
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <ul className="d-tree-container">
              {node.type != PROGRAM_MAP_TYPE.LEARNING_ACTIVITY && (
                <>
                  <ProgramMapTree
                    data={node.items}
                    searchKeyword={searchKeyword}
                    selectedElementPath={selectedElementPath}
                    addNewGroup={addNewGroup}
                    onGroupSelect={onGroupSelect}
                    addLearningActivities={addLearningActivities}
                    handleModalOpen={handleModalOpen}
                    moveTreeNode={moveTreeNode}
                    removeTreeNode={removeTreeNode}
                    getItemsFromParents={getItemsFromParents}
                  />
                  <ProgramMapLoadMore node={node} getItemsFromParents={getItemsFromParents} />
                </>
              )}
            </ul>
          </div>
        )}
      </li>

      {isOver && draggedEleHoverPosition === 1 ? (
        <li
          className={classes.declareRuleGroup}
          style={{
            borderTop: '0.01em solid',
            width: 'auto',
          }}></li>
      ) : (
        ''
      )}
    </>
  );
}
