import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { appRoutes } from '../../../helpers/routes';
import { getPrograms } from '../../../redux/actions/programActions';
import StatusCell from '../../../components/StatusCell';
import EditIcon from '@mui/icons-material/Edit';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';

export default function ProgramList() {
  const { t } = useTranslation();
  const programList = useSelector((state) => state.programs.programList);
  const selectedOrg = useSelector((state) => state.allOrgs.selectedOrg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [searchKey, setSearchKey] = useState('');
  const [filteredProgramList, setFilteredProgramList] = useState(programList);

  useEffect(() => {
    var filteredList = [];
    if (searchKey != '') {
      const filters = [
        (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        (o) => o.status.toLowerCase().startsWith(searchKey.toLowerCase()),
        (o) => o.department.name.toLowerCase().includes(searchKey.toLowerCase()),
      ];
      filteredList = programList.filter((o) => filters.some((fn) => fn(o)));
      programList.forEach((element) => {
        const filterProgramOptions = [
          (o) => o.name.toLowerCase().includes(searchKey.toLowerCase()),
        ];
        let programOptionFiltered = element.programOptions.filter((o) =>
          filterProgramOptions.some((fn) => fn(o)),
        );
        let isExist = filteredList.filter((res) => res.id === element.id);
        if (programOptionFiltered.length > 0 && isExist.length === 0) {
          filteredList.push(element);
        }
      });
      setFilteredProgramList(filteredList);
    } else {
      setFilteredProgramList(programList);
    }
  }, [programList, searchKey]);

  useEffect(() => {
    if (!programList.length > 0) {
      dispatch(getPrograms(orgId));
      setSearchKey('');
    }
  }, []);

  const navigateToCreateProgram = () => {
    navigate(generatePath(appRoutes.catalog.PROGRAM_CREATE, { orgId: selectedOrg.id }));
  };

  const navigateToEditProgram = async (data) => {
    navigate(
      generatePath(appRoutes.catalog.PROGRAM_EDIT, { orgId: selectedOrg.id, programId: data.id }),
    );
  };

  const columns = [
    {
      name: 'name',
      label: t('Program Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'programOptions',
      label: t('Options'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="p" className="ElipsisText">
              {value.map((val) => val.name).join(', ')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'department',
      label: t('Department/College'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="p" className="ElipsisText">
              {value.name}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: t('status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: t('Actions'),
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                let index = programList.findIndex((x) => x.id === value);
                navigateToEditProgram(programList[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('programs')]} />
          <SearchTextBox searchKey={searchKey} setSearchKey={setSearchKey} />
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            onClick={() => {
              navigateToCreateProgram();
            }}>
            {t('New Program')}
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={filteredProgramList} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
