import { HttpService } from './HttpService';

export class RequirementService extends HttpService {
  async getAll(org, catalogIds) {
    return Promise.all(
      catalogIds.map((id) =>
        this.get(`organizations/${org}/programMaps/requirements?catalog=${id}`),
      ),
    ).then((allCatalogData) => {
      const items = allCatalogData.map(({ data: { items } }) => items).flat();
      return { data: { items }, status: true };
    });
  }
}
