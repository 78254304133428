import { makeStyles } from '@mui/styles';

export const dataTableStyles = makeStyles(() => ({
  root: {
    '& .ElipsisText': {
      display: 'block',
      width: '200px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& .MuiTableCell-root': {
      '&.MuiTableCell-body': {
        paddingLeft: '8px',
      },
      '& .cellTextCenter': {
        textAlign: 'center',
      },
    },
  },

  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
