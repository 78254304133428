import { setUserInfo } from '../../actions/auth';
import { requestHandler, requestTypes } from '../helper';
import { requestRedirectToLogin, requestUserInfo } from '../requests/auth';

export function* handleLoginRedirect(action) {
  const { payload } = action;
  yield requestHandler(requestRedirectToLogin.bind(this, payload), null, requestTypes.GET, null);
}

export function* handleUserInfo() {
  yield requestHandler(requestUserInfo.bind(this), setUserInfo, requestTypes.GET, null);
}
