import {
  requestPostTeq,
  requestGetRequirements,
  requestGetTeqs,
  requestPostTeqAssignments,
  requestGetTeqStatuses,
  requestUpdateTeq,
  requestPatchTeqAssignments,
  requestPostTeqWithRequirements,
} from '../requests/transferEquivalency';
import { requestHandler, requestTypes } from '../helper';
import { setRequirements, setTeqList, setStatuses } from '../../actions/transferEquivalencyActions';

export function* handlePostTeq(action) {
  const { payload, navigate } = action;
  yield requestHandler(requestPostTeq.bind(this, payload), null, requestTypes.POST, navigate);
}

export function* handlePostTeqWithRequirements(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPostTeqWithRequirements.bind(this, payload),
    null,
    requestTypes.POST,
    navigate,
  );
}

export function* handleGetTeqStatuses(action) {
  const { payload } = action;
  yield requestHandler(requestGetTeqStatuses.bind(this, payload), setStatuses, requestTypes.GET);
}

export function* handleUpdateTeq(action) {
  const { payload, navigate } = action;
  yield requestHandler(requestUpdateTeq.bind(this, payload), null, requestTypes.PUT, navigate);
}

export function* handleGetRequirements(action) {
  const {
    payload: { orgId, catalogIds },
  } = action;
  yield requestHandler(
    requestGetRequirements.bind(this, orgId, catalogIds),
    setRequirements,
    requestTypes.GET,
  );
}

export function* handleGetTeqs(action) {
  const {
    payload: { orgId, sourceOrgId, learningActivities, catalogId },
  } = action;
  yield requestHandler(
    requestGetTeqs.bind(this, orgId, sourceOrgId, learningActivities, catalogId),
    setTeqList,
    requestTypes.GET,
  );
}

export function* handleGetTeq(action) {
  const {
    payload: { orgId, sourceOrgId, learningActivities },
    callback,
  } = action;
  const cb = (data) => {
    return callback(data);
  };
  yield requestHandler(
    requestGetTeqs.bind(this, orgId, sourceOrgId, learningActivities, null),
    null,
    requestTypes.GET,
    cb,
  );
}

export function* handlePostTeqAssignments(action) {
  const { payload, navigate } = action;

  yield requestHandler(
    requestPostTeqAssignments.bind(this, payload),
    null,
    requestTypes.POST,
    navigate,
  );
}

export function* handlePatchTeqAssignments(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPatchTeqAssignments.bind(this, payload),
    null,
    requestTypes.PATCH,
    navigate,
  );
}
