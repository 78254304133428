import { actionTypes } from '../constants/actionTypes';

export const getSettings = (params) => {
  return {
    type: actionTypes.SETTINGS.GET,
    payload: params,
  };
};

export const setSettings = (payload) => {
  return {
    type: actionTypes.SETTINGS.SET,
    payload,
  };
};

export const createSettings = (org, data) => {
  return {
    type: actionTypes.SETTINGS.SAVE,
    payload: { org, data },
  };
};
