import { makeStyles } from '@mui/styles';

export const transferEquivalencyRawStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  actionItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover $editIcon': {
      display: 'block',
    },
    marginTop: ({ option }) => (option ? theme.spacing(1.5) : null),
  },

  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: ({ option }) => (option ? theme.spacing(2.5) : theme.spacing(6)),
  },
  transferEquivalencyContainer: {
    marginTop: ({ option }) => (option ? null : theme.spacing(3)),
    padding: ({ option }) => (option ? null : theme.spacing(3)),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:nth-last-of-type(1)': {
      marginBottom: theme.spacing(3),
    },
    '&:hover $removeItem': {
      visibility: 'visible',
    },
    '&:hover $addEquivalency': {
      visibility: 'visible',
    },
    '&:hover $editIcon': {
      display: 'block',
    },
  },
  rawContainer: {
    //marginTop: ({ option }) => (option ? null : theme.spacing(3)),
    paddingLeft: ({ option }) => (option ? null : theme.spacing(3)),
    paddingRight: ({ option }) => (option ? null : theme.spacing(3)),
    paddingBottom: ({ option }) => (option ? null : theme.spacing(3)),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:nth-last-of-type(1)': {
      marginBottom: theme.spacing(3),
    },
    '&:hover $removeItem': {
      visibility: 'visible',
    },
    '&:hover .MuiBox-root svg': {
      visibility: 'visible',
    },
    '&:hover $addEquivalency': {
      visibility: 'visible',
    },
    '&:hover $editIcon': {
      display: 'block',
    },
  },

  rawDivider: {
    marginTop: theme.spacing(2),
  },

  transferInCourseContainer: {
    marginTop: '43px',
  },

  courseContainerItems: {
    paddingLeft: ({ option }) => (option ? theme.spacing(1) : null),
    marginTop: ({ option }) => (option ? null : theme.spacing(2)),
    '&:nth-of-type(1)': {
      marginTop: theme.spacing(0),
    },
  },

  courseContainerItemsFullHighlight: {
    paddingLeft: ({ option }) => (option ? theme.spacing(1) : null),
    marginTop: '({ option }) => (option ? null : theme.spacing(2))',
    '&:nth-of-type(1)': {
      marginTop: theme.spacing(0),
    },
    color: 'white',
  },

  courseContainer: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
    '&.MuiBox-root:nth-of-type(2)': {
      marginTop: ({ option }) => (option ? theme.spacing(2) : 'auto'),
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  courseContainerHighlighted: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    marginTop: ({ option }) => (option ? theme.spacing(2) : theme.spacing(1)),
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
  },

  courseContainerHighlightedPartially: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.main}`,
    padding: ({ option }) => (option ? theme.spacing(0.2) : theme.spacing(2)),
    borderRadius: theme.spacing(1),
    marginTop: ({ option }) => (option ? theme.spacing(2) : theme.spacing(1)),
    '&:nth-of-type(1)': {
      marginBottom: theme.spacing(2),
    },
  },

  rowItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    '&.MuiTypography-root': {
      fontWeight: ({ option }) => (option ? null : 800),
      fontSize: ({ option }) => (option ? 'small' : 'medium'),
    },
  },
  rowItemsUnits: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'right',
    width: 'auto',
    paddingRight: ({ option }) => (option ? theme.spacing(1) : null),
    '&.MuiTypography-root': {
      fontSize: ({ option }) => (option ? 'small' : 'medium'),
    },
  },
  removeItem: {
    position: 'absolute',
    right: theme.spacing(-3),
    top: theme.spacing(-1),
    '&.MuiSvgIcon-root': {
      visibility: 'hidden',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    cursor: 'pointer',
    verticalAlign: 'center',
    '&.MuiSvgIcon-root': {
      display: 'none',
    },
  },
  button: {
    '&.MuiButton-root': {
      height: '45px',
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
      width: '100px',
      '&:nth-last-of-type(1)': {
        marginLeft: theme.spacing(2),
      },
      '&:nth-last-of-type(2)': {
        marginLeft: theme.spacing(5.5),
      },
    },
  },
  hr: {
    display: 'block',
    visibility: 'visible',
    height: '1px',
    borderTop: '1px solid #ccc',
    margin: '1em 0',
  },
  actionItems: {
    cursor: 'pointer',
  },
  addEquivalency: {
    visibility: 'hidden',
  },
}));
