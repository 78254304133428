import * as React from 'react';
import style from './byTermPdf.css';
import classNames from 'classnames/bind';
import { termPlanningStatusTypes } from '../helpers/appConstants';
import _ from 'lodash';
import { isIncompletedCourse, isRetakenCourse } from '../helpers/pathwayHelper';
import { getTermDisplayName } from '../helpers/termsHelper';
import { isFreeElective } from '../helpers/studentsHelper';
export class ByTermPdfTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const marginTop = '96px';
    const marginRight = '96px';
    const marginBottom = '96px';
    const marginLeft = '96px';
    const getPageMargins = () => {
      return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };
    const {
      t,
      student,
      orderedPathway,
      selectedProgramMap,
      orgPdfLogo,
      selectedOrg,
      studentCompletedTerms,
      groupedCompletedExemptedCourses,
      teqStatusesEnum,
      pathwayAdditionalInfo,
    } = this.props;

    let cx = classNames.bind(style);

    const renderTermCards = ({ term, items, units, termPlanningStatus }, index) => (
      <div
        key={`term-${index}`}
        style={{
          display: 'flex',
          height: 'auto',
          width: '49%',
          marginBottom: '20px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '8px 0px',
            borderBottom: '1px solid #000000',
          }}>
          <p>{getTermDisplayName(term)}</p>
          <p>{units} hours</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0px',
            overflowY: 'auto',
            flex: 1,
            justifyContent: 'flex-start',
          }}>
          {termPlanningStatus == termPlanningStatusTypes.SIT_OUT ? (
            <div
              style={{
                display: 'flex',
                flex: 12,
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <p>Student defined break.</p>
            </div>
          ) : (
            _.isEmpty(items) && (
              <div
                style={{
                  display: 'flex',
                  flex: 12,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <p>No Courses Assigned.</p>
              </div>
            )
          )}
          {items.map(({ name, code, units, additionalInfo }, index) => (
            <div key={`course-${index}`} style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 12,
                  flexDirection: 'column',
                }}>
                <div
                  key={`laContainer-`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    justifyItems: 'stretch',
                    alignItems: 'flex-start',
                    gap: 1,
                    '&:nth-of-type(1)': {
                      marginTop: 0,
                    },
                  }}>
                  <div key={`laItem-stack-`} style={{ display: 'flex', flex: 1 }}>
                    <p>{code}</p>
                  </div>
                  <div key={`laItem-box-`} style={{ display: 'flex', flex: 2 }}>
                    <p
                      key={`laItem-tp1-`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'auto',
                      }}>
                      {name}
                      {isIncompletedCourse(additionalInfo) ? `- ${t('Incomplete')}` : ''}
                      {isRetakenCourse(additionalInfo) ? `- ${t('Retaken')}` : ''}
                      {isFreeElective(additionalInfo) ? `- ${t('Elective')}` : ''}
                    </p>
                  </div>
                  <div
                    key={`laItem-tp3-`}
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}>
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 'auto',
                      }}>
                      {isIncompletedCourse(additionalInfo) ? 0 : units}
                    </p>
                  </div>
                </div>
                {additionalInfo && (
                  <div
                    key={`laContainer-warning-`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      justifyItems: 'stretch',
                      alignItems: 'flex-start',
                      gap: 1,
                      '&:nth-of-type(1)': {
                        marginTop: 0,
                      },
                    }}>
                    <div key={`laItem-stack-`} style={{ display: 'flex', flex: 1 }}>
                      <p>&nbsp;</p>
                    </div>
                    <div key={`laItem-stack-`} style={{ display: 'flex', flex: 2 }}>
                      <p style={{ fontSize: '8px', fontStyle: 'italic' }}>
                        {additionalInfo.message}
                      </p>
                    </div>
                    <div key={`laItem-stack-`} style={{ display: 'flex', flex: 1 }}>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    const getTeqMessage = (additionalInfo) => {
      const messageArray = [];

      if (_.isNil(additionalInfo)) return null;

      const { status, studentStatus } = additionalInfo;
      const hasStudentBeenApproved =
        !_.isNil(studentStatus) && studentStatus?.status?.type === teqStatusesEnum.APPROVED;

      if (
        status?.type === teqStatusesEnum.REQUIRES_STUDENT_BY_STUDENT_REVIEW &&
        !hasStudentBeenApproved
      ) {
        messageArray.push(t('Transfer requires approval'));
      }

      if (status?.type === teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS) {
        messageArray.push(t('Equivalency review in progress'));
      }

      if (!_.isEmpty(additionalInfo?.warning)) {
        messageArray.push(additionalInfo?.warning);
      }

      return messageArray.join('; ');
    };

    const renderCompleteCourseItems = (title, completedCourses, type, isExempt = false) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0px',
            flex: 1,
            gap: 5,
            justifyContent: 'flex-start',
          }}>
          <p>{title}</p>
          {completedCourses.map(({ name, code, units, id, additionalInfo }, index) => {
            const message = getTeqMessage(additionalInfo);
            let courseName = name;
            let courseCode = code;
            let courseUnits = units;

            if (!_.isNil(additionalInfo?.requirementTransfer) && !_.isNil(pathwayAdditionalInfo)) {
              const requirementsDetails = pathwayAdditionalInfo?.usedRequirementTransfers.find(
                (data) => data.id === id,
              );

              if (!_.isNil(requirementsDetails)) {
                const requirementUnits = additionalInfo?.requirementTransfer?.unitsTransferred;
                courseName = `${requirementsDetails.units} of ${requirementUnits} units used in the current plan`;
                courseCode = name;
                courseUnits = requirementUnits;
              }
            }

            return (
              <div key={`course-${index}`} style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 12,
                    flexDirection: 'column',
                  }}>
                  <div
                    key={`laContainer-`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      justifyItems: 'stretch',
                      alignItems: 'flex-start',
                      gap: 1,
                      '&:nth-of-type(1)': {
                        marginTop: 0,
                      },
                    }}>
                    <div key={`laItem-stack-`} style={{ display: 'flex', flex: 1.4 }}>
                      <p>{courseCode}</p>
                    </div>
                    <div
                      key={`laItem-box-`}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 8,
                        justifyContent: 'center',
                        width: 'auto',
                      }}>
                      <p key={`laItem-tp1-`}>{courseName}</p>
                      <p style={{ fontSize: '8px', fontStyle: 'italic' }}>{message}</p>
                    </div>
                    <div
                      key={`laItem-tp3-`}
                      style={{
                        display: 'flex',
                        flex: 2,
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                      }}>
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 'auto',
                        }}>
                        {!isExempt ? courseUnits + ' hours' : 'Exempt'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    const renderCompletedCourseCard = () => {
      return (
        <div
          key={`completedExemptCourses`}
          style={{
            display: 'flex',
            height: 'auto',
            width: '100%',
            marginBottom: '20px',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '8px 0px',
              borderBottom: '1px solid #000000',
            }}>
            <p>Previous Coursework</p>
          </div>
          {[
            !_.isUndefined(groupedCompletedExemptedCourses?.Transferred) &&
              renderCompleteCourseItems(
                t('Transferred'),
                groupedCompletedExemptedCourses.Transferred,
                'Transferred',
              ),
            !_.isUndefined(groupedCompletedExemptedCourses?.Completed) &&
              renderCompleteCourseItems(
                t('Completed'),
                groupedCompletedExemptedCourses.Completed,
                'Completed',
              ),
            !_.isUndefined(groupedCompletedExemptedCourses?.Exempt) &&
              renderCompleteCourseItems(
                t('Exempt'),
                groupedCompletedExemptedCourses.Exempt,
                'Exempt',
                true,
              ),
          ]}
        </div>
      );
    };

    return (
      <div className="byTermPdf">
        <style type="text/css" media="print">
          {'\
   @page { size: portrait; }\
'}
          {getPageMargins()}
        </style>
        <div className={cx('pageWrapper')}>
          <div className={cx('nameTagContainer')}>
            <div>
              <p className={cx('noMargin')}>Name: {student.name}</p>
              <p className={cx('noMargin')}>ID: {student.studentId}</p>
              <p className={cx('noMargin')}>{selectedOrg.name}</p>
            </div>
            <div>
              <img src={orgPdfLogo} alt="delcare logo" style={{ width: '100px' }} />
            </div>
          </div>
          <div className={cx('pageHeader')}>
            <div className={cx('pageTitle')}>
              <p className={cx('noMargin')} style={{ fontWeight: 'bold', fontSize: '18px' }}>
                {selectedProgramMap.programName} - {orderedPathway[0].term.name} Start
              </p>
            </div>
          </div>
          <h3
            style={{
              textAlign: 'center',
              fontSize: '15px',
              padding: '10px',
              backgroundColor: '#c7d9f1',
              border: '1px solid #000000',
            }}>
            {selectedProgramMap.optionName}
          </h3>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            {!_.isEmpty(groupedCompletedExemptedCourses) && renderCompletedCourseCard()}
            {studentCompletedTerms.map(({ term, items, units, termPlanningStatus }, index) =>
              renderTermCards({ term, items, units, termPlanningStatus }, index),
            )}
            {orderedPathway.map(({ term, items, units, termPlanningStatus }, index) =>
              renderTermCards({ term, items, units, termPlanningStatus }, index),
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const ByTermPdfTemplateWrapper = React.forwardRef((props, ref) => {
  return (
    <ByTermPdfTemplate
      ref={ref}
      t={props.t}
      student={props.student}
      studentCompletedTerms={props.studentCompletedTerms}
      orderedPathway={props.orderedPathway}
      selectedProgramMap={props.selectedProgramMap}
      location={props.location}
      selectedOrg={props.selectedOrg}
      orgPdfLogo={props.orgPdfLogo}
      groupedCompletedExemptedCourses={props.groupedCompletedExemptedCourses}
      teqStatusesEnum={props.teqStatusesEnum}
      pathwayAdditionalInfo={props.pathwayAdditionalInfo}
    />
  );
});
