import * as React from 'react';
import style from './pdf.css';
import image from './logo.png';
import classNames from 'classnames/bind';
import { PROGRAM_MAP_TYPE } from '../helpers/programMapHelper';
import _ from 'lodash';

export class PdfTemplate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      selectedOrg,
      orgPdfLogo,
      student,
      catalog,
      evaluation,
      mappedData,
      transferredCourses,
      translate,
      teqStatusesEnum,
    } = this.props;

    let cx = classNames.bind(style);
    let mapArray = [];
    let total = 0;
    const { name: orgName, initials: orgCode } = selectedOrg;
    const transformedMappedData = mappedData.items.map((data) => ({ ...data, isParent: true }));
    const transferredRequirements = transferredCourses.filter(
      ({ additionalInfo, type }) => !_.isNil(additionalInfo.requirementTransfer),
    );

    const addMessageRow = (data) => {
      let outputMessage = data.additionalInfo.warning.join('; ');
      const statusType = data.additionalInfo.status.type;

      if (statusType === teqStatusesEnum.EQUIVALENCY_REVIEW_INPROGRESS) {
        outputMessage += !_.isEmpty(outputMessage) ? '; ' : '';
        outputMessage += translate(translate('Equivalency review in progress'));
      }

      if (!_.isEmpty(outputMessage)) {
        mapArray.push(
          <tr>
            <td style={{ backgroundColor: '#cdcdcd' }} colSpan="7">
              <i>{outputMessage}</i>
            </td>
          </tr>,
        );
      }
    };

    const addRequirementRow = (groupReq) => {
      if (groupReq) {
        mapArray.push(
          <tr className={cx('requirementRowStyle')}>
            <td style={{ textAlign: 'left', backgroundColor: '#cdcdcd' }}></td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}>
              {groupReq?.additionalInfo?.sourceLa?.code}
            </td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}>
              {groupReq?.additionalInfo?.sourceLa?.university?.initials}
            </td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}>
              {groupReq?.additionalInfo?.sourceLa?.grade}
            </td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}>
              {groupReq?.additionalInfo?.sourceLa?.units}
            </td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}></td>
            <td style={{ textAlign: 'center', backgroundColor: '#cdcdcd' }}></td>
          </tr>,
        );
      }
    };

    const calculateCourseUnitsInProgramMapGroup = (items = [], units = 0) => {
      items.forEach((data) => {
        const isProgramMapOrGroup =
          data.type === PROGRAM_MAP_TYPE.GROUP || data.type === PROGRAM_MAP_TYPE.PROGRAM_MAP;

        if (isProgramMapOrGroup && !_.isEmpty(data.items)) {
          calculateCourseUnitsInProgramMapGroup(data.items, units);
        } else {
          units += data.units;
          total += data.units;
        }
      });

      return units;
    };

    const transformMap = (items) => {
      items.forEach((data) => {
        const isProgramMapOrGroup =
          data.type === PROGRAM_MAP_TYPE.GROUP || data.type === PROGRAM_MAP_TYPE.PROGRAM_MAP;

        if (isProgramMapOrGroup) {
          if (!_.isEmpty(data.items)) {
            const groupUnits = calculateCourseUnitsInProgramMapGroup(data.items, 0);
            const groupReq = transferredRequirements.find(
              ({ courseReqId }) => courseReqId === data.id,
            );

            if (data.isParent) {
              mapArray.push(
                <tr>
                  <td
                    colSpan={7}
                    style={{ textAlign: 'center', backgroundColor: '#cf0221', color: 'white' }}>
                    {`${data.name} (${total} cr) `}
                    <i>{data.message}</i>
                  </td>
                </tr>,
              );
            } else {
              mapArray.push(
                <tr>
                  <td colSpan={7} style={{ backgroundColor: '#cdcdcd' }}>
                    {`${data.name} (${groupUnits} cr) `}
                    <i>{data.message}</i>
                  </td>
                </tr>,
              );
              total = 0;
            }
            addRequirementRow(groupReq);
            if (!_.isNil(groupReq)) {
              addMessageRow(groupReq);
            }

            transformMap(data.items);
          }
        } else {
          const transfers = transferredCourses.find(
            (info) => info.id === data.id && info.groupId === data.parentId,
          );

          mapArray.push(
            <tr>
              <td style={{ textAlign: 'left' }}>{`${data.code} ${data.name} (${data.units})`} </td>
              <td style={{ textAlign: 'center' }}>{transfers?.additionalInfo?.sourceLa?.code}</td>
              <td style={{ textAlign: 'center' }}>
                {transfers?.additionalInfo?.sourceLa?.university?.initials}
              </td>
              <td style={{ textAlign: 'center' }}>{transfers?.additionalInfo?.sourceLa?.grade}</td>
              <td style={{ textAlign: 'center' }}>{transfers?.additionalInfo?.sourceLa?.units}</td>
              <td style={{ textAlign: 'center' }}></td>
              <td style={{ textAlign: 'center' }}></td>
            </tr>,
          );

          if (!_.isNil(transfers)) {
            addMessageRow(transfers);
          }
        }
      });
      return mapArray;
    };

    return (
      <div className="evaluationPdf">
        <style type="text/css" media="print">
          {'\
   @page { size: portrait; }\
'}
        </style>
        <div className="flash" />
        <div className={cx('pageWrapper')} style={{ fontSize: '10px' }}>
          <div className={cx('pageHeader')}>
            <div>
              <img src={orgPdfLogo} alt="delcare logo" style={{ width: '100px' }} />
            </div>
            <div className={cx('pageTitle')} style={{ width: '400px' }}>
              <p className={cx('noMargin')} style={{ fontWeight: 'bold', fontSize: '18px' }}>
                Unofficial Evaluation
              </p>
              <p className={cx('noMargin')} style={{ fontWeight: 'bold', fontSize: '15px' }}>
                {orgName}
              </p>
              <p className={cx('noMargin')}>{orgCode}</p>
            </div>
          </div>
          <div className={cx('nameTagContainer')}>
            <div>
              <div style={{ borderBottom: '1px solid', width: '200px' }}>
                <p>Name: {student.name}</p>
              </div>
              <div style={{ borderBottom: '1px solid', width: '200px' }}>
                <p>ID: {student.studentId}</p>
              </div>
              <p className={cx('noMargin')}>
                Unofficial Evaluation for Catalog Year {catalog?.year}
              </p>
              <a
                className={cx('noMargin')}
                href="https://getdeclared.com/"
                target="_blank"
                rel="noopener noreferrer">
                View Transfer policies here
              </a>
            </div>
            <div>
              <table style={{ width: '350px', marginLeft: '100px' }}>
                <tr>
                  <td>Total Credits Needed for Degree</td>
                  <td>{evaluation.totalUnits}</td>
                </tr>
                <tr>
                  <td>Total Credits Earned to Date</td>
                  <td>{evaluation.unitsCovered}</td>
                </tr>
                <tr>
                  <td>Credits Remaining to Degree Completion</td>
                  <td>{evaluation.totalUnits - evaluation.unitsCovered}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className={cx('nameTagContainer')}>
            <div>
              <table style={{ width: '816px' }}>
                <thead>
                  <tr>
                    <th style={{ width: '45%' }}>Course Requirements</th>
                    <th>Equivalent</th>
                    <th>Institution Abv</th>
                    <th>Grade</th>
                    <th>Program credits</th>
                    <th>Course Planning Term</th>
                    <th>Previously Equated (Y/N)</th>
                  </tr>
                </thead>
                <tbody>{transformMap(transformedMappedData)}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const PdfTemplateWrapper = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return (
    <PdfTemplate
      ref={ref}
      selectedOrg={props.selectedOrg}
      orgPdfLogo={props.orgPdfLogo}
      student={props.student}
      catalog={props.catalog}
      evaluation={props.evaluation}
      mappedData={props.mappedData}
      transferredCourses={props.transferredCourses}
      translate={props.translate}
      teqStatusesEnum={props.teqStatusesEnum}
    />
  );
});
