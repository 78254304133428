import { Box, Button, Grid, Paper, Typography, Switch } from '@mui/material';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DeclareForm, useDeclareForm } from '../../../components/DeclareForm';
import DeclareFormControl from '../../../components/DeclareFormControl';
import { makeStyles } from '@mui/styles';
import { settings, settingsCategories } from '../../../helpers/settingsHelper';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSettings } from '../../../redux/actions/settingActions';
import { getSettings } from '../../../redux/actions/settingActions';

const useStyles = makeStyles((theme) => ({
  saveCancelBox: {
    float: 'right',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(20),
  },
  saveCancelBtn: {
    '&.MuiButton-root': {
      height: theme.spacing(5),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
      width: theme.spacing(18),
      marginLeft: theme.spacing(2),
    },
  },
}));
export default function ModuleAndFeaturesSettings({ moduleAndFeaturesSettings }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { values, setValues } = useDeclareForm({
    [settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE]: false,
  });

  useEffect(() => {
    dispatch(getSettings(orgId));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(moduleAndFeaturesSettings)) {
      Object.keys(moduleAndFeaturesSettings.settings).map((data) => {
        setValues({ [data]: moduleAndFeaturesSettings.settings[data] });
      });
    }
  }, [moduleAndFeaturesSettings]);

  const handleSwitch = (e) => {
    setValues({
      [settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE]: e,
    });
  };

  const saveSettings = () => {
    const data = {
      category: settingsCategories.MODULES_AND_FEATURES,
      settings: values,
    };
    dispatch(createSettings(orgId, data));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '20px' }}>
        <Grid container>
          <Grid item xs={12}>
            <DeclareFormControl
              control={
                <Switch
                  color="primary"
                  checked={
                    values[
                      settings[settingsCategories.MODULES_AND_FEATURES].TRANSFER_EVALUATION_MODULE
                    ]
                  }
                  onChange={(e) => {
                    handleSwitch(e.target.checked);
                  }}
                />
              }
              label={<Typography fontWeight={700}>{t('Transfer Evaluation Module')}</Typography>}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      <Box className={classes.saveCancelBox}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          className={classes.saveCancelBtn}
          onClick={() => {
            saveSettings();
          }}>
          {t('save')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
