import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, MenuItem, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';

import DeclareTextField from './DeclareTextField';
import RuleGroup from './RuleGroup';
import {
  appendRuleOrGroup,
  changeOperator,
  Operators,
  RuleCat,
  addRuleData,
  changeRuleType,
  removeRuleOrGroup,
} from '../helpers/ruleHelper';

import { moveTreeItem } from '../helpers/treeHelper';
import _ from 'lodash';
import { getStatus } from '../helpers/appConstants';
import DeclaredSelect from './DeclareSelect';
import { postRule, updateRule } from '../redux/actions/ruleActions';
import { useDispatch, useSelector } from 'react-redux';

const initialData = {
  operator: Operators[0],
  uuid: _.uniqueId(),
  data: [],
};

export default function RuleContainer(props) {
  const {
    ruledEntity,
    ruleData: passedRuleData,
    cancelAction,
    padding = '20px',
    hideTextBoxControls = false,
    autoSave = false,
    ruleName = '',
    selectedItemEditable = true,
    programMapId = null,
  } = props;
  const { orgId } = useParams();

  const formError = useSelector((state) => state.notifications.formError);

  const { t } = useTranslation();
  const [ruleContainerName, setRuleContainerName] = useState('');
  const [ruleStatus, setRuleStatus] = useState(getStatus()[3].value);
  const [ruleData, setRuleData] = useState(initialData);
  const [ruleDataId, setRuleDataId] = useState(null);

  const dispatch = useDispatch();

  const ruleUnsaved = !ruleDataId;

  const handleStatusChange = (e) => {
    setRuleStatus(e.target.value);
  };

  const addRuleDataHandler = (params) => {
    setRuleData(addRuleData(params, ruleData));
  };

  const changeRuleTypeHandler = (params) => {
    setRuleData(changeRuleType(params, ruleData));
  };

  const addRule = (params) => {
    setRuleData(appendRuleOrGroup(params, RuleCat.RULE, ruleData));
  };

  const addGroup = (params) => {
    setRuleData(appendRuleOrGroup(params, RuleCat.GROUP, ruleData));
  };

  const changeOperatorHandler = (selectedElementPath, newOperatorIndex) => {
    setRuleData(changeOperator(selectedElementPath, newOperatorIndex, ruleData));
  };

  const moveRule = (dragElementPath, dropElementPath, droppedPotion) => {
    setRuleData(moveTreeItem(ruleData, dragElementPath, dropElementPath, droppedPotion).mapData);
  };

  const removeRuleOrGroupHandler = (elementPath) => {
    setRuleData(removeRuleOrGroup(elementPath, ruleData));
  };

  const updateRuleDataId = (data) => {
    setRuleDataId(data.id);
  };

  const save = (silent = false, persistErrors = false) => {
    let data = {};
    data.name = ruleContainerName;
    data.ruledEntity = [ruledEntity];
    data.status = ruleStatus;
    data.json = ruleData;
    dispatch(postRule(orgId, data, silent, persistErrors, updateRuleDataId));
  };

  const update = (silent = false, persistErrors = false) => {
    const body = {
      name: ruleContainerName,
      ruledEntity: [ruledEntity],
      status: ruleStatus,
      json: ruleData,
    };
    dispatch(updateRule(orgId, ruleDataId, body, silent, persistErrors));
  };

  useEffect(() => {
    if (_.isEmpty(passedRuleData)) {
      setRuleContainerName(ruleName || '');
      setRuleStatus(getStatus()[3].value);
      setRuleData(initialData);
      setRuleDataId(null);
      return;
    }
    const { name, status, json } = passedRuleData;
    setRuleContainerName(name);
    setRuleStatus(status);
    setRuleData(json);
    setRuleDataId(passedRuleData.id);
  }, [passedRuleData]);

  useEffect(() => {
    const haveRulesChanged = !(_.isEmpty(ruleData.data) && ruleUnsaved);
    if (autoSave && haveRulesChanged) {
      ruleUnsaved ? save(true, true) : update(true, true);
    }
  }, [ruleData]);

  useEffect(() => {
    setRuleContainerName(ruleName);
  }, [ruleName]);

  let ruleDataEditable = true;
  if (!selectedItemEditable && !_.isNil(programMapId)) {
    ruleDataEditable = false;
  }
  return (
    <Box>
      <Paper sx={{ padding: padding }}>
        {!hideTextBoxControls && (
          <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
            <Grid item xs={6}>
              <DeclareTextField
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setRuleContainerName(e.target.value);
                }}
                inputlabel={t('name') + ' *'}
                variant="outlined"
                placeholder={t('Name of the rule')}
                value={ruleContainerName}
                name="name"
                error={formError['name']}
                helperText={formError['name']?.message}
                disabled={!ruleDataEditable}
              />
            </Grid>
            <Grid item xs={6}>
              <DeclaredSelect
                sx={{ width: '100%' }}
                inputlabel={t('status') + ' *'}
                margin="dense"
                value={ruleStatus}
                name="status"
                onChange={handleStatusChange}
                disabled={!ruleDataEditable}
                error={formError['status']}
                helperText={formError['status']?.message}>
                {getStatus().map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {t(item.label)}
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Grid>
          </Grid>
        )}
        <RuleGroup
          ruleData={ruleData}
          selectedElementPath={[]}
          addRule={addRule}
          addGroup={addGroup}
          moveRule={moveRule}
          changeOperatorHandler={changeOperatorHandler}
          changeRuleTypeHandler={changeRuleTypeHandler}
          addRuleDataHandler={addRuleDataHandler}
          selectedItemEditable={ruleDataEditable}
          removeRuleOrGroupHandler={removeRuleOrGroupHandler}></RuleGroup>
      </Paper>
      {!autoSave && (
        <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            disabled={!ruleDataEditable}
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              marginRight: '20px',
            }}
            onClick={() => {
              ruleUnsaved ? save() : update();
            }}>
            {ruleUnsaved ? t('save') : t('update')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            disabled={!ruleDataEditable}
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              color: '#fff',
            }}
            onClick={() => {
              cancelAction();
            }}>
            {t('cancel')}
          </Button>
        </Box>
      )}
    </Box>
  );
}
