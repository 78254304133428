import React from 'react';
import Tooltip from '@mui/material/Tooltip';
/**
 * Declare Tool tip
 * @param props Properties
 */

export default function DeclareToolTip(props) {
  props = Object.assign(props);

  const { children } = props;

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'common.white',
            width: 300,
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
            padding: '10px',
          },
        },
      }}
      {...props}
      placement="bottom-start">
      {children}
    </Tooltip>
  );
}
