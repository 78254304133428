import { HttpService } from './HttpService';

export default class DepartmentService extends HttpService {
  async getAll(id) {
    return this.get(`organizations/${id}/departments`);
  }

  async save(org, body) {
    return this.post(`organizations/${org}/departments`, body);
  }

  async update(org, college, body) {
    return this.put(`organizations/${org}/departments/${college}`, body);
  }
}
