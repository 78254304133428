import { ListItemText, MenuItem, MenuList } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function GroupContextMenu(props) {
  const {
    addNewGroup,
    selectedElementPath,
    handleMenuClose,
    setChildVisibility,
    handleModalOpen,
    childVisible,
    hasChild,
    removeTreeNode,
    showRemoveOnly = false,
  } = props;

  const { t } = useTranslation();

  return (
    <MenuList dense>
      {!showRemoveOnly && (
        <>
          <MenuItem
            onClick={() => {
              addNewGroup(selectedElementPath);
              handleMenuClose();
              setChildVisibility(true);
            }}>
            <ListItemText>{t('New Group')}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText
              onClick={() => {
                handleModalOpen(selectedElementPath);
                handleMenuClose();
                !(childVisible && hasChild) && setChildVisibility(true);
              }}>
              {t('Link Learning Activity')}
            </ListItemText>
          </MenuItem>
          {/* This feature was disabled under the story DE-1191, This will be enable in later */}
          {/* <MenuItem
            onClick={() => {
              LinkProgramMap(selectedElementPath);
              handleMenuClose();
              setChildVisibility(true);
            }}>
            <ListItemText>{t('Link Program Map')}</ListItemText>
          </MenuItem> */}
          <MenuItem>
            <ListItemText
              onClick={() => {
                removeTreeNode(selectedElementPath);
                handleMenuClose();
              }}>
              {t('Remove')}
            </ListItemText>
          </MenuItem>
        </>
      )}
      {showRemoveOnly && (
        <MenuItem>
          <ListItemText
            onClick={() => {
              removeTreeNode(selectedElementPath);
              handleMenuClose();
            }}>
            {t('Remove')}
          </ListItemText>
        </MenuItem>
      )}
      {/* Should uncomment these when we implement these  */}
      {/* <MenuItem>
        <ListItemText>{t('Link Program Mapping')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Duplicate')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Validate')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Copy')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Cut')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Paste')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Undo')}</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemText>{t('Redo')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemText>{t('Move to trash')}</ListItemText>
      </MenuItem> */}
    </MenuList>
  );
}
