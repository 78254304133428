import {
  getLearningActivities,
  setLearningActivities,
  setLearningActivitiesTransferEquivalencies,
  setLearningActivitiesForCatalog,
  setLearningActivity,
  setTerms,
  setClassSchedules,
} from '../../actions/learningActivityActions';
import { requestHandler, requestTypes } from '../helper';
import {
  requestPostLearningActivity,
  requestPutLearningActivity,
  requestGetLearningActivities,
  requestGetLearningActivity,
  requestGetLearningActivityTerms,
  requestGetLearningActivitiesForCatalog,
  requestGetClassSchedules,
  requestPostClassSchedule,
  requestPutClassSchedule,
  requestPatchLearningActivity,
} from '../requests/learningActivity';

let ongoingLearningActivityApiCalls = {};

export function* handleGetLearningActivities(action) {
  const { payload } = action;
  const cb = (data) => {
    return setLearningActivities(
      data.map((la) => ({ ...la, displayName: `${la.code} ${la.name}` })),
    );
  };
  const requestKey = `GET:organizations/${payload}/learningActivities`;
  if (ongoingLearningActivityApiCalls[requestKey]) {
    return;
  }

  try {
    ongoingLearningActivityApiCalls[requestKey] = true;
    yield requestHandler(requestGetLearningActivities.bind(this, payload), cb, requestTypes.GET);
  } finally {
    delete ongoingLearningActivityApiCalls[requestKey];
  }
}

export function* handleGetLearningActivitiesTransferEquivalencies(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetLearningActivities.bind(this, payload),
    setLearningActivitiesTransferEquivalencies,
    requestTypes.GET,
  );
}

export function* handleGetLearningActivitiesForCatalog(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetLearningActivitiesForCatalog.bind(this, payload),
    setLearningActivitiesForCatalog,
    requestTypes.GET,
  );
}

export function* handleGetLearningActivity(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestGetLearningActivity.bind(this, payload),
    setLearningActivity,
    requestTypes.GET,
    navigate,
    payload.data,
  );
}

export function* handleGetLearningActivityTerms(action) {
  const { payload } = action;
  yield requestHandler(
    requestGetLearningActivityTerms.bind(this, payload),
    setTerms,
    requestTypes.GET,
  );
}

export function* handlePostLearningActivity(action) {
  const { payload, navigate, shouldLoadList } = action;
  yield requestHandler(
    requestPostLearningActivity.bind(this, payload),
    shouldLoadList ? getLearningActivities.bind(this, payload.org) : null,
    requestTypes.POST,
    navigate,
  );
}

export function* handlePatchLearningActivity(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPatchLearningActivity.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}

export function* handlePutLearningActivity(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutLearningActivity.bind(this, payload),
    getLearningActivities.bind(this, payload.org),
    requestTypes.PUT,
    navigate,
    null,
    true,
  );
}

export function* handleGetClassSchedules(action) {
  const {
    payload: { orgId, lActivityId },
  } = action;
  yield requestHandler(
    requestGetClassSchedules.bind(this, orgId, lActivityId),
    setClassSchedules,
    requestTypes.GET,
  );
}

export function* handlePostClassSchedules(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPostClassSchedule.bind(this, payload),
    null,
    requestTypes.POST,
    navigate,
  );
}

export function* handlePutClassSchedules(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutClassSchedule.bind(this, payload),
    null,
    requestTypes.PUT,
    navigate,
  );
}
