import { getOrgs, setOrgs, setOrgsWithSelectedOrg } from '../../actions/orgActions';
import { requesetPostOrg, requesetPutOrg, requestGetOrgs } from '../requests/orgs';
import { requestHandler, requestTypes } from '../helper';
import { setError } from '../../actions/notificationActions';
import { requestPostCollegeDepartment } from '../requests/department';
import { requesetPostCatalog } from '../requests/catalogs';
import { requesetPostTerm } from '../requests/terms';
import { departments, getStatus } from '../../../helpers/appConstants';

export function* handleGetOrgs() {
  yield requestHandler(requestGetOrgs, setOrgs, requestTypes.GET);
}

export function* handleGetOrgsWithSelectedOrg(action) {
  const { payload, navigate } = action;
  const callback = (orgs) => {
    const selectedOrg = orgs.find((org) => org.id === payload);
    if (!selectedOrg) {
      navigate();
      return setError({ message: 'Invalid URL' });
    }
    return setOrgsWithSelectedOrg(orgs, selectedOrg);
  };
  yield requestHandler(requestGetOrgs, callback, requestTypes.GET);
}

export function* handlePostOrg(action) {
  const { payload, navigate, createDefaultResources } = action;
  if (createDefaultResources) {
    const onResponse = (org) => {
      const { id } = org;
      const year = new Date().getFullYear();

      const departmentData = {
        org: id,
        data: {
          name: 'The Department',
          status: getStatus()[3].value,
          type: departments[0].value,
        },
      };
      requestPostCollegeDepartment(departmentData);

      const catalogData = {
        org: id,
        data: {
          name: `Catalog ${year}`,
          year: year,
          status: getStatus()[3].value,
        },
      };
      requesetPostCatalog(catalogData);

      const termsData = {
        org: id,
        data: {
          name: `Fall ${year}`,
          status: getStatus()[3].value,
        },
      };
      requesetPostTerm(termsData);

      navigate();
    };
    yield requestHandler(
      requesetPostOrg.bind(this, payload),
      getOrgs,
      requestTypes.POST,
      onResponse,
    );
  } else {
    yield requestHandler(requesetPostOrg.bind(this, payload), getOrgs, requestTypes.POST, navigate);
  }
}

export function* handlePutOrg(action) {
  const { payload, navigate } = action;
  yield requestHandler(requesetPutOrg.bind(this, payload), getOrgs, requestTypes.PUT, navigate);
}
