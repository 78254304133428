import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { School, CardMembership, RemoveCircleOutline, AddCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DeclareAutocomplete from '../../../components/DeclareAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  getPathwaysToExplore,
  getTermPlanningStatus,
  savePathway,
  savePathwayCourseUsage,
  getPathway,
} from '../../../redux/actions/pathwayActions';
import { getPrograms } from '../../../redux/actions/programActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { getCompletedCourseReq } from '../../../redux/actions/transferEvaluationActions';
import { setError } from '../../../redux/actions/notificationActions';
import QuestionDialog from './Components/QuestionDialog';
import {
  pathwayQuestionTypes,
  termPlanningStatusTypes,
  studentPathwayStatusTypes,
  getStatus,
} from '../../../helpers/appConstants';
import { subtractArrays } from '../../../helpers/common';
import DeclaredSelect from '../../../components/DeclareSelect';
import {
  generateCourseUsageData,
  generatePathwayData,
  getTermsToSelect,
  getNextRegularTerm,
  createRenderValueMessage,
  checkTheTermHasCompletedStatus,
  doesOrderedPathwayOverlapsWithCompletedTerms,
  getFormattedOrderedPathwayData,
  getFormattedStudentCompletedTermsFromOrderedPathway,
  markRetakenCoursesInPathway,
  preprocessPathwayData,
  getTCECourseReqIds,
} from '../../../helpers/pathwayHelper';
import DeclareToolTip from '../../../components/DeclareToolTip';
import { getFinancialAidSetting } from '../../../helpers/settingsHelper';
import { updateCurrentStudent, getCurrentCatalog } from '../../../helpers/studentsHelper';
import { useDeclareForm } from '../../../components/DeclareForm';
import { getCatalogs } from '../../../redux/actions/catalogActions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
      maxWidth: theme.spacing(30),
    },
  },
  searchBtn: {
    '&.MuiButton-root': {
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      width: theme.spacing(18),
      fontWeight: 300,
    },
  },
  pathwayContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '2',
    borderRadius: 5,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
  pathwayContent: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  pathway: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:not(:last-of-type)': {
      borderBottom: '1px solid #ccc',
    },
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  filterContainer: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: '1.2',
    borderRadius: 5,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    '& .MuiSlider-valueLabelOpen': {
      top: '100%',
      background: 'none',
      lineHeight: '1',
      fontSize: ' 0.8rem',
    },
  },
  mainProgram: {
    marginBottom: theme.spacing(1),
  },
  laSelect: {
    '& .MuiChip-label, & .MuiButtonBase-root .MuiSvgIcon-root': {
      color: theme.palette.white.main,
    },
    '& .MuiAutocomplete-clearIndicator': {
      backgroundColor: theme.palette.grey.main,
      marginRight: theme.spacing(0.5),
    },
    '& .MuiAutocomplete-popupIndicator': {
      backgroundColor: theme.palette.grey.main,
    },
  },
  studentAttendingTerms: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiFormControl-root': {
      width: theme.spacing(35),
    },
  },
  pathwayBtn: { '&.MuiButton-root': { borderRadius: theme.spacing(3), width: theme.spacing(20) } },
}));

export default function ExploreView({ selectedExcludeCourses }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orgId } = useParams();
  const [includePrograms, setIncludePrograms] = useState([]);
  const [includeCourses, setIncludeCourses] = useState([]);
  const [excludeCourses, setExcludeCourses] = useState([]);
  const [exploredPathways, setExploredPathways] = useState([]);
  const [showOutcomesOnlyCheck, setShowOutcomesOnlyCheck] = useState(true);
  const formError = useSelector((state) => state.notifications.formError);
  const { completedCoursesRequirements: courseReq } = useSelector(
    (state) => state.transferEvaluation,
  );
  const { programMapList } = useSelector((state) => state.programMaps);
  const {
    currentSelectedStudent,
    studentOrderedPathway,
    studentCompletedTerms,
    list: studentList,
  } = useSelector((state) => state.students);
  const { programOption, requiresFinancialAid } = currentSelectedStudent;
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { termPlanningStatus, pathwayStatus } = useSelector((state) => state.pathway);
  const { termList } = useSelector((state) => state.terms);
  const { programList } = useSelector((state) => state.programs);
  const { processed } = useSelector((state) => state.settings);
  const [filteredLAByCatalog, setFilteredLAByCatalog] = useState([]);
  const [cachedPathway, setCachedPathway] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [activeQuestionAnswers, setActiveQuestionAnswers] = useState({});
  const [termListDropDown] = useState(getTermsToSelect(termList));
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [orderedPathwayTerms, setOrderedPathwayTerms] = useState([]);
  const [renderValueMessage, setRenderValueMessage] = useState(null);
  const [completedTerms, setCompletedTerms] = useState([]);
  const [studentPathwayIsInvalid, setStudentPathwayIsInvalid] = useState(false);
  const financialAidSetting = getFinancialAidSetting(processed);
  const { catalogs, latestCatalog } = useSelector((state) => state.allCatalogs);
  const { values, setValues, handleInputChange } = useDeclareForm({ catalogVersions: [] });
  const { cachedUsedRequirementTransfers, setCachedUsedRequirementTransfers } = useState([]);

  useEffect(() => {
    if (_.isEmpty(termList)) {
      dispatch(getTerms(orgId));
    }

    if (_.isEmpty(programList)) {
      dispatch(getPrograms(orgId));
    }

    if (_.isEmpty(programMapList)) {
      dispatch(getProgramMaps(orgId));
    }
    if (catalogs.length === 0) {
      dispatch(getCatalogs(orgId));
    }
    if (_.isEmpty(studentOrderedPathway)) {
      const { id: studentId, programOption } = currentSelectedStudent;
      dispatch(getPathway(orgId, studentId, programOption.id));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(catalogs)) {
      let catalog = catalogs.filter(({ status }) => status === getStatus()[0].value)[0];
      if (!_.isNull(currentSelectedStudent.programMapId)) {
        const programMap = programMapList.find(
          ({ id }) => id === currentSelectedStudent.programMapId,
        );
        catalog = catalogs.find((catalog) => catalog.id === programMap.catalogs[0].id);
      }
      setValues({
        catalogVersions: catalog,
      });
    }
  }, [catalogs]);

  useEffect(() => {
    const output = learningActivities.filter(({ catalog }) =>
      catalog.some(({ id }) => id === values.catalogVersions.id),
    );

    setIncludeCourses([]);
    setExcludeCourses([]);
    setFilteredLAByCatalog(output);
  }, [values.catalogVersions]);

  useEffect(() => {
    if (!_.isNil(selectedExcludeCourses) && !_.isEmpty(filteredLAByCatalog)) {
      const selectedLA = filteredLAByCatalog.find(
        ({ id }) => selectedExcludeCourses.id === id,
      ).displayName;
      setExcludeCourses([selectedLA]);
    }
  }, [selectedExcludeCourses, filteredLAByCatalog]);

  const setValuesToDropDown = (termsArray) => {
    const terms = termListDropDown.filter((term) => {
      return termsArray.find((res) => res.id === term.id && res.year === term.year);
    });
    setSelectedTerms(terms);
    const messageRenderValue = createRenderValueMessage(termList, terms, t);
    setRenderValueMessage(messageRenderValue);
  };

  useEffect(() => {
    const completedTermsFromOrderedPathway = getFormattedStudentCompletedTermsFromOrderedPathway(
      studentCompletedTerms,
      termList,
      filteredLAByCatalog,
    );
    if (!_.isEmpty(studentOrderedPathway)) {
      const pathwayData = getFormattedOrderedPathwayData(
        studentOrderedPathway,
        termList,
        filteredLAByCatalog,
      );
      let pathwayDataWithCompletedTerms = [];

      const orderedPathwayTermsHasCompletedTerms = doesOrderedPathwayOverlapsWithCompletedTerms(
        pathwayData,
        completedTermsFromOrderedPathway,
      );

      if (!orderedPathwayTermsHasCompletedTerms) {
        pathwayDataWithCompletedTerms = pathwayData.concat(completedTermsFromOrderedPathway);
      } else {
        pathwayDataWithCompletedTerms = completedTermsFromOrderedPathway;
      }
      setStudentPathwayIsInvalid(orderedPathwayTermsHasCompletedTerms);

      const termsArray = pathwayDataWithCompletedTerms
        .filter((theData) => theData.termPlanningStatus !== termPlanningStatusTypes.SIT_OUT)
        .map((aData) => {
          return aData.term;
        });
      const completedTermsFromPathway = pathwayDataWithCompletedTerms.filter(
        (termData) => termData.termPlanningStatus === termPlanningStatusTypes.COMPLETED,
      );

      setCompletedTerms(completedTermsFromPathway);
      if (!orderedPathwayTermsHasCompletedTerms) {
        setOrderedPathwayTerms(termsArray);
      }
      setValuesToDropDown(termsArray);
    } else {
      setCompletedTerms(completedTermsFromOrderedPathway);
      setValuesToDropDown(completedTermsFromOrderedPathway.map(({ term }) => term));
    }
  }, [studentOrderedPathway, termList, studentCompletedTerms]);

  useEffect(() => {
    if (_.isEmpty(courseReq)) {
      dispatch(getCompletedCourseReq(orgId, currentSelectedStudent.id));
    }

    if (_.isEmpty(termPlanningStatus)) {
      dispatch(getTermPlanningStatus(orgId));
    }
  }, [includePrograms]);

  const handleChangeCheckBox = (item) => {
    const term = selectedTerms.find((aTerm) => aTerm.id === item.id && aTerm.year === item.year);
    if (_.isNil(term)) {
      setValuesToDropDown([...selectedTerms, item]);
    } else {
      const index = selectedTerms.findIndex((trm) => trm.id === item.id && trm.year === item.year);
      selectedTerms.splice(index, 1);
      setValuesToDropDown(selectedTerms);
    }
  };

  const isCompletedTerm = (aTerm) =>
    completedTerms.some(({ term: { id, year } }) => id === aTerm.id && year === aTerm.year);

  const handleAddRemoveTermByClickPlusOrMinus = (addTerm) => {
    if (!addTerm) {
      if (_.isEmpty(selectedTerms) || isCompletedTerm(selectedTerms.at(-1))) {
        showStudentPathwayTermError();
      } else {
        const updatedTermsFromDropDown = selectedTerms.slice(0, -1);
        setValuesToDropDown(updatedTermsFromDropDown);
      }
    } else {
      if (_.size(selectedTerms) <= 0) {
        if (!_.isEmpty(studentOrderedPathway)) {
          const defaultTerm = orderedPathwayTerms[0];
          const newArray = [...selectedTerms, defaultTerm];
          setValuesToDropDown(newArray);
        } else {
          const defaultTerm = termListDropDown[0];
          const newArray = [...selectedTerms, defaultTerm];
          setValuesToDropDown(newArray);
        }
      } else {
        const lastTerm = selectedTerms[selectedTerms.length - 1];
        const nextRegularTerm = getNextRegularTerm(termListDropDown, lastTerm);
        if (!_.isNil(nextRegularTerm)) {
          const newArray = [...selectedTerms, nextRegularTerm];
          setValuesToDropDown(newArray);
        } else {
          dispatch(
            setError({
              message: t('Term unavailable, the pathway is too long'),
            }),
          );
        }
      }
    }
  };

  const showStudentPathwayTermError = () => {
    dispatch(
      setError({
        message: t('The student’s pathway must contain terms.'),
      }),
    );
  };

  const showNoPathwayAvailableError = () => {
    dispatch(
      setError({
        message: t('No pathways available based on the current filters.'),
      }),
    );
  };

  const searchPathways = (searchClicked = false, terms = [], completedTermsFromPathway = []) => {
    let firstNonCompletedTerm = null;
    let programMapId = null;
    if (!_.isEmpty(studentOrderedPathway)) {
      const { termId, year } = studentOrderedPathway.terms.find(
        ({ statusId }) => statusId.type !== termPlanningStatusTypes.COMPLETED,
      );
      firstNonCompletedTerm = { termId, year };
    } else {
      const { id } = termList.find((values) => values.currentTerm);
      firstNonCompletedTerm = { termId: id, year: new Date().getFullYear() };
    }

    let termsSelected = selectedTerms;

    /* 
    The completed terms has two sources, when user select the term as completed it return in the orderedPathway.
    From the second time and after the completed terms return in separate array from the api. In that case this reformatting 
    needs to be done, to assign completed terms to the selected terms.  
    */
    const completedTermsAfterReFormat = completedTermsFromPathway.map((aTerm) => {
      const formatData = {
        id: aTerm.term.termId,
        items: aTerm.items,
        termId: aTerm.term.id,
        year: aTerm.term.calendarYear,
        statusId: { type: aTerm.termPlanningStatus },
      };
      return formatData;
    });

    const updatedPathwayWithTerms = completedTermsAfterReFormat.concat(
      studentOrderedPathway?.terms || [],
    );

    const finalTerms = termsSelected.map((term) => {
      const theTerm = updatedPathwayWithTerms.find(
        (aTerm) =>
          aTerm.termId === term.id &&
          aTerm.year === term.year &&
          aTerm.statusId.type === termPlanningStatusTypes.COMPLETED,
      );
      if (!_.isNil(theTerm)) {
        return { ...term, termPlanningStatus: theTerm.statusId.type };
      } else {
        return { ...term };
      }
    });

    const programMap = programMapList.find(
      ({ optionId, catalogs }) =>
        optionId === programOption.id && catalogs[0].id === values.catalogVersions.id,
    );
    if (!_.isNil(programMap)) {
      programMapId = programMap.id;
      const programMapIds = [programMapId];
      const request = {
        programMapIds,
        noOfTerms: selectedTerms.length,
        completedCourses: getTCECourseReqIds(
          courseReq,
          studentCompletedTerms.concat(updatedPathwayWithTerms),
          false,
        ),
        questionResponses: answeredQuestions,
        firstNonCompletedTerm,
        includeCourses: includeCourses.map(
          (dpName) => filteredLAByCatalog.find(({ displayName }) => dpName === displayName).id,
        ),
        excludeCourses: excludeCourses.map(
          (dpName) => filteredLAByCatalog.find(({ displayName }) => dpName === displayName).id,
        ),
        selectedTerms: finalTerms,
        requiresFinancialAid,
        minNumOfUnitsPerTerm:
          requiresFinancialAid && !_.isUndefined(financialAidSetting) ? financialAidSetting : null,
      };

      if (searchClicked) {
        if (
          _.isEmpty(
            finalTerms.filter(
              ({ termPlanningStatus }) => termPlanningStatus !== termPlanningStatusTypes.COMPLETED,
            ),
          )
        ) {
          showStudentPathwayTermError();
        } else {
          const callback = (data) => {
            setExploredPathways(data);
            if (_.isEmpty(data)) {
              showNoPathwayAvailableError();
            }
          };
          dispatch(getPathwaysToExplore(orgId, request, callback));
        }
      } else {
        const callback = (data) => {
          setExploredPathways(data);
          if (_.isEmpty(data)) {
            showNoPathwayAvailableError();
          }
        };
        dispatch(getPathwaysToExplore(orgId, request, callback));
      }
    } else {
      setExploredPathways([]);
      dispatch(
        setError({
          message: t('Student’s preferred program is not available in the selected catalog.'),
        }),
      );
    }
  };

  const followPathway = (orderedPathway, usedRequirementTransfers) => {
    const studentId = currentSelectedStudent.id;
    const preprocessedPathwayData = preprocessPathwayData(orderedPathway);
    const programMap = programMapList.find(({ optionId, catalogs }) => {
      return (
        optionId === currentSelectedStudent.programOption.id &&
        catalogs.find(({ id }) => id === values.catalogVersions.id)
      );
    });
    const pathWayData = generatePathwayData(
      usedRequirementTransfers,
      markRetakenCoursesInPathway(
        preprocessedPathwayData,
        studentOrderedPathway,
        studentCompletedTerms,
      ),
      termPlanningStatus,
      courseReq,
      currentSelectedStudent,
      programMap.id,
    );

    dispatch(
      savePathway(orgId, studentId, pathWayData, ({ pathway }) => {
        const generatedCompletedCourses = generateCourseUsageData(
          courseReq,
          pathway.id,
          getTCECourseReqIds(courseReq, studentCompletedTerms.concat(orderedPathway)),
        );
        const { programMapId } = pathWayData;
        const status = pathwayStatus.find(
          (data) => data.type === studentPathwayStatusTypes.IN_PLANNING,
        );
        const currentStudent = {
          ...currentSelectedStudent,
          currentCatalog: getCurrentCatalog(
            programMapList,
            latestCatalog,
            programMap.id,
            catalogs,
            currentSelectedStudent.catalogId,
          ),
        };
        updateCurrentStudent(currentStudent, studentList, status, dispatch, programMapId);
        dispatch(
          savePathwayCourseUsage(orgId, studentId, pathway.id, {
            completedCourses: generatedCompletedCourses,
          }),
        );
      }),
    );
  };

  useEffect(() => {
    const selectedOption = programMapList.find(({ optionId }) => programOption.id == optionId);
    setIncludePrograms([`${selectedOption.programName}, ${selectedOption.optionName}`]);
  }, [programMapList, programOption]);

  const findFreeElective = (courseId, pathwayCoursesObjs) => {
    const { name } = pathwayCoursesObjs.find(({ id }) => id === courseId);
    return { code: `${t('Assign')} "${name}"`, name };
  };

  const generateCourseChips = (courses, pathwayCoursesObjs) =>
    courses
      .map(
        (courseId) =>
          filteredLAByCatalog.find(({ id }) => id === courseId) ||
          findFreeElective(courseId, pathwayCoursesObjs),
      )
      .map(({ code, name }, key) => (
        <Tooltip key={key} title={name} arrow>
          <Chip color="primary" label={code} className={classes.laSelect}></Chip>
        </Tooltip>
      ));

  const getDiffFromStudentsPlan = (pathway) => {
    let completedCourses = [];
    const pathwayCoursesObjs = pathway.terms.map(({ items }) => items).flat();
    const pathwayCourses = pathwayCoursesObjs.map(({ id }) => id);
    if (_.isEmpty(studentOrderedPathway) || studentPathwayIsInvalid) return <></>;
    const currentCoursesObjs = studentOrderedPathway.terms
      .filter(({ statusId }) => statusId.type !== termPlanningStatusTypes.COMPLETED)
      .map(({ items }) =>
        items.map((data) => ({
          ...data,
          id: data.courseReqId,
          name: data.additionalInfo?.groupName,
        })),
      )
      .flat();
    const currentCourses = currentCoursesObjs.map(({ id }) => id);

    if (!_.isEmpty(courseReq)) {
      completedCourses = Object.values(courseReq[0].assignments)
        .map((courseList) => {
          return courseList.map((course) => course.courseReqId);
        })
        .flat(2);
    }

    const added = subtractArrays(subtractArrays(pathwayCourses, currentCourses), completedCourses);
    const removed = subtractArrays(
      subtractArrays(currentCourses, pathwayCourses),
      completedCourses,
    );
    if (_.isEmpty(added) && _.isEmpty(removed)) {
      return null;
    }
    return (
      <>
        {!_.isEmpty(added) && (
          <>
            <Typography fontWeight={700}>{t(`Added to the student's plan`)}</Typography>
            <Box display="flex" flexWrap="wrap" sx={{ '& > :not(style)': { m: 0.5 } }}>
              {generateCourseChips(added, pathwayCoursesObjs)}
            </Box>
          </>
        )}
        {!_.isEmpty(removed) && (
          <>
            <Typography fontWeight={700}>{t(`Removed from the student's plan`)}</Typography>
            <Box display="flex" flexWrap="wrap" sx={{ '& > :not(style)': { m: 0.5 } }}>
              {generateCourseChips(removed, currentCoursesObjs)}
            </Box>
          </>
        )}
      </>
    );
  };

  const handleQuestionDialogClose = () => {
    setQuestionDialogOpen(false);
  };

  const handleQuestionDialogCancel = () => {
    setQuestionDialogOpen(false);
    setAnsweredQuestions({});
    setActiveQuestionAnswers({});
  };

  const handleQuestionSubmit = () => {
    setQuestionDialogOpen(false);

    const isEveryAnswerPositive = Object.entries(activeQuestionAnswers).every((item) => {
      return item[1] == true;
    });

    if (!isEveryAnswerPositive) {
      dispatch(
        setError({
          persistErrors: true,
          message: 'Based on your answer, this pathway is not valid for the student.',
        }),
      );
      searchPathways(false, [], completedTerms);
    } else {
      followPathway(cachedPathway, cachedUsedRequirementTransfers);
    }
    setActiveQuestionAnswers({});
  };

  const generatePathways = () => {
    return exploredPathways.map((pathway, key) => {
      // TODO generated pathways should return corresponding progaramMapId, and it should be used to display major and minor names
      const selectedOption = programMapList.find(({ optionId }) => programOption.id == optionId);
      const programOptionName = `${selectedOption.programName}, ${selectedOption.optionName}`;
      const minorOptionNames = [];
      const pathwayDifference = getDiffFromStudentsPlan(pathway);
      const alreadyFollowing = pathwayDifference === null;

      return (
        <Box key={key} className={classes.pathway}>
          <Stack>
            <Stack direction="row" gap={2} className={classes.mainProgram} alignItems="center">
              <School color="secondary" />
              <Typography color="secondary" fontWeight={700} variant="h6">
                {programOptionName}
              </Typography>
            </Stack>
            {minorOptionNames.map((name, key) => {
              return (
                <Stack key={key} direction="row" gap={2} alignItems="center" wrap="wrap">
                  <CardMembership color="secondary" />
                  <Typography fontWeight={700}>{name} sfs</Typography>
                </Stack>
              );
            })}
            {!showOutcomesOnlyCheck && pathwayDifference}
          </Stack>
          <Button
            onClick={() => {
              const { terms, questionWarnings, usedRequirementTransfers } = pathway;
              const pathwayQuestions = questionWarnings.filter(
                (pathwayQues) => pathwayQues.type === pathwayQuestionTypes.QUESTION,
              );
              if (!alreadyFollowing) {
                if (!_.isEmpty(pathwayQuestions)) {
                  setQuestions(pathwayQuestions);
                  setCachedPathway(terms);
                  setCachedUsedRequirementTransfers(usedRequirementTransfers);
                  setQuestionDialogOpen(true);
                } else {
                  followPathway(terms, usedRequirementTransfers);
                }
              }
            }}
            variant="contained"
            color={alreadyFollowing ? 'primary' : 'secondary'}
            className={classes.pathwayBtn}>
            <Typography color="common.white" variant="p1" sx={{ textTransform: 'none' }}>
              {alreadyFollowing ? t('Following') : t('Follow')}
            </Typography>
          </Button>
        </Box>
      );
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.pathwayContainer}>
        <Typography fontWeight={700} color="primary" variant="h4">
          {t('Pathways')}
        </Typography>
        {exploredPathways.length === 0 ? (
          <Box marginTop="15px">
            <InputLabel>{t('Apply filters and search to see alternative pathways.')}</InputLabel>
          </Box>
        ) : null}
        <Box className={classes.pathwayContent}>{generatePathways()}</Box>
      </Box>
      <Box className={classes.filterContainer}>
        <Typography fontWeight={700} color="primary" variant="h4">
          {t('Filters')}
        </Typography>
        <FormControlLabel
          sx={{ display: 'flex', alignSelf: 'flex-start' }}
          value="start"
          control={
            <Switch
              color="primary"
              checked={showOutcomesOnlyCheck}
              onChange={(e) => {
                setShowOutcomesOnlyCheck(e.target.checked);
              }}
            />
          }
          label={<Typography fontWeight={700}>{t('Show Outcomes Only')}</Typography>}
          labelPlacement="start"
        />

        <Box sx={{ display: 'none' }}>
          <DeclareAutocomplete
            className={classes.laSelect}
            disabled
            inputlabel={
              <Typography fontWeight={700} color="common.black">
                {t('Include Programs')}
              </Typography>
            }
            multiple
            limitTags={3}
            value={includePrograms}
            onChange={(_e, value) => {
              setIncludePrograms(value);
            }}
            options={programMapList.map(
              ({ optionName, programName }) => `${programName}, ${optionName}`,
            )}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip color="primary" key={index} label={option} {...getTagProps({ index })} />
              ));
            }}
            renderInput={(params) => <TextField {...params} name="includePrograms" />}
          />

          <DeclareAutocomplete
            className={classes.laSelect}
            disabled
            inputlabel={
              <Typography fontWeight={700} color="common.black">
                {t('Exclude Programs')}
              </Typography>
            }
            multiple
            limitTags={3}
            freeSolo
            options={[].map(({ code }) => code)}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip color="primary" key={index} label={option} {...getTagProps({ index })} />
              ));
            }}
            renderInput={(params) => <TextField {...params} name="courseCodes" />}
          />
        </Box>

        <DeclareAutocomplete
          className={classes.laSelect}
          inputlabel={
            <Typography fontWeight={700} color="common.black">
              {t('Include Courses')}
            </Typography>
          }
          multiple
          limitTags={3}
          value={includeCourses}
          onChange={(_e, value) => {
            setIncludeCourses(value);
          }}
          options={filteredLAByCatalog.map(({ displayName }) => displayName)}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip color="primary" key={index} label={option} {...getTagProps({ index })} />
            ));
          }}
          renderInput={(params) => <TextField {...params} name="courseCodes" />}
        />

        <DeclareAutocomplete
          className={classes.laSelect}
          inputlabel={
            <Typography fontWeight={700} color="common.black">
              {t('Exclude Courses')}
            </Typography>
          }
          multiple
          limitTags={3}
          value={excludeCourses}
          onChange={(_e, value) => {
            setExcludeCourses(value);
          }}
          options={filteredLAByCatalog.map(({ displayName }) => displayName)}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip color="primary" key={index} label={option} {...getTagProps({ index })} />
            ));
          }}
          renderInput={(params) => <TextField {...params} name="courseCodes" />}
        />
        <Typography fontWeight={700} color="common.black">
          {t('How many more terms?')}
        </Typography>
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <DeclareToolTip
            title={
              <Typography color="common.black" fontSize="small" fontWeight={400} marginLeft={3}>
                {t('Remove last term')}
              </Typography>
            }>
            <RemoveCircleOutline
              onClick={() => handleAddRemoveTermByClickPlusOrMinus(false)}
              color="primary"
            />
          </DeclareToolTip>
          <Box className={classes.studentAttendingTerms}>
            <DeclaredSelect
              multiple
              margin="dense"
              value={selectedTerms}
              name="selectedTerms"
              helperText={formError['selectedTerms']?.message}
              renderValue={(selected) =>
                selected
                  .filter((trm) => trm.currentTerm && trm.year === new Date().getFullYear())
                  .map((term) => term.name + ' ' + term.year)
                  .join(', ') + renderValueMessage
              }>
              {termListDropDown.map((item, index) => (
                <MenuItem value={item} key={index}>
                  <Checkbox
                    checked={
                      selectedTerms.findIndex(
                        (trm) => trm.id === item.id && trm.year === item.year,
                      ) >= 0
                    }
                    disabled={checkTheTermHasCompletedStatus(item, completedTerms)}
                    onClick={() => handleChangeCheckBox(item)}
                  />
                  <ListItemText primary={item.name + ' ' + item.year} />
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Box>
          <DeclareToolTip
            title={
              <Typography color="common.black" fontSize="small" fontWeight={400} marginLeft={3}>
                {t('Add next regular term')}
              </Typography>
            }>
            <AddCircleOutline
              onClick={() => handleAddRemoveTermByClickPlusOrMinus(true)}
              color="primary"
            />
          </DeclareToolTip>
        </Stack>
        <DeclaredSelect
          inputlabel={t('Catalog') + ' *'}
          margin="dense"
          value={values.catalogVersions}
          name="catalogVersions"
          onChange={handleInputChange}
          error={formError['type']}
          helperText={formError['type']?.message}>
          {catalogs
            .filter(({ status }) => status === getStatus()[0].value)
            .map((item, index) => (
              <MenuItem value={item} key={index}>
                <Typography variant="p" className="ElipsisText">
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
        </DeclaredSelect>
        <Button
          className={classes.searchBtn}
          disableElevation
          variant="contained"
          color="secondary"
          onClick={() => searchPathways(true, [], completedTerms)}>
          <Typography>{t('search')}</Typography>
        </Button>
      </Box>
      <QuestionDialog
        open={questionDialogOpen}
        questions={questions}
        answeredQuestions={answeredQuestions}
        setAnsweredQuestions={setAnsweredQuestions}
        activeQuestionAnswers={activeQuestionAnswers}
        setActiveQuestionAnswers={setActiveQuestionAnswers}
        handleClose={handleQuestionDialogClose}
        handleCancel={handleQuestionDialogCancel}
        handleSubmit={handleQuestionSubmit}
      />
    </Box>
  );
}
