import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../../../redux/actions/learningActivityActions';
import { getProgramMaps } from '../../../redux/actions/programMapActions';
import { getStudentPathway } from '../../../redux/actions/studentActions';
import {
  buildPathwayData,
  getReOrderedTermsByCalenderYear,
  validateNextTerm,
} from '../../../helpers/pathwayHelper';
import TermCardYear from './TermCardYear';
import TermCardCustomizedTerms from './TermCardCustomizedTerms';
import _ from 'lodash';
import { setError } from '../../../redux/actions/notificationActions';
import { getTermsOrder } from '../../../redux/actions/termActions';
import { useTranslation } from 'react-i18next';
import { getFinancialAidSetting } from '../../../helpers/settingsHelper';
import { useDeclareForm } from '../../DeclareForm';
import { getCatalogs } from '../../../redux/actions/catalogActions';

export default function TermCardBuildPathway({
  pathwayCallback,
  setPathFormValues,
  completedCourses = [],
  completedTerms,
}) {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const termList = useSelector((state) => state.terms.termList).filter((term) => term.regularTerm);
  const termInstances = useSelector((state) => state.terms.termInstances);
  const allTerms = useSelector((state) => state.terms.termList);
  const catalogs = useSelector((state) => state.allCatalogs.catalogs);
  const { currentSelectedStudent } = useSelector((state) => state.students);
  const { processed } = useSelector((state) => state.settings);
  const { programMapList } = useSelector((state) => state.programMaps);
  const [buildPathwayParams, setBuildPathwayParams] = useState({
    graduatingTerm: null,
    graduatingYear: null,
    nextTerm: null,
    nextYear: null,
    selectedTerms: [],
  });
  const { values, setValues, handleInputChange } = useDeclareForm(buildPathwayParams);
  const [showCustomizeTerms, setShowCustomizeTerms] = useState(false);
  const financialAidSetting = getFinancialAidSetting(processed);

  useEffect(() => {
    if (termList.length < 1) {
      dispatch(getTerms(orgId));
    }

    if (_.isEmpty(programMapList)) {
      dispatch(getProgramMaps(orgId));
    }
    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentSelectedStudent)) {
      presetFirstTerms();
    }
  }, [currentSelectedStudent]);

  const presetFirstTerms = () => {
    let firstTermId = currentSelectedStudent.firstTerm.id;
    let firstTermYear = currentSelectedStudent.firstTerm.year;
    const pathwayFirstTermId = currentSelectedStudent.pathwayTerm.id;
    const pathwayFirstYear = currentSelectedStudent.pathwayTerm.year;
    const year = new Date().getFullYear();
    const firstTermData = {};

    if (_.isNull(pathwayFirstTermId) && _.isNull(pathwayFirstYear)) {
      if (_.isNull(firstTermId) && _.isNull(firstTermYear)) {
        setValues({ ...values, nextTerm: null, nextYear: null });
        return;
      }
    } else {
      firstTermId = pathwayFirstTermId;
      firstTermYear = pathwayFirstYear;
    }

    const isTermRegular = termList.some(({ id }) => id === firstTermId);
    if (!isTermRegular) {
      setValues({ ...values, nextTerm: null, nextYear: null });
      return;
    }

    firstTermData['nextTerm'] = firstTermId;

    if (firstTermYear >= year) {
      firstTermData['nextYear'] = firstTermYear;
    }

    setValues({ ...values, ...firstTermData });
  };

  const dispatchBuildPathway = (data) => {
    dispatch(
      getStudentPathway(
        orgId,
        currentSelectedStudent.id,
        buildPathwayData(
          currentSelectedStudent,
          programMapList,
          data,
          completedCourses,
          financialAidSetting,
          catalogs,
        ),
        pathwayCallback,
      ),
    );
  };

  const isNextTermIsValidWRTCompletedTerms = ({ id: nextTermId, year }) => {
    if (_.isEmpty(completedTerms)) {
      return true;
    }

    const calendarYearStarIndex = termList.findIndex(({ calendarYearStart }) => calendarYearStart);
    const orderedTerms = termList
      .slice(calendarYearStarIndex)
      .concat(termList.slice(0, calendarYearStarIndex));
    const { term: lastCompletedTerm } = completedTerms.slice(-1)[0];

    const lastCompletedTermIndex = orderedTerms.findIndex(({ id }) => id === lastCompletedTerm.id);
    const nextTermIndex = orderedTerms.findIndex(({ id }) => id === nextTermId);

    return (
      year > lastCompletedTerm.year ||
      (year === lastCompletedTerm.year && nextTermIndex > lastCompletedTermIndex)
    );
  };

  const dispatchUpdateStudentNextTermErrorMsg = () => {
    dispatch(
      setError({
        message: t('Update student’s next term so it is not in the past.'),
      }),
    );
  };

  const showCompletedTermErrorMsg = () => {
    dispatch(
      setError({
        persistErrors: true,
        message: t('The student’s next term needs to be after their last completed term'),
      }),
    );
  };

  const clickBuildPathway = (values, fromCustomizedTerms = false) => {
    setPathFormValues(values);
    const { graduatingTerm, graduatingYear, nextTerm, nextYear, selectedTerms } = values;

    const currentYear = new Date().getFullYear() - 1;
    const orderedTermsByCalenderYear = getReOrderedTermsByCalenderYear(allTerms, currentYear);

    const calendarYearStarIndex = termList.findIndex(({ calendarYearStart }) => calendarYearStart);
    const orderedTerms = termList
      .slice(calendarYearStarIndex)
      .concat(termList.slice(0, calendarYearStarIndex));

    const startIndex = orderedTerms.findIndex((term) => term.id === nextTerm);
    const endIndex = orderedTerms.findIndex((term) => term.id === graduatingTerm);

    let termsInRange = [];
    for (let year = nextYear; year <= graduatingYear; year++) {
      orderedTerms.forEach((term, index) => {
        if (
          (year > nextYear || index >= startIndex) &&
          (year < graduatingYear || index <= endIndex)
        ) {
          termsInRange.push({ ...term, year: year, status: 'ACTIVE', regularTerm: true });
        }
      });
    }

    const completedTermsList = completedTerms.map((obj) => {
      const { termId, calendarYear, ...term } = obj.term;
      return {
        ...term,
        termPlanningStatus: obj.termPlanningStatus,
      };
    });

    const lastTermOfCompletedTerms = completedTermsList[completedTermsList.length - 1];
    const firstTermOfTermsRange = termsInRange[0];

    const isNext = _.isEmpty(completedTermsList)
      ? true
      : isNextTerm(lastTermOfCompletedTerms, firstTermOfTermsRange, orderedTerms);

    let selectedTermsList = [];

    if (!isNext) {
      selectedTermsList = completedTermsList.concat(termsInRange);
    } else {
      selectedTermsList = selectedTerms;
    }

    if (!isNextTermIsValidWRTCompletedTerms({ id: nextTerm, year: nextYear })) {
      showCompletedTermErrorMsg();
      return;
    }
    let data = null;
    data = {
      graduatingTerm,
      graduatingYear,
      nextTerm,
      nextYear,
      selectedTerms: selectedTermsList,
    };
    if (selectedTerms.length > 0) {
      data = {
        selectedTerms: selectedTermsList,
      };
    }

    const organizationCurrentTermIndex = orderedTermsByCalenderYear.findIndex(
      ({ currentTerm }) => currentTerm,
    );

    if (fromCustomizedTerms) {
      if (selectedTerms.length <= 0) {
        dispatch(
          setError({
            message: t('Select terms the student will attend before building the pathway.'),
          }),
        );
      } else {
        dispatchBuildPathway(data);
      }
    } else {
      if (
        validateNextTerm(
          nextTerm,
          nextYear,
          orderedTermsByCalenderYear,
          organizationCurrentTermIndex,
        )
      ) {
        dispatchBuildPathway(data);
      } else {
        dispatchUpdateStudentNextTermErrorMsg();
      }
    }
  };

  const isNextTerm = (lastTerm, firstTerm, orderedTerms) => {
    // Get the order indices for the terms
    const lastTermOrder = orderedTerms.indexOf(lastTerm.name);
    const firstTermOrder = orderedTerms.indexOf(firstTerm.name);

    // Check if the first term is the next term in sequence
    if (lastTerm.year === firstTerm.year) {
      // If same year, first term's order should be immediately after last term's order
      return firstTermOrder === lastTermOrder + 1;
    } else if (lastTerm.year + 1 === firstTerm.year) {
      // If consecutive years, last term should be the last in its year, and first term should be the first in its year
      return lastTermOrder === orderedTerms.length - 1 && firstTermOrder === 0;
    }

    return false;
  };

  const handleShowCustomizedTerms = (selectedTermsValues, returnValues) => {
    const { graduatingTerm, graduatingYear, nextTerm, nextYear } = returnValues;
    if (!isNextTermIsValidWRTCompletedTerms({ id: nextTerm, year: nextYear })) {
      showCompletedTermErrorMsg();
      return;
    }
    const orderedTermsByCalenderYear = getReOrderedTermsByCalenderYear(allTerms, 2022);

    const orgCurrentTermIndex = orderedTermsByCalenderYear.findIndex(
      ({ currentTerm }) => currentTerm,
    );

    if (validateNextTerm(nextTerm, nextYear, orderedTermsByCalenderYear, orgCurrentTermIndex)) {
      setBuildPathwayParams(returnValues);
      const requestData = {
        nextYear: nextYear,
        graduatingYear: graduatingYear,
        nextTerm: nextTerm,
        graduatingTerm: graduatingTerm,
      };

      const callback = (termsOrder) => {
        setBuildPathwayParams({
          nextYear: nextYear,
          graduatingYear: graduatingYear,
          nextTerm: nextTerm,
          graduatingTerm: graduatingTerm,
          selectedTerms: termsOrder,
        });
        setShowCustomizeTerms(selectedTermsValues);
      };

      dispatch(getTermsOrder(orgId, requestData, callback));
    } else {
      dispatchUpdateStudentNextTermErrorMsg();
    }
  };

  return (
    <>
      {!showCustomizeTerms ? (
        <TermCardYear
          handleShowCustomizedTerms={handleShowCustomizedTerms}
          clickBuildPathway={clickBuildPathway}
          buildPathwayParams={buildPathwayParams}
          formValues={values}
          handleFormInputChange={handleInputChange}
        />
      ) : (
        <TermCardCustomizedTerms
          pathwayCallback={pathwayCallback}
          setPathFormValues={setPathFormValues}
          completedCourses={completedCourses}
          handleShowCustomizedTerms={handleShowCustomizedTerms}
          clickBuildPathway={clickBuildPathway}
          buildPathwayParams={buildPathwayParams}
          setBuildPathwayParams={setBuildPathwayParams}
          completedTerms={completedTerms}
        />
      )}
    </>
  );
}
