import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiRadio-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  dialogRoot: {
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  question: {
    marginTop: `${theme.spacing(2)} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
  },
  questionList: {
    paddingLeft: theme.spacing(2),
  },
  dialogBoxBtn: {
    '&.MuiButton-root': {
      height: theme.spacing(5),
      marginTop: theme.spacing(1),
      borderRadius: theme.spacing(3),
      textTransform: 'capitalize',
      fontWeight: 300,
      width: theme.spacing(15),
      marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
    },
  },
  dialogBoxBtnContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

export default function QuestionDialog({
  open,
  questions,
  answeredQuestions,
  setAnsweredQuestions,
  activeQuestionAnswers,
  setActiveQuestionAnswers,
  handleClose,
  handleCancel,
  handleSubmit,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const questionComponent = (question) => {
    return (
      <Stack direction="column" className={classes.root}>
        <Typography className={classes.question}>{`${question.message}*`}</Typography>
        <FormControl>
          <RadioGroup
            name={question.id}
            onChange={(event, value) => {
              const answeredId = event.target.name;
              let answerObjCopy = _.cloneDeep(answeredQuestions);
              let activeAnswerCopy = _.cloneDeep(activeQuestionAnswers);
              answerObjCopy[answeredId] = value == 'true';
              activeAnswerCopy[answeredId] = value == 'true';
              setAnsweredQuestions(answerObjCopy);
              setActiveQuestionAnswers(activeAnswerCopy);
            }}>
            <FormControlLabel
              value={true}
              control={<Radio size="small" />}
              label={question.positiveAnswer}
            />
            <FormControlLabel
              value={false}
              control={<Radio size="small" />}
              label={question.negativeAnswer}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    );
  };
  return (
    <div>
      <Dialog
        className={classes.dialogRoot}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="pathway question"
        aria-describedby="pathway question dialog box">
        <DialogTitle id="pathway-question-dialog-title">
          Questions {`(${questions.length})`}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <ol className={classes.questionList}>
            {questions.map((question, key) => {
              return <li key={key}>{questionComponent(question)}</li>;
            })}
          </ol>
        </DialogContent>
        <DialogActions>
          <Box className={classes.dialogBoxBtnContainer}>
            <Button
              disabled={_.size(activeQuestionAnswers) !== questions.length}
              variant="contained"
              color="secondary"
              className={classes.dialogBoxBtn}
              onClick={handleSubmit}>
              {t('Submit')}
            </Button>
            <Button
              variant="contained"
              color="grey"
              className={classes.dialogBoxBtn}
              onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
