import { takeLatest } from 'redux-saga/effects';
import { actionTypes } from '../constants/actionTypes';
import {
  handleDuplicateCatalog,
  handleGetCatalogs,
  handlePostCatalog,
  handlePutCatalog,
  handlePutCatalogsOrder,
} from './handlers/catalogs';
import {
  handleGetLearningActivities,
  handleGetLearningActivitiesTransferEquivalencies,
  handleGetLearningActivity,
  handleGetLearningActivityTerms,
  handlePostLearningActivity,
  handlePutLearningActivity,
  handleGetLearningActivitiesForCatalog,
  handleGetClassSchedules,
  handlePostClassSchedules,
  handlePutClassSchedules,
  handlePatchLearningActivity,
} from './handlers/learningActivity';
import {
  handleGetPrograms,
  handleCreateProgram,
  handleEditProgram,
  handleGetProgramOptions,
  handleGetProgram,
  handleCreateProgramOption,
  handleGetProgramOption,
  handleUpdateProgramOption,
} from './handlers/program';
import {
  handleGetProgramMaps,
  handleCreateProgramMap,
  handleEditProgramMap,
  handleGetProgramMappingData,
  handleGetProgramMapRequirements,
  handleConstructProgramMapPathway,
  handleUpdateProgramMappingData,
  handleGetLearningActivitiesForGroup,
  handleRenameProgramMapGroup,
  handleAddNewProgramMapGroup,
  handleLinkProgramMapIntoProgramMap,
  handleRemoveProgramMapNode,
  handleMoveProgramMapNode,
  handleUpdateProgramMapNode,
} from './handlers/programMap';

import {
  handleGetOrgs,
  handlePostOrg,
  handlePutOrg,
  handleGetOrgsWithSelectedOrg,
} from './handlers/orgs';
import { handlePostRule, handleUpdateRule, handleGetAssociatedRule } from './handlers/rules';
import {
  handleGetColleges,
  handlePostCollegesDepartments,
  handlePutCollegeDepartment,
} from './handlers/department';
import {
  handlePostTerms,
  handlePutTerms,
  handlePutTermsOrder,
  handlePutTermInstances,
  handlePutCurrentTerm,
  handlePostPathwayNewTerm,
  handlePutCalendarYearStartTerm,
  handleGetTermsOrder,
  handleGetAcademicYearsWithTermInstances,
} from './handlers/terms';
import { handleGetTags } from './handlers/tags';
import {
  handleGetTeqs,
  handlePostTeq,
  handleGetRequirements,
  handlePostTeqAssignments,
  handleGetTeqStatuses,
  handleUpdateTeq,
  handlePatchTeqAssignments,
  handlePostTeqWithRequirements,
  handleGetTeq,
} from './handlers/transferEquivalency';
import {
  handleGetStudents,
  handleGetStudentsStatusList,
  handleGetStudentTeqAssignments,
  handleGetStudentTransferEvaluations,
  handlePostTransferEvaluation,
  handleGetTransferEvaluationStatusList,
  handleEditTransferEvaluation,
  handleGenerateStudentPathway,
  handlePatchStudent,
  handleSearchStudents,
  handleUpdateStudentPathway,
  handleUpdateStudentByStudentTeqStatus,
  handleDeleteStudentByStudentTeqStatus,
} from './handlers/students';
import {
  handleGetCompletedCourseReq,
  handleGetTransferEvaluationForPrint,
  handlePatchCompletedCourseReq,
  handlePostCompletedCourseReq,
} from './handlers/transferEvaluation';
import {
  handleGetPathways,
  handleGetPathwaysCourseUsage,
  handleGetPathwaysToExplore,
  handleGetTermPlanningStatus,
  handlePostResetPathway,
  handlePostPathways,
  handlePostPathwaysCourseUsage,
  handlePutPathway,
  handleValidatePathway,
  handleGetPathwayStatus,
  handleMarkCourseAsIncomplete,
} from './handlers/pathways';
import { handleLoginRedirect, handleUserInfo } from './handlers/auth';
import { handleGetSettings, handlePostSettings } from './handlers/settings';
import { handleGetAdvisors, handleUpdateAdvisorForStudent } from './handlers/advisors';
import {
  handleGetOrgClassSchedules,
  handleGetOrgPlannedStudents,
  handlePatchClassSchedule,
} from './handlers/classSchedules';

import { handlePostClassScheduleStats } from './handlers/classScheduleStats';
import {
  handleCreateCourseProjectionReport,
  handleCreateStatusHistoryReport,
  handleGenerateStatusHistoryReport,
} from './handlers/reports';

export function* watcherSaga() {
  yield takeLatest(actionTypes.GET_ORGS, handleGetOrgs);
  yield takeLatest(actionTypes.GET_ORGS_WITH_SELECTED_ORG, handleGetOrgsWithSelectedOrg);
  yield takeLatest(actionTypes.GET_CATALOGS, handleGetCatalogs);
  yield takeLatest(actionTypes.GET_LEARNING_ACTIVITIES, handleGetLearningActivities);
  yield takeLatest(
    actionTypes.GET_LEARNING_ACTIVITIES_TRANSFER_EQUIVALENCIES,
    handleGetLearningActivitiesTransferEquivalencies,
  );
  yield takeLatest(
    actionTypes.GET_LEARNING_ACTIVITIES_FOR_CATALOG,
    handleGetLearningActivitiesForCatalog,
  );
  yield takeLatest(actionTypes.PUT_ORG, handlePutOrg);
  yield takeLatest(actionTypes.POST_ORG, handlePostOrg);
  yield takeLatest(actionTypes.PUT_CATALOG, handlePutCatalog);
  yield takeLatest(actionTypes.POST_CATALOG, handlePostCatalog);
  yield takeLatest(actionTypes.GET_LEARNING_ACTIVITY, handleGetLearningActivity);
  yield takeLatest(actionTypes.GET_TERMS, handleGetLearningActivityTerms);
  yield takeLatest(actionTypes.POST_LEARNING_ACTIVITY, handlePostLearningActivity);
  yield takeLatest(actionTypes.PATCH_LEARNING_ACTIVITY, handlePatchLearningActivity);
  yield takeLatest(actionTypes.PUT_LEARNING_ACTIVITY, handlePutLearningActivity);
  yield takeLatest(actionTypes.GET_ASSOCIATED_RULE, handleGetAssociatedRule);
  yield takeLatest(actionTypes.POST_RULE, handlePostRule);
  yield takeLatest(actionTypes.UPDATE_RULE, handleUpdateRule);
  yield takeLatest(actionTypes.GET_PROGRAMS, handleGetPrograms);
  yield takeLatest(actionTypes.GET_PROGRAM, handleGetProgram);
  yield takeLatest(actionTypes.CREATE_PROGRAM, handleCreateProgram);
  yield takeLatest(actionTypes.EDIT_PROGRAM, handleEditProgram);
  yield takeLatest(actionTypes.GET_PROGRAM_OPTIONS, handleGetProgramOptions);
  yield takeLatest(actionTypes.GET_PROGRAM_OPTION, handleGetProgramOption);
  yield takeLatest(actionTypes.CREATE_PROGRAM_OPTION, handleCreateProgramOption);
  yield takeLatest(actionTypes.UPDATE_PROGRAM_OPTION, handleUpdateProgramOption);
  yield takeLatest(actionTypes.GET_PROGRAM_MAPS, handleGetProgramMaps);
  yield takeLatest(actionTypes.CREATE_PROGRAM_MAP, handleCreateProgramMap);
  yield takeLatest(actionTypes.EDIT_PROGRAM_MAP, handleEditProgramMap);
  yield takeLatest(actionTypes.CONSTRUCT_PROGRAM_MAP_PATHWAY, handleConstructProgramMapPathway);
  yield takeLatest(actionTypes.PROGRAM.GET_PROGRAM_MAPPING_DATA, handleGetProgramMappingData);
  yield takeLatest(
    actionTypes.PROGRAM.GET_LEARNING_ACTIVITIES_FOR_GROUP,
    handleGetLearningActivitiesForGroup,
  );
  yield takeLatest(actionTypes.PROGRAM.RENAME_PROGRAM_MAP_GROUP, handleRenameProgramMapGroup);
  yield takeLatest(actionTypes.PROGRAM.ADD_NEW_PROGRAM_MAP_GROUP, handleAddNewProgramMapGroup);
  yield takeLatest(
    actionTypes.PROGRAM.LINK_PROGRAM_MAP_INTO_PROGRAM_MAP,
    handleLinkProgramMapIntoProgramMap,
  );
  yield takeLatest(actionTypes.PROGRAM.REMOVE_PROGRAM_MAP_NODE, handleRemoveProgramMapNode);
  yield takeLatest(actionTypes.PROGRAM.MOVE_PROGRAM_MAP_NODE, handleMoveProgramMapNode);
  yield takeLatest(actionTypes.PROGRAM.UPDATE_PROGRAM_MAP_NODE, handleUpdateProgramMapNode);
  yield takeLatest(actionTypes.GET_PROGRAM_MAP_REQUIREMENTS, handleGetProgramMapRequirements);
  yield takeLatest(actionTypes.COLLEGES.GET, handleGetColleges);
  yield takeLatest(actionTypes.COLLEGES.CREATE, handlePostCollegesDepartments);
  yield takeLatest(actionTypes.COLLEGES.EDIT, handlePutCollegeDepartment);
  yield takeLatest(actionTypes.CREATE_TERMS, handlePostTerms);
  yield takeLatest(actionTypes.UPDATE_TERMS, handlePutTerms);
  yield takeLatest(actionTypes.UPDATE_TERMS_ORDER, handlePutTermsOrder);
  yield takeLatest(actionTypes.UPDATE_TERMS_INSTANCES, handlePutTermInstances);
  yield takeLatest(
    actionTypes.GET_ACADEMIC_YEARS_WITH_TERM_INSTANCES,
    handleGetAcademicYearsWithTermInstances,
  );
  yield takeLatest(actionTypes.UPDATE_CURRENT_TERM, handlePutCurrentTerm);
  yield takeLatest(actionTypes.UPDATE_CALENDAR_YEAR_START_TERM, handlePutCalendarYearStartTerm);
  yield takeLatest(actionTypes.PATHWAY.ADD_TERM, handlePostPathwayNewTerm);
  yield takeLatest(actionTypes.PATHWAY.GET_TERMS_ORDER, handleGetTermsOrder);
  yield takeLatest(actionTypes.GET_CLASS_SCHEDULES, handleGetClassSchedules);
  yield takeLatest(actionTypes.CREATE_CLASS_SCHEDULE, handlePostClassSchedules);
  yield takeLatest(actionTypes.UPDATE_CLASS_SCHEDULE, handlePutClassSchedules);
  yield takeLatest(actionTypes.TAGS.GET, handleGetTags);
  yield takeLatest(actionTypes.STUDENTS.GET, handleGetStudents);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.CREATE, handlePostTeq);
  yield takeLatest(
    actionTypes.TRANSFER_EQUIVALENCY.CREATE_WITH_REQUIREMENTS,
    handlePostTeqWithRequirements,
  );
  yield takeLatest(actionTypes.TRANSFER_EVALUATION.CREATE, handlePostTransferEvaluation);
  yield takeLatest(actionTypes.TRANSFER_EVALUATION.EDIT, handleEditTransferEvaluation);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.GET_REQUIREMENTS, handleGetRequirements);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.GET_LIST, handleGetTeqs);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.GET, handleGetTeq);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.GET_STATUSES, handleGetTeqStatuses);
  yield takeLatest(actionTypes.STUDENTS.GET_STUDENT_STATUS, handleGetStudentsStatusList);
  yield takeLatest(actionTypes.STUDENTS.GET_TEQ_ASSIGNMENTS, handleGetStudentTeqAssignments);
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.GET_STUDENT_EVALUATIONS,
    handleGetStudentTransferEvaluations,
  );
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.CREATE_ASSIGNMENT, handlePostTeqAssignments);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.PATCH_ASSIGNMENT, handlePatchTeqAssignments);
  yield takeLatest(actionTypes.TRANSFER_EQUIVALENCY.EDIT, handleUpdateTeq);
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.GET_STATUS_LIST,
    handleGetTransferEvaluationStatusList,
  );
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.CREATE_COMPLETED_COURSE_REQ,
    handlePostCompletedCourseReq,
  );
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.UPDATE_COMPLETED_COURSE_REQ,
    handlePatchCompletedCourseReq,
  );
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.GET_COMPLETED_COURSE_REQ,
    handleGetCompletedCourseReq,
  );
  yield takeLatest(actionTypes.STUDENTS.GET_ORDERED_PATHWAY, handleGenerateStudentPathway);
  yield takeLatest(actionTypes.PATHWAY.GET_TERM_PLANNING_STATUSES, handleGetTermPlanningStatus);
  yield takeLatest(actionTypes.PATHWAY.CREATE, handlePostPathways);
  yield takeLatest(actionTypes.PATHWAY.GET, handleGetPathways);
  yield takeLatest(actionTypes.PATHWAY.CREATE_COURSE_USAGE, handlePostPathwaysCourseUsage);
  yield takeLatest(actionTypes.PATHWAY.GET_COURSE_USAGE, handleGetPathwaysCourseUsage);
  yield takeLatest(actionTypes.PATHWAY.UPDATE_PATHWAY_ASSIGNMENT, handlePutPathway);
  yield takeLatest(actionTypes.PATHWAY.GET_PATHWAYS_TO_EXPLORE, handleGetPathwaysToExplore);
  yield takeLatest(actionTypes.REDIRECT_TO_LOGIN, handleLoginRedirect);
  yield takeLatest(actionTypes.PATHWAY.VALIDATE_PATHWAY, handleValidatePathway);
  yield takeLatest(actionTypes.GET_USER_INFO, handleUserInfo);
  yield takeLatest(actionTypes.STUDENTS.UPDATE_STUDENT, handlePatchStudent);
  yield takeLatest(actionTypes.STUDENTS.SEARCH_STUDENTS, handleSearchStudents);
  yield takeLatest(actionTypes.SETTINGS.GET, handleGetSettings);
  yield takeLatest(actionTypes.SETTINGS.SAVE, handlePostSettings);
  yield takeLatest(actionTypes.ORG_CLASS_SCHEDULES.GET, handleGetOrgClassSchedules);
  yield takeLatest(actionTypes.ADVISORS.GET, handleGetAdvisors);
  yield takeLatest(actionTypes.ADVISORS.SET_ADVISOR_FOR_STUDENT, handleUpdateAdvisorForStudent);
  yield takeLatest(
    actionTypes.ORG_CLASS_SCHEDULES.GET_PLANNED_STUDENTS,
    handleGetOrgPlannedStudents,
  );
  yield takeLatest(actionTypes.ORG_CLASS_SCHEDULES.CANCEL_SINGLE, handlePatchClassSchedule);
  yield takeLatest(actionTypes.PATHWAY.RESET, handlePostResetPathway);
  yield takeLatest(actionTypes.PATHWAY.GET_PATHWAY_STATUSES, handleGetPathwayStatus);
  yield takeLatest(actionTypes.PATHWAY.MARK_COURSE_AS_INCOMPLETE, handleMarkCourseAsIncomplete);
  yield takeLatest(actionTypes.STUDENTS.UPDATE_STUDENT_PATHWAY, handleUpdateStudentPathway);
  yield takeLatest(actionTypes.CLASS_SCHEDULES_STATS.CANCEL_SINGLE, handlePostClassScheduleStats);
  yield takeLatest(
    actionTypes.REPORTS.FETCH_PATHWAY_STATUS_HISTORY,
    handleCreateStatusHistoryReport,
  );
  yield takeLatest(actionTypes.REPORTS.GENERATE_STATUS_HISTORY, handleGenerateStatusHistoryReport);
  yield takeLatest(actionTypes.REPORTS.FETCH_COURSE_PROJECTION, handleCreateCourseProjectionReport);
  yield takeLatest(actionTypes.DUPLICATE_CATALOG, handleDuplicateCatalog);
  yield takeLatest(actionTypes.UPDATE_CATALOGS_ORDER, handlePutCatalogsOrder);
  yield takeLatest(actionTypes.PROGRAM.PATCH_PROGRAM_MAPPING_DATA, handleUpdateProgramMappingData);
  yield takeLatest(
    actionTypes.STUDENTS.UPDATE_STUDENT_BY_STUDENT_TEQ_STATUS,
    handleUpdateStudentByStudentTeqStatus,
  );
  yield takeLatest(
    actionTypes.STUDENTS.DELETE_STUDENT_BY_STUDENT_TEQ_STATUS,
    handleDeleteStudentByStudentTeqStatus,
  );
  yield takeLatest(
    actionTypes.TRANSFER_EVALUATION.GET_EVALUATION_FOR_PRINT,
    handleGetTransferEvaluationForPrint,
  );
}
