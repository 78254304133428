import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import TermComponent from './TermComponent';
import SitOutComponent from './SitOutComponent';
import { makeStyles } from '@mui/styles';
import { termCardStyles } from '../../styles/termCardStyles';
import { useDrop } from 'react-dnd';
import { learningActivityType, termPlanningStatusTypes } from '../../../helpers/appConstants';
import { MoreVert, CheckCircle } from '@mui/icons-material';
import DeclareMenu from '../../DeclareMenu';
import { useSelector } from 'react-redux';
import { checkTermIsCompletable, findTermInstance } from '../../../helpers/pathwayHelper';
import { getTermDisplayName } from '../../../helpers/termsHelper';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  sitOutBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  laList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    overflowY: 'auto',
    flex: 1,
    justifyContent: ({ center }) => (center ? 'center' : 'flex-start'),
  },
  checkIcon: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.6),
    float: 'right',
  },
}));

function TermCard({
  data,
  index,
  handlePathwayMove,
  handleMultipleMove,
  sitOutTerm,
  addNewTerm,
  orderedPathway,
  removeTerm = null,
  setActiveTab,
  setSelectedLearningActivity,
  undoSitOutTerm,
  selectedCourseItems,
  addItemsToSelectedCourseList,
  resetSelectedCourseItems,
  toggleCompletedStatus,
  setCompletedCardHeight,
  invalidCoursesDueToCSs,
  completedCourses,
  markCourseAsIncomplete,
  completeAnIncompletedCourse,
  freeElectiveData,
  setFreeElectiveData,
  termData,
  totalUnits,
  totalProgramUnits,
  removeCourseFromPathway,
}) {
  const { term, items, termPlanningStatus, units } = data;
  const ref = useRef(null);
  const firstTermCard = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles({ center: termPlanningStatus === termPlanningStatusTypes.SIT_OUT });
  const termCardClasses = termCardStyles();
  const [anchorElement, setAnchorElement] = useState(null);
  const [addNewTermPathway, setAddNewTermPathway] = useState(false);
  const openMenu = Boolean(anchorElement);
  const [regularTerm, setRegularTerm] = useState(false);
  const { termList, termInstances } = useSelector((state) => state.terms);

  const handleMenuOpen = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };
  const termCompleted = termPlanningStatus === termPlanningStatusTypes.COMPLETED;
  const termInstanceId = findTermInstance(termInstances, term)?.id;

  const [{ isOver, elem }, drop] = useDrop(
    () => ({
      accept: termCompleted ? '' : learningActivityType.COURSE,
      // eslint-disable-next-line prettier/prettier
      hover: (item, monitor) => {},
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        if (selectedCourseItems.length > 0) {
          handleMultipleMove(index);
        } else {
          handlePathwayMove(item.path, index);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true }),
        dropItem: monitor.getItem(),
        elem: term,
        targetId: monitor.targetId,
      }),
    }),
    [data, orderedPathway, selectedCourseItems, completedCourses],
  );

  const EmptyTermComponent = () => (
    <>
      <Box className={termCardClasses.termCardContent}>
        <Box className={termCardClasses.termCardButtonContainer}>
          <Box className={termCardClasses.termCardTransferredLaList}>
            <Box className={termCardClasses.termCardButtonItem}>
              <Typography color="common.black" fontWeight={700}>
                {t('Nothing added') + '.'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  useEffect(() => {
    if (!_.isEmpty(orderedPathway)) {
      const term = _.find(termList, (term) => term.id === termData.id);
      setRegularTerm(term.regularTerm);
      const currIndex = termList.indexOf(term);
      let nextTerm = null;
      let year = null;
      if (currIndex === termList.length - 1) {
        nextTerm = termList[0];
        year = termData.year + 1;
      } else {
        nextTerm = termList[currIndex + 1];
        year = termData.year;
      }

      const pathwayTerm = _.find(
        orderedPathway,
        (pathway) => pathway.termId === nextTerm.id && pathway.term.year === year,
      );

      //If next term is non regular and not in the pathway
      if (!nextTerm.regularTerm && _.isUndefined(pathwayTerm)) {
        setAddNewTermPathway(true);
      } else {
        setAddNewTermPathway(false);
      }
    }
  }, [orderedPathway]);

  const isSelected = (la, termIndex) => {
    return selectedCourseItems.find(
      (course) => course.laId == la.id && termIndex === course.path[0],
    );
  };

  const termCompletable = termCompleted || checkTermIsCompletable(orderedPathway, index, termList);

  const termNotSitOut = termPlanningStatus !== termPlanningStatusTypes.SIT_OUT;
  const notLastTerm = index !== orderedPathway.length - 1;

  const SitOutMenuItem = () =>
    termNotSitOut && notLastTerm && regularTerm && !termCompleted ? (
      <MenuItem
        onClick={() => {
          sitOutTerm(index);
          handleMenuClose();
        }}>
        <ListItemText>{t('Sit Out Term')}</ListItemText>
      </MenuItem>
    ) : null;
  const RemoveTermMenuItem = () =>
    termNotSitOut && (!notLastTerm || (notLastTerm && !regularTerm)) && !termCompleted ? (
      <MenuItem
        onClick={() => {
          removeTerm(index);
          handleMenuClose();
        }}>
        <ListItemText>{t('Remove Term')}</ListItemText>
      </MenuItem>
    ) : null;

  const AddTermMenuItem = () =>
    addNewTermPathway ? (
      <MenuItem
        onClick={() => {
          addNewTerm(orderedPathway[index], index);
          handleMenuClose();
        }}>
        <ListItemText>{t('Add term')}</ListItemText>
      </MenuItem>
    ) : null;

  const CompleteTermMenuItem = () =>
    termCompletable && !_.isEmpty(orderedPathway) ? (
      <MenuItem
        onClick={() => {
          toggleCompletedStatus(term, items, termCompleted);
          handleMenuClose();
        }}>
        <ListItemText>
          {termCompleted ? t('Mark as Incomplete Term') : t('Mark as Completed Term')}
        </ListItemText>
      </MenuItem>
    ) : null;

  const UndoSitOutMenuItem = () =>
    !termNotSitOut ? (
      <MenuItem
        onClick={() => {
          undoSitOutTerm(index);
          handleMenuClose();
        }}>
        <ListItemText>{t('Undo Sit Out Term')}</ListItemText>
      </MenuItem>
    ) : null;

  useLayoutEffect(() => {
    if (!_.isNil(firstTermCard.current)) {
      const height = Object.values(firstTermCard.current.children).reduce(
        (height, currentElement) => currentElement.clientHeight + height,
        0,
      );
      setCompletedCardHeight(height);
    }
  }, [data, items]);

  return (
    <Box className={termCardClasses.termCard} ref={termNotSitOut ? drop(ref) : null}>
      <Box className={classes.cardHeader}>
        <Box>
          <Typography variant="body1" fontSize={21} fontWeight={700}>
            {getTermDisplayName(term)}
            {termCompleted && (
              <CheckCircle color="primary" fontSize="xtrasmall" className={classes.checkIcon} />
            )}
          </Typography>
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {`${units} ${t('units')}`}
          </Typography>
        </Box>
        <Box className={classes.sitOutBtn}>
          {!_.isEmpty(orderedPathway) && (
            <MoreVert
              onClick={handleMenuOpen}
              fontSize="medium"
              color="common.black"
              className={termCardClasses.contextMenuBtn}
            />
          )}
          <DeclareMenu anchorEl={anchorElement} open={openMenu} onClose={handleMenuClose}>
            <SitOutMenuItem />
            <RemoveTermMenuItem />
            <AddTermMenuItem />
            <CompleteTermMenuItem />
            <UndoSitOutMenuItem />
          </DeclareMenu>
        </Box>
      </Box>
      <Box className={classes.laList} ref={index == 0 ? firstTermCard : null}>
        {/* TODO: get these status from the backend */}
        {termPlanningStatus === termPlanningStatusTypes.SIT_OUT ? (
          <SitOutComponent />
        ) : _.isEmpty(items) ? (
          <EmptyTermComponent />
        ) : (
          items.map((la, laIndex) => (
            <TermComponent
              termCompleted={termCompleted}
              fromTerm={true}
              selectedCount={selectedCourseItems.length}
              selected={isSelected(la, index)}
              key={laIndex}
              path={[index, laIndex]}
              laCode={la.code}
              laName={la.name}
              laUnits={la.units}
              la={la}
              laAdditionalInfo={la.additionalInfo}
              draggable={!termCompleted}
              setActiveTab={setActiveTab}
              setSelectedLearningActivity={setSelectedLearningActivity}
              addItemsToSelectedCourseList={addItemsToSelectedCourseList}
              resetSelectedCourseItems={resetSelectedCourseItems}
              invalidCoursesDueToCSs={invalidCoursesDueToCSs}
              termData={{ ...data, termInstanceId }}
              markCourseAsIncomplete={markCourseAsIncomplete}
              completeAnIncompletedCourse={completeAnIncompletedCourse}
              hasPathway={!_.isEmpty(orderedPathway)}
              freeElectiveData={freeElectiveData}
              setFreeElectiveData={setFreeElectiveData}
              totalUnits={totalUnits}
              totalProgramUnits={totalProgramUnits}
              removeCourseFromPathway={removeCourseFromPathway}
            />
          ))
        )}
      </Box>
    </Box>
  );
}

export default TermCard;
