import { HttpService } from './HttpService';
import _ from 'lodash';

export class LearningActivityService extends HttpService {
  constructor() {
    super();
    this.getAll = _.memoize(this.getAll.bind(this));
    this.getAllForCatalog = _.memoize(this.getAllForCatalog.bind(this));
  }

  async getTerms(org) {
    return this.get(`organizations/${org}/semesters`);
  }

  async getAll(id) {
    return this.get(`organizations/${id}/learningActivities`);
  }

  async getAllForCatalog({ org, catalog }) {
    return this.get(`organizations/${org}/learningActivities?catalog=${catalog}`);
  }

  async getActivity(org, { id, catalog }) {
    const catalogs = catalog.map((cat) => cat.id).join(',');
    return this.get(
      `organizations/${org}/learningActivities/${id}?existingCatalogVersions=${catalogs}`,
    );
  }

  async save(org, body) {
    return this.post(`organizations/${org}/learningActivities`, body);
  }

  async partiallyUpdate(org, id, body) {
    return this.patch(`organizations/${org}/learningActivities/${id}`, body);
  }

  async update(org, id, body) {
    return this.put(`organizations/${org}/learningActivities/${id}`, body);
  }

  async getAllClassSchedules(id, lActivityId) {
    return this.get(`organizations/${id}/learningActivities/${lActivityId}/classSchedules/`);
  }

  async saveClassSchedule(org, laId, body) {
    return this.post(`organizations/${org}/learningActivities/${laId}/classSchedules`, body);
  }

  async updateClassSchedule(org, laId, csId, body) {
    return this.put(`organizations/${org}/learningActivities/${laId}/classSchedules/${csId}`, body);
  }
}
