import _ from 'lodash';

export const processAcademicYears = (academicYears, terms) => {
  const termNames = _.chain(terms).keyBy('id').mapValues('name').value();
  return academicYears.map((academicYear) => ({
    ...academicYear,
    termInstances: academicYear.termInstances.map((termInstance) => ({
      ...termInstance,
      displayName: `${termNames[termInstance.termId]} ${termInstance.calendarYear}`,
    })),
  }));
};

export const processedTermInstances = (processedAcademicYears) => {
  const termInstances = processedAcademicYears.flatMap((processedAcademicYear) => {
    return processedAcademicYear.termInstances.map((termInstance) => ({
      ...termInstance,
      status: processedAcademicYear.status,
      name: processedAcademicYear.name,
      startingYear: processedAcademicYear.startingYear,
    }));
  });
  return _.keyBy(termInstances, 'id');
};

export const updateAcademicYears = (academicYears, data, year) => {
  const updatedInstances = _.keyBy(data, 'id');
  return academicYears.map((academicYear) =>
    academicYear.startingYear !== year
      ? academicYear
      : {
          ...academicYear,
          termInstances: academicYear.termInstances.map((termInstance) => {
            const { startDate, endDate } = updatedInstances[termInstance.id];
            return { ...termInstance, startDate, endDate };
          }),
        },
  );
};

export const getTermDisplayName = (term) => `${term.name} ${term.calendarYear}`;
