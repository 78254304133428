import { requestHandler, requestTypes } from '../helper';

import { requestPostClassScheduleStats } from '../requests/classScheduleStats';

export function* handlePostClassScheduleStats(action) {
  const { payload, callback } = action;
  yield requestHandler(
    requestPostClassScheduleStats.bind(this, payload),
    null,
    requestTypes.POST,
    callback,
  );
}
