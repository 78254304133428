import { Box, Button, Container, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';
import StatusCell from '../../../components/StatusCell';
import { CLASS_SCHEDULE_STATUS, deliveryModes } from '../../../helpers/appConstants';
import { getOrgClassSchedules } from '../../../redux/actions/classScheduleActions';
import { generateExcelForOrgClassSchedules } from '../../../processes/exportClassSchedule';
import { defaultTableOptions, getQueryParams } from '../../../helpers/classScheduleHelper';
import { convertToQueryString } from '../../../helpers/common';
import { Stack } from '@mui/system';
import { getAcademicYearsWithTermInstances } from '../../../redux/actions/termActions';
import { getLearningActivities, getTerms } from '../../../redux/actions/learningActivityActions';

export default function CatalogClassScheduleList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { termList, academicYears, termInstances } = useSelector((state) => state.terms);
  const { list: classSchedulesList } = useSelector((state) => state.classSchedules);
  const { learningActivities } = useSelector((state) => state.allLearningActivities);
  const { orgs } = useSelector((state) => state.allOrgs);
  const [totalClassSchedules, setTotalClassSchedules] = useState(0);
  const [filteredClassSchedulesList, setFilteredClassSchedulesList] = useState(classSchedulesList);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [tableOptions, setTableOptions] = useState(defaultTableOptions);
  const WAIT_UNTIL_SEARCH_MILLISECONDS = 400;

  useEffect(() => {
    const query = getQueryParams(tableOptions);
    const queryString = convertToQueryString(query);
    dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
    if (_.isEmpty(termList)) {
      dispatch(getTerms(orgId));
    }

    if (_.isEmpty(learningActivities)) {
      dispatch(getLearningActivities(orgId));
    }
  }, []);

  const debouncedSearch = useRef(
    _.debounce((query) => {
      dispatch(getOrgClassSchedules(orgId, query, handleGetClassScheduleResponse));
    }, WAIT_UNTIL_SEARCH_MILLISECONDS),
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (!_.isEmpty(termList) && _.isEmpty(academicYears)) {
      dispatch(getAcademicYearsWithTermInstances(orgId));
    }
  }, [termList, academicYears]);

  const handleSearchKeyChanges = (value) => {
    const data = { ...tableOptions, page: 0, query: value };
    const query = getQueryParams(data);
    const queryString = convertToQueryString(query);
    debouncedSearch(queryString);
    setTableOptions(data);
  };

  const handleGetClassScheduleResponse = (res) => {
    const { data, totalItems } = res;
    setTotalClassSchedules(totalItems);
    setTableOptions((tableOptions) => ({ ...tableOptions, totalItems }));
    setFilteredClassSchedulesList(
      data.map((cs) => ({
        ...cs,
        termInstanceIdForYear: cs.termInstanceId,
        termInstanceIdForTerms: cs.termInstanceId,
      })),
    );
  };

  const toggleMoreVert = (event) => {
    setAnchorEl(_.isNull(anchorEl) ? event.currentTarget : null);
  };

  const handleDownloadClassSchedule = async () => {
    setAnchorEl(null);
    const query = getQueryParams(tableOptions);
    query.page = 1;
    query.size = totalClassSchedules;
    query.sortColumn = 'lActivity.code';
    query.sortDirection = 'asc';
    const queryString = convertToQueryString(query);
    dispatch(
      getOrgClassSchedules(orgId, queryString, (res) => {
        const { data } = res;
        const organization = orgs.find((data) => data.id === orgId);
        const transformedData = transformClassScheduleExportData(data);
        generateExcelForOrgClassSchedules(organization, transformedData);
      }),
    );
  };

  const transformClassScheduleExportData = (data) => {
    return data.map(({ termInstanceId, lActivity, deliveryMode }) => {
      const term = termInstances[termInstanceId];
      const catalog = learningActivities.find(({ id }) => id === lActivity.id).catalog[0];

      return {
        catalog,
        lActivity,
        deliveryMode,
        term,
      };
    });
  };

  const columns = [
    {
      name: 'lActivity.code',
      label: t('Course Code'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (code) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {code}
            </Typography>
          );
        },
      },
    },
    {
      name: 'lActivity.name',
      label: t('Course Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (name) => {
          return <Typography variant="body2">{name}</Typography>;
        },
      },
    },
    {
      name: 'termInstanceIdForYear',
      label: t('Year'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (id) => {
          return <Typography variant="body2">{termInstances[id]?.name}</Typography>;
        },
      },
    },
    {
      name: 'termInstanceIdForTerms',
      label: t('Terms'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (id) => {
          return <Typography variant="body2">{termInstances[id]?.displayName}</Typography>;
        },
      },
    },
    {
      name: 'deliveryMode',
      label: t('Delivery Mode'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (dm) => {
          return (
            <Typography variant="body2">
              {
                deliveryModes.find(({ value }) => {
                  return value === dm;
                }).label
              }
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: t('Status'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <StatusCell value={value} name={CLASS_SCHEDULE_STATUS[value].label} />;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    serverSide: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    count: tableOptions.totalItems,
    page: tableOptions.page,
    rowsPerPage: tableOptions.rowsPerPage,
    viewColumns: false,
    enableNestedDataAccess: '.',
    sortOrder: tableOptions.sort,
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        const data = {
          ...tableOptions,
          page: tableState.page,
          rowsPerPage: tableState.rowsPerPage,
        };

        const query = getQueryParams(data);
        const queryString = convertToQueryString(query);
        dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
        setTableOptions(data);
      }

      if (action === 'sort') {
        const data = {
          ...tableOptions,
          sort: tableState.sortOrder,
        };

        const query = getQueryParams(data);
        const queryString = convertToQueryString(query);
        dispatch(getOrgClassSchedules(orgId, queryString, handleGetClassScheduleResponse));
        setTableOptions(data);
      }
    },
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('Class Schedules')]} />
          <SearchTextBox
            mr={24}
            ml={2}
            setSearchKey={handleSearchKeyChanges}
            label={t('Search name or code')}
          />
          <Stack>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={() => {}}
                disableElevation
                variant="contained"
                color="secondary"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                  fontWeight: 300,
                }}>
                {t('Import Class Schedule')}
              </Button>
              <MoreVert onClick={toggleMoreVert} />
            </Grid>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              sx={{ marginTop: 2, marginLeft: -5 }}
              onClose={toggleMoreVert}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem onClick={handleDownloadClassSchedule}>
                {t('Download Class Schedules')}
              </MenuItem>
            </Menu>
          </Stack>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable columns={columns} options={options} data={filteredClassSchedulesList} />
        </Box>
      </Box>
    </Container>
  );
}
