import {
  requesetPostCatalog,
  requesetPutCatalog,
  requestGetCatalogs,
  requestPostDuplicateCatalog,
  requestPutCatalogsOrder,
} from '../requests/catalogs';
import { getCatalogs, setCatalogs, setLatestSourceCatalog } from '../../actions/catalogActions';
import { requestHandler, requestTypes } from '../helper';
import { getLearningActivities } from '../../actions/learningActivityActions';

export function* handleGetCatalogs(action) {
  const { payload } = action;
  const { self } = action;
  const onResponse = (catalogs) => {
    return setLatestSourceCatalog(catalogs[0]);
  };
  yield requestHandler(
    requestGetCatalogs.bind(this, payload),
    self ? setCatalogs : onResponse,
    requestTypes.GET,
  );
}

export function* handlePostCatalog(action) {
  const { payload, navigate } = action;
  yield requestHandler(requesetPostCatalog.bind(this, payload), null, requestTypes.POST, navigate);
}

export function* handlePutCatalog(action) {
  const { payload, navigate } = action;
  yield requestHandler(requesetPutCatalog.bind(this, payload), null, requestTypes.PUT, navigate);
}

export function* handleDuplicateCatalog(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPostDuplicateCatalog.bind(this, payload),
    duplicateCatalogCallback.bind(this, payload),
    requestTypes.POST,
    navigate,
  );
}

const duplicateCatalogCallback = (payload) => {
  return getLearningActivities(payload.org);
};

export function* handlePutCatalogsOrder(action) {
  const { payload, navigate } = action;
  yield requestHandler(
    requestPutCatalogsOrder.bind(this, payload),
    null,
    requestTypes.PUT,
    navigate,
  );
}
