import { actionTypes } from '../constants/actionTypes';

export const getAllAdvisors = (payload) => {
  return {
    type: actionTypes.ADVISORS.GET,
    payload,
  };
};

export const setAdvisors = (payload) => {
  return {
    type: actionTypes.ADVISORS.SET,
    payload,
  };
};

export const changeAdvisorForStudent = (orgId, data, callback) => {
  return {
    type: actionTypes.ADVISORS.SET_ADVISOR_FOR_STUDENT,
    payload: { orgId, data },
    callback,
  };
};
