import { RuleService } from '../../../services/RuleService';

const ruleService = new RuleService();

export function requestPostRule(payload) {
  const { org, data } = payload;
  return ruleService.save(org, data);
}

export function requestUpdateRule(payload) {
  const { orgId, ruleId, data } = payload;
  return ruleService.update(orgId, ruleId, data);
}

export function requestGetAssociatedRule(payload) {
  const { orgId, rulableId } = payload;
  return ruleService.getAssociatedRule(orgId, rulableId);
}
