import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(1),
  },

  progressBar: {
    width: ({ allUnits, completedUnits }) => {
      return (completedUnits / allUnits) * 100 + '%';
    },
    color: 'white',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  allUnits: {
    padding: theme.spacing(0.5),
  },
}));

export default function ProgressBar(props) {
  const { allUnits, completedUnits } = props;
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      <Box className={classes.progressBar}>
        {completedUnits != 0 && <Typography sx={{ float: 'right' }}>{completedUnits}</Typography>}
      </Box>
      {allUnits != completedUnits && (
        <Box className={classes.allUnits}>
          <Typography>{allUnits}</Typography>
        </Box>
      )}
    </Box>
  );
}
