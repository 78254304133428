import { Box, Button, Checkbox, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import DeclareDataTable from '../../../components/DeclareDataTable';
import DeclareTextField from '../../../components/DeclareTextField';
import { appRoutes } from '../../../helpers/routes';
import { patchTeqAssignments } from '../../../redux/actions/transferEquivalencyActions';
import { getStudentTeqAssignments } from '../../../redux/actions/studentActions';
import {
  getStudentTransferEvaluations,
  postTransferEvaluation,
} from '../../../redux/actions/transferEvaluationActions';

import { setError } from '../../../redux/actions/notificationActions';
import { EvaluationStatusTypes } from '../../../helpers/appConstants';
import _ from 'lodash';
import {
  getDefaultProgramMapId,
  getStudentProgramMapIdByCatalog,
} from '../../../helpers/studentsHelper';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'flex-end',
  },
  tableRoot: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: theme.spacing(1),
      '&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5)': {
        textAlign: 'center',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(2),
      },
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      '&:nth-of-type(3) .tss-178gktx-MUIDataTableHeadCell-contentWrapper, &:nth-of-type(4) .tss-178gktx-MUIDataTableHeadCell-contentWrapper, &:nth-of-type(5) .tss-178gktx-MUIDataTableHeadCell-contentWrapper':
        {
          flexDirection: 'column',
        },
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1),
    },
    '& .MuiCheckbox-root': {
      padding: theme.spacing(1),
    },
  },
  btn: {
    '&.MuiButton-root': {
      '&:last-child': { marginLeft: theme.spacing(1) },
      display: 'flex',
      width: theme.spacing(15),
      height: theme.spacing(5),
      borderRadius: '20px',
      textTransform: 'capitalize',
      color: theme.palette.white.main,
      fontWeight: 300,
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
    justifyContent: 'right',
    marginTop: theme.spacing(5),
  },
}));

export default function TransferGrades() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const classes = useStyles();
  const { orgId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teqStatusesEnum } = useSelector((state) => state.transferEquivalency);
  const { currentSelectedStudent, transferEqAssignments } = useSelector((state) => state.students);
  const { current: currentEquivalencies } = useSelector((state) => state.transferEquivalency);
  const { statusList } = useSelector((state) => state.transferEvaluation);
  const studentTEvs = useSelector((state) => state.transferEvaluation.studentEvaluations);
  const [renderBody, setRenderBody] = useState([]);
  const { programMapList } = useSelector((state) => state.programMaps);
  const catalogs = useSelector((state) => state.allCatalogs.catalogs);

  useEffect(() => {
    dispatch(getStudentTeqAssignments(orgId, currentSelectedStudent));
    dispatch(getStudentTransferEvaluations(orgId, currentSelectedStudent.id));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(currentEquivalencies) && !_.isEmpty(transferEqAssignments)) {
      const data = currentEquivalencies.map((eq) => {
        const { transferringCourseId } = eq;
        const hasOnlyRefusedOrArchived = eq.equivalencies.every(
          (item) =>
            item.status.type === teqStatusesEnum.REFUSED ||
            item.status.type === teqStatusesEnum.ARCHIVED,
        );

        const { grade, meetsGradesRequirements } = transferEqAssignments.find(
          ({ transferringCourseId: tcId }) => tcId === transferringCourseId,
        ) || { grade: null, meetsGradesRequirements: false };
        return { ...eq, grade, meetsGradesRequirements, hasOnlyRefusedOrArchived };
      });
      setRenderBody(data);
    }
  }, [currentEquivalencies, transferEqAssignments]);

  const goBack = () => {
    navigate(-1);
  };

  const navigateToTransferEvaluationView = () => {
    navigate(
      generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, {
        orgId: orgId,
      }),
      { state: state },
    );
  };

  const processRequestBody = (sourceLaId, type, value) => {
    const data = renderBody.map((rb) => {
      if (rb.transferringCourseId === sourceLaId) {
        if (type === 'GRADE') {
          return { ...rb, grade: value };
        } else if (type === 'MEET_REQ') {
          return { ...rb, meetsGradesRequirements: value };
        }
      }

      return rb;
    });

    setRenderBody(data);
  };

  const updateTeqAssignments = async () => {
    const { id: studentId } = currentSelectedStudent;
    const hasEmptyGrade = renderBody.some(({ grade }) => !grade || !grade.trim().length);

    if (hasEmptyGrade) {
      dispatch(
        setError({
          message: t('You must add Grades before saving.'),
        }),
      );
    } else {
      const requestBody = renderBody.map(
        ({ transferringCourseId, grade, meetsGradesRequirements }) => ({
          transferringCourseId,
          grade,
          meetsGradesRequirements,
        }),
      );

      await saveTransferEvaluation();

      dispatch(
        patchTeqAssignments(
          orgId,
          studentId,
          { assignments: requestBody },
          navigateToTransferEvaluationView,
        ),
      );
    }
  };

  const saveTransferEvaluation = async () => {
    if (_.isEmpty(studentTEvs)) {
      const { id: studentId } = currentSelectedStudent;
      const status = statusList.find(({ type }) => type === EvaluationStatusTypes.IN_PROGRESS);
      const data = {
        programMapId: currentSelectedStudent.programMapId
          ? currentSelectedStudent.programMapId
          : !_.isNil(currentSelectedStudent.catalogId)
          ? getStudentProgramMapIdByCatalog(
              programMapList,
              currentSelectedStudent.programOption?.id,
              currentSelectedStudent.catalogId,
            )
          : getDefaultProgramMapId(
              programMapList,
              catalogs,
              currentSelectedStudent.programOption?.id,
            ),
        unitsCovered: 0,
        statusCode: status.code,
        catalogId: currentSelectedStudent.currentCatalog.id,
      };
      dispatch(postTransferEvaluation(orgId, studentId, data));
    }
  };

  const columns = [
    {
      name: 'code',
      label: t('Course Code'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'name',
      label: t('Course Name'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" fontWeight={700}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'units',
      label: t('Units'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" fontWeight={700}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'transferringCourseId',
      label: t('Grades'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const { grade } = renderBody.find(
            ({ transferringCourseId }) => transferringCourseId === value,
          );
          return (
            <DeclareTextField
              sm={true}
              sx={{ width: '60px' }}
              variant="outlined"
              value={grade ?? ''}
              name={value}
              inputProps={{ maxLength: 25 }}
              onChange={({ target }) => processRequestBody(value, 'GRADE', target.value)}
            />
          );
        },
      },
    },
    {
      name: 'transferringCourseId',
      label: t('Meets Grade Requirement'),
      options: {
        customBodyRender: (value) => {
          const { meetsGradesRequirements, hasOnlyRefusedOrArchived } = renderBody.find(
            ({ transferringCourseId }) => transferringCourseId === value,
          );
          return (
            <Checkbox
              disabled={hasOnlyRefusedOrArchived}
              name={value}
              checked={meetsGradesRequirements}
              onChange={({ target }) => processRequestBody(value, 'MEET_REQ', target.checked)}
            />
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onRowClick: () => {},
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <StudentStatusBar />
        <Box className={classes.contentWrapper}>
          <Typography fontWeight={700} color="primary" fontSize={30}>
            {t('Transfer Grades')}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, mb: 4 }}>
          <DeclareDataTable
            className={classes.tableRoot}
            data={renderBody}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          onClick={() => updateTeqAssignments()}
          className={classes.btn}>
          {t('Save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          className={classes.btn}
          onClick={() => goBack()}>
          {t('Cancel')}
        </Button>
      </Box>
    </Container>
  );
}
