import React, { useEffect, useState } from 'react';
import { Button, Chip, MenuItem, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import StudentStatusBar from '../../../components/advisor/StudentStatusBar/StudentStatusBar';
import DeclaredSelect from '../../../components/DeclareSelect';
import { PROGRAM_MAP_TYPE } from '../../../helpers/programMapHelper';
import {
  editTransferEvaluation,
  getCompletedCourseReq,
  getStudentTransferEvaluations,
  getTransferEvaluationStatusList,
} from '../../../redux/actions/transferEvaluationActions';
import { getStudentTeqAssignments } from '../../../redux/actions/studentActions';
import {
  EvaluationStatusTypes,
  pathwayCourseCategory,
  REQUIREMENT_TYPE,
} from '../../../helpers/appConstants';
import {
  getLearningActivities,
  getLearningActivitiesTransferEquivalencies,
} from '../../../redux/actions/learningActivityActions';
import { Edit, Warning, CloseFullscreen } from '@mui/icons-material';
import { appRoutes } from '../../../helpers/routes';
import {
  setSelectedLACodes,
  setSelectedSourceOrg,
  getTeqList,
  getStatuses,
} from '../../../redux/actions/transferEquivalencyActions';
import TransferOptions from './TransferOptions';
import { getPathway } from '../../../redux/actions/pathwayActions';
import {
  getUnusedCourses,
  flatMapProgrammeMap,
  flatMapTransferOptions,
} from '../../../helpers/assignTransferCourseMapHelper';
import theme from '../../../theme';
import { setError } from '../../../redux/actions/notificationActions';
import { getUnitsCovered, isStudentPreferredProgram } from '../../../helpers/studentsHelper';

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    '& ul': {
      listStyle: 'none',
      padding: 0,
      '& li': {
        paddingTop: theme.spacing(1),
      },
    },
    '& .MuiChip-root': {
      width: '8em',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  categoryTitle: {
    '&.MuiTypography-root': {
      fontWeight: 800,
    },
    width: 'fit-content',
  },
  courseChip: {
    '&.MuiChip-root': {
      color: theme.palette.white.main,
    },
  },
  groupChip: {
    '&.MuiChip-root': {
      width: '12em',
      color: theme.palette.white.main,
    },
  },
  courseChipProgramMapDefault: {
    '&.MuiChip-root': {
      color: 'common.black',
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  courseChipProgramMapHighlighted: {
    '&.MuiChip-root': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  courseChipProgramMapDisabled: {
    '&.MuiChip-root': {
      color: theme.palette.grey.main,
      backgroundColor: theme.palette.disabledCourse.main,
    },
  },
  courseChipProgramMapHighlightedPartially: {
    '&.MuiChip-root': {
      color: theme.palette.primary,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  btn: {
    '&.MuiButton-root': {
      '&:last-child': { marginLeft: theme.spacing(1) },
      display: 'flex',
      width: theme.spacing(15),
      height: theme.spacing(5),
      borderRadius: '20px',
      textTransform: 'capitalize',
      color: theme.palette.white.main,
      fontWeight: 300,
    },
  },
  evaluationCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  evaluationColumnPaper: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  equivalencyColumnPaper: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(1),
    flexDirection: 'column',
  },
  transferOptionHeader: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flex: 11,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
  },
  requirementPartiallyHighlight: {
    '&.MuiTypography-root': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  requirementFullHighlight: {
    cursor: 'pointer',
    '&.MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  requirementDefault: {
    cursor: 'pointer',
  },
  groupContainer: {
    '& li ul li ul': {
      marginLeft: theme.spacing(1.5),
    },
    '&.parent-map': {
      marginLeft: theme.spacing(0),
    },
  },
  spacer: { display: 'flex', flex: 1 },
  mappedGroupIcon: {
    marginTop: theme.spacing(1.2),
    '&.MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
}));

export default function View() {
  const classes = useStyle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { orgId, programMapId } = useParams();
  const { programMapList } = useSelector((state) => state.programMaps);
  const { statusList, studentEvaluations, completedCoursesRequirements } = useSelector(
    (state) => state.transferEvaluation,
  );
  const {
    transferEqAssignments,
    currentSelectedStudent: selectedStudent,
    studentOrderedPathway,
  } = useSelector((state) => state.students);
  const { learningActivities, lActivitiesForTransferEquivalencies } = useSelector(
    (state) => state.allLearningActivities,
  );
  const { orgs } = useSelector((state) => state.allOrgs);
  const { teqStatuses, teqStatusesEnum } = useSelector((state) => state.transferEquivalency);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [requirementList, setRequirementList] = useState([]);
  const [status, setStatus] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [selectedCourseCodes, setSelectedCourseCodes] = useState([]);
  const [sourceOrg, setSourceOrg] = useState(null);
  const [unUsedCourses, setUnUsedCourses] = useState([]);
  const [gradeReqCheckedTransferOptions, setGradeReqCheckedTransferOptions] = useState([]);
  const [gradeReqUnCheckedTransferOptions, setGradeReqUnCheckedTransferOptions] = useState([]);
  const [nonTransferrableCourses, setNonTransferrableCourses] = useState([]);
  const [programmeMapItems, setProgrammeMapItems] = useState([]);
  const [transferOptionItems, setTransferOptionItems] = useState([]);
  const [allDestinationEqList, setAllDestinationEqList] = useState([]);
  const [matchedOptions, setMatchedOptions] = useState([]);
  const [matchingInProgress, setMatchingInProgress] = useState({});
  const [disabledCourses, setDisabledCourses] = useState([]);
  const [disabledProgramMapItems, setDisabledProgramMapItems] = useState([]);
  const { allRequirements } = useSelector((state) => state.transferEquivalency);
  const [allCourseReq, setAllCourseReq] = useState([]);
  const TRANSFER_TYPE = {
    COURSE: 'COURSE',
    REQUIREMENT: 'REQUIREMENT',
  };

  useEffect(() => {
    if (_.isEmpty(statusList)) {
      dispatch(getTransferEvaluationStatusList(orgId, selectedStudent.id));
    }
    if (_.isEmpty(studentOrderedPathway)) {
      dispatch(getPathway(orgId, selectedStudent.id));
    }
    const student = {
      ...selectedStudent,
      currentCatalog: {
        id: state.catalogId ? state.catalogId : selectedStudent?.currentCatalog.id,
      },
    };
    dispatch(getStudentTeqAssignments(orgId, student));

    if (_.isEmpty(learningActivities)) {
      dispatch(getLearningActivities(orgId));
    }

    if (_.isEmpty(completedCoursesRequirements)) {
      dispatch(getCompletedCourseReq(orgId, selectedStudent.id));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(studentEvaluations)) {
      const req = studentEvaluations.find(
        ({ programMapId: pm }) => pm === programMapId,
      )?.requirementList;
      setRequirementList(req);
    }
  }, [studentEvaluations]);

  useEffect(() => {
    if (_.isEmpty(teqStatuses)) {
      dispatch(getStatuses(orgId));
    }
  }, [teqStatuses]);

  useEffect(() => {
    if (!_.isEmpty(transferEqAssignments) && _.isEmpty(selectedOrg)) {
      const sourceOrgId = transferEqAssignments[0].sourceOrgId;
      setSelectedOrg(sourceOrgId);
      dispatch(getLearningActivitiesTransferEquivalencies(sourceOrgId));
    }

    const data = transferEqAssignments.flatMap(
      ({ equivalencies, code, sourceLearningActivityId, meetsGradesRequirements }) =>
        equivalencies.flatMap(({ destinationEqList }) =>
          destinationEqList.flatMap((data) => {
            return {
              code,
              sourceLearningActivityId,
              transferEquivalencyId: data.transferEquivalencyId,
              meetsGradesRequirements: meetsGradesRequirements,
            };
          }),
        ),
    );

    const cleanedData = data.reduce((prev, curr) => {
      const eq = prev.find(
        (item) => item.sourceLearningActivityId === curr.sourceLearningActivityId,
      );
      return eq ? prev : prev.concat([curr]);
    }, []);

    const cleanedDataItem = _.keyBy(cleanedData, 'sourceLearningActivityId');
    const gradeReqCheckedList = [];
    const gradeReqUnCheckedList = [];

    _.forEach(transferEqAssignments, (transferOptionItem) => {
      const objectKey = cleanedDataItem[transferOptionItem.sourceLearningActivityId];
      if (objectKey?.meetsGradesRequirements) {
        gradeReqCheckedList.push(transferOptionItem);
      } else {
        gradeReqUnCheckedList.push(transferOptionItem);
      }
    });

    setGradeReqCheckedTransferOptions(gradeReqCheckedList);
    setGradeReqUnCheckedTransferOptions(gradeReqUnCheckedList);
  }, [transferEqAssignments, learningActivities]);

  useEffect(() => {
    setNonTransferrableCourses(
      gradeReqUnCheckedTransferOptions.map(({ code, name }) => ({
        code,
        name,
      })),
    );
  }, [gradeReqUnCheckedTransferOptions]);

  useEffect(() => {
    if (state) {
      setStatusCode(state.evaluation.status.code);
      setStatus(state.evaluation.status);
      setMatchedOptions(state.evaluation.mapping || []);
    }
  }, [state]);

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  useEffect(() => {
    if (!_.isEmpty(transferEqAssignments) && !_.isEmpty(lActivitiesForTransferEquivalencies)) {
      const sourceOrgId = transferEqAssignments[0].sourceOrgId;
      const sourceEquivalencyCourseCodes = transferEqAssignments
        .map(({ sourceLearningActivityId }) =>
          lActivitiesForTransferEquivalencies.find(({ id }) => id === sourceLearningActivityId),
        )
        .map((item) => item?.code)
        .filter((data) => data !== undefined);
      const value = orgs.find(({ id }) => id === sourceOrgId);
      const cleanArray = removeDuplicates(sourceEquivalencyCourseCodes);
      setSelectedCourseCodes(cleanArray);
      setSourceOrg(value);
      dispatch(
        getTeqList(
          orgId,
          value.id,
          cleanArray,
          state.catalogId ? state.catalogId : selectedStudent?.currentCatalog.id,
        ),
      );
    }
  }, [lActivitiesForTransferEquivalencies, transferEqAssignments]);

  useEffect(() => {
    if (!_.isEmpty(learningActivities) && !_.isEmpty(allRequirements)) {
      setAllCourseReq(learningActivities.concat(allRequirements));
    }
  }, [learningActivities, allRequirements]);

  const setEditState = () => {
    // This function sets the state for selected code and selected org
    // which is used to fetch the save state of the transfer equivalency
    dispatch(setSelectedLACodes(selectedCourseCodes));
    dispatch(setSelectedSourceOrg(sourceOrg));
  };

  const navigateToTransferEquivalency = () => {
    setEditState();
    navigate(
      generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, {
        orgId,
      }),
      {
        state: {
          isEdited: true,
          programMapId,
          ...state,
        },
      },
    );
  };

  const generateNonTransferableCourses = (nonTransferrableCourses) => {
    const nonTransferCourses = nonTransferrableCourses.map(({ code, name }, key) => {
      return (
        <Tooltip key={key} title={name} arrow>
          <Chip key={key} className={classes.courseChip} label={code} color="error" />
        </Tooltip>
      );
    });
    return (
      !_.isEmpty(nonTransferCourses) && (
        <>
          <Box sx={{ display: 'flex', flex: 1 }}></Box>
          <Box sx={{ display: 'flex', flex: 10 }}>
            <ul key={0}>
              <li key={0}>
                <Tooltip title={t("Transfer options that don't meet the grade requirement.")} arrow>
                  <Typography className={classes.categoryTitle}>
                    {t('Non-transferrable')}
                  </Typography>
                </Tooltip>
                <Typography>{sourceOrg?.name && `${t('From ')} ${sourceOrg?.name}`}</Typography>
                {nonTransferCourses}
              </li>
            </ul>
          </Box>
          <Box sx={{ display: 'flex', flex: 1 }}></Box>
        </>
      )
    );
  };

  const onClickReqMapChip = (course) => {
    if (
      _.isEmpty(matchingInProgress.transferOptions) ||
      _.isEmpty(matchingInProgress.requirements)
    ) {
      if (!_.isEmpty(matchedOptions)) {
        const amendedMatchedOptions = matchedOptions.filter(
          ({ matchingReq }) => matchingReq !== `${course.parentId}_${course.id}`,
        );
        setMatchedOptions(amendedMatchedOptions);
      }

      return;
    }
    const isRequirementAvailable = matchingInProgress.requirements.includes(
      `${course.parentId}_${course.id}`,
    );
    const matchedOption = matchingInProgress.transferOptions.find(({ clicked }) => clicked);
    if (isRequirementAvailable) {
      const finalMatchedOptions = [
        ...matchedOptions,
        {
          transferOptionId: [`${matchedOption.eqid}_${course.id}`],
          matchingReq: `${course.parentId}_${course.id}`,
        },
      ];
      setMatchedOptions(finalMatchedOptions);
    }
  };

  useEffect(() => {
    setMatchingInProgress({});
    const availableTransferOptions = allDestinationEqList.filter(
      (desEqList) => !unUsedCourses.includes(desEqList.id),
    );

    const sourceCountWithTEQ = transferOptionItems.reduce((acc, item) => {
      const equivalency = item.equivalencies
        .filter((eq) => {
          let isNotStudentReviewRefused = true;
          if (eq.status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW) {
            if (eq.studentStatus?.status?.type === teqStatusesEnum?.REFUSED) {
              isNotStudentReviewRefused = false;
            }
          }
          return (
            availableTransferOptions
              .map(({ transferEquivalencyId }) => transferEquivalencyId)
              .includes(eq.id) && isNotStudentReviewRefused
          );
        })
        .map((eq) => eq.id);
      acc.push({
        equivalency: equivalency,
        sourceLearningActivity: item.sourceLearningActivityId,
      });
      return acc;
    }, []);

    const courseNeedToDisable = matchedOptions.flatMap(({ transferOptionId }) => {
      return transferOptionId.flatMap((tOId) => {
        const splittedId = tOId.split('_');
        const matchedEquivalency = +splittedId[0];
        const parent = sourceCountWithTEQ.find(({ equivalency }) =>
          equivalency.includes(matchedEquivalency),
        );
        if (parent) {
          const remainingEquivalencies = parent.equivalency.filter(
            (eq) => eq !== matchedEquivalency,
          );
          return availableTransferOptions.filter(({ transferEquivalencyId }) =>
            remainingEquivalencies.includes(transferEquivalencyId),
          );
        }
        return [];
      });
    });

    let filteredProgramMapList = [];
    if (!_.isEmpty(programmeMapItems)) {
      filteredProgramMapList = filterTheList(programmeMapItems, availableTransferOptions).filter(
        ({ parentId, id }) =>
          !matchedOptions.map(({ matchingReq }) => matchingReq).includes(`${parentId}_${id}`),
      );
    }
    const studentReviewRefusedItems = transferOptionItems.flatMap(({ equivalencies }) => {
      return equivalencies
        .flatMap(({ destinationEqList, status, studentStatus }) => {
          let isStudentReviewRefused = false;
          if (status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW) {
            if (studentStatus?.status?.type === teqStatusesEnum?.REFUSED) {
              isStudentReviewRefused = true;
            }
          }
          return isStudentReviewRefused ? destinationEqList : [];
        })
        .filter((item) => item !== undefined);
    });

    const remainingTransferOptionsToDisable = availableTransferOptions.filter(
      ({ transferEquivalencyId, id }) =>
        !matchedOptions
          .flatMap(({ transferOptionId }) => transferOptionId.map((tOId) => tOId))
          .includes(`${transferEquivalencyId}_${id}`) &&
        !courseNeedToDisable
          .map(({ transferEquivalencyId: teqId, id: courseId }) => `${teqId}_${courseId}`)
          .includes(`${transferEquivalencyId}_${id}`) &&
        !filteredProgramMapList.map(({ id }) => id).includes(id) &&
        !studentReviewRefusedItems
          .map(({ transferEquivalencyId: teqId, id: courseId }) => `${teqId}_${courseId}`)
          .includes(`${transferEquivalencyId}_${id}`),
    );
    const optionsAvailableToMap = availableTransferOptions.filter(
      ({ transferEquivalencyId, id }) =>
        !matchedOptions
          .flatMap(({ transferOptionId }) => transferOptionId.map((tOId) => tOId))
          .includes(`${transferEquivalencyId}_${id}`) &&
        ![...courseNeedToDisable, ...remainingTransferOptionsToDisable]
          .map(({ transferEquivalencyId, id }) => `${transferEquivalencyId}_${id}`)
          .includes(`${transferEquivalencyId}_${id}`) &&
        !studentReviewRefusedItems
          .map(({ transferEquivalencyId: teqId, id: courseId }) => `${teqId}_${courseId}`)
          .includes(`${transferEquivalencyId}_${id}`),
    );

    const courses = filteredProgramMapList.filter(
      ({ id }) => !optionsAvailableToMap.map(({ id }) => id).includes(id),
    );

    const transferOptionsThatGotRefused = availableTransferOptions.filter(
      ({ transferEquivalencyId, id }) =>
        studentReviewRefusedItems
          .map(({ transferEquivalencyId: teqId, id: courseId }) => `${teqId}_${courseId}`)
          .includes(`${transferEquivalencyId}_${id}`),
    );

    setDisabledCourses([
      ...courseNeedToDisable,
      ...remainingTransferOptionsToDisable,
      ...transferOptionsThatGotRefused,
    ]);
    setDisabledProgramMapItems(courses);
  }, [matchedOptions, programmeMapItems, transferOptionItems, teqStatusesEnum]);

  const filterLearningActivities = (obj, destinationList) => {
    if (Array.isArray(obj)) {
      const filteredItems = obj
        .map((item) => filterLearningActivities(item, destinationList))
        .filter(Boolean);
      return filteredItems.length > 0 ? filteredItems : null;
    } else if (typeof obj === 'object') {
      const itemType = obj.type;
      if (itemType === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY) {
        const found = destinationList.find(({ id }) => obj.id === id);
        if (found) {
          return obj;
        }
      } else if (obj.items && Array.isArray(obj.items)) {
        const filteredItems = filterLearningActivities(obj.items, destinationList);
        if (filteredItems) {
          obj.items = filteredItems;
          return obj;
        }

        const found = destinationList.find(({ id }) => obj.id === id);
        if (found) {
          const clonedObject = _.cloneDeep(obj);
          clonedObject.items = [];
          return clonedObject;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const requirements = [];
    const courses = [];
    const destinationsCourses = [];
    const unusedCourses = [];
    const newMap = {
      items: requirementList?.items,
      name: t('Not mapped to a requirement in the program map'),
      isRequirement: true,
      type: PROGRAM_MAP_TYPE.GROUP,
      id: requirementList?.parent,
      parent: true,
    };
    const requirementListWithParentProgramMap = {
      items: newMap,
    };

    if (!_.isEmpty(requirementList) && !_.isEmpty(teqStatusesEnum)) {
      const resultProgrammeMap = flatMapProgrammeMap(
        requirementListWithParentProgramMap,
        requirements,
        courses,
      );

      const filteredTransferOptions = gradeReqCheckedTransferOptions
        .map((item) => {
          const filteredEquivalencies = item.equivalencies.filter(
            (eq) =>
              ![teqStatusesEnum.REFUSED, teqStatusesEnum.ARCHIVED].includes(eq.status?.type) &&
              eq.destinationEqList.every(
                (destination) =>
                  destination.id !== null && allCourseReq.some((req) => req.id === destination.id),
              ),
          );
          return { ...item, equivalencies: filteredEquivalencies };
        })
        .filter(({ equivalencies }) => !_.isEmpty(equivalencies));

      const resultTransferOptions = flatMapTransferOptions(
        filteredTransferOptions,
        destinationsCourses,
      );

      const unusedCoursesList = getUnusedCourses(
        [...resultProgrammeMap.courses, ...resultProgrammeMap.requirements],
        resultTransferOptions.destinations,
        unusedCourses,
      );

      setUnUsedCourses(removeDuplicates(unusedCoursesList));
      const deepClonedMap = _.cloneDeep(resultProgrammeMap.map);
      setProgrammeMapItems(
        filterLearningActivities(deepClonedMap.items, resultTransferOptions.destinations),
      );
      setTransferOptionItems(resultTransferOptions.currentEquivalencies);
    }
  }, [requirementList, gradeReqCheckedTransferOptions, teqStatusesEnum, allCourseReq]);

  useEffect(() => {
    const destinationList = transferOptionItems.flatMap((option) =>
      option.equivalencies.flatMap((equivalency) => equivalency.destinationEqList),
    );
    setAllDestinationEqList(destinationList);
  }, [transferOptionItems]);

  const filterTheList = (pmItemList, uniqueAvailableOptions) => {
    if (
      !pmItemList ||
      !pmItemList.items ||
      pmItemList.items.length === 0 ||
      _.isEmpty(uniqueAvailableOptions)
    ) {
      return [];
    }

    return pmItemList.items.reduce((result, pmItem) => {
      if (uniqueAvailableOptions.map(({ id }) => id).includes(pmItem.id)) {
        if (pmItem.type !== PROGRAM_MAP_TYPE.LEARNING_ACTIVITY) {
          const filteredItems = filterTheList(pmItem, uniqueAvailableOptions);
          result.push(...filteredItems);
        }
        result.push(pmItem);
      } else if (pmItem.items) {
        const filteredItems = filterTheList(pmItem, uniqueAvailableOptions);
        result.push(...filteredItems);
      }

      return result;
    }, []);
  };

  useEffect(() => {
    const availableTransferOptions = allDestinationEqList.filter(
      (desEqList) => !unUsedCourses.includes(desEqList.id),
    );

    const sourceCountWithTEQ = transferOptionItems.reduce((acc, item) => {
      const equivalency = item.equivalencies
        .filter((eq) => {
          let isNotStudentReviewRefused = true;
          if (eq.status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW) {
            if (eq.studentStatus?.status?.type === teqStatusesEnum?.REFUSED) {
              isNotStudentReviewRefused = false;
            }
          }
          return (
            availableTransferOptions
              .map(({ transferEquivalencyId }) => transferEquivalencyId)
              .includes(eq.id) && isNotStudentReviewRefused
          );
        })
        .map((eq) => eq.id);
      acc.push({
        equivalency: equivalency,
        sourceLearningActivity: item.sourceLearningActivityId,
      });
      return acc;
    }, []);

    const lookupMap = {};
    transferOptionItems.forEach((item) => {
      item.equivalencies.forEach((eq) => {
        eq.destinationEqList.forEach((destEq) => {
          const compoundId = eq.id + '_' + destEq.id;
          lookupMap[compoundId] = destEq.units;
        });
      });
    });

    const availableSourceCourses = sourceCountWithTEQ.filter((sc) => {
      return sc.equivalency.length === 1;
    });

    const studentReviewRefusedItems = transferOptionItems.flatMap(({ equivalencies }) => {
      return equivalencies
        .flatMap(({ destinationEqList, status, studentStatus }) => {
          let isStudentReviewRefused = false;
          if (status?.type === teqStatusesEnum?.REQUIRES_STUDENT_BY_STUDENT_REVIEW) {
            if (studentStatus?.status?.type === teqStatusesEnum?.REFUSED) {
              isStudentReviewRefused = true;
            }
          }
          return isStudentReviewRefused ? destinationEqList : [];
        })
        .filter((item) => item !== undefined);
    });

    const filteredOptions = state.evaluation.mapping
      ? state.evaluation.mapping.filter(({ transferOptionId }) => {
          return transferOptionId.some((optionId) => optionId in lookupMap);
        })
      : [];

    if (!isEmpty(studentReviewRefusedItems)) {
      const filteredMergedMappedOptions = filteredOptions?.filter(({ transferOptionId }) => {
        if (studentReviewRefusedItems.length === 0) {
          return true;
        }
        return !studentReviewRefusedItems.some(({ transferEquivalencyId, id }) =>
          transferOptionId.find((tOId) => tOId === `${transferEquivalencyId}_${id}`),
        );
      });
      if (filteredMergedMappedOptions && _.isEmpty(availableSourceCourses)) {
        setMatchedOptions(filteredMergedMappedOptions);
      }
    }

    if (!_.isEmpty(availableSourceCourses)) {
      const availableTeq = availableSourceCourses.reduce((acc, item) => {
        acc.push(...item.equivalency);
        return acc;
      }, []);

      const finalAvailableTransferOptions = availableTransferOptions.filter(
        ({ transferEquivalencyId }) => availableTeq.includes(transferEquivalencyId),
      );

      const allTeqCounts = availableTransferOptions.reduce((acc, item) => {
        acc[item.id] = (acc[item.id] || 0) + 1;
        return acc;
      }, {});

      const duplicateTeqIdsFromAll = Object.keys(allTeqCounts).filter((id) => allTeqCounts[id] > 1);
      const uniqueAvailableOptions = finalAvailableTransferOptions.filter(
        (tf) => !duplicateTeqIdsFromAll.includes(tf.id),
      );

      const filteredProgramMapList = filterTheList(programmeMapItems, uniqueAvailableOptions);
      const filteredMapListIdCounts = filteredProgramMapList.reduce((acc, item) => {
        acc[item.id] = (acc[item.id] || 0) + 1;
        return acc;
      }, {});

      const duplicateFilteredList = Object.keys(filteredMapListIdCounts).filter(
        (id) => filteredMapListIdCounts[id] > 1,
      );

      const programMapCoursesToAutoMap = filteredProgramMapList.filter(
        (fl) => !duplicateFilteredList.includes(fl.id),
      );

      const matchedItems = programMapCoursesToAutoMap.map((pms) => {
        const uao = uniqueAvailableOptions.find((uao) => uao.id === pms.id);
        if (uao) {
          return {
            transferOptionId: [`${uao.transferEquivalencyId}_${uao.id}`],
            type: uao.type,
            units: uao.units,
            matchingReq: `${
              pms.type !== PROGRAM_MAP_TYPE.LEARNING_ACTIVITY ? pms.id : pms.parentId
            }_${pms.id}`,
          };
        }
      });

      const mergedMappedOptions = _.uniqWith(
        [...(filteredOptions || []), ...matchedItems],
        (a, b) => _.isEqual(a.transferOptionId.sort(), b.transferOptionId.sort()),
      );

      const filteredMergedMappedOptions = mergedMappedOptions.filter(({ transferOptionId }) => {
        if (studentReviewRefusedItems.length === 0) {
          return true;
        }
        return !studentReviewRefusedItems.some(({ transferEquivalencyId, id }) =>
          transferOptionId.find((tOId) => tOId === `${transferEquivalencyId}_${id}`),
        );
      });
      setMatchedOptions(filteredMergedMappedOptions);
    }
  }, [allDestinationEqList, programmeMapItems, unUsedCourses]);

  const onClickRequirementGroup = (requirementGroup) => {
    const { transferOptions, requirements } = matchingInProgress;
    const matchingReqId = `${requirementGroup.id}_${requirementGroup.id}`;
    if (_.isEmpty(transferOptions) || _.isEmpty(requirements)) {
      const amendedMatchedOptions = matchedOptions.filter(
        ({ matchingReq }) => matchingReq !== matchingReqId,
      );
      setMatchedOptions(amendedMatchedOptions);
      return;
    }

    const isRequirementAvailable = requirements.includes(matchingReqId);

    if (isRequirementAvailable) {
      const amendedMatchedOptions = matchedOptions.filter(
        ({ matchingReq }) => matchingReq === matchingReqId,
      );

      if (!_.isEmpty(amendedMatchedOptions)) {
        const matchedOption = transferOptions.find(
          ({ clicked, id }) => clicked && id === requirementGroup.id,
        );
        const updatedMatchedOptions = matchedOptions.map((option) => {
          if (option.matchingReq === matchingReqId) {
            return {
              transferOptionId: [
                ...option.transferOptionId,
                `${matchedOption.eqid}_${requirementGroup.id}`,
              ],
              matchingReq: matchingReqId,
              units: option.units + matchedOption.units,
              type: TRANSFER_TYPE.REQUIREMENT,
            };
          }
          return option;
        });
        setMatchedOptions(updatedMatchedOptions);
      } else {
        const matchedOption = transferOptions.find(
          ({ clicked, id }) => clicked && id === requirementGroup.id,
        );
        const newMatchedOption = {
          transferOptionId: [`${matchedOption.eqid}_${requirementGroup.id}`],
          matchingReq: matchingReqId,
          units: matchedOption.units,
          type: TRANSFER_TYPE.REQUIREMENT,
        };
        const updatedMatchedOptions = [...matchedOptions, newMatchedOption];
        setMatchedOptions(updatedMatchedOptions);
      }
    }
  };

  const getChipMappedStyle = (itemObj, isDisabled) => {
    let chipStyle = classes.courseChipProgramMapDefault;
    if (itemObj.type === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY) {
      const matched = matchedOptions.find(
        ({ matchingReq }) => matchingReq === `${itemObj.parentId}_${itemObj.id}`,
      );
      if (matched) {
        chipStyle = classes.courseChipProgramMapHighlighted;
      } else if (isDisabled) {
        chipStyle = classes.courseChipProgramMapDisabled;
      }
    }
    return chipStyle;
  };

  const getReqMappedStyle = (reqObj) => {
    let styleRequirement = classes.requirementDefault;
    if (reqObj.isRequirement) {
      const matched = matchedOptions.find(
        ({ matchingReq }) => matchingReq === `${reqObj.id}_${reqObj.id}`,
      );
      if (matched) {
        styleRequirement = classes.requirementFullHighlight;
      }
      const { requirements } = matchingInProgress;
      if (!_.isEmpty(requirements)) {
        const isRequirementFound = requirements.find(
          (requirement) => requirement === `${reqObj.id}_${reqObj.id}`,
        );
        if (isRequirementFound) {
          styleRequirement = classes.requirementFullHighlight;
        }
      }
    }
    return styleRequirement;
  };

  const getReqMatchedUnits = (mapItem) => {
    let units = null;
    if (mapItem.isRequirement) {
      const matched = matchedOptions.find(
        ({ matchingReq }) => matchingReq === `${mapItem.id}_${mapItem.id}`,
      );
      if (matched) {
        units = matched.units;
      }
    }
    return units;
  };

  const renderChips = (filteredProgramMapLAIds, items) => {
    let chips = items.map((itemObj, key) => {
      const isDisabled = disabledProgramMapItems.find(({ id }) => id === itemObj.id);
      const chipStyle = getChipMappedStyle(itemObj, isDisabled);

      return (
        !_.isUndefined(itemObj.code) &&
        filteredProgramMapLAIds.includes(itemObj.id) && (
          <Tooltip title={itemObj.name} arrow>
            <Chip
              key={key}
              sx={{
                border: matchingInProgress?.requirements?.includes(
                  `${itemObj.parentId}_${itemObj.id}`,
                )
                  ? `2px solid ${theme.palette.primary.main} !important`
                  : `1px solid ${theme.palette.primary.main} !important`,
              }}
              className={chipStyle}
              label={itemObj.code}
              onClick={() => onClickReqMapChip(itemObj)}
            />
          </Tooltip>
        )
      );
    });
    return (
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        {chips}
      </Stack>
    );
  };

  const renderRequirementGroupComponent = (
    mapItem,
    styleRequirement,
    filteredProgramMapLAIds,
    matchedUnits,
  ) => {
    if (mapItem.parent) {
      if (!_.isEmpty(filteredProgramMapLAIds)) {
        return (
          <Stack direction="row">
            <Warning
              color="warning"
              sx={{ cursor: 'pointer', marginTop: '4px' }}
              fontSize="medium"
            />
            <Typography sx={{ lineHeight: '2' }} className={styleRequirement} fontWeight={800}>
              {mapItem.name}
            </Typography>
          </Stack>
        );
      }
    } else {
      return (
        <>
          <Stack direction="row" gap={1}>
            <Typography
              sx={{ lineHeight: '2' }}
              className={styleRequirement}
              fontWeight={800}
              onClick={() => {
                onClickRequirementGroup(mapItem);
              }}>
              {mapItem.name} {matchedUnits && `(${matchedUnits})`}
            </Typography>
            {!_.isNil(matchedUnits) && (
              <CloseFullscreen className={classes.mappedGroupIcon} color="secondary" />
            )}
          </Stack>
          <Typography>{mapItem.message}</Typography>
        </>
      );
    }
  };

  const renderMap = (map) => {
    if (!_.isEmpty(map)) {
      return map.map((mapItem, key) => {
        if (!_.isEmpty(mapItem)) {
          const styleRequirement = getReqMappedStyle(mapItem);
          const matchedUnits = getReqMatchedUnits(mapItem);
          if (
            mapItem.type == PROGRAM_MAP_TYPE.GROUP ||
            mapItem.type == PROGRAM_MAP_TYPE.PROGRAM_MAP
          ) {
            const filteredProgramMapLAIds = mapItem.items
              .filter(
                (item) =>
                  item.type === PROGRAM_MAP_TYPE.LEARNING_ACTIVITY &&
                  allDestinationEqList.some((destinationId) => destinationId.id === item.id),
              )
              .flatMap(({ id }) => id);
            return (
              <ul
                key={key}
                className={`${classes.groupContainer} ${mapItem.parent ? 'parent-map' : ''}`}>
                <li key={key}>
                  <>
                    {renderRequirementGroupComponent(
                      mapItem,
                      styleRequirement,
                      filteredProgramMapLAIds,
                      matchedUnits,
                    )}
                    {renderChips(filteredProgramMapLAIds, mapItem.items)}
                    {renderMap(mapItem.items)}
                  </>
                </li>
              </ul>
            );
          }
        }
      });
    }
  };

  const displayUnused = (unUsedCourses) => {
    const unusedCourses = unUsedCourses.map((res, key) => {
      const laOrGroup = allCourseReq.find(({ id }) => id === res);

      if (!_.isUndefined(laOrGroup)) {
        return (
          <Chip
            key={key}
            className={
              laOrGroup?.type !== REQUIREMENT_TYPE.Course ? classes.groupChip : classes.courseChip
            }
            label={laOrGroup?.type !== REQUIREMENT_TYPE.Course ? laOrGroup?.name : laOrGroup.code}
            color={'error'}
          />
        );
      }
    });
    return _.isEmpty(unusedCourses) ? null : (
      <ul key={0}>
        <li key={0}>
          <Tooltip title={t("Transfer option doesn't appear in the requirement map")} arrow>
            <Typography className={classes.categoryTitle}>{t('Unusable')}</Typography>
          </Tooltip>
          {unusedCourses}
        </li>
      </ul>
    );
  };

  const getCompletedCourses = () => {
    return matchedOptions.map(({ transferOptionId, type, units }) => {
      let requirementTransfer = null;

      if (type === TRANSFER_TYPE.REQUIREMENT) {
        requirementTransfer = {
          unitsTransferred: units,
        };
      }

      const id = transferOptionId[0].split('_')[1];
      return {
        id,
        type: pathwayCourseCategory.TRANSFERRED,
        additionalInfo: {
          requirementTransfer,
        },
      };
    });
  };

  const saveEvaluation = () => {
    const isUsedInPlanning = status?.type === EvaluationStatusTypes.USED_IN_PLANNING;
    const isPreferredProgram = isStudentPreferredProgram(
      selectedStudent,
      state?.programMapId,
      programMapList,
    );

    const navigate =
      isUsedInPlanning && isPreferredProgram ? navigateToStudentPlanning : navigateToList;

    const completedCourses = getCompletedCourses();
    const unitsCovered = getUnitsCovered(completedCourses, learningActivities);

    const data = {
      unitsCovered,
      statusCode: status.code,
      mapping: matchedOptions,
    };

    if (!_.isEmpty(matchedOptions) && isUsedInPlanning) {
      data['transferredCourses'] = completedCourses;
    }

    if (isUsedInPlanning && !isPreferredProgram) {
      dispatch(
        setError({
          message: t(
            'Evaluation can only be set to Used in Planning for the students preferred program. Create an evaluation that matches the student’s program first',
          ),
        }),
      );
      return;
    }

    dispatch(
      editTransferEvaluation(orgId, selectedStudent.id, state.evaluation.id, data, () =>
        dispatch(getStudentTransferEvaluations(orgId, selectedStudent.id, navigate)),
      ),
    );
  };

  const navigateToList = () => {
    navigate(generatePath(appRoutes.advisor.TRANSFER_EVALUATIONS_LIST, { orgId: orgId }));
  };

  const navigateToStudentPlanning = () => {
    navigate(generatePath(appRoutes.advisor.STUDENT_PLANNING, { orgId }));
  };

  const handleChange = (e) => {
    setStatus(statusList.find(({ code }) => code === e.target.value));
    setStatusCode(e.target.value);
  };

  const formatProgramMap = (programmeMapItems) => {
    return programmeMapItems.items.reduce((result, pmItem) => {
      if (pmItem) {
        result.push(pmItem);
        if (pmItem.items && pmItem.type !== PROGRAM_MAP_TYPE.LEARNING_ACTIVITY) {
          const filteredItems = formatProgramMap(pmItem);
          result.push(...filteredItems);
        }
      }

      return result;
    }, []);
  };

  const OnClickTransferOptionCards = (courseList) => {
    if (!_.isEmpty(matchingInProgress)) {
      const teqId = courseList[0].transferEquivalencyId;
      if (
        matchingInProgress.transferOptions.find(({ eqid, clicked }) => eqid === teqId && clicked)
      ) {
        setMatchingInProgress({});
        return;
      }
    }
    const availableTransferOptions = allDestinationEqList.filter(
      (desEqList) =>
        !unUsedCourses.includes(desEqList.id) &&
        !matchedOptions
          .flatMap(({ transferOptionId }) => transferOptionId.map((tOId) => tOId))
          .includes(`${desEqList.transferEquivalencyId}_${desEqList.id}`) &&
        !disabledCourses
          .map(({ transferEquivalencyId, id }) => `${transferEquivalencyId}_${id}`)
          .includes(`${desEqList.transferEquivalencyId}_${desEqList.id}`),
    );

    const formattedProgramMap = formatProgramMap(programmeMapItems);
    const filteredCourseList = courseList
      .filter(
        ({ transferEquivalencyId, id }) =>
          !matchedOptions
            .flatMap(({ transferOptionId }) => transferOptionId.map((tOId) => tOId))
            .includes(`${transferEquivalencyId}_${id}`) &&
          !disabledCourses
            .map(({ transferEquivalencyId, id }) => `${transferEquivalencyId}_${id}`)
            .includes(`${transferEquivalencyId}_${id}`),
      )
      .reduce(
        (acc, item) => {
          const foundInOption = availableTransferOptions
            .filter((course) => course.id === item.id)
            .map((ato) => {
              let isClicked = false;
              if (ato.transferEquivalencyId === item.transferEquivalencyId) {
                isClicked = true;
              }
              return {
                eqid: ato.transferEquivalencyId,
                clicked: isClicked,
                units: ato.units,
                id: ato.id,
              };
            });
          const foundInRequirement = formattedProgramMap
            .filter(
              (pmCourse) =>
                pmCourse.id === item.id &&
                !matchedOptions
                  .filter(({ type }) => type === TRANSFER_TYPE.COURSE)
                  .map(({ matchingReq }) => matchingReq)
                  .includes(
                    `${pmCourse.parentId ? pmCourse.parentId : pmCourse.id}_${pmCourse.id}`,
                  ),
            )
            .map(
              (filteredCourse) =>
                `${filteredCourse.parentId ? filteredCourse.parentId : filteredCourse.id}_${
                  filteredCourse.id
                }`,
            );

          acc['transferOptions'].push(...foundInOption);
          acc['requirements'].push(...foundInRequirement);
          return acc;
        },
        { transferOptions: [], requirements: [] },
      );
    setMatchingInProgress(filteredCourseList);
  };

  return (
    <Container
      maxWidth="xl"
      onClick={() => {
        setMatchingInProgress({});
      }}>
      <Box sx={{ marginTop: '100px' }}>
        <StudentStatusBar />
        <Box className={classes.headerContainer}>
          <Box className={classes.columnItem} sx={{ flex: '2.0.0' }}>
            <Typography color="primary" variant="h4" fontWeight={800}>
              {t('Assign Courses Transfer')}
            </Typography>
            <Typography>
              {t(
                'To match a transfer select an transfer option, and then match to the requirement map.',
              )}
            </Typography>
          </Box>
          <Box className={classes.columnItem} sx={{ flex: '0.8' }}>
            <DeclaredSelect
              sm={true}
              margin="dense"
              name="evaluationStatus"
              color="white"
              value={statusCode}
              onChange={(e) => {
                handleChange(e);
              }}>
              {statusList.map((item, index) => (
                <MenuItem value={item.code} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </DeclaredSelect>
          </Box>
        </Box>
        <Box className={classes.contentContainer}>
          <Box
            className={classes.columnItem}
            sx={{
              flex: '2 0 0',
            }}>
            <Paper className={classes.equivalencyColumnPaper}>
              <Box className={classes.evaluationCardHeader}>
                <Box className={classes.spacer}></Box>
                <Stack
                  className={classes.transferOptionHeader}
                  direction="row"
                  alignItems="center"
                  gap={2}>
                  <Typography color="primary" fontWeight={900} variant="h5">
                    {t('Transfer Options')}
                  </Typography>
                  <Edit
                    color="secondary"
                    sx={{ cursor: 'pointer', marginTop: '4px' }}
                    fontSize="medium"
                    onClick={() => navigateToTransferEquivalency()}
                  />
                </Stack>
              </Box>
              <Box className={classes.transferOptionData}>
                {transferOptionItems.map((eq, index) => {
                  return (
                    <TransferOptions
                      OnClickTransferOptionCards={OnClickTransferOptionCards}
                      matchedOptions={matchedOptions}
                      matchingInProgress={matchingInProgress}
                      disabledCourses={disabledCourses}
                      value={eq}
                      key={`tEqRow-${index}`}
                      selectedOrg={sourceOrg}
                      currentEquivalencies={transferOptionItems}
                      index={index}
                      unUsedCourses={unUsedCourses}
                      allCourseReq={allCourseReq}
                    />
                  );
                })}
              </Box>
              {!_.isEmpty(nonTransferrableCourses) && (
                <Box
                  className={classes.root}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #ccc',
                    marginBottom: '24px',
                    paddingTop: '16px',
                  }}>
                  {generateNonTransferableCourses(nonTransferrableCourses)}
                </Box>
              )}
            </Paper>
          </Box>
          <Box
            className={classes.columnItem}
            sx={{
              flex: '2 0 0',
              paddingLeft: '10px',
            }}>
            <Paper className={classes.evaluationColumnPaper}>
              <Box className={classes.evaluationCardHeader}>
                <Typography color="primary" fontWeight={900} variant="h5">
                  {state?.name}
                </Typography>
              </Box>
              <Typography color="primary" variant="body1" fontWeight={600}>
                {state?.evaluation?.catalog?.name}
              </Typography>
              <Box className={classes.root}>{renderMap([programmeMapItems] || [])}</Box>
              <Box className={classes.root}>{displayUnused(unUsedCourses)}</Box>
            </Paper>
          </Box>
        </Box>
        <Box className={classes.btnContainer}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            onClick={saveEvaluation}
            className={classes.btn}>
            {t('Save')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            className={classes.btn}
            onClick={navigateToList}>
            {t('Cancel')}
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}></Box>
      </Box>
    </Container>
  );
}
