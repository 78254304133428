import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, generatePath, useParams } from 'react-router-dom';
import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import DeclareTextField from '../../../components/DeclareTextField';
import DeclaredSelect from '../../../components/DeclareSelect';
import * as catalogHelper from '../../../helpers/catalogHelper';
import { appRoutes } from '../../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus } from '../../../helpers/appConstants';
import {
  duplicateCatalog,
  getCatalogs,
  postCatalog,
  putCatalog,
} from '../../../redux/actions/catalogActions';
import DeclareDialog from '../../../components/DeclareDialog';
import { setFormError } from '../../../redux/actions/notificationActions';

let initValues = {};

export default function CatalogForm({ isDuplicateForm = false }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { t } = useTranslation();
  const orgStore = useSelector((state) => state.allOrgs);
  const formError = useSelector((state) => state.notifications.formError);
  const { catalogs } = useSelector((state) => state.allCatalogs);
  const { values, handleInputChange } = useDeclareForm(initValues);
  const [catalogName, setCatalogName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  if (state?.editView) {
    initValues.name = state.name;
    initValues.year = state.year;
    initValues.status = state.status;
  } else {
    initValues = {
      name: '',
      year: '',
      status: getStatus()[3].value,
    };
  }

  useEffect(() => {
    if (_.isEmpty(catalogs)) {
      dispatch(getCatalogs(orgId));
    }
  }, []);

  const backToCatalogVersion = () => {
    navigate(generatePath(appRoutes.catalog.CATALOG_LIST, { orgId: orgStore.selectedOrg.id }));
  };

  const data = {
    name: values.name,
    year: values.year,
    status: values.status,
  };

  const saveCatalogVersion = async () => {
    dispatch(postCatalog(orgStore.selectedOrg.id, data, backToCatalogVersion));
  };

  const updateCatalogVersion = async () => {
    dispatch(putCatalog(orgStore.selectedOrg.id, state.id, data, backToCatalogVersion));
  };

  const validateFormData = () => {
    const formData = {
      ...data,
      duplicateFrom: values.duplicateFrom,
    };

    let hasError = false;
    let error = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (!value) {
        hasError = true;
        error = { ...error, [key]: { name: key, message: `${key} is required` } };
      }
    });

    dispatch(setFormError(error));
    return hasError;
  };

  const handleDuplicateCatalog = () => {
    const hasError = validateFormData();
    if (hasError) return;

    const catalog = catalogs.find(({ id }) => values.duplicateFrom === id);
    setCatalogName(catalog.name);
    setOpenDialog(true);
  };

  const saveDuplicateCatalog = () => {
    dispatch(duplicateCatalog(orgId, values.duplicateFrom, data, backToCatalogVersion));
    setOpenDialog(false);
  };

  return (
    <Box>
      <DeclareDialog
        header={`${t('Are you sure you want to duplicate')} ${catalogName}?`}
        yesLabel={t('Duplicate')}
        noLabel={t('Cancel')}
        open={openDialog}
        onClose={() => {}}
        handleYesClick={() => saveDuplicateCatalog()}
        handleNoClick={() => setOpenDialog(false)}
        cancelBtnColor="grey"
      />
      <DeclareForm>
        <Paper sx={{ padding: '50px' }}>
          <Grid container>
            <Grid item xs={6} className="left">
              <DeclareTextField
                inputlabel={t('name') + ' *'}
                variant="outlined"
                placeholder={t('nameOfTheCatalog')}
                value={values.name}
                name="name"
                onChange={handleInputChange}
                error={formError['name']}
                helperText={formError['name']?.message}
              />
              <DeclaredSelect
                inputlabel={t('year') + ' *'}
                margin="dense"
                value={values.year}
                name="year"
                onChange={handleInputChange}
                error={formError['year']}
                helperText={formError['year']?.message}>
                <MenuItem value={0}>None</MenuItem>
                {catalogHelper.getYears().map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Grid>
            <Grid item xs={6} className="right">
              <DeclaredSelect
                display={isDuplicateForm}
                inputlabel={t('Duplicate From') + ' *'}
                margin="dense"
                value={values.duplicateFrom}
                name="duplicateFrom"
                onChange={handleInputChange}
                error={formError['duplicateFrom']}
                helperText={formError['duplicateFrom']?.message}>
                {catalogs.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </DeclaredSelect>
              <DeclaredSelect
                inputlabel={t('status') + ' *'}
                margin="dense"
                value={values.status}
                name="status"
                onChange={handleInputChange}
                error={formError['status']}
                helperText={formError['status']?.message}>
                {getStatus().map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </DeclaredSelect>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              marginRight: '20px',
            }}
            onClick={() => {
              if (state?.editView) {
                return updateCatalogVersion();
              }

              if (isDuplicateForm) {
                return handleDuplicateCatalog();
              }

              saveCatalogVersion();
            }}>
            {state?.editView ? t('update') : t('save')}
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="grey"
            sx={{
              height: '45px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
              width: '150px',
              color: '#fff',
            }}
            onClick={() => {
              backToCatalogVersion();
            }}>
            {t('cancel')}
          </Button>
        </Box>
      </DeclareForm>
    </Box>
  );
}
