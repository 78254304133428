import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export function initExcel(title, columns, styleProperties = {}) {
  const { bold = true, size = 12 } = styleProperties;
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(title);
  sheet.columns = columns;
  sheet.eachRow({ includeEmpty: false }, function (row) {
    row.eachCell(function (cell) {
      cell.font = {
        bold,
        size,
      };
    });
  });
  return [workbook, sheet];
}

export function createDataRows(sheet, columnData) {
  let count = 2;

  for (const data of columnData) {
    sheet.insertRow(count, data);
    count++;
  }

  return;
}

export async function createExcelFile(workbook, filename) {
  const buffer = await workbook.xlsx.writeBuffer();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const blob = new Blob([buffer], { type: fileType });

  return saveAs(blob, filename);
}

export function getTodaysDate() {
  const date = new Date();
  const [dd, mm, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
  const month = mm < 9 ? `0${mm + 1}` : `${mm + 1}`;
  const day = dd < 10 ? `0${dd}` : `${dd}`;
  return `${month}-${day}-${year}`;
}
