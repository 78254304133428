import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StatusCell from '../../../components/StatusCell';
import { appRoutes } from '../../../helpers/routes';
import EditIcon from '@mui/icons-material/Edit';
import DeclareBreadcrumb from '../../../components/DeclareBreadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import { getOrgs, selectOrg } from '../../../redux/actions/orgActions';
import { getCatalogs } from '../../../redux/actions/catalogActions';
import DeclareDataTable from '../../../components/DeclareDataTable';
import SearchTextBox from '../../../components/SearchTextBox';

export default function OranizationList() {
  const { t } = useTranslation();
  const orgs = useSelector((state) => state.allOrgs.orgs);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!orgs.length > 0) {
      dispatch(getOrgs());
    }
  }, []);

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'group',
      label: 'Group',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value !== null ? value.name : '';
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <StatusCell value={value} />;
        },
      },
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              aria-label="delete"
              size="medium"
              id={value}
              onClick={(e) => {
                e.stopPropagation();
                let index = orgs.findIndex((x) => x.id === value);
                navigateToEditOrg(orgs[index]);
              }}>
              <EditIcon fontSize="inherit" color="secondary" />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'simple',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    onRowClick: (rowData, rowMeta) => {
      let id = rowData[3].props.id;
      let index = orgs.findIndex((x) => x.id === id);
      const org = orgs[index];
      dispatch(selectOrg(org));
      dispatch(getCatalogs(org.id));
      navigateToDashboard(id);
    },
    onCellClick: (colData, cellMeta) => {
      return;
    },
  };

  const navigateToCreateOrg = () => {
    navigate(appRoutes.catalog.ORGANIZATION_CREATE);
  };

  const navigateToEditOrg = (data) => {
    data.editView = true;
    data.routeName = data.name;

    navigate(appRoutes.catalog.ORGANIZATION_EDIT, { state: data });
  };

  const navigateToDashboard = (orgId) => {
    navigate(generatePath(appRoutes.catalog.DASHBOARD, { orgId }));
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: '100px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <DeclareBreadcrumb items={[t('organizations')]} />
          <SearchTextBox />
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            sx={{
              height: '50px',
              borderRadius: '20px',
              textTransform: 'capitalize',
              fontWeight: 300,
            }}
            onClick={() => {
              navigateToCreateOrg();
            }}>
            New Organization
          </Button>
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <DeclareDataTable data={orgs} columns={columns} options={options} />
        </Box>
      </Box>
    </Container>
  );
}
