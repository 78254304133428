import { actionTypes } from '../constants/actionTypes';

const initState = {
  programMapList: [],
};

const programMapReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROGRAM_MAPS:
      return { ...state, programMapList: payload };
    case actionTypes.SELECT_ORG:
      return { ...state, programMapList: [] };
    default:
      return state;
  }
};

export default programMapReducer;
