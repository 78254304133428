import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeclareTextField from '../DeclareTextField';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  boxTextFieldMaximumOfX: {
    width: theme.spacing(25),
  },
  ruleUnitBoxes: {
    display: 'flex',
  },
}));

export default function AllowMaximumOfX(props) {
  const { selectedElementPath, addRuleDataHandler, selectedItemEditable = true } = props;
  const [maximumCreditUnits, setMaximumCreditUnits] = useState(null);
  const [requirementCheck, setRequirementCheck] = useState(false);
  const [isRequirement, setIsRequirement] = useState(false);
  const { t } = useTranslation();

  const processChange = (isReq = requirementCheck) => {
    const data = {
      isRequirement: isReq,
      maximumCreditUnits: Number(maximumCreditUnits),
    };
    addRuleDataHandler({ data, selectedElementPath });
  };

  const classes = useStyle();

  useEffect(() => {
    setMaximumCreditUnits(props.data.maximumCreditUnits || null);
    setIsRequirement(props.data.isRequirement || false);
    setRequirementCheck(props.data.isRequirement || requirementCheck);
  }, [props]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.ruleUnitBoxes}>
          <Box className={classes.boxTextFieldBefore}>
            <DeclareTextField
              onChange={(e) => {
                setMaximumCreditUnits(e.target.value);
              }}
              onBlur={() => {
                processChange(isRequirement);
              }}
              inputlabel={`${t('Maximum Units of Credit')}*`}
              variant="outlined"
              value={maximumCreditUnits}
              name="maximumCreditUnits"
              type="number"
              disabled={!selectedItemEditable}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: '0px !important' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={requirementCheck}
              disabled={!selectedItemEditable}
              onChange={(e) => {
                setRequirementCheck(e.target.checked);
              }}
              onBlur={() => {
                processChange(requirementCheck);
              }}
            />
          }
          label={t('Is a requirement')}
        />
      </Grid>
    </Grid>
  );
}
