import { actionTypes } from '../constants/actionTypes';

export const postRule = (org, data, silent, persistErrors, callback) => {
  return {
    type: actionTypes.POST_RULE,
    payload: { org, data, silent, persistErrors },
    callback,
  };
};

export const updateRule = (orgId, ruleId, data, silent, persistErrors) => {
  return {
    type: actionTypes.UPDATE_RULE,
    payload: { orgId, ruleId, data, silent, persistErrors },
  };
};

export const getAssociatedRule = (orgId, rulableId, callback) => {
  return {
    type: actionTypes.GET_ASSOCIATED_RULE,
    payload: { orgId, rulableId },
    callback,
  };
};
