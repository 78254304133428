import { Box, Button, Chip, Grid, MenuItem, Paper, TextField } from '@mui/material';
import React from 'react';
import { useDeclareForm, DeclareForm } from '../../../components/DeclareForm';
import DeclareTextField from '../../../components/DeclareTextField';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import DeclaredSelect from '../../../components/DeclareSelect';
import * as orgHelper from '../../../helpers/orgHelper';
import DeclareAutocomplete from '../../../components/DeclareAutocomplete';
import { useTranslation } from 'react-i18next';
import { appRoutes } from '../../../helpers/routes';
import { useDispatch, useSelector } from 'react-redux';
import { postOrg, putOrg } from '../../../redux/actions/orgActions';
import { getStatus } from '../../../helpers/appConstants';

const aliasList = [];

let initValues = {};

const orgStates = orgHelper.getStates;

export default function OrganizationForm() {
  const { state } = useLocation();
  const dispatch = useDispatch();

  if (state?.editView) {
    let index = orgStates.findIndex((x) => x.value === state.state);
    initValues.name = state.name;
    initValues.initials = state.initials;
    initValues.alias = state.alias ? JSON.parse(state.alias) : initValues.alias;
    initValues.website = state.website;
    initValues.externalId = state.externalId;
    initValues.status = state.status;
    initValues.group = state.group !== null ? state.group.id : 0;
    initValues.state = orgStates[index];
  } else {
    initValues = {
      name: '',
      initials: '',
      alias: [],
      website: '',
      externalId: '',
      status: getStatus()[3].value,
      group: 0,
      state: '',
    };
  }

  const { values, setValues, handleInputChange } = useDeclareForm(initValues);
  const formError = useSelector((state) => state.notifications.formError);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToOrgList = () => {
    navigate(appRoutes.catalog.ORGANIZATION_LIST);
  };

  const data = {
    name: values.name,
    initials: values.initials,
    status: values.status,
    alias: JSON.stringify(values.alias),
    website: values.website,
    externalId: values.externalId,
    state: values.state ? values.state.value : '',
    group: values.group > 0 ? values.group : null,
  };

  const navigateToTransferEquivalency = () => {
    navigate(
      generatePath(appRoutes.advisor.TRANSFER_EQUIVALENCIES, {
        orgId: state.orgId,
      }),
    );
  };

  const saveOrganization = async () => {
    if (state?.fromAdvisor) {
      dispatch(postOrg(data, navigateToTransferEquivalency, state?.fromAdvisor));
    } else {
      dispatch(postOrg(data, navigateToOrgList));
    }
  };

  const updateOrganization = async () => {
    data.id = state.id;
    dispatch(putOrg(data, navigateToOrgList));
  };

  return (
    <DeclareForm>
      <Paper sx={{ padding: '50px' }}>
        <Grid container>
          <Grid item xs={6} className="left">
            <DeclareTextField
              inputlabel={t('name') + ' *'}
              variant="outlined"
              placeholder={t('nameOfTheOrganization')}
              value={values.name}
              name="name"
              onChange={handleInputChange}
              error={formError['name']}
              helperText={formError['name']?.message}
            />
            <DeclareTextField
              inputlabel={t('Initials') + ' *'}
              variant="outlined"
              placeholder={t('Initials')}
              value={values.initials}
              name="initials"
              onChange={handleInputChange}
              error={formError['initials']}
              helperText={formError['initials']?.message}
            />
            <DeclareAutocomplete
              freeSolo
              onChange={(event, value) => {
                handleInputChange({ target: { name: 'alias', value: value } });
              }}
              inputlabel={t('alias')}
              multiple
              limitTags={2}
              value={values.alias}
              options={aliasList.map((option) => option)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('alias')}
                  name="alias"
                  error={formError['alias']}
                  helperText={formError['alias']?.message}
                />
              )}
            />
            <DeclareTextField
              inputlabel={t('website') + ' *'}
              variant="outlined"
              placeholder={t('websiteOfTheOrganization')}
              name="website"
              value={values.website}
              onChange={handleInputChange}
              error={formError['website']}
              helperText={formError['website']?.message}
            />
          </Grid>
          <Grid item xs={6} className="right">
            <DeclaredSelect
              inputlabel={t('status') + ' *'}
              margin="dense"
              value={values.status}
              name="status"
              onChange={handleInputChange}
              error={formError['status']}
              helperText={formError['status']?.message}>
              {getStatus().map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclaredSelect
              inputlabel={t('group')}
              margin="dense"
              value={values.group}
              name="group"
              onChange={handleInputChange}
              error={formError['group']}
              helperText={formError['group']?.message}>
              <MenuItem value={0}>None</MenuItem>
              {orgHelper.getOrganizationGroups.map((item) => (
                <MenuItem value={item.id} key={item.id.toString()}>
                  {item.name}
                </MenuItem>
              ))}
            </DeclaredSelect>
            <DeclareAutocomplete
              value={values.state}
              onChange={(event, value) => {
                values.state = value;
                handleInputChange({ target: { name: 'state', value: value } });
              }}
              inputlabel={t('state') + ' *'}
              options={orgStates}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError['state']}
                  helperText={formError['state']?.message}
                />
              )}
            />
            <DeclareTextField
              inputlabel={t('externalId')}
              variant="outlined"
              placeholder={t('externalIdOfTheOrganization')}
              name="externalId"
              value={values.externalId}
              onChange={handleInputChange}
              error={formError['externalId']}
              helperText={formError['externalId']?.message}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ float: 'right', marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            marginRight: '20px',
          }}
          onClick={() => {
            state?.editView ? updateOrganization() : saveOrganization();
          }}>
          {state?.editView ? t('update') : t('save')}
        </Button>
        <Button
          disableElevation
          variant="contained"
          color="grey"
          sx={{
            height: '45px',
            borderRadius: '20px',
            textTransform: 'capitalize',
            fontWeight: 300,
            width: '150px',
            color: '#fff',
          }}
          onClick={() => {
            navigateToOrgList();
          }}>
          {t('cancel')}
        </Button>
      </Box>
    </DeclareForm>
  );
}
