import OrgError from '../../../error/OrgError';
import { ClassScheduleService } from '../../../services/ClassScheduleService';

const classScheduleService = new ClassScheduleService();

export function* requestGetOrgClassSchedules(payload) {
  const { orgId, query } = payload;
  if (orgId) {
    return yield classScheduleService.getAll(orgId, query);
  }
  throw OrgError.orgRequired();
}

export function* requestPatchClassSchedule(params) {
  const { orgId, classScheduleId, data } = params;
  if (!orgId) {
    throw OrgError.orgRequired();
  }
  return yield classScheduleService.cancelSingleClassSchedule(orgId, classScheduleId, data);
}

export function* requestGetOrgPlannedStudent(params) {
  const { orgId, termId, year, courseId } = params;

  if (!orgId) {
    throw OrgError.orgRequired();
  }

  return yield classScheduleService.plannedStudents(orgId, termId, year, courseId);
}
