export const getYears = (nextYearsOnly = false) => {
  var max = new Date().getFullYear() + 10;
  var min = max - 20;
  var years = [];
  if (nextYearsOnly) {
    min = max - 11;
  }
  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
