import { HttpService } from './HttpService';

export default class ReportService extends HttpService {
  postPathwayStatusHistoryReport({ orgId, advisors, studentFirstTerms, startDate, endDate }) {
    return this.post(`/organizations/${orgId}/reports/pathway/createStatusHistoryReport`, {
      studentFirstTerms: studentFirstTerms,
      advisors: advisors,
      startDate: startDate,
      endDate: endDate,
    });
  }

  postGenerateStatusHistoryReport(orgId, requestBody) {
    return this.post(
      `/organizations/${orgId}/reports/pathway/createAggregatedStatusHistoryReport`,
      requestBody,
    );
  }

  async postCourseProjectionReport(orgId, requestBody) {
    // Breaking the call into batches when number of courses are getting high request, response limit is exceeding with reporting service api call
    const batchSize = 500;
    const courseIds = requestBody.courseIds;
    const numBatches = Math.ceil(courseIds.length / batchSize);
    const batchPromises = [];

    for (let i = 0; i < numBatches; i++) {
      const batchIds = courseIds.slice(i * batchSize, (i + 1) * batchSize);
      const batchRequestBody = { ...requestBody, courseIds: batchIds };

      const batchPromise = this.post(
        `/organizations/${orgId}/reports/course/createProjectionReport`,
        batchRequestBody,
      );
      batchPromises.push(batchPromise);
    }

    const batchResponses = await Promise.allSettled(batchPromises);

    const validResponses = batchResponses.filter((response) => response.status === 'fulfilled');
    const combinedData = validResponses.reduce(
      (combinedData, response) => {
        combinedData.data.push(...response.value.data.data);
        if (combinedData.terms && combinedData.terms.length == 0) {
          combinedData.terms.push(...response.value.data.terms);
        }

        return combinedData;
      },
      { data: [], terms: [] },
    );

    return new Promise((resolve, reject) => {
      const responseObject = {
        data: combinedData,
        status: true,
      };
      resolve(responseObject);
    });
  }
}
