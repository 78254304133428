import { actionTypes } from '../constants/actionTypes';

const initState = {
  list: [],
  currentSelectedStudent: {},
  studentStatusList: [],
  transferEqAssignments: [],
  studentOrderedPathway: {},
  studentCompletedTerms: [],
  completedCourses: { Completed: [], Transferred: [], Exempt: [] },
  studentQueryOption: '',
};

const studentReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionTypes.STUDENTS.SET:
      return { ...state, list: payload };
    case actionTypes.STUDENTS.SET_ORDERED_PATHWAY:
      return {
        ...state,
        studentOrderedPathway: payload.pathway,
        studentCompletedTerms: payload.completedTerms,
      };
    case actionTypes.STUDENTS.RESET_ORDERED_PATHWAY:
      return { ...state, studentOrderedPathway: {}, studentCompletedTerms: [] };
    case actionTypes.STUDENTS.SET_SELECTED_STUDENT:
      return {
        ...state,
        currentSelectedStudent: payload,
        studentOrderedPathway: {},
        studentCompletedTerms: [],
        transferEqAssignments: [],
      };
    case actionTypes.STUDENTS.UPDATE_SELECTED_STUDENT:
      return { ...state, currentSelectedStudent: payload };
    case actionTypes.STUDENTS.SET_STUDENT_STATUS:
      return { ...state, studentStatusList: payload };
    case actionTypes.STUDENTS.SET_TEQ_ASSIGNMENTS:
      return { ...state, transferEqAssignments: payload };
    case actionTypes.STUDENTS.SET_COMPLETED_COURSES:
      return { ...state, completedCourses: payload };
    case actionTypes.STUDENTS.RESET_COMPLETED_COURSES:
      return { ...state, completedCourses: { Completed: [], Transferred: [], Exempt: [] } };
    case actionTypes.SELECT_ORG:
      return initState;
    case actionTypes.STUDENTS.SET_STUDENT_LIST_QUERY_OPTION:
      return { ...state, studentQueryOption: payload };
    default:
      return state;
  }
};

export default studentReducer;
